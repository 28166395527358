import { IGeneralStudy, MODALITY_SEPARATOR } from '@app/@core';
import { IStructureData } from './IStructureData';
import { SortOrder } from '../contants/sort';
import { IStudyViewer } from './IStudyViewer';
import { IDicomDir } from '@app/upload/models/IDicomDir';
import { StudyViewer } from './study-viewer';
import { IPatientInfo } from './IPatientInfo';
import { ISeriesInfo } from './ISeriesInfo';
import { IStackImage } from './IStackImage';
import { IStoreViewer } from './IStoreViewer';
import { Provider } from '../contants/load-image';

/**
 * Represents the structure data of the viewer.
 */
export class StructureData implements IStructureData {
  studyList: IStudyViewer[];

  /**
   * Constructs a new instance of the StructureData class.
   */
  constructor() {
    this.studyList = [];
  }

  /**
   * Finds a study by its UID.
   * @param uid - The UID of the study to find.
   * @returns The found study, or undefined if not found.
   */
  findStudy(uid: string): IStudyViewer | undefined {
    return this.studyList.find((study) => {
      return study.uid === uid;
    });
  }

  /**
   * Adds a study to the study list.
   * @param value - The study to be added.
   */
  addStudy(value: IStudyViewer): void {
    this.studyList.push(value);
  }

  /**
   * Deletes a study from the study list based on the provided UID.
   * @param uid The UID of the study to be deleted.
   */
  deletedStudy(uid: string): void {
    const index = this.studyList.findIndex((study) => study.uid === uid);
    if (index) {
      this.studyList.splice(index);
    }
  }

  /**
   * Preprocesses the study list by sorting series and images, and setting general study information.
   */
  preprocessorFile(): void {
    let totalImage = 0;

    // Sort series number
    for (let index = 0, length = this.studyList.length; index < length; index++) {
      const study = this.studyList[index];
      study.sortbySeriesNumber(SortOrder.Ascending);
      //Sort images as instance number
      const modalityList: string[] = [];
      for (let indexSeries = 0, lengthSeries = study.series.length; indexSeries < lengthSeries; indexSeries++) {
        const series = study.series[indexSeries];

        totalImage += series.imagePrefetch.length;
        if (modalityList.includes(series.modality) === false) {
          modalityList.push(series.modality);
        }
        series.sortByInstanceNumber(SortOrder.Ascending);
      }
      const image = study.series[0].imagePrefetch[0];
      const studyInfo: IGeneralStudy = {
        uid: study.uid,
        studyDate: image.tag.general.studyDate,
        studyTime: image.tag.general.studyTime,
        modality: modalityList.join(MODALITY_SEPARATOR),
        bodyPart: image.tag.general.bodyPart,
        totalSeries: study.series.length.toString(),
        totalImage: totalImage.toString(),
      };
      study.setGeneral(studyInfo);
      totalImage = 0;
    }
  }

  /**
   * Imports study data from a DICOM directory.
   * @param body - The DICOM directory data.
   */
  importFromDicomDir(body: IDicomDir, isLogin: boolean): void {
    const study = new StudyViewer(body.studyUid);
    study.fromDicomDir(body, isLogin);
    this.addStudy(study);
  }

  toStore(provider: Provider): IStoreViewer {
    let stackImages: IStackImage[] = [];
    //Study table data
    const studyInfo: IGeneralStudy[] = [];
    //Patient info data
    const patientInfo: IPatientInfo[] = [];
    let seriesInfos: ISeriesInfo[] = [];

    for (let index = 0, length = this.studyList.length; index < length; index++) {
      const study = this.studyList[index];
      const data = study.toStore(provider, study.priority);
      stackImages = [...stackImages, ...data.stackImages];
      studyInfo.push(data.studyInfo);
      patientInfo.push(data.patientInfo);
      seriesInfos = [...seriesInfos, ...data.seriesInfo];
    }
    return {
      stackImages,
      studyInfo,
      patientInfo,
      seriesInfos,
    };
  }

  /**
   * Retrieves a list of general study information.
   * @returns An array of IGeneralStudy objects representing the study information.
   */
  getStudyInfoList(): IGeneralStudy[] {
    const studyInfoList: IGeneralStudy[] = [];
    for (let index = 0, length = this.studyList.length; index < length; index++) {
      const study = this.studyList[index];
      studyInfoList.push(study.studyInfo);
    }
    return studyInfoList;
  }
}
