import { firebaseAuthActions } from './auth.actions';
import { IUser } from '../models/IUser';
import { createReducer, on, createSelector, createFeature } from '@ngrx/store';
import { LoginMode } from '../models/login-mode';

export const AUTH_KEY = 'auth';
export interface AuthState {
  loginMode: LoginMode | undefined;
  loggedIn: boolean;
  user: IUser;
  isLoading: boolean | undefined;
  error: string | undefined;
}

const initialState: AuthState = {
  loginMode: undefined,
  loggedIn: false,
  user: {} as IUser,
  isLoading: undefined,
  error: undefined,
};

//#region  reducer
export const authFeature = createFeature({
  name: AUTH_KEY,
  reducer: createReducer<AuthState>(
    initialState,
    on(
      firebaseAuthActions.login,
      (state): AuthState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      firebaseAuthActions.loginSuccess,
      (state, action): AuthState => ({
        ...state,
        isLoading: false,
        loginMode: LoginMode.firebase,
        loggedIn: true,
        user: {
          firebase: action.firebaseRes,
          googleAPI: action.googleToken,
        },
      }),
    ),
    on(
      firebaseAuthActions.loginFailure,
      (state): AuthState => ({
        ...state,
        isLoading: false,
      }),
    ),
    on(
      firebaseAuthActions.logout,
      (state): AuthState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      firebaseAuthActions.logoutSuccess,
      (state): AuthState => ({
        ...state,
        loggedIn: false,
        isLoading: false,
        loginMode: undefined,
        user: {} as IUser,
      }),
    ),
  ),
});

//#endregion

export const {
  name, // feature name
  reducer, // feature reducer
} = authFeature;
