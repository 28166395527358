import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AUTH_KEY, AuthState } from './auth.reducer';
import { LoginMode } from '../models/login-mode';

//#region selector
export const selectAuth = createFeatureSelector<AuthState>(AUTH_KEY);

const selectGApiInfo = createSelector(selectAuth, (state: AuthState) => state.user.googleAPI);
const selectUserInfo = createSelector(selectAuth, (state: AuthState) => {
  if (state.loginMode === LoginMode.firebase) {
    return state.user.firebase;
  }
  return state.user.onPremise;
});
const selectLoginModeInfo = createSelector(selectAuth, (state: AuthState) => state.loginMode);
const selectLoginState = createSelector(selectAuth, (state: AuthState) => {
  return { isLogin: state.loggedIn, error: state.error };
});
const selectLoginStatus = createSelector(selectAuth, (state: AuthState) => state.isLoading);
const selectCredentials = createSelector(selectAuth, (state: AuthState) => {
  return { userUid: state.user.firebase?.uid, token: state.user.googleAPI?.accessToken };
});

const selectLoginInfo = createSelector(selectAuth, (state: AuthState) => {
  const mode = state.loginMode;
  switch (mode) {
    case LoginMode.firebase:
      return { mode, userId: state.user.firebase?.uid };

    default:
      return { mode, userId: state.user.onPremise?.uid };
  }
});
//#endregion

export const authQuery = {
  selectUserInfo,
  selectGApiInfo,
  selectLoginModeInfo,
  selectLoginState,
  selectLoginStatus,
  selectCredentials,
  selectLoginInfo,
};
