export const DEFAULT_SETTING = {
  userSetting: {
    isFullRoot: true,
  },
  layoutModality: {
    default: [
      {
        name: 'default-One Study (2x2 stack)',
        isDefault: true,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['2x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1'],
      },
      {
        name: 'default-One Study (2x3 stack)',
        isDefault: false,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['2x3'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
      {
        name: 'default-Two Studies (2x2 stack)',
        isDefault: false,
        isCompare: true,
        panelLayout: '1x2',
        stackLayout: ['2x2', '2x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
    ],

    CT: [
      {
        name: 'CT-One Study (2x2 stack)',
        panelLayout: '1x1',
        isDefault: false,
        isCompare: false,
        stackLayout: ['2x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1'],
      },
      {
        name: 'CT-One Study (2x3 stack)',
        isDefault: true,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['2x3'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
      {
        name: 'CT-Two Studies (2x2 stack)',
        isDefault: false,
        isCompare: true,
        panelLayout: '1x2',
        stackLayout: ['2x2', '2x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
    ],

    MR: [
      {
        name: 'MR-One Study (2x2 stack)',
        isDefault: false,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['2x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1'],
      },
      {
        name: 'MR-One Study (2x3 stack)',
        isDefault: true,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['2x3'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
      {
        name: 'MR-Two Studies (2x2 stack)',
        isDefault: false,
        isCompare: true,
        panelLayout: '1x2',
        stackLayout: ['2x2', '2x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
    ],
    CR: [
      {
        name: 'CR-One Study (1x2 stack)',
        isDefault: true,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['1x2'],
        tileLayout: ['1x1', '1x1'],
      },
      {
        name: 'CR-Two Studies (1x1 stack)',
        isDefault: false,
        isCompare: true,
        panelLayout: '1x2',
        stackLayout: ['1x1', '1x2'],
        tileLayout: ['1x1', '1x1', '1x1', '1x1', '1x1', '1x1'],
      },
    ],

    PETCT: [
      {
        name: 'PETCT',
        isDefault: true,
        isCompare: false,
        panelLayout: '1x1',
        stackLayout: ['1x1', '1x1'],
        tileLayout: ['3x4', '1x1'],
      },
    ],
  },
  overlay: {
    topLeft: [
      {
        tag: '',
        name: 'patientName',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '0-0',
      },
      {
        tag: '',
        name: 'rotation',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '0-1',
      },
      {
        tag: '',
        name: 'index',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '0-2',
      },
    ],
    topRight: [
      {
        tag: 'x00200013',
        name: '',
        prefix: 'IN: ',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '1-0',
      },
      {
        tag: 'x00200011',
        name: '',
        prefix: 'SN:',
        suffix: '',
        row: '1',
        column: '0',
        type: '0',
        position: '1-1',
      },
      {
        tag: 'x00200012',
        name: '',
        prefix: 'AN: ',
        suffix: '',
        row: '1',
        column: '1',
        type: '0',
        position: '1-2',
      },
      {
        tag: 'x00080012',
        name: '',
        prefix: 'CD: ',
        suffix: '',
        row: '2',
        column: '0',
        type: '0',
        position: '1-3',
      },
    ],
    bottomLeft: [
      {
        tag: '',
        name: 'imageSize',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '1',
        position: '2-0',
      },
      {
        tag: 'x00080008',
        name: '',
        prefix: '',
        suffix: '',
        row: '1',
        column: '0',
        type: '0',
        position: '2-1',
      },
      {
        tag: 'x00201041',
        name: '',
        prefix: 'SL: ',
        suffix: '',
        row: '2',
        column: '0',
        type: '0',
        position: '2-2',
      },
      {
        tag: 'x00180081',
        name: '',
        prefix: 'ET: ',
        suffix: '',
        row: '3',
        column: '0',
        type: '0',
        position: '2-3',
      },
    ],
    bottomRight: [
      {
        tag: '',
        name: 'voi',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '1',
        position: '3-0',
      },
      {
        tag: '',
        name: 'scale',
        prefix: 'Zoom: ',
        suffix: ' %',
        row: '1',
        column: '0',
        type: '1',
        position: '3-1',
      },
      {
        tag: '',
        name: 'realSize',
        prefix: 'RealSizeRatio: ',
        suffix: ' %',
        row: '2',
        column: '0',
        type: '1',
        position: '3-2',
      },
    ],
  },
  overlayVolume: {
    topLeft: [
      {
        tag: '',
        name: 'patientName',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '0-0',
      },
      {
        tag: '',
        name: 'rotation',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '0-1',
      },
      {
        tag: '',
        name: 'index',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '0-2',
      },
    ],
    topRight: [
      {
        tag: 'x00200011',
        name: '',
        prefix: 'SN:',
        suffix: '',
        row: '0',
        column: '0',
        type: '0',
        position: '1-1',
      },
    ],
    bottomLeft: [
      {
        tag: '',
        name: 'SlideThickness',
        prefix: 'SL: ',
        suffix: '',
        row: '1',
        column: '0',
        type: '0',
        position: '2-3',
      },
    ],
    bottomRight: [
      {
        tag: '',
        name: 'voi',
        prefix: '',
        suffix: '',
        row: '0',
        column: '0',
        type: '1',
        position: '3-0',
      },
      {
        tag: '',
        name: 'scale',
        prefix: 'Zoom: ',
        suffix: ' %',
        row: '1',
        column: '0',
        type: '1',
        position: '3-1',
      },
      {
        tag: '',
        name: 'realSize',
        prefix: 'RealSizeRatio: ',
        suffix: ' %',
        row: '2',
        column: '0',
        type: '1',
        position: '3-2',
      },
    ],
  },
  windowLevelPresets: {
    default: [
      {
        name: 'Preset 1',
        ww: 400,
        wl: 60,
        shortkey: '',
      },
      {
        name: 'Preset 2',
        ww: 600,
        wl: 300,
        shortkey: '',
      },
      {
        name: 'Preset 3',
        ww: 1500,
        wl: 300,
        shortkey: '',
      },
    ],
    CR: [
      {
        name: 'Chest 1',
        ww: 400,
        wl: 60,
        shortkey: '',
      },
      {
        name: 'Chest 2',
        ww: 600,
        wl: 300,
        shortkey: '',
      },
      {
        name: 'Chest 3',
        ww: 1500,
        wl: 300,
        shortkey: '',
      },
    ],
    CT: [
      {
        name: 'CT Abdomen',
        ww: 400,
        wl: 60,
        shortkey: '',
      },
      {
        name: 'CT Angio',
        ww: 600,
        wl: 300,
        shortkey: '',
      },
      {
        name: 'CT Bone',
        ww: 1500,
        wl: 300,
        shortkey: '',
      },
      {
        name: 'CT Brain',
        ww: 80,
        wl: 40,
        shortkey: '',
      },
      {
        name: 'CT Chest',
        ww: 400,
        wl: 40,
        shortkey: '',
      },
      {
        name: 'CT Lungs',
        ww: 1500,
        wl: -400,
        shortkey: '',
      },
    ],
  },
};
