export enum BroadcastScope {
  ActiveTile,
  ActiveStack,
  ActivePanel,
  Viewer,
}

export enum BroadcastTool {
  DicomInfo,
  Export,
  Colorbar,
}

export enum AutoPlay {
  Open,
  Play,
  Pause,
  NextImage,
  PreviousImage,
  FirstImage,
  LastImage,
  Loop,
  Stop,
}
