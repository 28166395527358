<div class="content-wrapper">
  <div class="container">
    <h1>VPACSビューアのクッキーポリシー</h1>

    <p>これは、https://vpacs.net からアクセス可能なVPACSビューアのクッキーポリシーです。</p>

    <p><strong>クッキーとは</strong></p>

    <p>
      ほとんどのプロフェッショナルなウェブサイトで一般的なことですが、このサイトはクッキーを使用しています。クッキーは、あなたのコンピュータにダウンロードされる小さなファイルで、あなたの経験を向上させるために使用されます。このページでは、
      それらが収集する情報、私たちがそれをどのように使用するか、そしてなぜ時々これらのクッキーを保存する必要があるのかを説明します。また、これらのクッキーが保存されないようにする方法も共有しますが、これによりサイトの機能の一部が低下したり、
      '壊れたり'する可能性があります。
    </p>

    <p><strong>クッキーの使用方法</strong></p>

    <p>
      以下に詳述する理由から、クッキーを使用しています。残念ながら、ほとんどの場合、このサイトに追加される機能や特徴を完全に無効にすることなくクッキーを無効にするための業界標準のオプションはありません。あなたがそれらを必要とするかどうか確信が持てない場合、
      それらがあなたが使用するサービスを提供するために使用される可能性があるので、すべてのクッキーを有効にしておくことをお勧めします。
    </p>

    <p><strong>クッキーの無効化</strong></p>

    <p>
      ブラウザの設定を調整することでクッキーの設定を防ぐことができます（これを行う方法については、ブラウザのヘルプを参照してください）。クッキーを無効にすると、このウェブサイトとあなたが訪れる他の多くのウェブサイトの機能に影響を与えることに注意してください。
      クッキーを無効にすると、通常、このサイトの特定の機能と機能も無効になります。したがって、クッキーを無効にしないことをお勧めします。このクッキーポリシーは、
      <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">クッキーポリシージェネレーター</a>の助けを借りて作成されました。
    </p>
    <p><strong>私たちが設定するクッキー</strong></p>

    <ul>
      <li>
        <p>アカウント関連のクッキー</p>
        <p>
          私たちとアカウントを作成する場合、サインアッププロセスの管理と一般的な管理のためにクッキーを使用します。これらのクッキーは通常、ログアウトすると削除されますが、
          場合によってはログアウトした後もサイトの設定を覚えておくために残ることがあります。
        </p>
      </li>

      <li>
        <p>ログイン関連のクッキー</p>
        <p>
          ログインしているときには、この事実を覚えておくためにクッキーを使用します。これにより、新しいページを訪れるたびにログインする必要がなくなります。これらのクッキーは通常、
          ログインしたときにのみ制限された機能とエリアにアクセスできるように、ログアウトすると削除またはクリアされます。
        </p>
      </li>

      <li>
        <p>メールニュースレター関連のクッキー</p>
        <p>
          このサイトはニュースレターやメール購読サービスを提供しており、登録済みかどうかを覚えておくためにクッキーが使用されることがあります。また、購読者/非購読者のユーザーにのみ有効な特定の通知を表示するかどうかも覚えておくために使用されます。
        </p>
      </li>

      <li>
        <p>フォーム関連のクッキー</p>
        <p>連絡先ページやコメントフォームなどのフォームにデータを送信するとき、将来の対応のためにユーザーの詳細を覚えておくためにクッキーが設定されることがあります。</p>
      </li>
    </ul>

    <p><strong>サードパーティのクッキー</strong></p>

    <p>
      特別なケースでは、信頼できるサードパーティによって提供されるクッキーも使用しています。次のセクションでは、このサイトを通じて遭遇する可能性のあるサードパーティのクッキーについて詳しく説明します。
    </p>

    <ul>
      <li>
        <p>
          このサイトはGoogleアナリティクスを使用しており、ウェブ上で最も広く信頼されているアナリティクスソリューションの一つです。これにより、私たちはあなたがサイトをどのように使用しているか、
          そして私たちがあなたの経験を改善する方法を理解するのに役立ちます。これらのクッキーは、サイトで過ごす時間や訪れるページなどのことを追跡することがあり、私たちが引き続き魅力的なコンテンツを生産できるようにします。
        </p>
        <p>Googleアナリティクスのクッキーについての詳細は、公式のGoogleアナリティクスページをご覧ください。</p>
      </li>

      <li>
        <p>広告を提供するために使用するGoogle AdSenseサービスは、DoubleClickクッキーを使用して、ウェブ全体でより関連性の高い広告を提供し、特定の広告が表示される回数を制限します。</p>
        <p>Google AdSenseについての詳細は、公式のGoogle AdSenseプライバシーFAQをご覧ください。</p>
      </li>
    </ul>

    <p><strong>詳細情報</strong></p>

    <p>
      これであなたにとって物事が明確になったことを願っています。以前にも述べたように、あなたが必要かどうか確信が持てないものがある場合、それが私たちのサイトで使用する機能の一つと相互作用する可能性があるもしもし、クッキーが私たちのサイトで使用する機能の一つと相互作用する可能性がある場合、それを有効にしておく方が通常は安全です。
    </p>

    <p>
      クッキーに関する一般的な情報については、
      <a href="https://www.cookiepolicygenerator.com/sample-cookies-policy/">クッキーポリシーの記事</a>をお読みください。
    </p>

    <p>それでもさらに情報が必要な場合は、以下のいずれかの方法で私たちに連絡することができます：</p>

    <ul>
      <li>メール: radteam.vpacs&#64;gmail.com</li>
    </ul>
  </div>
</div>
