<div id="landing-page-body" data-spy="scroll" data-target=".navbar" data-offset="100">
  <header id="header-section">
    <nav class="navbar navbar-expand-lg pl-3 pl-sm-0" id="navbar">
      <div class="container">
        <div class="navbar-brand-wrapper d-flex w-100">
          <img src="assets/images/core/vpacslogo-rec.svg" style="height: 64px" alt="" />
          <button
            class="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="mdi mdi-menu navbar-toggler-icon"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse navbar-menu-wrapper" id="navbarSupportedContent">
          <ul class="navbar-nav align-items-lg-center align-items-start ml-auto">
            <li class="d-flex align-items-center justify-content-between pl-4 pl-lg-0">
              <div class="navbar-collapse-logo">
                <img src="assets/images/core/vpacslogo-rec.svg" style="height: 64px" alt="" />
              </div>
              <button
                class="navbar-toggler close-button"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="mdi mdi-close navbar-toggler-icon pl-5"></span>
              </button>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="./">Trang Chủ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/upload">Tải Dữ Liệu Lên</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/worklist">Danh Sách Nghiên Cứu</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/guest-viewer">Trình Xem DICOM</a>
            </li>
            <li class="nav-item btn-contact-us pl-4 pl-lg-0">
              <app-language-dropdown />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <router-outlet (activate)="onActivate($event)"></router-outlet>

  <section class="contact-us" id="contact-section">
    <div class="contact-us-bgimage grid-margin">
      <div class="pb-4">
        <h4 class="px-3 px-md-0 m-0" data-aos="fade-down">Bạn có câu hỏi nào không?</h4>
        <h4 class="pt-1" data-aos="fade-down">Liên hệ với chúng tôi</h4>
      </div>
      <div data-aos="fade-up">
        <button class="btn btn-rounded btn-outline-danger">Liên hệ</button>
      </div>
    </div>
  </section>
  <section class="contact-details container-fluid" id="contact-details-section">
    <div class="row text-center text-md-left">
      <div class="col-12 col-md-6 col-lg-3 grid-margin">
        <img src="assets\images\core\radteam-logo-19.png" style="height: 123px" alt="" class="pb-2" />
        <div class="pt-2">
          <p class="text-muted m-0">radteam.vpacs&#64;gmail.com</p>
          <!-- <p class="text-muted m-0">906-179-8309</p> -->
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 grid-margin">
        <h5 class="pb-2">Liên Lạc</h5>
        <p class="text-muted">Đừng bỏ lỡ các bản cập nhật về các mẫu và tiện ích mở rộng mới của chúng tôi.!</p>
        <form>
          <input type="text" class="form-control" id="Email" placeholder="Email của bạn" />
        </form>
        <div class="pt-3">
          <button class="btn btn-dark">Đăng Ký</button>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 grid-margin">
        <h5 class="pb-2">Hướng Dẫn</h5>
        <a routerLink="./terms"><p class="m-0 pb-2">Điều Khoản</p></a>
        <a routerLink="./privacy"><p class="m-0 pt-1 pb-2">Chính Sách Bảo Mật</p></a>
        <a routerLink="./cookies"><p class="m-0 pt-1 pb-2">Chính Sách Cookie</p></a>
      </div>
      <div class="col-12 col-md-6 col-lg-3 grid-margin">
        <h5 class="pb-2">Liên Hệ</h5>
        <p class="text-muted">Email: radteam.vpacs&#64;gmail.com</p>
        <div class="d-flex justify-content-center justify-content-md-start"></div>
      </div>
    </div>
  </section>
  <footer class="border-top">
    <p class="text-center text-muted pt-4">Bản quyền © 2023<a href="#" class="px-1">RADTeam.</a>Tất cả quyền được bảo lưu.</p>
  </footer>
  <!-- Modal for Contact - us Button -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">Liên Hệ</h4>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="Name">Tên</label>
              <input type="text" class="form-control" id="Name" placeholder="Tên" />
            </div>
            <div class="form-group">
              <label for="Email">Email</label>
              <input type="email" class="form-control" id="Email-1" placeholder="Email" />
            </div>
            <div class="form-group">
              <label for="Message">Thông Điệp</label>
              <textarea class="form-control" id="Message" placeholder="Nhập thông điệp của bạn"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Đóng</button>
          <button type="button" class="btn btn-success">Gửi</button>
        </div>
      </div>
    </div>
  </div>
</div>
