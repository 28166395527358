import { Injectable } from '@angular/core';
import { Logger, Severity, SharedService } from '@app/@shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { firstValueFrom, mergeMap } from 'rxjs';
import { volumeActions } from './cornerstone.actions';
import { CornerstoneMetadata, CornerstoneService, DicomLoaderService, MODALITY_NAME } from '@app/@core';
import { ViewerLayoutQuery, layoutActions } from '../layout';
import { Store } from '@ngrx/store';
import { ViewerGeneralQuery, viewerFileActions } from '../general';
import { ViewerMode, DEFAULT_PANEL_INDEX, FUSION_TOTAL_SERIES, SERIES_UID_VOLUME_PATTERN, VOLUME_MINUMUM_IMAGES } from '@app/viewer/contants';
import { CornerstoneQuery } from './cornerstone.selectors';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { viewerMenuActions } from '../menu/menu.actions';
import { metaData, cache as cs3DCache } from '@cornerstonejs/core';

const log = new Logger('CornerstoneEffects');
@Injectable()
export class CornerstoneEffects {
  constructor(
    private actions$: Actions,
    private cornerstoneService: CornerstoneService,
    private store: Store,
    private sharedService: SharedService,
    private translate: TranslateService,
    private dicomLoaderService: DicomLoaderService,
  ) {}

  // initVolumeDefault$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(viewerFileActions.loadSuccess),
  //       concatLatestFrom(() => this.store.select(CornerstoneQuery.selectVolumeIds)),
  //       mergeMap(async ([action, volumeIds]) => {
  //         try {
  //           //Check if the volume is already created
  //           const seriesUids: string[] = action.payload.seriesInfos.map((series) => series.uid);

  //           let modalities: string[] = [];
  //           const currentVolumeIds = cloneDeep(volumeIds);

  //           //Case 1: The series is not create and loaded volume, we create the volume for this series
  //           const newVolume: any[] = [];
  //           //Create new volume and store uid
  //           for (let index = 0; index < seriesUids.length; index++) {
  //             const seriesUid = seriesUids[index];
  //             const stackImage = action.payload.stackImages.find((stack) => stack.uid === seriesUid);
  //             debugger;
  //             const modality = stackImage?.modality;
  //             if (modality === MODALITY_NAME.PT) {
  //               const instanceMetaData = metaData.get(
  //                 CornerstoneMetadata.SCALING_MODLUE,
  //                 stackImage?.imagePrefetch[0].imageId,
  //               );
  //               if (instanceMetaData === undefined) {
  //                 this.dicomLoaderService.getPTInstanceMetadata(
  //                   stackImage?.imagePrefetch.map((img) => img.imageId) || [],
  //                 );
  //               }
  //             }
  //             modalities.push(modality || MODALITY_NAME.CT);
  //             const volume = await this.cornerstoneService.createAndCacheVolume(
  //               SERIES_UID_VOLUME_PATTERN(seriesUid, modality || MODALITY_NAME.CT),
  //               stackImage?.imagePrefetch.map((img) => img.imageId) || [],
  //             );
  //             newVolume.push(volume);
  //             currentVolumeIds.push(volume.volumeId);
  //           }
  //           newVolume.map((volume) => volume.load());
  //           return volumeActions.initVolumeSuccess({ volumeIds: currentVolumeIds, modality: modalities });
  //         } catch (err) {
  //           log.error(err);
  //           return volumeActions.createVolumeFail({ error: err });
  //         }
  //       }),
  //     );
  //   },
  //   { functional: true, dispatch: true },
  // );

  /**
   * Effect that creates a volume based on the specified series UIDs and mode.
   * @returns An observable that emits actions for creating the volume.
   */
  createVolume$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.createVolume),
        concatLatestFrom(() => this.store.select(CornerstoneQuery.selectVolumeIds)),
        mergeMap(async ([action, volumeIds]) => {
          try {
            //Check if the volume is already created
            const seriesUids: string[] = [];
            if (action.mode === ViewerMode.MPR && !volumeIds.includes(action.seriesUid[0])) {
              //check mpr volume is create or not
              seriesUids.push(action.seriesUid[0]);
            } else {
              //Fusion mode
              action.seriesUid.forEach((uid) => {
                if (!volumeIds.includes(uid)) {
                  seriesUids.push(uid);
                }
              });
            }
            let modalities: string[] = [];
            const currentVolumeIds = cloneDeep(volumeIds);
            if (seriesUids.length > 0) {
              //Case 1: The series is not create and loaded volume, we create the volume for this series
              const newVolume: any[] = [];
              //Create new volume and store uid
              for (let index = 0; index < seriesUids.length; index++) {
                const seriesUid = seriesUids[index];
                const stackImage = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUId(seriesUid)));
                const imageIds = stackImage?.imagePrefetch.map((img) => img.imageId) || [];

                if (imageIds.length < VOLUME_MINUMUM_IMAGES) {
                  this.sharedService.toastMessage(Severity.error, this.translate.instant('VolumeMinimumImages'));
                  return volumeActions.createVolumeFail({ error: 'VolumeMinimumImages' });
                }
                // remove images from the cache to be able to re-load them
                imageIds.forEach((imageId) => {
                  if (cs3DCache.getImageLoadObject(imageId)) {
                    cs3DCache.removeImageLoadObject(imageId);
                  }
                });

                const modality = stackImage?.modality;
                if (modality === MODALITY_NAME.PT) {
                  const instanceMetaData = metaData.get(CornerstoneMetadata.SCALING_MODLUE, stackImage?.imagePrefetch[0].imageId);
                  if (instanceMetaData === undefined) {
                    this.dicomLoaderService.getPTInstanceMetadata(imageIds);
                  }

                  // const test: any[] = [];
                  // stackImage?.imagePrefetch.map((img) => {
                  //   const instanceMetaData = metaData.get(CornerstoneMetadata.SCALING_MODLUE, img.imageId);
                  //   test.push(instanceMetaData);
                  // });
                }
                modalities.push(modality || MODALITY_NAME.CT);
                // this shouldn't be via removeVolumeLoadObject, since that will
                // remove the texture as well, but here we really just need a remove
                // from registry so that we load it again
                const volumeId = SERIES_UID_VOLUME_PATTERN(seriesUid, modality || MODALITY_NAME.CT);
                // //@ts-ignore
                // cs3DCache._volumeCache.delete(volumeId);
                const volume = await this.cornerstoneService.createAndCacheVolume(volumeId, imageIds);
                newVolume.push(volume);
                currentVolumeIds.push(volume.volumeId);
              }
              if (!modalities.includes(MODALITY_NAME.PT) && !modalities.includes(MODALITY_NAME.CT) && action.mode === ViewerMode.Fusion) {
                this.sharedService.toastMessage(Severity.error, this.translate.instant('FusionModalityNotSupport'));
                cs3DCache.purgeVolumeCache();
                return volumeActions.createVolumeFail({ error: 'FusionModalityNotSupport' });
              }
              newVolume.map((volume) => volume.load());
            } else {
              //Case 2: the volume is loaded
              //get current modality of volume
              const stackImages = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUIds(action.seriesUid)));
              modalities = [...modalities, ...stackImages.map((stack) => stack.modality)];
            }
            return volumeActions.createVolumeSuccess({
              volumeIds: currentVolumeIds,
              modality: modalities,
              mode: action.mode,
              seriesUids: action.seriesUid,
            });
          } catch (err) {
            log.error(err);
            return volumeActions.createVolumeFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles entering MPR (Multi-Planar Reconstruction) mode.
   * This effect listens for the `enterMPRMode` action and performs the necessary operations to enter MPR mode.
   * It selects the active stack from the store, checks the viewer mode, and creates a volume for MPR if necessary.
   * If any error occurs during the process, it dispatches a `createVolumeFail` action with the error information.
   *
   * @returns An observable that emits actions based on the logic inside the effect.
   */
  enterMPRMode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.enterMPRMode),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          try {
            const viewerMode = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectViewerMode));
            if (viewerMode === ViewerMode.MPR) {
              return viewerMenuActions.onMPRDeactive();
            }
            if (activeStack === undefined || activeStack.seriesUid === undefined || activeStack.seriesUid === '') {
              this.sharedService.toastMessage(Severity.error, this.translate.instant('MPRInputImage'));
              return volumeActions.createVolumeFail({ error: 'Not Input Image' });
            }
            //this.sharedService.preloader(true, this.translate.instant('MPRLoading'));
            return volumeActions.createVolume({
              mode: ViewerMode.MPR,
              seriesUid: [activeStack.seriesUid],
            });
          } catch (err) {
            log.error(err);
            return volumeActions.createVolumeFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles entering fusion mode.
   * This effect listens for the `enterFusionMode` action and performs the necessary operations to enter fusion mode.
   * It retrieves the active stack and viewer mode, checks for input images, and creates a volume for fusion mode.
   * If any error occurs during the process, it dispatches a `createVolumeFail` action with the corresponding error.
   *
   * @returns An observable that emits actions based on the logic described above.
   */
  enterFusionMode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.enterFusionMode),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          try {
            const viewerMode = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectViewerMode));
            if (viewerMode === ViewerMode.Fusion) {
              return viewerMenuActions.onFusionDeactive();
            }
            if (activeStack === undefined || activeStack.seriesUid === undefined || activeStack.seriesUid === '') {
              this.sharedService.toastMessage(Severity.error, this.translate.instant('FusionNoInputImage'));
              return volumeActions.createVolumeFail({ error: 'Not Input Image' });
            }
            let seriesUids: string[] = [];
            //Get current seriesUID in active panel
            const activePanel = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActivePanel));
            const stackImages = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewportByPanelIndex(activePanel?.panelIndex || DEFAULT_PANEL_INDEX)));
            if (stackImages.length === FUSION_TOTAL_SERIES) {
              seriesUids = stackImages.map((stack) => stack.seriesUid);
            } else {
              //Get current selected seriesUID
              const selectedStack = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectSelectedStack));
              if (selectedStack.length !== FUSION_TOTAL_SERIES) {
                this.sharedService.toastMessage(Severity.error, this.translate.instant('FusionTotalSeriesMustEqual2'));
                return volumeActions.createVolumeFail({ error: 'FusionTotalSeriesMustEqual2' });
              }

              seriesUids = selectedStack.map((stack) => stack.seriesUid);
            }

            //this.sharedService.preloader(true, this.translate.instant('FusionLoading'));
            return volumeActions.createVolume({
              mode: ViewerMode.Fusion,
              seriesUid: seriesUids,
            });
          } catch (err) {
            log.error(err);
            return volumeActions.createVolumeFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );
}
