/// <reference types="gapi" />
/// <reference types="gapi.client" />
/// <reference types="gapi.client.drive-v3" />
/// <reference types="gapi.client.sheets-v4" />
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AxiosService, DRIVE_MIMETYPE, GOOGLE_API_ENDPOINT } from '@app/@core';
import { Logger } from '@shared';
import { AxiosHeaders, AxiosResponse } from 'axios';
import * as _ from 'lodash';
const log: Logger = new Logger('driveService');

@Injectable({ providedIn: 'root' })
export class GoogleDriveService {
  constructor(
    private axios: AxiosService,
    private http: HttpClient,
  ) {}

  /**
   * Prints metadata about a file. This is a convenience method for Google Drive API calls that require a file ID to be provided in the request.
   *
   * @param {string} fileId - The ID of the file to print metadata for.
   *
   * @return { Promise<any> } A promise to print the file metadata on success or reject with an error
   */
  public async printFileMetadata(fileId: string): Promise<gapi.client.drive.File> {
    // Get metadata for file. Resolves with metadata for file or rejects with error
    const res = await gapi.client.drive.files.get({
      fileId,
      alt: 'media',
    });
    if (res.result) {
      return res.result;
    }
    return {};
  }

  /**
   * Retrieves the web content link for a given drive ID.
   * @param driveId The ID of the drive.
   * @returns A promise that resolves to the web content link, or an empty string if not found.
   */
  public async getWebcontentLink(driveId: string): Promise<string> {
    const res = await gapi.client.drive.files.get({
      fileId: driveId,
      fields: 'webContentLink',
    });
    return res.result.webContentLink || '';
  }

  /**
   * Shares a file and returns its shareable link.
   * @param fileId The ID of the file to share.
   * @returns A promise that resolves to the shareable link of the file.
   */
  public async shareFile(fileId: string): Promise<string> {
    try {
      // Create a permission to share the file.
      const id = await gapi.client.drive.permissions.create({
        fileId: fileId,
        resource: {
          type: 'anyone'.trim(),
          role: 'reader'.trim(),
        },
      });

      return JSON.stringify(id.result);
    } catch (error) {
      console.error('Error sharing file:', error);
      return '';
    }
  }

  /**
   * Unshares a file or folder on Google Drive.
   * @param fileId The ID of the file or folder to unshare.
   * @returns A promise that resolves to a boolean indicating whether the unsharing was successful.
   */
  public async unshareFileOrFolder(fileId: string, shareId: string): Promise<boolean> {
    try {
      await gapi.client.drive.permissions.delete({
        fileId: fileId,
        permissionId: shareId,
      });
      return true;
    } catch (error) {
      log.error(error);
      return false;
    }
  }

  /**
   * Get Google Drive folder by ID. This is a low - level method and should not be used by third - party code.
   *
   * @param {string} folderId - ID of the folder to get. Must be in the format ` folders / { folder } `.
   *
   * @return  { Promise<any> } Promise to be fulfilled with Google Drive folder
   */
  public async getGoogleDriveFolderById(folderId: string): Promise<gapi.client.drive.FileList> {
    try {
      const params = {
        q: `'${folderId}' in parents and trashed = false`,
        orderBy: 'modifiedTime',
        spaces: 'drive',
        pageSize: 100,
        fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, size)',
      };
      const res = await gapi.client.drive.files.list(params);
      return res.result;
    } catch (error) {
      log.error(error);
      return {};
    }
  }

  /**
   * Uploads a file to Google Drive. This is a low - level method for use with reports that don't require an API key.
   *
   * @param {string} folderID - The ID of the folder to upload to.
   * @param {string} content The string to write to the file.
   * @param {string} filename The name of the file to create.
   * @param {string} extension A string containing the specified file extension
   * @param {string} mimeType Indicates the MIME type of created file/folder/object/etc request.
   *
   * @return { Promise<AxiosResponse> } Resolves with the file's ID
   */
  public async uploadFile(folderID: string, content: Object | string, filename: string, extension: string, mimeType: string): Promise<AxiosResponse> {
    const file = content instanceof Object ? new Blob([JSON.stringify(content)], { type: mimeType }) : new Blob([content], { type: mimeType });
    const metadata = {
      name: `${filename}${extension}`, // Filename at Google Drive
      mimeType: mimeType, // mimeType at Google Drive
      parents: [folderID], // Folder ID at Google Drive
    };
    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: DRIVE_MIMETYPE.JSON }));
    form.append('file', file);
    const headers = new AxiosHeaders();
    headers.setAuthorization(`Bearer ${gapi.auth.getToken().access_token}`);
    const response = await this.axios.post(GOOGLE_API_ENDPOINT.UPLOAD, form, { headers });
    return response;
  }

  /**
   * Download a file from a URL. This is a low - level function to be used by clients that want to download files from the server.
   *
   * @param {string}  url - The URL to download from. Should be a file in the format / bucket / filename. ext
   * @param {string} credentials : Google jwt access token
   * @return { Promise<AxiosResponse> } file blob data
   */
  public async downloadFile(url: string, credentials: string): Promise<AxiosResponse> {
    const headers = new AxiosHeaders();
    headers.setAuthorization(`Bearer + ${credentials}`);
    const response = await this.axios.get(url, { headers, responseType: 'json' });
    return response;
  }

  /**
   * Retrieves the content of a file from Google Drive.
   * @param id The ID of the file to retrieve.
   * @returns A Promise that resolves to the file content, or undefined if an error occurs.
   */
  public async getFileContent(id: string): Promise<any> {
    try {
      const res = await gapi.client.drive.files.get({
        fileId: id,
        alt: 'media',
      });
      return res.result;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Get image content (string) from google drive
   * @param id image drive id
   * @returns binary image as string
   */
  public async getImageContent(id: string): Promise<string> {
    try {
      const res = await gapi.client.drive.files.get({
        fileId: id,
        alt: 'media',
      });

      const imageBody = res.body;
      if (imageBody) {
        //binary to base64
        // const base64 = btoa(new Uint8Array(imageBody).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        // return base64;
        return imageBody;
      }
      return '';
    } catch (error) {
      log.error(error);
      return '';
    }
  }

  /**
   * Get ID of file or folder. Will be'' if file or folder doesn't exist
   *
   * @param {string} name - Name of the file or folder
   * @param {string} mimeType Indicates the MIME type of created file/folder/object/etc request.
   * @param {string} parentId google drive id of current parent folder
   *
   * @return { Promise<string> } The ID of the file or folder.
   */
  public async getFileOrFolderId(name: string, mimeType: string, parentId: string = 'root'): Promise<string> {
    const params = {
      q: `'${parentId}' in parents and mimeType = '${mimeType}' and trashed = false and name = '${name}'`,
      orderBy: 'modifiedTime',
      spaces: 'drive',
      pageSize: 100,
      fields: 'nextPageToken, files(id, name, parents)',
    };

    const response = await gapi.client.drive.files.list(params);
    if (response.result.files && response.result.files.length > 0) {
      return response.result.files[0].id !== undefined ? response.result.files[0].id : '';
    }
    return '';
  }

  /**
   * Creates a Google Drive folder. This is a low - level method and should not be used on production servers.
   *
   * @param {string} name - The name of the folder to create. Must be unique within the project.
   * @param {string} folderId The IDs of the parent folders which contain the file. If not specified as part of a create request, the file is placed directly in the user's My Drive folder.
   *
   * @return { Promise<string> } The drive Id of created folder
   */
  public async CreateFolder(name: string, folderId: string = 'root'): Promise<string> {
    const fileBody = {
      name,
      mimeType: DRIVE_MIMETYPE.FOLDER,
      parents: [folderId],
    };
    const res = await gapi.client.drive.files.create({ prettyPrint: true }, fileBody);
    if (res.result) {
      return res.result.id !== undefined ? res.result.id : '';
    }
    return '';
  }

  /**
   * Create and upload file into Drive
   * @param {string} name file name
   * @param {string} folderId google drive folder id store file
   * @param {string} mimeType Indicates the MIME type of created file/folder/object/etc request.
   * @param {File} file Browser File Object
   * @returns { Promise<any> } The ID of the newly created file
   */
  public async CreateFile(name: string, folderId: string, mimeType: string = '', file?: File): Promise<any> {
    const metadata = {
      name, // Filename at Google Drive
      mimeType, // mimeType at Google Drive
      parents: [folderId], // Folder ID at Google Drive
    };
    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: DRIVE_MIMETYPE.JSON }));
    // Add a file to the form
    if (file) {
      form.append('file', new Blob([file], { type: file.type }));
    }

    const headers = {
      Authorization: `Bearer ${gapi.auth.getToken().access_token}`,
    };

    const response = await this.axios.post(GOOGLE_API_ENDPOINT.UPLOAD, form, { headers });
    return response.data.id;
  }

  /**
   * Deletes a file or folder from Google Drive.
   * @param id - The ID of the file or folder to be deleted.
   * @returns A promise that resolves to a boolean indicating whether the deletion was successful.
   */
  public async deleteFileOrFolder(id: string): Promise<boolean> {
    try {
      await gapi.client.drive.files.delete({ fileId: id });
      return true;
    } catch (error) {
      log.error(error);
      return false;
    }
  }

  /**
   * Finds the parent Drive ID of a given file.
   *
   * @param fileId - The ID of the file for which to find the parent Drive ID.
   * @returns A promise that resolves to the parent Drive ID as a string, or null if no parent is found or an error occurs.
   *
   * @throws Will log an error message to the console if the request fails.
   */
  public async findParentDriveId(fileId: string): Promise<string | null> {
    try {
      const file = await gapi.client.drive.files.get({
        fileId,
        fields: 'parents', // Only fetch parent information
      });

      // The 'parents' property may be an array or null
      const parents = file.result.parents;
      if (parents && parents.length > 0) {
        // Assuming a single parent, return the first one
        return parents[0];
      } else {
        return null; // No parent found
      }
    } catch (error) {
      console.error('Error finding parent Drive ID:', error);
      return null;
    }
  }
}
