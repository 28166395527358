import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { getDateTimeFormat } from '@app/@core';
import { IDBStudy } from '@app/@database';
import { selectClinicInfoByStudyUid, selectIsReadOnly } from '@app/report/store';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-clinic-info',
  templateUrl: './clinic-info.component.html',
  styleUrl: './clinic-info.component.scss',
})
export class ClinicInfoComponent implements OnChanges, OnInit {
  @Input() studyUid: string;

  protected clinicInfo$: Observable<IDBStudy | undefined>;
  protected dateTimeFormat: string = 'MM/DD/YYYY';
  protected readOnly$: Observable<boolean>;
  protected moment: any = moment;
  constructor(
    private translateService: TranslateService,
    private store: Store,
  ) {
    const currentLang = this.translateService.currentLang;
    this.dateTimeFormat = getDateTimeFormat(currentLang);
  }

  ngOnInit() {
    // Subscribe to the language change event to update the date time format.
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const lang = event.lang;
      this.dateTimeFormat = getDateTimeFormat(lang);
    });
    this.readOnly$ = this.store.select(selectIsReadOnly);
  }

  /**
   * Lifecycle hook that is called when one or more input properties of the component change.
   * @param changes - An object containing the changed properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.studyUid && changes.studyUid.currentValue !== undefined) {
      if (this.studyUid !== '') {
        this.clinicInfo$ = this.store.select(selectClinicInfoByStudyUid(this.studyUid));
      }
    }
  }
}
