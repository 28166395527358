/**
 * Represents the link element ID for the app theme.
 */
export const APP_THEME_LINK = 'app-theme';

/**
 * Represents the link element ID for the prime theme.
 */
export const PRIME_THEME_LINK = 'prime-theme';

/**
 * Represents the available themes.
 */
export enum Theme {
  Light,
  Dark,
}

export const THEME = {
  LIGHT: 'LightTheme',
  DARK: 'DarkTheme',
};

/**
 * Represents the dark theme configuration.
 */
export const DARK_THEME = {
  APP: 'assets/styles/dark-theme',
  PRIME: 'saga-blue-dark',
};

/**
 * Represents the light theme configuration.
 */
export const LIGHT_THEME = {
  APP: 'assets/styles/light-theme',
  PRIME: 'saga-blue-light',
};
