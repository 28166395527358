import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import vtkColormaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import IColorMapPreset from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import { utilities as cstUtils } from '@cornerstonejs/tools';
import { Threads } from '@app/@core';
import { BroadcastService } from '@app/viewer/services';
import { SharedService } from '@app/@shared';
const { Colorbar } = cstUtils.voi.colorbar;
const { ColorbarRangeTextPosition } = cstUtils.voi.colorbar.Enums;

@Component({
  selector: 'app-colorbar',
  templateUrl: './colorbar.component.html',
  styleUrl: './colorbar.component.scss',
})
export class ColorbarComponent implements OnChanges {
  protected colormaps: string[] = [];
  protected visible: boolean = false;
  @ViewChildren('pseudoColor') pseudoColorChildren: QueryList<ElementRef>;
  @Input() isDisplay: boolean;
  @Output() isDisplayChange = new EventEmitter<boolean>();

  constructor(
    private broadcastService: BroadcastService,
    private sharedService: SharedService,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisplay.currentValue) {
      this.visible = true;
    }
  }
  /**
   * Handles the logic for showing the dialog.
   * @protected
   */
  protected onShowDialog = async () => {
    if (this.colormaps.length === 0) {
      this.colormaps = vtkColormaps.rgbPresetNames;
      const colormapPresets = vtkColormaps.rgbPresetNames.map((presetName) => vtkColormaps.getPresetByName(presetName));
      await Threads.Instance.sleep(1000);
      for (const pseudoColor of this.pseudoColorChildren) {
        const children = pseudoColor.nativeElement.children;
        const colorbarElement: HTMLDivElement = children[0];

        const colorbar = new Colorbar({
          id: colorbarElement.id,
          colormaps: colormapPresets,
          activeColormapName: colorbarElement.id,
          container: colorbarElement,
          showFullPixelValueRange: true,
        });

        // Update colorBar direction
        const canvas = colorbarElement.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
        canvas.style.width = colorbarElement.offsetHeight + 'px';
        canvas.style.height = colorbarElement.offsetWidth + 'px';
        canvas.style.top = '50%';
        canvas.style.left = '50%';
        canvas.style.transform = 'translate(-50%, -50%) rotate(90deg)';
      }
    }
    this.sharedService.preloader(false);
  };

  /**
   * Event handler for color map change.
   * @param colormapName - The name of the new color map.
   */
  protected onColorMapChange = (colormapName: string) => {
    this.broadcastService.colormapBroadcast(colormapName);
  };

  /**
   * Callback function that is triggered when the dialog is hidden.
   */
  protected onHideDialog = () => {
    this.isDisplayChange.emit(false);
  };
}
