import { Study } from '@app/@core';
import { IStudyUpload, IStudyUploadInfo } from './IStudyUpload';
import { ISeriesUpload, ISeriesUploadInfo } from './ISeriesUpload';
import { SortOrder } from '@app/viewer/contants/sort';

/**
 * Represents a study upload.
 * @extends Study<ISeriesUpload>
 * @implements IStudyUpload
 */
export class StudyUpload extends Study<ISeriesUpload> implements IStudyUpload {
  /**
   * Creates an instance of StudyUpload.
   * @param {string} uid - The unique identifier of the study.
   */
  constructor(uid: string) {
    super(uid);
  }

  /**
   * Converts the study upload to upload data.
   * @returns {IStudyUploadInfo} The study upload data.
   */
  toUploadData(): IStudyUploadInfo {
    const series: ISeriesUploadInfo[] = [];
    this.series.forEach((value) => {
      series.push(value.toUploadData());
    });
    return {
      studyUid: this.uid,
      studyInfo: this.studyInfo,
      patientInfo: this.patientInfo,
      series,
    };
  }

  /**
   * Sorts the series by UID.
   * @param {SortOrder} [order] - The sort order. Defaults to ascending.
   */
  override sortByUid(order?: SortOrder): void {
    if (order === SortOrder.Ascending) {
      this.series.sort((a, b) => {
        if (a.metadata.seriesInstanceUID < b.metadata.seriesInstanceUID) {
          return -1;
        } else if (a.metadata.seriesInstanceUID > b.metadata.seriesInstanceUID) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this.series.sort((a, b) => {
        if (a.metadata.seriesInstanceUID > b.metadata.seriesInstanceUID) {
          return -1;
        } else if (a.metadata.seriesInstanceUID < b.metadata.seriesInstanceUID) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  /**
   * Sorts the series by series number.
   * @param {SortOrder} [order] - The sort order. Defaults to ascending.
   */
  override sortbySeriesNumber(order?: SortOrder): void {
    if (order === SortOrder.Ascending) {
      this.series.sort((a, b) => {
        if (a.metadata.seriesNumber < b.metadata.seriesNumber) {
          return -1;
        } else if (a.metadata.seriesNumber > b.metadata.seriesNumber) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this.series.sort((a, b) => {
        if (a.metadata.seriesNumber > b.metadata.seriesNumber) {
          return -1;
        } else if (a.metadata.seriesNumber < b.metadata.seriesNumber) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }
}
