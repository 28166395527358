import { Injectable } from '@angular/core';
import { FireStoreService } from './firestore.service';
import { COLLECTION, METADATA_KEY } from '@app/@core';
import { IDBStudy } from '../models/IStudy';
import { LazyLoadEvent } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { STUDY_COLLECTION_KEY } from '../constants/study';
import { ICondition } from '../models/IBase';

/**
 * Service for interacting with the Firestore database for Study collection.
 */
@Injectable({ providedIn: 'root' })
export class StudyFirebaseService {
  constructor(private firebase: FireStoreService) {}

  /**
   * Retrieves a study by its ID.
   * @param id - The ID of the study.
   * @returns A Promise that resolves to the study data.
   */
  public getStudyById = async (id: string): Promise<IDBStudy> => {
    const res = <IDBStudy>await this.firebase.getDataOfCollectionByKey(COLLECTION.STUDY, id);
    res.id = id;
    return res;
  };

  /**
   * Adds a new study to the Firestore database.
   * @param data - The study data to be added.
   * @returns A Promise that resolves to the added study data.
   */
  public addStudy = async (data: IDBStudy) => {
    const res = await this.firebase.addData(COLLECTION.STUDY, data);
    return res;
  };

  /**
   * Updates an existing study in the Firestore database.
   * @param data - The updated study data.
   * @param uid - The ID of the study to be updated.
   * @returns A Promise that resolves to the updated study data.
   */
  public updateStudy = async (data: IDBStudy, uid: string, userId: string) => {
    const res = await this.firebase.updateData(COLLECTION.STUDY, uid, data, userId);
    return res;
  };

  public updatePartialStudy = async (data: Partial<IDBStudy>, uid: string, userId: string): Promise<IDBStudy> => {
    const res = await this.firebase.updateData(COLLECTION.STUDY, uid, data, userId);
    return res as IDBStudy;
  };

  /**
   * Deletes a study from the Firestore database.
   * @param uid - The ID of the study to be deleted.
   * @param userID - The ID of the user performing the deletion.
   * @returns A Promise that resolves to the deletion result.
   */
  public deleteStudy = async (uid: string, userID: string) => {
    const res = await this.firebase.softDeleteData(COLLECTION.STUDY, uid, userID);
    return res;
  };

  /**
   * Deletes a study document from the Firestore database permanently.
   * @param {string} uid - The unique identifier of the study document to be deleted.
   * @returns {Promise<any>} - A promise that resolves with the result of the deletion operation.
   */
  public hardDeleteStudy = async (uid: string) => {
    const res = await this.firebase.deleteData(COLLECTION.STUDY, uid);
    return res;
  };

  /**
   * Retrieves studies by user ID.
   *
   * @param userId - The ID of the user.
   * @param options - Optional lazy load event options.
   * @returns A promise that resolves to an array of studies.
   */
  public getStudyByUserId = async (userId: string, options?: TableLazyLoadEvent, lastStudyId?: string) => {
    if (options) {
      // Calculate the page number based on the first and rows values
      let page = 1;
      if (options.first !== undefined && options.first > 0 && options.rows !== null && options.rows !== undefined && options.rows > 0) {
        page = options.first / options.rows + 1;
      }
      // Get the data chunk based on the page number and rows value
      const res = <IDBStudy[]>await this.firebase.getDatasOfCollectionByFieldValue(COLLECTION.STUDY, METADATA_KEY.CREATE_BY, userId, '==', 'desc', options.rows || 1, page, lastStudyId);
      return res;
    } else {
      // If no options are provided, get all data at once
      const res = <IDBStudy[]>await this.firebase.getDatasOfCollectionByFieldValue(COLLECTION.STUDY, METADATA_KEY.CREATE_BY, userId, '==', 'desc');
      return res;
    }
  };

  /**
   * Counts the number of studies associated with a specific user ID.
   *
   * @param userId - The ID of the user.
   * @returns A promise that resolves to the count of studies.
   */
  public countStudyByUserId = async (userId: string) => {
    const res = await this.firebase.countTotalRecord(COLLECTION.STUDY, METADATA_KEY.CREATE_BY, userId, '==');
    return res;
  };

  /**
   * Finds a study by its study instance UID.
   *
   * @param studyInstanceUid - The study instance UID to search for.
   * @param userID - The user ID associated with the study.
   * @returns A Promise that resolves to the study data if found, or undefined if not found.
   */
  public findStudyByStudyInstanceUid = async (studyInstanceUid: string, userID: string) => {
    const res = await this.firebase.getDataOfCollectionByFieldValue(COLLECTION.STUDY, STUDY_COLLECTION_KEY.STUDY_UID, studyInstanceUid, '==', userID);
    return res;
  };

  /**
   * Searches for studies based on the provided conditions.
   *
   * @param userId - The ID of the user performing the search.
   * @param condition - An array of conditions to filter the studies.
   * @returns A promise that resolves to an array of database studies.
   */
  public searchStudy = async (userId: string, condition: ICondition[]): Promise<IDBStudy[]> => {
    const res = <IDBStudy[]>await this.firebase.search(COLLECTION.STUDY, condition, userId, 'desc');
    return res;
  };

  /**
   * Checks if a study is shared by a specific user.
   *
   * @param studyId - The ID of the study to check.
   * @param userId - The ID of the user to check against.
   * @returns A promise that resolves to a boolean indicating whether the study is shared by the user.
   */
  public isSharedStudy = async (studyId: string, userId: string): Promise<boolean> => {
    const res = <IDBStudy>await this.firebase.getDataOfCollectionByKey(COLLECTION.STUDY, studyId);
    if (res) {
      const isSameUser = res.createBy === userId;
      if (isSameUser) {
        return res.isShared;
      }
      return false;
    }
    return false;
  };
}
