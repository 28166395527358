import { IGeneralStudy, IImagePrefetch, IPatientStudy } from '@app/@core';
import { ILayoutModalityItem } from '@app/setting';
import { ViewerMode } from '@app/viewer/contants/mode';
import { GoogleParams } from '@app/viewer/models/IInputLoad';
import { IMenuFusion, IMenuMPR } from '@app/viewer/models/IMenu';
import { IStoreViewer } from '@app/viewer/models/IStoreViewer';
import { ISeriesToStack, IViewportBackup } from '@app/viewer/models/IViewport';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

/**
 * Action group for viewer file actions.
 */
export const viewerFileActions = createActionGroup({
  source: 'Viewer File',
  events: {
    /**
     * Action to load files into the viewer.
     * @param files - The files to be loaded.
     */
    load: props<{ files: File[] }>(),

    /**
     * Action dispatched when loading files into the viewer is successful.
     * @param payload - The loaded study viewers.
     */
    loadSuccess: props<{ payload: IStoreViewer }>(),

    /**
     * Action dispatched when loading files into the viewer fails.
     * @param error - The error that occurred during loading.
     */
    loadFail: props<{ error: any }>(),
  },
});

/**
 * Action group for viewer drive actions.
 */
export const viewerDriveActions = createActionGroup({
  source: 'Viewer Drive',
  events: {
    /**
     * Action to load viewer drives.
     * @param driveIds - The drive IDs to load.
     */
    load: props<{ driveIds: GoogleParams }>(),
    /**
     * Action dispatched when viewer drives are successfully loaded.
     * @param payload - The loaded study viewers.
     */
    loadSuccess: props<{ payload: IStoreViewer }>(),
    /**
     * Action dispatched when loading viewer drives fails.
     * @param error - The error that occurred during loading.
     */
    loadFail: props<{ error: any }>(),
    //TODO: Improvement download multi file at time
    //downloadSuccess: props<{ uid: string; seriesUid: string; image: IImagePrefetch }>(),
    downloadSuccess: props<{ seriesUid: string; images: IImagePrefetch[] }>(),
    //downloadLowPrioritySuccess: props<{ uid: string; seriesUid: string; image: IImagePrefetch }>(),
    downloadLowPrioritySuccess: props<{ seriesUid: string; images: IImagePrefetch[] }>(),
    loadShareWithoutLogin: props<{ driveIds: GoogleParams }>(),
    loadShareWithLogin: props<{ driveIds: GoogleParams }>(),
    shareStudy: emptyProps(),
  },
});

/**
 * Action group for viewer-related actions.
 */
export const viewerGeneralActions = createActionGroup({
  source: 'Viewer',
  events: {
    startGuestMode: emptyProps(),
    startShareMode: props<{ params: GoogleParams }>(),
    initViewerData: props<{ layout: ILayoutModalityItem; displayViewport: ISeriesToStack[] }>(),
    /**
     * Action dispatched when the selected study is changed.
     * @param payload - The payload containing the general study information.
     */
    changeSelectedStudy: props<{ payload: IGeneralStudy[] }>(),
    replaceSeriesInStack: props<{ info: ISeriesToStack }>(),
    changeViewerMode: props<{ mode: ViewerMode }>(),
    backupDisplayViewport: props<{ viewports: ISeriesToStack[] }>(),
    restoreDisplayViewport: props<{ viewports: ISeriesToStack[] }>(),
    backupDisplayViewportFullImage: props<{
      viewports: ISeriesToStack[];
      currentViewport: ISeriesToStack;
      tileLayout: string;
    }>(),
    restoreDisplayViewportFullImage: props<{ viewports: ISeriesToStack[] }>(),
    changeDisplayViewportVolume: props<{
      mode: ViewerMode;
      displayViewport: ISeriesToStack[];
      config: IMenuFusion | IMenuMPR;
      oldStackLayout: string;
    }>(),
    changeDisplayVolume2D: props<{
      mode: ViewerMode;
      isDisplay: boolean;
    }>(),
    changeDisplayVolume2DSuccess: props<{
      mode: ViewerMode;
      isDisplay: boolean;
      displayViewport: ISeriesToStack[];
    }>(),
    activeFullImage: props<{ displayViewport: ISeriesToStack; tileLayout: string }>(),
    onDisplayFullImage: props<{ displayViewport: ISeriesToStack; tileLayout: string }>(),
    backupViewport: props<{ viewport: IViewportBackup }>(),
    changeDisplaySeries: props<{ currentStackIndex: string; displayViewport?: ISeriesToStack }>(),
    doNothing: emptyProps(),
    changeDisplayViewport: props<{ displayViewport: ISeriesToStack[] }>(),
  },
});

export const studyListAction = createActionGroup({
  source: 'StudyList',
  events: {
    studyDisplayCurrentLayout: props<{ studyUid: string[] }>(),
    studyDisplayCurrentLayoutSuccess: props<{ displayViewport: ISeriesToStack[] }>(),
    studyDisplayCurrentLayoutFail: props<{ error: any }>(),
    studyDisplayAllSeries: props<{ studyUid: string[] }>(),
    studyDisplayAllSeriesSuccess: props<{ displayViewport: ISeriesToStack[] }>(),
    studyDisplayAllSeriesFail: props<{ error: any }>(),
    studyDisplayBySetting: props<{ settingName: string; modality: string; selectedStudies: IGeneralStudy[] }>(),
    studyDisplayBySettingSuccess: props<{ displayViewport: ISeriesToStack[]; modalityLayout: ILayoutModalityItem }>(),
    studyDisplayBySettingFail: props<{ error: any }>(),
    // studyDisplayLastStack: props<{ studyUid: string[] }>(),
    // studyDisplayLastStackSuccess: props<{ displayViewport: ISeriesToStack[] }>(),
    // studyDisplayLastStackFail: props<{ error: any }>(),
  },
});
