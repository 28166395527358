import { Injectable } from '@angular/core';
import { Logger, Severity, SharedService } from '@app/@shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { firstValueFrom, mergeMap } from 'rxjs';
import { CornerstoneService } from '@app/@core';
import { Store } from '@ngrx/store';
import { viewerMenuActions } from './menu.actions';
import { ViewerLayoutQuery, layoutActions } from '../layout';
import { ViewerMenuQuery } from './menu.selectors';
import { ViewerMode } from '@app/viewer/contants/mode';
import { ViewerGeneralQuery, viewerGeneralActions } from '../general';
import { BroadcastService, ToolsService } from '@app/viewer/services';
import {
  PanTool,
  PlanarRotateTool,
  WindowLevelTool,
  ZoomTool,
  ArrowAnnotateTool,
  LengthTool,
  ProbeTool,
  RectangleROITool,
  EllipticalROITool,
  CircleROITool,
  BidirectionalTool,
  AngleTool,
  CobbAngleTool,
  PlanarFreehandROITool,
  MagnifyTool,
  StackScrollTool,
} from '@cornerstonejs/tools';
import { Annotate, Measure, Tools } from '@app/viewer/contants';
import { ISeriesToStack } from '@app/viewer/models';
import { cloneDeep } from 'lodash';
import RectangleAnnotateTool from '@app/viewer/helpers/annotation/RectangleAnnotateTool';
import EllipseAnnotateTool from '@app/viewer/helpers/annotation/EllipseAnnotateTool';
import RegionTool from '@app/viewer/helpers/annotation/RegionTool';
import { WindowService } from '@shared';
import { volumeActions } from '../cornerstone';

const log = new Logger('MenuEffects');
@Injectable()
export class MenuEffects {
  constructor(
    private actions$: Actions,
    private cornerstoneService: CornerstoneService,
    private toolService: ToolsService,
    private store: Store,
    private boardcastService: BroadcastService,
    private windowService: WindowService,
    private shared: SharedService,
  ) {}
  //#region  2d viewer
  /**
   * Effect that listens for the `onSortCommand` action and broadcasts the sort command using the `boardcastService`.
   */
  changeSortCommand$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onSortCommand),
        mergeMap(async (action) => {
          this.boardcastService.sortImageBroadcast(action.command);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Initializes the menu active mode effect.
   * This effect listens for the changeDisplayVolume2DSuccess action and updates the active mode accordingly.
   * If the action isDisplay property is true, the active mode is set to ViewerMode.Stack2D, otherwise it uses the mode from the action.
   * @returns An observable that emits the viewerMenuActions.changeActiveMode action.
   */
  initMenuActiveMode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.changeDisplayVolume2DSuccess),
        mergeMap(async (action) => {
          let mode = action.mode;
          if (action.isDisplay) {
            mode = ViewerMode.Stack2D;
          }
          return viewerMenuActions.changeActiveMode({ mode });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for the change of the active stack and dispatches an action to change the active mode in the viewer menu.
   * @returns An observable that emits the action to change the active mode in the viewer menu.
   */
  changeMenuActiveMode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeActiveStack),
        mergeMap(async (action) => {
          return viewerMenuActions.changeActiveMode({ mode: action.mode });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that changes the action mode when the application is initialized.
   * This effect listens for the 'changeDisplayViewportVolume' action and updates the active mode accordingly.
   * If the 'isDisplay2D' flag is true, it changes the active mode to 'ViewerMode.Stack2D'.
   * Otherwise, it changes the active mode to the mode specified in the action.
   */
  changeActionModeWhenInit$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.changeDisplayViewportVolume),
        mergeMap(async (action) => {
          if (action.config.isDisplay2D) {
            return viewerMenuActions.changeActiveMode({ mode: ViewerMode.Stack2D });
          } else {
            return viewerMenuActions.changeActiveMode({ mode: action.mode });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action to change the active WWWL (Window Width/Window Level) tool.
   * This effect performs the necessary operations to set the active tool and reset other tools.
   */
  changeWWWLActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onWWWLActive),
        mergeMap(async (action) => {
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
          this.toolService.setActiveStackTool(WindowLevelTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the activation of the WWWL region tool.
   * This effect sets the default tool stack, deactivates all annotate and measure stacks,
   * and activates the WWWL region tool.
   */
  onWWWLRegionActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onWWWLActiveByROI),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectActiveWWWLByROI)),
        mergeMap(async ([action, isActive]) => {
          if (isActive) {
            const toolName = RegionTool.toolName;
            this.toolService.setDefaultToolStack();
            this.toolService.passiveAllAnnotateMesuareStack();
            this.toolService.setActiveStackTool(toolName);
            return viewerGeneralActions.doNothing();
          } else {
            return viewerMenuActions.onSelect();
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action to change the zoom level.
   * This effect sets the necessary tool service properties to handle zooming.
   */
  changeZoomActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onZoom),
        mergeMap(async (action) => {
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
          this.toolService.setActiveStackTool(ZoomTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the action to change to the pan tool.
   * This effect sets the necessary tool configurations to activate the pan tool.
   */
  changePanActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onPan),
        mergeMap(async (action) => {
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
          this.toolService.setActiveStackTool(PanTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that is triggered when the viewer menu is scrolled.
   * It performs certain actions when the scroll event occurs.
   */
  changeScrollActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onScroll),

        mergeMap(async (action) => {
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
          this.toolService.enableCursor(StackScrollTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the action to change the active rotation tool.
   * This effect sets the necessary tool stacks and activates the PlanarRotateTool.
   */
  changeRotateActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onRotate),
        mergeMap(async (action) => {
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
          this.toolService.setActiveStackTool(PlanarRotateTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that is triggered when a menu item is selected.
   * It performs some actions related to the selected menu item.
   */
  changeSelectActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onSelect),
        mergeMap(async (action) => {
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  // /**
  //  * Effect that listens for `changeActiveStack` actions and dispatches a `changeActiveStack` action with the provided `toolState`.
  //  */
  // changeActiveStack$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(layoutActions.changeActiveStack),
  //       mergeMap(async (action) => {
  //         return viewerMenuActions.changeActiveStack({ toolState: action.toolState });
  //       }),
  //     );
  //   },
  //   { functional: true, dispatch: true },
  // );

  /**
   * Effect that handles the activation of an annotation tool.
   * This effect listens for the `onAnnotate` action and activates the corresponding annotation tool.
   * It also disables all other tools.
   */
  activeAnnotate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onAnnotate),
        mergeMap(async (action) => {
          let toolName = '';
          this.toolService.setDefaultToolStack();
          this.toolService.passiveAllAnnotateMesuareStack();
          switch (action.name) {
            case Annotate.Arrow:
              toolName = ArrowAnnotateTool.toolName;
              break;
            case Annotate.Rectangle:
              toolName = RectangleAnnotateTool.toolName;
              break;
            case Annotate.Ellipse:
              toolName = EllipseAnnotateTool.toolName;
              break;
            default:
              break;
          }
          this.toolService.setActiveStackTool(toolName);
          return viewerMenuActions.disableAllTool();
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the activation of a measure tool based on the dispatched action.
   * It sets the active tool stack and disables all other tools.
   *
   * @returns An observable that emits the action to disable all tools.
   */
  activeMeasure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onMeasure),
        mergeMap(async (action) => {
          let toolName = '';
          this.toolService.passiveAllAnnotateMesuareStack();
          this.toolService.setDefaultToolStack();
          switch (action.name) {
            case Measure.Length:
              toolName = LengthTool.toolName;
              break;
            case Measure.Angle:
              toolName = AngleTool.toolName;
              break;
            case Measure.ROIEllipse:
              toolName = EllipticalROITool.toolName;
              break;
            case Measure.ROIRectangle:
              toolName = RectangleROITool.toolName;
              break;
            case Measure.Bidirectional:
              toolName = BidirectionalTool.toolName;
              break;
            case Measure.CircleROI:
              toolName = CircleROITool.toolName;
              break;
            case Measure.PlannarFreehandROI:
              toolName = PlanarFreehandROITool.toolName;
              break;
            case Measure.Intensity:
              toolName = ProbeTool.toolName;
              break;
            case Measure.CobbAngle:
              toolName = CobbAngleTool.toolName;
              break;
            default:
              toolName = LengthTool.toolName;
              break;
          }
          this.toolService.setActiveStackTool(toolName);
          return viewerMenuActions.disableAllTool();
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that triggers the magnify tool based on the isActiveMagnify state.
   */
  onTriggerMagnify$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.triggerMagnify),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectMagnify)),
        mergeMap(async ([action, isActiveMagnify]) => {
          this.toolService.setDefaultToolStack();
          this.toolService.passiveAllAnnotateMesuareStack();
          if (isActiveMagnify) {
            this.toolService.setActiveStackTool(MagnifyTool.toolName);
          }
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that listens for the changeDisplayReferenceLine action and updates the state of the reference line in the tool service.
   * @returns An observable that emits the changeDisplayReferenceLine action and the current state of the display reference line.
   */
  onChangeDisplayReferenceLine$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.changeDisplayReferenceLine),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectDisplayReferenceLine)),
        mergeMap(async ([action, isActvice]) => {
          this.toolService.setStateReferenceLine(isActvice);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the action to navigate to the next series.
   * It retrieves the active stack, current display viewport, and series information from the store.
   * If the current series is the last one, it displays a toast message and does nothing.
   * Otherwise, it updates the display viewport with the next series and dispatches the changeDisplaySeries action.
   *
   * @returns An observable that emits the changeDisplaySeries action.
   */
  onNextSeries$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.nextSeries),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          // Get the current stack index, display viewport, and series information
          const currentStackIndex = activeStack?.stackIndex || '0-0';
          // Get the current display viewport
          const currentDisplayViewport = <ISeriesToStack>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewportByStackIndex(currentStackIndex)));
          const seriesInfos = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSeriesInfos));
          const currentSeriesIndex = seriesInfos.findIndex((series) => series.uid === currentDisplayViewport.seriesUid);
          // If the current series is the last one, display a toast message and do nothing
          if (currentSeriesIndex === seriesInfos.length - 1) {
            this.shared.toastMessage(Severity.info, 'LastSeries');
            return viewerGeneralActions.doNothing();
          }
          // Get the next series
          const nextSeries = seriesInfos[currentSeriesIndex + 1];
          // Create the next display viewport
          const nextDisplayViewport = cloneDeep(currentDisplayViewport);
          nextDisplayViewport.seriesUid = nextSeries.uid;

          return viewerGeneralActions.changeDisplaySeries({ currentStackIndex, displayViewport: nextDisplayViewport });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action to navigate to the previous series.
   * It retrieves the current stack index, display viewport, and series information,
   * determines the current series index, and creates the next display viewport
   * with the previous series. If the current series is the first one, it displays
   * a toast message and does nothing.
   *
   * @returns An observable of the action to change the display series.
   */
  onPrevSeries$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.prevSeries),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          // Get the current stack index, display viewport, and series information
          const currentStackIndex = activeStack?.stackIndex || '0-0';
          const currentDisplayViewport = <ISeriesToStack>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewportByStackIndex(currentStackIndex)));
          const seriesInfos = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSeriesInfos));
          // Get the current series index
          const currentSeriesIndex = seriesInfos.findIndex((series) => series.uid === currentDisplayViewport.seriesUid);
          // If the current series is the first one, display a toast message and do nothing
          if (currentSeriesIndex === 0) {
            this.shared.toastMessage(Severity.info, 'FirstSeries');
            return viewerGeneralActions.doNothing();
          }
          // Get the previous series
          const nextSeries = seriesInfos[currentSeriesIndex - 1];
          // Create the next display viewport
          const nextDisplayViewport = cloneDeep(currentDisplayViewport);
          nextDisplayViewport.seriesUid = nextSeries.uid;

          return viewerGeneralActions.changeDisplaySeries({ currentStackIndex, displayViewport: nextDisplayViewport });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that triggers when the closeSeries action is dispatched.
   * It selects the active stack and changes the display series based on the current stack index.
   */
  onCloseSeries$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.closeSeries),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          const currentStackIndex = activeStack?.stackIndex || '0-0';
          // Close the current series
          return viewerGeneralActions.changeDisplaySeries({ currentStackIndex });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  // /**
  //  * Effect that handles the action to open a report.
  //  * This effect performs some asynchronous operations.
  //  */
  // onOpenReport$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(viewerMenuActions.openReport),
  //       mergeMap(async (action) => {}),
  //     );
  //   },
  //   { functional: true, dispatch: false },
  // );

  /**
   * Effect that handles the action to paste a key image in full size.
   * This effect broadcasts the key image to the boardcast service.
   */
  onPasteKeyImageFull$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.pasteKeyImageFull),
        mergeMap(async (action) => {
          this.boardcastService.keyImageBroadcast(true);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the action for pasting a key image region.
   *
   * @returns An observable that emits actions based on the logic inside the effect.
   */
  onPasteKeyImageRegion$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.pasteKeyImageRegion),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectPasteKeyImageRegion)),
        mergeMap(async ([action, isActive]) => {
          this.toolService.setDefaultToolStack();
          this.toolService.passiveAllAnnotateMesuareStack();
          if (isActive) {
            const toolName = RegionTool.toolName;
            this.toolService.setActiveStackTool(toolName);
            return;
          } else {
            return viewerMenuActions.disableAllTool();
          }
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that triggers when the 'openReport' action is dispatched.
   * Opens a report using the provided parameters.
   */
  onReportOpen$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.openReport),

        mergeMap(async (action) => {
          this.windowService.openReport(action.params, action.isShareMode);
        }),
      );
    },
    { functional: true, dispatch: false },
  );
  //#endregion

  //#region Volume viewer
  /**
   * Effect that listens for the changeDisplayCrosshair action and updates the state of the crosshair tool.
   */
  onChangeDisplayCrosshair$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.changeDisplayCrosshair),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectDisplayCrosshair)),
        mergeMap(async ([action, isActvice]) => {
          const currentTool = await firstValueFrom(this.store.select(ViewerMenuQuery.selectActiveTool));
          if (currentTool === Tools.Select) {
            this.toolService.setStateCrosshair(isActvice);
          }
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that triggers when the MPR (Multi-Planar Reconstruction) mode is activated in the viewer menu.
   * Dispatches the `enterMPRMode` action from the `volumeActions` module.
   */
  onMPRActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onMPRActive),
        mergeMap(async (action) => {
          return volumeActions.enterMPRMode();
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that triggers when the fusion mode is activated in the viewer menu.
   * Dispatches the `enterFusionMode` action from the `volumeActions` module.
   */
  onFusionActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onFusionActive),
        mergeMap(async (action) => {
          return volumeActions.enterFusionMode();
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for the `onMPRLayoutChange` action and dispatches the `changeVolumeLayout` action.
   * @returns An observable that emits the `changeVolumeLayout` action.
   */
  changeMPRLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onMPRLayoutChange),
        mergeMap(async (action) => {
          return layoutActions.changeVolumeLayout({ tileLayout: action.layout });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for changes in MPR visibility in 2D layout and dispatches an action to change the display volume in 2D.
   *
   * @returns An observable that emits the action to change the display volume in 2D.
   */
  changeMPR2DLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onMPRVisible2DChange),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectMprDisplay2D)),
        mergeMap(async ([action, display2D]) => {
          return viewerGeneralActions.changeDisplayVolume2D({ mode: ViewerMode.MPR, isDisplay: display2D });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for the `onFusionLayoutChange` action and dispatches the `changeVolumeLayout` action with the specified tile layout.
   * @returns An observable that emits the `changeVolumeLayout` action.
   */
  changeFusionLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onFusionLayoutChange),
        mergeMap(async (action) => {
          return layoutActions.changeVolumeLayout({ tileLayout: action.layout });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for changes in the fusion visibility and MPR display mode,
   * and dispatches an action to change the display volume in 2D fusion mode.
   */
  changeFusion2DLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onFusionVisible2DChange),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectMprDisplay2D)),
        mergeMap(async ([action, display2D]) => {
          return viewerGeneralActions.changeDisplayVolume2D({ mode: ViewerMode.Fusion, isDisplay: display2D });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that triggers when the volume mode is deactivated.
   * It restores the display viewport to its backup state.
   */
  onDeactiveVolumeMode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onFusionDeactive, viewerMenuActions.onMPRDeactive),
        concatLatestFrom(() => this.store.select(ViewerGeneralQuery.selectDisplayViewportBackup)),
        mergeMap(async ([action, viewports]) => {
          return viewerGeneralActions.restoreDisplayViewport({ viewports });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the activation of an annotation tool.
   * This effect listens for the `onAnnotate` action and activates the corresponding annotation tool.
   * It also disables all other tools.
   */
  activeVolumeAnnotate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onVolumeAnnotate),
        mergeMap(async (action) => {
          let toolName = '';
          this._disableAllVolumeTool();
          switch (action.name) {
            case Annotate.Arrow:
              toolName = ArrowAnnotateTool.toolName;
              break;
            case Annotate.Rectangle:
              toolName = RectangleAnnotateTool.toolName;
              break;
            case Annotate.Ellipse:
              toolName = EllipseAnnotateTool.toolName;
              break;
            default:
              break;
          }
          this.toolService.setActiveVolumeTool(toolName);
          return viewerMenuActions.disableAllTool();
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the activation of a measure tool based on the dispatched action.
   * It sets the active tool volume and disables all other tools.
   *
   * @returns An observable that emits the action to disable all tools.
   */
  activeVolumeMeasure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onVolumeMeasure),
        mergeMap(async (action) => {
          let toolName = '';
          this._disableAllVolumeTool();
          switch (action.name) {
            case Measure.Length:
              toolName = LengthTool.toolName;
              break;
            case Measure.Angle:
              toolName = AngleTool.toolName;
              break;
            case Measure.ROIEllipse:
              toolName = EllipticalROITool.toolName;
              break;
            case Measure.ROIRectangle:
              toolName = RectangleROITool.toolName;
              break;
            case Measure.Bidirectional:
              toolName = BidirectionalTool.toolName;
              break;
            case Measure.CircleROI:
              toolName = CircleROITool.toolName;
              break;
            case Measure.PlannarFreehandROI:
              toolName = PlanarFreehandROITool.toolName;
              break;
            case Measure.Intensity:
              toolName = ProbeTool.toolName;
              break;
            case Measure.CobbAngle:
              toolName = CobbAngleTool.toolName;
              break;
            default:
              toolName = LengthTool.toolName;
              break;
          }
          this.toolService.setActiveVolumeTool(toolName);
          return viewerMenuActions.disableAllTool();
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action for activating the volume WWWL.
   * This effect performs several tasks such as passive all annotate measure volume,
   * setting the default tool volume, disabling the crosshair, and setting the active stack tool.
   */
  onActiveVolumeWWWL$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onWWWLVolumeActive),
        mergeMap(async (action) => {
          this._disableAllVolumeTool();
          this.toolService.setActiveVolumeTool(WindowLevelTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that is triggered when the active volume zoom action is dispatched.
   * Disables all volume tools and sets the active stack tool to the Zoom Tool.
   */
  onActiveVolumeZoom$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onVolumeZoom),
        mergeMap(async (action) => {
          this._disableAllVolumeTool();
          this.toolService.setActiveVolumeTool(ZoomTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that is triggered when the user activates the volume pan tool.
   * This effect disables all other volume tools and sets the active stack tool to the pan tool.
   */
  onActiveVolumePan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onVolumePan),
        mergeMap(async (action) => {
          this._disableAllVolumeTool();
          this.toolService.setActiveVolumeTool(PanTool.toolName);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the action to change the active volume selection.
   * Disables all volume tools and sets the state of the crosshair tool based on the displayCrosshair flag.
   *
   * @returns An observable that emits the resulting actions.
   */
  changeVolumeSelectActive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerMenuActions.onVolumeSelect),
        concatLatestFrom(() => this.store.select(ViewerMenuQuery.selectDisplayCrosshair)),
        mergeMap(async ([action, isDisplayCrosshair]) => {
          this._disableAllVolumeTool();
          if (isDisplayCrosshair) {
            this.toolService.setStateCrosshair(true);
          }
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Disables all volume tools.
   * This method sets the default volume tool, disables crosshair, and passive all annotate measure volume tools.
   */
  _disableAllVolumeTool = () => {
    this.toolService.passiveAllAnnotateMesuareVolume();
    this.toolService.setDefaultToolVolume();
    //disable crosshair when active tool
    this.toolService.setStateCrosshair(false);
  };

  //#endregion
}
