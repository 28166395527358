import { IGeneralSeries, IImagePrefetch, Series, IImage } from '@app/@core';
import { IImageUploadInfo, ISeriesUpload, ISeriesUploadInfo } from './ISeriesUpload';
import { SortOrder } from '@app/viewer/contants/sort';
import { UploadState } from '../constants/enum';

export class SeriesUpload extends Series implements ISeriesUpload {
  public imageObject: IImage[];
  public imagePrefetch: IImagePrefetch[];
  public thumbnailDriveId: string;
  public imagesUploaded: IImageUploadInfo[];
  public uploadState: UploadState;
  public uploadMessage: string;

  /**
   * Creates an instance of SeriesUpload.
   * @param uid - The unique identifier of the series.
   * @param metadata - The metadata of the series.
   */
  constructor(uid: string, metadata: IGeneralSeries, modality: string) {
    super(uid, metadata, modality);
    this.imageObject = [];
    this.imagesUploaded = [];
    this.uploadState = UploadState.notYet;
  }

  /**
   * Adds an image to the imagePrefetch array.
   * @param body - The image to be added.
   */
  addImage(body: IImagePrefetch): void {
    this.imagePrefetch.push(body);
  }

  /**
   * Adds an image object to the series upload.
   * @param body - The image object to be added.
   */
  addImageObject(body: IImage): void {
    this.imageObject.push(body);
  }

  /**
   * Converts the SeriesUpload object to an ISeriesUploadInfo object.
   * @returns The converted ISeriesUploadInfo object.
   */
  toUploadData(): ISeriesUploadInfo {
    return {
      uid: this.uid,
      images: this.imagesUploaded,
      metadata: this.metadata,
      thumbnail: this.thumbnailDriveId,
      modality: this.modality,
      totalImages: this.imageObject.length,
    };
  }

  /**
   * Sorts the imageObject array by acquisition number in either ascending or descending order.
   * @param order - The sort order. If not provided, the default is ascending order.
   */
  override sortByAcquisitionNumber(order?: SortOrder): void {
    if (order === SortOrder.Ascending) {
      this.imageObject.sort((a, b) => {
        const aInstanceNumber = a.tag.sort.instanceNumber;
        const bInstanceNumber = b.tag.sort.instanceNumber;
        if (aInstanceNumber < bInstanceNumber) {
          return -1;
        } else if (aInstanceNumber > bInstanceNumber) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this.imageObject.sort((a, b) => {
        const aInstanceNumber = a.tag.sort.instanceNumber;
        const bInstanceNumber = b.tag.sort.instanceNumber;
        if (aInstanceNumber < bInstanceNumber) {
          return 1;
        } else if (aInstanceNumber > bInstanceNumber) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }

  /**
   * Sorts the imageObject array by creation time in either ascending or descending order.
   * If the order parameter is not provided, the default sorting order is ascending.
   *
   * @param order - The sorting order (ascending or descending).
   */
  override sortByCreationTime(order?: SortOrder): void {
    if (order === SortOrder.Ascending) {
      this.imageObject.sort((a, b) => {
        const aInstanceNumber = a.tag.sort.instanceNumber;
        const bInstanceNumber = b.tag.sort.instanceNumber;
        if (aInstanceNumber < bInstanceNumber) {
          return -1;
        } else if (aInstanceNumber > bInstanceNumber) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this.imageObject.sort((a, b) => {
        const aInstanceNumber = a.tag.sort.instanceNumber;
        const bInstanceNumber = b.tag.sort.instanceNumber;
        if (aInstanceNumber < bInstanceNumber) {
          return 1;
        } else if (aInstanceNumber > bInstanceNumber) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }

  /**
   * Sorts the imageObject array by instance number in either ascending or descending order.
   * @param order - The sort order. If not provided, the default is ascending order.
   */
  override sortByInstanceNumber(order?: SortOrder): void {
    if (order === SortOrder.Ascending) {
      this.imageObject.sort((a, b) => {
        const aInstanceNumber = a.tag.sort.instanceNumber;
        const bInstanceNumber = b.tag.sort.instanceNumber;
        if (aInstanceNumber < bInstanceNumber) {
          return -1;
        } else if (aInstanceNumber > bInstanceNumber) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this.imageObject.sort((a, b) => {
        const aInstanceNumber = a.tag.sort.instanceNumber;
        const bInstanceNumber = b.tag.sort.instanceNumber;
        if (aInstanceNumber < bInstanceNumber) {
          return 1;
        } else if (aInstanceNumber > bInstanceNumber) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }

  /**
   * Sorts the imageObject array by echo time in ascending or descending order.
   * Echo time is not available for all image types, so it checks first before sorting.
   *
   * @param order - The sort order. If not provided, the default is ascending order.
   */
  override sortByEchoTime(order?: SortOrder): void {
    // Echo time is not available for all image types so we need to check first.
    if (this.imageObject[0].tag.sort.echoTime) {
      if (order === SortOrder.Ascending) {
        this.imageObject.sort((a, b) => {
          const aEchoTime = a.tag.sort.echoTime;
          const bEchoTime = b.tag.sort.echoTime;
          if (aEchoTime < bEchoTime) {
            return -1;
          } else if (aEchoTime > bEchoTime) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        this.imageObject.sort((a, b) => {
          const aEchoTime = a.tag.sort.echoTime;
          const bEchoTime = b.tag.sort.echoTime;
          if (aEchoTime < bEchoTime) {
            return 1;
          } else if (aEchoTime > bEchoTime) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
  }

  /**
   * Sorts the imageObject array by slice location in either ascending or descending order.
   * @param order - The sort order. If not provided, the default is ascending order.
   */
  override sortBySliceLocation(order?: SortOrder): void {
    if (order === SortOrder.Ascending) {
      this.imageObject.sort((a, b) => {
        const aSliceLocation = a.tag.sort.sliceLocation;
        const bSliceLocation = b.tag.sort.sliceLocation;
        if (aSliceLocation < bSliceLocation) {
          return -1;
        } else if (aSliceLocation > bSliceLocation) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      this.imageObject.sort((a, b) => {
        const aSliceLocation = a.tag.sort.sliceLocation;
        const bSliceLocation = b.tag.sort.sliceLocation;
        if (aSliceLocation < bSliceLocation) {
          return 1;
        } else if (aSliceLocation > bSliceLocation) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }
}
