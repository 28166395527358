import { IImage } from '@app/@core';
import { RegionAnnotation, getRectangleCoordinates, isTwoRecIntersect } from '../annotation';
import { Types as CoreTypes, utilities } from '@cornerstonejs/core';

const calculateWWWLRegion = (
  viewport: CoreTypes.IStackViewport,
  regionTool: RegionAnnotation,
):
  | {
      windowWidth: number;
      windowCenter: number;
    }
  | undefined => {
  // Get the region tool data
  const { points } = regionTool.data.handles;

  const worldPos1 = points[0];
  const worldPos2 = points[3];
  // Get the image data from the viewport
  const image = <IImage>viewport.getCornerstoneImage();
  const viewportImage = viewport.getImageData();

  const { imageData } = viewportImage;

  // Transform the world coordinates to pixel coordinates
  const pos1Index = utilities.transformWorldToIndex(imageData, worldPos1);

  pos1Index[0] = Math.floor(pos1Index[0]);
  pos1Index[1] = Math.floor(pos1Index[1]);
  pos1Index[2] = Math.floor(pos1Index[2]);

  const pos2Index = utilities.transformWorldToIndex(imageData, worldPos2);

  pos2Index[0] = Math.floor(pos2Index[0]);
  pos2Index[1] = Math.floor(pos2Index[1]);
  pos2Index[2] = Math.floor(pos2Index[2]);

  const rect = getRectangleCoordinates([pos1Index, pos2Index]);
  let { left, top, width, height } = rect;
  const imageRect = [0, 0, image.width, image.height];
  // Check if the rectangle is inside the image
  const isInsideImage = isTwoRecIntersect(imageRect, [left, top, width, height]);
  if (!isInsideImage) {
    return undefined;
  }
  // Bound the rectangle so we don't get undefined pixels
  left = Math.max(left, 0);
  left = Math.min(left, image.width);
  top = Math.max(top, 0);
  top = Math.min(top, image.height);
  width = Math.floor(Math.min(width, Math.abs(image.width - left)));
  height = Math.floor(Math.min(height, Math.abs(image.height - top)));

  // Get the pixel data in the rectangular region
  const pixelLuminanceData = _getLuminance(image, left, top, width, height);
  // Calculate the minimum and maximum pixel values
  const minMaxMean = _calculateMinMaxMean(pixelLuminanceData, image.minPixelValue, image.maxPixelValue);
  // Return the window width and window center
  return {
    windowWidth: Math.floor(Math.max(Math.abs(minMaxMean.max - minMaxMean.min), 1)),
    windowCenter: Math.floor(minMaxMean.mean),
  };
};

/**
 * Calculates the luminance values for a given image within a specified region.
 * @param image - The image object.
 * @param x - The starting x-coordinate of the region.
 * @param y - The starting y-coordinate of the region.
 * @param width - The width of the region.
 * @param height - The height of the region.
 * @returns An array of luminance values calculated for the specified region.
 */
const _getLuminance = (image: IImage, x: number, y: number, width: number, height: number) => {
  x = Math.round(x);
  y = Math.round(y);

  // Create an array to store the luminance values
  const luminance: any[] = [];
  let index = 0;
  // Get the pixel data from the image
  const pixelData = image.getPixelData();

  let spIndex, row, column;
  // Calculate the luminance values for the specified region
  if (image.colorImage) {
    // Convert the RGB pixel data to luminance values
    for (row = 0; row < height; row++) {
      for (column = 0; column < width; column++) {
        spIndex = ((row + y) * image.columns + (column + x)) * 4;
        const red = pixelData[spIndex];
        const green = pixelData[spIndex + 1];
        const blue = pixelData[spIndex + 2];

        luminance[index++] = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
      }
    }
  } else {
    // Use the pixel data as is
    for (row = 0; row < height; row++) {
      for (column = 0; column < width; column++) {
        spIndex = (row + y) * image.columns + (column + x);
        // luminance[index++] = pixelData[spIndex] * image.slope + image.intercept;
        luminance[index++] = pixelData[spIndex];
      }
    }
  }

  return luminance;
};

/**
 * Calculates the minimum, maximum, and mean values of stored pixel luminance data.
 *
 * @param storedPixelLuminanceData - An array of stored pixel luminance data.
 * @param globalMin - The global minimum value.
 * @param globalMax - The global maximum value.
 * @returns An object containing the calculated minimum, maximum, and mean values.
 */
const _calculateMinMaxMean = (storedPixelLuminanceData: any[], globalMin: number, globalMax: number) => {
  const numPixels = storedPixelLuminanceData.length;
  // If there are less than 2 pixels, return the global min and max
  if (numPixels < 2) {
    return {
      min: globalMin,
      max: globalMax,
      mean: (globalMin + globalMax) / 2,
    };
  }

  let min = globalMax;
  let max = globalMin;
  let sum = 0;

  // Calculate the minimum, maximum, and mean values
  for (let index = 0; index < numPixels; index++) {
    // Get the stored pixel value
    const spv = storedPixelLuminanceData[index];
    // Update the minimum, maximum, and sum values
    min = Math.min(min, spv);
    max = Math.max(max, spv);
    sum += spv;
  }
  return {
    min,
    max,
    mean: sum / numPixels,
  };
};

export { calculateWWWLRegion };
