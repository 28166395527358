import { Injectable } from '@angular/core';
import { GoogleParams } from '../viewer/models';
import { OPEN_COMPARE_REPORT_PATTERN, OPEN_REPORT_PATTERN } from '@app/@core';
import { Store } from '@ngrx/store';

function _window(): any {
  // return the native window obj
  return window;
}

@Injectable({ providedIn: 'root' })
export class WindowService {
  constructor() {}

  get nativeWindow(): any {
    return _window();
  }

  /**
   * Opens a report in a new window.
   *
   * @param urlParams - The URL parameters for the report.
   * @param isShareMode - A boolean indicating whether the report is in share mode.
   * @returns The newly opened window object.
   */
  openReport = (urlParams: GoogleParams, isShareMode: boolean) => {
    let url;
    if (urlParams.ref === undefined || urlParams.ref === '') {
      url = OPEN_REPORT_PATTERN(urlParams.main, urlParams.mainUid, isShareMode);
    } else {
      url = OPEN_COMPARE_REPORT_PATTERN(urlParams.main, urlParams.mainUid, urlParams.ref, urlParams.refUid, isShareMode);
    }

    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - screen.availHeight) / 2 / systemZoom + dualScreenLeft;
    const top = (height - screen.availWidth) / 2 / systemZoom + dualScreenTop;
    const params = [
      'height=' + screen.height / systemZoom + 'px',
      'width=' + screen.width / systemZoom + 'px',
      'titlebar=no',
      'top=' + top,
      'left=' + left,
      'status=no',
      'menubar=no',
      'location=0',
    ].join(',');
    const newwindow = window.open(url, '_blank', params);

    newwindow?.focus();
    newwindow?.resizeTo(screen.width, screen.height);
    return newwindow;
  };
}
