<header id="header">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <img src="assets/images/core/vpacslogo-rec.svg" style="height: 42px" alt="" />
      <button class="navbar-toggler" type="button" aria-controls="navbar-menu" aria-label="Toggle navigation" (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
        <div class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <a class="nav-item nav-link text-uppercase" routerLink="/worklist" routerLinkActive="active">
            <i class="fas fa-list"></i>
            <span translate>StudyList</span>
          </a>
          <a class="nav-item nav-link text-uppercase" routerLink="/upload" routerLinkActive="active">
            <i class="fas fa-cloud-upload"></i>
            <span translate>UploadStudy</span>
          </a>
          <a class="nav-item nav-link text-uppercase" routerLink="/viewer" routerLinkActive="active">
            <i class="fas fa-th-large"></i>
            <span translate>DICOMViewer</span>
          </a> -->
        </div>
        <div class="navbar-nav ml-auto">
          <div class="nav-item" ngbDropdown display="dynamic">
            <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
              <img crossorigin #avatar alt="item" [src]="(userInfo$ | async)?.photoURL || ''" class="avatar img-responsive" />
            </a>
            <div ngbDropdownMenu aria-labelledby="user-dropdown">
              <h6 class="dropdown-header">
                <span translate>LoggedInAs</span> <b>{{ (userInfo$ | async)?.displayName || '' }}</b>
              </h6>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" [routerLink]="'/worklist'" translate>StudyList</button>
              <button class="dropdown-item" [routerLink]="'/upload'" translate>UploadStudy</button>
              <button class="dropdown-item" [routerLink]="'/viewer'" translate>DICOMViewer</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="openSettingDialog()" translate>Setting</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="logout()" translate>Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
