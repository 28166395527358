import { Injectable } from '@angular/core';
import { ILayout } from '../models/ILayout';
import { LAYOUT_COLS_INDEX, LAYOUT_ROWS_INDEX, VIEWPORT_INDEX_SEPERATOR } from '../contants/layout';

@Injectable()
export class LayoutService {
  constructor() {}

  /**
   * Calculates the layout based on the provided id and layout string.
   * @param id - The identifier for the layout.
   * @param layout - The layout string in the format "x*y".
   * @returns The calculated layout object.
   */
  calculateLayout(id: string, layout: string): ILayout {
    const layoutObject = {
      x: parseInt(layout.charAt(LAYOUT_COLS_INDEX)),
      y: parseInt(layout.charAt(LAYOUT_ROWS_INDEX)),
    };
    return {
      id,
      layoutString: layout,
      total: layoutObject.x * layoutObject.y,
    };
  }

  calculateVolumeLayout(id: string, layout: string): ILayout {
    const layoutObject = {
      x: parseInt(layout.charAt(LAYOUT_COLS_INDEX)) || 0,
      y: parseInt(layout.charAt(LAYOUT_ROWS_INDEX)) || 0,
    };
    return {
      id,
      layoutString: layout,
      total: layoutObject.x + layoutObject.y,
    };
  }

  /**
   * Calculates the total layout based on the given layout string.
   * The layout string should be in the format "width x height".
   * @param layout The layout string in the format "width x height".
   * @returns The total layout calculated by multiplying the width and height.
   */
  calculateTotalLayout(layout: string): number {
    return parseInt(layout.charAt(LAYOUT_COLS_INDEX)) * parseInt(layout.charAt(LAYOUT_ROWS_INDEX));
  }

  /**
   * Calculates the stack index list based on the given stack layout.
   * @param stackLayout The stack layout array.
   * @returns The stack index list.
   */
  calculateMultiStackIndex(stackLayout: string[]): string[] {
    const stackIndexList: string[] = [];
    for (let index = 0, length = stackLayout.length; index < length; index++) {
      const stack = stackLayout[index];
      const totalStack = this.calculateTotalLayout(stack);
      for (let stackIndex = 0; stackIndex < totalStack; stackIndex++) {
        stackIndexList.push(`${index}${VIEWPORT_INDEX_SEPERATOR}${stackIndex}`);
      }
    }
    return stackIndexList;
  }

  calculateStackIndex(stackLayout: string, panelIndex: number): string[] {
    const stackIndexList: string[] = [];
    const totalStack = this.calculateTotalLayout(stackLayout);
    for (let stackIndex = 0; stackIndex < totalStack; stackIndex++) {
      stackIndexList.push(`${panelIndex}${VIEWPORT_INDEX_SEPERATOR}${stackIndex}`);
    }
    return stackIndexList;
  }
}
