<div class="login-container bg-light">
  <div class="login-box">
    <img crossorigin="anonymous" src="assets/images/core/vpacslogo-squ.svg" height="256px" alt="" />
    <div>
      <span translate>Version</span>
      <h6 class="d-inline-block">{{ version }}</h6>
      <div class="d-inline-block ml-3"></div>
    </div>
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <div class="m-3">
            <button class="btn btn-primary w-100" type="submit" (click)="loginByGoogle()">
              <i class="pi pi-google"></i>
              <span translate>Google</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
