<p-dialog [header]="'DicomInfoHeader' | translate" [(visible)]="visible" [style]="{ width: '70vw', height: '70vh' }" (onHide)="onDialogHide()" [modal]="true">
  <div class="flex py-2 justify-content-center width-100 h-100">
    <p-table
      #dt
      [value]="datasets"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines"
      [scrollable]="true"
      scrollHeight="calc(70vh - 200px)"
      [globalFilterFields]="['name', 'tag', 'vr']"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input style="width: 400px" pInputText type="text" (input)="onSearchTag($event)" [placeholder]="'Searchkeyword' | translate" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: fit-content" translate>Tag</th>
          <th style="width: fit-content" translate>Name</th>
          <th style="width: fit-content" translate>VR</th>
          <th style="width: fit-content" translate>Length</th>
          <th style="width: fit-content" translate>Value</th>
          <th style="width: fit-content" translate>DataOffset</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dataset>
        <tr>
          <td style="user-select: text">{{ dataset.tag }}</td>
          <td style="user-select: text">{{ dataset.name }}</td>
          <td style="user-select: text">{{ dataset.vr }}</td>
          <td style="user-select: text">{{ dataset.length }}</td>
          <td style="user-select: text">{{ dataset.value }}</td>
          <td style="user-select: text">{{ dataset.dataOffset }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
