import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IPatientInfo } from '@app/report/models';
import { selectPatientInfoByStudyUid } from '@app/report/store';

@Component({
  selector: 'app-report-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
/**
 * Represents the ReportPatientComponent class.
 * This component is responsible for displaying patient information and handling selected study events.
 */
export class ReportPatientComponent implements OnInit, OnChanges {
  constructor(
    private translateService: TranslateService,
    private store: Store,
  ) {}
  public selectedStudyUid: string;
  ngOnInit(): void {}
  @Input() studyUid: string = '';
  /**
   * Lifecycle hook that is called when one or more input properties of the component change.
   * @param changes - An object containing the changed properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.studyUid && changes.studyUid.currentValue !== undefined) {
      this.patientInfo$ = this.store.select(selectPatientInfoByStudyUid(this.studyUid));
    }
  }

  //#region properties
  public patientInfo$: Observable<IPatientInfo | undefined>;

  /**
   * Handles the event when a study is selected.
   * @param $event The event object containing the selected study UID.
   */
  onSelectedStudy($event) {
    this.selectedStudyUid = $event.uid;
  }
}
