import { IStudyInfo } from './IStudyInfo';
import * as moment from 'moment';
import { Base, IImage, MODALITY_SEPARATOR } from '@app/@core';

export class StudyInfo extends Base<IStudyInfo> implements IStudyInfo {
  public patientid: string;
  public name: string;
  public dob: string;
  public sex: string;
  public gender: string;
  public studydate: string;
  public studytime: string;
  public bodypart: string;
  public modality: string[];
  public manufacture: string;
  public accessionNumber: string;
  public studyDescription: string;
  public imagecount: string;
  public studyinstanceUID: string;
  public imagesdriveId: string;
  public reportdriveId: string;
  public registerdate: string;
  public dicomdirID: string;
  public isShared?: boolean = false;
  public isHaveReport?: boolean = false;

  constructor(patientId: string, baseImage: IImage, totalImage: string, imgFolderId: string, reportFolderId: string, dicomdirID: string, modality: string[]) {
    super();
    this.patientid = patientId;
    this.name = baseImage.tag.patientInfo.patientName;
    this.dob = baseImage.tag.patientInfo.patientBirthDate;
    this.sex = baseImage.tag.patientInfo.patientGender;
    this.studydate = baseImage.tag.general.studyDate;
    this.studytime = baseImage.tag.general.studyTime;
    this.bodypart = baseImage.tag.general.bodyPart;
    this.modality = modality;
    this.manufacture = baseImage.tag.general.manufacture || '';
    this.accessionNumber = baseImage.tag.general.accessionNumber || '';
    this.studyDescription = baseImage.tag.studyInfo.studyDescription || '';
    this.imagecount = totalImage;
    this.studyinstanceUID = baseImage.tag.general.studyInstanceUID;
    this.registerdate = moment().toISOString();
    this.imagesdriveId = imgFolderId;
    this.reportdriveId = reportFolderId;
    this.dicomdirID = dicomdirID;
  }

  /**
   * Converts the StudyInfo object to an array.
   * The array contains the values of the StudyInfo properties in the following order:
   * - patientid
   * - name
   * - dob
   * - age
   * - sex
   * - studydate
   * - studytime
   * - bodypart
   * - modality
   * - manufacture
   * - accessionNumber
   * - studyDescription
   * - imagecount
   * - studyinstanceUID
   * - imagesdriveId
   * - reportdriveId
   * - registerdate
   * - dicomdirID
   *
   * @returns {Array<any>} The array representation of the StudyInfo object.
   */
  public toArrayString(): string[] {
    return [
      this.patientid,
      this.name,
      this.dob,
      this.sex,
      this.studydate,
      this.studytime,
      this.bodypart,
      this.modality.join(MODALITY_SEPARATOR),
      this.manufacture,
      this.accessionNumber,
      this.studyDescription,
      this.imagecount,
      this.studyinstanceUID,
      this.imagesdriveId,
      this.reportdriveId,
      this.registerdate,
      this.dicomdirID,
    ];
  }
}
