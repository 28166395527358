import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { firstValueFrom, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { LoginMode, authQuery } from '@app/auth';
import * as worklistActions from './worklist.actions';
import { WorklistFirebaseService } from '../services/worklist.firebase.service';
import { IStudyInfo } from '../models/IStudyInfo';
import { Severity, SharedService } from '@app/@shared';
import { IWorklistData } from '../models/IDatatable';
import { OPEN_SHARE_VIEWER_PATTERN, OPEN_VIEWER_PATTERN } from '@app/@core';
import { TranslateService } from '@ngx-translate/core';
import { SearchWorklistService } from '../services/search.service';

@Injectable()
export class WorklistEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private worklistFirebaseSerivce: WorklistFirebaseService,
    private searchService: SearchWorklistService,
    private sharedService: SharedService,
    private clipboard: Clipboard,
    private translate: TranslateService,
  ) {}

  /**
   * Loads the worklist from either a worksheet or an API based on the login mode.
   * @param actions$ - The observable of actions.
   * @param authStore - The store containing the authentication state.
   * @returns An observable of actions.
   */
  /**
   * Loads the worklist from either a worksheet or an API based on the login mode.
   * @returns An observable that emits actions for success or failure.
   */
  loadWorklist$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(worklistActions.loadWorklist),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginModeInfo)),
        mergeMap(async ([action, loginMode]) => {
          try {
            let worklist: IStudyInfo[];
            if (loginMode === LoginMode.firebase) {
              const userInfo = await firstValueFrom(this.store.select(authQuery.selectUserInfo));
              const res = await this.worklistFirebaseSerivce.getWorklistByUserId(userInfo?.uid || '');
              return worklistActions.loadWorklistSuccess({
                worklist: res.worklist,
                totalRecords: res.countStudy,
              });
            } else {
              worklist = await this.loadWorklistFromAPI();
              return worklistActions.loadWorklistSuccess({
                worklist: worklist.map((item) => ({ ...item, id: '' })),
                totalRecords: 0,
              });
            }
          } catch (error) {
            return worklistActions.loadWorklistFailure({ error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that searches the worklist based on the login mode and action payload.
   * @returns An observable that emits actions to load the worklist or handle errors.
   */
  searchWorklist$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(worklistActions.searchWorklist),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginModeInfo)),
        mergeMap(async ([action, loginMode]) => {
          try {
            let worklist: IStudyInfo[];
            if (loginMode === LoginMode.firebase) {
              if (action.payload) {
                this.searchService.raiseExecuteSearchStudy(action.payload);
              } else {
                this.searchService.raiseClearSearch(true);
              }
            } else {
            }
          } catch (error) {}
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  // /**
  //  * Effect that filters the worklist based on the login mode and action payload.
  //  * @returns An observable that emits actions to load the filtered worklist or handle errors.
  //  */
  // filterWorklist$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(worklistActions.FilterWorklist),
  //       concatLatestFrom(() => this.store.select(authQuery.selectLoginModeInfo)),
  //       mergeMap(async ([action, loginMode]) => {
  //         try {
  //           let worklist: IStudyInfo[];
  //           if (loginMode === LoginMode.firebase) {
  //             const userInfo = await firstValueFrom(this.store.select(authQuery.selectUserInfo));
  //             const res = await this.worklistFirebaseSerivce.filterWorklist(userInfo?.uid || '', action.payload);
  //             return worklistActions.loadWorklistSuccess({
  //               worklist: res.worklist,
  //               totalRecords: res.countStudy,
  //             });
  //           } else {
  //             worklist = await this.loadWorklistFromAPI();
  //             return worklistActions.loadWorklistSuccess({
  //               worklist: worklist.map((item) => ({ ...item, id: '' })),
  //               totalRecords: 0,
  //             });
  //           }
  //         } catch (error) {
  //           return worklistActions.loadWorklistFailure({ error });
  //         }
  //       }),
  //     );
  //   },
  //   { functional: true, dispatch: true },
  // );

  /**
   * Effect for sharing a study.
   *
   * @remarks
   * This effect listens for the `shareStudy` action and performs the necessary logic to share a study.
   * If the user is logged in with Firebase, it calls the `sharingStudy` method of the `worklistFirebaseSerivce` to share the study.
   * If the sharing is successful, it dispatches the `shareStudySuccess` action.
   * If the sharing fails, it dispatches the `loadWorklistFailure` action with an error message.
   * If the user is not logged in with Firebase, it loads the worklist from the API and dispatches the `loadWorklistSuccess` action with the updated worklist and total records.
   * If an error occurs during the process, it dispatches the `loadWorklistFailure` action with the error.
   *
   * @returns An observable that emits the corresponding actions.
   */
  shareStudy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(worklistActions.shareStudy),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginModeInfo)),
        mergeMap(async ([action, loginMode]) => {
          try {
            let worklist: IStudyInfo[];
            if (loginMode === LoginMode.firebase) {
              const userInfo = await firstValueFrom(this.store.select(authQuery.selectUserInfo));
              const res = await this.worklistFirebaseSerivce.sharingStudy(action.study.id, userInfo?.uid || '', action.study);
              if (res) {
                const url = `${document.baseURI}${OPEN_SHARE_VIEWER_PATTERN(action.study.dicomdirID || '', action.study.id || '')}`;
                // Use Clipboard service to copy the URL
                this.clipboard.copy(url);
                this.sharedService.toastMessage(Severity.info, this.translate.instant('CopyLinkSuccess'));
                return worklistActions.shareStudySuccess({
                  study: res,
                });
              }
              return worklistActions.loadWorklistFailure({ error: 'Share study failed' });
            } else {
              worklist = await this.loadWorklistFromAPI();
              return worklistActions.loadWorklistSuccess({
                worklist: worklist.map((item) => ({ ...item, id: '' })),
                totalRecords: 0,
              });
            }
          } catch (error) {
            return worklistActions.loadWorklistFailure({ error });
          } finally {
            this.sharedService.preloader(false);
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Handles the unsharing of a study.
   *
   * @remarks
   * This effect listens for the `unshareStudy` action and performs the necessary logic to unshare a study.
   * If the user is logged in using Firebase authentication, it calls the `unsharingStudy` method of the `worklistFirebaseSerivce` to unshare the study.
   * If the unsharing is successful, it dispatches the `unshareStudySuccess` action.
   * If the unsharing fails, it dispatches the `loadWorklistFailure` action with an error message.
   *
   * If the user is not logged in using Firebase authentication, it calls the `loadWorklistFromAPI` method to load the worklist from the API.
   * It then dispatches the `loadWorklistSuccess` action with the updated worklist and total records.
   *
   * If any error occurs during the process, it dispatches the `loadWorklistFailure` action with the error.
   *
   * @returns An observable that emits the corresponding actions based on the logic performed.
   */
  unshareStudy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(worklistActions.unshareStudy),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginModeInfo)),
        mergeMap(async ([action, loginMode]) => {
          try {
            let worklist: IStudyInfo[];
            if (loginMode === LoginMode.firebase) {
              const userInfo = await firstValueFrom(this.store.select(authQuery.selectUserInfo));
              const res = await this.worklistFirebaseSerivce.unsharingStudy(action.study.id, userInfo?.uid || '', action.study);
              if (res) {
                return worklistActions.unshareStudySuccess({
                  study: res,
                });
              }
              return worklistActions.loadWorklistFailure({ error: 'Share study failed' });
            } else {
              worklist = await this.loadWorklistFromAPI();
              return worklistActions.loadWorklistSuccess({
                worklist: worklist.map((item) => ({ ...item, id: '' })),
                totalRecords: 0,
              });
            }
          } catch (error) {
            return worklistActions.loadWorklistFailure({ error });
          } finally {
            this.sharedService.preloader(false);
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );
  //#region Load Worklist using API
  //TODO mode to service after
  /**
   * Loads the worklist from the on-premise API.
   * @returns A promise that resolves to an array of IPatientInfo objects representing the worklist.
   */
  loadWorklistFromAPI = async (): Promise<IWorklistData[]> => {
    //TODO: Implement logic load worklist from onpremise API
    const worklist = [];
    return worklist;
  };
  //#endregion

  //#region general
  //#endregion
}
