import {
  FilterCommand,
  PanCommand,
  ReportCommand,
  RotateCommand,
  SelectCommand,
  SortCommand,
  ToolCommand,
  ZoomCommand,
  Annotate,
  Measure,
  SortOrder,
  SyncScope,
  ViewerMode,
  ScrollMode,
  ScrollSync,
} from '@app/viewer/contants';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GoogleParams, IToolState } from '@app/viewer/models';

export const viewerMenuActions = createActionGroup({
  source: 'Viewer Menu',
  events: {
    nextSeries: emptyProps(),
    prevSeries: emptyProps(),
    closeSeries: emptyProps(),
    changeDisplayOverlay: emptyProps(), // boolean, invert the state on store
    changeDisplayReferenceLine: emptyProps(), // boolean, invert the state on store
    changeDisplayCrosshair: emptyProps(), // boolean, invert the state on store
    onMeasure: props<{ name: Measure }>(),
    onVolumeMeasure: props<{ name: Measure }>(),
    onAnnotate: props<{ name: Annotate }>(),
    onVolumeAnnotate: props<{ name: Annotate }>(),
    onSortCommand: props<{ command: SortCommand }>(),
    onSortScope: emptyProps(), // boolean, invert the state on store
    onScroll: emptyProps(), //boolean, active or deactive tool on main menu
    changeScrollLoop: emptyProps(), // boolean, invert the state on store
    changeScrollMode: props<{ mode: ScrollMode }>(),
    changeScrollSync: props<{ sync: ScrollSync }>(),
    onZoom: emptyProps(), //boolean, active or deactive tool on main menu
    onVolumeZoom: emptyProps(), //boolean, active or deactive tool on main menu
    onZoomCommand: props<{ command: ZoomCommand }>(),
    onWWWLActive: emptyProps(), //boolean, active or deactive tool on main menu
    onWWWLVolumeActive: emptyProps(), //boolean, active or deactive tool on main menu
    onWWWLActiveByROI: emptyProps(),
    onWWWLInvert: props<{ state: boolean }>(),
    onPan: emptyProps(), //boolean, active or deactive tool on main menu
    onVolumePan: emptyProps(), //boolean, active or deactive tool on main menu
    onPanCommand: props<{ command: PanCommand }>(),
    onRotate: emptyProps(), //boolean, active or deactive tool on main menu
    ononRotateCommand: props<{ command: RotateCommand }>(),
    onFilterCommand: props<{ command: FilterCommand }>(),
    onLUTCommand: props<{ presetname: string }>(),
    onSyncScope: props<{ scope: SyncScope }>(),
    changeSyncZoom: emptyProps(),
    changeSyncPan: emptyProps(),
    changeSyncRotate: emptyProps(),
    changeSyncWWWL: emptyProps(),
    changeSyncLUT: emptyProps(),
    changeSyncScroll: emptyProps(),
    onSelect: emptyProps(), //boolean, active or deactive tool on main menu
    onVolumeSelect: emptyProps(), //boolean, active or deactive tool on main menu
    onSelectCommand: props<{ command: SelectCommand }>(),
    disableAllTool: emptyProps(),
    onToolCommand: props<{ command: ToolCommand }>(),
    onMPRActive: emptyProps(),
    onMPRDeactive: emptyProps(),
    onMPRLayout: props<{ layout: string }>(),
    onMPRVisible2D: emptyProps(), // boolean, invert the state on store
    onMPRLayoutChange: props<{ layout: string }>(), //Use for layout menu when active mpr mode
    onMPRVisible2DChange: emptyProps(), // boolean, invert the state on store, trigger change layout
    onFusionActive: emptyProps(),
    onFusionDeactive: emptyProps(),
    onFusionLayout: props<{ layout: string }>(),
    onFusionVisible2D: emptyProps(), // boolean, invert the state on store
    onFusionLayoutChange: props<{ layout: string }>(), //Use for layout menu when active mpr mode
    onFusionVisible2DChange: emptyProps(), // boolean, invert the state on store, trigger change layout
    onFullScreenCommand: props<{ isFullScreen: boolean }>(),
    triggerMagnify: emptyProps(), // boolean, invert the state on store
    changeActiveMode: props<{ mode: ViewerMode }>(),
    changeActiveTile: props<{ toolState: IToolState }>(),
    changeSortDirection: emptyProps(),
    openReport: props<{ params: GoogleParams; isShareMode: boolean }>(),
    closeReport: emptyProps(),
    pasteKeyImageFull: emptyProps(),
    pasteKeyImageRegion: emptyProps(),
  },
});
