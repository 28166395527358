<div class="worklist">
  <div class="worklist__sidebar" [class.worklist__sidebar--expanded]="expandedSidebar">
    <p-button class="worklist__sidebar__toggle-button" icon="pi pi-angle-double-right" severity="secondary" [rounded]="true" [text]="true" (click)="toggleSidebar()"></p-button>
    <div class="worklist__sidebar__main">
      <app-search></app-search>
      <app-preset></app-preset>
    </div>
  </div>
  <div class="worklist__main">
    <app-study-table [patientListInfoID]="patientListInfoID" />
  </div>
</div>
