import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { LandingPageComponent } from './landing-page.component';
import { APP_ROUTE_BASE, LANDING_PAGE_PATTERN, LANDING_PAGE_ROUTE, SUB_LANDING_PAGE_ROUTE } from '@app/@core';
import { TermComponent } from './pages/en-us/term/term.component';
import { MainComponent } from './pages/en-us/main.component';
import { PrivacyComponent } from './pages/en-us/privacy/privacy.component';
import { MainContentComponent } from './pages/en-us/main-content/main-content.component';
import { CookiesComponent } from './pages/en-us/cookies/cookies.component';

import { MainComponent as VNMainComponent } from './pages/vn-vn/main.component';
import { MainContentComponent as VNMainContentComponent } from './pages/vn-vn/main-content/main-content.component';
import { TermComponent as VNTermComponent } from './pages/vn-vn/term/term.component';
import { PrivacyComponent as VNPrivacyComponent } from './pages/vn-vn/privacy/privacy.component';
import { CookiesComponent as VNCookiesComponent } from './pages/vn-vn/cookies/cookies.component';

import { MainComponent as JPMainComponent } from './pages/ja-jp/main.component';
import { MainContentComponent as JPMainContentComponent } from './pages/ja-jp/main-content/main-content.component';
import { TermComponent as JPTermComponent } from './pages/ja-jp/term/term.component';
import { PrivacyComponent as JPPrivacyComponent } from './pages/ja-jp/privacy/privacy.component';
import { CookiesComponent as JPCookiesComponent } from './pages/ja-jp/cookies/cookies.component';

const jp_child_routes: Routes = [
  {
    path: SUB_LANDING_PAGE_ROUTE.TERM,
    component: JPTermComponent,
    data: { title: marker('Điều kiện sử dụng') },
  },
  {
    path: SUB_LANDING_PAGE_ROUTE.PRIVACY,
    component: JPPrivacyComponent,
    data: { title: marker('Privacy Policy') },
  },
  {
    path: SUB_LANDING_PAGE_ROUTE.COOKIES,
    component: JPCookiesComponent,
    data: { title: marker('Cookies Policy') },
  },
  {
    path: '',
    component: JPMainContentComponent,
    data: { title: marker('Trang Chủ') },
  },
];

const vn_child_routes: Routes = [
  {
    path: SUB_LANDING_PAGE_ROUTE.TERM,
    component: VNTermComponent,
    data: { title: marker('Điều kiện sử dụng') },
  },
  {
    path: SUB_LANDING_PAGE_ROUTE.PRIVACY,
    component: VNPrivacyComponent,
    data: { title: marker('Privacy Policy') },
  },
  {
    path: SUB_LANDING_PAGE_ROUTE.COOKIES,
    component: VNCookiesComponent,
    data: { title: marker('Cookies Policy') },
  },
  {
    path: '',
    component: VNMainContentComponent,
    data: { title: marker('Trang Chủ') },
  },
];
const us_child_routes: Routes = [
  {
    path: SUB_LANDING_PAGE_ROUTE.TERM,
    component: TermComponent,
    data: { title: marker('Terms and Condition') },
  },
  {
    path: SUB_LANDING_PAGE_ROUTE.PRIVACY,
    component: PrivacyComponent,
    data: { title: marker('Privacy Policy') },
  },
  {
    path: SUB_LANDING_PAGE_ROUTE.COOKIES,
    component: CookiesComponent,
    data: { title: marker('Cookies Policy') },
  },
  {
    path: '',
    component: MainContentComponent,
    data: { title: marker('Landing Page') },
  },
];

const child_routes: Routes = [
  //US language
  {
    path: LANDING_PAGE_ROUTE.US,
    component: MainComponent,
    children: us_child_routes,
    data: { title: marker('Landing Page') },
  },
  {
    path: LANDING_PAGE_ROUTE.VN,
    component: VNMainComponent,
    children: vn_child_routes,
    data: { title: marker('Trang Chủ') },
  },
  {
    path: LANDING_PAGE_ROUTE.JP,
    component: JPMainComponent,
    children: jp_child_routes,
    data: { title: marker('Trang Chủ') },
  },
];

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: APP_ROUTE_BASE.LANDING_PAGE,
    component: LandingPageComponent,
    children: child_routes,
    data: { child_routes },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LandingPageRoutingModule {}
