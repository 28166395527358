import { DATE_TIME_RANGE_SPLIT } from '@app/@core';
import { STUDY_DURATION } from '@app/worklist/contants/search';
import * as moment from 'moment';

/**
 * // Creates a filter that checks if value is between study date and filter. The filter is case sensitive
 * @param value current datatable value
 * @param filter input string filter
 * @returns
 */
const customDateTimeRangeFilter = (value: any, filter: string): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }
  const studyDateFrom = moment(filter.split(DATE_TIME_RANGE_SPLIT)[0]);
  const studyDateTo = moment(filter.split(DATE_TIME_RANGE_SPLIT)[1]);
  const result = moment(value, 'YYYYMMDD').isBetween(studyDateFrom, studyDateTo);
  return result;
};

/**
 * Custom filter function for filtering dates based on the number of days difference from the current date.
 * @param value - The date value to be filtered.
 * @param filter - The filter value representing the maximum number of days difference allowed.
 * @returns True if the date satisfies the filter condition, false otherwise.
 */
const customDateTimeFromDayFilter = (value: any, filter: string): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }
  const currentDateTime = moment();
  const currentDate = moment(value);
  const dayDiff = currentDateTime.diff(currentDate, 'days');
  return dayDiff <= parseInt(filter);
};

/**
 * Custom date time duration filter.
 *
 * @param value - The value to be filtered.
 * @param filter - The filter to be applied.
 * @returns A boolean indicating whether the value passes the filter.
 */
const customeDateTimeDurationFilter = (value: any, filter: string): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }
  const currentDateTime = moment();
  const currentDate = moment(value);
  const dayDiff = currentDateTime.diff(currentDate, 'days');
  let dayDuration = 0; //Current day
  switch (filter) {
    case STUDY_DURATION.YESTERDAY:
      dayDuration = 1;
      break;
    case STUDY_DURATION.LAST_7_DAYS:
      dayDuration = 7;
      break;
    case STUDY_DURATION.LAST_1_MOUNTHS:
      dayDuration = 30;
      break;
    case STUDY_DURATION.LAST_3_MONTHS:
      dayDuration = 30 * 3;
      break;
    case STUDY_DURATION.LAST_6_MONTHS:
      dayDuration = 30 * 6;
      break;
    case STUDY_DURATION.LAST_1_YEAR:
      dayDuration = 30 * 12;
      break;
    case STUDY_DURATION.LAST_3_YEARS:
      dayDuration = 30 * 12 * 3;
      break;
    case STUDY_DURATION.LAST_5_YEARS:
      dayDuration = 30 * 12 * 5;
      break;
    default:
      break;
  }
  return dayDiff <= dayDuration;
};

export { customDateTimeRangeFilter, customDateTimeFromDayFilter, customeDateTimeDurationFilter };
