import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from '@env/environment';
import { Logger } from '@shared';
import { SharedService } from '@shared';
import { Store } from '@ngrx/store';
import { apiAuthActions, firebaseAuthActions } from './store/auth.actions';
const log = new Logger('Login');

/**
 * Represents the LoginComponent class.
 * This component is responsible for handling the login functionality.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {
  version: string | null = environment.version;
  buildNumber: string | '0000' = environment.buildNumber;
  error: string | undefined;
  protected enabled = false;
  //#endregion
  /**
   * Constructs a new instance of the LoginComponent class.
   * @param store - The store service.
   */
  constructor(
    private sharedService: SharedService,
    private store: Store,
  ) {}

  /**
   * Performs additional initialization after the view is initialized.
   * This method is called after the component's view has been fully initialized.
   */
  ngAfterViewInit(): void {
    this.sharedService.preloader(false);
  }

  /**
   * Generates the code for logging in to Google.
   * This is the first step in authenticating a user.
   * It's important to check the status of the authentication process before calling this function.
   */
  loginByGoogle() {
    this.store.dispatch(firebaseAuthActions.login());
  }

  /**
   * Logs in the user by API username and password.
   * This is a low-level function.
   * @param username - The API username.
   * @param password - The API password.
   * @param rememberMe - Indicates whether to remember the user's login.
   */
  async loginByAPI(username: string, password: string, rememberMe: boolean) {
    this.store.dispatch(apiAuthActions.login({ username, password, rememberMe }));
  }
}
