export enum ScrollMode {
  OneImage,
  OneImageRow,
  OneImagePage,
}

export enum ScrollSync {
  ImagePosition,
  ImageOrder,
}
