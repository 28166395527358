import { Component, Input } from '@angular/core';

/**
 * Represents a loader component that displays a loading indicator and an optional message.
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  /**
   * Indicates whether the loader is currently in the loading state.
   */
  @Input() isLoading: boolean = false;

  /**
   * An optional message to be displayed alongside the loading indicator.
   */
  @Input() message: string | undefined;
}
