import { utilities } from '@cornerstonejs/core';

const setPetTransferFunction = ({ volumeActor }) => {
  const rgbTransferFunction = volumeActor.getProperty().getRGBTransferFunction(0);

  rgbTransferFunction.setRange(0, 5);

  utilities.invertRgbTransferFunction(rgbTransferFunction);
};

export { setPetTransferFunction };
