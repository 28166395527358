import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WORKLIST_KEY, WorklistState } from './worklist.reducer';

/**
 * Selects the worklist state from the feature state.
 *
 * @param state - The feature state.
 * @returns The worklist state.
 */
const selectWorklistState = createFeatureSelector<WorklistState>(WORKLIST_KEY);

/**
 * Selects the worklist from the worklist state.
 *
 * @param state - The worklist state.
 * @returns The worklist.
 */
const selectWorklist = createSelector(selectWorklistState, (state: WorklistState) => state.worklist);

/**
 * Selects the loading status from the worklist state.
 *
 * @param state - The worklist state.
 * @returns The loading status.
 */
const selectLoadingStatus = createSelector(selectWorklistState, (state: WorklistState) => state.isLoading);

/**
 * Selects the error from the worklist state.
 *
 * @param state - The worklist state.
 * @returns The error.
 */
const selectWorklistError = createSelector(selectWorklistState, (state: WorklistState) => state.error);

/**
 * Selects the total records from the worklist state.
 */
const selectTotalRecords = createSelector(selectWorklistState, (state: WorklistState) => state.totalRecords);

/**
 * Selects the isOpenReport from the worklist state.
 */
const selectIsOpenReport = createSelector(selectWorklistState, (state: WorklistState) => state.isOpenReport);

const selectSearch = createSelector(selectWorklistState, (state: WorklistState) => state.search);

export const worklistQuery = {
  selectWorklistState,
  selectWorklist,
  selectTotalRecords,
  selectWorklistError,
  selectLoadingStatus,
  selectIsOpenReport,
  selectSearch,
};
