import { SortOrder } from '@app/viewer';
import { IImagePrefetch } from '../models/IImage';
import { ISeries } from '../models/ISeries';

//#region SERIES SORT
/**
 * Sorts an array of images {@link IImagePrefetch} by acquisition number in either ascending or descending order.
 *
 * @param order - The sort order (ascending or descending).
 * @param image - The array of images to be sorted.
 */
const sortByAcquisitionNumber = (order: SortOrder, image: IImagePrefetch[]) => {
  if (order === SortOrder.Ascending) {
    image.sort((a, b) => {
      const aInstanceNumber = a.tag.sort.instanceNumber;
      const bInstanceNumber = b.tag.sort.instanceNumber;
      if (aInstanceNumber < bInstanceNumber) {
        return -1; //Ascending
      } else if (aInstanceNumber > bInstanceNumber) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    image.sort((a, b) => {
      const aInstanceNumber = a.tag.sort.instanceNumber;
      const bInstanceNumber = b.tag.sort.instanceNumber;
      if (aInstanceNumber < bInstanceNumber) {
        return 1; //Descending
      } else if (aInstanceNumber > bInstanceNumber) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};

/**
 * Sorts the given array of images by creation time in the specified order.
 *
 * @param order - The sort order (Ascending or Descending).
 * @param image - The array of images to be sorted.
 */
const sortByCreationTime = (order: SortOrder, image: IImagePrefetch[]) => {
  if (order === SortOrder.Ascending) {
    image.sort((a, b) => {
      const aInstanceNumber = a.tag.sort.instanceNumber;
      const bInstanceNumber = b.tag.sort.instanceNumber;
      if (aInstanceNumber < bInstanceNumber) {
        return -1;
      } else if (aInstanceNumber > bInstanceNumber) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    image.sort((a, b) => {
      const aInstanceNumber = a.tag.sort.instanceNumber;
      const bInstanceNumber = b.tag.sort.instanceNumber;
      if (aInstanceNumber < bInstanceNumber) {
        return 1;
      } else if (aInstanceNumber > bInstanceNumber) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};

/**
 * Sorts an array of images by instance number in either ascending or descending order.
 *
 * @param order - The sort order (ascending or descending).
 * @param image - The array of images to be sorted.
 */
const sortByInstanceNumber = (order: SortOrder, image: IImagePrefetch[]) => {
  if (order === SortOrder.Ascending) {
    image.sort((a, b) => {
      const aInstanceNumber = a.tag.sort.instanceNumber;
      const bInstanceNumber = b.tag.sort.instanceNumber;
      if (aInstanceNumber < bInstanceNumber) {
        return -1;
      } else if (aInstanceNumber > bInstanceNumber) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    image.sort((a, b) => {
      const aInstanceNumber = a.tag.sort.instanceNumber;
      const bInstanceNumber = b.tag.sort.instanceNumber;
      if (aInstanceNumber < bInstanceNumber) {
        return 1;
      } else if (aInstanceNumber > bInstanceNumber) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};

/**
 * Sorts the images by echo time in the specified order.
 *
 * @param order - The sort order (ascending or descending).
 * @param image - The array of images to be sorted.
 */
const sortByEchoTime = (order: SortOrder, image: IImagePrefetch[]) => {
  // Echo time is not available for all image types so we need to check first.
  if (image[0].tag.sort.echoTime) {
    if (order === SortOrder.Ascending) {
      image.sort((a, b) => {
        const aEchoTime = a.tag.sort.echoTime;
        const bEchoTime = b.tag.sort.echoTime;
        if (aEchoTime < bEchoTime) {
          return -1;
        } else if (aEchoTime > bEchoTime) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      image.sort((a, b) => {
        const aEchoTime = a.tag.sort.echoTime;
        const bEchoTime = b.tag.sort.echoTime;
        if (aEchoTime < bEchoTime) {
          return 1;
        } else if (aEchoTime > bEchoTime) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }
};

/**
 * Sorts an array of images by slice location in either ascending or descending order.
 *
 * @param order - The sort order (ascending or descending).
 * @param image - The array of images to be sorted.
 */
const sortBySliceLocation = (order: SortOrder, image: IImagePrefetch[]) => {
  if (order === SortOrder.Ascending) {
    image.sort((a, b) => {
      const aSliceLocation = a.tag.sort.sliceLocation;
      const bSliceLocation = b.tag.sort.sliceLocation;
      if (aSliceLocation < bSliceLocation) {
        return -1;
      } else if (aSliceLocation > bSliceLocation) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    image.sort((a, b) => {
      const aSliceLocation = a.tag.sort.sliceLocation;
      const bSliceLocation = b.tag.sort.sliceLocation;
      if (aSliceLocation < bSliceLocation) {
        return 1;
      } else if (aSliceLocation > bSliceLocation) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};

//#endregion

//#region STUDY SORT
/**
 * Sorts an array of series objects by their seriesInstanceUID property.
 * @param order - The sort order (Ascending or Descending).
 * @param series - The array of series objects to be sorted.
 */
const sortByUid = (order: SortOrder, series: ISeries[]) => {
  if (order === SortOrder.Ascending) {
    series.sort((a, b) => {
      if (a.metadata.seriesInstanceUID < b.metadata.seriesInstanceUID) {
        return -1;
      } else if (a.metadata.seriesInstanceUID > b.metadata.seriesInstanceUID) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    series.sort((a, b) => {
      if (a.metadata.seriesInstanceUID < b.metadata.seriesInstanceUID) {
        return 1;
      } else if (a.metadata.seriesInstanceUID > b.metadata.seriesInstanceUID) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};

/**
 * Sorts an array of series objects by their series number in ascending or descending order.
 * @param order - The sort order (ascending or descending).
 * @param series - The array of series objects to be sorted.
 */
const sortbySeriesNumber = (order: SortOrder, series: ISeries[]) => {
  if (order === SortOrder.Ascending) {
    series.sort((a, b) => {
      if (a.metadata.seriesNumber < b.metadata.seriesNumber) {
        return -1;
      } else if (a.metadata.seriesNumber > b.metadata.seriesNumber) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    series.sort((a, b) => {
      if (a.metadata.seriesNumber < b.metadata.seriesNumber) {
        return 1;
      } else if (a.metadata.seriesNumber > b.metadata.seriesNumber) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};
//#endregion
export { sortByAcquisitionNumber, sortByCreationTime, sortByInstanceNumber, sortByEchoTime, sortBySliceLocation, sortByUid, sortbySeriesNumber };
