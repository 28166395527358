import { createFeature, createReducer, on } from '@ngrx/store';
import { volumeActions } from './cornerstone.actions';

export const VIEWER_CORNERSTONE_KEY = 'cornerstone';

export interface CornerstoneState {
  isVolumeLoading: boolean;
  error: any;
  volumeIds: string[];
}

export const initialState: CornerstoneState = {
  error: null,
  isVolumeLoading: true,
  volumeIds: [],
};

export const cornerstoneFeature = createFeature({
  name: VIEWER_CORNERSTONE_KEY,
  reducer: createReducer<CornerstoneState>(
    initialState,
    on(
      volumeActions.createVolume,
      (state): CornerstoneState => ({
        ...state,
        isVolumeLoading: true,
      }),
    ),
    on(
      volumeActions.createVolumeSuccess,
      (state, action): CornerstoneState => ({
        ...state,
        isVolumeLoading: false,
        volumeIds: action.volumeIds,
      }),
    ),
    on(
      volumeActions.createVolumeFail,
      (state, action): CornerstoneState => ({
        ...state,
        isVolumeLoading: false,
        error: action.error,
      }),
    ),
    on(
      volumeActions.initVolumeSuccess,
      (state, action): CornerstoneState => ({
        ...state,
        isVolumeLoading: false,
        volumeIds: action.volumeIds,
      }),
    ),
  ),
});

export const { name, reducer } = cornerstoneFeature;
