<p-dialog [resizable]="false" [(visible)]="autoScrollImageDialogVisibility" styleClass="auto-scroll-dialog" [header]="'AutoScrollImage' | translate">
  <div class="auto-scroll-dialog__body">
    <p-button icon="pi pi-fast-backward" [rounded]="true" [text]="true" (onClick)="onAutoPlay(AUTO_PLAY.FirstImage)" />

    <p-button icon="pi pi-step-backward" [rounded]="true" [text]="true" (onClick)="onAutoPlay(AUTO_PLAY.PreviousImage)" />
    <p-button [icon]="playPauseIcon" [rounded]="true" [text]="true" (onClick)="onAutoPlay(AUTO_PLAY.Play)" />
    <p-button icon="pi pi-step-forward" [rounded]="true" [text]="true" (onClick)="onAutoPlay(AUTO_PLAY.NextImage)" />
    <p-button icon="pi pi-fast-forward" [rounded]="true" [text]="true" (onClick)="onAutoPlay(AUTO_PLAY.LastImage)" />
    <p-button icon="pi pi-sync" [rounded]="true" [text]="true" [class]="(isScrollLoopActive$ | async) ? 'state-activated' : ''" (onClick)="onAutoPlay(AUTO_PLAY.Loop)" />
    <p-dropdown [options]="autoScrollImageFPSs" [(ngModel)]="selectedAutoScrollImageFPS" optionLabel="name" appendTo="body" panelStyleClass="auto-scroll-dialog__dropdown-panel"></p-dropdown>
    <p-button icon="pi pi-times" [rounded]="true" [text]="true" (onClick)="closeAutoScrollImageDialog()" />
  </div>
</p-dialog>
