import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Severity } from '../enum/serverity';
import { toastPayload } from '../enum/toast';
@Component({
  selector: 'app-toast-message',
  templateUrl: './toast.component.html',
  providers: [MessageService],
})
export class ToastMessageComponent {
  /**
   * @param private - messageService The instance of MessageService. Must not be null.
   * @param messageService
   * @param MessageService
   * @param translateService
   * @param TranslateService
   */
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  private _showMessage: toastPayload;
  /**
   * Shows a toast message. This is a shortcut for ` showToastMessage ( severity message ) `
   *
   * @param payload - The toast payload to show.
   * @param toastPayload - The toast payload to show to the user
   */
  @Input()
  public set showMessage(payload: toastPayload) {
    // Show the toast message.
    if (payload) {
      this.showToastMessage(payload.severity, payload.message);
    }
  }

  /**
   * Display Toast component with library services and payload
   * @param severity
   * @param message
   */
  public showToastMessage(severity: Severity, message: string) {
    // Add severity to the message service.
    switch (severity) {
      case Severity.success:
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('toastSuccess'),
          detail: message,
          key: 'toast',
        });

        break;
      case Severity.info:
        this.messageService.add({
          severity: 'info',
          summary: this.translateService.instant('toastInfo'),
          detail: message,
          key: 'toast',
        });
        break;
      case Severity.error:
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('toastError'),
          detail: message,
          key: 'toast',
        });
        break;
      case Severity.warn:
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('toastWarn'),
          detail: message,
          key: 'toast',
        });
        break;

      default:
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('toastWarn'),
          detail: this.translateService.instant('MessSomethingWrong'),
          key: 'toast',
        });
        break;
    }
  }
}
