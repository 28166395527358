<div class="content-wrapper">
  <div class="container">
    <h1>Chính Sách Cookie cho VPACS Viewer</h1>

    <p>Đây là Chính Sách Cookie cho VPACS Viewer, truy cập từ https://vpacs.net</p>

    <p><strong>Cookie Là Gì</strong></p>

    <p>
      Như là thực tiễn phổ biến với hầu hết các trang web chuyên nghiệp, trang web này sử dụng cookie, là các tệp nhỏ được tải xuống máy tính của bạn, để cải thiện trải nghiệm của bạn. Trang này mô tả
      những thông tin mà chúng thu thập, cách chúng tôi sử dụng và tại sao đôi khi chúng tôi cần lưu trữ những cookie này. Chúng tôi cũng sẽ chia sẻ cách bạn có thể ngăn các cookie này được lưu trữ
      tuy nhiên điều này có thể hạ cấp hoặc 'làm hỏng' một số yếu tố chức năng của trang web.
    </p>

    <p><strong>Chúng Tôi Sử Dụng Cookie Như Thế Nào</strong></p>

    <p>
      Chúng tôi sử dụng cookie cho nhiều lý do được chi tiết bên dưới. Đáng tiếc là trong hầu hết các trường hợp, không có các tùy chọn tiêu chuẩn trong ngành để vô hiệu hóa cookie mà không hoàn toàn
      vô hiệu hóa chức năng và tính năng mà chúng thêm vào trang web này. Bạn nên để lại tất cả cookie nếu bạn không chắc liệu có cần chúng hay không, trong trường hợp chúng được sử dụng để cung cấp
      một dịch vụ mà bạn sử dụng.
    </p>

    <p><strong>Vô Hiệu Hóa Cookie</strong></p>

    <p>
      Bạn có thể ngăn việc thiết lập cookie bằng cách điều chỉnh các thiết lập trên trình duyệt của mình (xem phần Trợ Giúp của trình duyệt của bạn để biết cách thực hiện điều này). Hãy lưu ý rằng
      việc vô hiệu hóa cookie sẽ ảnh hưởng đến chức năng của trang web này và nhiều trang web khác mà bạn truy cập. Việc vô hiệu hóa cookie thường sẽ dẫn đến việc vô hiệu hóa các chức năng và tính
      năng nhất định của trang web này. Vì vậy, bạn nên không vô hiệu hóa cookie. Chính Sách Cookie này được tạo với sự trợ giúp của
      <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">Trình Tạo Chính Sách Cookie</a>.
    </p>

    <p><strong>Các Cookie Chúng Tôi Thiết Lập</strong></p>

    <ul>
      <li>
        <p>Cookie liên quan đến tài khoản</p>
        <p>
          Nếu bạn tạo tài khoản với chúng tôi thì chúng tôi sẽ sử dụng cookie cho việc quản lý quá trình đăng ký và quản trị chung. Các cookie này sẽ thường bị xóa khi bạn đăng xuất, tuy nhiên trong
          một số trường hợp, chúng có thể vẫn còn để nhớ các tùy chọn của bạn trên trang web khi đã đăng xuất.
        </p>
      </li>

      <li>
        <p>Cookie liên quan đến đăng nhập</p>
        <p>
          Chúng tôi sử dụng cookie khi bạn đã đăng nhập để chúng tôi có thể nhớ điều này. Điều này ngăn bạn phải đăng nhập mỗi khi bạn truy cập một trang mới. Các cookie này thường bị xóa hoặc xóa
          sạch khi bạn đăng xuất để đảm bảo rằng bạn chỉ có thể truy cập các tính năng và khu vực hạn chế khi đã đăng nhập.
        </p>
      </li>

      <li>
        <p>Cookie liên quan đến bản tin email</p>
        <p>
          Trang web này cung cấp dịch vụ đăng ký bản tin hoặc email và cookie có thể được sử dụng để nhớ nếu bạn đã đăng ký và liệu có nên hiển thị một số thông báo nhất định mà chỉ hợp lệ cho người
          đăng ký/hủy đăng ký.
        </p>
      </li>

      <li>
        <p>Cookie liên quan đến biểu mẫu</p>
        <p>
          Khi bạn gửi dữ liệu qua một biểu mẫu như những biểu mẫu tìm thấy trên trang liên hệ hoặc biểu mẫu nhận xét, cookie có thể được thiết lập để nhớ các chi tiết người dùng của bạn cho lần tương
          tác sau.
        </p>
      </li>
    </ul>

    <p><strong>Cookie Bên Thứ Ba</strong></p>

    <p>
      Trong một số trường hợp đặc biệt, chúng tôi cũng sử dụng cookie do các bên thứ ba đáng tin cậy cung cấp. Phần sau đây chi tiết về các cookie bên thứ ba mà bạn có thể gặp phải qua trang web này.
    </p>

    <ul>
      <li>
        <p>
          Trang web này sử dụng Google Analytics, một trong những giải pháp phân tích phổ biến và đáng tin cậy nhất trên web, để giúp chúng tôi hiểu cách bạn sử dụng trang web và cách chúng tôi có thể
          cải thiện trải nghiệm của bạn. Các cookie này có thể theo dõi những thứ như thời gian bạn dành trên trang web và các trang mà bạn truy cập để chúng tôi có thể tiếp tục tạo ra nội dung hấp
          dẫn.
        </p>
        <p>Để biết thêm thông tin về cookie Google Analytics, xem trang Google Analytics chính thức.</p>
      </li>

      <li>
        <p>
          Dịch vụ Google AdSense mà chúng tôi sử dụng để phục vụ quảng cáo sử dụng cookie DoubleClick để phục vụ các quảng cáo phù hợp hơn trên web và giới hạn số lần mà một quảng cáo cụ thể được hiển
          thị cho bạn.
        </p>
        <p>Để biết thêm thông tin về Google AdSense, xem FAQ về bảo mật của Google AdSense chính thức.</p>
      </li>
    </ul>

    <p><strong>Thông Tin Thêm</strong></p>

    <p>
      Hy vọng rằng điều đó đã làm rõ mọi thứ cho bạn và như đã đề cập trước đó nếu có điều gì đó mà bạn không chắc chắn liệu bạn có cần hay không thì thường an toàn hơn để để lại cookie được kích hoạt
      trong trường hợp nó tương tác với một trong những tính năng bạn sử dụng trên trang web của chúng tôi.
    </p>

    <p>
      Để biết thêm thông tin chung về cookie, vui lòng đọc
      <a href="https://www.cookiepolicygenerator.com/sample-cookies-policy/">bài viết về Chính Sách Cookie</a>.
    </p>

    <p>Tuy nhiên, nếu bạn vẫn tìm kiếm thêm thông tin thì bạn có thể liên hệ với chúng tôi qua một trong những phương thức liên hệ ưa thích của chúng tôi:</p>

    <ul>
      <li>Email: radteam.vpacs&#64;gmail.com</li>
    </ul>
  </div>
</div>
