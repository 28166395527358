<div class="banner">
  <div class="container">
    <h1 class="font-weight-semibold">HTML 5 ZERO-FOOTPRINT DICOM VIEWER</h1>
    <h6 class="font-weight-normal text-muted pb-3">
      V-PACS is an image diagnosing system with web-based DICOM Viewer and cloud storage system that is aimed at making diagnoses, viewing, archiving and transmitting medical images.
    </h6>
    <div>
      <button class="btn btn-opacity-light mr-1" onclick="document.getElementById('case-studies-section').scrollIntoView()">Get started</button>
      <button class="btn btn-opacity-success ml-1" onclick="document.getElementById('case-studies-section').scrollIntoView()">Learn more</button>
    </div>
    <br />
    <img src="assets/images/landing-page/dicomviewer.jpg" alt="" class="img-fluid" />
  </div>
</div>
<div class="content-wrapper">
  <div class="container">
    <section class="features-overview" id="features-section">
      <div class="content-header">
        <h2>System Features</h2>
        <h6 class="section-subtitle text-muted">Our Image Diagnosing System Has These Main Features</h6>
      </div>
      <div style="text-align: center" class="d-md-flex justify-content-between">
        <div class="grid-margin d-flex justify-content-start">
          <div class="features-width">
            <img src="assets/images/landing-page/cloudhosting.png" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">Cloud Hosting</h5>
            <p class="text-muted">Data is stored on Google Cloud Platform to provide a secured and easy access via internet connection</p>
            <!-- <a href="#"><p class="readmore-link">Readmore</p></a> -->
          </div>
        </div>
        <div class="grid-margin d-flex justify-content-center">
          <div class="features-width">
            <img src="assets/images/landing-page/dicomstandard.jpg" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">DICOM Standard</h5>
            <p class="text-muted">The system has beed build with DICOM Standards that allows system to work with multiple modalities as CT, MRI, XR, US, ....</p>
            <!-- <a href="#"><p class="readmore-link">Readmore</p></a> -->
          </div>
        </div>
        <div class="grid-margin d-flex justify-content-end">
          <div class="features-width">
            <img src="assets/images/landing-page/webbased.png" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">Web-based System</h5>
            <p class="text-muted">Our system has been created with different web-based applications to perform image dianosing workflow without any installation.</p>
            <!-- <a href="#"><p class="readmore-link">Readmore</p></a> -->
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="digital-marketing-service" id="digital-marketing-section">
          <div class="row align-items-center">
            <div class="col-12 col-lg-7 grid-margin grid-margin-lg-0" data-aos="fade-right">
              <h3 class="m-0">We Offer a Full Range<br />of Digital Marketing Services!</h3>
              <div class="col-lg-7 col-xl-6 p-0">
                <p class="py-4 m-0 text-muted">
                  Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.
                </p>
                <p class="font-weight-medium text-muted">
                  Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-5 p-0 img-digital grid-margin grid-margin-lg-0" data-aos="fade-left">
              <img src="assets/images/landing-page/Group1.png" alt="" class="img-fluid" />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-lg-7 text-center flex-item grid-margin" data-aos="fade-right">
              <img src="assets/images/landing-page/Group2.png" alt="" class="img-fluid" />
            </div>
            <div class="col-12 col-lg-5 flex-item grid-margin" data-aos="fade-left">
              <h3 class="m-0">Leading Digital Agency<br />for Business Solution.</h3>
              <div class="col-lg-9 col-xl-8 p-0">
                <p class="py-4 m-0 text-muted">
                  Power-packed with impressive features and well-optimized, this template is designed to provide the best
                  performance in all circumstances.
                </p>
                <p class="pb-2 font-weight-medium text-muted">
                  Its smart features make it a powerful stand-alone website building tool.
                </p>
              </div>
              <button class="btn btn-info">Readmore</button>
            </div>
          </div>
        </section> -->
    <section class="case-studies" id="case-studies-section">
      <div class="row grid-margin">
        <div class="col-12 text-center pb-5">
          <h2>Our User Manuals</h2>
          <h6 class="section-subtitle text-muted">Please refer our manuals for using the system with four main modules: Data Uploader, Study Worklist, DICOM Viewer and Report Creator.</h6>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-primary text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/upload.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around" action="https://www.w3docs.com/">
                  <div>
                    <h6 class="text-white pb-2 px-3">Know more about Data Uploader</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1EuwdE5BzSv5JGfDiZ8zWor9M7bNbv41wxlw5MKqsQ4g/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Data Uploader</h6>
                <p>About how to upload data to Google Drive</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-warning text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/worklist.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">Know more about Worklist</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1cLxUzOD_NcwJsdKevpJGdsrMunmIl33aGse-Lur-fjY/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Study Worklist</h6>
                <p>The guideline for displaying and interacting with the list of studies</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-violet text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/viewer.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">Know more about DICOM Viewer</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1j3vbmD2_dulsb_SR7rjhKjtjDZfOKoQ54O3dzqOKRF8/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">DICOM Viewer</h6>
                <p>The viewer for displaying DICOM standard image data</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card" data-aos="zoom-in" data-aos-delay="600">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-success text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/report.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">Know more about Report Creator</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1R1Qe_LieOKwy8XlqaLtAvwHwjujf1TpxEvkyc-ORrMA/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Report Creator</h6>
                <p>The application for creating radiology report</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
