import { CPU_RENDER_MODALITY, IGeneralSeries, Threads, CornerstoneMetadata } from '@app/@core';
import { RenderingEngine, utilities, Enums, Types, init, metaData } from '@cornerstonejs/core';

export class Thumbnail {
  //TODO: Need to Fix issue render CT image by viewport
  // private viewportGrid: HTMLDivElement;
  // private element1: HTMLDivElement;
  // private canvas: HTMLCanvasElement;
  // private renderingEngine: RenderingEngine;
  // private viewportId = 'THUMBNAIL';
  /**
   * a / object. User is given a name and a list of
   */
  constructor() {
    //TODO: Need to Fix issue render CT image by viewport
    // Creates a viewport with the given size and adds it to the layout. This is called during construction
    // const size = '128px';
    // this.viewportGrid = document.createElement('div');
    // this.viewportGrid.style.display = 'hidden';
    // this.viewportGrid.style.width = size;
    // this.viewportGrid.style.height = size;
    // this.element1 = document.createElement('div');
    // this.element1.style.width = size;
    // this.element1.style.height = size;
    // this.canvas = document.createElement('canvas');
    // this.canvas.width = 128;
    // this.canvas.height = 128;
    // this.viewportGrid.appendChild(this.element1);
    // document.body.appendChild(this.viewportGrid);
    // this.viewportGrid.appendChild(this.canvas);
  }

  // async init() {
  //   await init();
  //   //TODO: Need to Fix issue render CT image by viewport
  //   // // Sets the rendering engine to use for this node. Defaults to ` thumbnail `
  //   // const renderingEngineId = 'thumbnail';
  //   // this.renderingEngine = new RenderingEngine(renderingEngineId);

  //   // // Create a stack viewport
  //   // const viewportInput = {
  //   //   viewportId: this.viewportId,
  //   //   type: Enums.ViewportType.STACK,
  //   //   element: this.viewportGrid,
  //   //   defaultOptions: {
  //   //     background: <Types.Point3>[0, 0, 0],
  //   //   },
  //   // };

  //   // this.renderingEngine.enableElement(viewportInput);
  // }

  /**
   * Generates a thumbnail for DICOM Image and returns it as a data URL.
   *
   * @param imageId - The id of the image
   */
  async generate(imageId: string) {
    const canvas = <HTMLCanvasElement>await this.renderUsingCanvas(imageId);
    // This is the function that creates the thumbnail image. It takes care of the creation
    const thumbnailImg = canvas.toDataURL('image/jpeg');
    return thumbnailImg;
  }

  /**
   *Render dcm image using external canvas method
   * @param imageId
   * @returns
   */
  async renderUsingCanvas(imageId): Promise<HTMLCanvasElement> {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    //canvas.transferControlToOffscreen();
    const generalSeriesModule: IGeneralSeries = metaData.get(CornerstoneMetadata.GENERAL_SERIES, imageId) || {};
    if (CPU_RENDER_MODALITY.includes(generalSeriesModule.modality.toUpperCase())) {
      await utilities.loadImageToCanvas({ canvas, imageId, useCPURendering: true });
    } else {
      await utilities.loadImageToCanvas({ canvas, imageId });
    }
    return canvas;
  }

  /**
   *
   * @param imageId
   * @returns
   */
  async renderUsingViewport(imageId) {
    //TODO: Need to Fix issue render CT image by viewport
    const size = '256px';
    const element1 = document.createElement('div');
    element1.style.width = size;
    element1.style.height = size;
    document.body.appendChild(element1);
    // Instantiate a rendering engine
    const renderingEngineId = 'myRenderingEngine';
    const renderingEngine = new RenderingEngine(renderingEngineId);

    const viewportIds = ['CT_AXIAL_STACK_1'];
    //Center Full
    const sizeCanvas = 1;
    const pointValue = 0.5;
    const displayArea = {
      imageArea: [sizeCanvas, sizeCanvas],
      imageCanvasPoint: {
        imagePoint: [pointValue, pointValue],
        canvasPoint: [pointValue, pointValue],
      },
    };
    // Create a stack viewport
    const viewportInputArray = [
      {
        viewportId: viewportIds[0],
        type: Enums.ViewportType.STACK,
        element: element1,
        defaultOptions: {
          displayArea, // Center Full
          background: <Types.Point3>[0, 0, 0],
        },
      },
    ];
    //@ts-ignore
    renderingEngine.setViewports(viewportInputArray);

    // Get the stack viewport that was created
    const viewport1 = <Types.IStackViewport>renderingEngine.getViewport(viewportIds[0]);

    // To simulate a delay in loading the image since the loading
    // mechanisms are different for the two viewports

    await viewport1.setStack([imageId]);
    renderingEngine.renderViewports(viewportIds);
    await Threads.Instance.sleep(200);

    return viewport1.getCanvas();
  }
}
