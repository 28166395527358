import { utilities as csUtils } from '@cornerstonejs/core';
import { CornerstoneMetadata } from '../contants/metadata';

const scalingPerImageId = {};

function addInstance(imageId, scalingMetaData) {
  const imageURI = csUtils.imageIdToURI(imageId);
  scalingPerImageId[imageURI] = scalingMetaData;
}

function get(type, imageId) {
  if (type === CornerstoneMetadata.SCALING_MODLUE) {
    const imageURI = csUtils.imageIdToURI(imageId);
    return scalingPerImageId[imageURI];
  }
}

export default { addInstance, get };
