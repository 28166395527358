import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Logger } from '@app/@shared';
import { I18nService } from '@app/i18n';
import { shellActions, shellQuery } from '@app/shell';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
const log = new Logger('setting');
enum SettingTabIndex {
  General,
  Hotkeys,
  Worklist,
  UserInfo,
  AboutTeam,
}

/**
 * Represents the SettingComponent class.
 * This component is responsible for managing the settings of the application.
 */
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrl: './setting.component.scss',
})
export class SettingComponent implements OnInit, OnDestroy {
  activeIndex: number;
  visible$: Observable<boolean>;
  items: MenuItem[] | undefined;
  aboutTeamIndex = SettingTabIndex.AboutTeam;
  private onLangChangeSubs$: Subscription;
  constructor(
    private translateService: TranslateService,
    private store: Store,
    private i18nService: I18nService,
  ) {
    this.visible$ = this.store.select(shellQuery.selectSettingDialogVisible);
  }

  /**
   * Gets the current language.
   * @returns The current language.
   */
  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get className(): string {
    return `language-${this.currentLanguage.split('-')[0]}`;
  }

  /**
   * Initializes the component.
   * Sets up the items for the settings menu.
   */
  ngOnInit(): void {
    this._initMenu();
    this.onLangChangeSubs$ = this.translateService.onLangChange.subscribe((event) => {
      this._initMenu();
    });
  }

  ngOnDestroy(): void {
    if (this.onLangChangeSubs$) {
      this.onLangChangeSubs$.unsubscribe();
    }
  }

  /**
   * Closes the settings dialog.
   * @param visible - A boolean indicating whether the dialog should be visible or not.
   */
  closeDialog(visible: boolean) {
    this.store.dispatch(shellActions.changeSettingDialogVisible({ visible }));
  }

  handleClickAboutTeam() {
    this.activeIndex = SettingTabIndex.AboutTeam;
  }

  private _initMenu = () => {
    this.items = [
      {
        label: this.translateService.instant('GeneralSetting'),
        icon: 'fa-solid fa-gear',
        command: () => {
          this.activeIndex = SettingTabIndex.General;
        },
      },
      // {
      //   label: this.translateService.instant('HotKeysSetting'),
      //   icon: 'fa-solid fa-keyboard',
      //   command: () => {
      //     this.activeIndex = SettingTabIndex.Hotkeys;
      //   },
      // },
      {
        label: this.translateService.instant('WorklistSetting'),
        icon: 'fa-solid fa-table-list',
        command: () => {
          this.activeIndex = SettingTabIndex.Worklist;
        },
      },
      {
        label: this.translateService.instant('UserInfoSetting'),
        icon: 'fa-solid fa-id-card',
        command: () => {
          this.activeIndex = SettingTabIndex.UserInfo;
        },
      },
    ];
  };
}
