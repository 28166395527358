<h3 translate>ABOUTTEAM</h3>
<p translate>ABOUTTEAMDESC</p>
<hr />
<h5 translate>VersionInformation</h5>
<dl class="row">
  <dt class="col-sm-4" translate>VersionNumber</dt>
  <dd class="col-sm-8">{{ currentVersion }}</dd>

  <dt class="col-sm-4" translate>Browser</dt>
  <dd class="col-sm-8">{{ browser }}</dd>

  <dt class="col-sm-4" translate>OS</dt>
  <dd class="col-sm-8">{{ os }}</dd>
</dl>
<hr />
<h5 translate>ImportantLinks</h5>
<div class="d-flex flex-wrap gap-2">
  <a (click)="openTerms()" class="link"><span translate>Terms</span> <i class="fa-solid fa-up-right-from-square"></i></a>
  <a (click)="openPrivacy()" class="link"><span translate>Privacy</span><i class="fa-solid fa-up-right-from-square"></i></a>
  <a (click)="openCookie()" class="link"><span translate>CookiesPolicy</span><i class="fa-solid fa-up-right-from-square"></i></a>
</div>
<hr />
<h5 translate>3RDLicense</h5>
<div class="d-flex flex-wrap gap-2">
  <a class="link" (click)="openLicense()"><span translate>3RDLicenseOpen</span><i class="fa-solid fa-up-right-from-square"></i></a>
</div>

<p-dialog
  [header]="'3RDLicense' | translate"
  [modal]="true"
  [(visible)]="licenseVisible"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70rem', height: '60rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
  <pre>{{ thirdPartyLicense }}</pre>
</p-dialog>
