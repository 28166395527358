//#region General
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { RouteReusableStrategy, ApiPrefixInterceptor, SharedModule, ErrorHandlerInterceptor, GApiInitService } from '@shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { MenubarModule } from 'primeng/menubar';
import { AppRoutingModule } from './app-routing.module';
//#endregion
//#region OSS
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from '@angular/fire/firestore';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
//#endregion
//#region  vpacs Module
import { AuthModule, CredentialService } from '@app/auth';
import { WorklistModule } from './worklist/worklist.module';
import { UploadModule } from './upload/upload.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { ViewerModule } from './viewer/viewer.module';
import { SettingModule } from './setting';
import { CornerstoneService } from './@core';
import { ReportModule } from './report';
//#endregion

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    LandingPageModule,
    AuthModule,
    WorklistModule,
    UploadModule,
    ViewerModule,
    SettingModule,
    ReportModule,
    MenubarModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), trace: true }),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() =>
      initializeFirestore(getApp(), {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      }),
    ),
    GApiInitService,
    CredentialService,
    CornerstoneService,
    {
      provide: APP_INITIALIZER,
      useFactory: (cornetstoneService: CornerstoneService) => () => cornetstoneService.init(),
      deps: [CornerstoneService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (gapiInitService: GApiInitService) => () => gapiInitService.initGApi(),
      deps: [GApiInitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (credentialService: CredentialService) => () => credentialService.loadRememberSession(),
      deps: [CredentialService],
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
