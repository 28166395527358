<div class="user-info-setting container-fluid">
  <div class="row">
    <div class="user-info-setting__label col-xxl-2 col-xl-3">
      <p translate>UserInfoName</p>
    </div>
    <div class="col-xxl-10 col-xl-9">
      <div class="card flex">
        <input class="worklist-setting__order-list__form-input" type="text" pInputText [(ngModel)]="userInfo.name" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="user-info-setting__label col-xxl-2 col-xl-3">
      <p translate>UserInfoTitle</p>
    </div>
    <div class="col-xxl-10 col-xl-9">
      <div class="card flex">
        <input class="worklist-setting__order-list__form-input" type="text" pInputText [(ngModel)]="userInfo.title" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="user-info-setting__label col-xxl-2 col-xl-3">
      <p translate>UserInfoInstitution</p>
    </div>
    <div class="col-xxl-10 col-xl-9">
      <div class="card flex">
        <input class="worklist-setting__order-list__form-input" type="text" pInputText [(ngModel)]="userInfo.institution" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="user-info-setting__label col-xxl-2 col-xl-3">
      <p translate>UserInfoSignatureFile</p>
    </div>
    <div class="col-xxl-10 col-xl-9">
      <div class="card flex">
        <div class="input-group worklist-setting__order-list__form-input">
          <label class="user-info-setting__select-file-btn p-button p-button-secondary" for="select-signature-img-file" translate>BrowseFiles</label>
          <input id="select-signature-img-file" type="file" hidden accept="image/png, image/jpeg" (change)="onFileChange($event)" />
          <p class="user-info-setting__file-name">{{ this.signatureFile?.name || '' }}</p>
        </div>
      </div>
      <p class="user-info-setting__file-caption">{{ 'UserInfoSignatureLastUpdate' | translate: { date: this.lastUpdateDate } }}</p>
    </div>
  </div>
  <br />
  <br />
  <br />
  <div class="row">
    <div>
      <p translate>UserNote</p>
      <p translate>UserNoteDetail</p>
    </div>
  </div>
  <div class="worklist-setting__footer">
    <span class="worklist-setting__footer__group-actions">
      <p-button [label]="'SettingSave' | translate" (onClick)="saveUserInfo()"></p-button>
    </span>
  </div>
</div>
