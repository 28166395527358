import { createActionGroup, props, createAction, emptyProps } from '@ngrx/store';
import { IOnpremiseAPI } from '../models/IUser';
import { User, OAuthCredential } from '@angular/fire/auth';
import { IFirebaseUser } from '../models/IFirebaseUser';
import { IGoogleCredential } from '../models/IGoogleCredential';

export const firebaseAuthActions = createActionGroup({
  source: 'Firebase Auth',
  events: {
    login: emptyProps(),
    loginSuccess: props<{ firebaseRes: IFirebaseUser; googleToken: IGoogleCredential }>(),
    loginFailure: props<{ error: string }>(),
    logout: emptyProps(),
    logoutSuccess: emptyProps(),
    verify: emptyProps(),
  },
});

export const apiAuthActions = createActionGroup({
  source: 'API Auth',
  events: {
    login: props<{ username: string; password: string; rememberMe: boolean }>(),
    loginSuccess: props<{ token: string }>(),
    loginFailure: props<{ error: string }>(),
    logout: emptyProps(),
    logoutSuccess: emptyProps(),
    verify: emptyProps(),
  },
});
