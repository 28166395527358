import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { IStudyInfo } from '../../models/IStudyInfo';
import { LazyLoadEvent, MenuItem, SortEvent } from 'primeng/api';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { SearchWorklistService } from '@app/worklist/services/search.service';
import { FilterWorklistService } from '@app/worklist/services/filter.service';
import { ISearch } from '@app/worklist/models/ISearch';
import { Logger, Severity, SharedService, customDateTimeFromDayFilter, customDateTimeRangeFilter, customeDateTimeDurationFilter } from '@app/@shared';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import * as moment from 'moment';
import { FilterService } from 'primeng/api';
import { IFilterType, IFilter } from '@app/worklist/models/IFilter';
import {
  APP_ROUTE_BASE,
  DATE_TIME_RANGE_SPLIT,
  FIREBASE_FILTER_TYPE,
  getDateTimeFormat,
  MODALITY_SEPARATOR,
  MOMENT_FORMAT,
  OPEN_COMPARE_VIEWER_PATTERN,
  OPEN_REPORT_PATTERN,
  OPEN_SHARE_VIEWER_PATTERN,
  OPEN_VIEWER_PATTERN,
  READ_ONLY_PARAMS,
} from '@app/@core';
import { IWorklistData, IWorklistTableHeader } from '@app/worklist/models/IDatatable';
import { ColType } from '@app/worklist/contants/datatable';
import { Store } from '@ngrx/store';
import { worklistQuery } from '@app/worklist/store/worklist.selectors';
import { TranslateService } from '@ngx-translate/core';
import { FilterWorklist, loadWorklist, searchWorklist, openReport, closeReport, shareStudy, unshareStudy } from '@app/worklist/store/worklist.actions';
import { AuthenticationFirebaseService } from '@app/auth';
import { DicomDirService, DatabaseService } from '@app/upload/services';
import { ReportService } from '@app/report/services';
import { cloneDeep } from 'lodash';

const log = new Logger('StudyTable');
const HEADER_FIELD = {
  PATIENT_ID: 'patientid',
  NAME: 'name',
  DOB: 'dob',
  SEX: 'sex',
  STUDY_DATE: 'studydate',
  BODYPART: 'bodypart',
  MODALITY: 'modality',
  MANUFACTURE: 'manufacture',
  ACCESSION_NUMBER: 'accessionNumber',
  IMAGE_COUNT: 'imagecount',
  STUDY_INSTANCEUID: 'studyinstanceUID',
  IMAGES_DRIVEID: 'imagesdriveId',
  REPORT_DRIVEID: 'reportdriveId',
  REGISTER_DATE: 'registerdate',
  ISSHARED: 'isShared',
  ISHAVE_REPORT: 'isHaveReport',
  STUDY_DESCRIPTION: 'studyDescription',
  DICOMDIR_ID: 'dicomdirID',
};
/**
 * Represents the StudyTableComponent class.
 * This component displays a table of patient study information.
 */
@Component({
  selector: 'app-study-table',
  templateUrl: './study-table.component.html',
  styleUrls: ['./study-table.component.scss'],
  providers: [FilterService],
})
export class StudyTableComponent implements OnInit, OnDestroy {
  public cmItems: MenuItem[];
  public notification: Notification;
  public tableHeight: string = '800px';
  public scrollHeight: string = '400px';

  protected tableData$: Observable<IWorklistData[]>;
  protected tableData: IWorklistData[];
  protected totalRecords$: Observable<number>;
  protected loading = false;
  protected datetimeFormat = 'YYYY/MM/DD';
  protected isSorted: boolean | null = null;
  moment: any = moment;
  cols: IWorklistTableHeader[] = [
    { field: HEADER_FIELD.PATIENT_ID, header: 'wlPatientID', width: '100px', display: 'table-cell' },
    { field: HEADER_FIELD.NAME, header: 'wlPatientName', width: '200px', display: 'table-cell' },
    { field: HEADER_FIELD.DOB, header: 'wlPatientDoB', width: '120px', display: 'table-cell' },
    { field: HEADER_FIELD.SEX, header: 'wlPatientSex', width: '50px', display: 'table-cell' },
    { field: HEADER_FIELD.STUDY_DATE, header: 'wlStudyDate', width: '240px', display: 'table-cell', type: ColType.day },
    { field: HEADER_FIELD.BODYPART, header: 'wlStudyBodyPart', width: '150px', display: 'table-cell' },
    { field: HEADER_FIELD.MODALITY, header: 'wlStudyModality', width: '100px', display: 'table-cell' },
    { field: HEADER_FIELD.MANUFACTURE, header: 'wlStudyManufacture', width: '300px', display: 'table-cell' },
    { field: HEADER_FIELD.ACCESSION_NUMBER, header: 'wlAccessionNumber', width: '100px', display: 'table-cell' },
    { field: HEADER_FIELD.IMAGE_COUNT, header: 'wlStudyImages', width: '120px', display: 'table-cell' },
    { field: HEADER_FIELD.STUDY_INSTANCEUID, header: 'wlStudyUID', width: '500px', display: 'none' },
    { field: HEADER_FIELD.IMAGES_DRIVEID, header: 'Images Drive Id', width: '100px', display: 'none' },
    { field: HEADER_FIELD.REPORT_DRIVEID, header: 'Report Drive Id', width: '100px', display: 'none' },
    {
      field: HEADER_FIELD.REGISTER_DATE,
      header: 'wlStudyRegisterDate',
      width: '250px',
      display: 'table-cell',
      type: ColType.datetime,
    },
    {
      field: HEADER_FIELD.ISSHARED,
      header: 'wlIsShared',
      width: '50px',
      display: 'table-cell',
    },
    {
      field: HEADER_FIELD.ISHAVE_REPORT,
      header: 'wlIsHaveReport',
      width: '50px',
      display: 'table-cell',
    },
    { field: HEADER_FIELD.STUDY_DESCRIPTION, header: 'wlStudyDescription', width: '120px', display: 'table-cell' },
    { field: HEADER_FIELD.DICOMDIR_ID, header: 'Dicomdir ID', width: '100px', display: 'none' },
  ];
  @Input() patientListInfoID = '';
  @Output() selectedStudy: IWorklistData;
  @ViewChild('dt') dt: Table;
  @ViewChild('datatable') datatableView: ElementRef;

  constructor(
    private filterService: FilterService,
    private sharedService: SharedService,
    private store: Store,
    private translate: TranslateService,
    private auth: AuthenticationFirebaseService,
    private dbService: DatabaseService,
    private dicomDirService: DicomDirService,
    private reportService: ReportService,
    private clipboard: Clipboard,
    private searchWorklistService: SearchWorklistService,
  ) {}

  //#region subscribe area
  private _subscribeLangChange: Subscription;
  private _subscribeTableData: Subscription;
  private _subscribeSearch: Subscription;
  private _subscribeClear: Subscription;
  //#endregion
  /**
   * Initializes the component.
   */
  ngOnInit() {
    this.store.dispatch(loadWorklist());
    this.tableData$ = this.store.select(worklistQuery.selectWorklist);
    this.totalRecords$ = this.store.select(worklistQuery.selectTotalRecords);
    this._subscribeLangChange = this.translate.onLangChange.subscribe(() => {
      this.datetimeFormat = getDateTimeFormat(this.translate.currentLang);
    });
    // this._buildContextMenu();
    this._subscribeTableData = this.tableData$.subscribe((data) => this._onTableUpdatedData(data));
    this._subscribeSearch = this.searchWorklistService.executeSearchStudy.subscribe((search) => this.onSearch(search));
    this._subscribeClear = this.searchWorklistService.clearSearch.subscribe((search) => {
      this.dt?.clear();
    });
    // Register custom filter for datatable
    this.filterService.register(FIREBASE_FILTER_TYPE.DATE_TIME_RANGE, customDateTimeRangeFilter);
    this.filterService.register(FIREBASE_FILTER_TYPE.DATE_TIME_DAY, customDateTimeFromDayFilter);
    this.calculateTableHeight();
  }

  /**
   * Builds the menu items for the study table component.
   */
  private _buildContextMenu = (isShare: boolean = false) => {
    const sharingMenu = [
      {
        label: this.translate.instant('ShareStudy'),
        icon: 'fa-solid fa-share',
        command: (event) => this._shareStudy(this.selectedStudy),
      },
    ];
    const unshareMenu = [
      {
        label: this.translate.instant('CopyLink'),
        icon: 'fa-solid fa-link',
        command: (event) => this._copyLinkShareStudy(this.selectedStudy),
      },
      {
        label: this.translate.instant('UnshareStudy'),
        icon: 'fa-solid fa-unlink',
        command: (event) => this._unshareStudy(this.selectedStudy),
      },
    ];
    const shareMenu = isShare ? unshareMenu : sharingMenu;
    this.cmItems = [
      {
        label: this.translate.instant('OpenViewer'),
        icon: 'fa-solid fa-th-large',
        command: (event) => this._openViewer(this.selectedStudy),
      },
      {
        label: this.translate.instant('CompareStudy'),
        icon: 'fa-solid fa-code-compare',
        command: (event) => this._openCompareViewer(this.selectedStudy),
      },
      ...shareMenu,
      {
        label: this.translate.instant('OpenReport'),
        icon: 'fa-solid fa-file-text',
        command: (event) => this._openReport(this.selectedStudy),
      },

      {
        label: this.translate.instant('DeleteStudy'),
        icon: 'fa-solid fa-trash',
        command: (event) => this._deleteWorklist(this.selectedStudy),
      },
    ];
  };

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It unsubscribes from the search service, clear search service, and filter service.
   */
  ngOnDestroy(): void {
    this._subscribeSearch?.unsubscribe();
    this._subscribeLangChange?.unsubscribe();
    this._subscribeTableData?.unsubscribe();
  }

  /**
   * Adds the necessary filter to filter patients by patientId and / or patientName
   * @param payload input search information
   */
  protected onSearch = (payload: ISearch | null) => {
    if (payload === null) {
      this.dt?.clear();
      return;
    }

    this.sharedService.preloader(true);
    if (payload.patientId !== '') {
      this.dt.filter(payload.patientId, HEADER_FIELD.PATIENT_ID, FIREBASE_FILTER_TYPE.CONTAINS);
    }
    if (payload.patientName !== '') {
      this.dt.filter(payload.patientName, HEADER_FIELD.NAME, FIREBASE_FILTER_TYPE.CONTAINS);
    }
    if (payload.accessionNumber !== '') {
      this.dt.filter(payload.accessionNumber, HEADER_FIELD.ACCESSION_NUMBER, FIREBASE_FILTER_TYPE.CONTAINS);
    }
    if (payload.modalities.length > 0) {
      this.dt.filter(payload.modalities.join(MODALITY_SEPARATOR), HEADER_FIELD.MODALITY, FIREBASE_FILTER_TYPE.CONTAINS);
    }
    if (payload.studyDateFrom !== '' && payload.studyDateTo !== '') {
      this.dt.filter(`${payload.studyDateFrom}${DATE_TIME_RANGE_SPLIT}${payload.studyDateTo}`, HEADER_FIELD.STUDY_DATE, FIREBASE_FILTER_TYPE.DATE_TIME_RANGE);
    }
    this.sharedService.preloader(false);
  };

  /**
   * Callback function that is called when the table data is updated.
   * @param data - An array of IWorklistData objects representing the updated table data.
   */
  private _onTableUpdatedData = (data: IWorklistData[]) => {
    this.loading = false;
    this.tableData = cloneDeep(data);
    this.sharedService.preloader(false);
  };

  /**
   * Reverts the datatable into default data.
   * @param payload - The payload information.
   */
  protected onClear = (payload: boolean) => {
    if (payload) {
      this.dt.clear();
    }
  };
  //#endregion
  //#region  context menu
  /**
   * Open the viewer with all patient study
   * @param selectedRow current selected patient study in list
   */
  private _openViewer = async (selectedRow: IWorklistData) => {
    const isOpenReport = await firstValueFrom(this.store.select(worklistQuery.selectIsOpenReport));
    if (isOpenReport) {
      this.sharedService.toastMessage(Severity.error, this.translate.instant('WorklistReportAlreadyOpen'));
      return;
    }
    const url = OPEN_VIEWER_PATTERN(selectedRow.dicomdirID || '', selectedRow.id || '');
    this._openNewWindows(url);
  };

  /**
   * Open the viewer with compare past study of current selected study
   * @param selectedRow current selected patient study in list
   */
  private async _openCompareViewer(selectedRow: IWorklistData) {
    const isOpenReport = await firstValueFrom(this.store.select(worklistQuery.selectIsOpenReport));
    if (isOpenReport) {
      this.sharedService.toastMessage(Severity.error, this.translate.instant('WorklistReportAlreadyOpen'));
      return;
    }
    const tableData = await firstValueFrom(this.tableData$);
    // filter all study of selected patient
    const currentPatientStudy = this._sortPatientByStudyTime(tableData.filter((x) => x.patientid === selectedRow.patientid && x.studydate <= selectedRow.studydate));
    // If this patient have multi study and same study date, find the study have past study time
    if (currentPatientStudy.length > 0) {
      const pastStudy = <IWorklistData>currentPatientStudy.find((x) => {
        if (x.studydate === selectedRow.studydate && x.studytime < selectedRow.studytime) {
          return true;
        } else if (x.studydate < selectedRow.studydate) {
          return true;
        } else {
          return false;
        }
      });
      // Have past study, open the viewer
      if (pastStudy) {
        const url = OPEN_COMPARE_VIEWER_PATTERN(selectedRow.dicomdirID || '', selectedRow.id || '', pastStudy.dicomdirID || '', pastStudy.id || '');
        this._openNewWindows(url);
      } else {
        // otherwise, show message
        this.sharedService.toastMessage(Severity.error, this.translate.instant('wlNotFoundPastStudy'));
      }
    }
  }

  /**
   * Share the study to other user
   * @param selectedRow current selected patient study in list
   */
  private _shareStudy = async (selectedRow: IWorklistData) => {
    const confirm = await this.sharedService.confirmDialog(Severity.info, this.translate.instant('ShareStudyConfirm'));
    if (confirm) {
      this.sharedService.preloader(true, this.translate.instant('SharingStudy'));
      this.store.dispatch(shareStudy({ study: selectedRow }));
    }
  };

  /**
   * Copy link to share the study
   * @param selectedRow current selected patient study in list
   */
  private _copyLinkShareStudy = async (selectedRow: IWorklistData) => {
    const url = `${document.baseURI}${OPEN_SHARE_VIEWER_PATTERN(selectedRow.dicomdirID || '', selectedRow.id || '')}`;

    // Use Clipboard service to copy the URL
    this.clipboard.copy(url);
    this.sharedService.toastMessage(Severity.info, this.translate.instant('CopyLinkSuccess'));
  };

  /**
   * Handles the event when the share link is copied.
   *
   * @param selectedRow - The selected row data.
   */
  protected onCopyShareLink = (selectedRow: IWorklistData) => {
    this._copyLinkShareStudy(selectedRow);
  };

  /**
   * Unshare the study
   * @param selectedRow current selected patient study in list
   */
  private _unshareStudy = async (selectedRow: IWorklistData) => {
    const confirm = await this.sharedService.confirmDialog(Severity.info, this.translate.instant('UnshareStudyConfirm'));
    if (confirm) {
      this.sharedService.preloader(true, this.translate.instant('UnsharingStudy'));
      this.store.dispatch(unshareStudy({ study: selectedRow }));
    }
  };

  /**
   * Open the report with selected study
   * @param selectedRow current selected patient study in list
   */
  private _openReport = async (selectedRow: IWorklistData) => {
    const isOpenReport = await firstValueFrom(this.store.select(worklistQuery.selectIsOpenReport));
    if (isOpenReport) {
      this.sharedService.toastMessage(Severity.error, this.translate.instant('WorklistReportAlreadyOpen'));
      return;
    }
    //open from worklist is read only not have sharemode
    const url = OPEN_REPORT_PATTERN(selectedRow.dicomdirID || '', selectedRow.id || '', false) + `&${READ_ONLY_PARAMS}=1`;
    this._openNewWindows(url);
    this.store.dispatch(openReport());
    window.removeEventListener('message', this._listenReportWindow);
    window.addEventListener('message', this._listenReportWindow, false);
  };

  /**
   * Listens for messages from the report window and handles them accordingly.
   * @param event - The message event.
   */
  private _listenReportWindow = async (event) => {
    const origin = window.location.origin;
    // Check the origin of the message
    if (event.origin !== origin) {
      // Not the expected origin: Reject the message!
      return;
    }
    // Check the data of the message
    if (event.data === 'closed') {
      this.store.dispatch(closeReport());
      console.log('Report window closed');
    }
  };

  /**
   * Deletes a worklist entry.
   *
   * @param selectedRow - The selected row to be deleted.
   * @returns A promise that resolves when the deletion is complete.
   */
  private _deleteWorklist = async (selectedRow: IWorklistData): Promise<void> => {
    const confirm = await this.sharedService.confirmDialog(Severity.info, this.translate.instant('DeleteStudyConfirm'));
    if (confirm) {
      this.loading = true;
      //delete study in database
      await this.dbService.deleteExistStudy(selectedRow.id);
      //delete drive folder
      await this.dicomDirService.deleteStudyFolder(selectedRow.baseDirveId || '');
      //delete report
      await this.reportService.deleteReportContent(selectedRow.id);
      // force update table
      this._forceUpdateTable();
    }
  };
  /**
   * Sort study list by instance uid.
   * @param array
   */
  private _sortPatientByStudyTime(array: IStudyInfo[]): IStudyInfo[] {
    array.sort((a, b) => {
      if (a?.studydate + a?.studytime > b?.studydate + b?.studytime) {
        return -1;
      } else if (a.studydate + a.studytime > b.studydate + b.studytime) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
  //#endregion

  //#region Datatable event handler
  /**
   * Handles the event when a row is selected in the study table.
   *
   * @param event - The event object containing information about the row selection.
   */
  public onRowSelect(event: any) {}

  /**
   * Handles the selection of an item in the context menu.
   *
   * @param event - The event object containing the selected data.
   */
  public onContextMenuSelect(event: any) {
    this.selectedStudy = event.data;
    this._buildContextMenu(event.data.isShared);
  }

  /**
   * Calculates the height of the table based on the window height, page header height, page footer height, and scroll bar height.
   */
  public calculateTableHeight() {
    const pageHeaderHeight = document.getElementById('header')?.offsetHeight || 56;
    const pageFooterHeight = document.getElementById('footer')?.offsetHeight || 40;
    const scrollBarHeight = 6;
    const tableHeight = window.innerHeight - pageHeaderHeight - pageFooterHeight - scrollBarHeight;
    this.tableHeight = `${tableHeight}px`;
  }
  //#endregion

  /**
   * Opens a new window with the specified URL.
   * @param url - The URL to open in the new window.
   * @returns False to prevent the default behavior of the anchor tag.
   */
  private _openNewWindows(url) {
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - screen.availHeight) / 2 / systemZoom + dualScreenLeft;
    const top = (height - screen.availWidth) / 2 / systemZoom + dualScreenTop;
    const params = [
      'height=' + screen.height / systemZoom + 'px',
      'width=' + screen.width / systemZoom + 'px',
      'titlebar=no',
      'top=' + top,
      'left=' + left,
      'status=no',
      'menubar=no',
      'location=0',
    ].join(',');
    const newwindow = window.open(url, '_blank', params);

    newwindow?.focus();
    newwindow?.resizeTo(screen.width, screen.height);

    return false;
  }

  /**
   * Callback function that is triggered when the table needs to be refreshed.
   */
  protected onRefreshTable = () => {
    this._forceUpdateTable();
  };

  /**
   * Forces an update of the study table by creating lazy load metadata and loading the worklist.
   */
  private _forceUpdateTable() {
    this.loading = true;
    this.store.dispatch(loadWorklist());
  }

  /**
   * Custom sort function for handling the sorting state of the table.
   *
   * This function toggles the sorting state between three states: `true`, `false`, and `null`.
   * - When `isSorted` is `null` or `undefined`, it sets `isSorted` to `true` and sorts the table data.
   * - When `isSorted` is `true`, it sets `isSorted` to `false` and sorts the table data.
   * - When `isSorted` is `false`, it sets `isSorted` to `null`, resets the table data to the original state, and resets the table.
   *
   * @param event - The sorting event containing the details of the sort action.
   */
  customSort(event: SortEvent) {
    if (this.isSorted == null || this.isSorted === undefined) {
      this.isSorted = true;
      this.sortTableData(event);
    } else if (this.isSorted == true) {
      this.isSorted = false;
      this.sortTableData(event);
    } else if (this.isSorted == false) {
      this.isSorted = null;
      this.tableData$ = this.store.select(worklistQuery.selectWorklist);
      this.dt.reset();
    }
  }

  /**
   * Sorts the table data based on the specified field and order.
   *
   * @param event - The event object containing the data to be sorted, the field to sort by, and the order of sorting.
   * @param event.data - The array of data objects to be sorted.
   * @param event.field - The field name by which the data should be sorted.
   * @param event.order - The order of sorting: 1 for ascending and -1 for descending.
   *
   * The sorting logic handles various cases:
   * - If one value is null and the other is not, the null value is considered smaller.
   * - If both values are null, they are considered equal.
   * - If both values are strings, they are compared using localeCompare.
   * - For other types, a simple comparison is performed.
   *
   * @returns The sorted array of data objects.
   */
  sortTableData(event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result: any = null;
      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  //#endregion
}
