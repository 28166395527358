import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { firstValueFrom, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { reportActions } from './report.actions';
import { LoginMode, authQuery } from '@app/auth';
import { IReportInfo } from '../models';
import { PreloaderService } from '../services';
import { IFirebaseUser } from '@app/auth/models/IFirebaseUser';
import { Severity, SharedService } from '@app/@shared';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private preloader: PreloaderService,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) {}

  loadReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(reportActions.load),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginModeInfo)),
        mergeMap(async ([action, loginMode]) => {
          try {
            const userInfo = <IFirebaseUser>await firstValueFrom(this.store.select(authQuery.selectUserInfo));
            const res = await this.preloader.loadFromGoogle(action.data, userInfo);
            this.sharedService.preloader(false);
            const isReadOnly = Boolean(parseInt(action.data.readOnly || '0'));
            const isShare = Boolean(parseInt(action.data.isShare || '0'));
            return reportActions.loadSuccess({
              payload: res,
              isReadOnly,
              isShare,
            });
          } catch (error) {
            return reportActions.loadFail({ error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  reloadReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(reportActions.reload),
        mergeMap(async (action) => {
          try {
            const res = await this.preloader.reloadReportContent(action.reportId);
            this.sharedService.preloader(false);
            if (res === null) {
              return reportActions.loadFail({ error: 'Failed to reload report content.' });
            }
            this.sharedService.toastMessage(Severity.success, this.translateService.instant('ReportSavedSuccess'));
            return reportActions.reloadSuccess({
              payload: res,
            });
          } catch (error) {
            return reportActions.loadFail({ error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );
}
