import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IFilter } from '../models/IFilter';

@Injectable()
export class FilterWorklistService {
  constructor() {
    this.filterStudy = new Subject<IFilter>();
  }

  //#region  Rxjs
  public filterStudy: Subject<IFilter>;
  /** Raise event when user click on search button
   * @param payload search information
   */
  public raiseFilterStudy(payload: IFilter) {
    this.filterStudy.next(payload);
  }

  //#endregion
}
