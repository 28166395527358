import { createSelector } from '@ngrx/store';
import { selectViewerLayoutEntities } from '..';
import { ViewerLayoutState } from './layout.reducer';
import { DEFAULT_PANEL_INDEX } from '@app/viewer/contants/viewport';
import { IActivePanel, IActivePanelLayoutBackup, IActiveStack, IBackupLayout, ISelectedStack, IStackLayout, ITileLayout } from '@app/viewer/models/ILayout';
import { LAYOUT_SEPARATOR } from '@app/viewer/contants/layout';

//#region  Entities
const selectMetadataDisplay = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.metadataHidden);
const selectPanelLayout = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.panelLayout);
const selectStackLayout = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.stackLayout);
const selectTileLayout = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.tileLayout);
const selectActiveStack = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.activeStack);
const selectActivePanel = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.activePanel);
const selectActiveTile = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.activeTile);
const selectSelectedStack = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.selectedStack);
const selectIsWatingRender = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.isWating);
const selectBackupLayout = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.backup);
const selectActivePanelLayoutBackup = createSelector(selectViewerLayoutEntities, (state: ViewerLayoutState) => state.activePanelLayoutBackup);
//#endregion
//#region Models selectors
/**
 * Selects the stack layout for a specific panel index.
 *
 * @param panelIndex - The index of the panel.
 * @returns The stack layout for the specified panel index.
 */
const selectStackLayoutByPanelIndex = (panelIndex: string) => {
  return createSelector(selectStackLayout, (state: IStackLayout[]) => state.find((stack) => stack.panelId === panelIndex));
};

/**
 * Selects the current active panel index from the state.
 * If the active panel is undefined, it returns the default panel index.
 *
 * @returns The current active panel index.
 */
const selectCurrentActivePanelIndex = createSelector(selectActivePanel, (state: IActivePanel | undefined) => state?.panelIndex || DEFAULT_PANEL_INDEX);

/**
 * Selects the layout of the current active panel.
 * @returns The layout of the current active panel.
 */
const selectCurrentActivePanelLayout = createSelector(selectActivePanel, (state: IActivePanel | undefined) => state?.layout);

/**
 * Selects the series UID for the specified stack index.
 * @param stackIndex The index of the stack.
 * @returns The series UID of the selected stack, or an empty string if not found.
 */
const selectStackSelectedSeriesUid = (stackIndex: string) => {
  return createSelector(selectSelectedStack, (state: ISelectedStack[]) => state.find((stack) => stack.stackIndex === stackIndex)?.seriesUid || '');
};

/**
 * Selects the series UID of the active stack.
 * @returns The series UID of the active stack, or undefined if there is no active stack.
 */
const selectStackActiveSeriesUid = createSelector(selectActiveStack, (state: IActiveStack | undefined) => state?.seriesUid);

/**
 * Selects the tile layout by stack index.
 * @param stackIndex The stack index to filter by.
 * @returns The tile layout matching the stack index.
 */
const selectTileLayoutByStackIndex = (stackIndex: string) => {
  return createSelector(selectTileLayout, (state) => state.find((tile) => tile.stackId === stackIndex));
};

const selectLayout = createSelector(
  selectPanelLayout,
  selectStackLayout,
  selectTileLayout,
  (panelLayout, stackLayout, tileLayout) =>
    ({
      panelLayout,
      stackLayout,
      tileLayout,
    }) as IBackupLayout,
);

const selectActivePanelLayout = (panelIndex: string) =>
  createSelector(selectStackLayout, selectTileLayout, (stack, tile) => {
    const stackLayout = stack.find((stack) => stack.panelId === panelIndex);
    const tileLayout = tile.filter((tile) => tile.stackId.startsWith(`${stackLayout?.id}`, 0));
    return { stackLayout, tileLayout } as IActivePanelLayoutBackup;
  });

//#endregion
export const ViewerLayoutQuery = {
  selectMetadataDisplay,
  selectPanelLayout,
  selectStackLayout,
  selectTileLayout,
  selectActiveStack,
  selectActivePanel,
  selectActiveTile,
  selectIsWatingRender,
  selectSelectedStack,
  selectBackupLayout,
  selectActivePanelLayoutBackup,
  selectStackLayoutByPanelIndex,
  selectCurrentActivePanelIndex,
  selectCurrentActivePanelLayout,
  selectStackSelectedSeriesUid,
  selectStackActiveSeriesUid,
  selectTileLayoutByStackIndex,
  selectLayout,
  selectActivePanelLayout,
};
