import { LAYOUT_SEPARATOR, VIEWPORT_INDEX_SEPERATOR } from '@app/viewer/contants';

/**
 * Retrieves the stack index from the given tile index.
 *
 * @param tileIndex - The tile index to extract the stack index from.
 * @returns The stack index extracted from the tile index.
 */
const getStackIndexFromTileIndex = (tileIndex: string): string => {
  return tileIndex.split(VIEWPORT_INDEX_SEPERATOR).slice(0, 2).join(VIEWPORT_INDEX_SEPERATOR);
};

/**
 * Retrieves the panel index from a given tile index.
 * @param tileIndex - The tile index.
 * @returns The panel index.
 */
const getPanelIndexFromTileIndex = (tileIndex: string): string => {
  return tileIndex.split(VIEWPORT_INDEX_SEPERATOR)[0];
};

/**
 * Retrieves the panel index from the stack index.
 *
 * @param stackIndex - The stack index.
 * @returns The panel index.
 */
const getPanelIndexFromStackIndex = (stackIndex: string): string => {
  return stackIndex.split(LAYOUT_SEPARATOR)[0];
};

export { getStackIndexFromTileIndex, getPanelIndexFromTileIndex, getPanelIndexFromStackIndex };
