import { Injectable } from '@angular/core';
import { LoginMode } from './models/login-mode';
import { AuthenticationFirebaseService } from './authentication.firebase.service';
import { Store } from '@ngrx/store';
import { firebaseAuthActions } from './store/auth.actions';
const LOGIN_MODE = 'loginMode';
/**
 * Service responsible for handling credentials and authentication.
 */
@Injectable({ providedIn: 'root' })
/**
 * Service responsible for managing user credentials and authentication.
 */
export class CredentialService {
  constructor(
    private authFirebaseService: AuthenticationFirebaseService,
    private store: Store,
  ) {}

  private _isLoggin: boolean = false;

  /**
   * Gets the login status of the user.
   */
  public get isLoggin(): boolean {
    return this._isLoggin;
  }

  /**
   * Sets the login status of the user.
   * @param v The login status value.
   */
  public set isLoggin(v: boolean) {
    this._isLoggin = v;
  }

  /**
   * Loads the remember session for the user.
   * If the login mode is firebase, it verifies the token and dispatches the appropriate actions.
   */
  async loadRememberSession() {
    const loginMode = parseInt(localStorage.getItem(LOGIN_MODE) || '');
    if (loginMode === LoginMode.firebase) {
      const firebaseUser = this.authFirebaseService.getUserInfoStored();
      const gApiCredentials = this.authFirebaseService.getGAPIOauth();

      const tokenStatus = await this.authFirebaseService.verifyToken(gApiCredentials.idToken);
      if (tokenStatus) {
        this._isLoggin = true;
        this.store.dispatch(firebaseAuthActions.loginSuccess({ firebaseRes: firebaseUser, googleToken: gApiCredentials }));
      } else {
        this._isLoggin = false;
        this.authFirebaseService.onLogout();
      }
    }

    if (loginMode === LoginMode.onPremise) {
      //TODO: implement later
    }
  }
}
