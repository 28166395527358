import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AutoPlay, BroadcastTool, CameraAction, Position, SortCommand } from '../contants';
export interface ICameraBroadcast {
  action: CameraAction;
  position?: Position;
}
@Injectable()
/**
 * Service responsible for broadcasting events to subscribers.
 */
export class BroadcastService {
  constructor() {
    this._voi = new Subject<boolean>();
    this._camera = new Subject<ICameraBroadcast>();
    this._tool = new Subject<BroadcastTool>();
    this._colormap = new Subject<string>();
    this._autoPlay = new Subject<{ action: AutoPlay; fps: number }>();
    this._sortImage = new Subject<SortCommand>();
    this._keyImage = new Subject<boolean>();
    this._changeDisplayViewport = new Subject<boolean>();
  }

  /**
   * Represents a private subject for broadcasting camera updates.
   */
  private _camera: Subject<ICameraBroadcast>;
  /**
   * A subject used for broadcasting the inversion status of the VOI (Value of Interest).
   */
  private _voi: Subject<boolean>;
  /**
   * Represents the private subject for broadcasting the tool.
   */
  private _tool: Subject<BroadcastTool>;

  /**
   * Represents a subject for broadcasting colormap values.
   */
  private _colormap: Subject<string>;

  /**
   * Represents a subject for auto play.
   */
  private _autoPlay: Subject<{ action: AutoPlay; fps: number }>;

  /**
   * Represents a subject for sorting commands.
   */
  private _sortImage: Subject<SortCommand>;

  /**
   * A subject used for broadcasting key image updates.
   */
  private _keyImage: Subject<boolean>;

  /**
   * Represents a subject for broadcasting the display viewport.
   */
  private _changeDisplayViewport = new Subject<boolean>();

  /**
   * Gets an observable for VOI (Value of Interest) inversion broadcasts.
   */
  public get voi() {
    return this._voi.asObservable();
  }

  /**
   * Gets an observable for camera broadcasts.
   */
  public get camera() {
    return this._camera.asObservable();
  }

  /**
   * Gets an observable for tool broadcasts.
   */
  public get tool() {
    return this._tool.asObservable();
  }

  /**
   * Gets the observable for the colormap.
   * @returns An observable that emits the colormap.
   */
  public get colormap() {
    return this._colormap.asObservable();
  }

  /**
   * Gets the observable for the autoPlay property.
   * @returns An observable that emits the value of the autoPlay property.
   */
  public get autoPlay() {
    return this._autoPlay.asObservable();
  }

  /**
   * Gets the observable for sorting images.
   * @returns An observable that emits the sorted images.
   */
  public get sortImage() {
    return this._sortImage.asObservable();
  }

  /**
   * Gets the observable for the key image.
   * @returns An observable that emits the key image.
   */
  public get keyImage() {
    return this._keyImage.asObservable();
  }

  /**
   * Gets the observable for the display viewport.
   * @returns An observable that emits the display viewport.
   */
  public get changeDisplayViewport() {
    return this._changeDisplayViewport.asObservable();
  }

  /**
   * Inverts the VOI (Value of Interest) broadcast.
   * @param isReset - Indicates whether to reset the VOI inversion.
   */
  public voiBroadcast = (isReset: boolean = false) => {
    this._voi.next(isReset);
  };

  /**
   * Broadcasts a camera action with an optional position.
   * @param action - The camera action to broadcast.
   * @param position - The optional position associated with the camera action.
   */
  public cameraBroadcast = (action: CameraAction, position?: Position) => {
    this._camera.next({ action, position });
  };

  /**
   * Broadcasts the specified tool to subscribers.
   * @param tool - The tool to be broadcasted.
   */
  public toolBroadcast = (tool: BroadcastTool) => {
    this._tool.next(tool);
  };

  /**
   * Broadcasts the specified colormap to subscribers.
   * @param colormap - The colormap to broadcast.
   */
  public colormapBroadcast = (colormap: string) => {
    this._colormap.next(colormap);
  };

  /**
   * Sets the auto play action for the broadcast.
   * @param action - The auto play action to set.
   */
  public autoPlayBroadcast = (action: AutoPlay, fps: number = 2) => {
    this._autoPlay.next({ action, fps });
  };

  /**
   * Broadcasts the sorted images.
   */
  public sortImageBroadcast = (sortType: SortCommand) => {
    this._sortImage.next(sortType);
  };

  /**
   * Broadcasts the key image.
   */
  public keyImageBroadcast = (isKeyImage: boolean) => {
    this._keyImage.next(isKeyImage);
  };

  /**
   * Broadcasts the display viewport.
   * @param isChangeAll - Indicates whether to change all viewports.
   */
  public changeDisplayViewportBroadcast = (isChangeAll: boolean = false) => {
    this._changeDisplayViewport.next(isChangeAll);
  };
}
