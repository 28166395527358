/**
 * Enum the sort oder
 */
export enum SortOrder {
  Ascending,
  Descending,
}

export enum SortScope {
  None,
  SelectedStacks,
  ActivePanel,
  AllStacks,
}
