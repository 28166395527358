<div #datatable class="worklist-study-table">
  <p-table
    #dt
    [columns]="cols"
    [value]="tableData"
    [paginator]="true"
    [rows]="20"
    [totalRecords]="(totalRecords$ | async)!"
    [scrollable]="true"
    selectionMode="single"
    [(selection)]="selectedStudy"
    [loading]="loading"
    [contextMenu]="cm"
    (onRowSelect)="onRowSelect($event)"
    (onContextMenuSelect)="onContextMenuSelect($event)"
    [scrollHeight]="tableHeight"
    [resizableColumns]="true"
    (sortFunction)="customSort($event)"
    [customSort]="true"
    columnResizeMode="expand"
    styleClass="p-datatable-gridlines p-datatable-sm"
    id="study-table"
  >
    <ng-template #header pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th [ngStyle]="{ display: col.display, width: col.width }" [pSortableColumn]="col.field" pResizableColumn>{{ col.header | translate }} <p-sortIcon [field]="col.field" /></th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="table-row-nodata">
        <td [attr.colspan]="columns.length" translate>wlNoStudyFound</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-studyInfo let-columns="columns">
      <tr [pSelectableRow]="studyInfo" [pContextMenuRow]="studyInfo">
        <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">
          <td *ngSwitchCase="'studydate'" [ngStyle]="{ display: col.display, width: col.width }">
            {{ moment(studyInfo[col.field] || '', 'YYYYMMDD').format(datetimeFormat) || '' }}
            {{ moment(studyInfo['studytime'], 'hhmmss').format('HH:mm:ss') || '' }}
          </td>
          <td *ngSwitchCase="'sex'" [ngStyle]="{ display: col.display, width: col.width }">
            {{ studyInfo[col.field] | translate }}
          </td>
          <td *ngSwitchCase="'registerdate'" [ngStyle]="{ display: col.display, width: col.width }">
            {{ moment.unix(studyInfo[col.field].seconds).format(datetimeFormat + ' HH:mm:ss') }}
          </td>
          <td *ngSwitchCase="'isShared'" [ngStyle]="{ display: col.display, width: col.width }">
            <i *ngIf="studyInfo[col.field] === true" class="fa-solid fa-link"></i>
          </td>
          <td *ngSwitchCase="'isHaveReport'" [ngStyle]="{ display: col.display, width: col.width }">
            <i *ngIf="studyInfo[col.field] === true" class="fa-solid fa-file-circle-check"></i>
          </td>
          <td *ngSwitchDefault [ngStyle]="{ display: col.display, width: col.width }">
            {{ studyInfo[col.field] }}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <p-button type="button" icon="fa-solid fa-arrows-rotate" styleClass="p-button-text" [label]="'refresh' | translate" (onClick)="onRefreshTable()" />
    </ng-template>
  </p-table>
</div>

<p-contextMenu #cm [model]="cmItems"></p-contextMenu>
