<div class="worklist-setting">
  <div class="worklist-setting__body card xl:flex">
    <p-orderList #settingList [value]="worklistSetting" [header]="'PresetSetting' | translate" [dragdrop]="true" (onReorder)="onListChange($event)" class="worklist-setting__order-list">
      <ng-template let-setting pTemplate="item">
        <div class="worklist-setting__order-list__item">
          <div class="worklist-setting__order-list__form-control flex-1 flex">
            <label translate>presetName</label>
            <input class="worklist-setting__order-list__form-input" type="text" pInputText [(ngModel)]="setting.name" />
          </div>

          <p-button severity="danger" icon="pi pi-times" (onClick)="deleteFilter(setting.name)"></p-button>
        </div>
      </ng-template>
    </p-orderList>
  </div>
  <div class="worklist-setting__footer">
    <span class="worklist-setting__footer__group-actions">
      <p-button [label]="'Apply' | translate" [disabled]="!isChange" (onClick)="saveChange($event)"></p-button>
      <p-button severity="secondary" [label]="'Cancel' | translate" [disabled]="!isChange" (onClick)="cancelChange($event)"></p-button
    ></span>
  </div>
</div>
