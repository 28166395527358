/**
 * An object that maps image loader names to their corresponding URI schemes.
 * @readonly
 * @type {Object<string, string>}
 */
export const IMAGE_LOADER = {
  /**
   * The WADO-URI scheme for loading images.
   * @type {string}
   */
  WADO_URI: 'wadouri:',
  /**
   * The WADO-RS scheme for loading images.
   * @type {string}
   */
  WADO_RS: 'wadors:',
};

export const RENDER_ENGINE_ID = 'vpacs';

// Loader id which defines which volume loader to use
export const VOLUME_LOADER_SCHEME = 'cornerstoneStreamingImageVolume';

/**
 * Generates a volume ID pattern based on the provided name.
 * @param name - The name used to generate the volume ID pattern.
 * @returns The generated volume ID pattern.
 */
export const VOLUME_ID_PATTERN = (name: string) => `${VOLUME_LOADER_SCHEME}:${name}`;

export const VOLUME_3D_NAME = '3D';
