import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorklistComponent } from './worklist.component';
import { StudyTableComponent } from './pages/study-table/study-table.component';
import { PresetComponent } from './pages/preset/preset.component';
import { WorklistRoutingModule } from './worklist-routing.module';
import { I18nModule } from '@app/i18n';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { SearchComponent } from './pages/search/search.component';
import { FormsModule } from '@angular/forms';
import { SearchWorklistService } from './services/search.service';
import { FilterWorklistService } from './services/filter.service';
import { WorklistFirebaseService } from './services/worklist.firebase.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { WorklistEffects } from './store/worklist.effects';
import { worklistFeature } from './store/worklist.reducer';
import { RxFor } from '@rx-angular/template/for';

import { RxRenderStrategiesConfig, RX_RENDER_STRATEGIES_CONFIG } from '@rx-angular/cdk/render-strategies';
import { DialogModule } from 'primeng/dialog';

const FEATURE_RX_ANGULAR_CONFIG: RxRenderStrategiesConfig<string> = {
  primaryStrategy: 'immediate',
  patchZone: false,
};

@NgModule({
  declarations: [WorklistComponent, StudyTableComponent, SearchComponent, PresetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    RouterModule,
    SharedModule,
    TableModule,
    ContextMenuModule,
    WorklistRoutingModule,
    InputTextModule,
    FormsModule,
    CalendarModule,
    ButtonModule,
    MultiSelectModule,
    SelectButtonModule,
    DropdownModule,
    DialogModule,
    RxFor,
    StoreModule.forFeature(worklistFeature),
    EffectsModule.forFeature(WorklistEffects),
  ],
  providers: [
    {
      provide: RX_RENDER_STRATEGIES_CONFIG,
      useValue: FEATURE_RX_ANGULAR_CONFIG,
    },
    SearchWorklistService,
    FilterWorklistService,
    WorklistFirebaseService,
  ],
})
export class WorklistModule {}
