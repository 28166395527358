<div class="d-flex flex-column gap-2">
  <span class="search-form-item">
    <label class="search-form-item__label" translate>wlPatientID</label>
    <input class="search-form-item__input" type="text" pInputText [(ngModel)]="patientId"
  /></span>
  <span class="search-form-item">
    <label class="search-form-item__label" translate>wlPatientName</label>
    <input class="search-form-item__input" type="text" pInputText [(ngModel)]="patientName"
  /></span>
  <span class="search-form-item">
    <label class="search-form-item__label" translate>wlAccessionNumber</label>
    <input class="search-form-item__input" type="text" pInputText [(ngModel)]="accessionNumber"
  /></span>

  <ng-container>
    <span class="search-form-item">
      <label class="search-form-item__label" translate>wlStudyDate</label>
      <div class="d-flex justify-content-center align-items-center gap-2">
        <p-calendar class="search-form-item__calendar" [(ngModel)]="studyDateFrom" [showButtonBar]="true" appendTo="body" (onSelect)="onStudyFromBlur()"></p-calendar>
        <span>~</span>
        <p-calendar class="search-form-item__calendar" [(ngModel)]="studyDateTo" [minDate]="studyDateFrom" [showButtonBar]="true" appendTo="body" (onSelect)="onStudyToBlur()"></p-calendar></div
    ></span>
  </ng-container>
  <ng-container>
    <span class="search-form-item">
      <label class="search-form-item__label" translate>wlStudyDuration</label>
      <p-dropdown
        [options]="studyDuration"
        [(ngModel)]="selectStudyDuration"
        [placeholder]="'SelectStudyDuration' | translate"
        optionLabel="name"
        showClear="true"
        class="search-form-item__select"
        (onChange)="onStudyDurationBlur()"
        [showClear]="true"
    /></span>
  </ng-container>

  <span class="search-form-item">
    <label class="search-form-item__label" translate>wlModality</label>
    <p-multiSelect
      [options]="modalities"
      [(ngModel)]="selectedModality"
      [placeholder]="'SelectModality' | translate"
      optionLabel="name"
      showClear="true"
      display="chip"
      class="search-form-item__multiselect"
      [showClear]="true"
  /></span>
  <div class="search-form-footer">
    <p-button severity="secondary" class="search-form-footer__action" [label]="'Search' | translate" icon="pi pi-search" (onClick)="search()"></p-button>
    <p-button severity="secondary" class="search-form-footer__action" [label]="'Clear' | translate" icon="pi pi-filter-slash" (onClick)="Clear()"></p-button>
  </div>
</div>
