import { Component, OnInit, AfterContentInit, OnDestroy, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger, RouteHelpers, SharedService } from '@shared';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { Thumbnail } from '@app/@core';
import { Router } from '@angular/router';
import { reportActions } from './store';

const log = new Logger('report');
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, AfterContentInit {
  protected selectedStudyUid: string = '';

  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private store: Store,
    private route: Router,
  ) {}

  //#region properties

  //#endregion
  //#region Lifecyle hooks Area
  ngOnInit() {
    // Otherwise, it hides the preloader.
    const driveRouter = RouteHelpers.Instance.collectRouteQueryParams(this.route);
    if (driveRouter.main) {
      this.sharedService.preloader(true, this.translateService.instant('LoadingReport'));
      this.store.dispatch(reportActions.load({ data: driveRouter }));
    } else {
      this.sharedService.preloader(false);
    }
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized the content of the component's view.
   * It is called only once after the first ngAfterViewInit.
   * Use this hook to perform any additional initialization tasks.
   */
  ngAfterContentInit() {}

  //#endregion

  /**
   * Handles the event when a study is selected.
   *
   * @param $event - The event object containing the selected study UID.
   */
  onSelectedStudy($event: any) {
    this.selectedStudyUid = $event.uid;
  }

  //handle global mouse click event
  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    // do something
    console.log('click event report');
    document.body.focus();
  }
}
