import { AnnotationTool, Types, utilities, cursors, state, Enums, drawing, annotation } from '@cornerstonejs/tools';
import { Types as CoreTypes } from '@cornerstonejs/core';
import { getEnabledElement, utilities as csUtils } from '@cornerstonejs/core';
import { EllipseAnnotation, RectangleAnnotation } from './ToolSpecificAnnotationTypes';
import { triggerAnnotationCompleted } from './state';
const { drawHandles, drawEllipseByCoordinates } = drawing;
const { isAnnotationLocked } = annotation.locking;
const { addAnnotation, getAnnotations, removeAnnotation } = annotation.state;
const { isAnnotationVisible } = annotation.visibility;

class EllipseAnnotateTool extends AnnotationTool {
  static override toolName;

  touchDragCallback: any;
  mouseDragCallback: any;
  _throttledCalculateCachedStats: any;
  editData: {
    annotation: any;
    viewportIdsToRender: Array<string>;
    handleIndex?: number;
    movingTextBox?: boolean;
    centerWorld?: Array<number>;
    canvasWidth?: number;
    canvasHeight?: number;
    originalHandleCanvas?: Array<number>;
    newAnnotation?: boolean;
    hasMoved?: boolean;
  } | null;
  isDrawing: boolean;
  isHandleOutsideImage = false;

  constructor(
    toolProps: Types.PublicToolProps = {},
    defaultToolProps: Types.ToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: false,
        // Radius of the circle to draw  at the center point of the ellipse.
        // Set this zero(0) in order not to draw the circle.
        centerPointRadius: 0,
      },
    },
  ) {
    super(toolProps, defaultToolProps);
  }

  /**
   * Based on the current position of the mouse and the current imageId to create
   * a EllipticalROI Annotation and stores it in the annotationManager
   *
   * @param evt -  EventTypes.NormalizedMouseEventType
   * @returns The annotation object.
   *
   */
  addNewAnnotation = (evt: Types.EventTypes.InteractionEventType): EllipseAnnotation => {
    const eventDetail = evt.detail;
    const { currentPoints, element } = eventDetail;
    const worldPos = currentPoints.world;
    const canvasPos = currentPoints.canvas;

    const enabledElement = getEnabledElement(element);
    const { viewport, renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

    this.isDrawing = true;

    const camera = viewport.getCamera();
    const { viewPlaneNormal = [0, 0, 0], viewUp = [0, 0, 0] } = camera;

    const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

    const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();

    const annotation = {
      highlighted: true,
      invalidated: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: <CoreTypes.Point3>[...viewPlaneNormal],
        viewUp: <CoreTypes.Point3>[...viewUp],
        referencedImageId,
        ...viewport.getViewReference({ points: [worldPos] }),
      },
      data: {
        label: '',
        handles: {
          points: [[...worldPos], [...worldPos], [...worldPos], [...worldPos]] as [CoreTypes.Point3, CoreTypes.Point3, CoreTypes.Point3, CoreTypes.Point3],
          activeHandleIndex: null,
        },
        initialRotation: viewport.getRotation(),
      },
    };

    addAnnotation(annotation, element);

    const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(element, this.getToolName());

    this.editData = {
      annotation,
      viewportIdsToRender,
      centerWorld: worldPos,
      newAnnotation: true,
      hasMoved: false,
    };
    this._activateDraw(element);

    cursors.elementCursor.hideElementCursor(element);

    evt.preventDefault();

    utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  };

  /**
   * It returns if the canvas point is near the provided annotation in the provided
   * element or not. A proximity is passed to the function to determine the
   * proximity of the point to the annotation in number of pixels.
   *
   * @param element - HTML Element
   * @param annotation - Annotation
   * @param canvasCoords - Canvas coordinates
   * @param proximity - Proximity to tool to consider
   * @returns Boolean, whether the canvas point is near tool
   */
  isPointNearTool = (element: HTMLDivElement, annotation: EllipseAnnotation, canvasCoords: CoreTypes.Point2, proximity: number): boolean => {
    const enabledElement = getEnabledElement(element);
    const { viewport } = <CoreTypes.IEnabledElement>enabledElement;

    const { data } = annotation;
    const { points } = data.handles;

    // For some reason Typescript doesn't understand this, so we need to be
    // more specific about the type
    const canvasCoordinates = points.map((p) => viewport.worldToCanvas(p)) as [CoreTypes.Point2, CoreTypes.Point2, CoreTypes.Point2, CoreTypes.Point2];
    const canvasCorners = utilities.math.ellipse.getCanvasEllipseCorners(canvasCoordinates);

    const [canvasPoint1, canvasPoint2] = canvasCorners;

    const minorEllipse = {
      left: Math.min(canvasPoint1[0], canvasPoint2[0]) + proximity / 2,
      top: Math.min(canvasPoint1[1], canvasPoint2[1]) + proximity / 2,
      width: Math.abs(canvasPoint1[0] - canvasPoint2[0]) - proximity,
      height: Math.abs(canvasPoint1[1] - canvasPoint2[1]) - proximity,
    };

    const majorEllipse = {
      left: Math.min(canvasPoint1[0], canvasPoint2[0]) - proximity / 2,
      top: Math.min(canvasPoint1[1], canvasPoint2[1]) - proximity / 2,
      width: Math.abs(canvasPoint1[0] - canvasPoint2[0]) + proximity,
      height: Math.abs(canvasPoint1[1] - canvasPoint2[1]) + proximity,
    };

    const pointInMinorEllipse = this._pointInEllipseCanvas(minorEllipse, canvasCoords);
    const pointInMajorEllipse = this._pointInEllipseCanvas(majorEllipse, canvasCoords);

    if (pointInMajorEllipse && !pointInMinorEllipse) {
      return true;
    }

    return false;
  };

  toolSelectedCallback = (evt: Types.EventTypes.InteractionEventType, annotation: EllipseAnnotation): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    annotation.highlighted = true;

    const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(element, this.getToolName());

    this.editData = {
      annotation,
      viewportIdsToRender,
      movingTextBox: false,
    };

    cursors.elementCursor.hideElementCursor(element);

    this._activateModify(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

    utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    evt.preventDefault();
  };

  handleSelectedCallback = (evt: Types.EventTypes.InteractionEventType, annotation: EllipseAnnotation, handle: Types.ToolHandle): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const { data } = annotation;

    annotation.highlighted = true;

    let handleIndex;

    let centerCanvas;
    let centerWorld;
    let canvasWidth;
    let canvasHeight;
    let originalHandleCanvas;

    const { points } = data.handles;
    const { viewport } = <CoreTypes.IEnabledElement>getEnabledElement(element);
    const { worldToCanvas, canvasToWorld } = viewport;

    handleIndex = points.findIndex((p) => p === handle);

    const pointsCanvas = points.map(worldToCanvas);

    originalHandleCanvas = pointsCanvas[handleIndex];

    canvasWidth = Math.abs(pointsCanvas[2][0] - pointsCanvas[3][0]);
    canvasHeight = Math.abs(pointsCanvas[0][1] - pointsCanvas[1][1]);

    centerCanvas = [(pointsCanvas[2][0] + pointsCanvas[3][0]) / 2, (pointsCanvas[0][1] + pointsCanvas[1][1]) / 2];

    centerWorld = canvasToWorld(centerCanvas);

    // Find viewports to render on drag.
    const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(element, this.getToolName());

    this.editData = {
      annotation,
      viewportIdsToRender,
      handleIndex,
      canvasWidth,
      canvasHeight,
      centerWorld,
      originalHandleCanvas,
    };
    this._activateModify(element);

    cursors.elementCursor.hideElementCursor(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

    utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    evt.preventDefault();
  };

  _endCallback = (evt: Types.EventTypes.InteractionEventType): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    const editData = this.editData;

    if (editData) {
      const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = editData;
      const { data } = annotation;

      if (newAnnotation && !hasMoved) {
        return;
      }

      data.handles.activeHandleIndex = null;

      this._deactivateModify(element);
      this._deactivateDraw(element);

      cursors.elementCursor.resetElementCursor(element);

      const { renderingEngine } = <CoreTypes.IEnabledElement>getEnabledElement(element);

      this.editData = null;
      this.isDrawing = false;

      if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
        removeAnnotation(annotation.annotationUID);
      }

      utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

      if (newAnnotation) {
        triggerAnnotationCompleted(annotation);
      }
    }
  };

  _dragDrawCallback = (evt: Types.EventTypes.InteractionEventType): void => {
    this.isDrawing = true;
    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const { currentPoints } = eventDetail;
    const currentCanvasPoints = currentPoints.canvas;
    const enabledElement = <CoreTypes.IEnabledElement>getEnabledElement(element);
    const { renderingEngine, viewport } = enabledElement;
    const { canvasToWorld } = viewport;
    const editData = this.editData;
    if (editData) {
      //////
      const { annotation, viewportIdsToRender, centerWorld } = editData;
      const centerCanvas = viewport.worldToCanvas(centerWorld as CoreTypes.Point3);
      const { data } = annotation;

      const dX = Math.abs(currentCanvasPoints[0] - centerCanvas[0]);
      const dY = Math.abs(currentCanvasPoints[1] - centerCanvas[1]);

      // Todo: why bottom is -dY, it should be +dY
      const bottomCanvas = <CoreTypes.Point2>[centerCanvas[0], centerCanvas[1] - dY];
      const topCanvas = <CoreTypes.Point2>[centerCanvas[0], centerCanvas[1] + dY];
      const leftCanvas = <CoreTypes.Point2>[centerCanvas[0] - dX, centerCanvas[1]];
      const rightCanvas = <CoreTypes.Point2>[centerCanvas[0] + dX, centerCanvas[1]];

      data.handles.points = [canvasToWorld(bottomCanvas), canvasToWorld(topCanvas), canvasToWorld(leftCanvas), canvasToWorld(rightCanvas)];

      annotation.invalidated = true;

      editData.hasMoved = true;

      utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
    }
  };

  _dragModifyCallback = (evt: Types.EventTypes.InteractionEventType): void => {
    this.isDrawing = true;
    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const editData = this.editData;
    if (editData) {
      const { annotation, viewportIdsToRender, handleIndex, movingTextBox } = editData;
      const { data } = annotation;

      if (movingTextBox) {
        const { deltaPoints } = eventDetail;
        const worldPosDelta = deltaPoints.world;

        const { textBox } = data.handles;
        const { worldPosition } = textBox;

        worldPosition[0] += worldPosDelta[0];
        worldPosition[1] += worldPosDelta[1];
        worldPosition[2] += worldPosDelta[2];

        textBox.hasMoved = true;
      } else if (handleIndex === undefined) {
        // Moving tool
        const { deltaPoints } = eventDetail;
        const worldPosDelta = deltaPoints.world;

        const points = data.handles.points;

        points.forEach((point) => {
          point[0] += worldPosDelta[0];
          point[1] += worldPosDelta[1];
          point[2] += worldPosDelta[2];
        });
        annotation.invalidated = true;
      } else {
        this._dragHandle(evt);
        annotation.invalidated = true;
      }

      const enabledElement = getEnabledElement(element);
      const { renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

      utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
    }
  };

  _dragHandle = (evt: Types.EventTypes.InteractionEventType): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const { viewport } = <CoreTypes.IEnabledElement>getEnabledElement(element);
    const { canvasToWorld, worldToCanvas } = viewport;
    const editData = this.editData;
    if (editData) {
      const { annotation, canvasWidth, canvasHeight, handleIndex, centerWorld, originalHandleCanvas } = editData;
      const centerCanvas = viewport.worldToCanvas(centerWorld as CoreTypes.Point3);
      const { data } = annotation;
      const { points } = data.handles;

      // Move current point in that direction.
      // Move other points in opposite direction.

      const { currentPoints } = eventDetail;
      const currentCanvasPoints = currentPoints.canvas;

      if (handleIndex === 0 || handleIndex === 1) {
        // Dragging top or bottom point
        const dYCanvas = Math.abs(currentCanvasPoints[1] - centerCanvas[1]);
        const canvasBottom = <CoreTypes.Point2>[centerCanvas[0], centerCanvas[1] - dYCanvas];
        const canvasTop = <CoreTypes.Point2>[centerCanvas[0], centerCanvas[1] + dYCanvas];

        points[0] = canvasToWorld(canvasBottom);
        points[1] = canvasToWorld(canvasTop);
        if (originalHandleCanvas && canvasWidth) {
          const dXCanvas = currentCanvasPoints[0] - originalHandleCanvas[0];
          const newHalfCanvasWidth = canvasWidth / 2 + dXCanvas;
          const canvasLeft = <CoreTypes.Point2>[centerCanvas[0] - newHalfCanvasWidth, centerCanvas[1]];
          const canvasRight = <CoreTypes.Point2>[centerCanvas[0] + newHalfCanvasWidth, centerCanvas[1]];

          points[2] = canvasToWorld(canvasLeft);
          points[3] = canvasToWorld(canvasRight);
        }
      } else {
        // Dragging left or right point
        const dXCanvas = Math.abs(currentCanvasPoints[0] - centerCanvas[0]);
        const canvasLeft = <CoreTypes.Point2>[centerCanvas[0] - dXCanvas, centerCanvas[1]];
        const canvasRight = <CoreTypes.Point2>[centerCanvas[0] + dXCanvas, centerCanvas[1]];

        points[2] = canvasToWorld(canvasLeft);
        points[3] = canvasToWorld(canvasRight);
        if (originalHandleCanvas && canvasHeight) {
          const dYCanvas = currentCanvasPoints[1] - originalHandleCanvas[1];
          const newHalfCanvasHeight = canvasHeight / 2 + dYCanvas;
          const canvasBottom = <CoreTypes.Point2>[centerCanvas[0], centerCanvas[1] - newHalfCanvasHeight];
          const canvasTop = <CoreTypes.Point2>[centerCanvas[0], centerCanvas[1] + newHalfCanvasHeight];

          points[0] = canvasToWorld(canvasBottom);
          points[1] = canvasToWorld(canvasTop);
        }
      }
    }
  };

  cancel = (element: HTMLDivElement) => {
    // If it is mid-draw or mid-modify
    if (this.isDrawing) {
      this.isDrawing = false;
      this._deactivateDraw(element);
      this._deactivateModify(element);
      cursors.elementCursor.resetElementCursor(element);
      const editData = this.editData;
      if (editData) {
        const { annotation, viewportIdsToRender, newAnnotation } = editData;

        const { data } = annotation;

        annotation.highlighted = false;
        data.handles.activeHandleIndex = null;

        const { renderingEngine } = <CoreTypes.IEnabledElement>getEnabledElement(element);

        utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

        if (newAnnotation) {
          triggerAnnotationCompleted(annotation);
        }

        this.editData = null;
        return annotation.annotationUID;
      }
    }
  };

  _activateModify = (element) => {
    state.isInteractingWithTool = true;

    element.addEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.addEventListener(Enums.Events.MOUSE_DRAG, this._dragModifyCallback);
    element.addEventListener(Enums.Events.MOUSE_MOVE, this._dragModifyCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.addEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.addEventListener(Enums.Events.TOUCH_DRAG, this._dragModifyCallback);
    element.addEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  _deactivateModify = (element) => {
    state.isInteractingWithTool = false;

    element.removeEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.removeEventListener(Enums.Events.MOUSE_DRAG, this._dragModifyCallback);
    element.removeEventListener(Enums.Events.MOUSE_MOVE, this._dragModifyCallback);
    element.removeEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.removeEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.removeEventListener(Enums.Events.TOUCH_DRAG, this._dragModifyCallback);
    element.removeEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  _activateDraw = (element) => {
    state.isInteractingWithTool = true;
    element.addEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.addEventListener(Enums.Events.MOUSE_DRAG, this._dragDrawCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.addEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.addEventListener(Enums.Events.TOUCH_DRAG, this._dragDrawCallback);
    element.addEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  _deactivateDraw = (element) => {
    state.isInteractingWithTool = false;
    element.removeEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.removeEventListener(Enums.Events.MOUSE_DRAG, this._dragDrawCallback);
    element.removeEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.removeEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.removeEventListener(Enums.Events.TOUCH_DRAG, this._dragDrawCallback);
    element.removeEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  /**
   * it is used to draw the ellipticalROI annotation in each
   * request animation frame. It calculates the updated cached statistics if
   * data is invalidated and cache it.
   *
   * @param enabledElement - The Cornerstone's enabledElement.
   * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
   */
  renderAnnotation = (enabledElement: CoreTypes.IEnabledElement, svgDrawingHelper: Types.SVGDrawingHelper): boolean => {
    let renderStatus = false;
    const { viewport } = enabledElement;
    const { element } = viewport;

    let annotations = getAnnotations(this.getToolName(), element);

    if (!annotations?.length) {
      return renderStatus;
    }

    annotations = <Types.Annotations>this.filterInteractableAnnotationsForElement(element, annotations);

    if (!annotations?.length) {
      return renderStatus;
    }

    const targetId = this.getTargetId(viewport);

    const renderingEngine = viewport.getRenderingEngine();

    const styleSpecifier: Types.AnnotationStyle.StyleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i] as EllipseAnnotation;
      const { annotationUID = '', data } = annotation;
      const { handles } = data;
      const { points, activeHandleIndex } = handles;

      styleSpecifier.annotationUID = annotationUID;

      const { color, lineWidth, lineDash } = this.getAnnotationStyle({
        annotation,
        styleSpecifier,
      });

      const canvasCoordinates = points.map((p) => viewport.worldToCanvas(p)) as [CoreTypes.Point2, CoreTypes.Point2, CoreTypes.Point2, CoreTypes.Point2];

      const canvasCorners = <Array<CoreTypes.Point2>>utilities.math.ellipse.getCanvasEllipseCorners(canvasCoordinates); // bottom, top, left, right, keep as is

      const { centerPointRadius } = this.configuration;

      // If rendering engine has been destroyed while rendering
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      let activeHandleCanvasCoords;

      if (!isAnnotationVisible(annotationUID)) {
        continue;
      }

      if (!isAnnotationLocked(annotation) && !this.editData && activeHandleIndex !== null) {
        // Not locked or creating and hovering over handle, so render handle.
        activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
      }

      if (activeHandleCanvasCoords) {
        const handleGroupUID = '0';
        drawHandles(svgDrawingHelper, annotationUID, handleGroupUID, activeHandleCanvasCoords, {
          color,
        });
      }

      const dataId = `${annotationUID}-ellipse`;
      const ellipseUID = '0';
      drawEllipseByCoordinates(
        svgDrawingHelper,
        annotationUID,
        ellipseUID,
        canvasCoordinates,
        {
          color,
          lineDash,
          lineWidth,
        },
        dataId,
      );

      renderStatus = true;
    }

    return renderStatus;
  };

  /**
   * This is a temporary function to use the old ellipse's canvas-based
   * calculation for isPointNearTool, we should move the the world-based
   * calculation to the tool's isPointNearTool function.
   *
   * @param ellipse - The ellipse object
   * @param location - The location to check
   * @returns True if the point is inside the ellipse
   */
  _pointInEllipseCanvas(ellipse, location: CoreTypes.Point2): boolean {
    const xRadius = ellipse.width / 2;
    const yRadius = ellipse.height / 2;

    if (xRadius <= 0.0 || yRadius <= 0.0) {
      return false;
    }

    const center = [ellipse.left + xRadius, ellipse.top + yRadius];
    const normalized = [location[0] - center[0], location[1] - center[1]];

    const inEllipse = (normalized[0] * normalized[0]) / (xRadius * xRadius) + (normalized[1] * normalized[1]) / (yRadius * yRadius) <= 1.0;

    return inEllipse;
  }

  /**
   * It takes the canvas coordinates of the ellipse corners and returns the center point of it
   *
   * @param ellipseCanvasPoints - The coordinates of the ellipse in the canvas.
   * @returns center point.
   */
  _getCanvasEllipseCenter(ellipseCanvasPoints: CoreTypes.Point2[]): CoreTypes.Point2 {
    const [bottom, top, left, right] = ellipseCanvasPoints;
    const topLeft = [left[0], top[1]];
    const bottomRight = [right[0], bottom[1]];
    return [(topLeft[0] + bottomRight[0]) / 2, (topLeft[1] + bottomRight[1]) / 2] as CoreTypes.Point2;
  }
}

EllipseAnnotateTool.toolName = 'EllipseAnnotate';
export default EllipseAnnotateTool;
