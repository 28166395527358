import { Directive, Output, EventEmitter, HostListener } from '@angular/core';
import { SharedService, FileHelpers } from '@app/@shared';
import { TranslateService } from '@ngx-translate/core';
const ALLOW_DROP_FILE_EXTENSION = ['dcm'];

@Directive({
  selector: '[appFileDropZone]',
})
export class DropzoneDirective {
  @Output() dropped = new EventEmitter<File[]>();
  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
  ) {}
  // hostlistener to intercept drop event in the DOM
  @HostListener('drop', ['$event'])
  async onDrop($event: DragEvent) {
    if ($event.dataTransfer?.items[0].kind === 'file') {
      $event.preventDefault();
      this.sharedService.preloader(true, this.translate.instant('ReadFiles'));
      //@ts-ignore
      this.dropped.emit(await this._getAllFileEntries($event.dataTransfer.items));
    }
  }
  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
  }
  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
  }

  // Drop handler function to get all files
  private _getAllFileEntries = async (dataTransferItemList: DataTransferItemList): Promise<File[]> => {
    let fileEntries = [];
    // Use BFS to traverse entire directory/file structure
    let queue = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
      //@ts-ignore
      queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
      let entry = queue.shift();
      //@ts-ignore
      if (entry.isFile) {
        //@ts-ignore
        fileEntries.push(entry);
        //@ts-ignore
      } else if (entry.isDirectory) {
        //@ts-ignore
        queue.push(...(await this._readAllDirectoryEntries(entry.createReader())));
      }
    }
    let files: File[] = [];
    for (let i = 0; i < fileEntries.length; i++) {
      const fEntry = fileEntries[i];
      const file = await this._getFile(fEntry);
      const extensions = FileHelpers.Instance.getExtension(file.name);
      if (ALLOW_DROP_FILE_EXTENSION.includes(extensions)) {
        files.push(await this._getFile(fEntry));
      }
    }
    // console.log('file entries are:', files);
    return files;
  };

  // Get all the entries (files or sub-directories) in a directory
  // by calling readEntries until it returns empty array
  private _readAllDirectoryEntries = async (directoryReader) => {
    let entries = [];
    let readEntries: any = await this._readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
      //@ts-ignore
      entries.push(...readEntries);
      readEntries = await this._readEntriesPromise(directoryReader);
    }
    return entries;
  };

  // Wrap readEntries in a promise to make working with readEntries easier
  // readEntries will return only some of the entries in a directory
  // e.g. Chrome returns at most 100 entries at a time
  private _readEntriesPromise = async (directoryReader) => {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      console.log(err);
      return new Promise((rs, rj) => rj(err));
    }
  };
  //@ts-ignore
  private _getFile = async (fileEntry): Promise<File> => {
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  };
}
