import { IPatientStudy, Priority, Study, sortByUid, sortbySeriesNumber } from '@app/@core';
import { IStudyViewer } from './IStudyViewer';
import { Provider } from '../contants/load-image';
import { ISeriesViewer } from './ISeriesViewer';
import { SortOrder } from '../contants/sort';
import { IDicomDir } from '@app/upload/models/IDicomDir';
import { SeriesViewer } from './series-viewer';
import { IPatientInfo } from './IPatientInfo';
import { IStackImage } from './IStackImage';

/**
 * Represents a StudyViewer, which extends the Study class and implements the IStudyViewer interface.
 * It provides functionality for generating avatars based on patient gender and age, sorting series by UID or series number,
 * and parsing DICOM directory information.
 */
export class StudyViewer extends Study<ISeriesViewer> implements IStudyViewer {
  provider: Provider;
  priority: Priority;
  constructor(uid: string) {
    super(uid);
  }
  /**
   * Generates an avatar image source based on the patient's gender and age.
   */
  generateAvatar(rawPatientInfo: IPatientStudy): IPatientInfo {
    const patientInfo: IPatientInfo = { ...rawPatientInfo, studyUid: this.uid };
    const gender = rawPatientInfo.patientGender;
    const age = rawPatientInfo.patientAge?.substring(0, rawPatientInfo.patientAge.length - 1) || '0';
    let imageSrc = './assets/images/age-gender/';
    switch (gender) {
      case 'M':
        imageSrc += 'M-' + this._getAgeIconLabel(parseInt(age));
        break;
      case 'F':
        imageSrc += 'F-' + this._getAgeIconLabel(parseInt(age));
        break;
      default:
        this.patientInfo.patientGender = 'O';
        imageSrc += 'O.png';
        break;
    }
    patientInfo.avatar = imageSrc;
    return patientInfo;
  }

  /**
   * Chooses the image source based on the patient's age.
   * @param age The patient's age.
   * @returns The image source for the age icon.
   */
  private _getAgeIconLabel(age) {
    let imageSrc = '';
    switch (true) {
      case age > 0 && age < 1:
        imageSrc = '01.png';
        break;
      case age >= 1 && age < 3:
        imageSrc = '02.png';
        break;
      case age >= 3 && age < 4:
        imageSrc = '03.png';
        break;
      case age >= 4 && age < 5:
        imageSrc = '04.png';
        break;
      case age >= 5 && age < 15:
        imageSrc = '05.png';
        break;
      case age >= 15 && age < 25:
        imageSrc = '06.png';
        break;
      case age >= 25 && age < 30:
        imageSrc = '07.png';
        break;
      case age >= 30 && age < 40:
        imageSrc = '08.png';
        break;
      case age >= 40 && age < 65:
        imageSrc = '09.png';
        break;
      case age >= 65 && age < 70:
        imageSrc = '10.png';
        break;
      case age >= 70:
        imageSrc = '11.png';
        break;
      default:
        imageSrc = '12.png';
        break;
    }
    return imageSrc;
  }

  /**
   * Parses the DICOM directory information and populates the study viewer.
   * @param body The DICOM directory information.
   */
  fromDicomDir(body: IDicomDir, isLogin: boolean): void {
    this.studyInfo = body.studyInfo;
    this.patientInfo = body.patientInfo || {};
    this.studyInfo = body.studyInfo;
    //Import series data from dicom dir
    for (let index = 0, length = body.series.length; index < length; index++) {
      const series = body.series[index];
      const newSeries = new SeriesViewer(series.uid, series.metadata, series.modality);
      newSeries.fromDicomDir(series, this.priority, isLogin);
      this.addNewSeries(newSeries);
    }
  }

  /**
   * Converts the StudyViewer instance to a format suitable for storing.
   * @param provider - The provider of the study.
   * @param priority - The priority of the study.
   * @returns The converted study object.
   */
  toStore(provider: Provider, priority: Priority): any {
    let stackImages: IStackImage[];
    if (provider === Provider.Google) {
      stackImages = this.series.map((series) => series.toStoreStackFromDicomDir(priority));
    } else {
      stackImages = this.series.map((series) => series.toStoreStackFromFile());
    }
    return {
      uid: this.uid,
      studyInfo: this.studyInfo,
      patientInfo: this.generateAvatar(this.patientInfo),
      seriesInfo: this.series.map((series) => series.toStoreSeries(this.uid, provider)),
      stackImages,
    };
  }
  /**
   * Sorts the series by UID in the specified order.
   * @param order The sort order (optional). Defaults to ascending order.
   */
  override sortByUid(order?: SortOrder): void {
    sortByUid(order || SortOrder.Ascending, this.series);
  }
  /**
   * Sorts the series by series number in the specified order.
   * @param order The sort order (optional). Defaults to ascending order.
   */
  override sortbySeriesNumber(order?: SortOrder): void {
    sortbySeriesNumber(order || SortOrder.Ascending, this.series);
  }
}
