export class Threads {
  private constructor() {}

  /**
   * Singleton class
   */
  private static _instance: Threads;
  static get Instance() {
    if (!this._instance) {
      this._instance = new Threads();
    }
    return Threads._instance;
  }

  // Sleep thread
  public sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
