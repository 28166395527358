import { Component, OnInit } from '@angular/core';
// import { Severity, SharedService } from '@app/@shared';
import { SETTING, settingActions, settingQuery, WORKLIST_SETTING } from '@app/setting';
import { IPreset } from '@app/worklist/models/IPreset';
import { SearchWorklistService } from '@app/worklist/services/search.service';
import { searchWorklist } from '@app/worklist/store/worklist.actions';
import { worklistQuery } from '@app/worklist/store/worklist.selectors';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-preset',
  templateUrl: './preset.component.html',
  styleUrl: './preset.component.scss',
})
export class PresetComponent implements OnInit {
  protected visible: boolean = false;
  protected presetName: string = '';
  protected validateMsg: string = '';
  protected presetListButton$: Observable<IPreset[] | undefined>;
  constructor(
    private store: Store,
    private searchService: SearchWorklistService,
    // private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.presetListButton$ = this.store.select(settingQuery.selectWorklistPresetSetting);
  }

  /**
   * Displays the dialog by setting the `visible` property to `true`.
   *
   * @protected
   */
  protected showDialog = () => {
    this.visible = true;
  };

  /**
   * Hides the dialog and resets the preset name.
   *
   * This method is used to clear the preset name and hide the dialog
   * when it is no longer needed.
   */
  protected hideDialog = () => {
    this.presetName = '';
    this.validateMsg = '';
  };

  /**
   * Saves the current preset to the preset list.
   *
   * This method performs the following steps:
   * 1. Retrieves the current list of presets.
   * 2. Checks if a preset with the same name already exists. If it does, an error message is shown and the method returns.
   * 3. If the preset list is empty, initializes it as an empty array.
   * 4. Retrieves the current search payload from the store.
   * 5. If the search payload is null, an error message is shown and the method returns.
   * 6. Adds the new preset (with the current search payload) to the preset list.
   * 7. Dispatches an action to update the settings with the new preset list.
   * 8. Sets the visibility of the component to false.
   *
   * @returns {Promise<void>} A promise that resolves when the preset is saved.
   */
  protected savePreset = async () => {
    let presetList = cloneDeep(await firstValueFrom(this.presetListButton$));
    if (presetList) {
      const isExist = presetList.some((preset) => preset.name === this.presetName);
      if (isExist) {
        // Show error message
        // this.sharedService.toastMessage(Severity.error, 'Preset name already exists');
        this.validateMsg = 'Preset name already exists';
        return;
      }
    } else {
      presetList = [];
    }

    const searchPayload = await firstValueFrom(this.store.select(worklistQuery.selectSearch));
    if (searchPayload === null) {
      // Show error message
      // this.sharedService.toastMessage(Severity.error, 'No search criteria to save');
      this.validateMsg = 'No search criteria to save';
      return;
    }

    presetList.push({ name: this.presetName, ...searchPayload });
    this.store.dispatch(
      settingActions.updateSetting({
        name: SETTING.WORKLIST,
        payload: {
          [WORKLIST_SETTING.PRESET]: presetList,
        },
      }),
    );
    this.visible = false;
  };

  /**
   * Handles the click event on a preset item.
   * Dispatches a search action to the store with the selected preset as the payload.
   *
   * @param {IPreset} preset - The preset item that was clicked.
   * @returns {Promise<void>} A promise that resolves when the action is dispatched.
   */
  protected onPresetClick = async (preset: IPreset) => {
    this.searchService.raiseSearchStudy(preset);
  };
}
