import { createSelector, select } from '@ngrx/store';
import { ViewerGeneralState } from './general.reducer';
import { selectViewerGeneralEntities } from '..';
import { DOWNLOAD_DICOM_THREAD } from '@app/viewer/contants/download-queue';
import { IGeneralStudy, MODALITY_NAME, MODALITY_SEPARATOR, Priority } from '@app/@core';
import { Enums } from '@cornerstonejs/core';
import { ISeriesToStack } from '@app/viewer/models/IViewport';
import { Provider } from '@app/viewer/contants/load-image';
import { IPatientInfo } from '@app/viewer/models/IPatientInfo';
import { ISeriesInfo } from '@app/viewer/models/ISeriesInfo';
import { IStackImage } from '@app/viewer/models/IStackImage';

//#region  Entities
/**
 * Selects the guest mode from the viewer state.
 * @returns {MemoizedSelector<object, boolean>} The memoized selector function.
 */
const selectGuestMode = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.guestMode);
/**
 * Selects the share mode from the viewer state.
 * @returns {MemoizedSelector<object, boolean>} The memoized selector function.
 */
const selectShareMode = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.shareMode);

/**
 * Selects the from drive from the viewer state.
 * @returns {MemoizedSelector<object, boolean>} The memoized selector function.
 * @see ViewerGeneralState
 * @see selectViewerGeneralEntities
 * @see ViewerGeneralState.fromDrive
 * @see ViewerGeneralState
 *
 */
const selectFromDrive = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.fromDrive);
/**
 * Selects the study information list from the viewer state.
 * @returns {MemoizedSelector<object, StudyInfo[]>} The memoized selector function.
 */
const selectStudyInfoList = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.studyInfo);

/**
 * Selects the patient information list from the viewer state.
 * @returns {MemoizedSelector<object, PatientInfo[]>} The memoized selector function.
 */
const selectPatientInfos = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.patientInfo);

/**
 * Selects the stack images from the viewer state.
 * @returns {MemoizedSelector<object, StackImage[]>} The memoized selector function.
 */
const selectStackImages = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.stackImages);

/**
 * Selects the series information list from the viewer state.
 * @returns {MemoizedSelector<object, SeriesInfo[]>} The memoized selector function.
 */
const selectSeriesInfos = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.seriesInfos);

/**
 * Selects the current selected studies on datatable.
 * @returns {MemoizedSelector<object, Study[]>} The memoized selector function.
 */
const selectSelectedStudies = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.seletedStudies);

/**
 * Selects the display viewport from the viewer state.
 * @returns {MemoizedSelector<object, ISeriesToStack[]>} The memoized selector function.
 * @see ISeriesToStack
 * @see ViewerGeneralState
 * @see selectViewerGeneralEntities
 * @see ViewerGeneralState.displayViewport
 */
const selectDisplayViewport = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.displayViewport);

/**
 * Selects the display viewport backup from the viewer state.
 * @returns {MemoizedSelector<object, ISeriesToStack[]>} The memoized selector function.
 * @see ISeriesToStack
 * @see ViewerGeneralState
 * @see selectViewerGeneralEntities
 * @see ViewerGeneralState.displayViewportBackup
 */
const selectDisplayViewportBackup = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.displayViewportBackup);

/**
 * Selects the viewer mode from the general state.
 *
 * @param state - The general state of the viewer.
 * @returns The selected viewer mode.
 */
const selectViewerMode = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.mode);

/**
 * Selects the value of the `isFullImage` property from the `ViewerGeneralState`.
 *
 * @param state - The ViewerGeneralState object.
 * @returns The value of the `isFullImage` property.
 */
const selectIsFullImage = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.isFullImage);

/**
 * Selects the backup viewports from the viewer general state.
 *
 * @param state - The viewer general state.
 * @returns The backup viewports.
 */
const selectBackupViewports = createSelector(selectViewerGeneralEntities, (state: ViewerGeneralState) => state.viewportBackup);
//#endregion

//#region models-
/**
 * Selects the patient information from the viewer state.
 * @returns {MemoizedSelector<object, PatientInfo>} The memoized selector function.
 */
const selectPatientInfoByStudyUid = (studyUid: string) => createSelector(selectPatientInfos, (state: IPatientInfo[]) => state.find((patient) => patient.studyUid === studyUid));

/**
 * Selects the unique modalities from the study information in the viewer general state.
 *
 * @param state - The viewer general state.
 * @returns An array of unique modalities.
 */
const selectModalityFilter = createSelector(selectStudyInfoList, (state: IGeneralStudy[]) => {
  const modality = state.map((study) => study.modality).flatMap((modality) => modality.split(MODALITY_SEPARATOR));
  const result = modality.filter((value, index) => modality.indexOf(value) === index);
  return result;
});

/**
 * Selects the series for a given UID from the viewer state.
 * @param {string} uid - The UID of the study.
 * @returns {MemoizedSelector<object, { seriesUid: string, thumbnail: string }[]>} The memoized selector function.
 */
const selectSeriesInfo = (studyUid: string) =>
  createSelector(selectSeriesInfos, (state: ISeriesInfo[]) => {
    const res = state.filter((series) => series.studyUid === studyUid);
    return res;
  });

/**
 * Selects the series information object with the specified UID from the state.
 *
 * @param seriesUid - The UID of the series information object to select.
 * @returns The series information object with the specified UID, or undefined if not found.
 */
const selectSeriesInfoByUid = (seriesUid: string) =>
  createSelector(selectSeriesInfos, (state: ISeriesInfo[]) => {
    const res = state.find((series) => series.uid === seriesUid);
    return res;
  });

/**
 * Selects the study UID associated with the given series UID.
 * @param seriesUid The UID of the series.
 * @returns The study UID associated with the series UID, or an empty string if not found.
 */
const selectStudyUidBySeriesUid = (seriesUid: string) =>
  createSelector(selectSeriesInfos, (state: ISeriesInfo[]) => {
    const res = state.find((series) => series.uid === seriesUid);
    return res?.studyUid || '';
  });
/**
 * Selects series information by study UIDs.
 *
 * @param studyUids - An array of study UIDs.
 * @returns An array of series information filtered by the provided study UIDs.
 */
const selectSeriesInfosByStudyUid = (studyUids: string[]) =>
  createSelector(selectSeriesInfos, (state: ISeriesInfo[]) => {
    const res = state.filter((series) => studyUids.includes(series.uid));
    return res;
  });

/**
 * Selects the download URL for an image series based on the series UID.
 * @param seriesUid The UID of the image series.
 * @returns The download URL for the image series.
 */
const selectImageDownloadUrl = (seriesUid: string) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const series = state.find((series) => series.uid === seriesUid);
    if (series === undefined) return [];
    // get list of download link dicom file from remaining store
    if (series.imageDriveId) {
      // if remaining is larger than setting
      if (series.imageDriveId.length >= DOWNLOAD_DICOM_THREAD) {
        return series.imageDriveId.slice(0, DOWNLOAD_DICOM_THREAD);
      } else if (series.imageDriveId.length > 0) {
        // Get all remaining download url
        return series.imageDriveId.slice(0, series.imageDriveId.length);
      }
    }
    return [];
  });

/**
 * Selects the seriesUid to display for a given stack index in viewport.
 *
 * @param stackIndex - The stack index of viewport.
 * @returns The series UID of the DICOM data to display.
 */
const selectSeriesUidViewportByStackIndex = (stackIndex: string) =>
  createSelector(selectDisplayViewport, (state: ISeriesToStack[]) => {
    const series = state.find((series) => series.stackIndex === stackIndex);
    return series?.seriesUid || '';
  });

/**
 * Selects the display viewport by stack index.
 * @param stackIndex - The stack index to filter by.
 * @returns The display viewport matching the stack index.
 */
const selectDisplayViewportByStackIndex = (stackIndex: string) => createSelector(selectDisplayViewport, (state: ISeriesToStack[]) => state.find((series) => series.stackIndex === stackIndex));

/**
 * Selects the display viewport by panel index.
 *
 * @param panelIndex - The panel index to filter by.
 * @returns A selector function that filters the display viewport based on the panel index.
 */
const selectDisplayViewportByPanelIndex = (panelIndex: string) =>
  createSelector(selectDisplayViewport, (state: ISeriesToStack[]) => state.filter((series) => series.stackIndex.startsWith(panelIndex, 0)));

const selectBackupDisplayViewportByPanelIndex = (panelIndex: string) =>
  createSelector(selectDisplayViewportBackup, (state: ISeriesToStack[]) => state.filter((series) => series.stackIndex.startsWith(panelIndex, 0)));

/**
 * Selects the stack image with the specified series UID from the viewer general state.
 * @param seriesUid The UID of the series.
 * @returns The stack image object matching the series UID, or undefined if not found.
 */
const selectStackImagesBySeriesUId = (seriesUid: string) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const stackImage = state.find((images) => images.uid === seriesUid);
    return stackImage;
  });

/**
 * Selects stack images by series UIDs.
 *
 * @param seriesUids - An array of series UIDs.
 * @returns An array of stack images filtered by the provided series UIDs.
 */
const selectStackImagesBySeriesUIds = (seriesUids: string[]) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const stackImage = state.filter((images) => seriesUids.includes(images.uid));
    return stackImage;
  });

/**
 * Selects the total number of images in a stack for a given series UID.
 * @param seriesUid The UID of the series.
 * @returns The total number of images in the stack, or undefined if the stack is not found.
 */
const selectTotalImageInStack = (seriesUid: string) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const stackImage = state.find((images) => images.uid === seriesUid);
    return stackImage?.totalImage;
  });

/**
 * Selects the number of downloaded images in a stack for a given series UID.
 * @param seriesUid - The UID of the series.
 * @returns The number of downloaded images in the stack.
 */
const selectDownloadedImageInStack = (seriesUid: string) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const stackImage = state.find((images) => images.uid === seriesUid);
    return stackImage?.imagePrefetch.map((images) => images.imageId);
  });

/**
 * Selects the remaining image URLs from the viewer general state.
 * Only images with a priority of "Low" are included.
 *
 * @param state - The viewer general state.
 * @returns An array of image URLs.
 */
const selectRemainingImageUrl = createSelector(selectStackImages, (state: IStackImage[]): string[] => {
  const stackImage = state.filter((stack) => stack.priority === Priority.Low);
  if (stackImage) {
    return stackImage.flatMap((image) => image.imageDriveId || '');
  }
  return [];
});

/**
 * Selects the series information objects that are still downloading.
 *
 * @param state - The array of series information objects.
 * @returns An array of series information objects that are still downloading.
 */
const selectRemainingSeries = createSelector(selectSeriesInfos, (state: ISeriesInfo[]): ISeriesInfo[] => {
  const seriesInfos = state.filter((series) => series.isDownloading === true);
  return seriesInfos;
});

/**
 * Selects the image prefetch value for a given series UID.
 * @param seriesUid The UID of the series.
 * @returns The image prefetch value for the series.
 */
const selectImagePrefetch = (seriesUid: string) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const stackImage = state.find((images) => images.uid === seriesUid);
    return stackImage?.imagePrefetch;
  });

/**
 * Selects the metadata tag for a specific image ID.
 * @param imageId - The ID of the image.
 * @returns The metadata tag associated with the image ID, or undefined if not found.
 */
const selectMetadataImage = (imageId: string) =>
  createSelector(selectStackImages, (state: IStackImage[]) => {
    const stackImage = state.flatMap((images) => images.imagePrefetch);
    return stackImage?.find((image) => image.imageId === imageId)?.tag;
  });

/**
 * Selects the type {@link Enums.ViewportType} of the viewport for a given stack index.
 * @param stackIndex - The stack index of the viewport.
 * @returns The type of the viewport, or undefined if not found.
 */
const selectStackViewportType = (stackIndex: string) =>
  createSelector(selectDisplayViewport, (state: ISeriesToStack[]): ISeriesToStack | undefined => {
    const viewport = state.find((viewport) => viewport.stackIndex === stackIndex);
    return viewport;
  });

/**
 * Selects the stack provider {@link Provider} for a given series UID.
 *
 * @param seriesUid The UID of the series.
 * @returns The stack provider for the series.
 */
const selectStackProvider = (seriesUid: string) =>
  createSelector(selectStackImages, (state: IStackImage[]): Provider => {
    const stackImage = state.find((images) => images.uid === seriesUid);
    return stackImage?.provider || Provider.Local;
  });

/**
 * Selects the modality of a series based on its UID.
 *
 * @param seriesUid - The UID of the series.
 * @returns The modality of the series, or an empty string if not found.
 */
const selectModalityBySeriesUID = (seriesUid: string) => createSelector(selectSeriesInfos, (state) => state.find((series) => series.uid === seriesUid)?.modality || '');

/**
 * Selects the download status for a given series UID.
 * @param seriesUid - The UID of the series.
 * @returns The download status for the series UID.
 */
const selectDownloadStatusBySeriesUid = (seriesUid: string) => createSelector(selectSeriesInfos, (state) => state.find((series) => series.uid === seriesUid)?.isDownloading || false);

/**
 * Selects the backup viewport with the specified series UID and tile ID.
 *
 * @param seriesUid - The series UID of the viewport.
 * @param tileId - The tile ID of the viewport.
 * @returns The backup viewport matching the specified series UID and tile ID, or undefined if not found.
 */
const selectBackupViewport = (seriesUid: string, tileId: string) =>
  createSelector(selectBackupViewports, (state) => {
    const viewport = state.find((viewport) => viewport.seriesUid === seriesUid && viewport.tileIndex === tileId);
    return viewport;
  });

const selectBackupViewportBySeriesUid = (seriesUid: string) =>
  createSelector(selectBackupViewports, (state) => {
    const viewport = state.find((viewport) => viewport.seriesUid === seriesUid);
    return viewport;
  });
//#endregion
/**
 * Contains a collection of selectors for retrieving various pieces of information from the viewer general state.
 */
export const ViewerGeneralQuery = {
  selectGuestMode,
  selectShareMode,
  selectFromDrive,
  selectStudyInfoList,
  selectPatientInfos,
  selectStackImages,
  selectSeriesInfos,
  selectSelectedStudies,
  selectDisplayViewport,
  selectDisplayViewportBackup,
  selectViewerMode,
  selectIsFullImage,
  selectPatientInfoByStudyUid,
  selectModalityFilter,
  selectSeriesInfo,
  selectSeriesInfoByUid,
  selectSeriesInfosByStudyUid,
  selectStudyUidBySeriesUid,
  selectImageDownloadUrl,
  selectSeriesUidViewportByStackIndex,
  selectDisplayViewportByStackIndex,
  selectDisplayViewportByPanelIndex,
  selectBackupDisplayViewportByPanelIndex,
  selectStackImagesBySeriesUId,
  selectStackImagesBySeriesUIds,
  selectTotalImageInStack,
  selectDownloadedImageInStack,
  selectRemainingImageUrl,
  selectRemainingSeries,
  selectImagePrefetch,
  selectMetadataImage,
  selectStackViewportType,
  selectStackProvider,
  selectModalityBySeriesUID,
  selectDownloadStatusBySeriesUid,
  selectBackupViewport,
  selectBackupViewportBySeriesUid,
};
