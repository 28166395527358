export enum SortCommand {
  InstanceNumber,
  SliceLocation,
  EchoTime,
}
export enum ScrollCommand {
  AutoScroll,
  Loop,
}

export enum ZoomCommand {
  FitWidth,
  FitHeight,
  FitAll,
  Actual,
}

export enum WWWLCommand {
  Presets,
  OptimizebyROI,
  Invest,
  Original,
}

export enum PanCommand {
  Horizontal,
  Vertical,
  Reset,
}

export enum RotateCommand {
  Manual,
  Left,
  Right,
  FlipVertically,
  FilpHorizontal,
  Reset,
}
export enum FilterCommand {
  None,
  SharpenWeak,
  SharpenMedium,
  SharpenStrong,
}

export enum SelectCommand {
  SelectStack,
  UnselectStack,
  UnSelectAllStacks,
}

export enum ToolCommand {
  MagmifierGlass,
  DICOMTagInfo,
  PlayCine,
  ExportImage,
}

export enum ReportCommand {
  Open,
  PasteEntire,
  PasteRegion,
}
