/**
 * Service responsible for uploading DICOM images and thumbnails to a drive.
 */
import { Injectable } from '@angular/core';
import { getWebWorkerManager, cache } from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { Subject } from 'rxjs';
import { DRIVE_FILE_EXTENSION, DRIVE_MIMETYPE, WEBWORKER_UPLOAD_CONFIG, UPLOAD_WORKER_INFO, IImage, IImagePrefetch, ISeries, ImagePrefetch, ZIP_WORKER_INFO } from '@app/@core';
import { GoogleDriveService, Logger } from '@app/@shared';
import { cloneDeep } from 'lodash';
import { IImageUploadInfo } from '../models/ISeriesUpload';
import { ImageUploadFileType, ProgressReportType } from '../constants/enum';
const log = new Logger('uploadservice');
@Injectable()
/**
 * Service for uploading DICOM images and thumbnails to a drive.
 */
export class UploadService {
  /**
   * Represents the upload worker function.
   */
  uploadWorkerFn = () => {
    return new Worker(new URL('../workers/upload.worker.ts', import.meta.url), {
      name: UPLOAD_WORKER_INFO.NAME, // name used by the browser to name the worker
    });
  };

  zipWorkerFn = () => {
    return new Worker(new URL('../workers/zip.worker.ts', import.meta.url), {
      name: ZIP_WORKER_INFO.NAME, // name used by the browser to name the worker
    });
  };

  /**
   * Represents the web worker manager.
   */
  private workerManager = getWebWorkerManager();

  constructor(private gDriveService: GoogleDriveService) {
    //initalize webworker for uploading file to google drive
    const options = {
      maxWorkerInstances: WEBWORKER_UPLOAD_CONFIG.MAX_THEARDS,
      overwrite: WEBWORKER_UPLOAD_CONFIG.OVERWRITE,
    };
    this.workerManager.registerWorker(UPLOAD_WORKER_INFO.NAME, this.uploadWorkerFn, options);
    this.workerManager.registerWorker(ZIP_WORKER_INFO.NAME, this.zipWorkerFn, options);
  }

  /**
   * Uploads a DICOM file to a specified folder on the drive.
   * @param dcmFile - The DICOM file to be uploaded.
   * @param folderId - The ID of the folder where the file will be uploaded.
   * @param token - The authentication token for accessing the drive.
   * @param progressCallback - A callback function to track the progress of the upload.
   * @returns A promise that resolves to the upload information of the DICOM file.
   */
  public uploadDicomFileToDrive = async (dcmFile: IImage, folderId: string, token: string, progressCallback: any): Promise<IImageUploadInfo> => {
    /**
     * Represents a promise that resolves to the result of executing an upload task.
     */
    const res = await (<Promise<IImageUploadInfo>>this.workerManager.executeTask(
      UPLOAD_WORKER_INFO.NAME,
      UPLOAD_WORKER_INFO.UPLOAD_SMALL_FN_NAME,
      {
        token,
        folderId,
        mimeType: '', // DICOM file does not have a mime type on the drive
        file: dcmFile.file,
        name: dcmFile.file?.name || '',
        fileType: ImageUploadFileType.dcm,
      },
      {
        callbacks: [
          ({ percent }) => {
            progressCallback(ProgressReportType.uploadDcm, percent);
          },
        ],
      },
    ));
    return res;
  };

  /**
   * Uploads a thumbnail image to a drive.
   * @param body - The thumbnail image data.
   * @param folderId - The ID of the folder in the drive where the thumbnail will be uploaded.
   * @param token - The authentication token for accessing the drive.
   * @param seriesUid - The UID of the series to which the thumbnail belongs.
   * @returns A promise that resolves to the ID of the uploaded thumbnail in the drive.
   */
  public async uploadingThumbnailToDrive(body: Blob, folderId: string, token: string, seriesUid: string): Promise<string> {
    const response = await this.workerManager.executeTask(
      UPLOAD_WORKER_INFO.NAME,
      UPLOAD_WORKER_INFO.UPLOAD_SMALL_FN_NAME,
      {
        imageDir: { driveId: '' },
        token,
        folderId,
        mimeType: DRIVE_MIMETYPE.THUMBNAIL_MIME_TYPE,
        file: body,
        name: `${seriesUid}${DRIVE_FILE_EXTENSION.THUMBNAIL}`,
        fileType: ImageUploadFileType.dcm,
      },
      { callbacks: [(progress) => {}] },
    );
    //share permission view thumbnail
    const shareresult = await this.gDriveService.shareFile(response.driveId);
    return response.driveId;
  }

  /**
   * Uploads multiple images to a drive folder.
   *
   * @param images - An array of images to upload.
   * @param folderId - The ID of the destination folder.
   * @param token - The authentication token.
   * @param seriesUid - The UID of the image series.
   * @param progressCallback - A callback function to report the progress of the upload.
   * @returns A promise that resolves to an object containing information about the uploaded images.
   */
  public uploadMultiImageToDrive = async (images: IImage[], folderId: string, token: string, seriesUid: string, progressCallback: any): Promise<IImageUploadInfo> => {
    const files = images.map((image) => image.file);
    //Create zip file from array of files before upload
    const zipFile = await this.workerManager.executeTask(
      ZIP_WORKER_INFO.NAME,
      ZIP_WORKER_INFO.START_ZIP_FN_NAME,
      { files },
      {
        callbacks: [
          ({ percent }) => {
            progressCallback(ProgressReportType.zip, percent);
          },
        ],
      },
    );
    const uploadRes = <IImageUploadInfo>await this.workerManager.executeTask(
      UPLOAD_WORKER_INFO.NAME,
      UPLOAD_WORKER_INFO.UPLOAD_LARGE_FN_NAME,
      {
        token,
        folderId,
        mimeType: DRIVE_MIMETYPE.ZIP,
        file: zipFile,
        name: `${seriesUid}${DRIVE_FILE_EXTENSION.ZIP}`,
        fileType: ImageUploadFileType.zip,
      },
      {
        callbacks: [
          ({ percent }) => {
            progressCallback(ProgressReportType.uploadZip, percent);
          },
        ],
      },
    );
    return uploadRes;
  };

  /**
   * Cancels the upload process.
   * Terminates the worker manager and purges the cache.
   * @returns A promise that resolves when the upload is canceled.
   */
  public async cancelUpload(): Promise<void> {
    await this.workerManager.terminate(UPLOAD_WORKER_INFO.NAME);
    await this.workerManager.terminate(ZIP_WORKER_INFO.NAME);
    cache.purgeCache();
  }

  /**
   * Free all memory of cornerstone
   */
  public freeze() {
    cache.purgeCache();
    cornerstoneDICOMImageLoader.wadouri.fileManager.purge();
  }
}
