<div *rxLet="stackLayout" class="panel h-100" [ngStyle]="getStyle()">
  <app-viewport-stack
    *rxFor="let item of stackLayout"
    [stackIndex]="item.stackIndex"
    [stackLayout]="item.stackLayout"
    [activated]="activated && item.index === +currentActiveStack"
    [index]="item.index"
    (afterImageRender)="onAfterImageRender($event)"
    (initStack)="onInitStack($event)"
  ></app-viewport-stack>
  <!-- <span class="panel__mask" [class.panel__mask--activated]="activated"></span> -->
</div>
