<p-dialog
  [header]="'Setting' | translate"
  [visible]="(visible$ | async) || false"
  [modal]="true"
  [style]="{ width: '80vw', height: '70vh', overflow: 'auto' }"
  [draggable]="false"
  [resizable]="false"
  (visibleChange)="closeDialog($event)"
>
  <div class="setting-dialog" [class]="className">
    <div class="setting-dialog__menu">
      <p-menu [model]="items" class="setting-dialog__menu-list"></p-menu>
      <p-button [link]="true" class="setting-dialog__button-about" (click)="handleClickAboutTeam()">
        <i class="pi pi-info-circle"></i>
        <span translate>AboutTeam</span>
      </p-button>
    </div>
    <div class="setting-dialog__main-content">
      <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel>
          <app-general-setting></app-general-setting>
        </p-tabPanel>
        <p-tabPanel>
          <app-hotkey-setting></app-hotkey-setting>
        </p-tabPanel>
        <p-tabPanel>
          <app-worklist-setting></app-worklist-setting>
        </p-tabPanel>
        <p-tabPanel>
          <app-user-info-setting></app-user-info-setting>
        </p-tabPanel>
        <p-tabPanel>
          <app-about-team></app-about-team>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</p-dialog>
