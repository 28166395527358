import { Injectable } from '@angular/core';
import { IPatientStudy } from '@app/@core';
import { GoogleDriveService, Logger } from '@app/@shared';
import { IDicomDir } from '@app/upload';
import { GoogleParams } from '@app/viewer/models';
import { IReportContent, IReportDir, IReportInfo, PatientInfo } from '../models';
import { ReportService } from '.';
import { IFirebaseUser } from '@app/auth';
import { ReportFirebaseService } from '@app/@database';

const log = new Logger('[Report]PreloaderService');
@Injectable()
export class PreloaderService {
  constructor(
    private gDriveService: GoogleDriveService,
    private reportFirebaseService: ReportFirebaseService,
    private reportService: ReportService,
  ) {}

  /**
   * Loads data from Google Drive and Firebase to populate the report information.
   *
   * @param driveIds - The Google Drive IDs for the main and reference studies.
   * @param userInfo - The user information from Firebase.
   * @returns A promise that resolves to an object containing the loaded report information.
   * @throws An error if there is an issue loading the data.
   */
  public async loadFromGoogle(driveIds: GoogleParams, userInfo: IFirebaseUser): Promise<IReportInfo> {
    const reportInfos: IReportInfo = {
      clinicInfo: [],
      patients: [],
      reportContents: [],
      studyInfos: [],
    };
    try {
      //add data for main study

      const mainRes = <IDicomDir>await this.gDriveService.getFileContent(driveIds.main);
      if (mainRes === undefined) {
        reportInfos;
      }

      reportInfos.studyInfos.push(mainRes.studyInfo);
      const mainPatient: PatientInfo = new PatientInfo(mainRes.studyUid, <IPatientStudy>mainRes.patientInfo);
      reportInfos.patients.push(mainPatient);
      //get clinical information from firestore
      const clinicInfo = await this.reportFirebaseService.getClinicInfo(driveIds.mainUid);
      reportInfos.clinicInfo.push(clinicInfo);
      //get all report metadata on firebase
      const reportMetadata = await this.reportFirebaseService.findReportByStudyUid(driveIds.mainUid);
      if (reportMetadata) {
        const reportDirIds = reportMetadata.map((report) => report.driveId);
        const reportContents = await this.reportService.getReportContents(reportDirIds);
        //mapping report content with report metadata
        reportContents.forEach((reportContent) => {
          const reportMeta = reportMetadata.find((meta) => meta.createBy === reportContent.doctorUid);
          if (reportMeta) {
            reportInfos.reportContents.push({ ...reportMeta, ...reportContent, baseDriveId: clinicInfo.reportDriveId });
          }
        });
      }
      //add data for reference study
      if (driveIds.ref) {
        const refRes = <IDicomDir>await this.gDriveService.getFileContent(driveIds.ref);
        if (refRes === undefined) {
          return reportInfos;
        }
        reportInfos.studyInfos.push(refRes.studyInfo);
        const refPatient: PatientInfo = new PatientInfo(refRes.studyUid, <IPatientStudy>refRes.patientInfo);
        reportInfos.patients.push(refPatient);
        //get clinical information from firestore
        const refClinicInfo = await this.reportFirebaseService.getClinicInfo(driveIds.refUid);
        reportInfos.clinicInfo.push(refClinicInfo);
        //get all report metadata on firebase
        const reportRefMetadata = await this.reportFirebaseService.findReportByStudyUid(driveIds.refUid);
        if (reportRefMetadata) {
          const reportRefDirIds = reportRefMetadata.map((report) => report.driveId);
          const reportRefContents = await this.reportService.getReportContents(reportRefDirIds);
          //mapping report content with report metadata
          reportRefContents.forEach((reportContent) => {
            const reportMeta = reportRefMetadata.find((meta) => meta.createBy === reportContent.doctorUid);
            if (reportMeta) {
              reportInfos.reportContents.push({
                ...reportMeta,
                ...reportContent,
                baseDriveId: refClinicInfo.reportDriveId,
              });
            }
          });
        }
      }
      return reportInfos;
    } catch (error) {
      log.error(error);
      return reportInfos;
    }
  }

  public async reloadReportContent(reportDirId: string): Promise<IReportDir | null> {
    const reportContents = await this.reportService.getReportContent(reportDirId);
    return reportContents;
  }
}
