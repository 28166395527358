<footer id="footer">
  <div class="container-fluid">
    <div class="d-flex justify-content-between">
      <div>
        <small class="text-light" translate>Copyright</small>
      </div>
      <div>
        <a href="#" class="mx-2 text-light">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="#" class="mx-2 text-light">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="#" class="mx-2 text-light">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
</footer>
