import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { exhaustMap, firstValueFrom, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { LoginMode, authQuery } from '@app/auth';
import { settingActions } from './setting.actions';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { SettingFirebaseService } from '../services/setting.firebase.service';
import { IAppSetting } from '../models/ISetting';
import { Theme } from '@app/@core';
import { IFilterType } from '@app/worklist';
import { settingQuery } from './setting.selectors';
import { Severity, SharedService } from '@app/@shared';
import { DEFAULT_SETTING } from '@app/viewer';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
/**
 * Represents the effects class for managing worklist settings.
 */
export class SettingEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private settingFirebaseService: SettingFirebaseService,
    private sharedService: SharedService,
    private trans: TranslateService,
  ) {}

  /**
   * Retrieves the application setting based on the login mode.
   * If the login mode is firebase, it retrieves the setting from the Firebase service.
   * Otherwise, it retrieves the setting from the API.
   * @returns An observable that emits actions for success or failure.
   */
  getSetting = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(settingActions.getAll),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginInfo)),
        mergeMap(async ([action, info]) => {
          let appSeting: IAppSetting;

          try {
            if (info.mode === LoginMode.firebase) {
              appSeting = await this.settingFirebaseService.getSetting(info.userId || '');
              return settingActions.getSuccess({ payload: appSeting });
            } else {
              appSeting = await this.loadSettingFromAPI(info.userId || '');
              return settingActions.getSuccess({ payload: appSeting });
            }
          } catch (error) {
            return settingActions.getFailure({ error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that sets the setting based on the login mode.
   * If the login mode is firebase, it sets the setting using the settingFirebaseService.
   * Otherwise, it loads the setting from the API.
   * @returns An observable that emits actions for success or failure.
   */
  setSetting = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(settingActions.set),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginInfo)),
        mergeMap(async ([action, info]) => {
          try {
            if (info.mode === LoginMode.firebase) {
              await this.settingFirebaseService.setSetting(info.userId || '', action.payload);
              return settingActions.getSuccess({ payload: action.payload });
            } else {
              await this.loadSettingFromAPI(info.userId || '');
              return settingActions.getSuccess({ payload: action.payload });
            }
          } catch (error) {
            return settingActions.getFailure({ error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that sets the setting based on the login mode.
   * If the login mode is firebase, it sets the setting using the settingFirebaseService.
   * Otherwise, it loads the setting from the API.
   * @returns An observable that emits actions for success or failure.
   */
  updateSetting = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(settingActions.updateSetting),
        concatLatestFrom(() => [this.store.select(authQuery.selectLoginInfo), this.store.select(settingQuery.selectSettingUid)]),
        mergeMap(async ([action, loginInfo, uid]) => {
          try {
            this.sharedService.preloader(true);
            if (loginInfo.mode === LoginMode.firebase) {
              const res = await this.settingFirebaseService.updateSetting(uid || '', action.name, action.payload);
              this.sharedService.preloader(false);

              return settingActions.updateSettingSuccess({ payload: res });
            } else {
              await this.loadSettingFromAPI(loginInfo.userId || '');
              return settingActions.getSuccess({ payload: action.payload });
            }
          } catch (error) {
            this.sharedService.preloader(false);
            return settingActions.updateSettingtFailure({ error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  updateSettingSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(settingActions.updateSettingSuccess),
        concatLatestFrom(() => [this.store.select(authQuery.selectLoginInfo), this.store.select(settingQuery.selectSettingUid)]),
        mergeMap(async ([action, loginInfo, uid]) => {
          this.sharedService.toastMessage(Severity.success, this.trans.instant('SettingUpdateSuccess'));
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  updateSettingtFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(settingActions.updateSettingtFailure),
        concatLatestFrom(() => [this.store.select(authQuery.selectLoginInfo), this.store.select(settingQuery.selectSettingUid)]),
        mergeMap(async ([action, loginInfo, uid]) => {
          this.sharedService.toastMessage(Severity.success, this.trans.instant('SettingUpdateFail'));
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  //#region

  //#endregion

  //#region Load Worklist using API

  loadSettingFromAPI = async (userId: string): Promise<IAppSetting> => {
    //TODO: Implement logic load worklist from onpremise API
    const seting: IAppSetting = {
      userId: '1',
      general: {
        language: environment.supportedLanguages[0],
        theme: Theme.Dark,
      },
      worklist: {
        // filterSettings: [
        //   {
        //     label: 'Today Uploaded Data',
        //     filterData: '1',
        //     type: IFilterType.bodypart,
        //   },
        // ],
        presetSettings: [],
      },
      upload: {
        uploadSettings: [],
      },
      hotkeys: {
        platform: 'Win32',
        macOSKey: [{ event: 'openFile', combo: 'command+o' }],
        windowsKey: [{ event: 'openFile', combo: 'ctrl+o' }],
      },
      viewer: {
        textColor: '',
        ...DEFAULT_SETTING,
      },
      userInfo: {
        name: 'John Doe',
        title: 'Dr.',
        institution: 'Hospital',
        signatureUrl: '',
        lastUpdateDate: moment().format('YYYY-MM-DD hh:mm:ss'),
      },
    };

    return seting;
  };
  //#endregion
}
