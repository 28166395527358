import { Injectable } from '@angular/core';

import { RenderingEngine, utilities, Enums, Types, init, cache, setUseCPURendering, volumeLoader, setUseSharedArrayBuffer } from '@cornerstonejs/core';
import { cornerstoneStreamingImageVolumeLoader, cornerstoneStreamingDynamicImageVolumeLoader } from '@cornerstonejs/streaming-image-volume-loader';

import { Logger } from '@app/@shared';
import { RENDER_ENGINE_ID, VOLUME_ID_PATTERN } from '../contants/cornerstone';
import { LAYOUT_SEPARATOR, VIEWPORT_INDEX_SEPERATOR } from '@app/viewer';
const log = new Logger('CornerstoneService');

@Injectable({
  providedIn: 'root',
})
export class CornerstoneService {
  private _renderingEngine: RenderingEngine;
  constructor() {}

  /**
   * Initializes the cornerstone service.
   * @returns {Promise<void>} A promise that resolves when the initialization is complete.
   */
  public async init(): Promise<void> {
    //this.initLoader();
    await init();
    //setUseSharedArrayBuffer(false);
    //@ts-ignore
    volumeLoader.registerUnknownVolumeLoader(cornerstoneStreamingImageVolumeLoader);
    //@ts-ignore
    volumeLoader.registerVolumeLoader('cornerstoneStreamingImageVolume', cornerstoneStreamingImageVolumeLoader);
    //@ts-ignore
    volumeLoader.registerVolumeLoader(
      'cornerstoneStreamingDynamicImageVolume',
      //@ts-ignore
      cornerstoneStreamingDynamicImageVolumeLoader,
    );
    this._renderingEngine = new RenderingEngine(RENDER_ENGINE_ID);
    log.info('init cornerstone RenderingEngine:' + this._renderingEngine.id);
  }

  /**
   * Retrieves the rendering engine used by the Cornerstone service.
   * @returns The rendering engine.
   */
  public getRenderEngine = () => this._renderingEngine;

  /**
   * Retrieves the viewport by stack index.
   * @param stackIndex - The stack index to filter the viewports.
   * @returns An array of viewports that match the stack index.
   */
  public getViewportIdByStackIndex = (stackIndex: string) => {
    const viewports = this._renderingEngine.getViewports();
    const viewportInStack = viewports.filter((viewport) => viewport.id.startsWith(stackIndex));
    return viewportInStack.map((viewport) => viewport.id);
  };
  /**
   * Enables the viewport with the specified configuration.
   * @param config - @link Types.PublicViewportInput Types.PublicViewportInput  The configuration for the viewport.
   */
  public enableViewport(config: Types.PublicViewportInput): void {
    if (this._renderingEngine.getViewport(config.viewportId) === undefined) {
      this._renderingEngine.enableElement(config);
    }
  }

  public enableViewports(viewportInputArray: Types.PublicViewportInput[]): void {
    viewportInputArray.map((config) => {
      this._renderingEngine.enableElement(config);
    });
  }

  public setViewports(viewportInputArray) {
    this._renderingEngine.setViewports(viewportInputArray);
  }

  /**
   * Updates the viewport configuration for rendering DICOM images.
   * @param config - The viewport configuration to update.
   */
  public updateViewport(config: Types.PublicViewportInput): void {
    this._renderingEngine.disableElement(config.viewportId);
    this._renderingEngine.enableElement(config);
  }

  /**
   * Returns the number of viewports in the rendering engine.
   * @returns The number of viewports.
   */
  public countViewports(): number {
    return this._renderingEngine.getViewports().length;
  }

  /**
   * Disables the specified viewport by its ID.
   * @param viewportId The ID of the viewport to disable.
   */
  public disableViewport(viewportId: string): void {
    if (this._renderingEngine.getViewport(viewportId)) {
      this._renderingEngine.disableElement(viewportId);
    }
  }

  public disableViewportByStackIndex(stackIndex: string) {
    const viewportIds = this.getViewportIdByStackIndex(stackIndex);
    if (viewportIds && viewportIds.length > 0) {
      viewportIds.map((id) => {
        this._renderingEngine.disableElement(id);
      });
    }
  }

  public disableVolumeViewports() {
    const volumeViewport = this._renderingEngine.getVolumeViewports();
    volumeViewport.map((viewport) => {
      this._renderingEngine.disableElement(viewport.id);
    });
  }

  public disableAllViewports() {
    const viewportIds = this._renderingEngine.getViewports();
    viewportIds.map((viewport) => this._renderingEngine.disableElement(viewport.id));
  }

  /**
   * Creates and caches a volume with the given name and image IDs.
   * @param name - The name of the volume.
   * @param imageIds - An array of image IDs.
   * @returns A promise that resolves to the created and cached volume.
   */
  public async createAndCacheVolume(name: string, imageIds: string[]) {
    return await volumeLoader.createAndCacheVolume(VOLUME_ID_PATTERN(name), {
      imageIds,
    });
  }

  /**
   * Retrieves the default actor for a given viewport.
   * @param viewportId The ID of the viewport.
   * @returns The default actor for the specified viewport.
   */
  public getViewportDefaultActor(viewportId: string) {
    return this._renderingEngine.getViewport(viewportId).getDefaultActor().actor;
  }

  public getVolumeById(id: string) {
    return cache.getVolume(id);
  }

  /**
   * Retrieves the stack viewport with the specified ID.
   *
   * @param viewportId - The ID of the stack viewport.
   * @returns The stack viewport with the specified ID.
   */
  public getStackViewport(viewportId: string): Types.IStackViewport | null {
    // Get the stack viewport that was created
    const viewport = this._renderingEngine.getViewport(viewportId);
    if (viewport === undefined || viewport === null) {
      return null;
    }
    if (viewport.type === Enums.ViewportType.STACK) {
      return <Types.IStackViewport>viewport;
    }
    return null;
  }

  public getAllStackViewport(): Types.IStackViewport[] {
    // Get the stack viewport that was created
    const viewport = this._renderingEngine.getStackViewports();
    return viewport;
  }

  public getViewport(viewportId: string) {
    // Get the stack viewport that was created
    const viewport = this._renderingEngine.getViewport(viewportId);
    return viewport;
  }

  /**
   * Retrieves the volume viewport with the specified ID.
   * @param viewportId The ID of the viewport.
   * @returns The volume viewport object.
   */
  public getVolumeViewportById(viewportId: string): Types.IVolumeViewport {
    return <Types.IVolumeViewport>this._renderingEngine.getViewport(viewportId);
  }

  public getVolumeViewportsByType(viewportType): Types.IVolumeViewport[] | undefined {
    return this._renderingEngine.getVolumeViewports().filter((viewport) => viewport.id.includes(viewportType));
  }

  public getVolumeViewports(): Types.IVolumeViewport[] {
    return this._renderingEngine.getVolumeViewports();
  }
  /**
   * Filters the viewports based on the panel index.
   *
   * @param panelIndex - The index of the panel.
   * @returns An array of viewport IDs that match the panel index.
   */
  public filterViewport(panelIndex: string) {
    const viewportIds = this._renderingEngine.getViewports();
    const tileViewport = viewportIds.filter((viewport) => viewport.id.includes(`${panelIndex}${VIEWPORT_INDEX_SEPERATOR}`));

    return tileViewport.flatMap((viewport) => viewport.id);
  }

  public render(): void {
    this._renderingEngine.render();
  }

  public renderStack(viewportIds: string[]): void {
    viewportIds.map((id) => {
      const viewport = this._renderingEngine.getViewport(id);
      if (viewport) {
        viewport.render();
      }
    });
  }
  /**
   * Freezes the cache by purging it.
   */
  public freezeCache() {
    cache.purgeCache();
  }

  /**
   * workaround solution for some modality can't not use gpu for display to viewport
   * @param isUse
   */
  public useCPURendering(isUse: boolean) {
    setUseCPURendering(isUse);
  }

  public isStackViewport(viewport: Types.IViewport): viewport is Types.IStackViewport {
    return 'setStack' in viewport;
  }

  public isVolumeViewport(viewport: Types.IViewport): viewport is Types.IVolumeViewport {
    return 'addVolumes' in viewport;
  }
}
