/**
 * Represents the types of process buttons.
 */
export enum ProcessButtonType {
  Upload,
  Reset,
}

/**
 * Represents the file types for image uploads.
 */
export enum ImageUploadFileType {
  dcm,
  zip,
}

/**
 * Represents the types of progress reports.
 */
export enum ProgressReportType {
  zip,
  upload,
  uploadDcm,
  uploadZip,
  uploadThumb,
}

export enum UploadState {
  notYet,
  uploading,
  finished,
}
