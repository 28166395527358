<div class="content-wrapper">
  <div class="container">
    <h2><strong>利用規約</strong></h2>

    <p>VPACS Viewerへようこそ！</p>

    <p>これらの利用規約は、vpacs.netに位置するRADTeamのウェブサイトの使用に関する規則と規制を概説しています。</p>

    <p>このウェブサイトにアクセスすることにより、これらの利用規約を受け入れるものとします。このページに記載されているすべての利用規約に同意しない場合は、VPACS Viewerの使用を続けないでください。</p>

    <p>
      以下の用語は、これらの利用規約、プライバシーステートメント、免責事項、およびすべての契約に適用されます：「クライアント」、「あなた」と「あなたの」は、このウェブサイトにログオンし、会社の利用規約に従う人を指します。「会社」、「私たち自身」、「私たち」、「私たちの」、「私たち」とは、私たちの会社を指します。「パーティー」、「パーティーズ」、または「私たち」とは、クライアントと私たち自身の両方を指します。すべての用語は、クライアントのニーズに最も適した方法で私たちの支援のプロセスを実行するために必要な支払いの提供、受け入れ、および考慮を指し、vnの現行法に従って、会社の明示されたサービスの提供に関してクライアントのニーズを満たす明示的な目的のために参照されます。上記の用語または他の単語の単数形、複数形、大文字化、および/または彼/彼女または彼らは、交換可能と見なされ、したがって同じを指しています。
    </p>

    <h3><strong>クッキー</strong></h3>

    <p>私たちはクッキーの使用を採用しています。VPACS Viewerにアクセスすることにより、RADTeamのプライバシーポリシーに従ってクッキーを使用することに同意したことになります。</p>

    <p>
      ほとんどのインタラクティブなウェブサイトは、各訪問者の詳細を取得するためにクッキーを使用します。クッキーは、私たちのウェブサイトを訪れる人々にとって使いやすくするために、特定のエリアの機能を有効にするためにウェブサイトで使用されます。私たちのアフィリエイト/広告パートナーの一部もクッキーを使用する場合があります。
    </p>

    <h3><strong>ライセンス</strong></h3>

    <p>
      特に記載がない限り、RADTeamおよび/またはそのライセンサーは、VPACS
      Viewerのすべての素材に対する知的財産権を所有しています。すべての知的財産権は予約されています。これらの利用規約で設定された制限に従って、VPACS
      Viewerからこれを個人的な使用のためにアクセスすることができます。
    </p>

    <p>次のことはできません：</p>
    <ul>
      <li>VPACS Viewerから資料を再発行する</li>
      <li>VPACS Viewerから資料を販売、賃貸、またはサブライセンスする</li>
      <li>VPACS Viewerから資料を複製、複写、またはコピーする</li>
      <li>VPACS Viewerからコンテンツを再配布する</li>
    </ul>

    <p>
      この合意は、ここに記載された日付から始まります。私たちの利用規約は、
      <a href="https://www.termsandconditionsgenerator.com/">無料の利用規約ジェネレーター</a>の助けを借りて作成されました。
    </p>

    <p>
      このウェブサイトの一部は、ユーザーがウェブサイトの特定のエリアで意見や情報を投稿し交換する機会を提供しています。RADTeamは、ウェブサイト上にコメントが表示される前に、コメントをフィルタリング、編集、公開、またはレビューしません。コメントはRADTeam、その代理人、および/または関連会社の見解や意見を反映していません。コメントは、その見解や意見を投稿する人の見解や意見を反映しています。適用される法律で許可される範囲内で、RADTeamは、このウェブサイト上でのコメントの使用および/または投稿および/または表示によって引き起こされたおよび/または被ったコメントまたはいかなる責任、損害、または費用についても責任を負いません。
    </p>

    <p>RADTeamは、不適切、攻撃的、またはこれらの利用規約に違反する可能性のあるコメントをすべて監視し、削除する権利を留保します。</p>

    <p>あなたは保証し、表明します：</p>

    <ul>
      <li>あなたは、ウェブサイト上でコメントを投稿する権利があり、そうするために必要なすべてのライセンスと同意を得ています。</li>
      <li>コメントは、著作権、特許、商標を含むがこれに限定されない第三者のいかなる知的財産権も侵害しません。</li>
      <li>コメントには、名誉毀損、中傷、攻撃的、不適切、またはその他の違法な素材が含まれておらず、プライバシーの侵害になりません。</li>
      <li>コメントは、ビジネスやカスタムを勧誘または宣伝したり、商業活動や違法な活動を提示するために使用されません。</li>
    </ul>

    <p>あなたは、RADTeamにあなたのコメントを使用、複製、編集し、他者に使用、複製、編集する非独占的ライセンスをここに付与します。</p>

    <h3><strong>当社のコンテンツへのハイパーリンク</strong></h3>

    <p>
      次の組織は、事前の書面による承認なしに、私たちのウェブサイトにリンクもちろん、続きを翻訳いたします。以下がその続きです。 HTML
      これらの組織は、リンクがいかなる方法でも欺瞞的でなく、リンクする当事者とその製品および/またはサービスのスポンサーシップ、承認、または承認を偽って示さない限り、私たちのホームページ、出版物、または他のウェブサイト情報にリンクすることができます。また、リンクがリンクする当事者のサイトの文脈に適合している限りです。
    </p>

    <p>私たちは、次のタイプの組織からの他のリンク要求を検討し、承認することがあります：</p>

    <ul>
      <li>一般に知られている消費者および/またはビジネス情報源;</li>
      <li>dot.comコミュニティサイト;</li>
      <li>慈善団体を代表する団体やその他のグループ;</li>
      <li>オンラインディレクトリディストリビュータ;</li>
      <li>インターネットポータル;</li>
      <li>会計、法律、およびコンサルティング会社; そして</li>
      <li>教育機関および業界団体。</li>
    </ul>

    <p>
      私たちは、次の条件を満たす場合にこれらの組織からのリンク要求を承認します：(a) リンクが私たち自身または私たちの認定ビジネスに不利な見方をさせないこと、(b)
      組織が私たちとの間に否定的な記録を持っていないこと、(c) ハイパーリンクの可視性から私たちに対する利益がRADTeamの不在を補うこと、および (d) リンクが一般的なリソース情報の文脈にあること。
    </p>

    <p>
      これらの組織は、リンクがいかなる方法でも欺瞞的でなく、リンクする当事者とその製品またはサービスのスポンサーシップ、承認、または承認を偽って示さない限り、私たちのホームページにリンクすることができます。また、リンクがリンクする当事者のサイトの文脈に適合している限りです。
    </p>

    <p>
      パラグラフ2に記載されている組織の1つであり、私たちのウェブサイトにリンクすることに興味がある場合は、RADTeamに電子メールを送信して私たちに通知する必要があります。あなたの名前、あなたの組織名、連絡先情報、およびあなたのサイトのURL、私たちのウェブサイトにリンクするつもりのあるURLのリスト、およびあなたがリンクしたい私たちのサイト上のURLのリストを含めてください。返答を待つには2-3週間かかります。
    </p>

    <p>承認された組織は、次の方法で私たちのウェブサイトにハイパーリンクをすることができます：</p>

    <ul>
      <li>私たちの企業名を使用することによって; または</li>
      <li>リンクされている統一リソースロケータを使用することによって; または</li>
      <li>リンクする当事者のサイトの文脈と形式に合理的な範囲内で、リンクされている私たちのウェブサイトに関するその他の説明を使用することによって。</li>
    </ul>

    <p>商標ライセンス契約がない限り、RADTeamのロゴまたは他のアートワークをリンクに使用することは許可されません。</p>

    <h3><strong>iFrames</strong></h3>

    <p>事前の承認と書面による許可なしに、私たちのウェブページの周りにフレームを作成して、私たちのウェブサイトの視覚的なプレゼンテーションまたは外観を変更することはできません。</p>

    <h3><strong>コンテンツの責任</strong></h3>

    <p>
      あなたのウェブサイトに表示されるコンテンツについては、私たちは責任を負いません。あなたのウェブサイト上で発生するすべてのクレームに対して私たちを保護し、擁護することに同意します。名誉毀損、わいせつ、または犯罪的と解釈される可能性のあるウェブサイト、または第三者の権利を侵害する、または侵害することを主張または提唱するウェブサイトには、リンクが表示されるべきではありません。
    </p>

    <h3><strong>権利の予約</strong></h3>

    <p>
      私たちは、私たちのウェブサイトへのすべてのリンクまたは特定のリンクを削除するように要求する権利を留保します。あなたは、要求された場合に直ちに私たちのウェブサイトへのすべてのリンクを削除することに同意します。私たちはまた、これらの利用規約およびリンクポリシーをいつでも修正する権利を留保します。私たちのウェブサイトへのリンクを継続することにより、これらのリンクの利用規約に拘束され、それに従うことに同意します。
    </p>

    <h3><strong>私たちのウェブサイトからのリンクの削除</strong></h3>

    <p>
      何らかの理由で私たちのウェブサイト上のリンクが不快であると判断した場合、いつでも私たちに連絡して情報を提供することができます。私たちはリンクの削除を要求することを検討しますが、そうする義務はありませんし、直接あなたに返答する義務もありません。
    </p>

    <p>
      私たちは、このウェブサイト上の情報が正確であることを保証せず、その完全性または正確性を保証するものではありません。また、ウェブサイトが利用可能であること、またはウェブサイト上の資料が最新であることを約束するものでは
      私たちは、このウェブサイト上の情報が正確であることを保証せず、その完全性または正確性を保証するものではありません。また、ウェブサイトが利用可能であること、またはウェブサイト上の資料が最新であることを約束するものではありません。
    </p>

    <h3><strong>免責事項</strong></h3>

    <p>
      適用される法律で最大限に許される範囲で、私たちは、私たちのウェブサイトおよびこのウェブサイトの使用に関連するすべての表現、保証、および条件を除外します。この免責事項において何も、次のことを制限または除外しません：
    </p>

    <ul>
      <li>私たちまたはあなたの死亡または人身傷害に対する責任;</li>
      <li>私たちまたはあなたの詐欺または詐欺的な誤表現に対する責任;</li>
      <li>適用される法律で許されていない方法で私たちまたはあなたの責任を制限すること;</li>
      <li>適用される法律で除外されていない私たちまたはあなたの責任を除外すること。</li>
    </ul>

    <p>
      このセクションおよびこの免責事項の他の場所に設定されている責任の制限および禁止は、(a) 前述の段落に従属し、および (b)
      契約、不法行為、および法定義務違反に基づく責任を含む免責事項に基づくすべての責任を管理します。
    </p>

    <p>ウェブサイトおよびウェブサイト上の情報およびサービスが無料で提供される限り、私たちはいかなる性質の損失または損害に対しても責任を負いません。</p>
  </div>
</div>
