import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { WorklistComponent } from './worklist.component';
import { Shell } from '@app/shell/shell.service';
import { APP_ROUTE_BASE } from '@app/@core';

const routes: Routes = [
  Shell.childRoutes([
    // { path: '', redirectTo: '/worklist', pathMatch: 'full' },
    { path: APP_ROUTE_BASE.WORKLIST, component: WorklistComponent, data: { title: marker('StudyList') } },
  ]),
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class WorklistRoutingModule {}
