import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FIREBASE_FILTER_TYPE, IGeneralStudy, getDateTimeFormat } from '@app/@core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { DownloadQueueService } from '@app/viewer/services/download-queue.service';
import { Severity, SharedService } from '@app/@shared';
import { reportActions, selectModalityFilter, selectStudyInfoList } from '@app/report/store';
// import { environment } from '@env/environment';
@Component({
  selector: 'app-report-study-list',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss'],
})
/**
 * Represents the Viewer Study Component.
 * This component is responsible for displaying study information and handling user interactions.
 */
export class ReportStudyComponent implements OnInit, AfterViewInit, OnDestroy {
  //#region Props
  public totalRecord: number = 0;
  public tableData$: Observable<IGeneralStudy[]>;
  public filterButton$: Observable<string[]>;
  public tableDataSub: Subscription;
  private _onDataLoaded: Subscription;
  public modality: string[];
  public curModality: string = 'All';
  protected cols: any[] = [];
  protected dateTimeFormat: string;
  public selectedStudy: IGeneralStudy;
  @Output() selectedStudyEvent = new EventEmitter<IGeneralStudy>();
  @ViewChild('datatable') datatableView: ElementRef;
  @ViewChild('dt') dt: Table;
  public scrollHeight: string = '150px';
  protected moment: any = moment;
  //#endregion
  constructor(
    private translateService: TranslateService,
    private store: Store,
    private downloadQueue: DownloadQueueService,
    private sharedService: SharedService,
  ) {
    const currentLang = this.translateService.currentLang;
    this.dateTimeFormat = getDateTimeFormat(currentLang);
  }

  ngOnInit() {
    this.cols = [
      { field: 'uid', header: '', display: 'none' },
      { field: 'studyDate', header: 'studyInfoStudyDate', width: '30%', display: 'table-cell' },
      {
        field: 'modality',
        header: 'studyInfoModality',
        width: '20%',
        display: 'table-cell',
      },
      {
        field: 'bodyPart',
        header: 'studyInfoBodyPart',
        width: '20%',
        display: 'table-cell',
      },
      {
        field: 'totalSeries',
        header: 'studyInfoTotalSeries',
        width: '15%',
        display: 'table-cell',
      },
      {
        field: 'totalImage',
        header: 'studyInfoTotalImages',
        width: '15%',
        display: 'table-cell',
      },
    ];

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const lang = event.lang;
      this.dateTimeFormat = getDateTimeFormat(lang);
    });
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized the component's view.
   * It is called only once after the first ngAfterContentChecked().
   * Use this hook to perform any initialization logic that relies on the component's view.
   */
  ngAfterViewInit(): void {
    this.tableData$ = this.store.select(selectStudyInfoList);
    this.filterButton$ = this.store.select(selectModalityFilter);
    this._onDataLoaded = this.tableData$.subscribe((studies) => {
      if (studies.length === 0) {
        return;
      }
      this.selectedStudy = studies[0];
      this.selectedStudyEvent.emit(studies[0]);
      this.store.dispatch(reportActions.changeSelectedStudy({ studyUid: studies[0].uid }));
    });
  }

  ngOnDestroy(): void {
    this._onDataLoaded?.unsubscribe();
  }

  /**
   * Handles the change event when the selection changes.
   * @param $event The event object containing the new selection.
   */
  public onSelectionChange = ($event) => {
    // this.store.dispatch(viewerGeneralActions.changeSelectedStudy({ payload: $event }));
    this.selectedStudyEvent.emit($event);
    this.store.dispatch(reportActions.changeSelectedStudy({ studyUid: $event.uid }));
  };

  /**
   * Filters the study by the specified modality.
   *
   * @param modality - The modality to filter by.
   */
  protected onFilterModality = (modality: string) => {
    this.curModality = modality;
    this.dt.clearFilterValues();
    // Apply fileter modality
    if (modality === 'All') {
      this.dt.clear();
      return;
    }
    this.dt.filter(modality, this.cols[2].field, FIREBASE_FILTER_TYPE.CONTAINS);
  };
}
