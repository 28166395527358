<p-dialog
  [header]="'LUTColorbar' | translate"
  [(visible)]="visible"
  position="topleft"
  (onShow)="onShowDialog()"
  (onHide)="onHideDialog()"
  class="colormap__dialog"
  [style]="{ width: '400px', height: '400px' }"
>
  <div class="colormap w-100 h-100">
    <div class="colormap__container" #pseudoColor *rxFor="let colormapName of colormaps; let i = index; strategy: 'immediate'">
      <div class="colormap__container__colorbar" [id]="colormapName"></div>
      <div class="colormap__container__button">
        <p-button [label]="colormapName" [raised]="true" severity="secondary" (onClick)="onColorMapChange(colormapName)" [title]="colormapName" />
      </div>
    </div>
  </div>
</p-dialog>
