import { Injectable } from '@angular/core';
import { Logger, Severity, SharedService } from '@app/@shared';
import { LayoutService } from '@app/viewer/services/layout.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { layoutActions } from './layout.actions';
import { firstValueFrom, mergeMap } from 'rxjs';
import { IBackupLayout, IPanelLayout, IStackLayout, ITileLayout } from '@app/viewer/models/ILayout';
import { ViewerLayoutQuery } from './layout.selectors';
import {
  BackupMode,
  DEFAULT_PANEL_LAYOUT,
  DEFAULT_PANEL_VOLUME_2D_LAYOUT,
  DEFAULT_PANEL_VOLUME_LAYOUT,
  DEFAULT_STACK_LAYOUT,
  DEFAULT_STACK_VOLUME_2D_LAYOUT,
  DEFAULT_STACK_VOLUME_LAYOUT,
  DEFAULT_TILE_LAYOUT,
  DEFAULT_VOLUME_TILE_LAYOUT,
  PANEL_LAYOUT_INDEX,
  VIEWPORT_INDEX_SEPERATOR,
} from '@app/viewer/contants/layout';
import { ViewerGeneralQuery, studyListAction, viewerDriveActions, viewerFileActions, viewerGeneralActions } from '../general';
import { DEFAULT_PANEL_INDEX, DEFAULT_STACK_ID, DEFAULT_STACK_INDEX, DEFAULT_VOLUME_HAS_2D_STACK_INDEX, DEFAULT_VOLUME_STACK_INDEX } from '@app/viewer/contants/viewport';
import { cloneDeep } from 'lodash';
import { ViewerMode } from '@app/viewer/contants/mode';
import { TranslateService } from '@ngx-translate/core';
import { viewerMenuActions } from '../menu';
import { volumeActions } from '../cornerstone';

const log = new Logger('ViewerLayoutEffects');
@Injectable()
/**
 * Effects class for the viewer feature.
 * Handles the side effects related to loading DICOM files and interacting with the viewer.
 */
export class ViewerLayoutEffects {
  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private store: Store,
  ) {}

  /**
   * Initializes the selected stack effect.
   * This effect is triggered when a successful load action is dispatched for viewerFile or viewerDrive.
   * It changes the layout by modality of display series and sets the selected stack index to the default stack index.
   */
  initSelectedStack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerFileActions.loadSuccess, viewerDriveActions.loadSuccess),
        mergeMap(async (action) => {
          //Change layout by modality of display series
          const firstSeriesUId = action.payload.stackImages[0].uid;
          return layoutActions.changeActiveStack({
            activeStack: {
              seriesUid: firstSeriesUId,
              stackIndex: DEFAULT_STACK_INDEX.toString(),
              layout: DEFAULT_STACK_LAYOUT,
            },
            mode: ViewerMode.Stack2D,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Initializes the selected panel effect.
   * This effect is triggered when a successful load action is dispatched for viewerFile or viewerDrive.
   * It changes the layout by setting the selected panel index to the default panel index.
   */
  initSelectedPanel$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerFileActions.loadSuccess, viewerDriveActions.loadSuccess),
        mergeMap(async (action) => {
          //Change layout by modality of display series
          return layoutActions.changeActivePanel({
            activePanel: {
              panelIndex: DEFAULT_PANEL_INDEX.toString(),
              layout: DEFAULT_PANEL_LAYOUT,
            },
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for the changeActiveTile action and dispatches the viewerMenuActions.changeActiveTile action.
   * This effect is responsible for changing the layout based on the modality of the display series.
   */
  changeActiveTile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeActiveTile),
        mergeMap(async (action) => {
          //Change layout by modality of display series
          return viewerMenuActions.changeActiveTile({ toolState: action.toolState });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Initializes the modality layout effect.
   * This effect calculates the new panel layout and stack layout based on the provided action.
   * It dispatches the changePanelSuccess action if successful, or the changeLayoutFail action if an error occurs.
   *
   * @returns An observable that emits the changePanelSuccess or changeLayoutFail action.
   */
  initModalityLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.initModalityLayout),
        mergeMap(async (action) => {
          try {
            //Calculate new panel layout. Index of panel always is 0. so we pass '0' to calculateLayout function
            const panelLayout: IPanelLayout = this.layoutService.calculateLayout(PANEL_LAYOUT_INDEX.toString(), action.panelLayout);
            let newStackLayout: IStackLayout[] = [];
            let newTileLayout: ITileLayout[] = [];
            // transform stack setting to IStackLayout for store on redux
            let tileIndex = 0;
            for (let index = 0, length = action.stackLayout.length; index < length; index++) {
              //Calculate stack layout base on setting layout (XxY)
              let stackLayout = <IStackLayout>this.layoutService.calculateLayout(index.toString(), action.stackLayout[index]);
              stackLayout.panelId = index.toString();
              newStackLayout = [...newStackLayout, stackLayout];

              //Add ITileLayout each stack
              for (let indexStack = 0; indexStack < stackLayout.total; indexStack++) {
                const tileLayout = <ITileLayout>this.layoutService.calculateLayout(tileIndex.toString(), action.tileLayout[tileIndex]);
                tileLayout.stackId = `${index}${VIEWPORT_INDEX_SEPERATOR}${indexStack}`;
                newTileLayout = [...newTileLayout, tileLayout];
                tileIndex++;
              }
            }
            return layoutActions.changePanelSuccess({
              panelLayout,
              stackLayout: newStackLayout,
              tileLayout: newTileLayout,
            });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the change of panel layout.
   * It listens for the `changePanelLayout` action, calculates the new panel layout,
   * and dispatches the `changePanelSuccess` action with the updated panel layout and stack layout.
   * If an error occurs during the calculation, it dispatches the `changeLayoutFail` action with the error.
   */
  changePanelLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changePanelLayout),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectStackLayout)),
        mergeMap(async ([action, stackLayout]) => {
          try {
            //if viewer is on volume mode, we cant change panel layout
            const currentMode = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectViewerMode));
            if (currentMode !== ViewerMode.Stack2D) {
              this.sharedService.toastMessage(Severity.error, this.translate.instant('PanelViewerVolumeMode'));
              return layoutActions.changeLayoutFail({ error: 'Viewer is on volume mode' });
            }
            //Calculate new panel layout. Index of panel always is 0. so we pass '0' to calculateLayout function
            const panelLayout: IPanelLayout = this.layoutService.calculateLayout(PANEL_LAYOUT_INDEX.toString(), action.panelLayout);
            //Calculate new stack layout
            let newStackLayout: IStackLayout[] = [];
            for (let index = 0, length = panelLayout.total; index < length; index++) {
              //Get old stack layout setting map with panel index
              const stack = stackLayout.filter((stack) => stack.panelId === index.toString());
              if (stack.length > 0) {
                // Reuse old setting of stack
                newStackLayout = [...newStackLayout, ...stack];
              } else {
                // DEFAUTL layout is 2x2
                newStackLayout = [...newStackLayout, { id: index.toString(), layoutString: DEFAULT_STACK_LAYOUT, total: 4, panelId: index.toString() }];
              }
            }
            //Calculate new tile layout
            let newTileLayout: ITileLayout[] = [];
            let tileIndex = 0;
            let totalStack = 0;
            for (let index = 0; index < newStackLayout.length; index++) {
              const stack = newStackLayout[index];
              let stackIndex = 0;
              totalStack += stack.total;
              for (tileIndex; tileIndex < totalStack; tileIndex++) {
                const tileLayout = <ITileLayout>this.layoutService.calculateLayout(tileIndex.toString(), DEFAULT_PANEL_LAYOUT);
                tileLayout.stackId = `${index}${VIEWPORT_INDEX_SEPERATOR}${stackIndex}`;
                newTileLayout.push(tileLayout);
                stackIndex++;
              }
              stackIndex = 0;
            }

            //Calculate total
            return layoutActions.changePanelSuccess({
              panelLayout,
              stackLayout: newStackLayout,
              tileLayout: newTileLayout,
            });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the change of stack layout.
   * This effect listens for the `changeStackLayout` action, calculates the new panel layout based on the selected panel and the inputed menu,
   * and dispatches the `changeStackSuccess` action with the updated stack layout.
   * If an error occurs during the calculation, it dispatches the `changeLayoutFail` action with the error.
   */
  changeStackLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeStackLayout),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectCurrentActivePanelIndex)),
        mergeMap(async ([action, panelIndex]) => {
          try {
            //Calculate new panel layout from inputed menu
            const stackLayout = <IStackLayout>this.layoutService.calculateLayout(panelIndex, action.stackLayout);
            stackLayout.panelId = panelIndex;
            //Calculate new tile layout
            let newTileLayout: ITileLayout[] = [];

            for (let indexStack = 0; indexStack < stackLayout.total; indexStack++) {
              const tileLayout = this.layoutService.calculateLayout((parseInt(panelIndex) + indexStack).toString(), DEFAULT_TILE_LAYOUT);
              newTileLayout.push(<ITileLayout>{
                ...tileLayout,
                stackId: `${panelIndex}${VIEWPORT_INDEX_SEPERATOR}${indexStack}`,
              });
            }
            return layoutActions.changeStackSuccess({ stackLayout, tileLayout: newTileLayout });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  changeTileLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeTileLayout),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          try {
            //Calculate new panel layout from inputed menu
            const tileIndex = activeStack?.stackIndex.split(VIEWPORT_INDEX_SEPERATOR).reduce((a, b) => a + parseInt(b), 0);
            const tileLayout = <ITileLayout>this.layoutService.calculateLayout(tileIndex?.toString() || '1x1', action.tileLayout);
            tileLayout.stackId = activeStack?.stackIndex || '';
            return layoutActions.changeTileSuccess({ tileLayout });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  //#region  Change study on study list

  /**
   * Effect that listens for `changeLayoutBySetting` actions and handles the layout change based on the provided settings.
   * If the layout change is successful, it dispatches a `changeSettingLayoutSuccess` action with the updated display viewports.
   * If an error occurs during the layout change, it dispatches a `changeLayoutFail` action with the error details.
   */
  changeLayoutBySetting$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeLayoutBySetting),
        mergeMap(async (action) => {
          try {
            const firstSeriesUid = action.displayViewports[0].seriesUid;
            const stackLayout = action.stackLayout[0].layoutString;
            return layoutActions.changeSettingLayoutSuccess({ seriesUid: firstSeriesUid, stackLayout, displayViewports: action.displayViewports });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  changeLayoutBySettingSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeLayoutBySetting),
        mergeMap(async (action) => {
          return viewerGeneralActions.changeDisplayViewport({ displayViewport: action.displayViewports });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  triggerChangeStudyCurrentLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayCurrentLayoutSuccess),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectTileLayout)),
        mergeMap(async ([action, currentLayout]) => {
          try {
            const newTilelayout = cloneDeep(currentLayout);
            return layoutActions.overrideAllTile({ tileLayout: newTilelayout });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );
  //#endregion

  //#region  Volume

  initVolumeLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.initVolumeLayout),
        mergeMap(async (action) => {
          //new panel layout
          let panelLayoutString = DEFAULT_PANEL_LAYOUT;
          if (action.config.isDisplay2D) {
            panelLayoutString = DEFAULT_PANEL_VOLUME_2D_LAYOUT;
          }
          const panelLayout = this.layoutService.calculateLayout(PANEL_LAYOUT_INDEX.toString(), panelLayoutString);
          //Calculate new stack layout
          let newStackLayout: IStackLayout[] = [];
          let newTileLayout: ITileLayout[] = [];

          if (action.config.isDisplay2D) {
            const oldStackLayout = <IStackLayout>this.layoutService.calculateLayout(panelLayout.id, action.oldStackLayout);
            oldStackLayout.panelId = panelLayout.id;
            newStackLayout.push(oldStackLayout);
            let tileIndex = 0;
            let totalStack = 0;
            for (let index = 0; index < newStackLayout.length; index++) {
              const stack = newStackLayout[index];
              let stackIndex = 0;
              totalStack += stack.total;
              for (tileIndex; tileIndex < totalStack; tileIndex++) {
                const tileLayout = <ITileLayout>this.layoutService.calculateLayout(tileIndex.toString(), DEFAULT_PANEL_LAYOUT);
                tileLayout.stackId = `${index}${VIEWPORT_INDEX_SEPERATOR}${stackIndex}`;
                newTileLayout.push(tileLayout);
                stackIndex++;
              }
              stackIndex = 0;
            }
          }
          // in MPR or fusion, the total panel max is 2
          const volumeStack = <IStackLayout>this.layoutService.calculateLayout((panelLayout.total - 1).toString(), DEFAULT_STACK_VOLUME_2D_LAYOUT);
          volumeStack.panelId = (panelLayout.total - 1).toString();
          newStackLayout.push(volumeStack);
          const volumeTile = <ITileLayout>this.layoutService.calculateVolumeLayout(newTileLayout.length.toString(), action.config.layout);
          volumeTile.stackId = `${volumeStack.panelId}${VIEWPORT_INDEX_SEPERATOR}${DEFAULT_STACK_ID}`;
          newTileLayout.push(volumeTile);
          //Calculate total
          return layoutActions.changePanelSuccess({
            panelLayout: panelLayout,
            stackLayout: newStackLayout,
            tileLayout: newTileLayout,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  changeVolumeLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.changeVolumeLayout),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActiveStack)),
        mergeMap(async ([action, activeStack]) => {
          try {
            const currentTileLayout = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectTileLayoutByStackIndex(activeStack?.stackIndex || DEFAULT_STACK_INDEX)));

            const tileLayout = <ITileLayout>this.layoutService.calculateVolumeLayout(currentTileLayout?.id || '0', action.tileLayout);
            tileLayout.stackId = currentTileLayout?.stackId || DEFAULT_STACK_INDEX;
            return layoutActions.changeTileSuccess({ tileLayout });
          } catch (err) {
            return layoutActions.changeLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  displayVolume2DLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.changeDisplayVolume2DSuccess),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectActivePanelLayoutBackup)),
        mergeMap(async ([action, activePanelLayout]) => {
          let panelLayoutString = DEFAULT_PANEL_LAYOUT;
          let panelLayout: IPanelLayout;
          let newStackLayout: IStackLayout[] = [];
          let newTileLayout: ITileLayout[] = [];
          const currentTileLayout = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectTileLayout));
          if (action.isDisplay) {
            //Display 2d layout on the left
            //new panel layout
            panelLayoutString = DEFAULT_PANEL_VOLUME_2D_LAYOUT;
            panelLayout = this.layoutService.calculateLayout(PANEL_LAYOUT_INDEX.toString(), panelLayoutString);
            //This new stack layout for 2d layout is same with old stack layout but we change panelId to 0
            const oldStackLayout = cloneDeep(activePanelLayout?.stackLayout) as IStackLayout;
            oldStackLayout.panelId = DEFAULT_PANEL_INDEX;
            //Set volume stack layout is 1x1 and panelId is 1
            const volumeStack = <IStackLayout>this.layoutService.calculateLayout((panelLayout.total - 1).toString(), DEFAULT_STACK_VOLUME_2D_LAYOUT);
            volumeStack.panelId = (panelLayout.total - 1).toString();
            newStackLayout = [oldStackLayout, volumeStack];
            // This new tile layout for 2d layout is same with old tile layout but we change stackId to 0-0
            const oldTileLayout = cloneDeep(activePanelLayout?.tileLayout) as ITileLayout[];
            oldTileLayout.forEach((tile) => {
              tile.stackId = `${DEFAULT_PANEL_INDEX}${VIEWPORT_INDEX_SEPERATOR}${tile.stackId.split('-')[1]}`;
            });
            const currentTileLayoutString = currentTileLayout.find((layout) => layout.stackId === DEFAULT_VOLUME_STACK_INDEX)?.layoutString;
            const newVolumeTileLayout = this.layoutService.calculateVolumeLayout((oldTileLayout.length - 1).toString(), currentTileLayoutString || DEFAULT_VOLUME_TILE_LAYOUT) as ITileLayout;
            newVolumeTileLayout.stackId = `${volumeStack.panelId}${VIEWPORT_INDEX_SEPERATOR}${DEFAULT_STACK_ID}`;
            newTileLayout = [...oldTileLayout, newVolumeTileLayout];
          } else {
            //When display only volume, we set default layout into panel 1x1
            panelLayoutString = DEFAULT_PANEL_VOLUME_LAYOUT;
            panelLayout = this.layoutService.calculateLayout(PANEL_LAYOUT_INDEX.toString(), panelLayoutString);
            //Stack 1x1
            const stackLayout = <IStackLayout>this.layoutService.calculateLayout(PANEL_LAYOUT_INDEX.toString(), DEFAULT_STACK_VOLUME_LAYOUT);
            stackLayout.panelId = DEFAULT_PANEL_INDEX;
            newStackLayout = [stackLayout];

            //Get current tile layout value on store
            const currentTileLayoutString = currentTileLayout.find((layout) => layout.stackId === DEFAULT_VOLUME_HAS_2D_STACK_INDEX)?.layoutString;
            // create new tile layout for volume
            const tileLayout = <ITileLayout>this.layoutService.calculateVolumeLayout(DEFAULT_STACK_ID.toString(), currentTileLayoutString || DEFAULT_VOLUME_TILE_LAYOUT);
            tileLayout.stackId = `${DEFAULT_PANEL_INDEX}${VIEWPORT_INDEX_SEPERATOR}${DEFAULT_STACK_ID}`;
            newTileLayout = [tileLayout];
          }
          return layoutActions.changePanelSuccess({
            panelLayout: panelLayout,
            stackLayout: newStackLayout,
            tileLayout: newTileLayout,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  backupLayoutVolume$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.enterMPRMode, volumeActions.enterFusionMode),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectLayout)),
        mergeMap(async ([action, currentLayout]) => {
          const selectedPanel = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActivePanel));
          const activePanelLayout = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActivePanelLayout(selectedPanel?.panelIndex || '0')));
          return layoutActions.backupLayout({ layout: currentLayout, activePanelLayout });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  restoreLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.restoreDisplayViewport),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectBackupLayout)),
        mergeMap(async ([action, backupLayout]) => {
          return layoutActions.restoreLayout({ layout: backupLayout as IBackupLayout });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  restoreActiveStack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.restoreLayout),
        mergeMap(async (action) => {
          const firstSeriesUId = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewport));
          return layoutActions.changeActiveStack({
            activeStack: {
              seriesUid: firstSeriesUId[0].seriesUid,
              stackIndex: DEFAULT_STACK_INDEX.toString(),
              layout: DEFAULT_STACK_LAYOUT,
            },
            mode: ViewerMode.Stack2D,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  restoreActivePanel$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.restoreLayout),
        mergeMap(async (action) => {
          return layoutActions.changeActivePanel({
            activePanel: {
              panelIndex: DEFAULT_PANEL_INDEX.toString(),
              layout: DEFAULT_PANEL_LAYOUT,
            },
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  backupLayoutFullImage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(layoutActions.backupLayoutFullImage),
        mergeMap(async (action) => {
          const newDisplayViewport = cloneDeep(action.displayviewport);
          newDisplayViewport.stackIndex = DEFAULT_STACK_INDEX;
          return viewerGeneralActions.onDisplayFullImage({
            displayViewport: newDisplayViewport,
            tileLayout: action.tileLayout,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );
  //#endregion
}
