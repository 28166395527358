import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logger } from '@shared';
import { IGeneralSetting, settingActions, settingQuery } from '@app/setting';
import { Observable, Subscribable, Subscription, filter, firstValueFrom } from 'rxjs';
import { I18nService } from '@app/i18n';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { APP_ROUTE_BASE, ThemeService } from '@app/@core';
import { AuthenticationFirebaseService, LoginMode, firebaseAuthActions } from '@app/auth';
const log = new Logger('Shell');

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private i18nService: I18nService,
    private themService: ThemeService,
    private router: Router,
    private authFirebaseService: AuthenticationFirebaseService,
    private route: Router,
  ) {}

  private generalSetting$: Observable<IGeneralSetting>;
  private settingSubs: Subscription;
  public showHeader: boolean = true;
  public showFooter: boolean = true;
  ngOnInit() {
    log.info('init shell');
    this.store.dispatch(settingActions.getAll());

    const generalSetting$ = this.store.select(settingQuery.selectGeneralSetting);
    this.settingSubs = generalSetting$.subscribe((setting) => {
      if (setting) {
        if (this.themService.theme !== setting.theme) {
          this.themService.theme = setting.theme;
        }

        if (this.i18nService.language !== setting.language) {
          this.i18nService.language = setting.language || this.i18nService.defaultLanguage;
        }
      }
    });

    // not show header and footer when open viewer and report
    this.updateHeaderFooterVisibility(this.router.url);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateHeaderFooterVisibility(event.url);
      }
    });
  }

  ngOnDestroy() {
    this.settingSubs.unsubscribe();
  }

  updateHeaderFooterVisibility = (url: string) => {
    if (!url) {
      return;
    }

    const shouldHide = url.includes(APP_ROUTE_BASE.VIEWVER) || url.includes(APP_ROUTE_BASE.REPORT);
    this.showHeader = !shouldHide;
    this.showFooter = !shouldHide;
  };
}
