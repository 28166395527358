import { Component, OnDestroy, OnInit, AfterContentInit } from '@angular/core';

import { authQuery } from '@app/auth';
import { Observable, firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { LoginMode, firebaseAuthActions } from '@app/auth/';
import { IFirebaseUser } from '@app/auth/models/IFirebaseUser';
import { IOnpremiseAPI } from '@app/auth/models/IUser';
import { shellActions } from '../store/shell.actions';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
/**
 * Represents the header component of the application.
 * This component is responsible for displaying the header section of the application,
 * including the menu toggle button and user information.
 */
export class HeaderComponent implements AfterContentInit {
  public menuHidden: boolean = true;
  public settingVisible: boolean = false;
  constructor(private store: Store) {}
  public userInfo$: Observable<IFirebaseUser | IOnpremiseAPI | undefined>;
  //#region subscribe area
  //#endregion

  /**
   * Lifecycle hook that is called after Angular has fully initialized the content of the component's view.
   * It is called only once after the first ngAfterViewInit.
   * Use this hook to perform any additional initialization tasks.
   */
  ngAfterContentInit() {
    this.userInfo$ = this.store.select(authQuery.selectUserInfo);
  }

  /**
   * Toggles the visibility of the menu.
   */
  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  /**
   * Logs out the user and navigates to the login page. This is a convenience method
   */
  /**
   * Logs out the user.
   * If the login mode is set to 'firebase', it calls the 'onLogout' method of the 'authFirebaseService'.
   * Navigates to the login page.
   */
  async logout() {
    const loginMode = await firstValueFrom(this.store.select(authQuery.selectLoginModeInfo));
    if (loginMode === LoginMode.firebase) {
      this.store.dispatch(firebaseAuthActions.logout());
    }
  }

  /**
   * Opens the setting dialog.
   * @param visible - Specifies whether the setting dialog should be visible or not. Default value is true.
   */
  openSettingDialog(visible: boolean = true) {
    this.store.dispatch(shellActions.changeSettingDialogVisible({ visible }));
  }
}
