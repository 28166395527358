import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { APP_ROUTE_BASE } from './@core';
// import { Shell } from '@app/shell/shell.service';
// import { AuthenticationGuard } from './auth';

const routes: Routes = [
  {
    path: APP_ROUTE_BASE.LANDING_PAGE,
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
