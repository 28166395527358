/// <reference types="gapi" />
/// <reference types="google.accounts" />

import { Injectable } from '@angular/core';
import { Logger } from '../logger.service';
import { environment } from 'src/environments/environment';
import { AuthenticationFirebaseService } from '@app/auth';

const log = new Logger('gpiInit');
interface Script {
  id: string;
  src: string;
  script?: HTMLScriptElement;
}

export interface ScriptLoadInfo {
  script: any;
  loaded: boolean;
  status: string;
}
@Injectable({
  providedIn: 'root',
})
export class GApiInitService {
  private _scripts = new Array<Script>();

  public isGpaiLoaded: boolean = false;
  public isGoogleLoaded: boolean = false;
  /**
   * @param private
   * @param authenticationService
   * @param AuthenticationService
   */
  constructor(private authenticationService: AuthenticationFirebaseService) {}

  /**
   *  // Loads a google javascript script and adds it to the list of scripts. Returns a promise that resolves when the script is loaded
   * @param sc
   * @param scEx
   * @returns
   */
  private async loadScript(sc: Script, scEx: (e: HTMLScriptElement) => void): Promise<ScriptLoadInfo> {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      const esc = this._scripts.find((s) => s.id === sc.id);
      // Load script and add it to the script list
      if (esc) {
        resolve({ script: esc.script, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = sc.id;
        script.src = sc.src;
        sc.script = script;
        scEx(script);
        script.onload = () => {
          this._scripts.push(sc);
          resolve({ script, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('body')[0].appendChild(script);
      }
    });
  }

  //
  /**
   *  Load GAPI and GIS. Returns true if successful false otherwise. This is called after all files have been processed
   * @returns status load google api
   */
  public async initGApi(): Promise<Boolean> {
    const ri = await this.loadScript(
      {
        id: 'gapi',
        src: 'https://apis.google.com/js/api.js',
      },
      (se) => {
        se.async = true;
        se.defer = true;
      },
    );
    const gsi = await this.loadScript(
      {
        id: 'gsi',
        src: 'https://accounts.google.com/gsi/client',
      },
      (se) => {
        se.async = true;
        se.defer = true;
      },
    );
    if (!ri.loaded || !gsi.loaded) {
      log.error('not load gapi!');
      return false;
    }
    await this._loadApi();
    await this._initGis();
    return await this._initConfig();
  }

  // Load the API and return a promise that resolves when done. This is a bit tricky because we don't want to wait for the response
  private _loadApi = async (): Promise<Boolean> => {
    return new Promise((rev, rej) => {
      gapi.load('client', async () => {
        rev(true);
      });
    });
  };

  // Scope a list of strings in the end of the query. This is used to ensure that we don't accidentally end up with a comma in the end
  private _scopeStr = (sc: string[]): string => {
    let ans = '';
    for (const s of sc) {
      ans += s + ' ';
    }
    return ans;
  };

  // Initializes GPAI. Returns true if successful false otherwise. This is called once at the start of the request
  private _initConfig = async (): Promise<Boolean> => {
    try {
      await gapi.client.init({
        apiKey: environment.firebase.apiKey,
        discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4', 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      });
      this.isGpaiLoaded = true;
      return true;
    } catch (ex) {
      log.error(JSON.stringify(ex, null, 2));
      return false;
    }
  };

  // Initializes GIS based on options set in config. Returns true if successful false
  private _initGis = async (): Promise<Boolean> => {
    try {
      const oauth2 = this.authenticationService.getGAPIOauth();
      // Set the access token to the client s access token if oauth2 is enabled.
      if (oauth2) {
        gapi.client.setToken({ access_token: oauth2?.accessToken || '' });
        gapi.auth.setToken({
          access_token: oauth2?.accessToken || '',
          expires_in: '86400',
          state: '',
          error: '',
        });
      }

      google.accounts.oauth2.initTokenClient({
        client_id: environment.gpai.clientId,
        scope: this._scopeStr(['https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/spreadsheets']),
        callback: this._callbackToken,
      });
      // let tokenClient = await google.accounts.oauth2.initTokenClient({

      //   client_id: environment.gpai.clientId,
      //   scope: ['https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/spreadsheets'].join(' '),
      // });
      this.isGoogleLoaded = true;
      return true;
    } catch (ex) {
      log.error(JSON.stringify(ex, null, 2));
      return false;
    }
  };

  private _callbackToken = () => {};
}
