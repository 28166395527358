import { Component, OnInit, OnDestroy, AfterContentInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed, toastPayload, SharedService, loaderPayload } from '@shared';
import { I18nService } from '@app/i18n';

const log = new Logger('App');

/**
 * Represents the root component of the application.
 * This component is responsible for initializing the application and managing its lifecycle.
 */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private changeDetector: ChangeDetectorRef,
    private sharedService: SharedService,
  ) {}

  //#region  Properties
  /**
   * Indicates whether the component is currently loading.
   */
  protected isLoading: boolean = true;

  /**
   * The message to be displayed in the loader.
   */
  protected loaderMessage: string;

  /**
   * The payload for displaying toast messages.
   */
  protected toastPayload: toastPayload;
  //#endregion

  //#region subscribe area
  private _subscribePreloaderService: Subscription;
  private _subscribeToastMessageService: Subscription;
  //#endregion

  /**
   * Initializes the component.
   * Subscribes to global services and sets up logger and translations.
   */
  ngOnInit() {
    //#region  Start subscribe global service when start app
    this._subscribePreloaderService = this.sharedService.preloader$.subscribe((payload: loaderPayload) => {
      if (this.isLoading !== payload.show) {
        this.isLoading = payload.show;
      }
      this.loaderMessage = payload.message;
    });

    this._subscribeToastMessageService = this.sharedService.toastMessage$.subscribe((payload: toastPayload) => {
      this.toastPayload = payload;
    });

    //#endregion
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this),
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  /**
   * Detects changes in the component's content.
   */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * Cleans up resources when the component is destroyed.
   */
  ngOnDestroy() {
    this.i18nService.destroy();
    this._subscribePreloaderService.unsubscribe();
    this._subscribeToastMessageService.unsubscribe();
  }
}
