export const CPU_RENDER_MODALITY = ['OP'];
export const MODALITY_NAME = {
  CT: 'CT',
  MR: 'MR',
  PT: 'PT',
  NM: 'NM',
  CR: 'CR',
  DX: 'DX',
  MG: 'MG',
  US: 'US',
  OT: 'OT',
  SC: 'SC',
  XA: 'XA',
  RF: 'RF',
  PET: 'PET',
  PETMIP: 'PETMIP',
  FUSION: 'FUSION',
  OP: 'OP',
};

export const MODALITY_SEPARATOR = ',';
