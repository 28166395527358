export enum IFilterType {
  modality,
  bodypart,
  registerDate,
  studyDate,
}
export const FILTER_TYPE = {
  modality: 'studyModality',
  bodypart: 'studyBodyPart',
  registerDate: 'studyRegisterDate',
  studyDate: 'studyDate',
};
export interface IFilter {
  type: IFilterType;
  filterData: string;
}

export interface IFilterButton {
  label: string;
  type: IFilterType;
  filterData: string;
}
