<div class="report">
  <div class="report__header">
    <div class="report__info">
      <app-report-patient class="report__info__patient" [studyUid]="selectedStudyUid" />
      <app-report-study-list class="report__info__studies" (selectedStudyEvent)="onSelectedStudy($event)" />
    </div>
    <app-clinic-info class="report__study-list" [studyUid]="selectedStudyUid" />
  </div>
  <app-content class="report__body" [studyUid]="selectedStudyUid" />
  <app-footer class="report__footer"></app-footer>
</div>
