import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements AfterViewInit {
  constructor(private route: Router) {}

  async ngAfterViewInit() {
    const currentPage = this.route.url.split('/')[1];
    if (currentPage === '') {
      // if this is pwa mode install app, redirect to worklist page
      if (window.matchMedia('(display-mode: standalone)').matches) {
        this.route.navigate(['/worklist']);
      } else {
        const lang = window.navigator.language;
        if (lang.toLocaleLowerCase().includes('en') || lang.toLocaleLowerCase().includes('us')) {
          window.location.href = '/en-us';
        } else if (lang.toLocaleLowerCase().includes('jp') || lang.toLocaleLowerCase().includes('ja')) {
          window.location.href = '/ja-jp';
        } else if (lang.toLocaleLowerCase().includes('vi') || lang.toLocaleLowerCase().includes('vn')) {
          window.location.href = '/vi-vn';
        } else {
          window.location.href = '/en-us';
        }
      }
    }
  }
}
