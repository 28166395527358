import { Injectable } from '@angular/core';
import { RENDER_ENGINE_ID } from '@app/@core/dicom/contants/cornerstone';
import { Logger } from '@app/@shared';

import {
  ReferenceLinesTool,
  ToolGroupManager,
  StackScrollMouseWheelTool,
  ZoomTool,
  PanTool,
  Enums,
  Types,
  addTool,
  init,
  WindowLevelTool,
  MIPJumpToClickTool,
  VolumeRotateMouseWheelTool,
  CrosshairsTool,
  TrackballRotateTool,
  StackScrollTool,
  OrientationMarkerTool,
  PlanarRotateTool,
  ArrowAnnotateTool,
  LengthTool,
  ProbeTool,
  RectangleROITool,
  EllipticalROITool,
  CircleROITool,
  BidirectionalTool,
  AngleTool,
  CobbAngleTool,
  PlanarFreehandROITool,
  annotation,
  MagnifyTool,
  cursors,
} from '@cornerstonejs/tools';

const { selection, visibility } = annotation;
import { Store } from '@ngrx/store';
import { getReferenceLineColor, getReferenceLineControllable, getReferenceLineDraggableRotatable, getReferenceLineSlabThicknessControlsOn } from '../helpers/petct/reference-line';
import { CornerstoneService, MODALITY_NAME } from '@app/@core';
import { ViewerMode } from '../contants/mode';
import { MPR_VIEWPORT_ID } from '../contants/viewport';
import { firstValueFrom } from 'rxjs';
import { ViewerMenuQuery } from '../store/menu/menu.selectors';
import { ViewerGeneralQuery } from '../store/general';
import { ViewerLayoutQuery } from '../store/layout';
import RectangleAnnotateTool from '../helpers/annotation/RectangleAnnotateTool';
import EllipseAnnotateTool from '../helpers/annotation/EllipseAnnotateTool';
import RegionTool from '../helpers/annotation/RegionTool';
import { Annotate, Tools } from '../contants';
import { EllipseCursor, RectangleCursor } from '../helpers/annotation';
//import ScaleOverlayTool from '../helpers/overlay/ScaleOverlayTool';

const toolGroupId = '2DTool';
const log = new Logger('toolservice');

@Injectable()
export class ToolsService {
  private _stackToolGroup: Types.IToolGroup | undefined;
  private _currentVolumeTools: Types.IToolGroup[] = [];
  constructor(
    private store: Store,
    private cornerstone: CornerstoneService,
  ) {
    init();
    // Add tools to Cornerstone3D
    //#region Tools
    addTool(ReferenceLinesTool);
    addTool(ZoomTool);
    addTool(StackScrollMouseWheelTool);
    addTool(PanTool);
    addTool(WindowLevelTool);
    addTool(PlanarRotateTool);
    addTool(MIPJumpToClickTool);
    addTool(VolumeRotateMouseWheelTool);
    addTool(CrosshairsTool);
    addTool(TrackballRotateTool);
    addTool(OrientationMarkerTool);

    //#endregion
    //#region Annotation
    addTool(ArrowAnnotateTool);
    addTool(RectangleAnnotateTool);
    addTool(EllipseAnnotateTool);
    addTool(RegionTool);
    //#endregion
    //#region Measure
    addTool(LengthTool);
    addTool(ProbeTool);
    addTool(RectangleROITool);
    addTool(EllipticalROITool);
    addTool(CircleROITool);
    addTool(BidirectionalTool);
    addTool(AngleTool);
    addTool(CobbAngleTool);
    addTool(PlanarFreehandROITool);
    //#endregion

    //#region Other tools
    addTool(MagnifyTool);
    //#endregion
    // Define a tool group, which defines how mouse events map to tool commands for
    // Any viewport using the group
    this._stackToolGroup = ToolGroupManager.createToolGroup(toolGroupId);
  }
  //#region  General Function
  /**
   * Initializes the default tool stack.
   * Adds various tools to the tool group and specifies their configurations.
   */
  public initDefautlToolStack() {
    if (this._stackToolGroup === undefined) {
      return;
    }
    // Add the tools to the tool group and specify which volume they are pointing at
    this._stackToolGroup.addTool(ReferenceLinesTool.toolName, { showFullDimension: true });
    this._stackToolGroup.addTool(ZoomTool.toolName);
    this._stackToolGroup.addTool(WindowLevelTool.toolName);
    this._stackToolGroup.addTool(PlanarRotateTool.toolName);
    cursors.registerCursor(PlanarRotateTool.toolName, cursors.CursorSVG['Rotate'].iconContent, cursors.CursorSVG['Rotate'].viewBox);
    // this._stackToolGroup.addTool(StackScrollMouseWheelTool.toolName, {
    //   debounceIfNotLoaded: false,
    // });
    this._stackToolGroup.addTool(PanTool.toolName);

    // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
    // hook instead of mouse buttons, it does not need to assign any mouse button.
    // this._stackToolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
    this._stackToolGroup.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // Right Click
        },
      ],
    });

    //#region Annotate
    this._stackToolGroup.addTool(ArrowAnnotateTool.toolName);
    this._stackToolGroup.addTool(RectangleAnnotateTool.toolName);
    cursors.registerCursor(RectangleAnnotateTool.toolName, RectangleCursor.iconContent, RectangleCursor.viewBox);
    this._stackToolGroup.addTool(EllipseAnnotateTool.toolName);
    cursors.registerCursor(EllipseAnnotateTool.toolName, EllipseCursor.iconContent, EllipseCursor.viewBox);
    this._stackToolGroup.addTool(RegionTool.toolName);
    //#endregion

    //#region Measure
    this._stackToolGroup.addTool(LengthTool.toolName);
    this._stackToolGroup.addTool(ProbeTool.toolName);
    this._stackToolGroup.addTool(RectangleROITool.toolName);
    this._stackToolGroup.addTool(EllipticalROITool.toolName);
    this._stackToolGroup.addTool(CircleROITool.toolName);
    this._stackToolGroup.addTool(BidirectionalTool.toolName);
    this._stackToolGroup.addTool(AngleTool.toolName);
    this._stackToolGroup.addTool(CobbAngleTool.toolName);
    this._stackToolGroup.addTool(PlanarFreehandROITool.toolName, {
      calculateStats: true,
    });
    //#endregion

    //#region Other tools
    this._stackToolGroup.addTool(MagnifyTool.toolName);
    //#endregion
  }

  /**
   * Maps a tool enum value to its corresponding name.
   * @param tool - The tool enum value.
   * @returns The name of the tool.
   */
  public mapToolToName = (tool: Tools) => {
    switch (tool) {
      case Tools.Scroll:
        return StackScrollTool.toolName;
      case Tools.Zoom:
        return ZoomTool.toolName;
      case Tools.WWWL:
        return WindowLevelTool.toolName;
      case Tools.Pan:
        return PanTool.toolName;
      case Tools.Rotate:
        return PlanarRotateTool.toolName;
      default:
        return 'default';
    }
  };

  /**
   * Enables the cursor for the specified tool.
   * @param tool - The tool for which to enable the cursor.
   */
  public enableCursor = (toolName: string) => {
    const viewports = this.cornerstone.getAllStackViewport();
    viewports.forEach((viewport) => {
      cursors.setCursorForElement(viewport.element, toolName);
    });
  };

  /**
   * Initializes the default tool volume for the specified tool group.
   */
  public initDefaultToolVolume() {
    this._currentVolumeTools.forEach((toolGroup) => {
      // Add the tools to the tool group and specify which volume they are pointing at
      toolGroup.addTool(ZoomTool.toolName);
      toolGroup.addTool(WindowLevelTool.toolName);
      toolGroup.addTool(PanTool.toolName);

      //#region Annotate
      toolGroup.addTool(ArrowAnnotateTool.toolName);
      toolGroup.addTool(RectangleAnnotateTool.toolName);
      toolGroup.addTool(EllipseAnnotateTool.toolName);
      toolGroup.addTool(RegionTool.toolName);
      //#endregion

      //#region Measure
      toolGroup.addTool(LengthTool.toolName);
      toolGroup.addTool(ProbeTool.toolName);
      toolGroup.addTool(RectangleROITool.toolName);
      toolGroup.addTool(EllipticalROITool.toolName);
      toolGroup.addTool(CircleROITool.toolName);
      toolGroup.addTool(BidirectionalTool.toolName);
      toolGroup.addTool(AngleTool.toolName);
      toolGroup.addTool(CobbAngleTool.toolName);
      toolGroup.addTool(PlanarFreehandROITool.toolName, {
        calculateStats: true,
      });
      //#endregion

      //#region Other tools
      toolGroup.addTool(MagnifyTool.toolName);
      //#endregion
    });
  }

  /**
   * Sets the default tool stack by setting all tools to passive state.
   */
  public setDefaultToolStack() {
    this._stackToolGroup?.setToolDisabled(ZoomTool.toolName);
    this._stackToolGroup?.setToolDisabled(PanTool.toolName);
    this._stackToolGroup?.setToolDisabled(PlanarRotateTool.toolName);
    this._stackToolGroup?.setToolDisabled(WindowLevelTool.toolName);
    this._stackToolGroup?.setToolDisabled(MagnifyTool.toolName);
    this._stackToolGroup?.setToolDisabled(RegionTool.toolName);
    this.enableCursor('default');
    this._stackToolGroup?.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // Right Click
        },
      ],
    });
  }

  /**
   * Sets the default tool volume by disabling certain tools and activating the WindowLevelTool with secondary mouse button binding.
   */
  public setDefaultToolVolume() {
    this._currentVolumeTools.forEach((toolGroup) => {
      toolGroup.setToolDisabled(ZoomTool.toolName);
      toolGroup.setToolDisabled(PanTool.toolName);
      toolGroup.setToolDisabled(WindowLevelTool.toolName);
      toolGroup.setToolDisabled(MagnifyTool.toolName);
      toolGroup.setToolDisabled(RegionTool.toolName);
      toolGroup.setToolActive(WindowLevelTool.toolName, {
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Secondary, // Right Click
          },
        ],
      });
    });
  }

  /**
   * Sets all annotate and measure tools to passive state.
   * This method is used to deactivate all annotate and measure tools in the stack tool group.
   */
  public passiveAllAnnotateMesuareStack() {
    this._stackToolGroup?.setToolPassive(ArrowAnnotateTool.toolName);
    this._stackToolGroup?.setToolPassive(RectangleAnnotateTool.toolName);
    this._stackToolGroup?.setToolPassive(EllipseAnnotateTool.toolName);
    this._stackToolGroup?.setToolPassive(LengthTool.toolName);
    this._stackToolGroup?.setToolPassive(ProbeTool.toolName);
    this._stackToolGroup?.setToolPassive(RectangleROITool.toolName);
    this._stackToolGroup?.setToolPassive(EllipticalROITool.toolName);
    this._stackToolGroup?.setToolPassive(CircleROITool.toolName);
    this._stackToolGroup?.setToolPassive(BidirectionalTool.toolName);
    this._stackToolGroup?.setToolPassive(AngleTool.toolName);
    this._stackToolGroup?.setToolPassive(CobbAngleTool.toolName);
    this._stackToolGroup?.setToolPassive(PlanarFreehandROITool.toolName);
    this._stackToolGroup?.setToolPassive(RegionTool.toolName);
  }

  /**
   * Sets all annotation and measurement tools to passive state.
   * This method is used to deactivate all tools related to annotation and measurement.
   */
  public passiveAllAnnotateMesuareVolume() {
    this._currentVolumeTools.forEach((toolGroup) => {
      toolGroup.setToolPassive(ArrowAnnotateTool.toolName);
      toolGroup.setToolPassive(RectangleAnnotateTool.toolName);
      toolGroup.setToolPassive(EllipseAnnotateTool.toolName);
      toolGroup.setToolPassive(LengthTool.toolName);
      toolGroup.setToolPassive(ProbeTool.toolName);
      toolGroup.setToolPassive(RectangleROITool.toolName);
      toolGroup.setToolPassive(EllipticalROITool.toolName);
      toolGroup.setToolPassive(CircleROITool.toolName);
      toolGroup.setToolPassive(BidirectionalTool.toolName);
      toolGroup.setToolPassive(AngleTool.toolName);
      toolGroup.setToolPassive(CobbAngleTool.toolName);
      toolGroup.setToolPassive(PlanarFreehandROITool.toolName);
      toolGroup.setToolPassive(RegionTool.toolName);
    });
  }

  /**
   * Sets the active stack tool.
   * @param toolName - The name of the tool to set as active.
   */
  public setActiveStackTool(toolName: string) {
    this._stackToolGroup?.setToolActive(toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Primary, // Left Click
        },
      ],
    });
  }

  /**
   * Sets the active volume tool.
   * @param toolName - The name of the tool to set as active.
   */
  public setActiveVolumeTool(toolName: string) {
    this._currentVolumeTools.forEach((toolGroup) => {
      toolGroup.setToolActive(toolName, {
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Primary, // Left Click
          },
        ],
      });
    });
  }

  /**
   * Sets the state of the reference lines tool.
   * @param isDisplay - A boolean indicating whether to display the reference lines tool.
   * @returns A promise that resolves when the state is set.
   */
  public setStateReferenceLine = async (isDisplay: boolean) => {
    if (isDisplay) {
      const currentActiveStack = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveStack));
      // Add the tools to the tool group and specify which volume they are pointing at
      this._stackToolGroup?.setToolConfiguration(ReferenceLinesTool.toolName, {
        sourceViewportId: `${currentActiveStack?.stackIndex || '0-0'}-0`,
      });

      // Set the initial state of the tools, here we set one tool active on left click.
      // This means left click will draw that tool.
      this._stackToolGroup?.setToolEnabled(ReferenceLinesTool.toolName);
    } else {
      this._stackToolGroup?.setToolDisabled(ReferenceLinesTool.toolName);
    }
  };

  /**
   * Adds a viewport to the tool group.
   *
   * @param index - The index of the viewport to add.
   */
  public addViewport(index: string) {
    this._stackToolGroup?.addViewport(index, RENDER_ENGINE_ID);
  }

  /**
   * Removes tools based on the provided group ID and viewer mode.
   *
   * @param groupId - The ID of the tool group.
   * @param mode - The viewer mode.
   * @returns A promise that resolves when the tools are removed.
   */
  public removeTools = async (groupId: string, mode: ViewerMode) => {
    switch (mode) {
      // case ViewerMode.Stack2D:
      //   //Remove 2D tool
      //   const viewports = this._stackToolGroup?.getViewportIds();
      //   if (viewports) {
      //     viewports.forEach((id) => {
      //       this._stackToolGroup?.removeViewports(id);
      //     });
      //     this._stackToolGroup?.setToolDisabled(ReferenceLinesTool.toolName);
      //   }

      //   break;
      case ViewerMode.MPR:
        //Remove 3D volume tool
        const volume3dTool = ToolGroupManager.getToolGroup(MPR_VIEWPORT_ID.VOLUME3D);
        if (volume3dTool !== undefined) {
          volume3dTool.setToolDisabled(TrackballRotateTool.toolName);
          volume3dTool.setToolDisabled(OrientationMarkerTool.toolName);
          // const viewports = volume3dTool.getViewportIds();
          // viewports.forEach((id) => {
          //   volume3dTool.removeViewports(RENDER_ENGINE_ID, id);
          // });
          ToolGroupManager.destroyToolGroup(MPR_VIEWPORT_ID.VOLUME3D);
          this._currentVolumeTools = this._currentVolumeTools.filter((toolGroup) => toolGroup.id !== volume3dTool.id);
        }
        //MPR tool group
        const display2D = await firstValueFrom(this.store.select(ViewerMenuQuery.selectMprDisplay2D));
        if (!display2D) {
          this._stackToolGroup?.setToolDisabled(ReferenceLinesTool.toolName);
          const viewportsStack = this._stackToolGroup?.getViewportIds();
          if (viewportsStack) {
            viewportsStack.forEach((id) => {
              this._stackToolGroup?.removeViewports(RENDER_ENGINE_ID, id);
            });
          }
        }
        const mprTool = ToolGroupManager.getToolGroup(groupId);
        if (mprTool) {
          mprTool.setToolDisabled(StackScrollMouseWheelTool.toolName);
          mprTool.setToolDisabled(CrosshairsTool.toolName);
          mprTool.setToolDisabled(WindowLevelTool.toolName);

          // const viewports = mprTool.getViewportIds();
          // viewports.forEach((id) => {
          //   mprTool.removeViewports(RENDER_ENGINE_ID, id);
          // });
          ToolGroupManager.destroyToolGroup(groupId);
          this._currentVolumeTools = this._currentVolumeTools.filter((toolGroup) => toolGroup.id !== mprTool.id);
        }

        break;
      case ViewerMode.Fusion:
        const display2DFusion = await firstValueFrom(this.store.select(ViewerMenuQuery.selectFusionDisplay2D));
        if (!display2DFusion) {
          this._stackToolGroup?.setToolDisabled(ReferenceLinesTool.toolName);
          const viewportsStack = this._stackToolGroup?.getViewportIds();
          if (viewportsStack) {
            viewportsStack.forEach((id) => {
              this._stackToolGroup?.removeViewports(RENDER_ENGINE_ID, id);
            });
          }
        }
        const ctToolGroup = ToolGroupManager.getToolGroup(`ct${groupId}`);
        const ptToolGroup = ToolGroupManager.getToolGroup(`pt${groupId}`);
        const fusionToolGroup = ToolGroupManager.getToolGroup(`fusion${groupId}`);
        const mipToolGroup = ToolGroupManager.getToolGroup(`mip${groupId}`);
        if (ctToolGroup) {
          ctToolGroup.setToolDisabled(ZoomTool.toolName);
          ctToolGroup.setToolDisabled(StackScrollMouseWheelTool.toolName);
          ctToolGroup.setToolDisabled(CrosshairsTool.toolName);
          // const viewportsCT = ctToolGroup.getViewportIds();
          // viewportsCT.forEach((id) => {
          //   ctToolGroup.removeViewports(RENDER_ENGINE_ID, id);
          // });
          ToolGroupManager.destroyToolGroup(`ct${groupId}`);
          this._currentVolumeTools = this._currentVolumeTools.filter((toolGroup) => toolGroup.id !== ctToolGroup.id);
        }
        if (ptToolGroup) {
          ptToolGroup.setToolDisabled(ZoomTool.toolName);
          ptToolGroup.setToolDisabled(StackScrollMouseWheelTool.toolName);
          ptToolGroup.setToolDisabled(CrosshairsTool.toolName);
          // const viewportsPT = ptToolGroup.getViewportIds();
          // viewportsPT.forEach((id) => {
          //   ptToolGroup.removeViewports(RENDER_ENGINE_ID, id);
          // });
          ToolGroupManager.destroyToolGroup(`pt${groupId}`);
          this._currentVolumeTools = this._currentVolumeTools.filter((toolGroup) => toolGroup.id !== ptToolGroup.id);
        }

        if (fusionToolGroup) {
          fusionToolGroup.setToolDisabled(ZoomTool.toolName);
          fusionToolGroup.setToolDisabled(StackScrollMouseWheelTool.toolName);
          fusionToolGroup.setToolDisabled(CrosshairsTool.toolName);
          // const viewportsFusion = fusionToolGroup.getViewportIds();
          // viewportsFusion.forEach((id) => {
          //   fusionToolGroup.removeViewports(RENDER_ENGINE_ID, id);
          // });
          ToolGroupManager.destroyToolGroup(`fusion${groupId}`);
          this._currentVolumeTools = this._currentVolumeTools.filter((toolGroup) => toolGroup.id !== fusionToolGroup.id);
        }

        if (mipToolGroup) {
          mipToolGroup.setToolDisabled('VolumeRotateMouseWheel');
          mipToolGroup.setToolDisabled('MIPJumpToClickTool');
          // const viewportsMIP = mipToolGroup.getViewportIds();
          // viewportsMIP.forEach((id) => {
          //   mipToolGroup.removeViewports(RENDER_ENGINE_ID, id);
          // });
          ToolGroupManager.destroyToolGroup(`mip${groupId}`);
          this._currentVolumeTools = this._currentVolumeTools.filter((toolGroup) => toolGroup.id !== mipToolGroup.id);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Removes the specified viewport from the tool groups based on the viewer mode and viewport type.
   * @param mode - The viewer mode.
   * @param groupId - The group ID of the tool group.
   * @param viewportId - The ID of the viewport to be removed.
   * @param is3D - Indicates whether the viewport is a 3D viewport.
   */
  public removeVolumesViewport = async (mode: ViewerMode, groupId: string, viewportId: string, is3D: boolean) => {
    if (is3D) {
      const volume3dTool = ToolGroupManager.getToolGroup(MPR_VIEWPORT_ID.VOLUME3D);
      if (volume3dTool !== undefined) {
        volume3dTool.removeViewports(RENDER_ENGINE_ID, viewportId);
      }
    } else {
      switch (mode) {
        case ViewerMode.MPR:
          const mprTool = ToolGroupManager.getToolGroup(groupId);
          if (mprTool) {
            mprTool.removeViewports(RENDER_ENGINE_ID, viewportId);
          }
          break;
        case ViewerMode.Fusion:
          const ctToolGroup = ToolGroupManager.getToolGroup(`ct${groupId}`);
          const ptToolGroup = ToolGroupManager.getToolGroup(`pt${groupId}`);
          const fusionToolGroup = ToolGroupManager.getToolGroup(`fusion${groupId}`);
          const mipToolGroup = ToolGroupManager.getToolGroup(`mip${groupId}`);
          if (ctToolGroup) {
            ctToolGroup.removeViewports(RENDER_ENGINE_ID, viewportId);
          }
          if (ptToolGroup) {
            ptToolGroup.removeViewports(RENDER_ENGINE_ID, viewportId);
          }
          if (fusionToolGroup) {
            fusionToolGroup.removeViewports(RENDER_ENGINE_ID, viewportId);
          }
          if (mipToolGroup) {
            mipToolGroup.removeViewports(RENDER_ENGINE_ID, viewportId);
          }
          break;
        default:
          break;
      }
    }
  };

  /**
   * Removes the 2D tools from the specified viewport.
   * @param viewportId The ID of the viewport from which to remove the 2D tools.
   */
  public remove2DTools = async (viewportId: string) => {
    //Remove 2D tool
    this._stackToolGroup?.setToolDisabled(ReferenceLinesTool.toolName);
    this._stackToolGroup?.removeViewports(RENDER_ENGINE_ID, viewportId);
  };
  //#endregion

  //#region  Reference Line
  /**
   * Enables the reference line tool for a specific stack index.
   * @param index - The index of the tile to enable the reference line tool for.
   */
  public async changeActiveReferenceLine(index: string) {
    const isActive = await firstValueFrom(this.store.select(ViewerMenuQuery.selectDisplayReferenceLine));
    if (isActive) {
      // Add the tools to the tool group and specify which volume they are pointing at
      this._stackToolGroup?.setToolConfiguration(ReferenceLinesTool.toolName, {
        sourceViewportId: index,
      });

      // Set the initial state of the tools, here we set one tool active on left click.
      // This means left click will draw that tool.
      this._stackToolGroup?.setToolEnabled(ReferenceLinesTool.toolName);
    }
  }

  //#endregion

  //#region MPR FUSION TOOLS
  /**
   * Activates the specified tools in the MPR viewport for the given group ID.
   * If the tool group does not exist, it creates a new one.
   *
   * @param groupId - The ID of the tool group.
   * @param viewportIds - An array of viewport IDs to add to the tool group.
   * @param volumeId - The ID of the volume.
   */
  public activeToolToMPRViewport(groupId: string, viewportIds: string[], volumeId: string) {
    const volume3DViewport = viewportIds.find((id) => id.includes(MPR_VIEWPORT_ID.VOLUME3D));
    if (volume3DViewport) {
      this.addToolToVolume3D(volume3DViewport, volumeId);
    }
    const viewportMPRIds = viewportIds.filter((id) => !id.includes(MPR_VIEWPORT_ID.VOLUME3D));
    let toolGroup = ToolGroupManager.getToolGroup(groupId);
    let isNewToolGroup = false;
    if (toolGroup === undefined) {
      toolGroup = ToolGroupManager.createToolGroup(groupId);
      isNewToolGroup = true;
    }

    viewportMPRIds.forEach((id) => toolGroup?.addViewport(id, RENDER_ENGINE_ID));

    if (isNewToolGroup) {
      toolGroup?.addTool(StackScrollMouseWheelTool.toolName);
      toolGroup?.addTool(CrosshairsTool.toolName, {
        getReferenceLineColor,
        getReferenceLineControllable,
        getReferenceLineDraggableRotatable,
        getReferenceLineSlabThicknessControlsOn,
      });
      toolGroup?.addTool(WindowLevelTool.toolName);
    }

    toolGroup?.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // Right Click
        },
      ],
    });

    toolGroup?.setToolActive(StackScrollMouseWheelTool.toolName);
    toolGroup?.setToolActive(CrosshairsTool.toolName, {
      bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
    });
    //@ts-ignore
    //Is check case tool group undefined above and create new tool group
    this._currentVolumeTools.push(toolGroup);
  }

  /**
   * Adds default tools for CT (Computed Tomography) to the specified group and viewports.
   * @param groupId - The ID of the tool group.
   * @param viewportIds - An array of viewport IDs to which the tools will be added.
   */
  public addDefaultToolsCT(groupId: string, viewportIds: string[]) {
    const ctToolGroup = ToolGroupManager.createToolGroup(`ct${groupId}`);
    viewportIds.forEach((id) => ctToolGroup?.addViewport(id, RENDER_ENGINE_ID));

    // Manipulation Tools
    ctToolGroup?.addTool(PanTool.toolName);
    ctToolGroup?.addTool(ZoomTool.toolName);
    ctToolGroup?.addTool(WindowLevelTool.toolName);
    ctToolGroup?.addTool(StackScrollMouseWheelTool.toolName);
    if (viewportIds.length >= 2) {
      ctToolGroup?.addTool(CrosshairsTool.toolName, {
        getReferenceLineColor,
        getReferenceLineControllable,
        getReferenceLineDraggableRotatable,
        getReferenceLineSlabThicknessControlsOn,
      });
      ctToolGroup?.setToolActive(CrosshairsTool.toolName, {
        bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
      });
    }

    ctToolGroup?.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // Right Click
        },
      ],
    });

    ctToolGroup?.setToolActive(StackScrollMouseWheelTool.toolName);
    //@ts-ignore
    //Is check case tool group undefined above and create new tool group
    this._currentVolumeTools.push(ctToolGroup);
  }

  /**
   * Adds default tools for a given group ID and viewport IDs.
   * @param groupId - The ID of the tool group.
   * @param viewportIds - An array of viewport IDs.
   */
  public addDefaultToolsPT(groupId: string, viewportIds: string[]) {
    const ptToolGroup = ToolGroupManager.createToolGroup(`pt${groupId}`);
    viewportIds.forEach((id) => ptToolGroup?.addViewport(id, RENDER_ENGINE_ID));

    // Manipulation Tools

    ptToolGroup?.addTool(PanTool.toolName);
    ptToolGroup?.addTool(ZoomTool.toolName);
    ptToolGroup?.addTool(WindowLevelTool.toolName);
    ptToolGroup?.addTool(StackScrollMouseWheelTool.toolName);

    if (viewportIds.length >= 2) {
      ptToolGroup?.addTool(CrosshairsTool.toolName, {
        getReferenceLineColor,
        getReferenceLineControllable,
        getReferenceLineDraggableRotatable,
        getReferenceLineSlabThicknessControlsOn,
      });
      ptToolGroup?.setToolActive(CrosshairsTool.toolName, {
        bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
      });
    }

    ptToolGroup?.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // Right Click
        },
      ],
    });

    ptToolGroup?.setToolActive(StackScrollMouseWheelTool.toolName);
    //@ts-ignore
    //Is check case tool group undefined above and create new tool group
    this._currentVolumeTools.push(ptToolGroup);
  }

  /**
   * Adds default tools for fusion to the specified group and viewports.
   * @param groupId - The ID of the tool group.
   * @param viewportIds - An array of viewport IDs.
   * @param ctVolumeId - The ID of the CT volume.
   */
  public addDefaultToolsFusion(groupId: string, viewportIds: string[], ctVolumeId: string) {
    const fusionToolGroup = ToolGroupManager.createToolGroup(`fusion${groupId}`);
    viewportIds.map((id) => fusionToolGroup?.addViewport(id, RENDER_ENGINE_ID));

    fusionToolGroup?.addTool(PanTool.toolName);
    fusionToolGroup?.addTool(WindowLevelTool.toolName);
    fusionToolGroup?.addTool(ZoomTool.toolName);
    fusionToolGroup?.addTool(StackScrollMouseWheelTool.toolName);
    if (viewportIds.length >= 2) {
      fusionToolGroup?.addTool(CrosshairsTool.toolName, {
        getReferenceLineColor,
        getReferenceLineControllable,
        getReferenceLineDraggableRotatable,
        getReferenceLineSlabThicknessControlsOn,
        // Only set CT volume to MIP in the fusion viewport
        filterActorUIDsToSetSlabThickness: [ctVolumeId],
      });
      fusionToolGroup?.setToolActive(CrosshairsTool.toolName, {
        bindings: [{ mouseButton: Enums.MouseBindings.Primary }],
      });
    }

    fusionToolGroup?.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // Right Click
        },
      ],
    });

    fusionToolGroup?.setToolActive(StackScrollMouseWheelTool.toolName);
    //@ts-ignore
    //Is check case tool group undefined above and create new tool group
    this._currentVolumeTools.push(fusionToolGroup);
  }

  /**
   * Adds default tools for MIP (Maximum Intensity Projection) to the specified group and viewport.
   * @param groupId - The ID of the tool group.
   * @param viewportId - The ID of the viewport.
   */
  public addDefaultToolsMIP(groupId: string, viewportId: string) {
    // MIP Tool Groups
    const mipToolGroup = ToolGroupManager.createToolGroup(`mip${groupId}`);
    mipToolGroup?.addViewport(viewportId, RENDER_ENGINE_ID);
    mipToolGroup?.addTool('VolumeRotateMouseWheel');
    mipToolGroup?.addTool('MIPJumpToClickTool', {
      toolGroupId: `pt${groupId}`,
    });

    // Set the initial state of the tools, here we set one tool active on left click.
    // This means left click will draw that tool.
    mipToolGroup?.setToolActive('MIPJumpToClickTool', {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Primary, // Left Click
        },
      ],
    });
    // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
    // hook instead of mouse buttons, it does not need to assign any mouse button.
    mipToolGroup?.setToolActive('VolumeRotateMouseWheel');
  }

  /**
   * Sets the state of the crosshair tool.
   * @param isDisplay - A boolean indicating whether to display the crosshair tool.
   */
  public setStateCrosshair = async (isDisplay: boolean) => {
    const viewerMode = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectViewerMode));
    if (viewerMode === ViewerMode.MPR) {
      const display2D = await firstValueFrom(this.store.select(ViewerMenuQuery.selectMprDisplay2D));
      let mprTool = ToolGroupManager.getToolGroup('0-0');
      if (display2D) {
        mprTool = ToolGroupManager.getToolGroup('1-0');
      }
      if (!mprTool) {
        return;
      }
      this._updateCrosshairConfiguration(isDisplay, mprTool);
      return;
    }

    if (viewerMode === ViewerMode.Fusion) {
      const display2DFusion = await firstValueFrom(this.store.select(ViewerMenuQuery.selectFusionDisplay2D));
      let groupId = '0-0';
      if (display2DFusion) {
        groupId = '1-0';
      }
      const ctToolGroup = ToolGroupManager.getToolGroup(`ct${groupId}`);
      const ptToolGroup = ToolGroupManager.getToolGroup(`pt${groupId}`);
      const fusionToolGroup = ToolGroupManager.getToolGroup(`fusion${groupId}`);
      if (ctToolGroup) {
        this._updateCrosshairConfiguration(isDisplay, ctToolGroup);
      }
      if (ptToolGroup) {
        this._updateCrosshairConfiguration(isDisplay, ptToolGroup);
      }

      if (fusionToolGroup) {
        this._updateCrosshairConfiguration(isDisplay, fusionToolGroup);
      }
    }
  };

  /**
   * Updates the crosshair configuration based on the specified display flag and tool group.
   * @param isDisplay - A boolean indicating whether to display the crosshair tool.
   * @param toolGroup - The tool group to update.
   */
  private _updateCrosshairConfiguration = async (isDisplay: boolean, toolGroup: Types.IToolGroup) => {
    if (isDisplay) {
      toolGroup.setToolActive(CrosshairsTool.toolName, { bindings: [{ mouseButton: Enums.MouseBindings.Primary }] });
    } else {
      toolGroup.setToolDisabled(CrosshairsTool.toolName);
      toolGroup.setToolActive(WindowLevelTool.toolName, {
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Secondary, // Right Click
          },
        ],
      });
    }
  };
  //#endregion

  //#region Tool for volume 3d
  public addToolToVolume3D(viewportId: string, volumeId: string) {
    let toolGroup = ToolGroupManager.getToolGroup(viewportId);
    if (toolGroup === undefined) {
      // Define a tool group, which defines how mouse events map to tool commands for
      // Any viewport using the group
      toolGroup = ToolGroupManager.createToolGroup(viewportId);
    }

    // Add the tools to the tool group and specify which volume they are pointing at
    toolGroup?.addTool(TrackballRotateTool.toolName, {
      configuration: { volumeId },
    });

    // Set the initial state of the tools, here we set one tool active on left click.
    // This means left click will draw that tool.
    toolGroup?.setToolActive(TrackballRotateTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Primary, // Left Click
        },
      ],
    });

    toolGroup?.addViewport(viewportId, RENDER_ENGINE_ID);
    // Add OrientationMarkerTool
    toolGroup?.addTool(OrientationMarkerTool.toolName);
    toolGroup?.setToolConfiguration(OrientationMarkerTool.toolName, {
      overlayMarkerType: OrientationMarkerTool.OVERLAY_MARKER_TYPES.ANNOTATED_CUBE,
    });
    toolGroup?.setToolActive(OrientationMarkerTool.toolName);

    //add zoom tool
    toolGroup?.addTool(ZoomTool.toolName);
    toolGroup?.setToolActive(ZoomTool.toolName, {
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary, // right click
        },
      ],
    });
  }
  //#endregion

  //#region Annotation & Measure

  /**
   * Removes the selected annotation measure.
   */
  public removeAnnotationMeasure = (): void => {
    const annotationUIDs = selection.getAnnotationsSelected();

    if (annotationUIDs && annotationUIDs.length) {
      const annotationUID = annotationUIDs[0];

      visibility.setAnnotationVisibility(annotationUID, false);
    }
  };

  /**
   * Removes an annotation measure by its unique identifier.
   * @param annotationUID - The unique identifier of the annotation measure to be removed.
   */
  public removeAnnotationMeasureById = (annotationUID: string): void => {
    visibility.setAnnotationVisibility(annotationUID, false);
  };

  //#endregion
}
