import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { IAppSetting } from '../models/ISetting';
import { LoginMode } from '@app/auth';

export const settingActions = createActionGroup({
  source: 'Setting',
  events: {
    getAll: emptyProps(),
    getSuccess: props<{ payload?: IAppSetting }>(),
    getFailure: props<{ error: any }>(),
    set: props<{ payload: IAppSetting }>(),
    setSuccess: props<{ payload: IAppSetting }>(),
    settFailure: props<{ error: any }>(),
    updateSetting: props<{ name: string; payload: any }>(),
    updateSettingSuccess: props<{ payload: IAppSetting }>(),
    updateSettingtFailure: props<{ error: any }>(),
  },
});
