import { Component, Renderer2, ElementRef, OnInit, AfterContentInit } from '@angular/core';
import { SharedService } from '@app/@shared';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnInit, AfterContentInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  constructor(
    private render: Renderer2,
    private el: ElementRef,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    //this.onInitSidebar();
    window.onscroll = this.onScrollBody;
    window.onresize = this.onResizeBody;
  }
  ngAfterContentInit() {
    this.sharedService.preloader(false);
  }

  private onInitSidebar = () => {
    let _this = this;
    //SIDEBAR-OPEN
    let navbar = this.el.nativeElement.querySelector('#navbarSupportedContent');
    let body = this.el.nativeElement.querySelector('#landing-page-body');
    navbar.on('hidden.bs.collapse', () => {
      _this.render.removeClass(body, 'sidebar-open');
    });

    navbar.on('shown.bs.collapse', () => {
      _this.render.addClass(body, 'sidebar-open');
    });
  };
  private onResizeBody = () => {
    var w = window.innerWidth;
    let body = this.el.nativeElement.querySelector('#landing-page-body');
    let navbar = this.el.nativeElement.querySelector('#navbarSupportedContent');
    if (w >= 992) {
      this.render.removeClass(navbar, 'show');
      this.render.removeClass(body, 'sidebar-open');
    }
  };

  private onScrollBody = () => {
    let body = this.el.nativeElement.querySelector('#landing-page-body');
    let navbar = this.el.nativeElement.querySelector('.navbar');
    if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
      this.render.addClass(navbar, 'fixed-top');
      this.render.addClass(body, 'header-small');
    } else {
      this.render.removeClass(navbar, 'fixed-top');
      this.render.removeClass(body, 'header-small');
    }
  };

  public onActivate(e) {
    //scrollContainer.scrollTop = 0;
    window.scrollTo(0, 0);
  }
}
