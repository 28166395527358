import { Synchronizer, SynchronizerManager } from '@cornerstonejs/tools';
import { Enums } from '@cornerstonejs/core';
import imageIndexSyncCallback from '../callbacks/imageIndexSyncCallback';

const { VOLUME_NEW_IMAGE, STACK_NEW_IMAGE } = Enums.Events;

/**
 * A helper that creates a new `Synchronizer` which listens to the `STACK_NEW_IMAGE`
 * rendering event and calls the `ImageSliceSyncCallback`.
 *
 * @param synchronizerName - The name of the synchronizer.
 * @returns A new `Synchronizer` instance.
 */
const createImageIndexSynchronizer = (synchronizerName: string): Synchronizer => {
  const stackImageIndexSynchronizer = SynchronizerManager.createSynchronizer(synchronizerName, STACK_NEW_IMAGE, imageIndexSyncCallback, {
    auxiliaryEventNames: [VOLUME_NEW_IMAGE],
  });

  return stackImageIndexSynchronizer;
};
export default createImageIndexSynchronizer;
