import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { I18nModule } from '@app/i18n';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { RxFor } from '@rx-angular/template/for';
import { GalleriaModule } from 'primeng/galleria';

import { RxRenderStrategiesConfig, RX_RENDER_STRATEGIES_CONFIG } from '@rx-angular/cdk/render-strategies';
import { ReportRoutingModule } from './report-routing.module';

import { ReportComponent } from './report.component';
import { ReportPatientComponent } from './pages/patient/patient.component';
import { ReportStudyComponent } from './pages/study/study.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reportFeature, ReportEffects } from './store';
import { PreloaderService, ReportService, ReportPdfService } from './services';
import { ClinicInfoComponent } from './pages/clinic-info/clinic-info.component';
import { ContentComponent } from './pages/content/content.component';
import { DiagnosisContentComponent } from './pages/content/diagnosis-content/diagnosis-content.component';
import { RxLet } from '@rx-angular/template/let';
import { RxIf } from '@rx-angular/template/if';
import { ShellModule } from '@app/shell/shell.module';

const FEATURE_RX_ANGULAR_CONFIG: RxRenderStrategiesConfig<string> = {
  primaryStrategy: 'immediate',
  patchZone: false,
};

@NgModule({
  declarations: [ReportComponent, ReportPatientComponent, ReportStudyComponent, ClinicInfoComponent, ContentComponent, DiagnosisContentComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    RouterModule,
    SharedModule,
    TableModule,
    ContextMenuModule,
    ReportRoutingModule,
    InputTextModule,
    FormsModule,
    CalendarModule,
    ButtonModule,
    ShellModule,
    RxFor,
    RxLet,
    RxIf,
    GalleriaModule,
    StoreModule.forFeature(reportFeature),
    EffectsModule.forFeature(ReportEffects),
  ],
  providers: [
    {
      provide: RX_RENDER_STRATEGIES_CONFIG,
      useValue: FEATURE_RX_ANGULAR_CONFIG,
    },
    PreloaderService,
    ReportService,
    ReportPdfService,
  ],
})
export class ReportModule {}
