import { Enums, Types } from '@cornerstonejs/core';
const { ViewportType } = Enums;
export const DEFAULT_PANEL_ID = 0;
export const DEFAULT_STACK_ID = 0;
export const DEFAULT_TILE_ID = 0;
export const DEFAULT_PANEL_INDEX = '0';
export const DEFAULT_STACK_INDEX = '0-0';
export const DEFAULT_TILE_INDEX = '0-0-0';
export const INDEX_SEPERATOR = '-';
export const DEFAULT_VOLUME_HAS_2D_PANEL_INDEX = '1';
export const DEFAULT_VOLUME_HAS_2D_STACK_INDEX = '1-0';

export const DEFAULT_VOLUME_PANEL_INDEX = '0';
export const DEFAULT_VOLUME_STACK_INDEX = '0-0';
export const PANEL_INDEX_AT = 0;
export const STACK_INDEX_AT = 2;
export const TILE_INDEX_AT = 4;
export const ORTHOGRAPHIC_STACK_SEPERATOR = '-';
export const ORTHOGRAPHIC_STACK_PATTERN = (ctSeriesUid: string, ptSeriesUid: string) => `${ctSeriesUid}${ORTHOGRAPHIC_STACK_SEPERATOR}${ptSeriesUid}`;
export const SERIES_UID_VOLUME_SEPERATOR = ':';
export const SERIES_UID_VOLUME_PATTERN = (seriesUid: string, modality: string) => `${modality}${SERIES_UID_VOLUME_SEPERATOR}${seriesUid}`;
export const MODALITY_INDEX = 0;
export const CT_INDEX = 0;
export const PT_INDEX = 1;
export const ORIENTATION = {
  AXIAL: 'axial',
  SAGITTAL: 'sagittal',
  CORONAL: 'coronal',
};
export const ORIENTATION_LIST = ['axial', 'coronal', 'sagittal'];
export const FUSION_TOTAL_SERIES = 2;
export const VOLUME_MINUMUM_IMAGES = 3;
export const MPR_VIEWPORT_ID = {
  AXIAL: 'mpr-axial',
  SAGITTAL: 'mpr-sagittal',
  CORONAL: 'mpr-coronal',
  VOLUME3D: 'volume-3d',
};

export const MPR_VIEWPORT_CONFIG = {
  '3V0H': [
    {
      viewportId: MPR_VIEWPORT_ID.AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
  ],
  '3H1V': [
    {
      viewportId: MPR_VIEWPORT_ID.AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      element: undefined,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.VOLUME3D,
      type: ViewportType.VOLUME_3D,
      defaultOptions: {
        defaultOptions: {
          orientation: Enums.OrientationAxis.CORONAL,
        },
        background: <Types.Point3>[0.2, 0, 0.2],
      },
    },
  ],
  '1D0D': [
    {
      viewportId: MPR_VIEWPORT_ID.VOLUME3D,
      type: ViewportType.VOLUME_3D,
    },
  ],
  '2H1V': [
    {
      viewportId: MPR_VIEWPORT_ID.SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
  ],
  '3H1D': [
    {
      viewportId: MPR_VIEWPORT_ID.AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      viewportId: MPR_VIEWPORT_ID.VOLUME3D,
      type: ViewportType.VOLUME_3D,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
  ],
};

export const FUSION_CT_ID = 'ct-';
export const FUSION_PT_ID = 'pet-';
export const FUSION_ID = 'fusion-';
export const FUSION_MIP_ID = 'mip-';
export const FUSION_MODALITY_INDEX = 0;
export const FUSION_ORIENTATION_INDEX = 1;
export const FUSION_INDEX_SEPERATOR = '-';

export const FUSION_VIEWPORT_ID = {
  CT_AXIAL: 'ct-axial',
  CT_CORONAL: 'ct-coronal',
  CT_SAGOTTAL: 'ct-sagittal',
  PET_AXIAL: 'pet-axial',
  PET_CORONAL: 'pet-coronal',
  PET_SAGOTTAL: 'pet-sagittal',
  FUSION_AXIAL: 'fusion-axial',
  FUSION_CORONAL: 'fusion-coronal',
  FUSION_SAGITTAL: 'fusion-sagittal',
  MIP_CORONAL: 'mip-coronal',
};

export const FUSION_VIEWPORT_CONFIG = {
  '3H3V1V': [
    {
      //CT AXIAL
      viewportId: FUSION_VIEWPORT_ID.CT_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //CT SAGITTAL
      viewportId: FUSION_VIEWPORT_ID.CT_SAGOTTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
    {
      //CT CORONAL
      viewportId: FUSION_VIEWPORT_ID.CT_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      //PT AXIAL
      viewportId: FUSION_VIEWPORT_ID.PET_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //PT SAGITTAL
      viewportId: FUSION_VIEWPORT_ID.PET_SAGOTTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //PT CORONAL
      viewportId: FUSION_VIEWPORT_ID.PET_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //FUSION AXIAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },

    {
      //FUSION SAGITTAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
    {
      //FUSION CORONAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      //MIP CORONAL
      viewportId: FUSION_VIEWPORT_ID.MIP_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
  ],
  '3V0H': [
    {
      //MIP CORONAL
      viewportId: FUSION_VIEWPORT_ID.MIP_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //CT AXIAL
      viewportId: FUSION_VIEWPORT_ID.CT_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //FUSION AXIAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
  ],
  '2V2H': [
    {
      //MIP CORONAL
      viewportId: FUSION_VIEWPORT_ID.MIP_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //FUSION AXIAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //FUSION CORONAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      //FUSION SAGITTAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
  ],
  '2H3V': [
    {
      //CT AXIAL
      viewportId: FUSION_VIEWPORT_ID.CT_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //FUSION CORONAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      element: undefined,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
    {
      //PET CORONAL
      viewportId: FUSION_VIEWPORT_ID.PET_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //FUSION AXIAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //FUSION SAGITTAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_SAGITTAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
      },
    },
    {
      //MIP CORONAL
      viewportId: FUSION_VIEWPORT_ID.MIP_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
  ],
  '2H2V': [
    {
      //CT AXIAL
      viewportId: FUSION_VIEWPORT_ID.CT_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //PET AXIAL
      viewportId: FUSION_VIEWPORT_ID.PET_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //FUSION AXIAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //MIP CORONAL
      viewportId: FUSION_VIEWPORT_ID.MIP_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
  ],
  '4V0H': [
    {
      //CT AXIAL
      viewportId: FUSION_VIEWPORT_ID.CT_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //FUSION AXIAL
      viewportId: FUSION_VIEWPORT_ID.FUSION_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
      },
    },
    {
      //PET AXIAL
      viewportId: FUSION_VIEWPORT_ID.PET_AXIAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
    {
      //MIP CORONAL
      viewportId: FUSION_VIEWPORT_ID.MIP_CORONAL,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: <Types.Point3>[1, 1, 1],
      },
    },
  ],
};

export const MPR_VIEWPORT_COLORS = {
  [MPR_VIEWPORT_ID.AXIAL]: 'rgb(200, 0, 0)',
  [MPR_VIEWPORT_ID.SAGITTAL]: 'rgb(200, 200, 0)',
  [MPR_VIEWPORT_ID.CORONAL]: 'rgb(0, 200, 0)',
  [FUSION_VIEWPORT_ID.CT_AXIAL]: 'rgb(200, 0, 0)',
  [FUSION_VIEWPORT_ID.CT_SAGOTTAL]: 'rgb(200, 200, 0)',
  [FUSION_VIEWPORT_ID.CT_CORONAL]: 'rgb(0, 200, 0)',
  [FUSION_VIEWPORT_ID.PET_AXIAL]: 'rgb(200, 0, 0)',
  [FUSION_VIEWPORT_ID.PET_SAGOTTAL]: 'rgb(200, 200, 0)',
  [FUSION_VIEWPORT_ID.PET_CORONAL]: 'rgb(0, 200, 0)',
  [FUSION_VIEWPORT_ID.FUSION_AXIAL]: 'rgb(200, 0, 0)',
  [FUSION_VIEWPORT_ID.FUSION_SAGITTAL]: 'rgb(200, 200, 0)',
  [FUSION_VIEWPORT_ID.FUSION_CORONAL]: 'rgb(0, 200, 0)',
};

export const MPR_REFERENCELINE_LINE_CONTROLLABLE = [
  MPR_VIEWPORT_ID.AXIAL,
  MPR_VIEWPORT_ID.SAGITTAL,
  MPR_VIEWPORT_ID.CORONAL,
  FUSION_VIEWPORT_ID.CT_AXIAL,
  FUSION_VIEWPORT_ID.CT_SAGOTTAL,
  FUSION_VIEWPORT_ID.CT_CORONAL,
  FUSION_VIEWPORT_ID.FUSION_AXIAL,
  FUSION_VIEWPORT_ID.FUSION_CORONAL,
  FUSION_VIEWPORT_ID.FUSION_SAGITTAL,
  FUSION_VIEWPORT_ID.PET_AXIAL,
  FUSION_VIEWPORT_ID.PET_CORONAL,
  FUSION_VIEWPORT_ID.PET_SAGOTTAL,
];

export const MPR_REFERENCELINE_LINE_DRAGGABLE_ROTATABLE = [
  MPR_VIEWPORT_ID.AXIAL,
  MPR_VIEWPORT_ID.SAGITTAL,
  MPR_VIEWPORT_ID.CORONAL,
  FUSION_VIEWPORT_ID.CT_AXIAL,
  FUSION_VIEWPORT_ID.CT_SAGOTTAL,
  FUSION_VIEWPORT_ID.CT_CORONAL,
  FUSION_VIEWPORT_ID.FUSION_AXIAL,
  FUSION_VIEWPORT_ID.FUSION_CORONAL,
  FUSION_VIEWPORT_ID.FUSION_SAGITTAL,
  FUSION_VIEWPORT_ID.PET_AXIAL,
  FUSION_VIEWPORT_ID.PET_CORONAL,
  FUSION_VIEWPORT_ID.PET_SAGOTTAL,
];

export const MPR_REFERENCELINE_LINE_SLABTHICKNESS_CONTROLSON = [
  MPR_VIEWPORT_ID.AXIAL,
  MPR_VIEWPORT_ID.SAGITTAL,
  MPR_VIEWPORT_ID.CORONAL,
  FUSION_VIEWPORT_ID.CT_AXIAL,
  FUSION_VIEWPORT_ID.CT_SAGOTTAL,
  FUSION_VIEWPORT_ID.CT_CORONAL,
  FUSION_VIEWPORT_ID.FUSION_AXIAL,
  FUSION_VIEWPORT_ID.FUSION_CORONAL,
  FUSION_VIEWPORT_ID.FUSION_SAGITTAL,
  FUSION_VIEWPORT_ID.PET_AXIAL,
  FUSION_VIEWPORT_ID.PET_CORONAL,
  FUSION_VIEWPORT_ID.PET_SAGOTTAL,
];

export const DEFAULT_3D_PRESET = 'CT-Bone';

export const MOUSE_MOVE_DISTANCE = 10;
