import { Injectable } from '@angular/core';
import { CornerstoneMetadata, DicomLoaderService, DicomTag, IImage, IImagePrefetch, ImagePrefetch, MODALITY_NAME, Priority, Thumbnail } from '@app/@core';
import { GoogleDriveService, Logger } from '@app/@shared';
import { GoogleParams, InputLoad } from '../models/IInputLoad';
import { IStructureData } from '../models/IStructureData';
import { metaData } from '@cornerstonejs/core';
import { IDicomDir } from '@app/upload/models/IDicomDir';
import { StructureData } from '../models/structure-data';
import { StudyViewer } from '../models/study-viewer';
import { Provider } from '../contants/load-image';
import { SeriesViewer } from '../models/series-viewer';
import { IStoreViewer } from '../models/IStoreViewer';
const log = new Logger('[Viewer]PreloadService');

@Injectable({ providedIn: 'root' })
/**
 * Service responsible for preloading data based on the provider and payload.
 */
export class PreloadService {
  constructor(
    private dicomLoaderService: DicomLoaderService,
    private gDriveService: GoogleDriveService,
  ) {}

  //#region LOCAL DICOM FILE
  /**
   * Loads DICOM files from the local file system and organizes them into a structure data object.
   * @param file - An array of File objects representing the DICOM files to be loaded.
   * @returns A Promise that resolves to an IStructureData object containing the loaded DICOM data, or undefined if an error occurs.
   */
  public async loadFromLocal(file: File[]): Promise<IStoreViewer | undefined> {
    try {
      const { imageIds } = await this.dicomLoaderService.loadFromLocal(file);
      const thumbnail = new Thumbnail();
      //await thumbnail.init();
      const structureData = new StructureData();
      for (let index = 0, length = imageIds.length; index < length; index++) {
        //Init reference object store to Ngrx for display image
        const imageId = imageIds[index];
        let imagePrefetch = new ImagePrefetch(imageId);
        const tag = new DicomTag(imageId);
        imagePrefetch.setTag(tag.toObject());
        imagePrefetch.checkMultiFrame();
        const thumbnailID = imagePrefetch.isMultiFrame ? imagePrefetch.frameId[0] : imageId;
        const modality = imagePrefetch.tag.general.modality;

        //get metadata for image for structure study and series for inputed data
        const studyInstanceUID = tag.general.studyInstanceUID || '';
        let seriesInstanceUID = tag.general.seriesInstanceUID || '';
        if (imagePrefetch.isMultiFrame || modality === MODALITY_NAME.MG) {
          seriesInstanceUID = tag.general.seriesInstanceUID + index;
        }
        // Case 1: Add new study object into result if doesn't exist on result. Initialize all upload Data(Study, Series, dcm File)
        const study = structureData.findStudy(studyInstanceUID);
        if (study === undefined) {
          const newStudy = new StudyViewer(studyInstanceUID);
          newStudy.provider = Provider.Local;
          newStudy.setPatient(tag.patientInfo);
          const seriesMetadata = metaData.get(CornerstoneMetadata.GENERAL_SERIES, imageId);
          const newSeries = new SeriesViewer(seriesInstanceUID, seriesMetadata, imagePrefetch.tag.general.modality);
          //Generate thumbnail for multi frame image
          newSeries.addThumbnail(await thumbnail.generate(thumbnailID));
          newSeries.addImagePrefetch(imagePrefetch);
          newStudy.addNewSeries(newSeries);
          structureData.addStudy(newStudy);
        } else {
          // Case 2: Add new Series on existed study on upload data.
          const series = study.findSeries(seriesInstanceUID);
          if (series === undefined) {
            const seriesMetadata = metaData.get(CornerstoneMetadata.GENERAL_SERIES, imageId);
            const newSeries = new SeriesViewer(seriesInstanceUID, seriesMetadata, imagePrefetch.tag.general.modality);
            //Generate thumbnail for multi frame image
            newSeries.addThumbnail(await thumbnail.generate(thumbnailID));
            newSeries.addImagePrefetch(imagePrefetch);
            study.addNewSeries(newSeries);
          } else {
            // Case 3: exist all study, series. only add dcm file for upload
            series.addImagePrefetch(imagePrefetch);
          }
        }
      }
      structureData.preprocessorFile();
      return structureData.toStore(Provider.Local);
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  //#endregion

  //#region GOOGLE DICOM DIR
  /**
   * Loads DICOM files from Google Drive and organizes them into a structure data object.
   * @param driveIds - An object containing the Google Drive file IDs for the main study and reference study (optional).
   * @returns A Promise that resolves to an IStructureData object containing the loaded DICOM data, or undefined if an error occurs.
   */
  public async loadFromGoogle(driveIds: GoogleParams, isLogin: boolean = true): Promise<IStoreViewer | undefined> {
    try {
      const structureData = new StructureData();
      //add data for main study
      const mainRes = <IDicomDir>await this.gDriveService.getFileContent(driveIds.main);
      if (mainRes === undefined) {
        return undefined;
      }
      const mainStudy = new StudyViewer(mainRes.studyUid);
      mainStudy.priority = Priority.High;
      mainStudy.provider = Provider.Google;
      mainStudy.fromDicomDir(mainRes, isLogin);
      structureData.addStudy(mainStudy);
      //add data for reference study
      if (driveIds.ref) {
        const refRes = <IDicomDir>await this.gDriveService.getFileContent(driveIds.ref);
        if (mainRes === undefined) {
          return undefined;
        }
        const refStudy = new StudyViewer(refRes.studyUid);
        refStudy.priority = Priority.Low;
        refStudy.provider = Provider.Google;
        refStudy.fromDicomDir(refRes, isLogin);
        structureData.addStudy(refStudy);
      }
      return structureData.toStore(Provider.Google);
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Prefetches an image from the specified URL.
   * @param url - The URL of the image to prefetch.
   * @returns A promise that resolves to an instance of IImagePrefetch if successful, otherwise undefined.
   * @see IImagePrefetch
   */
  public async prefetchImage(url: string): Promise<IImagePrefetch | undefined> {
    try {
      const image = await this.dicomLoaderService.loadSingleFromDrivePromise(url);
      const imagePrefetch = new ImagePrefetch(image.imageId);
      const tag = new DicomTag(image.imageId);
      imagePrefetch.init(tag);
      imagePrefetch.checkMultiFrame();
      return imagePrefetch;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  /**
   * Preloads and prefetches images from the specified download URLs.
   * @param downloadUrls - An array of URLs to download the images from.
   * @returns A promise that resolves to an array of preloaded and prefetched images, or undefined if there was an error.
   */
  public async prefetchImages(downloadUrls: string[]): Promise<IImagePrefetch[] | undefined> {
    try {
      const imagePromise: Promise<any>[] = [];
      // Save image to store
      if (downloadUrls.length > 0) {
        for (let index = 0, length = downloadUrls.length; index < length; index++) {
          let url = downloadUrls[index];
          let promise = this.dicomLoaderService.loadSingleFromDrivePromise(url);
          imagePromise.push(promise);
        }
        const images: IImage[] = await Promise.all(imagePromise);
        const imagePrefetchs: IImagePrefetch[] = new Array();
        images.map((image) => {
          const imagePrefetchItem = new ImagePrefetch(image.imageId);
          const tag = new DicomTag(image.imageId);
          imagePrefetchItem.init(tag);
          imagePrefetchItem.checkMultiFrame();
          imagePrefetchs.push(imagePrefetchItem);
        });
        return imagePrefetchs;
      } else {
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }
  //#endregion
}
