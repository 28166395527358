import { Injectable } from '@angular/core';
import { Synchronizer, SynchronizerManager, synchronizers } from '@cornerstonejs/tools';
import { createImageIndexSynchronizer, createImageLUTSynchronizer, createImagePanSynchronizer, createImageRotateSynchronizer, createImageZoomSynchronizer } from '../helpers/synchronizers';
import { RENDER_ENGINE_ID } from '@app/@core/dicom/contants/cornerstone';
import { Logger } from '@app/@shared';
import { DEFAULT_TILE_ID, DEFAULT_TILE_LAYOUT, INDEX_SEPERATOR, LAYOUT_SEPARATOR, ORIENTATION_LIST, ScrollSync, SyncType } from '../contants';
import { Store } from '@ngrx/store';
import { Subject, firstValueFrom } from 'rxjs';
import { ViewerMenuQuery } from '../store/menu';
import { enumValueToArray } from '../helpers';
import { ITileSyncData } from '../models';

const { createCameraPositionSynchronizer } = synchronizers;
const log = new Logger('SynchronizerService');

@Injectable()
export class SynchronizerService {
  constructor(private store: Store) {
    this._tileSynchronize = new Subject<ITileSyncData>();
  }

  /**
   * Subject used for tile synchronization.
   */
  private _tileSynchronize: Subject<ITileSyncData>;

  /**
   * Gets the observable for tile synchronization.
   * @returns An observable that emits tile synchronization events.
   */
  public get tileSynchronize() {
    return this._tileSynchronize.asObservable();
  }

  /**
   * Handles tile synchronization.
   * @param syncData - The data for tile synchronization.
   */
  public onTileSynchronize = async (syncData: ITileSyncData) => {
    this._tileSynchronize.next(syncData);
  };

  /**
   * Creates a volume camera position synchronizer.
   * @param orientation - The orientation of the synchronizer.
   * @param parentIndex - The parent index of the synchronizer.
   * @param viewportIds - The array of viewport IDs to synchronize.
   */
  public createVolumeCameraPositionSynchronizer = (orientation: string, parentIndex: string, viewportIds: string[]) => {
    if (viewportIds.length > 1) {
      const cameraPositionSynchronizer = createCameraPositionSynchronizer(`${orientation}-${parentIndex}`);
      viewportIds.forEach((index) => cameraPositionSynchronizer.add({ renderingEngineId: RENDER_ENGINE_ID, viewportId: index }));
    }
  };

  /**
   * Removes the volume camera position synchronizer for the specified parent index.
   * @param parentIndex - The parent index of the synchronizer to be removed.
   */
  public removeVolumeCameraPositionSynchronizer = (parentIndex: string) => {
    ORIENTATION_LIST.forEach((orientation) => {
      if (SynchronizerManager.getSynchronizer(`${orientation}-${parentIndex}`)) {
        SynchronizerManager.destroySynchronizer(`${orientation}-${parentIndex}`);
      }
    });
  };
}
