import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Logger } from '@shared';
import { firstValueFrom } from 'rxjs';
import { authQuery } from './store/auth.selectors';
import { CredentialService } from './credential.service';
const LOGIN_MODE = 'loginMode';
const log = new Logger('AuthenticationGuard');
/**
 * Checks if the user is authenticated and has access to the requested route.
 * If the route requires login, it redirects the user to the login page and adds the original target URL as a query parameter named redirect.
 * After successful login, the user can be redirected back to the original target URL.
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns A promise that resolves to true if the user is authenticated and has access to the route, or false otherwise.
 */
export const authGuard = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const credentialService = inject(CredentialService);
  //The first part of the code checks if the route requires login by accessing the requireLogin property from the route's data.
  //If requireLogin is explicitly set to false, the guard immediately allows access to the route by returning true.

  const currentRouteOptions = route.data.routes.filter((r) => {
    const baseURI = state.url.split('?')[0];
    return baseURI === '/' + r.path;
  });
  if (currentRouteOptions.length > 0) {
    const requireLogin = currentRouteOptions[0].data.requireLogin;
    if (requireLogin !== undefined && requireLogin === false) {
      return true;
    }
  }

  //check state on credential service
  if (credentialService.isLoggin) {
    return true;
  }

  //If none of the above conditions are met, the guard assumes that the user is not authenticated.
  //It logs a debug message, redirects the user to the login page, and adds the original target URL as a query parameter named redirect.
  //This way, after successful login, the user can be redirected back to the original target URL. The guard denies access to the route by returning false.
  log.debug('Not authenticated, redirecting and adding redirect url...');
  router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
  return false;
};
