import { Injectable } from '@angular/core';
import { RouteHelpers, Severity, SharedService } from '@app/@shared';
import { PreloadService } from '@app/viewer/services/preload.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { firstValueFrom, mergeMap } from 'rxjs';
import { viewerGeneralActions, viewerDriveActions, viewerFileActions, studyListAction } from './general.actions';
import { DownloadQueueService } from '@app/viewer/services/download-queue.service';
import { ViewerLayoutQuery, layoutActions } from '../layout';
import { LayoutService } from '@app/viewer/services/layout.service';
import { ISeriesToStack } from '@app/viewer/models/IViewport';
import { CornerstoneService, isMultiFrame, MODALITY_NAME, OPEN_SHARE_VIEWER_PATTERN, Priority } from '@app/@core';
import { TranslateService } from '@ngx-translate/core';
import { Enums } from '@cornerstonejs/core';
import { settingQuery } from '@app/setting';
import { DEFAULT_SETTING } from '@app/viewer/contants/default-setting';
import { ViewerGeneralQuery } from './general.selectors';
import { DEFAULT_PANEL_LAYOUT, DEFAULT_TILE_LAYOUT, LAYOUT_SEPARATOR, PANEL_LAYOUT_INDEX, PANEL_VOLUME_LAYOUT_INDEX, VIEWPORT_INDEX_SEPERATOR } from '@app/viewer/contants/layout';
import { clone, cloneDeep } from 'lodash';
import { volumeActions } from '../cornerstone';
import { ViewerMode } from '@app/viewer/contants/mode';
import { IMenuFusion, IMenuMPR } from '@app/viewer/models/IMenu';
import { ViewerMenuQuery } from '../menu/menu.selectors';
import {
  CT_INDEX,
  DEFAULT_PANEL_ID,
  DEFAULT_PANEL_INDEX,
  DEFAULT_STACK_ID,
  DEFAULT_STACK_INDEX,
  DEFAULT_TILE_ID,
  ORTHOGRAPHIC_STACK_PATTERN,
  PT_INDEX,
  SERIES_UID_VOLUME_PATTERN,
  STACK_INDEX_AT,
} from '@app/viewer/contants/viewport';
import { GoogleParams, IBackupLayout, IPanelLayout, ISeriesInfo, IStackLayout, ITileLayout } from '@app/viewer/models';
import { BroadcastService, WorklistService } from '@app/viewer/services';
import { authQuery, CredentialService } from '@app/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable()
/**
 * Effects class for the viewer feature.
 * Handles the side effects related to loading DICOM files and interacting with the viewer.
 */
export class ViewerGeneralEffects {
  constructor(
    private actions$: Actions,
    private preloadService: PreloadService,
    private sharedService: SharedService,
    private downloadQueueService: DownloadQueueService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private store: Store,
    private cornerstoneService: CornerstoneService,
    private broadcastService: BroadcastService,
    private credentialService: CredentialService,
    private worklistService: WorklistService,
    private route: Router,
    private clipboard: Clipboard,
  ) {}

  //#region Files from local

  /**
   * An effect that loads DICOM files.
   * @returns An observable that emits actions.
   */
  loadDicomFiles$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerFileActions.load),
        mergeMap(async (action) => {
          try {
            const data = await this.preloadService.loadFromLocal(action.files);
            if (data) {
              return viewerFileActions.loadSuccess({ payload: data });
            } else {
              return viewerFileActions.loadFail({ error: 'Cant not load file' });
            }
          } catch (error) {
            return viewerFileActions.loadFail({ error: error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action when loading a DICOM file fails.
   * It displays an error message and disables the preloader.
   */
  loadDicomFileFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerFileActions.loadFail),
        mergeMap(async (action) => {
          this.sharedService.preloader(false);
          this.sharedService.toastMessage(Severity.error, this.translateService.instant('LoadDicomFileFail'));
        }),
      );
    },
    { functional: true, dispatch: false },
  );
  //#endregion
  //#region Google Drive

  /**
   * Effect for loading DICOM directory.
   * This effect listens for the 'load' action and loads DICOM data from Google Drive.
   * If the data is successfully loaded, it dispatches the 'loadSuccess' action with the loaded study list.
   * If there is an error during loading, it dispatches the 'loadFail' action with the error message.
   */
  loadDicomDir$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerDriveActions.load),
        mergeMap(async (action) => {
          try {
            const data = await this.preloadService.loadFromGoogle(action.driveIds);
            if (!data) {
              return viewerDriveActions.loadFail({ error: 'Cant not load file' });
            }
            //prefetch 1st dicom data of main series to display into viewport
            const mainStudy = data.stackImages.filter((stack) => stack.priority === Priority.High);
            //get list 1st image of each series in main study
            const urls: string[] = new Array();
            mainStudy.flatMap((stack) => {
              urls.push(stack.imageDriveId?.shift() || '');
            });
            // Download all 1st image
            const imagePrefetchs = await this.downloadQueueService.downloadByUid(urls);
            //mapping image into stack by series uid
            imagePrefetchs?.map((image) => {
              const modality = image.tag.general.modality;
              if (image.isMultiFrame) {
                const stack = mainStudy.pop();
                if (image.frameId && image && stack) {
                  image.frameId.forEach((frameId) => {
                    const newImage = { ...image };
                    newImage.imageId = frameId;
                    stack.imagePrefetch.push(newImage);
                  });
                }
              } else if (modality === MODALITY_NAME.MG) {
                const stack = mainStudy.pop();
                if (image && stack) {
                  stack.imagePrefetch.push(image);
                }
              } else {
                const stack = mainStudy?.find((stack) => stack.uid === image.tag.general.seriesInstanceUID);
                if (stack) {
                  stack.imagePrefetch.push(image);
                }
              }
            });
            // if (mainStudy) {
            //   for (let index = 0; index < mainStudy.length; index++) {
            //     const stack = mainStudy[index];
            //     let url = stack.imageDriveId?.shift();
            //     const image = await this.preloadService.prefetchImage(url || '');
            //     if (image) {
            //       if (image.isMultiFrame && image.frameId) {
            //         image.frameId.forEach((frameId) => {
            //           const newImage = { ...image };
            //           newImage.imageId = frameId;
            //           stack.imagePrefetch.push(newImage);
            //         });
            //       } else {
            //         stack.imagePrefetch.push(image);
            //       }
            //     }
            //   }
            // }
            if (data) {
              return viewerDriveActions.loadSuccess({ payload: data });
            } else {
              return viewerDriveActions.loadFail({ error: 'Cant not load file' });
            }
          } catch (error) {
            return viewerDriveActions.loadFail({ error: error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect to handle the start of share mode.
   *
   * This effect listens for the `startShareMode` action and performs a mergeMap operation.
   * Depending on the login status, it dispatches either `loadShareWithLogin` or `loadShareWithoutLogin` actions.
   * If an error occurs during the process, it dispatches the `loadFail` action with the error.
   *
   * @returns An observable of the dispatched actions.
   */
  loadShare$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.startShareMode),
        mergeMap(async (action) => {
          try {
            if (this.credentialService.isLoggin) {
              return viewerDriveActions.loadShareWithLogin({ driveIds: action.params });
            } else {
              return viewerDriveActions.loadShareWithoutLogin({ driveIds: action.params });
            }
          } catch (error) {
            return viewerDriveActions.loadFail({ error: error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect to load shared DICOM directory with login.
   *
   * This effect listens for the `loadShareWithLogin` action and performs the following steps:
   * 1. Calls the `preloadService.loadFromGoogle` method with the provided drive IDs.
   * 2. If the data is successfully loaded, it filters the main study stack images with high priority.
   * 3. Extracts the first image of each series in the main study and downloads them.
   * 4. Maps the downloaded images into the stack by series UID.
   * 5. Dispatches `loadSuccess` action if the data is successfully processed, otherwise dispatches `loadFail` action.
   *
   * @returns An observable of the dispatched action.
   * @throws Dispatches `loadFail` action with an error message if any error occurs during the process.
   */
  loadShareDicomDir$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerDriveActions.loadShareWithLogin),
        mergeMap(async (action) => {
          try {
            const data = await this.preloadService.loadFromGoogle(action.driveIds);
            if (!data) {
              return viewerDriveActions.loadFail({ error: 'Cant not load file' });
            }
            //prefetch 1st dicom data of main series to display into viewport
            const mainStudy = data.stackImages.filter((stack) => stack.priority === Priority.High);
            //get list 1st image of each series in main study
            const urls: string[] = new Array();
            mainStudy.flatMap((stack) => {
              urls.push(stack.imageDriveId?.shift() || '');
            });
            // Download all 1st image
            const imagePrefetchs = await this.downloadQueueService.downloadByUid(urls);
            //mapping image into stack by series uid
            imagePrefetchs?.map((image) => {
              const modality = image.tag.general.modality;
              if (image.isMultiFrame) {
                const stack = mainStudy.pop();
                if (image.frameId && image && stack) {
                  image.frameId.forEach((frameId) => {
                    const newImage = { ...image };
                    newImage.imageId = frameId;
                    stack.imagePrefetch.push(newImage);
                  });
                }
              } else if (modality === MODALITY_NAME.MG) {
                const stack = mainStudy.pop();
                if (image && stack) {
                  stack.imagePrefetch.push(image);
                }
              } else {
                const stack = mainStudy?.find((stack) => stack.uid === image.tag.general.seriesInstanceUID);
                if (stack) {
                  stack.imagePrefetch.push(image);
                }
              }
            });
            // if (mainStudy) {
            //   for (let index = 0; index < mainStudy.length; index++) {
            //     const stack = mainStudy[index];
            //     let url = stack.imageDriveId?.shift();
            //     const image = await this.preloadService.prefetchImage(url || '');
            //     if (image) {
            //       if (image.isMultiFrame && image.frameId) {
            //         image.frameId.forEach((frameId) => {
            //           const newImage = { ...image };
            //           newImage.imageId = frameId;
            //           stack.imagePrefetch.push(newImage);
            //         });
            //       } else {
            //         stack.imagePrefetch.push(image);
            //       }
            //     }
            //   }
            // }
            if (data) {
              return viewerDriveActions.loadSuccess({ payload: data });
            } else {
              return viewerDriveActions.loadFail({ error: 'Cant not load file' });
            }
          } catch (error) {
            return viewerDriveActions.loadFail({ error: error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect to load shared DICOM directory without requiring login.
   *
   * This effect listens for the `loadShareWithoutLogin` action and attempts to load
   * DICOM files from Google Drive using the provided drive IDs. It prefetches the first
   * DICOM image of the main series to display in the viewport. The effect handles
   * different modalities and multi-frame images, mapping the pre-fetched images into
   * their respective stacks.
   *
   * @effect
   * @returns {Observable} - An observable that emits either a `loadSuccess` action with the loaded data
   *                         or a `loadFail` action with an error message.
   */
  loadShareDicomDirWithoutLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerDriveActions.loadShareWithoutLogin),
        mergeMap(async (action) => {
          try {
            const data = await this.preloadService.loadFromGoogle(action.driveIds, false);
            if (!data) {
              return viewerDriveActions.loadFail({ error: 'Cant not load file' });
            }
            //prefetch 1st dicom data of main series to display into viewport
            const mainStudy = data.stackImages.filter((stack) => stack.priority === Priority.High);
            //get list 1st image of each series in main study
            const urls: string[] = new Array();
            mainStudy.flatMap((stack) => {
              urls.push(stack.imageDriveId?.shift() || '');
            });
            // Download all 1st image
            //test
            // const image = await firstValueFrom(
            //   this.http.get(urls[0], {
            //     responseType: 'blob',
            //     headers: new HttpHeaders({
            //       Accept: 'application/octet-stream',
            //     }),
            //   }),
            // );
            // debugger;
            const imagePrefetchs = await this.downloadQueueService.downloadByUid(urls);
            //mapping image into stack by series uid
            imagePrefetchs?.map((image) => {
              const modality = image.tag.general.modality;
              if (image.isMultiFrame) {
                const stack = mainStudy.pop();
                if (image.frameId && image && stack) {
                  image.frameId.forEach((frameId) => {
                    const newImage = { ...image };
                    newImage.imageId = frameId;
                    stack.imagePrefetch.push(newImage);
                  });
                }
              } else if (modality === MODALITY_NAME.MG) {
                const stack = mainStudy.pop();
                if (image && stack) {
                  stack.imagePrefetch.push(image);
                }
              } else {
                const stack = mainStudy?.find((stack) => stack.uid === image.tag.general.seriesInstanceUID);
                if (stack) {
                  stack.imagePrefetch.push(image);
                }
              }
            });
            // if (mainStudy) {
            //   for (let index = 0; index < mainStudy.length; index++) {
            //     const stack = mainStudy[index];
            //     let url = stack.imageDriveId?.shift();
            //     const image = await this.preloadService.prefetchImage(url || '');
            //     if (image) {
            //       if (image.isMultiFrame && image.frameId) {
            //         image.frameId.forEach((frameId) => {
            //           const newImage = { ...image };
            //           newImage.imageId = frameId;
            //           stack.imagePrefetch.push(newImage);
            //         });
            //       } else {
            //         stack.imagePrefetch.push(image);
            //       }
            //     }
            //   }
            // }
            if (data) {
              return viewerDriveActions.loadSuccess({ payload: data });
            } else {
              return viewerDriveActions.loadFail({ error: 'Cant not load file' });
            }
          } catch (error) {
            return viewerDriveActions.loadFail({ error: error });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action when loading DICOM directory fails.
   * It displays an error message and disables the preloader.
   */
  loadDicomDirFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerDriveActions.loadFail),
        mergeMap(async (action) => {
          this.sharedService.preloader(false);
          this.sharedService.toastMessage(Severity.error, this.translateService.instant('LoadDicomDirFail'));
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect to handle the sharing of the current study.
   *
   * This effect listens for the `shareStudy` action and attempts to share the current study
   * using the `worklistService`. If the study is successfully shared, it copies the shareable
   * URL to the clipboard and displays a success toast message. If the sharing fails, it displays
   * an error toast message.
   *
   * @effect
   * @returns {Observable<void>} An observable that performs the share operation.
   *
   * @throws {Error} If an error occurs during the sharing process.
   */
  shareCurrentStudy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerDriveActions.shareStudy),
        concatLatestFrom(() => this.store.select(authQuery.selectLoginInfo)),
        mergeMap(async ([action, userInfo]) => {
          try {
            // If the viewer is not in share mode, it dispatches a start action.
            const driveRouter = <GoogleParams>RouteHelpers.Instance.collectRouteQueryParams(this.route);
            const isShareStatus = await this.worklistService.shareCurrentStudy(driveRouter.mainUid, driveRouter.main, userInfo.userId || '');
            if (isShareStatus) {
              const url = `${document.baseURI}${OPEN_SHARE_VIEWER_PATTERN(driveRouter.main, driveRouter.mainUid)}&isShare=1`;
              // Use Clipboard service to copy the URL
              this.clipboard.copy(url);
              this.sharedService.toastMessage(Severity.info, this.translateService.instant('CopyLinkSuccess'));
            } else {
              this.sharedService.toastMessage(Severity.error, this.translateService.instant('CanNotShareStudy'));
            }
          } catch (error) {
          } finally {
            this.sharedService.preloader(false);
          }
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  //#endregion

  //#region Viewer effects
  // use the one dispatched action

  /**
   * Effect that is triggered when a successful load action is dispatched for viewerFile or viewerDrive.
   * It changes the selected study in the viewer and disables the preloader.
   */
  initViewerMetadata$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerFileActions.loadSuccess, viewerDriveActions.loadSuccess),
        mergeMap(async (action) => {
          this.sharedService.preloader(false);
          //1st init always load 1st study from input list
          return viewerGeneralActions.changeSelectedStudy({ payload: [action.payload.studyInfo[0]] });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Initializes the default modality layout effect.
   * This effect listens for successful loading of viewer files or drives,
   * retrieves the default layout for the modality, calculates the panel index map,
   * and initializes the viewer data with the layout and display viewport.
   *
   * @returns An observable that emits the action to initialize the viewer data.
   */
  initDefaultModalityLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerFileActions.loadSuccess, viewerDriveActions.loadSuccess),
        mergeMap(async (action) => {
          //get modality of main study, default 1st study mapping with layout ID
          let modality = action.payload.studyInfo[0].modality;
          //get layout setting of selected modality
          const settingLayout = await firstValueFrom(this.store.select(settingQuery.selectLayoutByModality(modality)));
          // If not exist setting, using default setting of all modality
          let modalityLayout = settingLayout.find((setting) => setting.isDefault) || DEFAULT_SETTING.layoutModality.default[0];

          const seriesToStack: ISeriesToStack[] = [];
          // Incase dicom file from google drive dicom dir
          if (action.type === viewerDriveActions.loadSuccess.toString()) {
            //binding series uid to stack index for displaying image
            const highPriorityStack = action.payload.stackImages.filter((stack) => stack.priority === Priority.High);
            for (let index = 0; index < highPriorityStack.length; index++) {
              let stack = action.payload.stackImages[index];
              const studyUid = action.payload.seriesInfos.find((series) => series.uid === stack.uid)?.studyUid;
              seriesToStack.push({
                studyUid: studyUid || '',
                seriesUid: stack.uid,
                stackIndex: `${PANEL_LAYOUT_INDEX}${VIEWPORT_INDEX_SEPERATOR}${index}`,
                type: Enums.ViewportType.STACK,
                mode: ViewerMode.Stack2D,
              });
            }
          } else {
            //In case from local data
            const studyUid = action.payload.studyInfo[0].uid;
            const seriesUids = action.payload.seriesInfos.filter((series) => series.studyUid === studyUid).flatMap((info) => info.uid);
            const stack = action.payload.stackImages.filter((stack) => seriesUids.includes(stack.uid));
            for (let index = 0; index < stack.length; index++) {
              let element = stack[index];
              const studyUid = action.payload.seriesInfos.find((series) => series.uid === element.uid)?.studyUid;
              seriesToStack.push({
                studyUid: studyUid || '',
                seriesUid: element.uid,
                stackIndex: `${PANEL_LAYOUT_INDEX}${VIEWPORT_INDEX_SEPERATOR}${index}`,
                type: Enums.ViewportType.STACK,
                mode: ViewerMode.Stack2D,
              });
            }
          }
          // Call action init viewer( change layout, display image etc)
          return viewerGeneralActions.initViewerData({ layout: modalityLayout, displayViewport: seriesToStack });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Initializes the viewer data effect.
   * This effect listens for the 'initViewerData' action and initializes the viewer layout based on the provided layout configuration.
   * @returns An observable that emits the 'initModalityLayout' action.
   */
  initViewerData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.initViewerData),
        mergeMap(async (action) => {
          //Change layout by modality of display series
          return layoutActions.initModalityLayout({
            panelLayout: action.layout.panelLayout,
            stackLayout: action.layout.stackLayout,
            tileLayout: action.layout.tileLayout,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that is triggered when a successful load action is dispatched for viewerFile or viewerDrive.
   * It changes the selected study in the viewer and disables the preloader.
   */
  initDicomDirData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerDriveActions.loadSuccess),
        mergeMap(async (action) => {
          // Get high priority download series uid
          const series = <ISeriesInfo[]>action.payload.seriesInfos.filter((series) => {
            return series.priority === Priority.High;
          });
          this.downloadQueueService.startDownload(series);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that replaces a series in the stack.
   * This effect listens for `replaceSeriesInStack` actions and performs the necessary operations to replace the series in the stack.
   * It retrieves the high priority download series UID and overrides the tile in the stack with the new series UID.
   *
   * @returns An observable that emits the `overrideTileInStack` action.
   */
  replaceSeriesInStack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.replaceSeriesInStack),
        mergeMap(async (action) => {
          // Get high priority download series uid
          const tileLayout = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectTileLayoutByStackIndex(action.info.stackIndex)));
          return layoutActions.overrideTileInStack({
            tileLayout: cloneDeep(tileLayout),
            stackIndex: action.info.stackIndex,
            seriesUid: action.info.seriesUid,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the change of the full image in the viewer.
   * It listens for the `activeFullImage` action and performs the necessary operations based on the current state.
   * If the full image is active, it backs up the current display viewport and sets the new display viewport.
   * If the full image is not active, it restores the backup display viewport.
   *
   * @returns An observable that emits actions based on the state changes.
   */
  onChangeFullImage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.activeFullImage),
        concatLatestFrom(() => this.store.select(ViewerGeneralQuery.selectIsFullImage)),
        mergeMap(async ([action, isFullImage]) => {
          if (isFullImage) {
            const currentDisplayViewport = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewport));
            return viewerGeneralActions.backupDisplayViewportFullImage({
              viewports: currentDisplayViewport,
              tileLayout: action.tileLayout,
              currentViewport: action.displayViewport,
            });
          } else {
            const backupDisplayViewport = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewportBackup));
            return viewerGeneralActions.restoreDisplayViewportFullImage({ viewports: backupDisplayViewport });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that triggers when the backupDisplayViewportFullImage action is dispatched.
   * It backs up the layout and full image of the display viewport.
   */
  onBackupDisplayViewportFullImage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.backupDisplayViewportFullImage),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectLayout)),
        mergeMap(async ([action, layout]) => {
          return layoutActions.backupLayoutFullImage({
            layout,
            tileLayout: action.tileLayout,
            displayviewport: action.currentViewport,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that triggers when the display viewport needs to be restored to full image.
   * It listens to the `restoreDisplayViewportFullImage` action and restores the layout
   * using the backup layout obtained from the `ViewerLayoutQuery.selectBackupLayout` selector.
   */
  onRestoreDisplayViewportFullImage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.restoreDisplayViewportFullImage),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectBackupLayout)),
        mergeMap(async ([action, backupLayout]) => {
          return layoutActions.restoreLayout({ layout: backupLayout as IBackupLayout });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that handles the action to display a full image.
   * Calculates the panel, stack, and tile layouts based on the action payload.
   * Dispatches a changePanelSuccess action with the updated layouts.
   *
   * @returns An observable that emits the changePanelSuccess action.
   */
  onDisplayFullImage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.onDisplayFullImage),
        mergeMap(async (action) => {
          const panelLayout: IPanelLayout = this.layoutService.calculateLayout(DEFAULT_PANEL_ID.toString(), DEFAULT_PANEL_LAYOUT);
          const stackLayout = this.layoutService.calculateLayout(DEFAULT_STACK_ID.toString(), DEFAULT_PANEL_LAYOUT) as IStackLayout;
          stackLayout.panelId = DEFAULT_PANEL_ID.toString();
          const newStackLayout: IStackLayout[] = [stackLayout];
          const tileLayout = this.layoutService.calculateLayout(DEFAULT_TILE_ID.toString(), action.tileLayout) as ITileLayout;
          tileLayout.stackId = `${DEFAULT_PANEL_ID}${VIEWPORT_INDEX_SEPERATOR}${DEFAULT_STACK_ID}`;
          const newTileLayout: ITileLayout[] = [tileLayout];
          return layoutActions.changePanelSuccess({
            panelLayout: panelLayout,
            stackLayout: newStackLayout,
            tileLayout: newTileLayout,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  onChangeDisplaySeries$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.changeDisplaySeries),
        mergeMap(async (action) => {
          this.broadcastService.changeDisplayViewportBroadcast();
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  //#endregion

  //#region  Study List
  /**
   * Effect that handles the studyDisplayCurrentLayout action.
   * Retrieves series information and stack images based on the study UID,
   * and constructs an array of display viewports for the layout.
   * Dispatches studyDisplayCurrentLayoutSuccess action with the display viewports on success,
   * or studyDisplayCurrentLayoutFail action with the error on failure.
   *
   * @returns An observable that emits studyDisplayCurrentLayoutSuccess or studyDisplayCurrentLayoutFail actions.
   */
  studyDisplayCurrentLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayCurrentLayout),
        concatLatestFrom(() => this.store.select(ViewerLayoutQuery.selectStackLayout)),
        mergeMap(async ([action, stackLayout]) => {
          try {
            const displayViewport: ISeriesToStack[] = [];
            for (let index = 0; index < stackLayout.length; index++) {
              const study = action.studyUid[index];
              if (study) {
                const seriesInfo = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSeriesInfo(study)));
                const stackImages = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUIds(seriesInfo.map((series) => series.uid))));

                for (let stackImageIndex = 0; stackImageIndex < stackImages.length; stackImageIndex++) {
                  const stack = stackImages[stackImageIndex];
                  const studyUid = seriesInfo.find((series) => series.uid === stack.uid)?.studyUid;
                  displayViewport.push({
                    studyUid: studyUid || '',
                    seriesUid: stack.uid,
                    stackIndex: `${index}${VIEWPORT_INDEX_SEPERATOR}${stackImageIndex}`,
                    type: Enums.ViewportType.STACK,
                    mode: ViewerMode.Stack2D,
                  });
                }
              }
            }

            return studyListAction.studyDisplayCurrentLayoutSuccess({ displayViewport });
          } catch (err) {
            return studyListAction.studyDisplayCurrentLayoutFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for the success action of changing the study display layout.
   * It broadcasts the change of the display viewport.
   * @returns An observable that emits the broadcast changeDisplayViewportBroadcast action.
   * @throws Does not dispatch any action.
   * @readonly
   * @memberof ViewerGeneralEffects
   * @function studyDisplayCurrentLayoutSuccess$
   * */
  studyDisplayCurrentLayoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayCurrentLayoutSuccess),
        mergeMap(async (action) => {
          this.broadcastService.changeDisplayViewportBroadcast(true);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect to handle the automatic layout of study display.
   *
   * This effect listens for the `studyDisplayAllSeries` action and then
   * retrieves the current stack layout from the store. It attempts to
   * perform an asynchronous operation to handle the study display layout.
   *
   * If the operation is successful, it should dispatch the
   * `studyDisplayAllSeriesSuccess` action with the display viewport.
   * If an error occurs, it dispatches the `studyDisplayAllSeriesFail`
   * action with the error.
   *
   * @effect
   * @returns {Observable} An observable that emits actions to handle the study display layout.
   */
  studyDisplayAutomaticLayout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayAllSeries),
        mergeMap(async (action) => {
          try {
            //return studyListAction.studyDisplayAllSeriesSuccess({ displayViewport });
            return false;
          } catch (err) {
            return studyListAction.studyDisplayAllSeriesFail({ error: err });
          }
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that listens for the success action of changing the study display layout.
   * It broadcasts the change of the display viewport.
   * @returns An observable that emits the broadcast changeDisplayViewportBroadcast action.
   * @throws Does not dispatch any action.
   * @readonly
   * @memberof ViewerGeneralEffects
   * @function studyDisplayAutomatictSuccess$
   * */
  studyDisplayAutomatictSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayCurrentLayoutSuccess),
        mergeMap(async (action) => {
          this.broadcastService.changeDisplayViewportBroadcast(true);
        }),
      );
    },
    { functional: true, dispatch: false },
  );

  /**
   * Effect that handles the study display by setting action.
   * Retrieves the modality of the main study, the layout setting of the selected modality,
   * calculates the panel index map with image stack, and sets all series of selected series to store.
   * Returns the modality layout and the display viewport as a success action.
   */
  studyDisplayBySetting$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayBySetting),
        mergeMap(async (action) => {
          //get modality of main study
          const modality = action.modality;
          //get layout setting of selected modality
          const settingLayout = await firstValueFrom(this.store.select(settingQuery.selectLayoutByModality(modality)));
          let modalityLayout = settingLayout.find((setting) => setting.name === action.settingName) || DEFAULT_SETTING.layoutModality.default[0];
          //Caculate panel index map with image stack
          const seriesToStack: ISeriesToStack[] = [];
          //Set all series of selected series to store
          const studyUids = action.selectedStudies.map((study) => study.uid);
          for (let index = 0; index < studyUids.length; index++) {
            const studyUid = studyUids[index];
            const seriesInfo = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectSeriesInfo(studyUid)));
            const stackImages = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUIds(seriesInfo.map((series) => series.uid))));
            for (let indexStack = 0; indexStack < stackImages.length; indexStack++) {
              const stack = stackImages[indexStack];
              const studyUid = seriesInfo.find((series) => series.uid === stack.uid)?.studyUid;
              seriesToStack.push({
                studyUid: studyUid || '',
                seriesUid: stack.uid,
                stackIndex: `${index}${VIEWPORT_INDEX_SEPERATOR}${indexStack}`,
                type: Enums.ViewportType.STACK,
                mode: ViewerMode.Stack2D,
              });
            }
          }

          return studyListAction.studyDisplayBySettingSuccess({ modalityLayout, displayViewport: seriesToStack });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  /**
   * Effect that listens for the success action of changing the study display settings and
   * initializes the layout based on the modality of the display series.
   */
  changeLayoutOfStudyDisplayBySetting$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(studyListAction.studyDisplayBySettingSuccess),
        mergeMap(async (action) => {
          let newStackLayout: IStackLayout[] = [];
          let newTileLayout: ITileLayout[] = [];
          // transform stack setting to IStackLayout for store on redux
          let tileIndex = 0;
          const stackLayouts = action.modalityLayout.stackLayout;
          const tileLayouts = action.modalityLayout.tileLayout;
          for (let index = 0, length = stackLayouts.length; index < length; index++) {
            //Calculate stack layout base on setting layout (XxY)
            let stackLayout = <IStackLayout>this.layoutService.calculateLayout(index.toString(), stackLayouts[index]);
            stackLayout.panelId = index.toString();
            newStackLayout = [...newStackLayout, stackLayout];

            //Add ITileLayout each stack
            for (let indexStack = 0; indexStack < stackLayout.total; indexStack++) {
              const tileLayout = <ITileLayout>this.layoutService.calculateLayout(tileIndex.toString(), tileLayouts[tileIndex]);
              tileLayout.stackId = `${index}${VIEWPORT_INDEX_SEPERATOR}${indexStack}`;
              newTileLayout = [...newTileLayout, tileLayout];
              tileIndex++;
            }
          }

          //Change layout by modality of display series
          return layoutActions.changeLayoutBySetting({
            stackLayout: newStackLayout,
            tileLayout: newTileLayout,
            displayViewports: action.displayViewport,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  //#endregion

  //#region  Volume processing
  onVolumeLoadSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.createVolumeSuccess),
        mergeMap(async (action) => {
          // caculate display viewport to display volume
          let config: IMenuMPR | IMenuFusion;
          //Update menu by active stack, if active stack is 2D, we need to update menu to display 2D otherwise by volume stack
          let newActionMode = ViewerMode.MPR;
          if (action.mode === ViewerMode.MPR) {
            config = <IMenuMPR>await firstValueFrom(this.store.select(ViewerMenuQuery.selectMpr));
          } else {
            config = <IMenuFusion>await firstValueFrom(this.store.select(ViewerMenuQuery.selectFusion));
          }
          let displayViewport: ISeriesToStack[] = [];
          let panelIndex = 0;
          let oldStackLayoutString: string = '';
          if (config.isDisplay2D) {
            newActionMode = ViewerMode.Stack2D;
            //Get current layout from store\
            const currentPanel = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActivePanel));
            //Allow only active panel when display MPR or fusion
            panelIndex = 1;
            const oldViewport = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewportByPanelIndex(currentPanel?.panelIndex || DEFAULT_PANEL_INDEX)));
            //get 2d viewport from backup
            const newViewport = cloneDeep(oldViewport);
            displayViewport = [...newViewport];
            const oldStackLayout = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectStackLayoutByPanelIndex(currentPanel?.panelIndex || DEFAULT_PANEL_INDEX)));
            oldStackLayoutString = oldStackLayout?.layoutString || '';
          }
          //Get volume info from store and add it into the viewport list
          //Volume seriesUid format modality:seriesUid-modality:seriesUid-...
          if (action.mode === ViewerMode.MPR) {
            displayViewport.push({
              studyUid: '', // Volume don't have studyUid, so we use empty for now
              seriesUid: SERIES_UID_VOLUME_PATTERN(action.seriesUids[CT_INDEX], action.modality[CT_INDEX]),
              stackIndex: `${panelIndex}${VIEWPORT_INDEX_SEPERATOR}${DEFAULT_STACK_ID}`,
              type: Enums.ViewportType.ORTHOGRAPHIC,
              mode: ViewerMode.MPR,
            });
          } else {
            //FUSION
            newActionMode = ViewerMode.Fusion;
            const seriesA = action.seriesUids[0];
            const seriesAModality = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectModalityBySeriesUID(seriesA)));
            const seriesAVolumeId = SERIES_UID_VOLUME_PATTERN(seriesA, seriesAModality);
            const seriesB = action.seriesUids[1];
            const seriesBModality = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectModalityBySeriesUID(seriesB)));
            const seriesBVolumeId = SERIES_UID_VOLUME_PATTERN(seriesB, seriesBModality);
            displayViewport.push({
              studyUid: '', // Volume don't have studyUid, so we use empty for now
              seriesUid: ORTHOGRAPHIC_STACK_PATTERN(seriesAVolumeId, seriesBVolumeId),
              stackIndex: `${panelIndex}${VIEWPORT_INDEX_SEPERATOR}${DEFAULT_STACK_ID}`,
              type: Enums.ViewportType.ORTHOGRAPHIC,
              mode: ViewerMode.Fusion,
            });
          }
          return viewerGeneralActions.changeDisplayViewportVolume({
            mode: newActionMode,
            displayViewport,
            config,
            oldStackLayout: oldStackLayoutString,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  changeViewerModeByVolume$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.changeDisplayViewportVolume),
        mergeMap(async (action) => {
          return layoutActions.initVolumeLayout({
            mode: action.mode,
            config: action.config,
            oldStackLayout: action.oldStackLayout,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  changeDisplayViewportVolume$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.createVolumeSuccess),
        mergeMap(async (action) => {
          return viewerGeneralActions.changeViewerMode({ mode: action.mode });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  backupViewportOnStartMPRFusion$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(volumeActions.createVolumeSuccess),
        concatLatestFrom(() => this.store.select(ViewerGeneralQuery.selectDisplayViewport)),
        mergeMap(async ([action, viewports]) => {
          return viewerGeneralActions.backupDisplayViewport({ viewports });
        }),
      );
    },
    { functional: true, dispatch: true },
  );

  displayVolume2D$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerGeneralActions.changeDisplayVolume2D),
        concatLatestFrom(() => this.store.select(ViewerGeneralQuery.selectDisplayViewportBackup)),
        mergeMap(async ([action, viewportsBackup]) => {
          const currentDisplayViewport = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectDisplayViewport));
          let newDisplayViewport = cloneDeep(currentDisplayViewport);
          if (action.isDisplay) {
            //Current display only have 2D, get backup viewport to dissplay
            const backupActivePanel = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActivePanel));
            const backupViewport = cloneDeep(viewportsBackup.filter((viewport) => viewport.stackIndex.startsWith(backupActivePanel?.panelIndex || DEFAULT_PANEL_INDEX), 0));
            newDisplayViewport = [...backupViewport, ...newDisplayViewport];
            newDisplayViewport.forEach((viewport) => {
              //In Volume mode, in this time we only support 1x2 with 2d on the left panel,
              //so we replace all stack index of 2d study image into 1st panel and 2nd panel for volume
              //Format index is panel(0)-stack(1)-tile(2)
              const stackIndex = viewport.stackIndex.split(VIEWPORT_INDEX_SEPERATOR)[1];
              if (viewport.mode === ViewerMode.Stack2D) {
                viewport.stackIndex = `${PANEL_LAYOUT_INDEX}${VIEWPORT_INDEX_SEPERATOR}${stackIndex}`;
              } else {
                viewport.stackIndex = `${PANEL_VOLUME_LAYOUT_INDEX}${VIEWPORT_INDEX_SEPERATOR}${stackIndex}`;
              }
            });
          } else {
            //Remove all volume 2D display viewport on left
            newDisplayViewport = newDisplayViewport.filter((viewport) => viewport.mode === ViewerMode.MPR || viewport.mode === ViewerMode.Fusion);
            // Change stack index into 1st panel
            newDisplayViewport.forEach((viewport, index) => {
              viewport.stackIndex = `${PANEL_LAYOUT_INDEX}${VIEWPORT_INDEX_SEPERATOR}${index}`;
            });
          }
          return viewerGeneralActions.changeDisplayVolume2DSuccess({
            displayViewport: newDisplayViewport,
            isDisplay: action.isDisplay,
            mode: action.mode,
          });
        }),
      );
    },
    { functional: true, dispatch: true },
  );
  //#endregion
}
