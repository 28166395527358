import { IActivePanel, IActivePanelLayoutBackup, IActiveStack, IActiveTile, IBackupLayout, IPanelLayout, ISelectedStack, IStackLayout, ITileLayout } from '@app/viewer/models/ILayout';
import { createFeature, createReducer, on } from '@ngrx/store';
import { layoutActions } from './layout.actions';
import { cloneDeep } from 'lodash';
import { DEFAULT_STACK_LAYOUT } from '@app/viewer/contants/layout';
import { DEFAULT_STACK_INDEX, DEFAULT_TILE_INDEX } from '@app/viewer/contants';

export const VIEWER_LAYOUT_KEY = 'layout';

export interface ViewerLayoutState {
  // additional entity state properties
  metadataHidden: boolean;
  panelLayout: IPanelLayout;
  stackLayout: IStackLayout[];
  tileLayout: ITileLayout[];
  activeStack: IActiveStack | undefined;
  activePanel: IActivePanel | undefined;
  activeTile: IActiveTile | undefined;
  selectedStack: ISelectedStack[];
  isLoading: boolean;
  // A flag to indicate that the the action rendering image need wating destroy the layout before processing
  isWating: boolean;
  backup: IBackupLayout | undefined;
  activePanelLayoutBackup: IActivePanelLayoutBackup | undefined;
  error: any;
}

export const initialState: ViewerLayoutState = {
  metadataHidden: false,
  isLoading: true,
  isWating: true,
  panelLayout: {
    id: '0',
    layoutString: '1x1',
    total: 1,
  },
  stackLayout: [
    {
      id: '0',
      layoutString: '2x2',
      total: 4,
      panelId: '0',
    },
  ],
  tileLayout: [
    {
      id: '0',
      layoutString: '1x1',
      total: 1,
      stackId: '0-0',
    },
    {
      id: '1',
      layoutString: '1x1',
      total: 1,
      stackId: '0-1',
    },
    {
      id: '2',
      layoutString: '1x1',
      total: 1,
      stackId: '0-2',
    },
    {
      id: '3',
      layoutString: '1x1',
      total: 1,
      stackId: '0-3',
    },
  ],
  activePanel: undefined,
  activeStack: undefined,
  activeTile: {
    seriesUid: '',
    tileIndex: '0-0-0',
  },
  selectedStack: [],
  backup: undefined,
  activePanelLayoutBackup: undefined,
  error: '',
};

export const layoutFeature = createFeature({
  name: VIEWER_LAYOUT_KEY,
  reducer: createReducer<ViewerLayoutState>(
    initialState,
    //#region  change layout
    on(
      layoutActions.changePanelLayout,
      (state): ViewerLayoutState => ({
        ...state,
        isLoading: true,
        isWating: true,
      }),
    ),
    on(
      layoutActions.changeStackLayout,
      (state): ViewerLayoutState => ({
        ...state,
        isLoading: true,
        isWating: true,
      }),
    ),
    on(
      layoutActions.changeTileLayout,
      (state): ViewerLayoutState => ({
        ...state,
        isLoading: true,
        isWating: false,
      }),
    ),
    on(
      layoutActions.changePanelSuccess,
      (state, action): ViewerLayoutState => ({
        ...state,
        panelLayout: action.panelLayout,
        stackLayout: action.stackLayout,
        tileLayout: action.tileLayout,
        isLoading: false,
      }),
    ),
    on(
      layoutActions.changeLayoutBySetting,
      (state, action): ViewerLayoutState => ({
        ...state,
        stackLayout: action.stackLayout,
        tileLayout: action.tileLayout,
        isLoading: true,
      }),
    ),
    on(
      layoutActions.changeWaitingRender,
      (state, action): ViewerLayoutState => ({
        ...state,
        isWating: action.isWating,
      }),
    ),
    on(layoutActions.changeStackSuccess, (state, action): ViewerLayoutState => {
      //remove the old one stack layout on store
      const oldState = state.stackLayout.filter((stack) => stack.panelId != action.stackLayout.panelId);
      const newStackIdList = action.tileLayout.map((tile) => tile.stackId);
      const oldTileState = state.tileLayout.filter((tile) => !newStackIdList.includes(tile.stackId));
      return {
        ...state,
        stackLayout: [...oldState, action.stackLayout],
        tileLayout: [...oldTileState, ...action.tileLayout],
        isLoading: false,
      };
    }),
    on(layoutActions.changeTileSuccess, (state, action): ViewerLayoutState => {
      //remove the old one stack layout on store
      const oldState = state.tileLayout.filter((tile) => tile.id != action.tileLayout.id);
      return {
        ...state,
        tileLayout: [...oldState, action.tileLayout],
        isLoading: false,
        isWating: false,
      };
    }),
    on(
      layoutActions.overrideAllTile,
      (state, action): ViewerLayoutState => ({
        ...state,
        tileLayout: action.tileLayout,
        isLoading: false,
      }),
    ),
    on(layoutActions.overrideTileInStack, (state, action): ViewerLayoutState => {
      if (action.tileLayout === undefined) {
        return {
          ...state,
        };
      } else {
        const oldStale = state.tileLayout.filter((tile) => tile.stackId !== action.stackIndex);
        const currentStackLayout = state.stackLayout.find((stack) => stack.id === action.stackIndex);
        return {
          ...state,
          tileLayout: [...oldStale, action.tileLayout],
          activeStack: {
            seriesUid: action.seriesUid,
            stackIndex: action.stackIndex,
            layout: currentStackLayout?.layoutString || DEFAULT_STACK_LAYOUT,
          },
          activeTile: {
            seriesUid: action.seriesUid,
            tileIndex: action.stackIndex + '-0',
          },
          isWating: false,
        };
      }
    }),
    on(layoutActions.changeSettingLayoutSuccess, (state, action): ViewerLayoutState => {
      return {
        ...state,
        isLoading: false,
        activeStack: {
          seriesUid: action.seriesUid,
          stackIndex: DEFAULT_STACK_INDEX,
          layout: action.stackLayout,
        },
        activeTile: {
          seriesUid: action.seriesUid,
          tileIndex: DEFAULT_TILE_INDEX,
        },
      };
    }),
    on(
      layoutActions.changeLayoutFail,
      (state, action): ViewerLayoutState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
    //#endregion
    //#region change selected state
    on(
      layoutActions.changeActivePanel,
      (state, action): ViewerLayoutState => ({
        ...state,
        activePanel: action.activePanel,
      }),
    ),
    on(
      layoutActions.changeActiveStack,
      (state, action): ViewerLayoutState => ({
        ...state,
        activeStack: action.activeStack,
      }),
    ),
    on(
      layoutActions.changeActiveTile,
      (state, action): ViewerLayoutState => ({
        ...state,
        activeTile: action.activeTile,
      }),
    ),
    on(layoutActions.updateSelectedStack, (state, action): ViewerLayoutState => {
      const isExistSelectedStack = state.selectedStack.find((stack) => stack.stackIndex === action.selectedStack.stackIndex);
      let newState: ISelectedStack[] = [];
      if (isExistSelectedStack || action.selectedStack.seriesUid === '') {
        //if selected stack is exist, remove it
        newState = state.selectedStack.filter((stack) => stack.stackIndex !== action.selectedStack.stackIndex);
      } else {
        //if selected stack is not exist, add it
        newState = [...state.selectedStack, action.selectedStack];
      }
      return {
        ...state,
        selectedStack: newState,
      };
    }),
    //#endregion
    //#region Other
    on(
      layoutActions.changeMetadataDisplay,
      (state, action): ViewerLayoutState => ({
        ...state,
        metadataHidden: !state.metadataHidden,
      }),
    ),
    on(
      layoutActions.backupLayout,
      (state, action): ViewerLayoutState => ({
        ...state,
        backup: action.layout,
        activePanelLayoutBackup: action.activePanelLayout,
      }),
    ),
    on(
      layoutActions.backupLayoutFullImage,
      (state, action): ViewerLayoutState => ({
        ...state,
        backup: action.layout,
      }),
    ),
    on(
      layoutActions.restoreLayout,
      (state, action): ViewerLayoutState => ({
        ...state,
        panelLayout: action.layout.panelLayout,
        stackLayout: action.layout.stackLayout,
        tileLayout: action.layout.tileLayout,
        isLoading: true,
        isWating: true,
      }),
    ),
    //#endregion
  ),
});

export const { name, reducer } = layoutFeature;
