import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CornerstoneService, DataDictionary, IImage, uids } from '@app/@core';
import { VIEWPORT_INDEX_SEPERATOR } from '@app/viewer/contants';
import { Tag } from '@app/viewer/models';
import { ViewerLayoutQuery } from '@app/viewer/store/layout';
import { Store } from '@ngrx/store';
import { Table } from 'primeng/table';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-dicom-info',
  templateUrl: './dicom-info.component.html',
  styleUrls: ['./dicom-info.component.scss'],
})
export class DicomInfoComponent implements OnInit, OnChanges {
  constructor(
    private store: Store,
    private cornerstoneService: CornerstoneService,
  ) {}
  @Input() isDisplay: boolean;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  protected datasets: Tag[] = [];
  protected visible: boolean = false;
  ngOnInit() {}
  @ViewChild('dt') dt: Table;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisplay.currentValue) {
      const activeStack = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveStack));
      const viewport = this.cornerstoneService.getStackViewport(`${activeStack?.stackIndex}${VIEWPORT_INDEX_SEPERATOR}0` || '0-0-0');
      const imageData: IImage = viewport?.getCornerstoneImage() as IImage;
      const dataSet = imageData?.data;
      this._dumpDataset(dataSet);
      this.visible = true;
    }
  }

  /**
   * Handles the search tag event.
   * @param event - The event object containing the search value.
   */
  protected onSearchTag = ($event) => {
    this.dt.filterGlobal($event.target.value, 'contains');
  };

  /**
   * Callback function that is triggered when the dialog is hidden.
   * Clears the datasets array.
   */
  protected onDialogHide = () => {
    this.datasets = [];
    this.isDisplayChange.emit(false);
  };
  /**
   * Get dicom tag from header
   * @param tag tag id
   */
  private _getTag = (tag) => {
    let group = tag.substring(1, 5);
    let element = tag.substring(5, 9);
    let tagIndex = ('(' + group + ',' + element + ')').toUpperCase();
    let attr = DataDictionary.TAG_DICT[tagIndex];
    return attr;
  };

  /**
   * Convert dicom tag to default dicom tag string x00000001 -> (0000,0001)
   * @param tag tag info
   */
  private _convertTag(tag) {
    let group = tag.substring(1, 5);
    let element = tag.substring(5, 9);

    return ('(' + group + ',' + element + ')').toUpperCase();
  }

  /**
   * Dumps the dataset by iterating over each element and building a string describing its contents.
   * The output is stored in the `dataset` array.
   *
   * @param dataSet - The dataset to be dumped.
   * @returns A Promise that resolves when the dataset has been dumped.
   */
  private _dumpDataset = async (dataSet) => {
    try {
      let keys: string[] = [];
      for (let propertyName in dataSet.elements) {
        if (dataSet.elements.hasOwnProperty(propertyName)) {
          keys.push(propertyName);
        }
      }

      keys.sort();
      let dicomTag: Tag;
      let prefixText = '';

      if (dataSet.level !== undefined) {
        for (let i = 0; i < dataSet.level; i++) {
          prefixText += '>';
        }
      }
      // the dataSet.elements object contains properties for each element parsed.  The name of the property
      // is based on the elements tag and looks like 'xGGGGEEEE' where GGGG is the group number and EEEE is the
      // element number both with lowercase hexadecimal letters.  For example, the Series Description DICOM element 0008,103E would
      // be named 'x0008103e'.  Here we iterate over each property (element) so we can build a string describing its
      // contents to add to the output array
      for (let k = 0; k < keys.length; k++) {
        // Get dicomtag info from data dictionary
        let propertyName = keys[k];
        let element = dataSet.elements[propertyName];
        let tag = this._getTag(element.tag);

        dicomTag = new Tag();

        dicomTag.length = element.length;
        if (element.hadUndefinedLength) {
          dicomTag.length += '(-1)';
        }
        if (element.vr) {
          dicomTag.vr = element.vr;
        }

        dicomTag.dataOffset = element.dataOffset;

        // The output string begins with the element name (or tag if not in data dictionary), length and VR (if present).  VR is undefined for
        // implicit transfer syntaxes
        if (tag === undefined) {
          dicomTag.name = '';
          dicomTag.tag = prefixText + this._convertTag(element.tag);
        } else {
          dicomTag.name = tag.name;
          dicomTag.tag = prefixText + tag.tag;
        }

        // Here we check for Sequence items and iterate over them if present.  items will not be set in the
        // element object for elements that don't have SQ VR type.  Note that implicit little endian
        // sequences will are currently not parsed.
        if (element.items) {
          this.datasets.push(dicomTag);

          // each item contains its own data set so we iterate over the items
          // and recursively call this function
          var itemNumber = 0;

          element.items.forEach((item) => {
            if (item.dataSet.level === undefined) {
              item.dataSet.level = 1;
            } else {
              item.dataSet.level++;
            }
            this._dumpDataset(item.dataSet);
          });
        } else if (element.fragments) {
          // Must investigate later for understand what is Fragments
          // text += "encapsulated pixel data with " + element.basicOffsetTable.length + " offsets and " +
          //   element.fragments.length + " fragments";
          // text += sha1Text(dataSet.byteArray, element.dataOffset, element.length);
          // output.push("<li title='" + title + "'=>" + text + '</li>');
          // if (showFragments && element.encapsulatedPixelData) {
          //   output.push('Fragments:<br>');
          //   output.push('<ul>');
          //   var itemNumber = 0;
          //   element.fragments.forEach(function (fragment) {
          //     var str = '<li>Fragment #' + itemNumber++ + ' dataOffset = ' + fragment.position;
          //     str += '; offset = ' + fragment.offset;
          //     str += '; length = ' + fragment.length;
          //     str += sha1Text(dataSet.byteArray, fragment.position, fragment.length);
          //     str += '</li>';
          //     output.push(str);
          //   });
          //   output.push('</ul>');
          // }
          // if (showFrames && element.encapsulatedPixelData) {
          //   output.push('Frames:<br>');
          //   output.push('<ul>');
          //   var bot = element.basicOffsetTable;
          //   // if empty bot and not RLE, calculate it
          //   if (bot.length === 0) {
          //     bot = dicomParser.createJPEGBasicOffsetTable(dataSet, element);
          //   }
          //   function imageFrameLink(frameIndex) {
          //     var linkText = "<a class='imageFrameDownload' ";
          //     linkText += "data-frameIndex='" + frameIndex + "'";
          //     linkText += " href='#'> Frame #" + frameIndex + "</a>";
          //     return linkText;
          //   }
          //   for (var frameIndex = 0; frameIndex < bot.length; frameIndex++) {
          //     var str = "<li>";
          //     str += imageFrameLink(frameIndex, "Frame #" + frameIndex);
          //     str += ' dataOffset = ' + (element.fragments[0].position + bot[frameIndex]);
          //     str += '; offset = ' + (bot[frameIndex]);
          //     var imageFrame = dicomParser.readEncapsulatedImageFrame(dataSet, element, frameIndex, bot);
          //     str += '; length = ' + imageFrame.length;
          //     str += sha1Text(imageFrame);
          //     str += '</li>';
          //     output.push(str);
          //   }
          //   output.push('</ul>');
          // }
        } else {
          // use VR to display the right value
          var vr;
          if (element.vr !== undefined) {
            vr = element.vr;
          } else {
            if (tag !== undefined) {
              vr = tag.vr;
            }
          }

          dicomTag.value = this._convertDicomTagToValue(dataSet, propertyName);
          // finally we add the string to our output array surrounded by li elements so it shows up in the
          // DOM as a list
          this.datasets.push(dicomTag);
        }
      }
    } catch (error) {}
  };

  /**
   * Convert the dicom tag to right value
   * @param dataSet The dataset header of image
   * @param tagName The DICOM Tag ex:x00010002
   */
  private _convertDicomTagToValue = (dataSet, tagName) => {
    let element = dataSet.elements[tagName];
    let result = '';
    if (element !== undefined) {
      let vr = element.vr;
      // if the length of the element is less than 128 we try to show it.  We put this check in
      // to avoid displaying large strings which makes it harder to use.
      if (element.length < 128) {
        // Since the dataset might be encoded using implicit transfer syntax and we aren't using
        // a data dictionary, we need some simple logic to figure out what data types these
        // elements might be.  Since the dataset might also be explicit we could be switch on the
        // VR and do a better job on this, perhaps we can do that in another example

        // First we check to see if the element's length is appropriate for a UI or US VR.
        // US is an important type because it is used for the
        // image Rows and Columns so that is why those are assumed over other VR types.
        if (element.vr === undefined) {
          if (element.length === 2) {
            result += dataSet.uint16(tagName);
          } else if (element.length === 4) {
            result += dataSet.uint32(tagName);
          }

          // Next we ask the dataset to give us the element's data in string form.  Most elements are
          // strings but some aren't so we do a quick check to make sure it actually has all ascii
          // characters so we know it is reasonable to display it.
          var str = dataSet.string(tagName);
          var stringIsAscii = this._isASCII(str);

          if (stringIsAscii) {
            // the string will be undefined if the element is present but has no data
            // (i.e. attribute is of type 2 or 3 ) so we only display the string if it has
            // data.  Note that the length of the element will be 0 to indicate "no data"
            // so we don't put anything here for the value in that case.
            if (str !== undefined) {
              result += str + this._mapUid(str);
            }
          }
        } else {
          if (this._isStringVr(vr)) {
            // Next we ask the dataset to give us the element's data in string form.  Most elements are
            // strings but some aren't so we do a quick check to make sure it actually has all ascii
            // characters so we know it is reasonable to display it.
            var str = dataSet.string(tagName);
            var stringIsAscii = this._isASCII(str);

            if (stringIsAscii) {
              // the string will be undefined if the element is present but has no data
              // (i.e. attribute is of type 2 or 3 ) so we only display the string if it has
              // data.  Note that the length of the element will be 0 to indicate "no data"
              // so we don't put anything here for the value in that case.
              if (str !== undefined) {
                result += str + this._mapUid(str);
              }
            } else {
              // if (element.length !== 2 && element.length !== 4) {
              //   color = '#C8C8C8';
              //   // If it is some other length and we have no string
              //   result += '<i>binary data</i>';
              // }
            }
          } else if (vr === 'US') {
            result += dataSet.uint16(tagName);
            for (var i = 1; i < dataSet.elements[tagName].length / 2; i++) {
              result += '\\' + dataSet.uint16(tagName, i);
            }
          } else if (vr === 'SS') {
            result += dataSet.int16(tagName);
            for (var i = 1; i < dataSet.elements[tagName].length / 2; i++) {
              result += '\\' + dataSet.int16(tagName, i);
            }
          } else if (vr === 'UL') {
            result += dataSet.uint32(tagName);
            for (var i = 1; i < dataSet.elements[tagName].length / 4; i++) {
              result += '\\' + dataSet.uint32(tagName, i);
            }
          } else if (vr === 'SL') {
            result += dataSet.int32(tagName);
            for (var i = 1; i < dataSet.elements[tagName].length / 4; i++) {
              result += '\\' + dataSet.int32(tagName, i);
            }
          } else if (vr === 'FD') {
            result += dataSet.double(tagName);
            for (var i = 1; i < dataSet.elements[tagName].length / 8; i++) {
              result += '\\' + dataSet.double(tagName, i);
            }
          } else if (vr === 'FL') {
            result += dataSet.float(tagName);
            for (var i = 1; i < dataSet.elements[tagName].length / 4; i++) {
              result += '\\' + dataSet.float(tagName, i);
            }
          } else if (vr === 'OB' || vr === 'OW' || vr === 'UN' || vr === 'OF' || vr === 'UT') {
            // Ignore this VR value
          } else if (vr === 'AT') {
            var group = dataSet.uint16(tagName, 0);
            var groupHexStr = ('0000' + group.toString(16)).substr(-4);
            var element1 = dataSet.uint16(tagName, 1);
            var elementHexStr = ('0000' + element1.toString(16)).substr(-4);
            result += 'x' + groupHexStr + elementHexStr;
          } else if (vr === 'SQ') {
            // Ignore this VR value
          } else {
            // If it is some other length and we have no string
            // Ignore
          }
        }
      } else {
        // Add result saying the data is too long to show...
        //Inorge
      }
    }
    return result;
  };

  /**
   * helper function to see if a string only has ascii characters in it
   * @param str string
   */
  private _isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
  }

  /**
   * check string VR
   * @param vr VR code
   */
  private _isStringVr(vr) {
    if (vr === 'AT' || vr === 'FL' || vr === 'FD' || vr === 'OB' || vr === 'OF' || vr === 'OW' || vr === 'SI' || vr === 'SQ' || vr === 'SS' || vr === 'UL' || vr === 'US') {
      return false;
    }
    return true;
  }

  /**
   * Get uids of dicom tag
   */
  private _mapUid(str) {
    var uid = uids.uids[str];
    if (uid) {
      return ' [ ' + uid + ' ]';
    }
    return '';
  }
}
