import { Injectable } from '@angular/core';
import { Severity } from './enum/serverity';
import { Subject } from 'rxjs';
import { Logger } from './logger.service';
import { confirmDialogPayload } from './enum/confirm-dialog';
import { toastPayload } from './enum/toast';
import { loaderPayload } from './enum/loader';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
const log = new Logger('SharedService');

/**
 * Service for sharing data and triggering events across components.
 */
@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
  ) {
    this.preloader$ = new Subject<loaderPayload>();
    this.toastMessage$ = new Subject<toastPayload>();
  }
  //#region declare Rxjs subject event
  public preloader$: Subject<loaderPayload>;
  public toastMessage$: Subject<toastPayload>;
  //#endregion

  //#region public next event subject
  /**
   * Emits a confirm dialog event.
   * @param severity - The severity of the dialog (e.g., 'success', 'error', 'warning').
   * @param message - The message to be displayed in the dialog.
   */
  public confirmDialog(severity: Severity, message: string): Promise<boolean> {
    return new Promise((rs, rj) => {
      switch (severity) {
        case Severity.info:
          this.confirmationService.confirm({
            message: message,
            header: this.translateService.instant('ConfirmDialogHeader'),
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'none',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button-text',
            accept: () => {
              rs(true);
            },
            reject: () => {
              rs(false);
            },
          });
          break;
        case Severity.error:
          this.confirmationService.confirm({
            message: message,
            header: this.translateService.instant('DangerDialogHeader'),
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass: 'p-button-danger p-button-text',
            rejectButtonStyleClass: 'p-button-text p-button-text',
            acceptIcon: 'none',
            rejectIcon: 'none',
            accept: () => {
              rs(true);
            },
            reject: () => {
              rs(false);
            },
          });
          break;
        case Severity.warn:
          this.confirmationService.confirm({
            message: message,
            header: this.translateService.instant('WarnDialogHeader'),
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass: 'p-button-danger p-button-text',
            rejectButtonStyleClass: 'p-button-text p-button-text',
            acceptIcon: 'none',
            rejectIcon: 'none',
            accept: () => {
              rs(true);
            },
            reject: () => {
              rs(false);
            },
          });
          break;
        default:
          break;
      }
    });
  }

  /**
   * Emits a preloader event.
   * @param show - Indicates whether to show or hide the preloader.
   * @param message - The message to be displayed in the preloader.
   */
  public preloader(show: boolean, message: string = '') {
    this.preloader$.next({ show, message });
  }

  /**
   * Emits a toast message event.
   * @param message - The message to be displayed in the toast.
   * @param severity - The severity of the toast (e.g., 'success', 'error', 'warning').
   */
  public toastMessage(severity: Severity, message: string) {
    this.toastMessage$.next({ message, severity });
  }

  //#endregion
}
