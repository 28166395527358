import { Base, CornerstoneMetadata, IGeneralSeries, IImage, IImagePrefetch } from '@app/@core';
import { ISeries } from './ISeries';
import { SortOrder } from '@app/viewer/contants/sort';
/**
 * Represents a DICOM series.
 */
export class Series implements ISeries {
  uid: string;
  metadata: IGeneralSeries;
  thumbnail?: string | undefined;
  modality: string;

  /**
   * Represents a Series object.
   * @constructor
   * @param {string} uid - The unique identifier of the series.
   * @param {IGeneralSeries} metadata - The metadata of the series.
   * @param {string} modality - The modality of the series.
   */
  constructor(uid: string, metadata: IGeneralSeries, modality: string) {
    this.uid = uid;
    this.metadata = metadata;
    this.modality = modality;
  }

  /**
   * Adds a thumbnail URL to the series.
   * @param url - The URL of the thumbnail.
   */
  addThumbnail(url: string): void {
    this.thumbnail = url;
  }

  /**
   * Sorts the series by instance number.
   * @param order - The sort order (ascending or descending).
   */
  sortByInstanceNumber(order: SortOrder = SortOrder.Ascending): void {}

  /**
   * Sorts the series by acquisition number.
   * @param order - The sort order (ascending or descending).
   */
  sortByAcquisitionNumber(order: SortOrder = SortOrder.Ascending): void {}

  /**
   * Sorts the series by creation time.
   * @param order - The sort order (ascending or descending).
   */
  sortByCreationTime(order: SortOrder = SortOrder.Ascending): void {}

  /**
   * Sorts the series by slice location.
   * @param order - The sort order (ascending or descending).
   */
  sortBySliceLocation(order: SortOrder = SortOrder.Ascending): void {}

  /**
   * Sorts the series by echo time.
   * @param order - The sort order (ascending or descending).
   * @remarks This method overrides the base implementation.
   */
  sortByEchoTime(order: SortOrder = SortOrder.Ascending): void {
    // Override methods
  }
}
