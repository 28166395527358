<div class="viewer-patient">
  <ng-container *rxLet="patientInfo$; let patient">
    <div class="viewer-patient__avatar">
      <img [src]="patient?.avatar !== '' ? patient?.avatar : './assets/images/age-gender/O.png'" alt="Patient's Avatar" />
    </div>
    <div class="viewer-patient__meta">
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientID</span>:
        <span>{{ patient?.patientId }}</span>
      </div>
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientName</span>:
        <span>{{ patient?.patientName }}</span>
      </div>
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientSex</span>:
        <span>{{ patient?.patientGender?.trim() || '' | translate }}</span>
      </div>
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientAge</span>:
        <span>{{ patient?.patientAge }}</span>
      </div>
    </div>
  </ng-container>
</div>
