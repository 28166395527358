import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import * as dicomParser from 'dicom-parser';
import { CornerstoneMetadata } from '../contants/metadata';

/**
 * Retrieves metadata for a given type and image ID.
 * @param type - The type of metadata to retrieve.
 * @param imageId - The ID of the image.
 * @returns The metadata object corresponding to the given type and image ID.
 */
const get = (type: string, imageId: string): any => {
  if (imageId === undefined) {
    return;
  }
  const { parseImageId, dataSetCacheManager } = cornerstoneWADOImageLoader.wadouri;
  const parsedImageId = parseImageId(imageId);
  const dataSet = dataSetCacheManager.get(parsedImageId.url);

  if (!dataSet) {
    return;
  }

  if (type === CornerstoneMetadata.GENERAL_IMAGE_MODULE) {
    return {
      accessionNumber: dataSet.string('x00080050') || '',
      modality: dataSet.string('x00080060') || '',
      seriesInstanceUID: dataSet.string('x0020000e') || '',
      seriesNumber: dataSet.intString('x00200011') || '',
      studyInstanceUID: dataSet.string('x0020000d') || '',
      studyDate: dataSet.string('x00080020') || '',
      studyTime: dataSet.string('x00080030') || '',
      seriesDate: dataSet.string('x00080021') || '',
      seriesTime: dataSet.string('x00080031') || '',
      seriesDescription: dataSet.string('x0008103e') || '',
      bodyPart: dataSet.string('x00180015') || '',
      sopInstanceUID: dataSet.string('x00080018') || '',
      acquisitionTime: dataSet.string('x00080032') || '',
      instanceNumber: dataSet.string('x00200013') || '',
      manufacture: dataSet.string('x00080070') || '',
    };
  }

  if (type === CornerstoneMetadata.PATIENT_MODULE) {
    return {
      patientName: dataSet.string('x00100010') || '',
      patientId: dataSet.string('x00100020') || 'Anonymous',
      patientSize: dataSet.floatString('x00101020') || '',
      patientWeight: dataSet.floatString('x00101030') || '',
      patientGender: dataSet.string('x00100040') || '',
      patientBirthDate: dataSet.string('x00100030') || '',
      patientAge: calculatePatientAge(dataSet) || '',
      charset: dataSet.string('x00080005') || '',
    };
  }

  if (type === CornerstoneMetadata.GENERAL_STUDY_MODULE) {
    return {
      studyDescription: dataSet.string('x00081030') || '',
      studyDate: dataSet.string('x00080020') || '',
      studyTime: dataSet.string('x00080030') || '',
    };
  }

  if (type === CornerstoneMetadata.SORT_NUMBER_MODULE) {
    return {
      creationTime: parseInt(dataSet.string('x00080012') + dataSet.string('x00080013')) || -1,
      acquisitionTime: parseInt(dataSet.string('x00080032')) || 1,
      acquisitionNumber: parseInt(dataSet.string('x00200012')) || 1,
      echoTime: parseInt(dataSet.string('x00180081')) || 1,
      instanceNumber: parseInt(dataSet.string('x00200013')) || 1,
      sliceLocation: parseInt(dataSet.string('x00201041')) || 1,
    };
  }

  if (dataSet.elements[type] !== undefined) {
    const element = dataSet.elements[type];
    if (!element.vr) {
      return;
    }
    return dicomParser.explicitElementToString(dataSet, element);
  }
};

/**
 * Calculates the patient's age based on the DICOM dataset.
 * @param dataset - The DICOM dataset containing the necessary information.
 * @returns The calculated patient age.
 */
const calculatePatientAge = (dataSet: any): string => {
  let age: any;

  if (dataSet.string('x00101010') !== undefined) {
    age = dataSet.string('x00101010');
    return age;
  } else if (dataSet.string('x00080020') !== undefined && dataSet.string('x00100030')) {
    age = (parseInt(dataSet.string('x00080020'), 10) - parseInt(dataSet.string('x00100030'), 10)) / 10000;
    return Math.floor(age).toString();
  } else {
    return '';
  }
};

export { get };
