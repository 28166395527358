import { ITag } from './ITag';

export class Tag implements ITag {
  /**
   *ctor
   */
  constructor() {}

  //#region variables
  public tag: string;
  public name: string;
  public vr: string;
  public length: string;
  public value: string;
  public dataOffset: string;
  //#endregion

  //#region Methods
  /**
   * Input the value to this dicom tag
   * @param tag tag number
   * @param name tag name
   * @param vr VR
   * @param length length
   * @param value tag value
   */
  public input(tag, name, vr, length, value, dataOffset) {
    this.tag = tag;
    this.name = name;
    this.vr = vr;
    this.length = length;
    this.value = value;
    this.dataOffset = dataOffset;
  }

  /**
   * Clone this object
   */
  public clone() {
    const clone = new Tag();
    clone.tag = this.tag;
    clone.name = this.name;
    clone.vr = this.vr;
    clone.length = this.length;
    clone.value = this.value;
    clone.dataOffset = this.dataOffset;
    return clone;
  }
  //#endregion
}
