import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Enums, Types } from '@cornerstonejs/core';
import { CornerstoneService, Threads } from '@app/@core';
import { DEFAULT_PANEL_INDEX } from '@app/viewer/contants';
import { IPanelLayout } from '@app/viewer/models';
import { BroadcastService, ToolsService } from '@app/viewer/services';
import { ViewerLayoutQuery } from '@app/viewer/store/layout';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
const { IMAGE_RENDERED } = Enums.Events;
export interface IPanelViewport {
  panelIndex: string;
  panelLayout: string;
  index: number;
}
@Component({
  selector: 'app-viewer-viewport',
  templateUrl: './viewport.component.html',
  styleUrl: './viewport.component.scss',
})
export class ViewerViewportComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private toolsService: ToolsService,
    private cornerstoneService: CornerstoneService,
    private boardcastService: BroadcastService,
  ) {}

  private _selectPanelSubs$: Subscription;

  public panelLayout$: Observable<IPanelLayout>;
  public panelLayout: IPanelViewport[];
  public layout: string;
  public currentActivePanel$: Observable<string | undefined>;
  public currentActivePanel: string;
  private _metadataMenuSubs$: Subscription;
  private _displayMetadata$: Observable<boolean>;
  ngOnInit(): void {
    this.toolsService.initDefautlToolStack();
    this.panelLayout$ = this.store.select(ViewerLayoutQuery.selectPanelLayout);
    this._displayMetadata$ = this.store.select(ViewerLayoutQuery.selectMetadataDisplay);
    this._metadataMenuSubs$ = this._displayMetadata$.subscribe((value) => {
      this._onResizeRender();
    });
    this.panelLayout$.subscribe((value: IPanelLayout) => {
      if (this.panelLayout) {
        this.panelLayout.length = 0;
      }
      const layout: IPanelViewport[] = [];
      for (let index = 0; index < value.total; index++) {
        const panel: IPanelViewport = {
          panelIndex: value.id,
          panelLayout: value.layoutString,
          index: index,
        };
        layout.push(panel);
      }
      this.panelLayout = layout;
    });
    this.currentActivePanel$ = this.store.select(ViewerLayoutQuery.selectCurrentActivePanelIndex);
    this._selectPanelSubs$ = this.currentActivePanel$.subscribe((value) => {
      this.currentActivePanel = value || DEFAULT_PANEL_INDEX;
    });
  }

  ngOnDestroy(): void {
    this._selectPanelSubs$?.unsubscribe();
    this._metadataMenuSubs$?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  protected onWindowsResize = async () => {
    //Wait for DOM rerender grid view with delay time
    await Threads.Instance.sleep(100);
    this._onResizeRender();
  };

  private _onResizeRender = async () => {
    const renderingEngine = this.cornerstoneService.getRenderEngine();

    if (renderingEngine) {
      const viewports = this.cornerstoneService.getAllStackViewport();
      if (viewports.length === 0) {
        return;
      }
      const presentations = viewports.map((viewport) => viewport.getViewPresentation());
      renderingEngine.resize(true, false);
      viewports.forEach((viewport, idx) => {
        viewport.setViewPresentation(presentations[idx]);
      });
    }
  };
}
