import { ActivatedRouteSnapshot, Router } from '@angular/router';

export class RouteHelpers {
  private constructor() {}

  /**
   * Singleton class
   */
  private static _instance: RouteHelpers;

  static get Instance(): RouteHelpers {
    // Create a new Route instance.
    if (!this._instance) {
      this._instance = new RouteHelpers();
    }
    return RouteHelpers._instance;
  }

  /**
   * Collects all route parameters from the given router.
   *
   * @param router - The router instance.
   * @returns An object containing all route parameters.
   */
  public collectRouteParams(router: Router): any {
    let params = {};
    let stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
    while (stack.length > 0) {
      const route = stack.pop()!;
      params = { ...params, ...route.params };
      stack.push(...route.children);
    }
    return params;
  }

  /**
   * Collects all query parameters from the current router state and its child routes.
   *
   * @param router - The router instance.
   * @returns An object containing all the collected query parameters.
   */
  public collectRouteQueryParams(router: Router): any {
    let params = {};
    let stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
    while (stack.length > 0) {
      const route = stack.pop()!;
      params = { ...params, ...route.queryParams };
      stack.push(...route.children);
    }
    return params;
  }
}
