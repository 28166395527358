import { IGeneralStudy, IPatientStudy } from '@app/@core';
import { IStudy } from './IStudy';
import { SortOrder } from '@app/viewer/contants/sort';

/**
 * Represents a study in the DICOM system.
 * @template T - The type of series contained in the study.
 */
export class Study<T> implements IStudy<T> {
  uid: string;
  patientInfo: IPatientStudy;
  studyInfo: IGeneralStudy;
  series: T[];
  constructor(uid: string) {
    this.uid = uid;
    this.series = [];
  }

  /**
   * Sets the patient information for the study.
   *
   * @param value - The patient study information.
   * @returns void
   */
  setPatient(value: IPatientStudy): void {
    this.patientInfo = value;
  }

  /**
   * Sets the general study information.
   *
   * @param value - The general study information to set.
   * @returns void
   */
  setGeneral(value: IGeneralStudy): void {
    this.studyInfo = value;
  }

  /**
   * Adds a new series to the study.
   *
   * @param value - The series to be added.
   */
  addNewSeries(value: T): void {
    this.series.push(value);
  }

  /**
   * Finds a series in the study by its UID.
   * @param uid - The UID of the series to find.
   * @returns The found series, or undefined if not found.
   */
  findSeries(uid: string): T | undefined {
    return this.series.find((value) => value['uid'] === uid);
  }

  /**
   * Sorts the study by UID.
   * @param order The sort order. Defaults to SortOrder.Ascending.
   */
  sortByUid(order: SortOrder = SortOrder.Ascending): void {
    //Override method
  }

  /**
   * Sorts the study by series number.
   * @param order The sort order (optional, default is ascending).
   */
  sortbySeriesNumber(order: SortOrder = SortOrder.Ascending): void {
    //Override method
  }
}
