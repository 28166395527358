import { Component, OnInit } from '@angular/core';
import { AxiosService } from '@app/@core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-about-team',
  templateUrl: './about-team.component.html',
  styleUrl: './about-team.component.scss',
})
export class AboutTeamComponent implements OnInit {
  constructor(
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private axios: AxiosService,
  ) {
    this.currentVersion = environment.version;
  }
  protected currentVersion: string = '1.0.0';
  protected browser: string = '';
  protected os: string = '';
  protected licenseVisible: boolean = false;
  protected thirdPartyLicense: string = '';

  ngOnInit(): void {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.browser = deviceInfo.browser + ' ' + deviceInfo.browser_version;
    this.os = deviceInfo.os + ' ' + deviceInfo.os_version.split('-')[1];
  }

  openTerms() {
    const url = `/${this._getCurrentLanguage()}/terms`;
    window.open(url, '_blank', 'noopener noreferrer');
  }

  openPrivacy() {
    const url = `/${this._getCurrentLanguage()}/privacy`;
    window.open(url, '_blank', 'noopener noreferrer');
  }
  openCookie() {
    const url = `/${this._getCurrentLanguage()}/cookies`;
    window.open(url, '_blank', 'noopener noreferrer');
  }

  async openLicense() {
    const content = await this._3rdPartyLibraries();
    this.thirdPartyLicense = content.data;
    this.licenseVisible = true;
  }

  private _getCurrentLanguage(): string {
    return this.translateService.currentLang.toLocaleLowerCase();
  }

  private _3rdPartyLibraries = async () => {
    const content = this.axios.get('/3rdpartylicenses.txt', {});
    return content;
  };
}
