<div class="stack" [ngStyle]="getStyle()" pDroppable="series" (onDrop)="seriesDrop($event)">
  <div class="stack__scroll" *ngIf="totalImages > 1">
    <input class="stack__scroll__slider" type="range" min="0" [max]="totalImages - 1" step="1" [value]="imageIndex" (input)="handleSliderChanged($event)" />
  </div>
  <div class="w-100 h-100" [ngSwitch]="viewportInfo?.mode">
    <ng-container *ngSwitchCase="ViewerMode.Stack2D">
      <ng-container *ngTemplateOutlet="viewport2D"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ViewerMode.MPR">
      <ng-container *rxLet="tileLayout$; let volumeLayout; strategy: 'immediate'">
        <ng-container *rxIf="volumeLayout?.layoutString === '3V0H'">
          <ng-container *ngTemplateOutlet="MPRVolume3V"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '3H1D'">
          <ng-container *ngTemplateOutlet="MPRVolume3H1D"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '1D0D'">
          <ng-container *ngTemplateOutlet="MPR3D"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '2H1V'">
          <ng-container *ngTemplateOutlet="MPRVolume2H1V"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ViewerMode.Fusion">
      <ng-container *rxLet="tileLayout$; let volumeLayout; strategy: 'immediate'">
        <ng-container *rxIf="volumeLayout?.layoutString === '3H3V1V'">
          <ng-container *ngTemplateOutlet="Fusion3H3V1V"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '3V0H'">
          <ng-container *ngTemplateOutlet="Fusion3V"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '4V0H'">
          <ng-container *ngTemplateOutlet="Fusion4V"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '2H3V'">
          <ng-container *ngTemplateOutlet="Fusion2H3V"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '2V2H'">
          <ng-container *ngTemplateOutlet="Fusion2V2H"></ng-container>
        </ng-container>
        <ng-container *rxIf="volumeLayout?.layoutString === '2H2V'">
          <ng-container *ngTemplateOutlet="Fusion2H2V"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Default for video viewport -->
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="videoViewport"></ng-container>
    </ng-container>
  </div>
  <!-- <span class="stack__mask"></span> -->
  <!-- <span class="stack__mask" [class.stack__mask--activated]="activated"></span> -->

  <span class="stack__selected" [class.stack__selected--activated]="(selectedStack$ | async) === seriesUid && seriesUid !== ''"></span>
</div>

<!-- TEMPLATE FOR DISPLAY VOLUME -->
<!-- VIDEO VIEWPORT -->
<ng-template #videoViewport>
  <div id="videoViewport" style="height: 100%; width: 100%"></div>
</ng-template>
<ng-template #viewport2D>
  <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
    <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
      <app-tile
        #tile
        [tileId]="item.tileId"
        [tileIndex]="item.tileIndex"
        [viewportType]="item.viewportType"
        [index]="i"
        [tileLayout]="tileLayout?.layoutString || '1x1'"
        [style]="getTile2DStyle(i, tileLayout?.layoutString || '1x1')"
        (scrollIndex)="onTileScrollEvent($event)"
      ></app-tile>
    </ng-container>
  </ng-container>
</ng-template>
<!-- MPR -->
<!-- 3V -->
<ng-template #MPRVolume3V>
  <div class="w-100 h-100" style="display: flex; float: left">
    <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
      <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
        <app-tile
          #tile
          [tileId]="item.tileId"
          [tileIndex]="item.tileIndex"
          [viewportType]="item.viewportType"
          [index]="i"
          [viewportConfig]="item.config"
          [tileLayout]="tileLayout?.layoutString || '1x1'"
          [style]="getVolumeStyle('1x1')"
          [ngStyle]="getVolumeStyle('1x3')"
        ></app-tile>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- 3H -1V -->
<ng-template #MPRVolume3H1D>
  <div class="w-100 h-100" style="display: flex; float: left">
    <div class="h-100 w-40">
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
          <ng-container *rxIf="i < 3">
            <app-tile
              #tile
              [tileId]="item.tileId"
              [tileIndex]="item.tileIndex"
              [viewportType]="item.viewportType"
              [index]="i"
              [viewportConfig]="item.config"
              [tileLayout]="tileLayout?.layoutString || '1x1'"
              [style]="getVolumeStyle('1x1')"
              [ngStyle]="getVolumeStyle('3x1')"
            ></app-tile>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="h-100 w-60">
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxLet="tileInfo[tileInfo.length - 1]; let lastTile">
          <app-tile
            #tile
            [tileId]="lastTile.tileId"
            [tileIndex]="lastTile.tileIndex"
            [viewportType]="lastTile.viewportType"
            [index]="tileInfo.length - 1"
            [viewportConfig]="lastTile.config"
            [tileLayout]="tileLayout?.layoutString || '1x1'"
            [style]="getVolumeStyle('1x1')"
            [ngStyle]="getVolumeStyle('1x1')"
          ></app-tile>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- 1x1 3D or prepare for some special layout-->
<ng-template #MPR3D>
  <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
    <ng-container *rxLet="tileInfo[0]; let lastTile">
      <app-tile
        #tile
        [tileId]="lastTile.tileId"
        [tileIndex]="lastTile.tileIndex"
        [viewportType]="lastTile.viewportType"
        [index]="tileInfo.length - 1"
        [viewportConfig]="lastTile.config"
        [tileLayout]="tileLayout?.layoutString || '1x1'"
        [style]="getVolumeStyle('1x1')"
        [ngStyle]="getVolumeStyle('1x1')"
      ></app-tile>
    </ng-container>
  </ng-container>
</ng-template>

<!-- 2H -1V -->
<ng-template #MPRVolume2H1V>
  <div class="w-100 h-100" style="display: flex; float: left">
    <div class="h-100 w-40">
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
          <ng-container *rxIf="i < 2">
            <app-tile
              #tile
              [tileId]="item.tileId"
              [tileIndex]="item.tileIndex"
              [viewportType]="item.viewportType"
              [index]="i"
              [viewportConfig]="item.config"
              [tileLayout]="tileLayout?.layoutString || '1x1'"
              [style]="getVolumeStyle('1x1')"
              [ngStyle]="getVolumeStyle('2x1')"
            ></app-tile>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="h-100 w-60">
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxLet="tileInfo[tileInfo.length - 1]; let lastTile">
          <app-tile
            #tile
            [tileId]="lastTile.tileId"
            [tileIndex]="lastTile.tileIndex"
            [viewportType]="lastTile.viewportType"
            [index]="tileInfo.length - 1"
            [viewportConfig]="lastTile.config"
            [tileLayout]="tileLayout?.layoutString || '1x1'"
            [style]="getVolumeStyle('1x1')"
            [ngStyle]="getVolumeStyle('1x1')"
          ></app-tile>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- FUSION -->
<!-- 3Hx3V 1V -->
<ng-template #Fusion3H3V1V>
  <div class="w-100 h-100" style="display: flex">
    <div class="w-80 h-100" style="display: block; float: left">
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
          <ng-container *rxIf="i < tileInfo.length - 1">
            <app-tile
              #tile
              [tileId]="item.tileId"
              [tileIndex]="item.tileIndex"
              [viewportType]="item.viewportType"
              [index]="i"
              [viewportConfig]="item.config"
              [tileLayout]="tileLayout?.layoutString || '1x1'"
              [style]="getVolumeStyle('1x1')"
              [ngStyle]="getVolumeStyle('3x3')"
            ></app-tile>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="w-20 h-100" style="display: block; float: left">
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxLet="tileInfo[tileInfo.length - 1]; let lastTile">
          <app-tile
            #tile
            [tileId]="lastTile.tileId"
            [tileIndex]="lastTile.tileIndex"
            [viewportType]="lastTile.viewportType"
            [index]="tileInfo.length - 1"
            [viewportConfig]="lastTile.config"
            [tileLayout]="tileLayout?.layoutString || '1x1'"
            [style]="getVolumeStyle('1x1')"
            [ngStyle]="getVolumeStyle('1x1')"
          ></app-tile>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<!-- 3V 0H -->
<ng-template #Fusion3V>
  <div class="w-100 h-100" style="display: flex; float: left">
    <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
      <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
        <app-tile
          #tile
          [tileId]="item.tileId"
          [tileIndex]="item.tileIndex"
          [viewportType]="item.viewportType"
          [index]="i"
          [viewportConfig]="item.config"
          [tileLayout]="tileLayout?.layoutString || '1x1'"
          [style]="getVolumeStyle('1x1')"
          [ngStyle]="getVolumeStyle('1x3')"
        ></app-tile>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<!-- 2V - 2H-->
<ng-template #Fusion2V2H>
  <div class="w-100 h-100" style="display: flex; float: left">
    <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
      <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
        <ng-container *rxIf="i < 2">
          <app-tile
            #tile
            [tileId]="item.tileId"
            [tileIndex]="item.tileIndex"
            [viewportType]="item.viewportType"
            [index]="i"
            [viewportConfig]="item.config"
            [tileLayout]="tileLayout?.layoutString || '1x1'"
            [style]="getVolumeStyle('1x1')"
            [ngStyle]="getVolumeStyle('1x3')"
          ></app-tile>
        </ng-container>
      </ng-container>
    </ng-container>
    <div
      [ngStyle]="{
        height: '100%',
        width: 100 / 3 + '%'
      }"
    >
      <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
        <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
          <ng-container *rxIf="i > 1">
            <app-tile
              #tile
              [tileId]="item.tileId"
              [tileIndex]="item.tileIndex"
              [viewportType]="item.viewportType"
              [index]="i"
              [viewportConfig]="item.config"
              [tileLayout]="tileLayout?.layoutString || '1x1'"
              [style]="getVolumeStyle('1x1')"
              [ngStyle]="getVolumeStyle('2x1')"
            ></app-tile>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<!-- 2H -3V -->
<ng-template #Fusion2H3V>
  <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
    <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
      <app-tile
        #tile
        [tileId]="item.tileId"
        [tileIndex]="item.tileIndex"
        [viewportType]="item.viewportType"
        [index]="i"
        [viewportConfig]="item.config"
        [tileLayout]="tileLayout?.layoutString || '1x1'"
        [style]="getVolumeStyle('1x1')"
        [ngStyle]="getVolumeStyle('2x3')"
      ></app-tile>
    </ng-container>
  </ng-container>
</ng-template>
<!-- 2H - 2V -->
<ng-template #Fusion2H2V>
  <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
    <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
      <app-tile
        #tile
        [tileId]="item.tileId"
        [tileIndex]="item.tileIndex"
        [viewportType]="item.viewportType"
        [index]="i"
        [viewportConfig]="item.config"
        [tileLayout]="tileLayout?.layoutString || '1x1'"
        [style]="getVolumeStyle('1x1')"
        [ngStyle]="getVolumeStyle('2x2')"
      ></app-tile>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #Fusion4V>
  <div class="w-100 h-100" style="display: flex; float: left">
    <ng-container *rxLet="tileLayout$; let tileLayout; strategy: 'immediate'">
      <ng-container *rxFor="let item of tileInfo; let i = index; strategy: 'immediate'">
        <app-tile
          #tile
          [tileId]="item.tileId"
          [tileIndex]="item.tileIndex"
          [viewportType]="item.viewportType"
          [index]="i"
          [viewportConfig]="item.config"
          [tileLayout]="tileLayout?.layoutString || '1x1'"
          [style]="getVolumeStyle('1x1')"
          [ngStyle]="getVolumeStyle('1x4')"
        ></app-tile>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<!-- END  TEMPLATE FOR DISPLAY VOLUME-->
