import vtkMath from '@kitware/vtk.js/Common/Core/Math';
import { Types } from '@cornerstonejs/core';

const getViewportZoomScale = (size: Types.Point2, camera: Types.ICamera): number => {
  const { position, focalPoint } = camera;

  const distance = position && focalPoint ? vtkMath.distance2BetweenPoints(position, focalPoint) : 0;
  const zoomScale = Math.sqrt(distance) / size[1];
  return zoomScale;
};

export { getViewportZoomScale };
