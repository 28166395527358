export enum CameraAction {
  ZoomFitWidth,
  ZoomFitHeight,
  ZoomFitAll,
  ZoomActualSize,
  HorizontalAlign,
  VerticalAlign,
  ResetPan,
  ResetZoom,
  RotateLeft,
  RotateRight,
  FlipHorizontal,
  FlipVertical,
  ResetRotate,
  Reset,
}

export enum Position {
  Top,
  Right,
  Bottom,
  Left,
  Center,
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
}

export const DEFAULT_ROTATE_RADIUS = 90;
