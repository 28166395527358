/**
 * Returns the date format based on the specified language.
 * @param lang The language code.
 * @returns The date format string.
 */
const getDateTimeFormat = (lang: string) => {
  switch (lang) {
    case 'ja-JP':
      return 'YYYY/MM/DD';
    case 'vi-VN':
      return 'DD/MM/YYYY';
    default:
      return 'MM/DD/YYYY';
  }
};

export { getDateTimeFormat };
