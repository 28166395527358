import { vec3, mat4 } from 'gl-matrix';
import { Types, metaData, utilities, VolumeViewport, triggerEvent, EVENTS } from '@cornerstonejs/core';

/**
 * Retrieves the spatial registration metadata for the given target and source IDs.
 * @param {string} targetId - The ID of the target.
 * @param {string} sourceId - The ID of the source.
 * @returns {SpatialRegistrationMetadata} The spatial registration metadata.
 */
const getSpatialRegistration = (targetId, sourceId) => utilities.spatialRegistrationMetadataProvider.get('spatialRegistrationModule', targetId, sourceId);

/**
 * Retrieves the index of the closest image in the target viewport's stack
 * to the source viewport's image position.
 *
 * @param sViewport - The source viewport.
 * @param tViewport - The target viewport.
 * @returns A promise that resolves to the index of the closest image in the target viewport's stack,
 *          or -1 if the viewports are not coplanar or if an error occurs.
 */
const imageSlice = (sViewport: Types.IVolumeViewport | Types.IStackViewport, tViewport: Types.IVolumeViewport | Types.IStackViewport): number => {
  const imageId1 = sViewport.getCurrentImageId();
  const imagePlaneModule1 = metaData.get('imagePlaneModule', imageId1);
  const sourceImagePositionPatient = imagePlaneModule1.imagePositionPatient;

  const targetImageIds = tViewport.getImageIds();

  if (!areViewportsCoplanar(sViewport, tViewport)) {
    return -1;
  }

  // if the frame of reference is different we need to use the registrationMetadataProvider
  // and add that to the imagePositionPatient of the source viewport to get the
  // imagePositionPatient of the target viewport's closest image in its stack
  let registrationMatrixMat4 = getSpatialRegistration(tViewport.id, sViewport.id);

  if (!registrationMatrixMat4) {
    const frameOfReferenceUID1 = sViewport.getFrameOfReferenceUID();
    const frameOfReferenceUID2 = tViewport.getFrameOfReferenceUID();
    if (frameOfReferenceUID1 === frameOfReferenceUID2) {
      registrationMatrixMat4 = mat4.identity(mat4.create());
    } else {
      utilities.calculateViewportsSpatialRegistration(sViewport, tViewport);
      registrationMatrixMat4 = getSpatialRegistration(tViewport.id, sViewport.id);
    }
    if (!registrationMatrixMat4) {
      return -1;
    }
  }

  // apply the registration matrix to the source viewport's imagePositionPatient
  // to get the target viewport's imagePositionPatient
  const targetImagePositionPatientWithRegistrationMatrix = vec3.transformMat4(vec3.create(), sourceImagePositionPatient, registrationMatrixMat4);

  // find the closest image in the target viewport's stack to the
  // targetImagePositionPatientWithRegistrationMatrix
  const closestImageIdIndex2 = _getClosestImageIdIndex(targetImagePositionPatientWithRegistrationMatrix, targetImageIds);

  let imageIndexToSet = closestImageIdIndex2.index;
  if (tViewport instanceof VolumeViewport) {
    // since in case of volume viewport our stack is reversed, we should
    // reverse the index as well
    imageIndexToSet = targetImageIds.length - closestImageIdIndex2.index - 1;
  }

  if (closestImageIdIndex2.index !== -1 && tViewport.getCurrentImageIdIndex() !== closestImageIdIndex2.index) {
    return imageIndexToSet;
  }
  return -1;
};

/**
 * Finds the index of the closest image ID to the target point.
 *
 * @param {vec3} targetPoint - The target point to compare distances with.
 * @param {string[]} imageIds - The array of image IDs to search through.
 * @returns {{ distance: number, index: number }} - The object containing the distance and index of the closest image ID.
 */
const _getClosestImageIdIndex = (targetPoint, imageIds) => {
  // todo: this does not assume orientation yet, but that can be added later
  // todo: handle multiframe images
  return imageIds.reduce(
    (closestImageIdIndex, imageId, index) => {
      const { imagePositionPatient } = metaData.get('imagePlaneModule', imageId);
      const distance = vec3.distance(imagePositionPatient, targetPoint);

      if (distance < closestImageIdIndex.distance) {
        return {
          distance,
          index,
        };
      }
      return closestImageIdIndex;
    },
    {
      distance: Infinity,
      index: -1,
    },
  );
};

/**
 * Checks if two viewports are coplanar.
 *
 * @param viewport1 - The first viewport to compare.
 * @param viewport2 - The second viewport to compare.
 * @returns A boolean indicating whether the viewports are coplanar.
 */
const areViewportsCoplanar = (viewport1: Types.IStackViewport | Types.IVolumeViewport, viewport2: Types.IStackViewport | Types.IVolumeViewport): boolean => {
  const { viewPlaneNormal: viewPlaneNormal1 } = viewport1.getCamera();
  const { viewPlaneNormal: viewPlaneNormal2 } = viewport2.getCamera();
  const dotProducts = viewPlaneNormal1 && viewPlaneNormal2 ? vec3.dot(viewPlaneNormal1, viewPlaneNormal2) : 0;
  return Math.abs(dotProducts) > 0.9;
};

/**
 * Scrolls the stack viewport to the specified index.
 *
 * @param viewport - The stack viewport to scroll.
 * @param index - The index to scroll to.
 * @param isLoop - Optional. Indicates whether the scrolling should loop back to the beginning or end when reaching the end or beginning of the stack. Default is false.
 * @param delta - Optional. The direction of the scroll. Default is 0.
 * @returns A promise that resolves to the image ID of the scrolled viewport.
 */
const stackScroll = async (viewport: Types.IStackViewport, index: number, isLoop: boolean = false, delta: number = 0): Promise<string> => {
  const totalImages = viewport.getImageIds().length;
  if (isLoop) {
    if (index < 0) {
      index = totalImages - 1;
    } else if (index >= totalImages) {
      index = 0;
    }
  } else {
    if (index < 0 || index >= totalImages) {
      return '-1';
    }
  }
  const imageId = await viewport.setImageIdIndex(index);
  triggerEvent(viewport.element, EVENTS.STACK_VIEWPORT_SCROLL, {
    newImageIdIndex: index,
    imageId,
    direction: delta,
  });
  return imageId;
};

export { imageSlice, stackScroll };
