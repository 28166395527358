import { Component, Input, SimpleChanges, OnChanges, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { NO_IMAGES_SRC } from '@app/@core';
import { IDBReport, StudyFirebaseService } from '@app/@database';
import { Severity, SharedService } from '@app/@shared';
import { IFirebaseUser, authQuery } from '@app/auth';
import { DiagnosisType } from '@app/report/constants/report';
import { IKeyImage, IReportContent, IReportDir } from '@app/report/models';
import { ICopyContent } from '@app/report/models/ICopy';
import { ReportPdfService, ReportService } from '@app/report/services';
import { reportActions, selectIsReadOnly, selectShareMode } from '@app/report/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Galleria } from 'primeng/galleria';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-diagnosis-content',
  templateUrl: './diagnosis-content.component.html',
  styleUrl: './diagnosis-content.component.scss',
})
export class DiagnosisContentComponent implements OnChanges, OnInit, OnDestroy {
  @Input() reportIndex: number;
  @Input() isHistoryReport: boolean = true;
  @Input() diagnosisContent: IReportContent;
  @Input() isReadOnly: boolean = false;
  @Output() copyContent: EventEmitter<ICopyContent> = new EventEmitter<ICopyContent>();
  @ViewChild('finding') finding: ElementRef<any>;
  @ViewChild('diagnosis') diagnosis: ElementRef<any>;
  @ViewChild('comment') comment: ElementRef<any>;
  @ViewChild('galleria') galleria: Galleria | undefined;

  protected figure: string = '';
  protected keyImages: IKeyImage[] = [];
  protected activeIndex: number = 0;
  protected fullscreen: boolean = false;
  onFullScreenListener: any;
  constructor(
    private translate: TranslateService,
    private _cdr: ChangeDetectorRef,
    private shared: SharedService,
    private store: Store,
    private reportService: ReportService,
    private reportPDfService: ReportPdfService,
    private studyService: StudyFirebaseService,
  ) {}

  /**
   * Lifecycle hook that is called when any data-bound property of the component changes.
   * Use this hook to perform any necessary actions when the input properties change.
   *
   * @param changes - An object containing the changed properties and their current and previous values.
   */
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.diagnosisContent && changes.diagnosisContent.currentValue !== undefined) {
      this.keyImages = [];
      this.diagnosisContent.keyImages.forEach((keyImage) => {
        this.keyImages.push({ src: keyImage, thumbnail: keyImage });
      });
      this.figure = this.diagnosisContent.figure[0];
      this._cdr.detectChanges();
    }
  }

  /**
   * Lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   * Define an ngOnInit() method to handle any additional initialization tasks.
   */
  ngOnInit() {
    this.bindDocumentListeners();
  }

  /**
   * Lifecycle hook that is called when a directive, pipe, or service is destroyed.
   * Use this hook to release any custom resources, handles, or subscriptions.
   */
  ngOnDestroy() {
    this.unbindDocumentListeners();
  }

  /**
   * Copies the specified content with the given type.
   * @param content - The content to be copied.
   * @param type - The type of the diagnosis.
   */
  onCopyContent = (content: string, type: DiagnosisType) => {
    if (this.isReadOnly === true) {
      return;
    }
    this.copyContent.emit({ type, content });
  };

  /**
   * Copies all the content from the diagnosisContent object and emits it using the copyContent event.
   */
  onCopyAllContent = async () => {
    if (this.isReadOnly === true) {
      return;
    }
    this.copyContent.emit({ type: DiagnosisType.finding, content: this.diagnosisContent.finding });
    this.copyContent.emit({ type: DiagnosisType.diagnosis, content: this.diagnosisContent.diagnosis });
    this.copyContent.emit({ type: DiagnosisType.comment, content: this.diagnosisContent.comment });
  };

  /**
   * Updates the diagnosis content based on the received copy content.
   * @param content - The copy content to be received.
   */
  public recieveCopyContent = (content: ICopyContent) => {
    switch (content.type) {
      case DiagnosisType.finding:
        this.diagnosisContent.finding = content.content;
        break;
      case DiagnosisType.diagnosis:
        this.diagnosisContent.diagnosis = content.content;
        break;
      case DiagnosisType.comment:
        this.diagnosisContent.comment = content.content;
        break;
    }
  };

  /**
   * Handles the change event of the key image index.
   * @param $event - The event object containing the key image index.
   */
  protected onKeyImageIndexChange = ($event: any) => {
    this.figure = this.diagnosisContent.figure[$event];
  };

  /**
   * Adds a key image to the list of key images.
   *
   * @param src - The source of the key image.
   */
  public onRecieveKeyImage = (src: string) => {
    if (this.keyImages[0].src === NO_IMAGES_SRC) {
      this.keyImages = [];
    }
    this.keyImages.push({ src, thumbnail: src });
    this.activeIndex = this.keyImages.length - 1;
    this.figure = '';
    this._cdr.detectChanges();
  };

  /**
   * Closes the report content.
   * If the user confirms the close action, the window will be closed.
   */
  protected onClose = async () => {
    let msgStr = this.translate.instant('ConfirmCloseReport1');
    if (this.isReadOnly) {
      msgStr = this.translate.instant('ConfirmCloseReport2');
    }
    const confirmed = await this.shared.confirmDialog(Severity.warn, msgStr);
    if (confirmed) {
      window.close();
    }
  };

  /**
   * Handles the blur event for the figure input field.
   * Updates the figure value in the diagnosisContent array at the active index.
   */
  protected onFigureBlur = () => {
    this.diagnosisContent.figure[this.activeIndex] = this.figure;
  };

  /**
   * Saves the report content and metadata.
   *
   * @returns {Promise<void>} A promise that resolves when the report content and metadata are saved successfully.
   */
  protected onSaveReport = async () => {
    window.document.body.click();
    this.diagnosisContent.keyImages = this.keyImages.map((keyImage) => keyImage.src);
    const userId = <IFirebaseUser>await firstValueFrom(this.store.select(authQuery.selectUserInfo));
    //confirm overwrite report
    if (this.diagnosisContent.driveId !== '') {
      const confirmed = await this.shared.confirmDialog(Severity.info, this.translate.instant('ConfirmOverwriteReport'));
      if (!confirmed) {
        return;
      } else {
        //delete old report content
        const deleteReportDir = await this.reportService.deleteReportContent(this.diagnosisContent.driveId);
        const deleteReportMetadata = await this.reportService.deleteReportMetadata(this.diagnosisContent.id || '');
        if (!deleteReportDir && !deleteReportMetadata) {
          window.document.body.click();
          this.shared.toastMessage(Severity.error, this.translate.instant('CantDeleteOldReport'));
          return;
        }
      }
    }
    window.document.body.click();
    this.shared.preloader(true, this.translate.instant('SavingReport'));
    //save report content to drive as json file
    const reportDir: IReportDir = {
      studyInstanceUid: this.diagnosisContent.studyInstanceUid,
      doctorUid: userId.uid,
      finding: this.diagnosisContent.finding,
      diagnosis: this.diagnosisContent.diagnosis,
      comment: this.diagnosisContent.comment,
      keyImages: this.diagnosisContent.keyImages,
      figure: this.diagnosisContent.figure,
      updatedDate: moment().unix().toString(),
    };
    const isShareMode = await firstValueFrom(this.store.select(selectShareMode));
    const reportDriveID = await this.reportService.saveReportContent(this.diagnosisContent.baseDriveId, reportDir, userId.uid, isShareMode);

    if (reportDriveID === '') {
      window.document.body.click();
      this.shared.toastMessage(Severity.error, this.translate.instant('ReportSavedFail'));
      this.shared.preloader(false);
      return;
    }

    this.diagnosisContent.driveId = reportDriveID;

    //Save report metadata to firebase\
    const reportMetadata: IDBReport = {
      doctorName: userId.displayName || '',
      driveId: reportDriveID,
      studyUid: this.diagnosisContent.studyUid,
      note: '',
      createBy: userId.uid,
    };

    const saveMetadata = await this.reportService.saveReportMetadata(reportMetadata);
    //Save report status to study firebase
    //update study isShared flat to true
    const updatePayload = { isHaveReport: true };
    const res = await this.studyService.updatePartialStudy(updatePayload, this.diagnosisContent.studyUid, userId.uid);
    window.document.body.click();
    if (saveMetadata === undefined) {
      this.shared.toastMessage(Severity.error, this.translate.instant('ReportSavedFail'));
      this.shared.preloader(false);
    } else {
      this.store.dispatch(reportActions.reload({ reportId: reportDriveID }));
    }
    window.document.body.click();
  };

  /**
   * Preview the report.
   * This method triggers the generation of a report PDF and displays it for preview.
   * It shows a preloader while the report is being generated.
   */
  protected onPreviewReport = async () => {
    //Syncup keyImages before creating PDF report
    this.diagnosisContent.keyImages = this.keyImages.map((keyImage) => keyImage.src);
    //Preview PDF Report
    await this.reportPDfService.reviewReport(this.diagnosisContent);
  };

  /**
   * Downloads a report.
   *
   * This method triggers the download of a report by calling the `downloadReport` method of the `reportPDfService` service.
   * It also displays a preloader while the report is being generated.
   *
   * @returns {Promise<void>} A promise that resolves when the report has been downloaded.
   */
  protected onDownloadReport = async () => {
    //Syncup keyImages before creating PDF report
    this.diagnosisContent.keyImages = this.keyImages.map((keyImage) => keyImage.src);
    //Create PDF Report
    await this.reportPDfService.downloadReport(this.diagnosisContent);
  };

  /**
   * Returns the CSS class for the full screen icon based on the current fullscreen state.
   * If the component is in fullscreen mode, it returns 'pi-window-minimize',
   * otherwise it returns 'pi-window-maximize'.
   *
   * @returns The CSS class for the full screen icon.
   */
  protected fullScreenIcon() {
    return `pi ${this.fullscreen ? 'pi-window-minimize' : 'pi-window-maximize'}`;
  }

  /**
   * Binds document event listeners for fullscreen change.
   */
  protected bindDocumentListeners() {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullScreenListener);
    document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.addEventListener('msfullscreenchange', this.onFullScreenListener);
  }

  /**
   * Removes the document event listeners for fullscreen change.
   */
  protected unbindDocumentListeners() {
    document.removeEventListener('fullscreenchange', this.onFullScreenListener);
    document.removeEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('msfullscreenchange', this.onFullScreenListener);
    this.onFullScreenListener = null;
  }

  /**
   * Toggles the fullscreen mode of the diagnosis content component.
   * If the component is currently in fullscreen mode, it will close the fullscreen preview.
   * If the component is not in fullscreen mode, it will open the fullscreen preview.
   * It also detaches the change detection reference.
   */
  protected toggleFullScreen() {
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    } else {
      this.openPreviewFullScreen();
    }

    this._cdr.detach();
  }

  /**
   * Opens the preview in full screen mode.
   */
  protected openPreviewFullScreen() {
    let elem = this.galleria?.element.nativeElement.querySelector('.p-galleria');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem['mozRequestFullScreen']) {
      /* Firefox */
      elem['mozRequestFullScreen']();
    } else if (elem['webkitRequestFullscreen']) {
      /* Chrome, Safari & Opera */
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) {
      /* IE/Edge */
      elem['msRequestFullscreen']();
    }
  }

  /**
   * Handles the fullscreen change event.
   * Toggles the fullscreen property and triggers change detection.
   */
  protected onFullScreenChange() {
    this.fullscreen = !this.fullscreen;
    this._cdr.detectChanges();
    this._cdr.reattach();
  }

  /**
   * Closes the preview in full screen mode.
   */
  protected closePreviewFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }
}
