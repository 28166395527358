import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { settingQuery } from '@app/setting/store/setting.selectors';
import { FILTER_TYPE, IFilterType, IFilterButton, IPreset } from '@app/worklist';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';
import { settingActions } from '@app/setting/store/setting.actions';
import { SETTING, WORKLIST_SETTING } from '@app/setting/models/ISetting';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Logger } from '@app/@shared';
import { Threads } from '@app/@core';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { OrderList } from 'primeng/orderlist';
interface FilterTypeDefault {
  name: string;
  type: IFilterType;
}

const log = new Logger('worklist setting');
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};
/**
 * Represents the component for worklist settings.
 */
@Component({
  selector: 'app-worklist-setting',
  templateUrl: './worklist-setting.component.html',
  styleUrl: './worklist-setting.component.scss',
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class WorklistSettingComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private translateService: TranslateService,
  ) {}

  /**
   * The default filter types.
   */
  public filterTypeDefault: FilterTypeDefault[];

  /**
   * An observable that emits the worklist settings.
   */
  public worklistSetting$: Observable<IPreset[] | undefined>;
  private worlistSubs: Subscription;
  /**
   * The current worklist settings.
   */
  public worklistSetting: IPreset[];

  /**
   * Indicates whether there are changes in the worklist settings.
   */
  public isChange: boolean = false;

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.worklistSetting$ = this.store.select(settingQuery.selectWorklistPresetSetting);
    this.filterTypeDefault = [
      { name: FILTER_TYPE.bodypart, type: IFilterType.bodypart },
      { name: FILTER_TYPE.modality, type: IFilterType.modality },
      { name: FILTER_TYPE.registerDate, type: IFilterType.registerDate },
      { name: FILTER_TYPE.studyDate, type: IFilterType.studyDate },
    ];

    this.worlistSubs = this.worklistSetting$.subscribe((value) => {
      this.worklistSetting = cloneDeep(value) || [];
    });
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * Unsubscribes from the 'worlistSubs' subscription.
   */
  ngOnDestroy(): void {
    this.worlistSubs?.unsubscribe();
  }

  /**
   * Deletes a filter from the worklist settings.
   * @param label The label of the filter to delete.
   */
  deleteFilter(label) {
    this.worklistSetting = this.worklistSetting.filter((item) => item.name !== label);
    this.isChange = true;
  }

  /**
   * Cancels the changes made to the worklist settings.
   * @param $event The event object.
   */
  async cancelChange($event) {
    this.isChange = false;
    const res = (await firstValueFrom(this.store.select(settingQuery.selectWorklistPresetSetting))) || [];
    this.worklistSetting = cloneDeep(res);
  }

  /**
   * Saves the changes made to the worklist settings.
   * @param $event The event object.
   */
  async saveChange($event) {
    this.isChange = false;
    this.store.dispatch(
      settingActions.updateSetting({
        name: SETTING.WORKLIST,
        payload: {
          [WORKLIST_SETTING.PRESET]: this.worklistSetting,
        },
      }),
    );
  }

  async onListChange($event) {
    this.isChange = true;
  }
  @ViewChild('settingList') orderList: OrderList;
  scrollToBottom() {
    if (this.orderList && this.orderList.listViewChild?.nativeElement) {
      this.orderList.listViewChild.nativeElement.scrollTop = this.orderList.listViewChild.nativeElement.scrollHeight;
    }
  }
}
