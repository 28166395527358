import { Injectable } from '@angular/core';
import { FireStoreService } from './firestore.service';
import { COLLECTION, METADATA_KEY } from '@app/@core';
import { IDBPatient } from '../models/IPatient';
import { DocumentData } from 'firebase/firestore';
import { LazyLoadEvent } from 'primeng/api';
import { ICondition } from '../models/IBase';
import { STUDY_SEARCH_KEY } from '../constants/study';
export const PATIENT_KEY = 'patientId';

/**
 * Service for interacting with the Firestore collection 'PATIENT'.
 */
@Injectable({ providedIn: 'root' })
export class PatientFirebaseService {
  constructor(private firebase: FireStoreService) {}

  /**
   * Retrieves a patient document from the Firestore collection by its ID.
   * @param id - The ID of the patient document.
   * @returns A Promise that resolves to the patient document data, or undefined if not found.
   */
  public getPatientById = async (id: string): Promise<DocumentData | undefined> => {
    const res = await this.firebase.getDataOfCollectionByFieldValue(COLLECTION.PATIENT, PATIENT_KEY, id);
    return res;
  };

  /**
   * Adds a new patient document to the Firestore collection.
   * @param data - The data of the patient document to be added.
   * @returns A Promise that resolves to the ID of the newly added patient document, or undefined if failed.
   */
  public addPatient = async (data: IDBPatient): Promise<string | undefined> => {
    const res = await this.firebase.addData(COLLECTION.PATIENT, data);
    return res;
  };

  /**
   * Updates an existing patient document in the Firestore collection.
   * @param data - The updated data of the patient document.
   * @param uid - The ID of the patient document to be updated.
   * @returns A Promise that resolves to the ID of the updated patient document, or undefined if failed.
   */
  public updatePatient = async (data: IDBPatient, uid: string, userId: string) => {
    const res = await this.firebase.updateData(COLLECTION.PATIENT, uid, data, userId);
    return res;
  };

  /**
   * Soft deletes a patient document in the Firestore collection.
   * @param uid - The ID of the patient document to be deleted.
   * @param userID - The ID of the user performing the deletion.
   * @returns A Promise that resolves to the ID of the deleted patient document, or undefined if failed.
   */
  public deletePatient = async (uid: string, userID: string) => {
    const res = await this.firebase.softDeleteData(COLLECTION.PATIENT, uid, userID);
    return res;
  };

  /**
   * Retrieves all patient documents from the Firestore collection.
   * @returns A Promise that resolves to an array of patient documents.
   */
  public getAllPatients = async (options?: LazyLoadEvent): Promise<IDBPatient[]> => {
    // If options are provided, use them to get the data chunk by chunk
    if (options) {
      let page = 1;
      // Calculate the page number based on the first and rows values
      if (options.first !== undefined && options.first > 0 && options.rows !== undefined && options.rows > 0) {
        // The page number is 1-based, so we subtract one from it
        page = options.first / options.rows + 1;
      }
      // Get the data chunk based on the page number and rows value
      const res = await this.firebase.getAllDataOfCollection(COLLECTION.PATIENT, options.rows, page);
      return res;
    } else {
      // If no options are provided, get all data at once
      const res = await this.firebase.getAllDataOfCollection(COLLECTION.PATIENT);
      return res;
    }
  };

  /**
   * Retrieves patients by user ID.
   * @param userId - The ID of the user.
   * @param options - Optional lazy load event options.
   * @returns A promise that resolves to an array of IDBPatient objects.
   */
  public getPatientsByUserId = async (userId: string, options?: LazyLoadEvent): Promise<IDBPatient[]> => {
    // If options are provided, use them to get the data chunk by chunk
    if (options) {
      // Calculate the page number based on the first and rows values
      let page = 1;
      if (options.first !== undefined && options.first > 0 && options.rows !== undefined && options.rows > 0) {
        page = options.first / options.rows + 1;
      }
      // Get the data chunk based on the page number and rows value
      const res = <IDBPatient[]>await this.firebase.getDatasOfCollectionByFieldValue(COLLECTION.PATIENT, METADATA_KEY.CREATE_BY, userId, '==', 'asc', options.rows, page);
      return res;
    } else {
      // If no options are provided, get all data at once
      const res = <IDBPatient[]>await this.firebase.getDatasOfCollectionByFieldValue(COLLECTION.PATIENT, METADATA_KEY.CREATE_BY, userId, '==', 'asc');
      return res;
    }
  };

  /**
   * Retrieves patients from the Firestore database based on the provided UIDs.
   * @param uids - An array of UIDs representing the patients to retrieve.
   * @returns A Promise that resolves to an array of IDBPatient objects representing the retrieved patients.
   */
  public getPatientsByUids = async (uids: string[]): Promise<IDBPatient[]> => {
    const res = <IDBPatient[]>await this.firebase.getDataOfCollectionByKeys(COLLECTION.PATIENT, uids);
    return res;
  };

  /**
   * Retrieves a patient from the database based on the patient ID.
   * @param patientId - The ID of the patient to retrieve.
   * @returns A Promise that resolves to the retrieved patient.
   */
  public getPatientsByPatientId = async (patientId: string): Promise<IDBPatient> => {
    const res = <IDBPatient>await this.firebase.getDataOfCollectionByFieldValue(COLLECTION.PATIENT, STUDY_SEARCH_KEY.PATIENT_ID, patientId, '==');
    return res;
  };

  /**
   * Retrieves a list of patients from the Firestore database based on the provided patient name.
   * @param patientName - The name of the patient to search for.
   * @returns A Promise that resolves to an array of IDBPatient objects matching the provided patient name.
   */
  public getPatientsByName = async (patientName: string): Promise<IDBPatient[]> => {
    const res = <IDBPatient[]>await this.firebase.getAllDataOfCollection(COLLECTION.PATIENT);
    const searchRes = res.filter((patient) => {
      return patient.patientName.toLowerCase().includes(patientName.toLowerCase());
    });
    return searchRes;
  };
}
