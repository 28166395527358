import { Enums } from '@cornerstonejs/core';
export const CornerstoneMetadata = {
  ...Enums.MetadataModules,
  IMAGE_PIXEL_MODULE: 'imagePixelModule',
  VOI_LUT_MODULE: 'voiLutModule',
  SOP_COMMON_MODULE: 'sopCommonModule',
  PET_ISOTOPE_MODULE: 'petIsotopeModule',
  TRANSFER_SYSTAX_MODULE: 'transferSyntax',
  PET_SERIES_MODULE: 'petSeriesModule',
  PET_IMAGE_MODULE: 'petImageModule',
  INSTANCE_MODULE: 'instance',
  GENERAL_IMAGE_MODULE: 'generalImageModule',
  PATIENT_MODULE: 'patientModule',
  GENERAL_STUDY_MODULE: 'generalStudyModule',
  SORT_NUMBER_MODULE: 'sortNumberModule',
  SCALING_MODLUE: 'scalingModule',
};
