import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LoginComponent } from './login.component';
import { APP_ROUTE_BASE } from '@app/@core';
import { CanDeactivateGuard } from './deactive-component.guard';
const routes: Routes = [
  {
    path: APP_ROUTE_BASE.LOGIN,
    component: LoginComponent,
    data: { title: marker('Login') },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateGuard],
})
export class AuthRoutingModule {}
