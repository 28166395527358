export const STUDY_COLLECTION_KEY = {
  STUDY_UID: 'studyInstanceUid',
  STUDY_DATE: 'studyDate',
};

export const STUDY_SEARCH_KEY = {
  PATIENT_ID: 'patientId',
  PATIENT_NAME: 'patientName',
  STUDY_DATE: 'studyDate',
  MODALITY: 'modality',
};

export const STUDY_FILTER_KEY = {
  MODALITY: 'modality',
  BODY_PART: 'bodyPart',
  STUDY_DATE: 'studyDate',
};
