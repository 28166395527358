import { Base } from '@app/@core/models/base';
import * as encoding from 'encoding-japanese';
import { metaData } from '@cornerstonejs/core';
import { CornerstoneMetadata } from '../contants/metadata';
import { IDcmTag } from './IDicomTag';

/**
 * Represents a DICOM tag.
 */
export class DicomTag extends Base<IDcmTag> implements IDcmTag {
  public general: {
    studyInstanceUID: string;
    seriesInstanceUID: string;
    studyDate: string;
    studyTime: string;
    bodyPart: string;
    modality: string;
    manufacture: string;
    seriesNumber: string;
    seriesTime: string;
    seriesDate: string;
    sopInstanceUID: string;
    acquisitionTime: string;
    instanceNumber: string;
    seriesDescription: string;
    accessionNumber: string;
  };
  public sort: {
    acquisitionTime: number;
    acquisitionNumber: number;
    instanceNumber: number;
    echoTime: number;
    creationTime: number;
    sliceLocation: number;
  };
  public patientInfo: {
    patientName: string;
    patientId: string;
    patientBirthDate: string;
    patientAge: string;
    patientSize: string;
    patientGender: string;
    patientWeight: string;
    studyDate: string;
    studyTime: string;
    bodyPart: string;
    modality: string;
    manufacture: string;
  };

  public studyInfo: {
    studyDescription: string;
    studyDate: string;
    studyTime: string;
  };

  constructor(uid: string) {
    super();
    this.general = metaData.get(CornerstoneMetadata.GENERAL_IMAGE_MODULE, uid);
    this.sort = metaData.get(CornerstoneMetadata.SORT_NUMBER_MODULE, uid);
    const rawPatientInfo = metaData.get(CornerstoneMetadata.PATIENT_MODULE, uid);
    rawPatientInfo.patientName = this._encodePatientTag(rawPatientInfo.patientName, rawPatientInfo.charset).name;
    this.patientInfo = rawPatientInfo;
    this.studyInfo = metaData.get(CornerstoneMetadata.GENERAL_STUDY_MODULE, uid);
  }

  /**
   * Encodes the patient name and returns the encoded name and phonetic name.
   * @param patient - The patient name to encode.
   * @param charset - The character set used for encoding.
   * @returns An object containing the encoded patient name and phonetic name.
   */
  private _encodePatientTag(patient: any, charset: string) {
    let res = { name: '', phoneticName: '' };
    let sp = this._splitPatientName(patient);

    let encoded: string;
    let charsets = [''];

    if (charset) {
      charsets = charset.split('\\');
    }
    if (!charsets[1]) {
      charsets[1] = charsets[0];
    }
    if (!charsets[2]) {
      charsets[2] = charsets[1];
    }
    // Adds data to the response based on charsets and other options. This is called in process
    for (let i = 0; i < sp.length; i++) {
      let buffer = sp[i];
      switch (charsets[i]) {
        case 'ISO 2022 IR 87':
        case 'ISO 2022 IR 159':
          encoded = encoding.convert(buffer, { to: 'UNICODE', from: 'JIS', type: 'string' });
          if (encoded) {
            encoded = encoded.split('^').join(' ');
            if (!res.name) {
              res.name = encoded;
            }
            if (encoded.replace(/\s+/g, '').match(/^[\u30A0-\u30FF]+$/)) {
              res.phoneticName = encoded;
            }
          }
          break;

        case 'ISO_IR 13':
        case 'ISO 2022 IR 13':
          encoded = encoding.convert(buffer, { to: 'UNICODE', from: 'SJIS', type: 'string' });
          if (encoded && !res.name) {
            res.name = encoded.split('^').join(' ');
          }
          break;

        default:
          encoded = encoding.convert(buffer, { to: 'UNICODE', from: 'JIS', type: 'string' });
          if (encoded.replace(/\s+/g, '') !== '=') {
            res.name = encoded.split('^').join(' ');
          } // 1??????????
      }
    }
    return res;
  }

  /**
   * Splits the patient name into individual components.
   * @param patient - The patient name to split.
   * @returns An array containing the individual components of the patient name.
   */
  private _splitPatientName(patient: string): any {
    let sp: string[] = [];
    if (!patient) {
      return sp;
    }
    let p = 0;
    for (let i = 0; i < patient.length; i++) {
      // If there is a patient between 61 and 27 add it to the list sp
      if (patient[i].charCodeAt(0) === 61 && patient[i + 1].charCodeAt(0) === 27) {
        sp.push(patient.slice(p, i));
        p = i + 1;
      }
    }
    sp.push(patient.slice(p, patient.length));
    return sp;
  }
}
