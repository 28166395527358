export class FileHelpers {
  /**
   * / / object. js version of this function is used to provide a way to pass
   */
  private constructor() {}

  /**
   * Singleton class
   */
  private static _instance: FileHelpers;
  /**
   * Returns the singleton instance of this class. This is a singleton so you can call it multiple times without redefining the singleton.
   *
   *
   * @return { FileHelpers } An instance of this class or null
   */
  static get Instance(): FileHelpers {
    // Create a new File instance.
    if (!this._instance) {
      this._instance = new FileHelpers();
    }
    return FileHelpers._instance;
  }

  /**
   * one-line non-regexp universal solution get file name extension
   * @param fname
   * @param string
   *
   * @return { string } The extension of the filename
   */
  public getExtension(fname: string): string {
    return fname.slice((Math.max(0, fname.lastIndexOf('.')) || Infinity) + 1);
  }
}
