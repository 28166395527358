import { Component, OnInit } from '@angular/core';
import { THEME, Theme, ThemeService } from '@app/@core';
import { I18nService } from '@app/i18n';
import { GENERAL_SETTING, SETTING } from '@app/setting/models/ISetting';
import { settingActions } from '@app/setting/store/setting.actions';
import { Store } from '@ngrx/store';

/**
 * Represents the General Setting component.
 */
@Component({
  selector: 'app-general-setting',
  templateUrl: './general-setting.component.html',
  styleUrl: './general-setting.component.scss',
})
export class GeneralSettingComponent implements OnInit {
  themeStateOptions: any[];
  get themeValue(): Theme {
    return this.themeService.theme;
  }

  /**
   * Gets the supported languages.
   * @returns An array of supported languages.
   */
  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  /**
   * Gets the current language.
   * @returns The current language.
   */
  get currentLanguage(): string {
    return this.i18nService.language;
  }

  constructor(
    private themeService: ThemeService,
    private i18nService: I18nService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.themeStateOptions = [
      { label: THEME.LIGHT, value: Theme.Light },
      { label: THEME.DARK, value: Theme.Dark },
    ];
  }

  /**
   * Handles the theme change event.
   * @param $event The event object.
   */
  onThemeChange($event) {
    if ($event.value === this.themeValue || $event.value === null || $event.value === undefined) {
      return;
    }
    this.dispatchUpdate({ theme: $event.value });
  }

  /**
   * Sets the language.
   * @param language The language to set.
   */
  setLanguage(language: string) {
    if (language === this.currentLanguage) {
      return;
    }
    this.dispatchUpdate({ lang: language });
  }

  dispatchUpdate({ lang = this.currentLanguage, theme = this.themeValue }) {
    this.store.dispatch(
      settingActions.updateSetting({
        name: SETTING.GENERAL,
        payload: {
          [GENERAL_SETTING.LANGUAGE]: lang,
          [GENERAL_SETTING.THEME]: theme,
        },
      }),
    );
  }
}
