import { createReducer, on, createSelector, createFeature } from '@ngrx/store';
import { IAppSetting } from '../models/ISetting';
import { settingActions } from './setting.actions';
export const SETTING_KEY = 'appSetting';
export interface AppSetingState {
  setting: IAppSetting | undefined;
  isLoading: boolean | undefined;
  error: any | undefined;
}

const initialState: AppSetingState = {
  setting: undefined,
  isLoading: undefined,
  error: undefined,
};

//#region  reducer
export const settingFeature = createFeature({
  name: SETTING_KEY,
  reducer: createReducer<AppSetingState>(
    initialState,
    on(
      settingActions.getAll,
      (state): AppSetingState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      settingActions.getSuccess,
      (state, action): AppSetingState => ({
        ...state,
        isLoading: false,
        setting: action.payload,
      }),
    ),
    on(
      settingActions.getFailure,
      (state, action): AppSetingState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
    on(
      settingActions.set,
      (state): AppSetingState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      settingActions.setSuccess,
      (state, action): AppSetingState => ({
        ...state,
        isLoading: false,
        setting: action.payload,
      }),
    ),
    on(
      settingActions.settFailure,
      (state, action): AppSetingState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
    on(
      settingActions.updateSetting,
      (state): AppSetingState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      settingActions.updateSettingSuccess,
      (state, action): AppSetingState => ({
        ...state,
        isLoading: false,
        setting: action.payload,
      }),
    ),
    on(
      settingActions.updateSettingtFailure,
      (state, action): AppSetingState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
  ),
});

//#endregion

export const {
  name, // feature name
  reducer, // feature reducer
} = settingFeature;
