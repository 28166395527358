import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SETTING_KEY, AppSetingState } from './setting.reducer';
import { DEFAULT_SETTING } from '@app/viewer';

//#region selector
const selectSetting = createFeatureSelector<AppSetingState>(SETTING_KEY);
const selectAllSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting);
const selectGeneralSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.general);
const selectWorklistSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.worklist);
//const selectWorklistFilterSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.worklist.filterSettings);
const selectWorklistPresetSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.worklist.presetSettings);
const selectUploadSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.upload);
const selectViewerSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.viewer);
const selectHotkeysSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.hotkeys);
const selectUserInfoSetting = createSelector(selectSetting, (state: AppSetingState) => state.setting?.userInfo);
const selectSettingUid = createSelector(selectSetting, (state: AppSetingState) => state.setting?.id);
const selectLayoutByModality = (modality: string) =>
  createSelector(selectSetting, (state: AppSetingState) => {
    if (state.setting?.viewer.layoutModality === undefined) {
      return [];
    }
    if (modality in state.setting?.viewer.layoutModality) {
      return state.setting?.viewer.layoutModality[modality];
    }
    return state.setting?.viewer.layoutModality.default;
  });
const selectLayoutModality = createSelector(selectSetting, (state: AppSetingState) => state.setting?.viewer.layoutModality || DEFAULT_SETTING.layoutModality);
//#endregion
/**
 * Select overlay setting
 */
const selectOverlaySetting = createSelector(selectViewerSetting, (state) => state?.overlay);
/**
 * Select volume overlay setting
 */
const selectVolumeOverlaySetting = createSelector(selectViewerSetting, (state) => state?.overlayVolume);

export const settingQuery = {
  selectSettingUid,
  selectAllSetting,
  selectGeneralSetting,
  selectWorklistSetting,
  selectUploadSetting,
  selectViewerSetting,
  selectHotkeysSetting,
  selectWorklistPresetSetting,
  // selectWorklistFilterSetting,
  selectLayoutByModality,
  selectLayoutModality,
  selectOverlaySetting,
  selectVolumeOverlaySetting,
  selectUserInfoSetting,
};
