<div class="viewer" appFileDropZone (dropped)="onFileDrop($event)">
  <app-viewer-menu class="viewer__menu"></app-viewer-menu>
  <div class="viewer__meta-data" [hidden]="metadataVisible$ | async">
    <app-viewer-patient class="viewer__patient" [studyUid]="selectedStudyUid"></app-viewer-patient>
    <app-viewer-study-list class="viewer__study" (selectedStudyEvent)="onSelectedStudy($event)"></app-viewer-study-list>
    <app-viewer-series-list class="viewer__series" [studyUid]="selectedStudyUid"></app-viewer-series-list>
  </div>
  <app-viewer-viewport class="viewer__viewport"></app-viewer-viewport>

  <app-dicom-info [(isDisplay)]="isDisplayDicomInfo"></app-dicom-info>
  <app-colorbar [(isDisplay)]="isDisplayLUTColorbarDialog"></app-colorbar>
</div>
