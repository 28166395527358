<div class="viewer-menu" #viewerMenu>
  <p-menubar id="vpacs-menu" [model]="menuItemsMain" [autoHide]="true" [autoDisplay]="true" [baseZIndex]="2000" (mouseleave)="onMouseleave()"></p-menubar>
  <ng-container *rxLet="isGuestMode$; let isGuestMode">
    <ng-container *rxIf="isGuestMode === false">
      <div class="nav-item viewer-menu__user" ngbDropdown display="dynamic">
        <a id="user-dropdown" class="nav-link viewer-menu__user-link" ngbDropdownToggle>
          <img class="viewer-menu__user-avatar" [src]="(userInfo$ | async)?.photoURL || ''" alt="." />
          <ng-template #avatarNoExist>
            <i class="fas fa-user-circle"></i>
          </ng-template>
        </a>
        <div ngbDropdownMenu aria-labelledby="user-dropdown">
          <h6 class="dropdown-header">
            <span translate>LoggedInAs</span>
            <b>{{ (userInfo$ | async)?.displayName || '' }}</b>
          </h6>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" [routerLink]="'/worklist'" translate>StudyList</button>
          <button class="dropdown-item" [routerLink]="'/upload'" translate>UploadStudy</button>
          <button class="dropdown-item" [routerLink]="'/viewer'" translate>DICOMViewer</button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" (click)="openSettingDialog()" translate>Setting</button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" (click)="logout()" translate>Logout</button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
