import { Component, OnInit, AfterContentInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger, SharedService } from '@shared';
import { IStudyInfo } from './models/IStudyInfo';
import { Store } from '@ngrx/store';
import { loadWorklist } from './store/worklist.actions';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { worklistQuery } from './store/worklist.selectors';

const log = new Logger('Worklist');
@Component({
  selector: 'app-worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.scss'],
})
export class WorklistComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) {}

  //#region properties

  public patientListInfoID: string;
  public expandedSidebar: boolean;

  //#endregion
  //#region Lifecyle hooks Area
  ngOnInit() {
    this.sharedService.preloader(true, this.translateService.instant('StartLoadWorklistMessage'));
    this.expandedSidebar = true;
  }

  //#endregion
  toggleSidebar() {
    this.expandedSidebar = !this.expandedSidebar;
  }
}
