<div class="content-wrapper">
  <div class="container">
    <h1>プライバシーポリシー</h1>
    <p>最終更新日：2024年5月16日</p>
    <p>
      このプライバシーポリシーは、サービスを利用した際のあなたの情報の収集、使用、開示についての当社の方針と手続きを説明し、あなたのプライバシー権利と法律があなたをどのように保護するかについて説明します。
    </p>
    <p>
      私たちはあなたの個人データを使用してサービスを提供し、改善します。サービスを利用することで、あなたはこのプライバシーポリシーに従って情報を収集し使用することに同意したことになります。このプライバシーポリシーは、
      <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">プライバシーポリシージェネレーター</a>の助けを借りて作成されました。
    </p>
    <h2>解釈と定義</h2>
    <h3>解釈</h3>
    <p>初文字が大文字の単語は、以下の条件で定義された意味を持ちます。以下の定義は、単数または複数で現れるかに関係なく、同じ意味を持ちます。</p>
    <h3>定義</h3>
    <p>このプライバシーポリシーの目的のために：</p>
    <ul>
      <li>
        <p><strong>アカウント</strong>は、私たちのサービスまたはサービスの一部にアクセスするためにあなたのために作成されたユニークなアカウントを意味します。</p>
      </li>
      <li>
        <p>
          <strong>アフィリエイト</strong
          >は、パーティーを制御する、またはパーティーによって制御される、またはパーティーと共通の制御下にあるエンティティを意味します。ここでの「制御」は、取締役または他の管理権限の選挙に投票する権利を有する株式、持分、または他の証券の50％以上を所有することを意味します。
        </p>
      </li>
      <li>
        <p><strong>会社</strong>（この契約では「会社」、「私たち」、「私たち」、「私たち」のいずれかとして参照されます）はRadteamを指します。</p>
      </li>
      <li>
        <p>
          <strong>クッキー</strong
          >は、その多くの用途の中で、そのウェブサイトの閲覧履歴の詳細を含む小さなファイルで、ウェブサイトによってあなたのコンピュータ、モバイルデバイス、またはその他のデバイスに配置されます。
        </p>
      </li>
      <li>
        <p><strong>国</strong>は、ベトナムを指します。</p>
      </li>
      <li>
        <p><strong>デバイス</strong>は、コンピュータ、携帯電話、またはデジタルタブレットなど、サービスにアクセスできる任意のデバイスを意味します。</p>
      </li>
      <li>
        <p><strong>個人データ</strong>は、特定の個人または特定可能な個人に関連する情報を指します。</p>
      </li>
      <li>
        <p><strong>サービス</strong>は、ウェブサイトを指します。</p>
      </li>
      <li>
        <p>
          <strong>サービスプロバイダー</strong
          >は、会社の代わりにデータを処理する任意の自然人または法人を意味します。これは、サービスを容易にするため、会社の代わりにサービスを提供するため、サービスに関連するサービスを実行するため、またはサービスの使用方法を会社が分析するのを助けるために、会社が雇用した第三者の会社または個人を指します。
        </p>
      </li>
      <li>
        <p>
          <strong>サードパーティのソーシャルメディアサービス</strong
          >は、ユーザーがログインしたり、サービスを使用するためのアカウントを作成したりすることができる任意のウェブサイトまたはソーシャルネットワークウェブサイトを指します。
        </p>
      </li>
      <li>
        <p><strong>使用データ</strong>は、サービスの使用によって自動的に収集されるデータ、またはサービスのインフラストラクチャ自体から収集されるデータ（例えば、ページ訪問の期間）を指します。</p>
      </li>
      <li>
        <p><strong>ウェブサイト</strong>は、VPACS Web Viewerを指し、 <a href="https://vpacs.net" rel="external nofollow noopener" target="_blank">https://vpacs.net</a>からアクセスできます。</p>
      </li>
      <li>
        <p><strong>あなた</strong>は、サービスをアクセスまたは使用する個人、またはそのような個人がサービスをアクセスまたは使用する法人、またはその他の法的エンティティを指します。</p>
      </li>
    </ul>
    <h2>あなたの個人データの収集と使用</h2>
    <h3>収集されるデータの種類</h3>
    <h4>個人データ</h4>
    <p>
      私たちのサービスを利用する際、私たちはあなたに連絡を取るため、またはあなたを特定するための特定の個人情報を提供するように求めることがあります。個人的に特定可能な情報には、以下のものが含まれますが、これらに限定されません：
    </p>
    <ul>
      <li><p>メールアドレス</p></li>
      <li><p>名前と姓</p></li>
      <li><p>使用データ</p></li>
    </ul>
    <h4>使用データ</h4>
    <p>使用データは、サービスを使用する際に自動的に収集されます。</p>
    <p>
      使用データには、あなたのデバイスのインターネットプロトコルアドレス（例：IPアドレス）、ブラウザの種類、ブラウザのバージョン、あなたが訪れたサービスのページ、訪問の日時、それらのページで過ごした時間、ユニークなデバイス識別子、その他の診断データなどの情報が含まれる場合があります。
    </p>
    <p>
      モバイルデバイスを通じてまたはそれを介してサービスにアクセスすると、私たちは自動的に特定の情報を収集することがあります。これには、使用しているモバイルデバイスの種類、モバイルデバイスのユニークID、モバイルデバイスのIPアドレス、モバイルオペレーティングシステム、使用しているモバイルインターネットブラウザの種類、ユニークなデバイス識別子、その他の診断データなどが含まれる場合があります。
    </p>
    <p>また、サービスを訪れるたびや、モバイルデバイスを通じてまたはそれを介してサービスにアクセスするときに、あなたのブラウザが送信する情報も収集することがあります。</p>
    <h4>サードパーティのソーシャルメディアサービスからの情報</h4>
    <p>当社は、以下のサードパーティのソーシャルメディアサービスを通じて、あなたがアカウントを作成し、ログインしてサービスを利用することを可能にしています：</p>
    <ul>
      <li>Google</li>
      <li>Facebook</li>
      <li>Instagram</li>
      <li>Twitter</li>
      <li>LinkedIn</li>
    </ul>
    <p>
      サードパーティのソーシャルメディアサービスを通じて登録するか、それ以外の方法で私たちにアクセスを許可することを決定した場合、私たちは、あなたの名前、メールアドレス、活動、またはそのアカウントに関連付けられた連絡先リストなど、あなたのサードパーティのソーシャルメディアサービスのアカウントにすでに関連付けられている個人データを収集することがあります。
    </p>
    <p>
      また、登録時またはそれ以外の場合に、サードパーティのソーシャルメディアサービスのアカウントを通じて会社と追加の情報を共有するオプションを持つことがあります。そのような情報と個人データを提供することを選択した場合、あなたは会社に、このプライバシーポリシーに従ってそれを使用、共有、保存するための許可を与えていることになります。
    </p>
    <h4>トラッキング技術とクッキー</h4>
    <p>
      私たちはクッキーと同様のトラッキング技術を使用して、私たちのサービスでの活動を追跡し、特定の情報を保存します。使用されるトラッキング技術には、ビーコン、タグ、スクリプトなどがあり、これらを使用して情報を収集し追跡し、私たちのサービスを改善し分析します。私たちが使用する技術には以下のものが含まれます：
    </p>
    <ul>
      <li>
        <strong>クッキーまたはブラウザクッキー。</strong>
        クッキーは、あなたのデバイスに配置される小さなファイルです。あなたは、すべてのクッキーを拒否するか、クッキーが送信されているときにそれを示すようにブラウザに指示することができます。ただし、クッキーを受け入れない場合、私たちのサービスの一部を利用できない場合があります。ブラウザの設定を調整してクッキーを拒否するように設定していない限り、私たちのサービスはクッキーを使用することがあります。
      </li>
      <li>
        <strong>ウェブビーコン。</strong>
        私たちのサービスの特定のセクションや私たちのメールには、ウェブビーコン（クリアギフ、ピクセルタグ、シングルピクセルギフとも呼ばれます）と呼ばれる小さな電子ファイルが含まれている場合があります。これにより、例えば、あるページを訪れたユーザーの数を数えたり、メールを開いた数を数えたり、その他の関連するウェブサイトの統計（例えば、特定のセクションの人気度を記録したり、システムとサーバーの完全性を確認したりする）を取得することができます。
      </li>
    </ul>
    <p>
      クッキーは「永続的」または「セッション」のクッキーになることがあります。永続的なクッキーは、あなたがオフラインになったときもあなたの個人コンピュータまたはモバイルデバイスに残りますが、セッションクッキーは、あなたがウェブブラウザを閉じるとすぐに削除されます。クッキーについての詳細は、
      <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">TermsFeedウェブサイト</a>の記事をご覧いただけます。
    </p>
    <p>私たちは以下に示す目的のために、セッションクッキーと永続的なクッキーの両方を使用します：</p>
    <ul>
      <li>
        <p><strong>必要/必須クッキー</strong></p>
        <p>タイプ：セッションクッキー</p>
        <p>管理者：私たち</p>
        <p>
          目的：これらのクッキーは、ウェブサイトを通じて提供されるサービスを提供し、その一部の機能を利用できるようにするために必要です。これらはユーザーの認証を助け、ユーザーアカウントの不正使用を防ぎます。これらのクッキーなしでは、あなたが求めたサービスは提供できず、私たちはこれらのクッキーを使用してあなたにそれらのサービスを提供します。
        </p>
      </li>
      <li>
        <p><strong>クッキーポリシー/通知承認クッキー</strong></p>
        <p>タイプ：永続的なクッキー</p>
        <p>管理者：私たち</p>
        <p>目的：これらのクッキーは、ユーザーがウェブサイト上でクッキーの使用を受け入れたかどうかを識別します。</p>
      </li>
      <li>
        <p><strong>機能性クッキー</strong></p>
        <p>タイプ：永続的なクッキー</p>
        <p>管理者：私たち</p>
        <p>
          目的：これらのクッキーは、ウェブサイトを使用する際にあなたが行う選択を記憶することを可能にします。たとえば、ログイン情報や言語設定を記憶します。これらのクッキーの目的は、よりパーソナライズされた体験を提供し、ウェブサイトを使用するたびに設定を再入力する必要をなくすことです。
        </p>
      </li>
    </ul>
    <p>クッキーについての詳細な情報や、クッキーに関する選択肢については、クッキーポリシーまたはプライバシーポリシーのクッキーのセクションをご覧ください。</p>
    <h3>あなたの個人データの使用</h3>
    <p>会社は以下の目的で個人データを使用することがあります：</p>
    <ul>
      <li>
        <p><strong>サービスの提供と維持：</strong> サービスの使用を監視するため。</p>
      </li>
      <li>
        <p>
          <strong>あなたのアカウントの管理：</strong>
          サービスのユーザーとしてのあなたの登録を管理します。あなたが提供する個人データは、登録ユーザーとして利用可能なサービスの異なる機能にアクセスすることを可能にします。
        </p>
      </li>
      <li>
        <p><strong>契約の履行：</strong> あなたが購入した製品、アイテム、またはサービス、またはサービスを通じて私たちと結んだ他の契約の開発、遵守、および実施。</p>
      </li>
      <li>
        <p>
          <strong>あなたに連絡するため：</strong>
          更新や情報通信に関連する電子メール、電話、SMS、または他の同等の電子通信形式、たとえばモバイルアプリケーションのプッシュ通知を使用してあなたに連絡します。これは、その実装に必要または合理的な場合、例えばセキュリティ更新に関連して行われます。
        </p>
      </li>
      <li>
        <p>
          <strong>あなたに提供するため：</strong>
          あなたがすでに購入したり問い合わせたりしたものと同様の他の商品、サービス、イベントについてのニュース、特別オファー、一般情報を提供します。ただし、あなたがそのような情報を受け取ることを選択しない場合は除きます。
        </p>
      </li>
      <li>
        <p><strong>あなたのリクエストを管理するため：</strong> あなたからのリクエストを受け付け、管理します。</p>
      </li>
      <li>
        <p>
          <strong>ビジネスの移転のため：</strong>
          私たちは、あなたの情報を評価したり、合併、売却、再編、再構築、解散、またはその他の資産の一部または全部の売却または移転を行うために使用することがあります。これは、私たちが保有しているサービスのユーザーに関する個人データが移転される資産の一部である場合、継続的な事業または破産、清算、または類似の手続きの一部として行われます。
        </p>
      </li>
      <li>
        <p>
          <strong>その他の目的のため：</strong>
          私たちは、データ分析、使用傾向の特定、プロモーションキャンペーンの効果の判断、および私たちのサービス、製品、サービス、マーケティング、およびあなたの経験の評価と改善のためなど、他の目的のためにあなたの情報を使用することがあります。
        </p>
      </li>
    </ul>
    <p>私たちは以下の状況であなたの個人情報を共有することがあります：</p>
    <ul>
      <li><strong>サービスプロバイダーと：</strong> 私たちは、サービスの使用を監視し分析するため、またはあなたに連絡するために、あなたの個人情報をサービスプロバイダーと共有することがあります。</li>
      <li>
        <strong>ビジネスの移転のため：</strong>
        私たちは、あなたの個人情報を、合併、会社資産の売却、資金調達、または私たちのビジネスの全部または一部を他の会社に譲渡する際、またはその交渉中に共有または譲渡することがあります。
      </li>
      <li>
        <strong>アフィリエイトと：</strong>
        私たちは、あなたの情報を私たちのアフィリエイトと共有することがあります。その場合、私たちはこれらのアフィリエイトにこのプライバシーポリシーを尊重するよう要求します。アフィリエイトには、私たちの親会社、その他の子会社、ジョイントベンチャーパートナー、または私たちが制御する、または私たちと共通の制御下にある他の会社が含まれます。
      </li>
      <li><strong>ビジネスパートナーと：</strong> 私たちは、あなたに特定の製品、サービス、またはプロモーションを提供するために、あなたの情報を私たちのビジネスパートナーと共有することがあります。</li>
      <li>
        <strong>他のユーザーと：</strong>
        あなたが個人情報を共有したり、他のユーザーと公開エリアで相互作用したりすると、その情報はすべてのユーザーに見られ、外部に公開的に配布される可能性があります。サードパーティのソーシャルメディアサービスを通じて他のユーザーと相互作用したり、登録したりすると、サードパーティのソーシャルメディアサービスのあなたの連絡先はあなたの名前、プロフィール、写真、およびあなたの活動の説明を見ることができます。同様に、他のユーザーはあなたの活動の説明を見ることができ、あなたとコミュニケーションを取ることができ、あなたのプロフィールを見ることができます。
      </li>
      <li><strong>あなたの同意により：</strong> あなたの同意がある場合、私たちは他の目的であなたの個人情報を開示することがあります。</li>
    </ul>
    <h3>あなたの個人データの保持</h3>
    <p>
      会社は、このプライバシーポリシーで定められた目的のために必要な期間だけ、あなたの個人データを保持します。私たちは、法的義務を遵守するため（例えば、適用される法律によりあなたのデータを保持することが必要な場合）、紛争を解決し、法的契約とポリシーを強制するために必要な範囲で、あなたの個人データを保持し使用します。
    </p>
    <p>
      会社はまた、内部分析の目的のために使用データを保持します。使用データは一般的に短期間保持されますが、このデータが私たちのサービスのセキュリティを強化するため、または私たちのサービスの機能を改善するため、または私たちはこのデータをより長い期間保持することが法的に義務付けられている場合には、このデータが使用されます。
    </p>
    <h3>あなたの個人データの転送</h3>
    <p>
      あなたの情報、個人データを含む、は、会社の運営オフィスおよび処理に関与する当事者が所在する他の場所で処理されます。これは、この情報があなたの州、県、国、または他の政府管轄区域から離れた場所にあるコンピュータに転送され、保管されることを意味します。これらの場所では、データ保護法があなたの管轄区域のものと異なる場合があります。
    </p>
    <p>あなたがこのプライバシーポリシーに同意し、その後にそのような情報を提出することは、あなたがその転送に同意したことを表します。</p>
    <p></p>
    <p>
      会社は、あなたのデータがこのプライバシーポリシーに従って安全に取り扱われ、あなたのデータと他の個人情報の安全性を含む適切な管理が行われている組織または国へのあなたの個人データの移転が行われないよう、合理的に必要なすべての手段を講じます。
    </p>
    <h3>あなたの個人データの削除</h3>
    <p>あなたは、私たちがあなたについて収集した個人データを削除する権利を持っています、または私たちがそれを削除するのを手伝うよう要求する権利を持っています。</p>
    <p>私たちのサービスは、私たちがあなたについて収集した特定の情報を削除する能力をあなたに提供することがあります。</p>
    <p>
      あなたはいつでも、アカウントにサインインして、あなたの個人情報を管理することができるアカウント設定のセクションを訪れることで、あなたの情報を更新、修正、または削除することができます。また、私たちに連絡して、あなたが私たちに提供した個人情報にアクセスしたり、それを修正したり、削除したりすることもできます。
    </p>
    <p>ただし、私たちが法的義務を果たすため、または合法的な根拠がある場合、特定の情報を保持する必要があることに注意してください。</p>
    <h3>あなたの個人データの開示</h3>
    <h4>ビジネストランザクション</h4>
    <p>
      会社が合併、買収、または資産売却に関与している場合、あなたの個人データは移転される可能性があります。あなたの個人データが移転され、異なるプライバシーポリシーの対象となる前に、私たちは通知を提供します。
    </p>
    <h4>法執行機関</h4>
    <p>特定の状況下では、会社は法律により要求された場合、または公的機関（例えば、裁判所や政府機関）からの有効な要求に応じて、あなたの個人データを開示することが求められる場合があります。</p>
    <h4>その他の法的要件</h4>
    <p>会社は、以下の行動が必要であると信じる善意に基づいて、あなたの個人データを開示することがあります：</p>
    <ul>
      <li>法的義務を遵守するため</li>
      <li>会社の権利または財産を保護し防御するため</li>
      <li>サービスに関連する不正行為を防止または調査するため</li>
      <li>サービスのユーザーや公衆の個人の安全を保護するため</li>
      <li>法的責任から保護するため</li>
    </ul>
    <h3>あなたの個人データの安全性</h3>
    <p>
      あなたの個人データの安全性は私たちにとって重要ですが、インターネット上での伝送、または電子ストレージの方法は100％安全であることを覚えておいてください。私たちはあなたの個人データを保護するために商業的に受け入れられる手段を使用して努力していますが、その絶対的な安全性を保証することはできません。
    </p>
    <h2>子供のプライバシー</h2>
    <p>
      私たちのサービスは13歳未満の方を対象としていません。私たちは意図的に13歳未満の方から個人を特定できる情報を収集しません。あなたが親または保護者であり、あなたの子供が私たちに個人データを提供したことを知っている場合は、私たちに連絡してください。私たちは、親の同意の確認なしに13歳未満の方から個人データを収集したことを知った場合、その情報を私たちのサーバーから削除する手続きを行います。
    </p>
    <p>私たちがあなたの情報を処理する法的根拠として同意に頼る必要があり、あなたの国が親の同意を必要とする場合、私たちはその情報を収集し使用する前にあなたの親の同意が必要になる場合があります。</p>
    <h2>他のウェブサイトへのリンク</h2>
    <p>
      私たちのサービスには、私たちが運営していない他のウェブサイトへのリンクが含まれている場合があります。第三者のリンクをクリックすると、あなたはその第三者のサイトに移動します。私たちは、あなたが訪れるすべてのサイトのプライバシーポリシーを確認することを強くお勧めします。
    </p>
    <p>私たちは、第三者のサイトやサービスのコンテンツ、プライバシーポリシー、または慣行について一切の管理権を持っておらず、その責任を負いません。</p>
    <h2>このプライバシーポリシーの変更</h2>
    <p>私たちは時々、私たちのプライバシーポリシーを更新することがあります。新しいプライバシーポリシーをこのページに掲載することで、変更をお知らせします。</p>
    <p>変更が有効になる前に、私たちはあなたにメールや/または私たちのサービス上の目立つ通知を通じてお知らせし、このプライバシーポリシーの「最終更新日」を更新します。</p>
    <p>あなたは定期的にこのプライバシーポリシーを確認することをお勧めします。このプライバシーポリシーの変更は、このページに掲載されたときに有効になります。</p>
    <h2>お問い合わせ</h2>
    <p>このプライバシーポリシーについてのご質問がある場合は、以下の方法でお問い合わせいp>あなたが私たちに提供した情報について、以下の方法でお問い合わせいただけます：</p>
    <ul>
      <li>メールによるお問い合わせ：radteam.vpacs&#64;gmail.com</li>
    </ul>
  </div>
</div>
