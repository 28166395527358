import { DOWNLOAD_SHARE_URL, DOWNLOAD_URL } from '@app/@core/contants/google';
import { metaData } from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { IImage, IImagePrefetch } from '../models/IImage';
import { IMAGE_LOADER } from '../contants/cornerstone';
import { IImageUploadInfo } from '@app/upload/models/ISeriesUpload';
import { ImageUploadFileType } from '@app/upload';
/**
 * Preloads image metadata in memory.
 * @param {string[]} imageIdsToPrefetch - An array of image IDs to preload.
 * @returns {Promise<any[]>} A promise that resolves to an array of preloaded images.
 */
const prefetchMetadataInformation = async (imageIdsToPrefetch: string[]): Promise<void> => {
  for (let i = 0; i < imageIdsToPrefetch.length; i++) {
    await cornerstoneDICOMImageLoader.wadouri.loadImage(imageIdsToPrefetch[i]).promise;
  }
};

/**
 * Preloads a single image's metadata in memory.
 * @param {string} imageIdToPrefetch - The image ID to preload.
 * @returns {Promise<any>} A promise that resolves to the preloaded image.
 */
const prefetchSingleMetadataInformation = async (imageIdToPrefetch: string): Promise<void> => {
  await cornerstoneDICOMImageLoader.wadouri.loadImage(imageIdToPrefetch).promise;
};

/**
 * Preloads a single image's metadata in memory and return promise for business handler.
 * @param {string} imageIdToPrefetch - The image ID to preload.
 * @returns {Promise<IImage>} A promise of {@link IImage}.
 */
const prefetchSingleMetadataInformationPromise = async (imageIdToPrefetch: string): Promise<IImage> => {
  return cornerstoneDICOMImageLoader.wadouri.loadImage(imageIdToPrefetch).promise;
};

/**
 * Preloads image metadata and additional information in memory.
 * @param {string[]} imageIdsToPrefetch - An array of image IDs to preload.
 * @returns {Promise<IImage[]>} A promise that resolves to an array of preloaded images {@link IImage} with additional information.
 */
const prefetchImagesInformation = async (imageIdsToPrefetch: string[]): Promise<IImage[]> => {
  try {
    const images: any = [];
    for (let i = 0; i < imageIdsToPrefetch.length; i++) {
      const image = await cornerstoneDICOMImageLoader.wadouri.loadImage(imageIdsToPrefetch[i]).promise;
      const frame = convertMultiframeImageId(imageIdsToPrefetch[i]);
      if (frame.length > 1) {
        image['frameId'] = frame;
      }
      const fileId = cornerstoneDICOMImageLoader.wadouri.parseImageId(imageIdsToPrefetch[i]);
      const fileStored = cornerstoneDICOMImageLoader.wadouri.fileManager.get(fileId.url);
      image['file'] = fileStored;
      images.push(image);
    }
    return images;
  } catch (error) {
    return [];
  }
};

/**
 * Receives a list of imageids possibly referring to multiframe dicom images
 * and returns a list of imageid where each imageid referes to one frame.
 * For each imageId representing a multiframe image with n frames,
 * it will create n new imageids, one for each frame, and returns the new list of imageids
 * If a particular imageid no refer to a mutiframe image data, it will be just copied into the new list
 * @returns new list of imageids where each imageid represents a frame
 */
const convertMultiframeImageIds = (imageIds: string[]): string[] => {
  // Adds frameless images to the list of image ids. Does not add images that are part of a multiframe
  const newImageIds = Array();
  imageIds.forEach((imageId) => {
    const instanceMetaData = metaData.get('multiframeModule', imageId);
    if (instanceMetaData && instanceMetaData.NumberOfFrames && instanceMetaData.NumberOfFrames > 1) {
      const { imageIdFrameless } = getFrameInformation(imageId);
      const NumberOfFrames = instanceMetaData.NumberOfFrames;
      for (let i = 0; i < NumberOfFrames; i++) {
        const newImageId = imageIdFrameless + (i + 1);
        newImageIds.push(newImageId);
      }
    } else {
      newImageIds.push(imageId);
    }
  });
  return newImageIds;
};

/**
 * Converts a multiframe image ID into an array of image IDs.
 * If the input image ID is part of a multiframe, it generates image IDs for each frame.
 * If the input image ID is frameless, it simply returns the input image ID.
 * @param imageId The image ID to convert.
 * @returns An array of image IDs.
 */
const convertMultiframeImageId = (imageId: string): string[] => {
  // Adds frameless images to the list of image ids. Does not add images that are part of a multiframe
  const newImageIds = Array();

  const instanceMetaData = metaData.get('multiframeModule', imageId);
  if (instanceMetaData && instanceMetaData.NumberOfFrames && instanceMetaData.NumberOfFrames > 1) {
    const { imageIdFrameless } = getFrameInformation(imageId);
    const NumberOfFrames = instanceMetaData.NumberOfFrames;
    for (let i = 0; i < NumberOfFrames; i++) {
      const newImageId = imageIdFrameless + (i + 1);
      newImageIds.push(newImageId);
    }
  } else {
    newImageIds.push(imageId);
  }

  return newImageIds;
};

/**
 * Checks if the DICOM image with the given imageId is a multi-frame image.
 * @param imageId - The ID of the DICOM image.
 * @returns A boolean indicating whether the image is multi-frame or not.
 */
const isMultiFrame = (imageId: string): boolean => {
  const instanceMetaData = metaData.get('multiframeModule', imageId);
  if (instanceMetaData && instanceMetaData.NumberOfFrames && instanceMetaData.NumberOfFrames > 1) {
    return true;
  }
  return false;
};

/**
 * Retrieves frame information from the given imageId.
 * If the imageId contains the prefix 'wadors:', it adds the frame parameter to the URL.
 * If the imageId does not contain the prefix 'wadors:', it adds the frame parameter to prevent collisions.
 * @param imageId The ID of the image.
 * @returns An object containing the frame index and the imageId without the frame parameter.
 */
const getFrameInformation = (imageId: string): { frameIndex: number; imageIdFrameless: string } => {
  // Adds frame parameter if wadors : prefix is present in the URL. Otherwise adds frame
  if (imageId.includes('wadors:')) {
    const frameIndex = imageId.indexOf('/frames/');
    const imageIdFrameless = frameIndex > 0 ? imageId.slice(0, frameIndex + 8) : imageId;
    return {
      frameIndex,
      imageIdFrameless,
    };
  } else {
    // Adds frame parameter if it's not present in the imageId. This is used to prevent collisions with frame =
    const frameIndex = imageId.indexOf('&frame=');
    let imageIdFrameless = frameIndex > 0 ? imageId.slice(0, frameIndex + 7) : imageId;
    if (!imageIdFrameless.includes('&frame=')) {
      imageIdFrameless = imageIdFrameless + '&frame=';
    }
    return {
      frameIndex,
      imageIdFrameless,
    };
  }
};

/**
 * Generates a download link for the given images.
 *
 * @param images - The images to generate the download link for.
 * @returns The download link URL.
 */
const generateDownloadLink = (images: IImageUploadInfo, isLogin: boolean = true): string => {
  let url = isLogin ? DOWNLOAD_URL(images.driveId) : DOWNLOAD_SHARE_URL(images.driveId);
  // prefix the url with wadouri: so cornerstone can find the image loader
  url = IMAGE_LOADER.WADO_URI + url;
  return url;
};

/**
 * Generates download links for the given array of images.
 * @param images - An array of IImagePrefetch objects representing the images.
 * @param isLogin - A boolean indicating whether the user is logged in.
 * @returns An array of strings representing the download links.
 */
const generateDownloadLinks = (images: IImageUploadInfo[], isLogin: boolean = true): string[] => {
  const downloadUrl: string[] = [];
  for (let index = 0, length = images.length; index < length; index++) {
    const image = images[index];
    let url = isLogin ? DOWNLOAD_URL(image.driveId) : DOWNLOAD_SHARE_URL(image.driveId);
    // prefix the url with wadouri: so cornerstone can find the image loader
    // if (image.fileType === ImageUploadFileType.dcm) {
    //   url = IMAGE_LOADER.WADO_URI + url;
    // }

    downloadUrl.push(url);
  }
  return downloadUrl;
};

export {
  generateDownloadLink,
  generateDownloadLinks,
  convertMultiframeImageIds,
  convertMultiframeImageId,
  prefetchMetadataInformation,
  prefetchSingleMetadataInformation,
  prefetchImagesInformation,
  prefetchSingleMetadataInformationPromise,
  isMultiFrame,
};
