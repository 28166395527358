import { createSelector } from '@ngrx/store';
import { selectViewerCornerstoneEntities } from '..';
import { CornerstoneState } from './cornerstone.reducer';

/**
 * Selects the volume IDs from the Cornerstone state.
 *
 * @param state - The Cornerstone state.
 * @returns An array of volume IDs.
 */
const selectVolumeIds = createSelector(selectViewerCornerstoneEntities, (state: CornerstoneState) => state.volumeIds);

/**
 * Selects the volume ID associated with the given series UID.
 * @param seriesUid The series UID.
 * @returns The volume ID.
 */
const selectVolumeBySeriesUid = (seriesUid: string) => createSelector(selectVolumeIds, (state) => state.find((id) => id.includes(seriesUid)) || '');

/**
 * Selects the volume IDs that match the given modality.
 *
 * @param modality - The modality to filter the volume IDs by.
 * @returns An array of volume IDs that match the given modality.
 */
const selectVolumeByModality = (modality: string) => createSelector(selectVolumeIds, (state) => state.filter((id) => id.includes(modality)));

export const CornerstoneQuery = {
  selectVolumeIds,
  selectVolumeBySeriesUid,
  selectVolumeByModality,
};
