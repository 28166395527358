import { Component } from '@angular/core';
/**
 * Represents a confirmation dialog component.
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {}
