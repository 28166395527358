<div #scrollTarget class="viewer-series">
  <span *rxFor="let thumbnail of thumbnail$" class="viewer-series__item">
    <div class="viewer-series__item__overlay noselect">
      <div id="topLeft" class="viewer-series__item__overlay__top-left">
        <p class="viewer-series__item__overlay__p">{{ thumbnail.seriesNumber }}</p>
        <ng-container *rxIf="thumbnail.isDownloading">
          <p class="viewer-series__item__overlay__loading" translate>isLoading</p>
        </ng-container>
      </div>
      <div id="topLeft" class="viewer-series__item__overlay__top-right">
        <p class="viewer-series__item__overlay__p">{{ thumbnail.totalImage }}</p>
      </div>
      <div id="topLeft" class="viewer-series__item__overlay__bottom-left">
        <p class="viewer-series__item__overlay__p" [title]="thumbnail.seriesDescription">
          {{ thumbnail.seriesDescription }}
        </p>
        <!-- <p class="viewer-series__item__overlay__p" [title]="thumbnail.seriesDescription"> aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</p> -->
      </div>
    </div>
    <span [class]="(activeSeries$ | async) === thumbnail.uid ? 'viewer-series__item-currently' : ''">
      <span [class]="selectedSeriesUID === thumbnail.uid ? 'viewer-series__item-selected' : ''">
        <img
          crossorigin
          [id]="thumbnail.uid"
          [src]="thumbnail.thumbnail"
          class="img-fluid rounded-top"
          width="112"
          height="112"
          alt=""
          loading="lazy"
          (click)="onClickThumbnail(thumbnail.uid)"
          pDraggable="series"
          (onDragStart)="dragStart($event, thumbnail)"
        />
      </span>
    </span>
  </span>
</div>
