import { IOverlay, IOverlayItem } from '@app/setting';
import { ITileOverlay, ITileOverlayItem } from '@app/viewer/models';

/**
 * Converts an IOverlay object to an ITileOverlay object.
 * @param setting - The IOverlay object to convert.
 * @returns The converted ITileOverlay object.
 */
const settingToOverlay = (setting: IOverlay): ITileOverlay => {
  let stackOverlay: ITileOverlay = {
    topLeft: [],
    topRight: [],
    bottomLeft: [],
    bottomRight: [],
  };
  for (const [key, value] of Object.entries(setting)) {
    const overlayItems = value as IOverlayItem[];
    overlayItems.forEach((item) => {
      const stackOverlayItem: ITileOverlayItem = {
        id: item.name !== '' ? item.name : item.tag,
        ...item,
      };
      stackOverlay[key].push(stackOverlayItem);
    });
  }
  return stackOverlay;
};

/**
 * Checks if an overlay name exists in the given overlay object.
 * @param overlay - The overlay object to search in.
 * @param overlayName - The name or ID of the overlay to check for existence.
 * @returns A boolean indicating whether the overlay name exists in the overlay object.
 */
const isExistOverlayName = (overlay: ITileOverlay, overlayName: string): boolean => {
  for (const overlayItems of Object.values(overlay)) {
    if (!Array.isArray(overlayItems)) continue;
    for (const item of overlayItems) {
      if ([item.name, item.id].includes(overlayName)) return true;
    }
  }
  return false;
};
export { settingToOverlay, isExistOverlayName };
