import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewerComponent } from './viewer.component';
import { ViewerMenuComponent } from './pages/menu/menu.component';
import { ViewerPatientComponent } from './pages/patient/patient.component';
import { ViewerStudyComponent } from './pages/study/study.component';
import { SeriesListComponent } from './pages/series/series.component';
import { ViewerViewportComponent } from './pages/viewport/viewport.component';
import { ViewerRoutingModule } from './viewer-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@app/i18n';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DragDropModule } from 'primeng/dragdrop';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { PreloadService } from './services/preload.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StackComponent } from './pages/viewport/stack/stack.component';
import { PanelComponent } from './pages/viewport/panel/panel.component';

import { VIEWER_KEY, viewerReducer } from './store';
import { ViewerGeneralEffects } from './store/general/general.effects';
import { ViewerLayoutEffects } from './store/layout/layout.effect';
import { LayoutService } from './services/layout.service';
import { SynchronizerService } from './services/synchronizer.service';
import { ToolsService } from './services/tools.service';
import { CornerstoneEffects } from './store/cornerstone/cornerstone.effects';
import { RxLet } from '@rx-angular/template/let';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';

import { RxRenderStrategiesConfig, RX_RENDER_STRATEGIES_CONFIG } from '@rx-angular/cdk/render-strategies';
import { MenuEffects } from './store/menu/menu.effects';
import { BroadcastService } from './services/broadcast.service';
import { DicomInfoComponent } from './pages/common/dicom-info/dicom-info.component';
import { TileComponent } from './pages/viewport/tile/tile.component';
import { ColorbarComponent } from './pages/common/colorbar/colorbar.component';
import { AutoPlayComponent } from './pages/common/auto-play/auto-play.component';
import { WorklistService } from './services';

const FEATURE_RX_ANGULAR_CONFIG: RxRenderStrategiesConfig<string> = {
  primaryStrategy: 'immediate',
  patchZone: false,
};

@NgModule({
  declarations: [
    ViewerComponent,
    ViewerMenuComponent,
    ViewerPatientComponent,
    ViewerStudyComponent,
    ViewerViewportComponent,
    SeriesListComponent,
    StackComponent,
    PanelComponent,
    TileComponent,
    DicomInfoComponent,
    ColorbarComponent,
    AutoPlayComponent,
  ],
  imports: [
    CommonModule,
    ViewerRoutingModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    RouterModule,
    SharedModule,
    RxFor,
    RxLet,
    RxIf,
    MenubarModule,
    TableModule,
    ButtonModule,
    ContextMenuModule,
    DragDropModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    StoreModule.forFeature(VIEWER_KEY, viewerReducer),
    EffectsModule.forFeature([ViewerGeneralEffects, ViewerLayoutEffects, CornerstoneEffects, MenuEffects]),
  ],
  providers: [
    {
      provide: RX_RENDER_STRATEGIES_CONFIG,
      useValue: FEATURE_RX_ANGULAR_CONFIG,
    },

    PreloadService,
    LayoutService,
    ToolsService,
    SynchronizerService,
    BroadcastService,
    WorklistService,
  ],
})
export class ViewerModule {}
