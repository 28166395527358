import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoPlay } from '@app/viewer/contants';
import { BroadcastService } from '@app/viewer/services';
import { viewerMenuActions, ViewerMenuQuery } from '@app/viewer/store/menu';
import { Store } from '@ngrx/store';
interface DropDownType {
  name: string;
  code: string;
}
const PI_PLAY = 'pi pi-play';
const PI_STOP = 'pi pi-stop';
@Component({
  selector: 'app-auto-play',
  templateUrl: './auto-play.component.html',
  styleUrl: './auto-play.component.scss',
})
export class AutoPlayComponent implements OnInit, OnDestroy {
  public autoScrollImageDialogVisibility: boolean = false;
  public selectedAutoScrollImageFPS: DropDownType;

  readonly AUTO_PLAY: typeof AutoPlay = AutoPlay;

  protected playPauseIcon = 'pi pi-play';
  protected autoScrollImageFPSs: DropDownType[] = [
    { name: '2 FPS', code: '2' },
    { name: '4 FPS', code: '4' },
    { name: '5 FPS', code: '5' },
    { name: '10 FPS', code: '10' },
    { name: '20 FPS', code: '20' },
    { name: '30 FPS', code: '30' },
  ];
  protected isScrollLoopActive$;

  private _autoPlaySubscription$;
  constructor(
    private broadcastService: BroadcastService,
    private store: Store,
  ) {
    this.selectedAutoScrollImageFPS = this.autoScrollImageFPSs[3];
    this.isScrollLoopActive$ = this.store.select(ViewerMenuQuery.selectIsScrollLoop);
  }

  ngOnInit(): void {
    this._autoPlaySubscription$ = this.broadcastService.autoPlay.subscribe((payload) => {
      switch (payload.action) {
        case AutoPlay.Stop:
          this.playPauseIcon = PI_PLAY;
          break;
        case AutoPlay.Open:
          this.openAutoScrollImageDialog();
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this._autoPlaySubscription$?.unsubscribe();
  }

  /**
   * Opens the auto scroll image dialog.
   */
  public openAutoScrollImageDialog = () => {
    this.autoScrollImageDialogVisibility = true;
  };

  /**
   * Closes the auto scroll image dialog.
   */
  public closeAutoScrollImageDialog = () => {
    this.autoScrollImageDialogVisibility = false;
  };

  /**
   * Handles the auto play action.
   *
   * @param action - The auto play action.
   */
  protected onAutoPlay = (action: AutoPlay): void => {
    this.broadcastService.autoPlayBroadcast(action, parseInt(this.selectedAutoScrollImageFPS.code));
    if (action === AutoPlay.Play) {
      if (this.playPauseIcon === PI_PLAY) {
        this.playPauseIcon = PI_STOP;
      } else {
        this.playPauseIcon = PI_PLAY;
      }
    }

    if (action === AutoPlay.Loop) {
      this.store.dispatch(viewerMenuActions.changeScrollLoop());
    }
  };
}
