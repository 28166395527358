import {
  IGeneralSeries,
  IImagePrefetch,
  Priority,
  Series,
  THUMBNAIL_VIEW_URL,
  generateDownloadLink,
  generateDownloadLinks,
  sortByAcquisitionNumber,
  sortByCreationTime,
  sortByEchoTime,
  sortByInstanceNumber,
  sortBySliceLocation,
} from '@app/@core';
import { ISeriesViewer } from './ISeriesViewer';
import { Provider } from '../contants/load-image';
import { SortOrder } from '../contants/sort';
import { IImageUploadInfo, ISeriesUploadInfo } from '@app/upload/models/ISeriesUpload';
import { IStackImage } from './IStackImage';
import { ISeriesInfo } from './ISeriesInfo';

/**
 * Represents a viewer for a series of images.
 */
export class SeriesViewer extends Series implements ISeriesViewer {
  series: ISeriesUploadInfo;
  imagesUploaded: IImageUploadInfo[];
  imagePrefetch: IImagePrefetch[];
  provider: Provider;
  priority: Priority;
  isLogin: boolean;
  constructor(uid: string, metadata: IGeneralSeries, modality: string) {
    super(uid, metadata, modality);
    this.imagePrefetch = [];
  }

  /**
   * Retrieves the next pending image from the series viewer's image queue.
   * @returns The next pending image, or undefined if the queue is empty.
   */
  getPendingImage(): IImageUploadInfo | undefined {
    const image = this.imagesUploaded.shift();
    return image;
  }

  /**
   * Adds an image prefetch to the series viewer.
   * @param image - The image prefetch to add.
   */
  addImagePrefetch(image: IImagePrefetch): void {
    // If input data is multi frame, convert each frame into one image prefetch to display on viewport
    if (image.isMultiFrame && image.frameId) {
      //Create each frame is one image prefetch to display on viewport
      image.frameId.forEach((frameId) => {
        const newImage = { ...image };
        newImage.imageId = frameId;
        this.imagePrefetch.push(newImage);
      });
    } else {
      //otherwise normal image
      this.imagePrefetch.push(image);
    }
  }

  /**
   * Converts the given DICOM series information into the corresponding viewer data.
   * @param series The DICOM series information to convert.
   */
  fromDicomDir(series: ISeriesUploadInfo, priority: Priority, isLogin: boolean): void {
    this.series = series;
    this.imagesUploaded = series.images;
    this.priority = priority;
    this.provider = Provider.Google;
    this.isLogin = isLogin;
    const thumbnailUrl = THUMBNAIL_VIEW_URL(series.thumbnail);
    this.addThumbnail(thumbnailUrl);
  }

  /**
   * Converts the SeriesViewer instance to an IStackImage object to store on Redux from dicom dir input.
   * @returns The converted IStackImage object.
   */
  toStoreStackFromDicomDir(priority: Priority): IStackImage {
    const newDownloadUrl = generateDownloadLinks(this.imagesUploaded, this.isLogin);
    return {
      uid: this.uid,
      imageDriveId: newDownloadUrl,
      imagePrefetch: [],
      tags: [],
      provider: this.provider,
      totalImage: this.series.totalImages,
      priority,
      modality: this.modality,
    };
  }

  /**
   * Converts the SeriesViewer instance to an IStackImage object to store on redux from dicom file input.
   * @returns The converted IStackImage object.
   */
  toStoreStackFromFile(): IStackImage {
    return {
      uid: this.uid,
      provider: this.provider,
      imagePrefetch: this.imagePrefetch,
      tags: this.imagePrefetch.map((image) => {
        return { id: image.imageId, value: image.tag, isMultiFrame: image.isMultiFrame };
      }),
      totalImage: this.imagePrefetch.length,
      modality: this.modality,
    };
  }

  /**
   * Converts the series viewer information into a format suitable for storing.
   * @param studyUid The UID of the study associated with the series.
   * @returns The converted series information.
   */
  toStoreSeries(studyUid: string, provider: Provider): ISeriesInfo {
    return {
      uid: this.uid,
      studyUid: studyUid,
      thumbnail: this.thumbnail,
      priority: this.priority,
      modality: this.modality,
      seriesNumber: provider === Provider.Local ? this.imagePrefetch[0].tag.general.seriesNumber : this.series.metadata.seriesNumber?.toString() || '0',
      totalImage: provider === Provider.Local ? this.imagePrefetch.length.toString() : this.series.totalImages.toString(),
      seriesDescription: provider === Provider.Local ? this.imagePrefetch[0].tag.general.seriesDescription || '' : this.series.metadata.seriesDescription || '',
      isDownloading: provider === Provider.Local ? false : true,
    };
  }

  /**
   * Sorts the imagePrefetch array by acquisition number in either ascending or descending order.
   * @param order - The sort order. If not provided, the default is ascending order.
   */
  override sortByAcquisitionNumber(order?: SortOrder): void {
    sortByAcquisitionNumber(order || SortOrder.Ascending, this.imagePrefetch);
  }

  /**
   * Sorts the imagePrefetch array by creation time.
   * @param order - The sort order. If not specified, the default is ascending order.
   */
  override sortByCreationTime(order?: SortOrder): void {
    sortByCreationTime(order || SortOrder.Ascending, this.imagePrefetch);
  }

  /**
   * Sorts the imagePrefetch array by instance number in either ascending or descending order.
   * If the order parameter is not provided, the default sorting order is ascending.
   *
   * @param order - The sorting order. Can be either SortOrder.Ascending or SortOrder.Descending.
   */
  override sortByInstanceNumber(order?: SortOrder): void {
    sortByInstanceNumber(order || SortOrder.Ascending, this.imagePrefetch);
  }

  /**
   * Sorts the imagePrefetch array by echo time in either ascending or descending order.
   * Echo time is not available for all image types, so it is checked before sorting.
   *
   * @param order - The sort order. If not specified, the default is ascending order.
   */
  override sortByEchoTime(order?: SortOrder): void {
    sortByEchoTime(order || SortOrder.Ascending, this.imagePrefetch);
  }

  /**
   * Sorts the imagePrefetch array by slice location in either ascending or descending order.
   * @param order - The sort order. If not specified, the default is ascending order.
   */
  override sortBySliceLocation(order?: SortOrder): void {
    sortBySliceLocation(order || SortOrder.Ascending, this.imagePrefetch);
  }
}
