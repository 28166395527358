export const DEFAULT_STACK_LAYOUT = '2x2';
export const DEFAULT_PANEL_LAYOUT = '1x1';
export const DEFAULT_PANEL_VOLUME_2D_LAYOUT = '1x2';
export const DEFAULT_STACK_VOLUME_2D_LAYOUT = '1x1';
export const DEFAULT_PANEL_VOLUME_LAYOUT = '1x1';
export const DEFAULT_STACK_VOLUME_LAYOUT = '1x1';
export const DEFAULT_TILE_LAYOUT = '1x1';
export const DEFAULT_VOLUME_TILE_LAYOUT = '3V0H';

export const PANEL_LAYOUT_INDEX = 0;
export const PANEL_VOLUME_LAYOUT_INDEX = 1;
export const STACK_LAYOUT_INDEX = 1;
export const TILE_LAYOUT_INDEX = 2;
export const LAYOUT_ROWS_INDEX = 0;
export const LAYOUT_COLS_INDEX = 2;

export const LAYOUT_SEPARATOR = 'x';
export const VIEWPORT_INDEX_SEPERATOR = '-';

export const MPR_LAYOUT = {
  '3V0H': '3V0H',
  '2H1V': '2H1V',
  '3H1V': '3H1V',
  '3H1D': '3H1D',
  '1D0D': '1D0D',
};

export const FUSION_LAYOUT = {
  '3H3V1V': '3H3V1V',
  '2V2H': '2V2H',
  '2H3V': '2H3V',
  '2H2V': '2H2V',
  '3V0H': '3V0H',
  '4V0H': '4V0H',
};

export enum BackupMode {
  Volume,
  FullImage,
}
