/**
 * Represents the available providers for loading images.
 */
export enum Provider {
  //Local input dicom file(s) from user pc
  Local,
  //Load dicom dir from google drive
  Google,
  //Load dicom dir from On-premise server
  Onpremise,
}
