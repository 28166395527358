/**
 * An enumeration of different priority levels.
 * @readonly
 * @enum {number}
 */
export enum Priority {
  /**
   * Indicates the highest priority level.
   */
  Urgent = 0,
  /**
   * Indicates a critical priority level.
   */
  Critical,
  /**
   * Indicates a high priority level.
   */
  High,
  /**
   * Indicates a medium priority level.
   */
  Medium,
  /**
   * Indicates a low priority level.
   */
  Low,
  /**
   * Indicates no priority level.
   */
  NoPriority,
}
