import { IPreset } from '@app/worklist';
import { IHotKey } from './IHotkey';
import { Theme } from '@app/@core';
import { IBase } from '@app/@database';

export const SETTING = {
  WORKLIST: 'worklist',
  GENERAL: 'general',
  HOTKEYS: 'hotkeys',
  UPLOAD: 'upload',
  VIEWER: 'viewer',
  REPORT: 'report',
  USER_INFO: 'userInfo',
};
export const WORKLIST_SETTING = {
  //FILTER: 'filterSettings',
  PRESET: 'presetSettings',
};
export const GENERAL_SETTING = {
  LANGUAGE: 'language',
  THEME: 'theme',
};

/**
 * Represents the worklist settings.
 */
export interface IWorklistSetting {
  presetSettings: IPreset[];
}

export interface IUserInfo {
  name: string;
  title: string;
  institution: string;
  signatureUrl: string;
  lastUpdateDate: string;
}
/**
 * Represents the general settings.
 */
export interface IGeneralSetting {
  language: string;
  theme: Theme;
}

/**
 * Represents the upload settings.
 */
export interface IUploadSetting {
  uploadSettings: [];
}

/**
 * Represents the interface for hotkey settings.
 */
export interface IHotKeySetting {
  platform: string;
  macOSKey: IHotKey[];
  windowsKey: IHotKey[];
}

/**
 * Represents the viewer settings.
 */
export interface IViewerSetting {
  textColor: string;
  userSetting: { isFullRoot: boolean };
  layoutModality: ILayoutModality;
  overlay: IOverlay;
  overlayVolume: IOverlay;
  windowLevelPresets: IWindowLevelPresets;
}
export interface IWindowLevelPresets {
  [key: string]: IWindowLevelPresetItem[];
}
export interface IWindowLevelPresetItem {
  name: string;
  ww: number;
  wl: number;
  shortkey: string;
}
export interface IOverlay {
  topLeft: IOverlayItem[];
  topRight: IOverlayItem[];
  bottomLeft: IOverlayItem[];
  bottomRight: IOverlayItem[];
}
export interface IOverlayItem {
  tag: string;
  name: string;
  prefix: string;
  suffix: string;
  row: string;
  column: string;
  type: string;
  position: string;
}
export interface ILayoutModality {
  [key: string]: ILayoutModalityItem[];
}
export interface ILayoutModalityItem {
  name: string;
  isDefault: boolean;
  isCompare: boolean;
  panelLayout: string;
  stackLayout: string[];
  tileLayout: string[];
}

/**
 * Represents the application settings.
 */
export interface IAppSetting extends IBase {
  userId: string;
  general: IGeneralSetting;
  worklist: IWorklistSetting;
  upload: IUploadSetting;
  hotkeys?: IHotKeySetting;
  viewer: IViewerSetting;
  userInfo: IUserInfo;
}
