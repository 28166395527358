import { ViewerMode } from '@app/viewer/contants/mode';
import { IStackImage } from '@app/viewer/models/IStackImage';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const volumeActions = createActionGroup({
  source: 'Cornerstone Volume',
  events: {
    enterMPRMode: emptyProps(),
    enterFusionMode: emptyProps(),
    createVolume: props<{ mode: ViewerMode; seriesUid: string[] }>(),
    createVolumeSuccess: props<{ volumeIds: string[]; modality: string[]; seriesUids: string[]; mode: ViewerMode }>(),
    createVolumeFail: props<{ error: any }>(),
    startRenderer: emptyProps(),
    initVolumeSuccess: props<{ volumeIds: string[]; modality: string[] }>(),
  },
});
