import { Injectable } from '@angular/core';
import { getDateTimeFormat, viNormal, viBold, jaNormal, jaBold, THUMBNAIL_VIEW_URL } from '@app/@core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IReportContent } from '../models';
import jsPDF from 'jspdf';
import { selectClinicInfoByStudyUid, selectPatientInfoByStudyUid, selectSelectedStudy } from '../store';
import { firstValueFrom } from 'rxjs';
import { IUserInfo, settingQuery } from '@app/setting';
import * as moment from 'moment';
import { GoogleDriveService } from '@app/@shared';

//#region Document Settings
const marginTop = 20; //Top margin
const marginBottom = 20; //Bottom margin
const marginLeft = 20; //Left margin
const marginRight = 20; //Right margin
const lineSpace = 6; //Line Spacing
const keyImageFrameHeight = 100; //Default Height for key image
const fontSizeTitle = 16; //Fontsize for Report Title
const fontSizeItemLabel = 12; //Fontsize for Item Title
const fontSizeItemContent = 11; //Fontsize for Item Content
//#endregion

@Injectable()
export class ReportPdfService {
  constructor(
    private trans: TranslateService,
    private store: Store,
    private driveService: GoogleDriveService,
  ) {
    const currentLang = this.trans.currentLang;
    this.dateTimeFormat = getDateTimeFormat(currentLang);
  }
  protected dateTimeFormat: string;

  /**
   * Generates a review report in PDF format.
   *
   * @param reportInfo - The information needed to generate the report.
   * @returns void
   */
  public reviewReport = async (reportInfo: IReportContent): Promise<void> => {
    const doc = new jsPDF('p', 'mm', 'a4');
    await this._createReport(doc, reportInfo);
    //Open on browser popup
    window.open(doc.output('bloburl'), '_blank', 'popup,left=0,top=0,width=1920,height=1080');
  };

  /**
   * Downloads a report as a PDF file.
   *
   * @param reportInfo - The information of the report to be downloaded.
   * @returns void
   */
  public downloadReport = async (reportInfo: IReportContent): Promise<void> => {
    const doc = new jsPDF('p', 'mm', 'a4');
    const pdfReportFileName = await this._createReport(doc, reportInfo);
    //Export to PDF file
    doc.save(pdfReportFileName);
  };

  /**
   * Creates a report using the provided jsPDF instance and report information.
   *
   * @param doc - The jsPDF instance used for creating the report.
   * @param reportInfo - The information to be included in the report.
   * @returns A Promise that resolves when the report is created.
   */
  private _createReport = async (doc: jsPDF, reportInfo: IReportContent): Promise<string> => {
    const lang = this.trans.currentLang;
    const labelTitle = this.trans.instant('ReportPdfLabel');
    const labelStudyInfo = [
      this.trans.instant('ReportPdfPatientName'),
      this.trans.instant('ReportPdfGender'),
      this.trans.instant('ReportPdfPatientId'),
      this.trans.instant('ReportPdfModality'),
      this.trans.instant('ReportPdfDatetime'),
      this.trans.instant('ReportPdfBodyPart'),
      this.trans.instant('ReportPdfStudyUid'),
      this.trans.instant('ReportPdfReportCreatedDate'),
    ];

    const labelReportFinding = this.trans.instant('Finding');
    const labelReportDiagnosis = this.trans.instant('Diagnosis');
    const labelReportComment = this.trans.instant('Comment');
    const labelDoctor = this.trans.instant('ReportPdfDoctor');
    const labelKeyImage = this.trans.instant('ReportPdfKeyImage');
    const labelKeyImageFigure = this.trans.instant('ReportPdImageFigure');
    const labelFooter = this.trans.instant('ReportPdfFooter');
    const selectedStudy = await firstValueFrom(this.store.select(selectSelectedStudy));
    const patientInfo = await firstValueFrom(this.store.select(selectPatientInfoByStudyUid(selectedStudy)));
    const studyInfo = await firstValueFrom(this.store.select(selectClinicInfoByStudyUid(selectedStudy)));
    const doctorInfo = <IUserInfo>await firstValueFrom(this.store.select(settingQuery.selectUserInfoSetting));

    //Add Font for drawing text
    if (lang == 'ja-JP') {
      //Japanese Font for ja-JP
      doc.addFileToVFS('Font.ttf', jaNormal);
      doc.addFont('Font.ttf', 'Font', 'normal');
      doc.addFileToVFS('Font-Bold.ttf', jaBold);
      doc.addFont('Font-Bold.ttf', 'Font', 'bold');
    } else {
      //Vietnamese Font for vi-VN and en-US
      doc.addFileToVFS('Font.ttf', viNormal);
      doc.addFont('Font.ttf', 'Font', 'normal');
      doc.addFileToVFS('Font-Bold.ttf', viBold);
      doc.addFont('Font-Bold.ttf', 'Font', 'bold');
    }
    doc.setFont('Font');

    var docWidthMax = doc.internal.pageSize.getWidth() - marginLeft - marginRight; //Max value of document printing width
    var docHeightMax = doc.internal.pageSize.getHeight() - marginTop - marginBottom; //Max value of document printing height
    var docXMax = doc.internal.pageSize.getWidth() - marginRight; //Max value of printing position in X direction
    var docYMax = doc.internal.pageSize.getHeight() - marginBottom; //Max value of printing position in X direction
    var textX = marginLeft;
    var textY = marginTop;

    //doc.rect(marginLeft, marginTop, docWidthMax, docHeightMax); // bounder for testing pdf report

    //Draw Title
    doc.setFont('Font', 'bold');
    doc.setFontSize(fontSizeTitle);
    const textLength = doc.getTextWidth(labelTitle);
    textX = marginLeft + (docWidthMax - textLength) / 2;
    textY += lineSpace;
    doc.text(labelTitle, textX, textY);

    //Draw Study Info
    doc.setFont('Font', 'normal');
    doc.setFontSize(fontSizeItemContent);
    const studyInfos: string[] = [];
    studyInfos.push(patientInfo?.name || '');
    studyInfos.push(patientInfo?.gender || '');
    studyInfos.push(patientInfo?.id || '');
    studyInfos.push(studyInfo?.modality.join(',') || '');
    const dateTimeFormat = getDateTimeFormat(lang);
    const studyDateTime = `${moment(studyInfo?.studyDate, 'YYYYMMDD').format(dateTimeFormat)} ${moment(studyInfo?.studyTime, 'hhmmss').format('HH:mm:ss')}`;
    studyInfos.push(studyDateTime);
    studyInfos.push(studyInfo?.bodyPart || '');
    studyInfos.push(studyInfo?.studyInstanceUid || '');
    // studyInfos.push(moment.unix(parseInt(reportInfo.updatedDate)).format(dateTimeFormat + ' HH:mm:ss'));
    studyInfos.push(moment().format(dateTimeFormat + ' HH:mm:ss')); //Set PDF Report created time

    //Draw Study Information
    textX = marginLeft;
    textY = this._drawStudyInfo(doc, labelStudyInfo, studyInfos, textX, textY);

    //Draw Report Content
    textY += 2 * lineSpace;
    textY = this._drawReportInfo(doc, labelReportFinding, reportInfo.finding, textX, textY, docWidthMax, docHeightMax);
    textY += 2 * lineSpace;
    textY = this._drawReportInfo(doc, labelReportDiagnosis, reportInfo.diagnosis, textX, textY, docWidthMax, docHeightMax);
    textY += 2 * lineSpace;
    textY = this._drawReportInfo(doc, labelReportComment, reportInfo.comment, textX, textY, docWidthMax, docHeightMax);

    //Draw Doctor Information
    textX = marginLeft + docWidthMax / 2;
    textY += 2 * lineSpace;
    await this._drawDoctorInfo(doc, labelDoctor, doctorInfo, textX, textY, docWidthMax, docHeightMax);

    //Draw Key Image
    this._drawKeyImage(doc, reportInfo, labelKeyImage, labelKeyImageFigure, docWidthMax, docHeightMax);

    //Add page count
    this._drawFootNote(doc, labelFooter);

    //Set PDF FileName
    return ('Report_' + patientInfo?.id + '_' + patientInfo?.name + '_' + reportInfo.updatedDate + '.pdf').replace(/\/|:|\s/g, ''); //Remove /, : and space
  };
  /**
   * Draws study information on the PDF document.
   *
   * @param doc - The jsPDF document object.
   * @param labelStudyInfo - An array of labels for the study information.
   * @param studyInfos - An array of study information values.
   * @param x - The x-coordinate of the starting position.
   * @param y - The y-coordinate of the starting position.
   * @returns The y-coordinate after drawing the study information.
   */
  private _drawStudyInfo = (doc: jsPDF, labelStudyInfo: string[], studyInfos: string[], x: number, y: number): number => {
    var textX = x;
    var textY = y;
    var labelWidth = 0;
    for (var i = 0; i < labelStudyInfo.length; i++) {
      labelWidth = Math.max(labelWidth, doc.getTextWidth(labelStudyInfo[i]));
    }
    labelWidth += 5;

    textY += lineSpace;
    for (var i = 0; i < labelStudyInfo.length; i++) {
      textY += lineSpace;
      doc.text(labelStudyInfo[i] + ': ', textX, textY);
      doc.text(studyInfos[i], textX + labelWidth, textY);
    }
    return textY;
  };

  /**
   * Draws the report information on the given jsPDF document.
   *
   * @param doc - The jsPDF document to draw on.
   * @param label - The label for the report information.
   * @param content - The content of the report information.
   * @param x - The x-coordinate of the starting position.
   * @param y - The y-coordinate of the starting position.
   * @param docWidthMax - The maximum width of the document.
   * @param docHeightMax - The maximum height of the document.
   * @returns The y-coordinate of the last drawn text.
   */
  private _drawReportInfo = (doc: jsPDF, label: string, content: string, x: number, y: number, docWidthMax: number, docHeightMax: number): number => {
    let textX = x;
    let textY = y;

    //Label
    doc.setFont('Font', 'bold');
    doc.setFontSize(fontSizeItemLabel);
    doc.text(label, textX, textY);

    //Content
    textY += lineSpace;
    doc.setFont('Font', 'normal');
    doc.setFontSize(fontSizeItemContent);

    let textLength = doc.getTextWidth(content);
    const docMaxY = marginTop + docHeightMax;
    if (textX + textLength < docWidthMax) {
      doc.text(content, textX, textY);
    } else {
      textLength = 0;
      var textArray = content.split(' ');
      var textRow = textArray[0];
      for (var i = 1; i < textArray.length; i++) {
        textLength = doc.getTextWidth(textRow);
        if (textLength + doc.getTextWidth(textArray[i]) > docWidthMax) {
          doc.text(textRow, textX, textY);
          textY += lineSpace;
          if (textY > docMaxY) {
            textY = this._addNewPage(doc);
          }
          textRow = '';
        }
        textRow += textArray[i] + ' ';
      }
      doc.text(textRow, textX, textY);
    }
    return textY;
  };

  /**
   * Draws the doctor information on the PDF document.
   * @param doc - The jsPDF document object.
   * @param labelDoctor - The label for the doctor information.
   * @param doctorInfo - The doctor information to be displayed.
   * @param x - The x-coordinate of the starting position.
   * @param y - The y-coordinate of the starting position.
   * @param docWidthMax - The maximum width of the document.
   * @param docHeightMax - The maximum height of the document.
   */
  private _drawDoctorInfo = async (doc: jsPDF, labelDoctor: string, doctorInfo: IUserInfo, x: number, y: number, docWidthMax: number, docHeightMax: number) => {
    doc.setFont('Font', 'normal');
    doc.setFontSize(fontSizeItemContent);
    var textX = x;
    var textY = y;
    var doctorInfoWidth = docWidthMax / 2;
    var doctorInfoHeight = 7.5 * lineSpace;
    var textLength = doc.getTextWidth(doctorInfo.name);
    if (textLength > doctorInfoWidth) doctorInfoWidth = textLength;
    textLength = doc.getTextWidth(doctorInfo.title);
    if (textLength > doctorInfoWidth) doctorInfoWidth = textLength;
    textLength = doc.getTextWidth(doctorInfo.institution);
    if (textLength > doctorInfoWidth) doctorInfoWidth = textLength;
    var docMaxX = marginLeft + docWidthMax;
    if (textX + doctorInfoWidth > docMaxX) textX = docMaxX - doctorInfoWidth;
    var docMaxY = marginTop + docHeightMax;
    if (textY + doctorInfoHeight > docMaxY) {
      textY = this._addNewPage(doc);
    }
    //doc.rect(textX, textY - lineSpace, doctorInfoWidth, doctorInfoHeight); // bounder for testing pdf report
    doc.setFont('Font', 'normal');
    doc.setFontSize(fontSizeItemContent);
    doc.text(labelDoctor, textX, textY);
    const imgData = await this.driveService.getImageContent(doctorInfo.signatureUrl);
    textY += 0.5 * lineSpace;
    this._drawImage(doc, imgData, textX, textY, doctorInfoWidth, 4 * lineSpace);
    // doc.rect(textX, textY, doctorInfoWidth, 4 * lineSpace);
    textY += 5 * lineSpace;
    var str = "";
    if (doctorInfo.title.length > 0) str += doctorInfo.title + " ";
    str += doctorInfo.name;
    doc.text(str, textX, textY);
    textY += lineSpace;
    doc.text(doctorInfo.institution, textX, textY);
  };

  /**
   * Draws the key image section in the PDF document.
   *
   * @param {jsPDF} doc - The jsPDF instance representing the PDF document.
   * @param {any} reportInfo - The report information.
   * @param {string} labelKeyImage - The label for the key image.
   * @param {string} labelKeyImageFigure - The label for the key image figure.
   * @param {number} docWidthMax - The maximum width of the document.
   * @param {number} docHeightMax - The maximum height of the document.
   */
  private _drawKeyImage(doc: jsPDF, reportInfo: any, labelKeyImage: string, labelKeyImageFigure: string, docWidthMax: number, docHeightMax: number) {
    doc.setFont('Font', 'bold');
    doc.setFontSize(fontSizeItemLabel);
    var textX = marginLeft;
    var textY = this._addNewPage(doc);
    doc.text(labelKeyImage, textX, textY);

    var docMaxY = marginTop + docHeightMax;
    for (var i = 0; i < reportInfo.keyImages.length; i++) {
      //Calculate heigh for drawing a key image
      var imgData = reportInfo.keyImages[i];
      if (imgData == undefined) return;
      var imgProps = doc.getImageProperties(imgData);
      var imgHeight = keyImageFrameHeight;
      var imgWidth = (imgHeight * imgProps.width) / imgProps.height;
      if (imgWidth > docWidthMax) {
        imgWidth = docWidthMax;
        imgHeight = (imgWidth * imgProps.height) / imgProps.width;
      }
      if (textY + 2.0 * lineSpace + imgHeight > docMaxY) {
        textY = this._addNewPage(doc);
      }

      //Draw Label
      doc.setFont('Font', 'normal');
      doc.setFontSize(fontSizeItemContent);
      var str = labelKeyImageFigure + ' ' + (i + 1) + ': ';
      if (reportInfo.figure[i] != null) str += reportInfo.figure[i];
      textY += 1.5 * lineSpace;
      doc.text(str, textX, textY);
      textY += 0.5 * lineSpace;

      //Draw Key Image
      doc.addImage(imgData, imgProps.fileType, textX, textY, imgWidth, imgHeight);
      textY += imgHeight;
    }
  }

  /**
   * Draws an image on the provided jsPDF document.
   *
   * @param doc - The jsPDF document to draw the image on.
   * @param imgData - The image data as a string.
   * @param textX - The x-coordinate of the starting position for the image.
   * @param textY - The y-coordinate of the starting position for the image.
   * @param imgFrameWidth - The maximum width of the image frame.
   * @param imgFrameHeight - The maximum height of the image frame.
   * @returns The height of the drawn image.
   */
  private _drawImage(doc: jsPDF, imgData: string, textX: number, textY: number, imgFrameWidth: number, imgFrameHeight: number): number {
    if (imgData == undefined || imgData == "") return 0;
    var imgProps = doc.getImageProperties(imgData);
    var imgHeight = imgFrameHeight;
    var imgWidth = (imgHeight * imgProps.width) / imgProps.height;
    if (imgWidth > imgFrameWidth) {
      imgWidth = imgFrameWidth;
      imgHeight = (imgWidth * imgProps.height) / imgProps.width;
    }
    doc.addImage(imgData, imgProps.fileType, textX, textY, imgWidth, imgHeight);
    return imgHeight;
  }

  /**
   * Add a new page to PDF file
   * @param doc
   * @returns The start position of Y axis of new page
   */
  private _addNewPage(doc: jsPDF): number {
    doc.addPage('a4', 'p');
    //doc.rect(marginLeft, marginTop, docWidthMax, docHeightMax); // bounder for testing pdf report
    var textY = marginTop + lineSpace;
    return textY;
  }

  /**
   * Draws the footer note on each page of the PDF document.
   *
   * @param doc - The jsPDF instance representing the PDF document.
   * @param labelFooter - The label to be displayed in the footer.
   */
  private _drawFootNote = (doc: jsPDF, labelFooter: string) => {
    const pageCount = doc.internal.pages.length - 1;
    //Draw Page Number
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      //Draw Page Number
      var text = labelFooter + ' ' + i + '/' + pageCount;
      var textLength = doc.getTextWidth(text);
      var textX = (doc.internal.pageSize.getWidth() - textLength) / 2;
      var textY = doc.internal.pageSize.getHeight() - marginBottom + lineSpace;
      doc.text(text, textX, textY);
    }
  };
}
