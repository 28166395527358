import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ISearch } from '../models/ISearch';

@Injectable()
export class SearchWorklistService {
  constructor() {
    this.searchStudy = new Subject<ISearch>();
    this.executeSearchStudy = new Subject<ISearch>();
    this.clearSearch = new Subject<boolean>();
  }

  //#region  Rxjs
  public searchStudy: Subject<ISearch>;
  public executeSearchStudy: Subject<ISearch>;
  public clearSearch: Subject<boolean>;
  /** Raise event when user click on search button
   * @param payload search information
   */
  public raiseSearchStudy(payload: ISearch) {
    this.searchStudy.next(payload);
  }

  /** Raise event when user click on clear search button
   * @param state state of clear search
   */
  public raiseClearSearch(state: boolean) {
    this.clearSearch.next(state);
  }

  public raiseExecuteSearchStudy(payload: ISearch) {
    this.executeSearchStudy.next(payload);
  }

  //#endregion
}
