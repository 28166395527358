<div class="general-setting container-fluid">
  <div class="row">
    <div class="general-setting__label col-md-2">
      <p translate>Theme</p>
    </div>
    <div class="col-md-auto">
      <div class="card flex">
        <p-selectButton [options]="themeStateOptions" [ngModel]="themeValue" optionLabel="label" optionValue="value" [unselectable]="true" (onChange)="onThemeChange($event)">
          <ng-template let-item pTemplate> {{ item.label | translate }} </ng-template>
        </p-selectButton>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="general-setting__label col-md-2">
      <p translate>Language</p>
    </div>
    <div class="col-md-auto">
      <div class="card flex">
        <p-dropdown [options]="languages" [ngModel]="currentLanguage" (onChange)="setLanguage($event.value)">
          <ng-template pTemplate="selectedItem">
            <div>{{ currentLanguage | translate }}</div>
          </ng-template>
          <ng-template let-lang pTemplate="item">
            <div>{{ lang | translate }}</div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
</div>
