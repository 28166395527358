import { Injectable } from '@angular/core';
import { DRIVE_FILE_EXTENSION, DRIVE_FILE_NAME, DRIVE_FOLDER_NAME, DRIVE_MIMETYPE } from '@app/@core';
import { GoogleDriveService, GoogleSpreadSheetService } from '@app/@shared';
import { environment } from '@env/environment';
import { HttpStatusCode } from 'axios';
import { IStudyInfo, StudyInfo } from '@app/worklist';

@Injectable()
/**
 * Service for managing DICOM DIR files and folders on Google Drive.
 */
export class DicomDirService {
  constructor(
    private gDriveService: GoogleDriveService,
    private sheetService: GoogleSpreadSheetService,
  ) {}

  /**
   * Returns the ID of the Google Drive root folder. If it doesn't exist it will be created
   */
  //
  public async GetGoogleDriveRootID() {
    let rootFolderId = await this.gDriveService.getFileOrFolderId(environment.drive.root_path, DRIVE_MIMETYPE.FOLDER);
    if (rootFolderId === '') {
      rootFolderId = await this.gDriveService.CreateFolder(environment.drive.root_path);
    }
    return rootFolderId;
  }

  /**
   * Get DICOM DIR folder ID on Google Drive
   * @param patientId patient id of uploading dicom
   * @param rootFolderId Google drive folder store all dicom dir
   * @param studyUid current uploading uid of study
   * @returns
   */
  public async GetDicomDirID(patientId, rootFolderId, studyUid) {
    // Get current patient dcm folder of current study
    let patientFolderId = await this.gDriveService.getFileOrFolderId(patientId, DRIVE_MIMETYPE.FOLDER, rootFolderId);
    if (patientFolderId === '') {
      patientFolderId = await this.gDriveService.CreateFolder(patientId, rootFolderId);
    }
    // Check study is exist on drive or not
    let studyFolderId = await this.gDriveService.getFileOrFolderId(studyUid, DRIVE_MIMETYPE.FOLDER, patientFolderId);
    let imgFolderId = '';
    let reportFolderId = '';
    let thumbnailFolderId = '';
    // in case not exist study, create the new one. otherwire get id of img folder and report folder
    if (studyFolderId === '') {
      studyFolderId = await this.gDriveService.CreateFolder(studyUid, patientFolderId);
      imgFolderId = await this.gDriveService.CreateFolder(DRIVE_FOLDER_NAME.DICOMFILE, studyFolderId);
      reportFolderId = await this.gDriveService.CreateFolder(DRIVE_FOLDER_NAME.REPORT, studyFolderId);
      thumbnailFolderId = await this.gDriveService.CreateFolder(DRIVE_FOLDER_NAME.THUMBNAIL, studyFolderId);
    } else {
      imgFolderId = await this.gDriveService.getFileOrFolderId(DRIVE_FOLDER_NAME.DICOMFILE, DRIVE_MIMETYPE.FOLDER, studyFolderId);
      reportFolderId = await this.gDriveService.getFileOrFolderId(DRIVE_FOLDER_NAME.REPORT, DRIVE_MIMETYPE.FOLDER, studyFolderId);
      thumbnailFolderId = await this.gDriveService.getFileOrFolderId(DRIVE_FOLDER_NAME.THUMBNAIL, DRIVE_MIMETYPE.FOLDER, studyFolderId);
    }
    return {
      studyFolderId,
      imgFolderId,
      reportFolderId,
      thumbnailFolderId,
    };
  }

  /**
   * Create DICOM DIR file on Google Drive
   * @param studyFolderId Google drive folder store all dicom dir
   * @param payload data of dicom dir
   * @returns
   */
  public async CreateDicomDir(studyFolderId: string, payload): Promise<string> {
    const response = await this.gDriveService.uploadFile(studyFolderId, payload, DRIVE_FILE_NAME.DICOM_DIR, DRIVE_FILE_EXTENSION.DICOMDIR, DRIVE_MIMETYPE.JSON);
    if (response.status === HttpStatusCode.Ok) {
      const driveId = response.data.id;
      return driveId;
    } else {
      return '';
    }
  }

  /**
   * Save study information to a Google Sheet.
   * @param worklistDriveId - The ID of the Google Drive file that represents the worklist.
   * @param payload - The study information to be saved.
   * @returns The response from the Google Sheet API.
   */
  public async SaveStudyInformation(worklistDriveId: string, payload: StudyInfo) {
    const response = await this.sheetService.appendValuesToSheet(worklistDriveId, environment.worklist.dataRange, payload.toArrayString());
    return response;
  }

  /**
   * Deletes a study folder from the server.
   * @param studyFolderId - The ID of the study folder to delete.
   * @returns A promise that resolves with the response from the server.
   */
  public deleteStudyFolder = async (studyFolderId: string) => {
    const response = await this.gDriveService.deleteFileOrFolder(studyFolderId);
    return response;
  };
}
