import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from './loader/loader.component';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastMessageComponent } from './toast/toast.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmationService } from 'primeng/api';
import { DomChangeDirective } from './directives/doom-change.directive';
import { DropzoneDirective } from './directives/file-drap-drop.directive';
@NgModule({
  imports: [TranslateModule, CommonModule, BlockUIModule, ProgressSpinnerModule, ToastModule, ConfirmDialogModule],
  declarations: [LoaderComponent, ToastMessageComponent, ConfirmComponent, DropzoneDirective, DomChangeDirective],
  exports: [LoaderComponent, ToastMessageComponent, ConfirmComponent, DropzoneDirective, DomChangeDirective],
  providers: [ConfirmationService],
})
export class SharedModule {}
