import { IStudy } from '@app/@core';
import { IUploadData } from './IUploadData';
import { SortOrder } from '@app/viewer/contants/sort';
import { IStudyUpload } from './IStudyUpload';

/**
 * Represents the data for uploading studies.
 */
export class UploadData implements IUploadData {
  studyList: IStudyUpload[];

  /**
   * Initializes a new instance of the UploadData class.
   */
  constructor() {
    this.studyList = [];
  }

  /**
   * Finds a study by its unique identifier.
   * @param uid - The unique identifier of the study.
   * @returns The found study, or undefined if not found.
   */
  findStudy(uid: string): IStudyUpload | undefined {
    return this.studyList.find((study) => {
      return study.uid === uid;
    });
  }

  /**
   * Adds a study to the study list.
   * @param value - The study to be added.
   */
  addStudy(value: IStudyUpload): void {
    this.studyList.push(value);
  }

  /**
   * Deletes a study from the study list.
   * @param uid - The unique identifier of the study to be deleted.
   */
  deletedStudy(uid: string): void {
    const index = this.studyList.findIndex((study) => study.uid === uid);
    if (index) {
      this.studyList.splice(index);
    }
  }

  /**
   * Sorts the study list by default criteria.
   * - Sorts series by series number in ascending order.
   * - Sorts images within each series by instance number in ascending order.
   */
  defaultSort() {
    // Sort series number
    for (let index = 0, length = this.studyList.length; index < length; index++) {
      const study = this.studyList[index];
      study.sortbySeriesNumber(SortOrder.Ascending);
      //Sort images as instance number
      for (let indexSeries = 0, lengthSeries = study.series.length; indexSeries < lengthSeries; indexSeries++) {
        const series = study.series[indexSeries];
        series.sortByInstanceNumber(SortOrder.Ascending);
      }
    }
  }
}
