import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, viewChild } from '@angular/core';
import { CornerstoneService } from '@app/@core';
import { Logger } from '@app/@shared';
import { DEFAULT_TILE_ID, INDEX_SEPERATOR, MouseButton, ScrollSync, SyncScope, SyncType, TILE_INDEX_AT, Tools } from '@app/viewer/contants';
import { LAYOUT_COLS_INDEX, LAYOUT_ROWS_INDEX, STACK_LAYOUT_INDEX, VIEWPORT_INDEX_SEPERATOR } from '@app/viewer/contants/layout';
import { filterStudyInDisplayViewport } from '@app/viewer/helpers';
import { IActiveStack, IStackLayout } from '@app/viewer/models/ILayout';
import { ISeriesToStack } from '@app/viewer/models/IViewport';
import { ToolsService } from '@app/viewer/services';
import { SynchronizerService } from '@app/viewer/services/synchronizer.service';
import { ViewerGeneralQuery } from '@app/viewer/store/general';
import { ViewerLayoutQuery, layoutActions } from '@app/viewer/store/layout';
import { ViewerMenuQuery } from '@app/viewer/store/menu';
import { cursors, WindowLevelTool } from '@cornerstonejs/tools';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
const log = new Logger('Viewport-panel');

export interface IStackViewport {
  stackIndex: string;
  stackLayout: string;
  index: number;
}
@Component({
  selector: 'app-viewport-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['../viewport.component.scss', './panel.component.scss'],
})
export class PanelComponent implements OnInit, AfterViewInit, OnDestroy {
  //#region Props
  @Input() panelIndex: string;
  @Input() panelLayout: string;
  @Input() activated: boolean;
  @Input() index: number;

  private _activeStackSubs: Subscription;
  private _stackViewportType: ISeriesToStack[] = [];
  private _mouseRightDown: boolean = false;
  public stackLayout$: Observable<IStackLayout | undefined>;

  public stackLayout: IStackViewport[] | undefined;
  public currentActiveStack$: Observable<IActiveStack | undefined>;
  public currentActiveStack: string;
  //#endregion

  constructor(
    private store: Store,
    private cornerstoneService: CornerstoneService,
    private toolService: ToolsService,
  ) {}

  //#region Angular Lifecycle hook
  ngOnInit(): void {
    this.stackLayout$ = this.store.select(ViewerLayoutQuery.selectStackLayoutByPanelIndex(this.panelIndex));
    this.stackLayout$.subscribe((value: IStackLayout | undefined) => {
      if (!value) {
        return;
      }
      if (this.stackLayout) {
        this.stackLayout.length = 0;
      }
      const layout: IStackViewport[] = [];
      for (let index = 0; index < value.total; index++) {
        const stack: IStackViewport = {
          stackIndex: `${this.panelIndex}${VIEWPORT_INDEX_SEPERATOR}${index}`,
          stackLayout: value.layoutString,
          index: index,
        };
        layout.push(stack);
      }
      this.stackLayout = layout;
    });
    //console.log('done init panel:' + this.panelIndex);
    this.currentActiveStack$ = this.store.select(ViewerLayoutQuery.selectActiveStack);
    this._activeStackSubs = this.currentActiveStack$.subscribe((value) => {
      if (value) {
        this.currentActiveStack = value.stackIndex.split(VIEWPORT_INDEX_SEPERATOR)[STACK_LAYOUT_INDEX];
      }
    });
  }

  ngAfterViewInit(): void {}

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * It unsubscribes from any subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    this._activeStackSubs?.unsubscribe();
  }
  //#endregion

  //#region Layout

  /**
   * Returns the X coordinate of the panel.
   *
   * @returns The X coordinate of the panel.
   */
  getPanelX() {
    return parseInt(this.panelLayout.charAt(LAYOUT_COLS_INDEX));
  }

  /**
   * Retrieves the style object for the panel component.
   * The style object includes border properties based on the panel's position within the layout.
   *
   * @returns The style object containing the panel's width and border properties.
   */
  protected getStyle() {
    const rows = parseInt(this.panelLayout.charAt(LAYOUT_ROWS_INDEX));
    const cols = parseInt(this.panelLayout.charAt(LAYOUT_COLS_INDEX));
    const row = Math.floor(this.index / cols);
    const col = this.index % cols;
    const borderProp = '1px solid var(--bs-white)';

    const style: any = {};
    style.borderTop = borderProp;
    style.borderLeft = borderProp;

    if (row === rows - 1) {
      style.borderBottom = borderProp;
    }

    if (col === cols - 1) {
      style.borderRight = borderProp;
    }

    return { width: 100 / this.getPanelX() + '%', ...style };
  }
  //#endregion

  //#region Child component callback

  /**
   * Event handler called after an image is rendered.
   * @param $event - The event object containing the stackId and studyUid.
   */
  protected onAfterImageRender = async ($event: { stackId: string; studyUid: string }) => {};

  /**
   * Initializes the stack with the provided event data.
   * @param $event The event data.
   */
  protected onInitStack = async ($event: any) => {
    if ($event.viewportInfo) {
      this._stackViewportType.push($event.viewportInfo);
    }
  };
  //#endregion

  //#region HostListener
  //mouseDown
  @HostListener('mousedown', ['$event'])
  protected onMouseDown = (event: MouseEvent) => {
    if (this.activated === false) {
      log.debug('change active panel ' + this.panelIndex);
      this.store.dispatch(layoutActions.changeActivePanel({ activePanel: { panelIndex: this.panelIndex, layout: this.panelLayout } }));
    }

    if (event.button === MouseButton.Right) {
      this._mouseRightDown = true;
      const viewports = this.cornerstoneService.getAllStackViewport();
      viewports.forEach((viewport) => {
        cursors.setCursorForElement(viewport.element, WindowLevelTool.toolName);
      });
    }
  };

  @HostListener('mousewheel', ['$event'])
  protected onMouseWheel = (event: MouseEvent) => {
    if (this.activated === false) {
      this.store.dispatch(layoutActions.changeActivePanel({ activePanel: { panelIndex: this.panelIndex, layout: this.panelLayout } }));
    }
  };

  @HostListener('window:mouseup', ['$event'])
  protected onMouseUp = async (event: MouseEvent) => {
    if (this._mouseRightDown) {
      this._mouseRightDown = false;
      const tools = await firstValueFrom(this.store.select(ViewerMenuQuery.selectActiveTool));
      const toolName = this.toolService.mapToolToName(tools);
      const viewports = this.cornerstoneService.getAllStackViewport();
      viewports.forEach((viewport) => {
        cursors.setCursorForElement(viewport.element, toolName);
      });
    }
  };
  //#endregion

  //#region  Subscriptions
  //#endregion
}
