import { BackupMode, ViewerMode } from '@app/viewer/contants/';
import {
  IToolState,
  IMenuFusion,
  IMenuMPR,
  IActivePanel,
  IActivePanelLayoutBackup,
  IActiveStack,
  IBackupLayout,
  IPanelLayout,
  ISelectedStack,
  IStackLayout,
  ITileLayout,
  ISeriesToStack,
  IActiveTile,
} from '@app/viewer/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const layoutActions = createActionGroup({
  source: 'Viewer Layout',
  events: {
    initModalityLayout: props<{ panelLayout: string; stackLayout: string[]; tileLayout: string[] }>(),
    changePanelLayout: props<{ panelLayout: string }>(),
    changeStackLayout: props<{ stackLayout: string }>(),
    changeTileLayout: props<{ tileLayout: string }>(),
    changeLayoutBySetting: props<{ stackLayout: IStackLayout[]; tileLayout: ITileLayout[]; displayViewports: ISeriesToStack[] }>(),
    changeVolumeLayout: props<{ tileLayout: string }>(),
    changeMetadataDisplay: emptyProps(),
    changeActivePanel: props<{ activePanel: IActivePanel }>(),
    changeActiveStack: props<{ activeStack: IActiveStack; mode: ViewerMode }>(),
    changeActiveTile: props<{ activeTile: IActiveTile; toolState: IToolState }>(),
    updateSelectedStack: props<{ selectedStack: ISelectedStack }>(),
    changePanelSuccess: props<{ panelLayout: IPanelLayout; stackLayout: IStackLayout[]; tileLayout: ITileLayout[] }>(),
    changeStackSuccess: props<{ stackLayout: IStackLayout; tileLayout: ITileLayout[] }>(),
    changeTileSuccess: props<{ tileLayout: ITileLayout }>(),
    changeSettingLayoutSuccess: props<{ stackLayout: string; seriesUid: string; displayViewports: ISeriesToStack[] }>(),
    overrideAllTile: props<{ tileLayout: ITileLayout[] }>(),
    overrideTileInStack: props<{ tileLayout: ITileLayout | undefined; stackIndex: string; seriesUid: string }>(),
    changeLayoutFail: props<{ error: any }>(),
    initVolumeLayout: props<{
      mode: ViewerMode;
      config: IMenuFusion | IMenuMPR;
      oldStackLayout: string;
    }>(),
    backupLayout: props<{ layout: IBackupLayout; activePanelLayout: IActivePanelLayoutBackup }>(),
    backupLayoutFullImage: props<{ layout: IBackupLayout; tileLayout: string; displayviewport: ISeriesToStack }>(),
    restoreLayout: props<{ layout: IBackupLayout }>(),
    displayVolume2D: props<{ mode: ViewerMode; isDisplay: boolean }>(),
    changeWaitingRender: props<{ isWating: boolean }>(),
  },
});
