/**
 * Represents the state of a broadcast in the download queue.
 */
export enum BroadcastState {
  // broadcast next stack start download image if not exist pause queue
  Next,
  //Start download image
  Download,
  //Pause download image because user change stack
  Pause,
  //Resume download image after download queue finish
  Resume,
  //Finish download image queue
  Finish,
  //Error download image queue
  Error,
}

/**
 * Represents the signals that can be sent to control the download queue.
 */
export enum QueueSignal {
  Pause,
  Stop,
  Resume,
  Continue,
}
export enum QueueState {
  High,
  Low,
}
/**
 * The number of threads used for downloading DICOM files.
 */
export const DOWNLOAD_DICOM_THREAD = 5;
