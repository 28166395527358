import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, exhaustMap } from 'rxjs';
import { firebaseAuthActions } from './auth.actions';
import { AuthenticationFirebaseService } from '../authentication.firebase.service';
import { IFirebaseUser } from '../models/IFirebaseUser';
import { IGoogleCredential } from '../models/IGoogleCredential';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Severity, SharedService } from '@app/@shared';
import { TranslateService } from '@ngx-translate/core';
import { CredentialService } from '../credential.service';

/**
 * Effect for logging in with Google.
 * This effect listens for the `login` action and triggers the `onFirebaseLogin` method of the `authFirebaseService`.
 * If the login is successful, it dispatches the `loginSuccess` action with the user information and Google token.
 * If there is an error, it dispatches the `loginFailure` action with the error message.
 * @returns An Observable of the login action.
 */
export const loginByGoogle = createEffect(
  (
    actions$ = inject(Actions),
    authService = inject(AuthenticationFirebaseService),
    credentialService = inject(CredentialService),
    sharedService = inject(SharedService),
    router = inject(Router),
    translateService = inject(TranslateService),
    route = inject(ActivatedRoute),
  ) => {
    return actions$.pipe(
      ofType(firebaseAuthActions.login),
      exhaustMap(() =>
        authService
          .onFirebaseLogin()
          .then((res) => {
            if (res.status === false) {
              sharedService.toastMessage(Severity.error, translateService.instant('LoginFail'));
              return firebaseAuthActions.loginFailure({ error: res.error || 'unknow error' });
            }
            credentialService.isLoggin = true;
            sharedService.toastMessage(Severity.success, translateService.instant('LoginSuccess'));
            const returnUrl = route.snapshot.queryParams['redirect'];
            router.navigateByUrl(returnUrl || '/worklist');
            return firebaseAuthActions.loginSuccess({
              firebaseRes: res.userInfo || ({} as IFirebaseUser),
              googleToken: res.gApi || ({} as IGoogleCredential),
            });
          })
          .catch((err) => {
            sharedService.toastMessage(Severity.error, translateService.instant('LoginFail'));
            return firebaseAuthActions.loginFailure({ error: err });
          }),
      ),
    );
  },
  { functional: true, dispatch: true },
);
/**
 * Effect for logging out with Google.
 * This effect listens for the `logout` action and triggers the `onLogout` method of the `authFirebaseService`.
 * If the logout is successful, it dispatches the `logoutSuccess` action.
 * If there is an error, it dispatches the `logoutFailure` action with the error message.
 * @returns An Observable of the logout action.
 */
export const logoutByGoogle = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthenticationFirebaseService), router = inject(Router)) => {
    return actions$.pipe(
      ofType(firebaseAuthActions.logout),
      tap(() => {
        authService.onLogout();
        router.navigate(['/login'], {
          queryParams: { redirect: router.url },
          replaceUrl: true,
        });
        return firebaseAuthActions.logoutSuccess();
      }),
    );
  },
  { functional: true, dispatch: false },
);
/**
 * Effect for logging in with on-premise credentials.
 * This effect listens for the `login` action and triggers the `login` method of the `AuthService`.
 * If the login is successful, it dispatches the `loginSuccess` action with the user token.
 * If there is an error, it dispatches the `loginFailure` action with the error message.
 * @returns An Observable of the login action.
 */
// loginByOnPremise = createEffect(() => {
//   return this.actions.pipe(
//     ofType(apiAuthActions.login),
//     exhaustMap((action) =>
//       this.AuthService.login(action.username, action.password, action.rememberMe).pipe(
//         map((user) => apiAuthActions.loginSuccess({ token: user.token })),
//         catchError((error) => of(apiAuthActions.loginFailure({ error }))),
//       ),
//     ),
//   );
// });
