import { Rectangle } from '@app/viewer/types';
import html2canvas from 'html2canvas';
const DEFAULT_IMAGE_TYPE = 'image/jpeg';
/**
 * Captures the content of an HTML element as a canvas and downloads it as an image.
 * @param element The HTML element to capture.
 * @returns A promise that resolves to the captured canvas element.
 */
const capture = async (element: HTMLElement): Promise<HTMLCanvasElement> => {
  const canvas = await html2canvas(element);
  const imageUrl = canvas.toDataURL(DEFAULT_IMAGE_TYPE);
  return canvas;
};

/**
 * Drops a key image from the source canvas based on the specified rectangle.
 * @param srcCanvas The source canvas from which to drop the key image.
 * @param rectangle The rectangle specifying the area of the key image to drop.
 * @returns The dropped key image as a new canvas element.
 */
const dropKeyImage = (srcCanvas: HTMLCanvasElement, rectangle: Rectangle): HTMLCanvasElement => {
  const destCanvas = document.createElement('canvas');
  destCanvas.width = rectangle.width;
  destCanvas.height = rectangle.height;
  const destCtx = destCanvas.getContext('2d');
  if (!destCtx) {
    throw new Error('Failed to get 2d context');
  }
  destCtx.drawImage(srcCanvas, rectangle.left, rectangle.top, rectangle.width, rectangle.height, 0, 0, rectangle.width, rectangle.height);
  return destCtx.canvas;
};

/**
 * Converts the given canvas element to a data URL representing the image in JPEG format.
 *
 * @param canvas - The HTMLCanvasElement to convert.
 * @returns A string representing the data URL of the converted image.
 */
const pasteKeyImage = (canvas: HTMLCanvasElement): string => {
  return canvas.toDataURL(DEFAULT_IMAGE_TYPE);
};

/**
 * Saves the given data as an image file with the specified name.
 *
 * @param data - The data of the image to be saved.
 * @param name - The name of the image file.
 */
const saveToImage = (data: string, name: string) => {
  const dlLink = document.createElement('a');
  dlLink.download = `${name}.jpeg`;
  dlLink.href = data;
  dlLink.dataset.downloadurl = [DEFAULT_IMAGE_TYPE, dlLink.download, dlLink.href].join(':');
  document.body.appendChild(dlLink);
  dlLink.click();
  document.body.removeChild(dlLink);
};

export { capture, dropKeyImage, pasteKeyImage, saveToImage };
