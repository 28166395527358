import { createSelector } from '@ngrx/store';
import { selectVieweMenuEntities } from '..';
import { ViewerMenuState } from './menu.reducer';

//#region Entities
const selectPanelLayout = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.panelLayout);
const selectStackLayout = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.stackLayout);
const selectStackTile = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.tileLayout);
const selectDisplay = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.display);
const selectMeasure = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.measure);
const selectAnnotate = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.annotate);
const selectScroll = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.scroll);
const selectSort = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.sort);
const selectActiveTool = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.activeTool);
const selectWWWLTool = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.wwwl);
const selectCurrentFilter = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.currentFilter);
const selectCurrentLut = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.currentLut);
const selectSync = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.sync);
const selectMpr = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.mpr);
const selectFusion = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.fusion);
const selectIsFullScreen = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.isFullScreen);
const selectActiveMode = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.activeMode);
const selectMagnify = createSelector(selectVieweMenuEntities, (state: ViewerMenuState) => state.isActiveMagnify);
const selectSortDirection = createSelector(selectVieweMenuEntities, (state) => state.sortDirection);
const selectReportOpen = createSelector(selectVieweMenuEntities, (state) => state.isReportOpen);
const selectPasteKeyImageRegion = createSelector(selectVieweMenuEntities, (state) => state.isPasteKeyImageRegion);
//#endregion
//#region Tools
const selectActiveWWWLByROI = createSelector(selectWWWLTool, (state) => state.isActiveByROI);
const selectActiveWWWLInvert = createSelector(selectWWWLTool, (state) => state.isInvest);
//#endregion
//#region model selectors
const selectDisplayOverlay = createSelector(selectDisplay, (state) => state.displayOverlay);
const selectDisplayReferenceLine = createSelector(selectDisplay, (state) => state.displayReferenceLine);
const selectDisplayCrosshair = createSelector(selectDisplay, (state) => state.displayCrosshair);
//Sort
const selectSortCommand = createSelector(selectSort, (state) => state.command);
const selectSortScope = createSelector(selectSort, (state) => state.scope);
const selectSortOrder = createSelector(selectSort, (state) => state.order);
//Scroll
const selectIsScrollLoop = createSelector(selectScroll, (state) => state.loop);
const selectScrollMode = createSelector(selectScroll, (state) => state.scrollMode);
const selectScrollSync = createSelector(selectScroll, (state) => state.scrollSync);
const selectSyncScope = createSelector(selectSync, (state) => state.scope);
const selectSyncZoom = createSelector(selectSync, (state) => state.isSyncZoom);
const selectSyncPan = createSelector(selectSync, (state) => state.isSyncPan);
const selectSyncRotate = createSelector(selectSync, (state) => state.isSyncRotate);
const selectSyncWWWL = createSelector(selectSync, (state) => state.isSyncWWWL);
const selectSyncFilter = createSelector(selectSync, (state) => state.isSyncFilter);
const selectSyncLUT = createSelector(selectSync, (state) => state.isSyncLUT);
const selectSyncScroll = createSelector(selectSync, (state) => state.isSyncScroll);

const selectMprLayout = createSelector(selectMpr, (state) => state.layout);
const selectMprDisplay2D = createSelector(selectMpr, (state) => state.isDisplay2D);

const selectFusionLayout = createSelector(selectFusion, (state) => state.layout);
const selectFusionDisplay2D = createSelector(selectFusion, (state) => state.isDisplay2D);
//#endregion
export const ViewerMenuQuery = {
  selectPanelLayout,
  selectStackLayout,
  selectStackTile,
  selectDisplay,
  selectMeasure,
  selectAnnotate,
  selectSort,
  selectCurrentFilter,
  selectCurrentLut,
  selectSync,
  selectMpr,
  selectFusion,
  selectIsFullScreen,
  selectActiveMode,
  selectReportOpen,
  selectPasteKeyImageRegion,
  selectMagnify,
  selectSortDirection,
  selectDisplayOverlay,
  selectDisplayReferenceLine,
  selectDisplayCrosshair,
  selectSortCommand,
  selectSortScope,
  selectSortOrder,
  selectActiveTool,
  selectActiveWWWLByROI,
  selectActiveWWWLInvert,
  selectSyncScope,
  selectSyncZoom,
  selectSyncPan,
  selectSyncRotate,
  selectSyncWWWL,
  selectSyncFilter,
  selectSyncLUT,
  selectSyncScroll,
  selectMprLayout,
  selectMprDisplay2D,
  selectFusionLayout,
  selectFusionDisplay2D,
  selectIsScrollLoop,
  selectScrollMode,
  selectScrollSync,
};
