/**
 * Configuration object for web worker upload.
 */
export const WEBWORKER_UPLOAD_CONFIG = {
  /**
   * Maximum number of threads for concurrent uploads.
   */
  MAX_THEARDS: 1,

  /**
   * Time in milliseconds after which the web worker will automatically terminate if idle.
   */
  AUTO_TERMINATION_ON_IDLE: 10000,

  /**
   * Flag indicating whether to overwrite existing files during upload.
   */
  OVERWRITE: false,
};

export const UPLOAD_WORKER_INFO = {
  /**
   * The name of the worker.
   */
  NAME: 'uploadWorker',
  /**
   * The URL of the worker file.
   */
  URL: '../workers/upload.worker.ts',
  /**
   * The name of the upload function in the worker.
   */
  UPLOAD_SMALL_FN_NAME: 'smallFile',
  UPLOAD_LARGE_FN_NAME: 'largeFile',
};

export const ZIP_WORKER_INFO = {
  /**
   * The name of the worker.
   */
  NAME: 'zipWorker',
  /**
   * The URL of the worker file.
   */
  URL: '../workers/zip.worker.ts',
  /**
   * The name of the upload function in the worker.
   */
  START_ZIP_FN_NAME: 'start',
};
