import { IPatientStudy } from '@app/@core';
import { IPatientInfo } from './IPatientInfo';

export class PatientInfo implements IPatientInfo {
  public studyUid: string;
  public avatar: string;
  public gender: string;
  public age: string;
  public name: string;
  public id: string;
  constructor(studyUid: string, raw: IPatientStudy) {
    this.studyUid = studyUid;
    this.id = raw.patientId || '';
    this.name = raw.patientName || '';
    this._generateAvatar(raw);
  }

  /**
   * Generates an avatar image source based on the patient's gender and age.
   */
  _generateAvatar(rawPatientInfo: IPatientStudy) {
    this.gender = rawPatientInfo.patientGender || '';
    this.age = rawPatientInfo.patientAge?.substring(0, rawPatientInfo.patientAge.length - 1) || '0';
    let imageSrc = './assets/images/age-gender/';
    switch (this.gender) {
      case 'M':
        imageSrc += 'M-' + this._getAgeIconLabel(parseInt(this.age));
        break;
      case 'F':
        imageSrc += 'F-' + this._getAgeIconLabel(parseInt(this.age));
        break;
      default:
        this.gender = 'O';
        imageSrc += 'O.png';
        break;
    }
    this.avatar = imageSrc;
  }

  /**
   * Chooses the image source based on the patient's age.
   * @param age The patient's age.
   * @returns The image source for the age icon.
   */
  private _getAgeIconLabel(age) {
    let imageSrc = '';
    switch (true) {
      case age > 0 && age < 1:
        imageSrc = '01.png';
        break;
      case age >= 1 && age < 3:
        imageSrc = '02.png';
        break;
      case age >= 3 && age < 4:
        imageSrc = '03.png';
        break;
      case age >= 4 && age < 5:
        imageSrc = '04.png';
        break;
      case age >= 5 && age < 15:
        imageSrc = '05.png';
        break;
      case age >= 15 && age < 25:
        imageSrc = '06.png';
        break;
      case age >= 25 && age < 30:
        imageSrc = '07.png';
        break;
      case age >= 30 && age < 40:
        imageSrc = '08.png';
        break;
      case age >= 40 && age < 65:
        imageSrc = '09.png';
        break;
      case age >= 65 && age < 70:
        imageSrc = '10.png';
        break;
      case age >= 70:
        imageSrc = '11.png';
        break;
      default:
        imageSrc = '12.png';
        break;
    }
    return imageSrc;
  }
}
