<div class="upload-preview card flex justify-content-center">
  <p-fieldset class="upload-preview__fieldset" [legend]="'PreviewStudyUid' | translate: { uid: studyUid, patientId: patientId, patientName: patientName }">
    <div class="upload-preview__content d-flex flex-wrap bd-highlight">
      <p-card
        class="upload-preview__item"
        *rxFor="let seriesData of seriesList"
        styleClass="p-card-shadow"
        [class.upload-preview__item--activated]="seriesUploadingId === seriesData.metadata.seriesNumber"
      >
        <div class="d-flex mb-2 w-100 height-100">
          <div class="upload-preview__item__thumbnail">
            <img crossorigin alt="Thumbnail" [src]="seriesData.thumbnail" />
          </div>
          <div class="upload-preview__item__information w-50 m-1">
            <p>{{ 'PreviewSeriesUid' | translate: { uid: seriesData.metadata.seriesNumber || 0 } }}</p>

            <p>{{ 'PreviewModality' | translate: { value: seriesData.metadata.modality } }}</p>
            <p>
              {{
                'PreviewTotalImage'
                  | translate
                    : {
                        value: cloneDeep(seriesData.imageObject.length) || 0
                      }
              }}
            </p>
            <ng-container *rxLet="seriesData.uploadState; let state" [ngSwitch]="state">
              <ng-container *ngSwitchCase="0">
                <p translate>SeriesUploadStatusNotYet</p>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <p>{{ 'SeriesUploadStatusUploading' | translate: { state: seriesData.uploadMessage } }}</p>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <p translate>SeriesUploadStatusUploaded</p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </p-card>
    </div>
  </p-fieldset>
</div>
