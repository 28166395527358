/**
 * Collection names used in the database.
 */
export const COLLECTION = {
  /**
   * Collection for worklist data.
   */
  WORKLIST: 'worklist',

  /**
   * Collection for setting data.
   */
  SETTING: 'setting',

  /**
   * Collection for uploaded study data.
   */
  UPLOAD_STUDY: 'uploadStudy',

  /**
   * Collection for viewer data.
   */
  VIEWER: 'viewer',

  /**
   * Collection for patient data.
   */
  PATIENT: 'patient',

  /**
   * Collection for study data.
   */
  STUDY: 'study',

  /**
   * Collection for report data.
   */
  REPORT: 'report',

  /**
   * Collection for user data
   * */
  USER_INFO: 'userInfo',
};

/**
 * Constants for metadata keys used in the database.
 */
export const METADATA_KEY = {
  /**
   * Key for the unique identifier.
   */
  ID: 'id',

  /**
   * Key for the creation timestamp.
   */
  CREATE_AT: 'createAt',

  /**
   * Key for the update timestamp.
   */
  UPDATE_AT: 'updateAt',

  /**
   * Key for the deletion timestamp.
   */
  DELETE_AT: 'deleteAt',

  /**
   * Key for the creator identifier.
   */
  CREATE_BY: 'createBy',

  /**
   * Key for the deleter identifier.
   */
  DELETE_BY: 'deleteBy',

  /**
   * Key for the updater identifier.
   */
  UPDATE_BY: 'updateBy',
  /**
   * Firebase authentication user id key
   */
  USER_ID: 'userId',
};

/**
 * A constant object representing different query operators used in database queries.
 * @type {Object}
 * @property {string} LT - Less than
 * @property {string} LTE - Less than or equal to
 * @property {string} EQ - Equal to
 * @property {string} GT - Greater than
 * @property {string} GTE - Greater than or equal to
 * @property {string} NEQ - Not equal to
 * @property {string} ARRAY_CONTAINS - Check if an array contains a specific value
 * @property {string} ARRAY_CONTAINS_ANY - Check if an array contains any of the specific values in an array
 * @property {string} IN - Check if a value is in a specific set of values
 * @property {string} NOT_IN - Check if a value is not in a specific set of values
 */
export const QUERY_OPERATOR = {
  LT: '<',
  LTE: '<=',
  EQ: '=',
  GT: '>',
  GTE: '>=',
  NEQ: '!=',
  ARRAY_CONTAINS: 'array-contains',
  ARRAY_CONTAINS_ANY: 'array-contains-any',
  IN: 'in',
  NOT_IN: 'not-in',
};

/**
 * Defines the filter types for Firebase queries.
 */
export const FIREBASE_FILTER_TYPE = {
  /**
   * Represents a filter based on a date-time range.
   */
  DATE_TIME_RANGE: 'datetimeRange',
  /**
   * Represents a filter based on a specific date-time day.
   */
  DATE_TIME_DAY: 'datetimeDay',
  /**
   * Represents a filter based on a specific number of days.
   */
  DAYS: 'days',
  /**
   * Represents a filter based on a string that contains a specific value.
   */
  CONTAINS: 'contains',
  /**
   * Represents a filter based on a string that equals a specific value.
   */
  EQUALS: 'equals',

  /**
   * Represents a filter based on a string that starts with a day duration.
   */
  DURATION: 'duration',
};

export const DATE_TIME_RANGE_SPLIT = '/';
