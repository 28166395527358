/// <reference types="gapi" />
/// <reference types="gapi.client" />
/// <reference types="gapi.client.drive-v3" />
/// <reference types="gapi.client.sheets-v4" />
import { Injectable } from '@angular/core';
import { DRIVE_FILE_NAME, DRIVE_MIMETYPE, PATIENT_INFO_SHEET_HEADER } from '@app/@core';
import { environment } from '@env/environment';
import { GoogleDriveService, Logger } from '@shared';

import * as _ from 'lodash';
const log: Logger = new Logger('spreadsheet');
@Injectable({ providedIn: 'root' })
export class GoogleSpreadSheetService {
  constructor(private driveService: GoogleDriveService) {}

  /**
   * Initializes the worklist sheet by creating a new sheet in the root folder and appending the header values.
   * @returns The ID of the newly created worklist sheet.
   */
  public async initWorklistSheet() {
    let rootFolderId = await this.driveService.getFileOrFolderId(environment.drive.root_path, DRIVE_MIMETYPE.FOLDER);
    if (rootFolderId === '') {
      rootFolderId = await this.driveService.CreateFolder(environment.drive.root_path);
    }
    const worklistSheetId = await this.driveService.CreateFile(DRIVE_FILE_NAME.WORKLIST, rootFolderId, DRIVE_MIMETYPE.SHEET);

    // Add only header value into worklist sheet
    await this.appendValuesToSheet(worklistSheetId, environment.worklist.headerRange, PATIENT_INFO_SHEET_HEADER);
    return worklistSheetId;
  }

  /**
   * Get all the content in the Google Spreadsheet in selected file
   * @param {string} filename the Spreadsheet file name in ggdrive
   * @returns {Promise<gapi.client.drive.File[]>} Google drive file interface
   */
  public async getGoogleSpreadsheetByName(filename: string): Promise<gapi.client.drive.File[]> {
    const params = {
      q: `mimeType='application/vnd.google-apps.spreadsheet' and trashed = false and name contains '${filename}'`,
      fields: 'nextPageToken, files(id, name)',
      spaces: 'drive',
    };
    const res = await gapi.client.drive.files.list(params);
    if (res.result.files) {
      return res.result.files;
    }
    return [];
  }

  /**
   * Get the content with selected range in the Google Spreadsheet in selected file
   * @param {string} fileId The ID of the spreadsheet to get data.
   * @param {string} range The [A1 notation](/sheets/api/guides/concepts#cell) of a range to search for a logical table of data. Values are appended after the last row of the table.
   * @returns {Promise<any[][]>} Google sheet value range object
   */
  public async getGoogleSpreadsheetRangeValueByName(fileId: string, range: string): Promise<any[][]> {
    const res = await gapi.client.sheets.spreadsheets.values.get({ spreadsheetId: fileId, range });
    if (res.result.values) {
      return res.result.values;
    }
    return [];
  }

  /**
   * Get the content with selected range in the Google Spreadsheet in selected file
   * @param {string} fileId  The ID of the spreadsheet to update.
   * @param {string} range The [A1 notation](/sheets/api/guides/concepts#cell) of a range to search for a logical table of data. Values are appended after the last row of the table.
   * @param {any[]} value The data that was read or to be written. This is an array of arrays.
   * @returns {Promise<gapi.client.sheets.ValueRange>} Google sheet value range object
   */
  public async appendValuesToSheet(fileId: string, range: string, value: any[]): Promise<gapi.client.sheets.AppendValuesResponse> {
    const config = {
      spreadsheetId: fileId,
      range,
      valueInputOption: 'RAW',
      insertDataOption: 'INSERT_ROWS',
    };

    const body: gapi.client.sheets.ValueRange = {
      values: [[...value]],
    };
    const res = await gapi.client.sheets.spreadsheets.values.append(config, body);
    if (res.result.updates) {
      return res.result.updates;
    }
    return {};
  }

  /**
   * Get the content with selected range in the Google Spreadsheet in selected file
   * @param {string} fileId  The ID of the spreadsheet to update.
   * @param {string} range The [A1 notation](/sheets/api/guides/concepts#cell) of a range to search for a logical table of data. Values are appended after the last row of the table.
   * @param {any[]} value The data that was read or to be written. This is an array of arrays.
   * @returns {Promise<gapi.client.sheets.UpdateValuesResponse>} Google sheet value range object
   */
  public async updateValuesToSheet(fileId: string, range: string, value: any[]): Promise<gapi.client.sheets.UpdateValuesResponse> {
    const config = {
      spreadsheetId: fileId,
      range,
      valueInputOption: 'RAW',
    };

    const body: gapi.client.sheets.ValueRange = {
      values: [...value],
    };
    const res = await gapi.client.sheets.spreadsheets.values.update(config, body);
    if (res.result) {
      return res.result;
    }
    return {};
  }
}
