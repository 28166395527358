import { Component, OnDestroy, OnInit } from '@angular/core';
import { GoogleDriveService, Logger, SharedService } from '@app/@shared';
import { IUserInfo, SETTING } from '@app/setting/models/ISetting';
import { settingQuery } from '@app/setting/store/setting.selectors';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { environment } from '@env/environment';
import { DRIVE_MIMETYPE, getDateTimeFormat, splitFileNameAndExtension, toBase64 } from '@app/@core';
import * as moment from 'moment';
import { settingActions } from '@app/setting/store/setting.actions';
const log = new Logger('userinfo setting');
@Component({
  selector: 'app-user-info-setting',
  templateUrl: './user-info-setting.component.html',
  styleUrl: './user-info-setting.component.scss',
})
export class UserInfoSettingComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private translateService: TranslateService,
    private driveService: GoogleDriveService,
    private sharedService: SharedService,
  ) {}
  protected userInfo: IUserInfo = {
    name: '',
    title: '',
    institution: '',
    signatureUrl: '',
    lastUpdateDate: '',
  };
  protected lastUpdateDate: string = '';
  protected dateTimeFormat: string = 'MM/DD/YYYY hh:mm:ss';
  protected signatureFile: File | null = null;
  private _userInfo$: Observable<IUserInfo | undefined>;
  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this._userInfo$ = this.store.select(settingQuery.selectUserInfoSetting);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      const lang = event.lang;
      this.dateTimeFormat = getDateTimeFormat(lang);
    });
    this._userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        this.userInfo = cloneDeep(userInfo);
        const lang = this.translateService.currentLang;
        this.lastUpdateDate = moment.unix(Number(this.userInfo.lastUpdateDate)).format(this.dateTimeFormat);
      }
    });
  }

  ngOnDestroy(): void {}

  public onFileChange = async (event: any) => {
    this.signatureFile = event.target.files[0];
  };

  public saveUserInfo = async () => {
    // Save user info

    if (this.signatureFile) {
      // Save signature file
      this.sharedService.preloader(true);
      //upload signature file to google drive
      let rootFolderId = await this.driveService.getFileOrFolderId(environment.drive.root_path, DRIVE_MIMETYPE.FOLDER);
      let uploadFolder = await this.driveService.getFileOrFolderId(environment.drive.upload, DRIVE_MIMETYPE.FOLDER, rootFolderId);

      if (uploadFolder === '') {
        uploadFolder = await this.driveService.CreateFolder(environment.drive.upload, rootFolderId);
      }
      this.userInfo.signatureUrl = await this.driveService.CreateFile(this.signatureFile.name, uploadFolder, this.signatureFile.type, this.signatureFile);
      this.userInfo.lastUpdateDate = moment().unix().toString();
    }
    this.store.dispatch(
      settingActions.updateSetting({
        name: SETTING.USER_INFO,
        payload: this.userInfo,
      }),
    );
  };
}
