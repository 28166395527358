import { Injectable } from '@angular/core';
import { COLLECTION, METADATA_KEY, Theme } from '@app/@core';
import { FireStoreService } from '@app/@database';
import { IFilterType } from '@app/worklist';
import { environment } from '@env/environment';
import { IAppSetting } from '../models/ISetting';
import { DEFAULT_SETTING } from '@app/viewer';

@Injectable({ providedIn: 'root' })
export class SettingFirebaseService {
  constructor(private firestoreService: FireStoreService) {}

  /**
   * Initializes the setting for a user.
   *
   * @param userId - The ID of the user.
   * @returns A promise that resolves with the result of adding the setting data.
   */
  public async initSettingForNewUser(userId: string) {
    const body: IAppSetting = {
      userId,
      general: {
        language: environment.supportedLanguages[0],
        theme: Theme.Light,
      },
      worklist: {
        presetSettings: [],
      },
      upload: {
        uploadSettings: [],
      },

      viewer: {
        textColor: '#000000',
        ...DEFAULT_SETTING,
      },
      userInfo: {
        name: '',
        title: '',
        institution: '',
        lastUpdateDate: '',
        signatureUrl: '',
      },
      createBy: userId,
      createAt: new Date().toString(),
    };
    const res = await this.firestoreService.addData(COLLECTION.SETTING, body);
    return res;
  }

  /**
   * Retrieves the setting for a given user.
   * @param uid - Firebase uid of setting document
   * @returns A Promise that resolves to the setting data.
   */
  public async getSetting(userID: string): Promise<IAppSetting> {
    const res = (await this.firestoreService.getDataOfCollectionByFieldValue(COLLECTION.SETTING, METADATA_KEY.USER_ID, userID, '==')) as IAppSetting;
    return res;
  }

  /**
   * Sets the app setting for a specific user.
   * @param userId - The ID of the user.
   * @param body - The app setting data to be set.
   * @returns A promise that resolves when the setting is successfully set.
   */
  public async setSetting(userId: string, body: IAppSetting): Promise<IAppSetting> {
    return <IAppSetting>await this.firestoreService.updateData(COLLECTION.SETTING, userId, body, userId);
  }

  /**
   * Sets the app setting for a specific user.
   * @param userId - The ID of the user.
   * @param body - The app setting data to be set.
   * @returns A promise that resolves when the setting is successfully set.
   */
  public async updateSetting(userId: string, field: string, body: any): Promise<IAppSetting> {
    return <IAppSetting>await this.firestoreService.updateData(COLLECTION.SETTING, userId, { [field]: body }, userId);
  }
}
