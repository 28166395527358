import { Injectable } from '@angular/core';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
@Injectable({ providedIn: 'root' })
export class AxiosService {
  /**
   * Send a GET request to the server. This is a convenience method for ` axios. get ` with the addition of ` params `.
   *
   * @param {string} url - The URL to send the GET request to.
   * @param {AxiosRequestConfig} config custom HTTP config before sending
   *
   * @return {Promise<AxiosResponse>} A promise resolving to the response object from the server
   */
  public async get(url: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      const res = await axios.get(url, config);
      return res;
    } catch (error: any) {
      return error.response;
    }
  }

  /**
   * Send a POST request to the given URL. This is a convenience method for ` axios. post `.
   *
   * @param url - The URL to send the request to. This may be relative to the base URL of the API.
   * @param {AxiosRequestConfig} config custom HTTP config before sending
   *
   *  @return {Promise<AxiosResponse>} A promise resolving to the response object from the server
   */
  public async post(url: string, data: any, config: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      const res = await axios.post(url, data, config);
      return res;
    } catch (error: any) {
      return error.response;
    }
  }

  /**
   * Send a PUT request to the server. This is a convenience method for ` axios. put `.
   *
   * @param url - The URL to send the request to. Can be relative or absolute.
   * @param {AxiosRequestConfig} config custom HTTP config before sending
   *  @return {Promise<AxiosResponse>} A promise resolving to the response object from the server
   */
  public async put(url: string, data: any, config: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      const res = await axios.put(url, data, config);
      return res;
    } catch (error: any) {
      return error.response;
    }
  }

  /**
   * Convenience function for making a DELETE request. This is a low - level method and should not be used on production use.
   *
   * @param url - The URL to send the request to. Can be relative or absolute
   * @param {AxiosRequestConfig} config custom HTTP config before sending
   *
   *  @return {Promise<AxiosResponse>} A promise resolving to the response object from the server
   */
  public async delete(url: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      const res = await axios.delete(url, config);
      return res;
    } catch (error: any) {
      return error.response;
    }
  }
}
