<div class="content-wrapper">
  <div class="container">
    <h1>Chính Sách Bảo Mật</h1>
    <p>Cập nhật lần cuối: Ngày 16 tháng 5, 2024</p>
    <p>
      Chính Sách Bảo Mật này mô tả các chính sách và quy trình của Chúng Tôi về việc thu thập, sử dụng và tiết lộ thông tin của Bạn khi Bạn sử dụng Dịch Vụ và thông báo cho Bạn về quyền riêng tư của
      Bạn và cách luật pháp bảo vệ Bạn.
    </p>
    <p>
      Chúng Tôi sử dụng Dữ liệu Cá nhân của Bạn để cung cấp và cải thiện Dịch Vụ. Bằng việc sử dụng Dịch Vụ, Bạn đồng ý với việc thu thập và sử dụng thông tin theo Chính Sách Bảo Mật này. Chính Sách
      Bảo Mật này đã được tạo ra với sự trợ giúp của
      <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a>.
    </p>
    <h2>Diễn Giải và Định Nghĩa</h2>
    <h3>Diễn Giải</h3>
    <p>Các từ viết hoa sẽ có ý nghĩa được định nghĩa trong các điều kiện dưới đây. Các định nghĩa sau sẽ có cùng ý nghĩa dù chúng xuất hiện ở dạng số ít hay số nhiều.</p>
    <h3>Định Nghĩa</h3>
    <p>Cho mục đích của Chính Sách Bảo Mật này:</p>
    <ul>
      <li>
        <p><strong>Tài Khoản</strong> có nghĩa là tài khoản duy nhất được tạo cho Bạn để truy cập Dịch Vụ của chúng tôi hoặc một phần của Dịch Vụ.</p>
      </li>
      <li>
        <p>
          <strong>Đối Tác</strong> có nghĩa là một thực thể kiểm soát, bị kiểm soát bởi hoặc dưới sự kiểm soát chung với một bên, trong đó &quot;kiểm soát&quot; có nghĩa là sở hữu 50% trở lên cổ phần,
          quyền lợi vốn hoặc các chứng khoán khác có quyền bỏ phiếu bầu giám đốc hoặc thẩm quyền quản lý khác.
        </p>
      </li>
      <li>
        <p><strong>Công Ty</strong> (được gọi là &quot;Công Ty&quot;, &quot;Chúng Tôi&quot;, &quot;Chúng Ta&quot; hoặc &quot;Của Chúng Tôi&quot; trong Thỏa Thuận này) đề cập đến Radteam.</p>
      </li>
      <li>
        <p>
          <strong>Cookie</strong> là các tệp nhỏ được đặt trên máy tính, thiết bị di động hoặc bất kỳ thiết bị nào khác của Bạn bởi một trang web, chứa chi tiết lịch sử duyệt web của Bạn trên trang
          web đó cùng với nhiều công dụng khác.
        </p>
      </li>
      <li>
        <p><strong>Quốc Gia</strong> đề cập đến: Việt Nam</p>
      </li>
      <li>
        <p><strong>Thiết Bị</strong> có nghĩa là bất kỳ thiết bị nào có thể truy cập Dịch Vụ như máy tính, điện thoại di động hoặc máy tính bảng kỹ thuật số.</p>
      </li>
      <li>
        <p><strong>Dữ Liệu Cá Nhân</strong> là bất kỳ thông tin nào liên quan đến một cá nhân đã được xác định hoặc có thể xác định.</p>
      </li>
      <li>
        <p><strong>Dịch Vụ</strong> đề cập đến Trang Web.</p>
      </li>
      <li>
        <p>
          <strong>Nhà Cung Cấp Dịch Vụ</strong> có nghĩa là bất kỳ cá nhân hoặc pháp nhân nào xử lý dữ liệu thay mặt cho Công Ty. Nó đề cập đến các công ty hoặc cá nhân bên thứ ba được Công Ty thuê để
          tạo điều kiện cho Dịch Vụ, cung cấp Dịch Vụ thay mặt Công Ty, thực hiện các dịch vụ liên quan đến Dịch Vụ hoặc giúp Công Ty phân tích cách Dịch Vụ được sử dụng.
        </p>
      </li>
      <li>
        <p><strong>Dịch Vụ Mạng Xã Hội Bên Thứ Ba</strong> đề cập đến bất kỳ trang web hoặc trang mạng xã hội nào qua đó Người Dùng có thể đăng nhập hoặc tạo tài khoản để sử dụng Dịch Vụ.</p>
      </li>
      <li>
        <p>
          <strong>Dữ Liệu Sử Dụng</strong> đề cập đến dữ liệu được thu thập tự động, hoặc được tạo ra bởi việc sử dụng Dịch Vụ hoặc từ chính hạ tầng của Dịch Vụ (ví dụ: thời lượng của một lần truy cập
          trang).
        </p>
      </li>
      <li>
        <p>
          <strong>Trang Web</strong> đề cập đến VPACS Web Viewer, có thể truy cập từ
          <a href="https://vpacs.net" rel="external nofollow noopener" target="_blank">https://vpacs.net</a>
        </p>
      </li>
      <li>
        <p><strong>Bạn</strong> có nghĩa là cá nhân truy cập hoặc sử dụng Dịch Vụ, hoặc công ty, hoặc pháp nhân khác thay mặt cho cá nhân đó truy cập hoặc sử dụng Dịch Vụ, nếu áp dụng.</p>
      </li>
    </ul>
    <h2>Thu Thập và Sử Dụng Dữ Liệu Cá Nhân Của Bạn</h2>
    <h3>Loại Dữ Liệu Được Thu Thập</h3>
    <h4>Dữ Liệu Cá Nhân</h4>
    <p>
      Khi sử dụng Dịch Vụ của Chúng Tôi, Chúng Tôi có thể yêu cầu Bạn cung cấp cho Chúng Tôi một số thông tin nhận dạng cá nhân có thể được sử dụng để liên hệ hoặc nhận dạng Bạn. Thông tin nhận dạng
      cá nhân có thể bao gồm, nhưng không giới hạn ở:
    </p>
    <ul>
      <li>
        <p>Địa chỉ email</p>
      </li>
      <li>
        <p>Tên và họ</p>
      </li>
      <li>
        <p>Dữ Liệu Sử Dụng</p>
      </li>
    </ul>
    <h4>Dữ Liệu Sử Dụng</h4>
    <p>Dữ Liệu Sử Dụng được thu thập tự động khi sử dụng Dịch Vụ.</p>
    <p>
      Dữ Liệu Sử Dụng có thể bao gồm thông tin như địa chỉ Giao Thức Internet của Thiết Bị của Bạn (ví dụ: địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang của Dịch Vụ mà Bạn truy cập,
      thời gian và ngày truy cập của Bạn, thời gian sử dụng trên những trang đó, các số nhận dạng thiết bị độc nhất và dữ liệu chẩn đoán khác.
    </p>
    <p>
      Khi Bạn truy cập Dịch Vụ bằng hoặc qua một thiết bị di động, Chúng Tôi có thể thu thập một số thông tin tự động, bao gồm, nhưng không giới hạn ở, loại thiết bị di động Bạn sử dụng, ID duy nhất
      của thiết bị di động của Bạn, địa chỉ IP của thiết bị di động của Bạn, hệ điều hành di động của Bạn, loại trình duyệt Internet di động mà Bạn sử dụng, các số nhận dạng thiết bị độc nhất và dữ
      liệu chẩn đoán khác.
    </p>
    <p>Chúng Tôi cũng có thể thu thập thông tin mà trình duyệt của Bạn gửi bất cứ khi nào Bạn truy cập Dịch Vụ của Chúng Tôi hoặc khi Bạn truy cập Dịch Vụ bằng hoặc qua một thiết bị di động.</p>
    <h4>Thông Tin Từ Dịch Vụ Mạng Xã Hội Bên Thứ Ba</h4>
    <p>Công Ty cho phép Bạn tạo tài khoản và đăng nhập để sử dụng Dịch Vụ thông qua các Dịch Vụ Mạng Xã Hội Bên Thứ Ba sau:</p>
    <ul>
      <li>Google</li>
      <li>Facebook</li>
      <li>Instagram</li>
      <li>Twitter</li>
      <li>LinkedIn</li>
    </ul>
    <p>
      Nếu Bạn quyết định đăng ký qua hoặc cho phép chúng tôi truy cập vào một Dịch Vụ Mạng Xã Hội Bên Thứ Ba, Chúng Tôi có thể thu thập Dữ liệu Cá nhân đã được liên kết với tài khoản của Bạn trên Dịch
      Vụ Mạng Xã Hội Bên Thứ Ba, chẳng hạn như tên của Bạn, địa chỉ email của Bạn, các hoạt động của Bạn hoặc danh sách liên lạc của Bạn liên kết với tài khoản đó.
    </p>
    <p>
      Bạn cũng có thể có tùy chọn chia sẻ thêm thông tin với Công Ty thông qua tài khoản Dịch Vụ Mạng Xã Hội Bên Thứ Ba của Bạn. Nếu Bạn chọn cung cấp thông tin như vậy và Dữ liệu Cá nhân, trong quá
      trình đăng ký hoặc cách khác, Bạn đang cho Công Ty quyền sử dụng, chia sẻ và lưu trữ thông tin đó theo Chính Sách Bảo Mật này.
    </p>
    <h3>Công Nghệ Theo Dõi và Cookie</h3>
    <p>
      Chúng Tôi sử dụng Cookie và các công nghệ theo dõi tương tự để theo dõi hoạt động trên Dịch Vụ của Chúng Tôi và lưu trữ một số thông tin. Các công nghệ theo dõi được sử dụng là các đèn hiệu, thẻ
      và tập lệnh để thu thập và theo dõi thông tin và để cải thiện và phân tích Dịch Vụ của Chúng Tôi. Các công nghệ Chúng Tôi sử dụng có thể bao gồm:
    </p>
    <ul>
      <li>
        <strong>Cookie hoặc Cookie trình duyệt.</strong> Cookie là các tệp nhỏ được đặt trên Thiết Bị của Bạn. Bạn có thể hướng dẫn trình duyệt của mình từ chối tất cả các Cookie hoặc cho biết khi nào
        một Cookie đang được gửi đi. Tuy nhiên, nếu Bạn không chấp nhận Cookie, Bạn có thể không sử dụng được một số phần của Dịch Vụ của Chúng Tôi. Trừ khi Bạn đã điều chỉnh cài đặt trình duyệt của
        mình để nó từ chối Cookie, Dịch Vụ của Chúng Tôi có thể sử dụng Cookie.
      </li>
      <li>
        <strong>Cookie Flash.</strong> Một số tính năng của Dịch Vụ của Chúng Tôi có thể sử dụng các đối tượng được lưu trữ cục bộ (hoặc Cookie Flash) để thu thập và lưu trữ thông tin về tùy chọn của
        Bạn hoặc hoạt động của Bạn trên Dịch Vụ của Chúng Tôi. Cookie Flash không được quản lý bởi các cài đặt trình duyệt tương tự như những cài đặt được sử dụng cho Cookie trình duyệt. Để biết thêm
        thông tin về cách Bạn có thể xóa Cookie Flash, vui lòng đọc &quot;Bạn có thể sử dụng cài đặt nào để xóa hoặc vô hiệu hóa các đối tượng được lưu trữ cục bộ?&quot; có tại
        <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_DisableLSO" target="_blank">Adobe Flash Player Help</a>
      </li>
      <li>
        <strong>Đèn hiệu web.</strong> Một số phần của Dịch Vụ của Chúng Tôi và các email của chúng tôi có thể chứa các tệp điện tử nhỏ được gọi là đèn hiệu web (còn được gọi là GIF rõ ràng, pixel tag
        và single-pixel GIF) cho phép Công Ty, ví dụ, đếm số Người Dùng đã truy cập những trang đó hoặc mở một email và cho các số liệu thống kê trang web liên quan khác (ví dụ: ghi lại mức độ phổ
        biến của một phần nhất định và xác minh hệ thống và tính toàn vẹn của máy chủ).
      </li>
    </ul>
    <p>
      Cookie có thể là Cookie &quot;liên tục&quot; hoặc &quot;phiên&quot;. Cookie liên tục vẫn còn trên máy tính cá nhân hoặc thiết bị di động của Bạn khi Bạn ngoại tuyến, trong khi Cookie phiên sẽ bị
      xóa ngay khi Bạn đóng trình duyệt web của mình. Bạn có thể tìm hiểu thêm về Cookie tại
      <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">TermsFeed</a> website article.
    </p>
    <p>Chúng Tôi sử dụng cả Cookie phiên và Cookie liên tục cho các mục đích được nêu dưới đây:</p>
    <ul>
      <li>
        <p>
          <strong>Cookie Cần Thiết / Thiết Yếu</strong>
        </p>
        <p>
          Mục đích: Những Cookie này là cần thiết để cung cấp cho Bạn các dịch vụ có sẵn thông qua Trang Web và để cho phép Bạn sử dụng một số tính năng của Trang Web. Chúng giúp xác thực người dùng
          và ngăn chặn việc sử dụng gian lận các tài khoản người dùng. Nếu không có những Cookie này, các dịch vụ mà Bạn đã yêu cầu không thể được cung cấp, và Chúng Tôi chỉ sử dụng những Cookie này
          để cung cấp các dịch vụ đó cho Bạn.
        </p>
        <p>Loại Cookie: Cookie phiên</p>
        <p>Quản lý bởi: Chúng Tôi</p>
      </li>
      <li>
        <p>
          <strong>Cookie Chính Sách / Thông Báo Cookie</strong>
        </p>
        <p>Mục đích: Những Cookie này xác định xem người dùng có chấp nhận sử dụng Cookie trên Trang Web hay không.</p>
        <p>Loại Cookie: Cookie liên tục</p>
        <p>Quản lý bởi: Chúng Tôi</p>
      </li>
      <li>
        <p>
          <strong>Cookie Chức Năng</strong>
        </p>
        <p>
          Mục đích: Những Cookie này cho phép Chúng Tôi ghi nhớ các lựa chọn Bạn thực hiện khi Bạn sử dụng Trang Web, chẳng hạn như ghi nhớ thông tin đăng nhập của Bạn hoặc tùy chọn ngôn ngữ của Bạn.
          Mục đích của những Cookie này là cung cấp cho Bạn một trải nghiệm cá nhân hơn và tránh Bạn phải nhập lại các tùy chọn của mình mỗi khi Bạn sử dụng Trang Web.
        </p>
        <p>Loại Cookie: Cookie liên tục</p>
        <p>Quản lý bởi: Chúng Tôi</p>
      </li>
      <li>
        <p>
          <strong>Cookie Theo Dõi và Hiệu Suất</strong>
        </p>
        <p>
          Mục đích: Những Cookie này được sử dụng để theo dõi thông tin về lưu lượng truy cập vào Trang Web và cách người dùng sử dụng Trang Web. Thông tin được thu thập qua những Cookie này có thể
          trực tiếp hoặc gián tiếp nhận dạng Bạn là một khách truy cập cá nhân. Điều này là bởi vì thông tin được thu thập thường được liên kết với một định danh duy nhất liên quan đến thiết bị mà Bạn
          sử dụng để truy cập Trang Web. Chúng Tôi cũng có thể sử dụng những Cookie này để thử nghiệm các trang hoặc tính năng mới hoặc các chức năng mới của Trang Web để xem phản ứng của người dùng
          của chúng tôi.
        </p>
        <p>Loại Cookie: Cookie liên tục</p>
        <p>Quản lý bởi: Bên thứ ba</p>
      </li>
    </ul>
    <h2>Sử Dụng Dữ Liệu Cá Nhân Của Bạn</h2>
    <p>Công Ty có thể sử dụng Dữ Liệu Cá Nhân cho các mục đích sau:</p>
    <ul>
      <li>
        <p><strong>Để cung cấp và duy trì Dịch Vụ của chúng tôi</strong>, bao gồm để theo dõi việc sử dụng Dịch Vụ của chúng tôi.</p>
      </li>
      <li>
        <p>
          <strong>Để quản lý Tài Khoản của Bạn:</strong> để quản lý đăng ký của Bạn như một người dùng của Dịch Vụ. Dữ Liệu Cá Nhân mà Bạn cung cấp có thể cho Bạn quyền truy cập vào các chức năng khác
          nhau của Dịch Vụ có sẵn cho Bạn như một người dùng đã đăng ký.
        </p>
      </li>
      <li>
        <p>
          <strong>Để thực hiện hợp đồng:</strong> phát triển, tuân thủ và thực hiện hợp đồng mua bán các sản phẩm, mặt hàng hoặc dịch vụ mà Bạn đã mua hoặc bất kỳ hợp đồng nào khác với Chúng Tôi thông
          qua Dịch Vụ.
        </p>
      </li>
      <li>
        <p>
          <strong>Để liên hệ với Bạn:</strong> để liên hệ với Bạn qua email, cuộc gọi điện thoại, tin nhắn SMS hoặc các hình thức tương đương khác của thông tin liên lạc điện tử, chẳng hạn như thông
          báo đẩy của ứng dụng di động liên quan đến các cập nhật hoặc các thông tin liên lạc thông tin liên quan đến các chức năng, sản phẩm hoặc dịch vụ đã được hợp đồng, bao gồm các cập nhật bảo
          mật, khi cần thiết hoặc hợp lý để thực hiện chúng.
        </p>
      </li>
      <li>
        <p>
          <strong>Để cung cấp cho Bạn</strong> tin tức, các ưu đãi đặc biệt và thông tin chung về các hàng hóa, dịch vụ và sự kiện khác mà Chúng Tôi cung cấp tương tự như những hàng hóa, dịch vụ và sự
          kiện mà Bạn đã mua hoặc yêu cầu trừ khi Bạn đã chọn không nhận thông tin đó.
        </p>
      </li>
      <li>
        <p><strong>Để quản lý các yêu cầu của Bạn:</strong> Để tham dự và quản lý các yêu cầu của Bạn với Chúng Tôi.</p>
      </li>
      <li>
        <p><strong>Để phân tích xu hướng:</strong> để phân tích xu hướng của người dùng và cách họ sử dụng Dịch Vụ để cải thiện Dịch Vụ và phát triển sản phẩm mới.</p>
      </li>
      <li>
        <p><strong>Với sự chấp thuận của Bạn:</strong> Chúng Tôi có thể tiết lộ Dữ Liệu Cá Nhân của Bạn cho bất kỳ mục đích nào khác với sự chấp thuận của Bạn.</p>
      </li>
    </ul>
    <h2>Chúng Tôi Có Thể Chia Sẻ Dữ Liệu Cá Nhân Của Bạn</h2>
    <p>Chúng Tôi có thể chia sẻ Dữ Liệu Cá Nhân của Bạn trong các tình huống sau:</p>
    <ul>
      <li>
        <strong>Với Các Nhà Cung Cấp Dịch Vụ:</strong> Chúng Tôi có thể chia sẻ Dữ Liệu Cá Nhân của Bạn với các Nhà Cung Cấp Dịch Vụ để giám sát và phân tích việc sử dụng Dịch Vụ của Chúng Tôi, để
        liên hệ với Bạn.
      </li>
      <li>
        <strong>Đối với việc chuyển nhượng kinh doanh:</strong> Chúng Tôi có thể chia sẻ hoặc chuyển Dữ Liệu Cá Nhân của Bạn liên quan đến hoặc trong quá trình đàm phán bất kỳ sáp nhập, bán tài sản
        Công Ty, tài trợ hoặc mua lại toàn bộ hoặc một phần của Chúng Tôi cho một công ty khác.
      </li>
      <li>
        <strong>Với các Công Ty liên kết:</strong> Chúng Tôi có thể chia sẻ thông tin của Bạn với các công ty liên kết của Chúng Tôi, trong trường hợp đó Chúng Tôi sẽ yêu cầu các công ty liên kết đó
        tuân thủ Chính Sách Bảo Mật này. Các công ty liên kết bao gồm công ty mẹ của Chúng Tôi và bất kỳ công ty con, đối tác liên doanh hoặc các công ty khác mà Chúng Tôi kiểm soát hoặc nằm dưới sự
        kiểm soát chung với Chúng Tôi.
      </li>
      <li>
        <strong>Với các đối tác kinh doanh:</strong> Chúng Tôi có thể chia sẻ thông tin của Bạn với các đối tác kinh doanh của Chúng Tôi để cung cấp cho Bạn các sản phẩm, dịch vụ hoặc khuyến mãi nhất
        định.
      </li>
      <li>
        <strong>Với các Người Dùng khác:</strong> khi Bạn chia sẻ thông tin cá nhân hoặc tương tác trong các khu vực công cộng với các Người Dùng khác, thông tin đó có thể được xem bởi tất cả các
        Người Dùng và có thể được phân phối công khai ra bên ngoài.
      </li>
      <li><strong>Với sự chấp thuận của Bạn:</strong> Chúng Tôi có thể tiết lộ Dữ Liệu Cá Nhân của Bạn cho bất kỳ mục đích nào khác với sự chấp thuận của Bạn.</li>
    </ul>
    <h2>Lưu Trữ Dữ Liệu Cá Nhân Của Bạn</h2>
    <p>
      <strong
        >Công Ty sẽ lưu trữ Dữ Liệu Cá Nhân của Bạn chỉ trong thời gian cần thiết cho các mục đích được nêu trong Chính Sách Bảo Mật này. Chúng Tôi sẽ lưu trữ và sử dụng Dữ Liệu Cá Nhân của Bạn trong
        phạm vi cần thiết để tuân thủ các nghĩa vụ pháp lý của Chúng Tôi (ví dụ, nếu Chúng Tôi được yêu cầu lưu trữ dữ liệu của Bạn để tuân thủ các luật hiện hành), giải quyết tranh chấp và thi hành
        các thỏa thuận và chính sách pháp lý của Chúng Tôi.</strong
      >
    </p>
    <p>
      Công Ty cũng sẽ lưu trữ Dữ Liệu Sử Dụng cho các mục đích phân tích nội bộ. Dữ Liệu Sử Dụng thường được lưu trữ trong một khoảng thời gian ngắn hơn, ngoại trừ khi dữ liệu này được sử dụng để tăng
      cường bảo mật hoặc cải thiện chức năng của Dịch Vụ của Chúng Tôi, hoặc Chúng Tôi bị pháp luật yêu cầu lưu trữ dữ liệu này trong thời gian dài hơn.
    </p>
    <h2>Chuyển Giao Dữ Liệu Cá Nhân Của Bạn</h2>
    <p>
      Thông tin của bạn, bao gồm Dữ Liệu Cá Nhân, được xử lý tại các văn phòng điều hành của Công Ty và bất kỳ nơi nào khác mà các bên liên quan đến quá trình xử lý nằm. Điều này có nghĩa là thông tin
      này có thể được chuyển đến - và được duy trì trên - các máy tính nằm ngoài tiểu bang, tỉnh, quốc gia của Bạn hoặc các khu vực pháp lý chính phủ khác, nơi luật bảo vệ dữ liệu có thể khác với luật
      của khu vực pháp lý của Bạn.
    </p>
    <p>Sự chấp nhận của Bạn đối với Chính Sách Bảo Mật này kèm theo việc Bạn gửi thông tin đó thể hiện sự đồng ý của Bạn đối với việc chuyển giao đó.</p>
    <p>
      Công Ty sẽ thực hiện tất cả các bước hợp lý cần thiết để đảm bảo rằng dữ liệu của Bạn được xử lý một cách an toàn và phù hợp với Chính Sách Bảo Mật này và không có việc chuyển giao Dữ Liệu Cá
      Nhân của Bạn sẽ diễn ra đến một tổ chức hoặc quốc gia trừ khi có các biện pháp kiểm soát đầy đủ tại chỗ bao gồm bảo mật dữ liệu của Bạn và các thông tin cá nhân khác.
    </p>
    <h2>Tiết Lộ Dữ Liệu Cá Nhân Của Bạn</h2>
    <h3>Giao Dịch Kinh Doanh</h3>
    <p>
      Nếu Công Ty tham gia vào việc sáp nhập, mua lại hoặc bán tài sản, Dữ Liệu Cá Nhân của Bạn có thể được chuyển giao. Chúng Tôi sẽ cung cấp thông báo trước khi Dữ Liệu Cá Nhân của Bạn được chuyển
      giao và trở thành đối tượng của một Chính Sách Bảo Mật khác.
    </p>
    <h3>Thực Thi Pháp Luật</h3>
    <p>
      Trong một số trường hợp nhất định, Công Ty có thể được yêu cầu tiết lộ Dữ Liệu Cá Nhân của Bạn nếu được yêu cầu làm như vậy theo luật pháp hoặc để đáp ứng các yêu cầu hợp lệ của cơ quan công
      quyền (ví dụ: tòa án hoặc cơ quan chính phủ).
    </p>
    <h3>Yêu Cầu Pháp Lý Khác</h3>
    <p>Công Ty có thể tiết lộ Dữ Liệu Cá Nhân của Bạn với sự tin tưởng chân thành rằng hành động đó là cần thiết để:</p>
    <ul>
      <li>Tuân thủ nghĩa vụ pháp lý</li>
      <li>Bảo vệ và bảo vệ các quyền hoặc tài sản của Công Ty</li>
      <li>Ngăn chặn hoặc điều tra các hành vi sai trái liên quan đến Dịch Vụ</li>
      <li>Bảo vệ an toàn cá nhân của Người Dùng Dịch Vụ hoặc công chúng</li>
      <li>Bảo vệ trách nhiệm pháp lý</li>
    </ul>
    <h2>Bảo Mật Dữ Liệu Cá Nhân Của Bạn</h2>
    <p>
      Bảo mật Dữ Liệu Cá Nhân của Bạn là quan trọng đối với Chúng Tôi, nhưng hãy nhớ rằng không có phương thức truyền qua Internet hoặc phương thức lưu trữ điện tử nào là hoàn toàn an toàn. Trong khi
      Chúng Tôi nỗ lực sử dụng các phương tiện được chấp nhận về mặt thương mại để bảo vệ Dữ Liệu Cá Nhân của Bạn, Chúng Tôi không thể đảm bảo an ninh tuyệt đối của nó.
    </p>
    <h2>Thông Tin Chi Tiết Về Việc Xử Lý Dữ Liệu Cá Nhân Của Bạn</h2>
    <p>
      Các Nhà Cung Cấp Dịch Vụ mà Chúng Tôi sử dụng có thể có quyền truy cập vào Dữ Liệu Cá Nhân của Bạn. Các nhà cung cấp bên thứ ba này thu thập, lưu trữ, sử dụng, xử lý và chuyển thông tin về hoạt
      động của Bạn trên Dịch Vụ của Chúng Tôi theo Chính Sách Bảo Mật của họ.
    </p>
    <h3>Phân Tích</h3>
    <p>Chúng Tôi có thể sử dụng các Nhà Cung Cấp Dịch Vụ bên thứ ba để giám sát và phân tích việc sử dụng Dịch Vụ của Chúng Tôi.</p>
    <ul>
      <li>
        <p><strong>Google Analytics</strong></p>
        <p>
          Google Analytics là một dịch vụ phân tích web được cung cấp bởi Google sử dụng dữ liệu được thu thập để theo dõi và kiểm tra việc sử dụng Dịch Vụ của Chúng Tôi. Dữ liệu này được chia sẻ với
          các dịch vụ khác của Google. Google có thể sử dụng dữ liệu được thu thập để ngữ cảnh hóa và cá nhân hóa các quảng cáo trong mạng quảng cáo của riêng mình.
        </p>
        <p>
          Bạn có thể từ chối việc cung cấp hoạt động của mình trên Dịch Vụ cho Google Analytics bằng cách cài đặt tiện ích chặn trình duyệt Google Analytics. Tiện ích này ngăn cản JavaScript của
          Google Analytics (ga.js, analytics.js và dc.js) chia sẻ thông tin với Google Analytics về hoạt động truy cập.
        </p>
        <p>
          Để biết thêm thông tin về các thực tiễn bảo mật của Google, vui lòng truy cập trang web của Google
          <a href="https://policies.google.com/privacy?hl=en">Privacy & Terms</a> trang.
        </p>
      </li>
    </ul>
    <h3>Quảng Cáo</h3>

    <p>Chúng Tôi có thể sử dụng các Nhà Cung Cấp Dịch Vụ để hiển thị quảng cáo cho Bạn để giúp hỗ trợ và duy trì Dịch Vụ của Chúng Tôi.</p>
    <ul>
      <li>
        <p><strong>Google AdSense và DoubleClick Cookie</strong></p>
        <p>
          Google, như một nhà cung cấp bên thứ ba, sử dụng cookie để phục vụ quảng cáo trên Dịch Vụ của Chúng Tôi. Việc sử dụng cookie của Google cho phép nó và các đối tác của nó phục vụ quảng cáo
          cho Người Dùng của Chúng Tôi dựa trên việc họ truy cập vào Dịch Vụ của Chúng Tôi hoặc các trang web khác trên Internet.
        </p>
        <p>
          Bạn có thể từ chối sử dụng DoubleClick Cookie cho quảng cáo dựa trên sở thích bằng cách truy cập trang web của Google Ads Settings:
          <a href="http://www.google.com/ads/preferences/">Google Ads Settings</a>.
        </p>
      </li>
    </ul>
    <h3>Tiếp Thị Lại Hành Vi</h3>
    <p>
      Công Ty sử dụng các dịch vụ tiếp thị lại để quảng cáo cho Bạn trên các trang web của bên thứ ba sau khi Bạn đã truy cập hoặc sử dụng Dịch Vụ của Chúng Tôi. Chúng Tôi và các nhà cung cấp bên thứ
      ba của Chúng Tôi sử dụng cookie để thông báo, tối ưu hóa và phục vụ quảng cáo dựa trên các lần truy cập trước của Bạn vào Dịch Vụ của Chúng Tôi.
    </p>
    <ul>
      <li>
        <p><strong>Quảng Cáo của Google (AdWords)</strong></p>
        <p>Dịch vụ tiếp thị lại của Google AdWords được cung cấp bởi Google.</p>
        <p>
          Bạn có thể từ chối Google Analytics cho Quảng Cáo hiển thị và tùy chỉnh các quảng cáo của Mạng hiển thị của Google bằng cách truy cập trang cài đặt Google Ads Settings:
          <a href="http://www.google.com/settings/ads">Google Ads Settings</a>.
        </p>
        <p>
          Google cũng khuyến nghị cài đặt Tiện Ích Trình Duyệt Chặn Google Analytics:
          <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>.
        </p>
        <p>
          Để biết thêm thông tin về các thực tiễn bảo mật của Google, vui lòng truy cập trang web của Google Privacy & Terms:
          <a href="https://policies.google.com/privacy?hl=en">Google Privacy & Terms</a>.
        </p>
      </li>
      <li>
        <p><strong>Tiếp Thị Lại của Facebook</strong></p>
        <p>Dịch vụ tiếp thị lại của Facebook được cung cấp bởi Facebook Inc.</p>
        <p>
          Bạn có thể tìm hiểu thêm về quảng cáo dựa trên sở thích từ Facebook bằng cách truy cập trang này:
          <a href="https://www.facebook.com/help/164968693837950">Facebook Help</a>.
        </p>
        <p>
          Để từ chối quảng cáo dựa trên sở thích của Facebook, hãy làm theo các hướng dẫn này từ Facebook:
          <a href="https://www.facebook.com/help/568137493302217">Facebook Settings</a>.
        </p>
        <p>
          Facebook tuân thủ các nguyên tắc tự điều chỉnh của Digital Advertising Alliance. Bạn cũng có thể từ chối Facebook và các công ty khác tham gia qua Digital Advertising Alliance tại Hoa Kỳ
          <a href="http://www.aboutads.info/choices/">DAA</a>, Digital Advertising Alliance của Canada tại Canada <a href="http://youradchoices.ca/">DAAC</a> hoặc Liên Minh Quảng Cáo Kỹ Thuật Số Tương
          Tác tại châu Âu <a href="http://www.youronlinechoices.eu/">EDAA</a> hoặc từ chối bằng cách sử dụng cài đặt thiết bị di động của bạn.
        </p>
        <p>
          Để biết thêm thông tin về các thực tiễn bảo mật của Facebook, vui lòng truy cập Chính Sách Dữ Liệu của Facebook: <a href="https://www.facebook.com/privacy/explanation">Facebook Privacy</a>.
        </p>
      </li>
    </ul>
    <h2>Quyền Riêng Tư CCPA của Bạn</h2>
    <p>Phần quyền riêng tư này dành cho cư dân California và bổ sung cho các thông tin chứa trong Chính Sách Bảo Mật này.</p>
    <h3>Quyền của Người Tiêu Dùng Dưới CCPA</h3>
    <p>Theo CCPA, cư dân California có quyền:</p>
    <ul>
      <li>Yêu cầu một doanh nghiệp tiết lộ các loại Dữ Liệu Cá Nhân và các phần cụ thể của Dữ Liệu Cá Nhân mà doanh nghiệp đã thu thập về người tiêu dùng.</li>
      <li>Yêu cầu một doanh nghiệp xóa bất kỳ Dữ Liệu Cá Nhân nào về người tiêu dùng mà doanh nghiệp đã thu thập.</li>
      <li>Yêu cầu một doanh nghiệp bán Dữ Liệu Cá Nhân của người tiêu dùng, không bán Dữ Liệu Cá Nhân của người tiêu dùng.</li>
    </ul>
    <p>Công Ty không bán Dữ Liệu Cá Nhân. Nếu Bạn gửi yêu cầu, Chúng Tôi có một tháng để trả lời Bạn. Nếu Bạn muốn thực hiện bất kỳ quyền nào trong số này, vui lòng liên hệ với Chúng Tôi.</p>
    <h3>Các Dịch Vụ Được Sử Dụng Để Phân Tích Dữ Liệu</h3>
    <p>
      Chúng Tôi có thể sử dụng các dịch vụ Phân Tích của bên thứ ba để giám sát và phân tích việc sử dụng Dịch Vụ của Chúng Tôi. Các bên thứ ba này có quyền truy cập vào Dữ Liệu Cá Nhân của Bạn và xử
      lý dữ liệu này thay mặt cho Chúng Tôi để thực hiện các nhiệm vụ được yêu cầu.
    </p>
    <ul>
      <li>
        <p><strong>Google Analytics</strong></p>
        <p>
          Google Analytics là một dịch vụ phân tích web do Google cung cấp giúp theo dõi và báo cáo lưu lượng truy cập trang web. Google sử dụng dữ liệu được thu thập để theo dõi và giám sát việc sử
          dụng Dịch Vụ của Chúng Tôi. Dữ liệu này được chia sẻ với các dịch vụ khác của Google. Google có thể sử dụng dữ liệu thu thập được để ngữ cảnh hóa và cá nhân hóa quảng cáo của mạng quảng cáo
          của riêng mình.
        </p>
        <p>
          Bạn có thể từ chối việc cung cấp hoạt động của mình trên Dịch Vụ cho Google Analytics bằng cách cài đặt tiện ích chặn trình duyệt Google Analytics. Tiện ích này ngăn chặn JavaScript của
          Google Analytics (ga.js, analytics.js và dc.js) chia sẻ thông tin với Google Analytics về hoạt động truy cập.
        </p>
        <p>
          Để biết thêm thông tin về các thực tiễn bảo mật của Google, vui lòng truy cập trang web của Google
          <a href="https://policies.google.com/privacy?hl=en">Google Privacy & Terms</a>.
        </p>
      </li>
    </ul>
    <h2>Quyền Bảo Mật Dữ Liệu GDPR của Bạn</h2>
    <p>
      Nếu Bạn là cư dân của Khu Vực Kinh Tế Châu Âu (EEA), Bạn có một số quyền bảo vệ dữ liệu nhất định. Công Ty nhằm thực hiện các bước hợp lý để cho phép Bạn chỉnh sửa, sửa đổi, xóa bỏ hoặc giới hạn
      việc sử dụng Dữ Liệu Cá Nhân của Bạn.
    </p>
    <p>Nếu Bạn muốn được thông báo về Dữ Liệu Cá Nhân mà Chúng Tôi nắm giữ về Bạn và nếu Bạn muốn dữ liệu đó bị xóa khỏi hệ thống của Chúng Tôi, vui lòng liên hệ với Chúng Tôi.</p>
    <p>Trong một số trường hợp nhất định, Bạn có các quyền bảo vệ dữ liệu sau:</p>
    <ul>
      <li>
        Quyền truy cập, cập nhật hoặc xóa thông tin mà Chúng Tôi có trên Bạn. Bất cứ khi nào có thể, Bạn có thể truy cập, cập nhật hoặc yêu cầu xóa Dữ Liệu Cá Nhân của mình trực tiếp trong phần cài
        đặt tài khoản của Bạn. Nếu Bạn không thể thực hiện các hành động này, vui lòng liên hệ với Chúng Tôi để được hỗ trợ.
      </li>
      <li>Quyền chỉnh sửa. Bạn có quyền yêu cầu thông tin của Bạn được chỉnh sửa nếu thông tin đó không chính xác hoặc không đầy đủ.</li>
      <li>Quyền phản đối. Bạn có quyền phản đối việc xử lý Dữ Liệu Cá Nhân của Bạn.</li>
      <li>Quyền hạn chế. Bạn có quyền yêu cầu Chúng Tôi hạn chế việc xử lý thông tin cá nhân của Bạn.</li>
      <li>Quyền di chuyển dữ liệu. Bạn có quyền được cung cấp một bản sao thông tin mà Chúng Tôi có trên Bạn ở định dạng có cấu trúc, máy đọc được và thường được sử dụng.</li>
      <li>Qu yền rút lại sự đồng ý. Bạn cũng có quyền rút lại sự đồng ý của mình bất cứ lúc nào mà Công Ty đã dựa vào sự đồng ý của Bạn để xử lý thông tin cá nhân của Bạn.</li>
    </ul>
    <p>Xin lưu ý rằng Chúng Tôi có thể yêu cầu Bạn xác minh danh tính của mình trước khi trả lời các yêu cầu đó.</p>
    <p>
      Bạn có quyền phàn nàn với Cơ Quan Bảo Vệ Dữ Liệu về việc Chúng Tôi thu thập và sử dụng Dữ Liệu Cá Nhân của Bạn. Để biết thêm thông tin, vui lòng liên hệ với cơ quan bảo vệ dữ liệu địa phương
      trong Khu Vực Kinh Tế Châu Âu (EEA).
    </p>
    <h2>Quyền Bảo Mật Dữ Liệu của Bạn tại Brazil</h2>
    <p>
      Nếu Bạn ở Brazil, Bạn có các quyền bảo vệ dữ liệu nhất định theo Luật Bảo Vệ Dữ Liệu Cá Nhân Brazil (Lei Geral de Proteção de Dados, “LGPD”), bao gồm quyền truy cập, sửa đổi, hủy bỏ hoặc giới
      hạn việc xử lý Dữ Liệu Cá Nhân của Bạn. Nếu Bạn muốn được thông báo về Dữ Liệu Cá Nhân mà Chúng Tôi nắm giữ về Bạn và nếu Bạn muốn dữ liệu đó bị xóa khỏi hệ thống của Chúng Tôi, vui lòng liên hệ
      với Chúng Tôi.
    </p>
    <p>Công Ty nhằm thực hiện các bước hợp lý để cho phép Bạn chỉnh sửa, sửa đổi, xóa bỏ hoặc giới hạn việc sử dụng Dữ Liệu Cá Nhân của Bạn.</p>
    <h2>Quyền Bảo Mật Dữ Liệu của Bạn tại Thụy Sĩ</h2>
    <p>
      Nếu Bạn ở Thụy Sĩ, Bạn có các quyền bảo vệ dữ liệu nhất định theo Đạo Luật Bảo Vệ Dữ Liệu Liên Bang Thụy Sĩ (“Swiss DPA”), bao gồm quyền truy cập, sửa đổi, hủy bỏ hoặc giới hạn việc xử lý Dữ
      Liệu Cá Nhân của Bạn. Nếu Bạn muốn được thông báo về Dữ Liệu Cá Nhân mà Chúng Tôi nắm giữ về Bạn và nếu Bạn muốn dữ liệu đó bị xóa khỏi hệ thống của Chúng Tôi, vui lòng liên hệ với Chúng Tôi.
    </p>
    <p>Công Ty nhằm thực hiện các bước hợp lý để cho phép Bạn chỉnh sửa, sửa đổi, xóa bỏ hoặc giới hạn việc sử dụng Dữ Liệu Cá Nhân của Bạn.</p>
    <h2>Liên Kết Đến Các Trang Web Khác</h2>
    <p>
      Dịch Vụ của Chúng Tôi có thể chứa các liên kết đến các trang web khác không do Chúng Tôi vận hành. Nếu Bạn nhấp vào liên kết của bên thứ ba, Bạn sẽ được chuyển hướng đến trang web của bên thứ ba
      đó. Chúng Tôi khuyến cáo Bạn xem lại Chính Sách Bảo Mật của mỗi trang web mà Bạn truy cập.
    </p>
    <p>Chúng Tôi không kiểm soát và không chịu trách nhiệm đối với nội dung, chính sách bảo mật hoặc thực tiễn của bất kỳ trang web hoặc dịch vụ bên thứ ba nào.</p>
    <h2>Thay Đổi Chính Sách Bảo Mật Này</h2>
    <p>Chúng Tôi có thể cập nhật Chính Sách Bảo Mật của mình theo thời gian. Chúng Tôi sẽ thông báo cho Bạn về bất kỳ thay đổi nào bằng cách đăng Chính Sách Bảo Mật mới trên trang này.</p>
    <p>Chúng Tôi sẽ cho Bạn biết qua email và/hoặc một thông báo nổi bật trên Dịch Vụ của Chúng Tôi, trước khi thay đổi có hiệu lực và cập nhật ngày “Có hiệu lực từ” ở đầu Chính Sách Bảo Mật này.</p>
    <p>Bạn được khuyên nên xem lại Chính Sách Bảo Mật này định kỳ để biết bất kỳ thay đổi nào. Các thay đổi đối với Chính Sách Bảo Mật này có hiệu lực khi chúng được đăng trên trang này.</p>
    <h2>Liên Hệ Chúng Tôi</h2>
    <p>Nếu Bạn có bất kỳ câu hỏi nào về Chính Sách Bảo Mật này, Bạn có thể liên hệ với Chúng Tôi:</p>
    <ul>
      <li>Qua email: radteam.vpacs&#64;gmail.com</li>
    </ul>
  </div>
</div>
