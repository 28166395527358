import { IDcmTag, IImage, TAG_KEY } from '@app/@core';
import { IOverlay, IOverlayItem } from '@app/setting';
import { STATIC_OVERLAY } from '@app/viewer/contants';
import { ITileOverlay, ITileOverlayItem } from '@app/viewer/models';
import { StackViewport, Types, VolumeViewport, cache, metaData, utilities } from '@cornerstonejs/core';

/**
 * Concatenates the given value with the prefix and suffix of the overlay item.
 * @param overlay - The overlay item.
 * @param value - The value to be concatenated.
 * @returns The concatenated string.
 */
const _overlayString = (overlay: IOverlayItem, value: any) => `${overlay.prefix}${value}${overlay.suffix}`;
//#region STACK OVERLAY
/**
 * Retrieves the value for a given overlay item based on the provided viewport and other parameters.
 * @param viewport - The stack viewport.
 * @param overlay - The tile overlay item.
 * @param totalImages - The total number of images.
 * @param tileIndex - The tile index (default: 0).
 * @param tag - The Dcm tag (optional).
 * @returns The value for the overlay item.
 */
const getOverlayValue = async (viewport: StackViewport | VolumeViewport, overlay: ITileOverlayItem, totalImages: number, tileIndex: number = 0) => {
  const imageId = _getImageIndex(viewport);
  switch (overlay.id) {
    case STATIC_OVERLAY.PATIENT_NAME:
      return _overlayString(overlay, metaData.get(TAG_KEY.PATIENT_NAME, imageId));
    //return _overlayString(overlay, tag?.patientInfo.patientName);
    case STATIC_OVERLAY.VOI:
      return _overlayString(overlay, _getVoi(viewport));
    case STATIC_OVERLAY.ROTATION:
      return _overlayString(overlay, _getRotation(viewport));
    case STATIC_OVERLAY.SCALE:
      return _overlayString(overlay, _getScale(viewport));
    case STATIC_OVERLAY.INDEX:
      const imageIndex = viewport.getCurrentImageIdIndex() + 1;
      const overlayStr = `${imageIndex + tileIndex}/${totalImages}`;
      return _overlayString(overlay, overlayStr);
    case STATIC_OVERLAY.SLIDE_THICKNESS:
      if (viewport instanceof VolumeViewport) {
        return _overlayString(overlay, viewport.getSlabThickness());
      } else {
        return '';
      }
    case STATIC_OVERLAY.IMAGE_SIZE:
      const imageData = viewport.getImageData();
      if (imageData === undefined) {
        return '';
      }
      //dimission[0]:row, dimission[1]:column
      return _overlayString(overlay, _overlayString(overlay, `${imageData.dimensions[0]}x${imageData.dimensions[1]}`));
    // case STATIC_OVERLAY.INSTANCE_NUMBER:
    //   const images: IImage = <IImage>viewport.getCornerstoneImage();
    //   return _overlayString(overlay, images.data.string(overlay.tag) || '');
    default:
      const str = metaData.get(overlay.tag, imageId);
      if (str) {
        return _overlayString(overlay, str);
      }
      return '';
  }
};

/**
 * Gets the image index for the given viewport.
 * If the viewport is a StackViewport, it returns the current image ID.
 * If the viewport is a VolumeViewport, it returns the first image ID of the associated volume.
 * @param viewport - The StackViewport or VolumeViewport instance.
 * @returns The image index.
 */
const _getImageIndex = (viewport: StackViewport | VolumeViewport) => {
  if (viewport instanceof StackViewport) {
    return viewport.getCurrentImageId();
  } else {
    //@ts-ignore
    const volumeId = viewport.getVolumeId();
    const volume = cache.getVolume(volumeId);
    if (!volume) {
      return '';
    }
    const { imageIds } = volume;
    return imageIds[0];
  }
};

/**
 * Gets the VOI (Window Width/Window Level) string for the given viewport.
 * @param viewport - The stack viewport.
 * @returns The VOI string in the format "WW:windowWidth/WL:windowCenter".
 */
const _getVoi = (viewport: StackViewport | VolumeViewport): string => {
  const { voiRange } = viewport.getProperties();
  if (voiRange === undefined || voiRange === null) {
    return '';
  }

  const { lower, upper } = voiRange;
  const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(lower, upper);
  return `WW:${Math.round(windowWidth * 100) / 100}/WL:${Math.round(windowCenter * 100) / 100}`;
};

/**
 * Gets the rotation value of the viewport.
 * @param viewport - The stack viewport.
 * @returns The rotation value of the viewport.
 */
const _getRotation = (viewport: StackViewport | VolumeViewport): number => {
  return Math.floor(viewport.getRotation()) || 0;
};

/**
 * Calculates the scale of the viewport.
 * @param viewport - The stack viewport.
 * @returns The scale of the viewport.
 */
const _getScale = (viewport: StackViewport | VolumeViewport): number => {
  return Math.floor(viewport.getZoom() * 100) || 100;
};
//#endregion

export { getOverlayValue };
