export enum Measure {
  Length,
  Angle,
  CobbAngle,
  ROIEllipse,
  ROIRectangle,
  Intensity,
  Bidirectional,
  CircleROI,
  PlannarFreehandROI,
  None,
}
