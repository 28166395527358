import { createFeature, createReducer, on } from '@ngrx/store';
import { shellActions } from './shell.actions';
export const SHELL_KEY = 'shellApp';

export interface ShellState {
  settingDialog: {
    visible: boolean;
  };
}
const initialState: ShellState = {
  settingDialog: {
    visible: false,
  },
};

//#region  reducer
export const shellFeature = createFeature({
  name: SHELL_KEY,
  reducer: createReducer<ShellState>(
    initialState,
    on(
      shellActions.changeSettingDialogVisible,
      (state, action): ShellState => ({
        ...state,
        settingDialog: {
          visible: action.visible,
        },
      }),
    ),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = shellFeature;
