import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { UploadComponent } from './upload.component';
import { Shell } from '@app/shell/shell.service';
import { APP_ROUTE_BASE } from '@app/@core';
import { CanDeactivateGuard } from '@app/auth';

const routes: Routes = [
  Shell.childRoutes([
    // { path: '', redirectTo: '/upload', pathMatch: 'full' },
    {
      path: APP_ROUTE_BASE.UPLOAD,
      component: UploadComponent,
      canDeactivate: [CanDeactivateGuard],
      data: { title: marker('UploadStudy') },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class UploadRoutingModule {}
