import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { CornerstoneService, IDcmTag, IImage, IImagePrefetch, MODALITY_NAME, sortByEchoTime, sortByInstanceNumber, sortBySliceLocation, Threads } from '@app/@core';
import { Logger, WindowService } from '@app/@shared';
import { settingQuery } from '@app/setting';
import {
  CameraAction,
  DEFAULT_3D_PRESET,
  DEFAULT_ROTATE_RADIUS,
  DEFAULT_TILE_INDEX,
  FUSION_CT_ID,
  FUSION_ID,
  FUSION_MIP_ID,
  FUSION_PT_ID,
  MPR_VIEWPORT_ID,
  MouseButton,
  Position,
  STATIC_OVERLAY,
  ScrollSync,
  SortCommand,
  SyncScope,
  SyncType,
  Tools,
  ViewerMode,
} from '@app/viewer/contants';
import {
  calculateWWWLRegion,
  capture,
  dropKeyImage,
  getOverlayValue,
  getStackIndexFromTileIndex,
  getViewportZoomScale,
  imageSlice,
  isExistOverlayName,
  pasteKeyImage,
  saveToImage,
  setCtTransferFunctionForVolumeActor,
  setPetColorMapTransferFunctionForVolumeActor,
  setPetTransferFunction,
  settingToOverlay,
  stackScroll,
  viewportOrientationMarkers,
} from '@app/viewer/helpers';
import { RegionAnnotation, getRectangleCoordinates, RegionTool } from '@app/viewer/helpers/annotation';

import {
  IActiveTile,
  IOrientation,
  ISyncFilter,
  ISyncLUT,
  ISyncPan,
  ISyncRotate,
  ISyncScroll,
  ISyncWWWL,
  ISyncWWWLRevert,
  ISyncZoom,
  ITileOverlay,
  ITileSyncData,
  IViewportBackup,
} from '@app/viewer/models';
import { BroadcastService, ICameraBroadcast, SynchronizerService, ToolsService } from '@app/viewer/services';
import { ViewerGeneralQuery, viewerGeneralActions } from '@app/viewer/store/general';
import { ViewerLayoutQuery, layoutActions } from '@app/viewer/store/layout';
import { ViewerMenuQuery, viewerMenuActions } from '@app/viewer/store/menu';

import { CONSTANTS, EVENTS, Enums, StackViewport, Types, VolumeViewport, cache, eventTarget, setVolumesForViewports, triggerEvent, utilities } from '@cornerstonejs/core';
import { Enums as ToolsEnums, Types as ToolsTypes, utilities as ToolUtils } from '@cornerstonejs/tools';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { Rectangle } from '@app/viewer/types';

const { STACK_VIEWPORT_SCROLL, CAMERA_MODIFIED, VOI_MODIFIED, VOLUME_NEW_IMAGE } = Enums.Events;
const { ANNOTATION_COMPLETED } = ToolsEnums.Events;

const logger = new Logger('TileComponent');
@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrl: './tile.component.scss',
})
export class TileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tileIndex: string;
  @Input() tileId: string;
  @Input() tileLayout: string;
  @Input() index: number;
  @Input() style: any;
  @Input() viewportConfig: any;
  @Input() viewportType: Enums.ViewportType;
  @Output() scrollIndex = new EventEmitter<number>();
  @ViewChild('render') render: ElementRef<any>;
  @ViewChild('tile') tile: ElementRef<any>;
  protected displayOverlay$: Observable<boolean>;
  protected isDownloading$: Observable<boolean>;
  protected activeTile$: Observable<IActiveTile | undefined>;
  protected activated: boolean = false;
  protected totalImage: number = 0;
  private _activeTileSub$: Subscription;
  private _cameraBoardcast$: Subscription;
  private _voiSubs$: Subscription;
  private _colormapSubs$: Subscription;
  private _syncTileSubs$: Subscription;
  private _sortImageSubs$: Subscription;
  private _keyImageSubs$: Subscription;
  private _window: any;
  protected tileOverlay: ITileOverlay = {
    topLeft: [],
    topRight: [],
    bottomLeft: [],
    bottomRight: [],
  };
  protected orientation: IOrientation | undefined;
  protected seriesUid: string;
  protected studyUid: string;
  private _isVoiInvert = false;
  private _mode: ViewerMode = ViewerMode.Stack2D;
  private _is3D: boolean = false;
  private _isFullImage: boolean = false;
  private _fullImageTileIndex: string;
  constructor(
    private store: Store,
    private cornerstoneService: CornerstoneService,
    private toolsService: ToolsService,
    private broadcastService: BroadcastService,
    private syncService: SynchronizerService,
    private windowService: WindowService,
    private _cdr: ChangeDetectorRef,
  ) {
    this._window = this.windowService.nativeWindow;
  }
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.displayOverlay$ = this.store.select(ViewerMenuQuery.selectDisplayOverlay);
    this.activeTile$ = this.store.select(ViewerLayoutQuery.selectActiveTile);
    this._activeTileSub$ = this.activeTile$.subscribe((activeTile) => {
      if (activeTile?.tileIndex === this.tileIndex) {
        this.activated = true;
      } else {
        this.activated = false;
      }
    });
  }

  ngOnDestroy(): void {
    this._activeTileSub$?.unsubscribe();
    this._cameraBoardcast$?.unsubscribe();
    this._voiSubs$?.unsubscribe();
    this._colormapSubs$?.unsubscribe();
    this._syncTileSubs$?.unsubscribe();
    this._sortImageSubs$?.unsubscribe();
    this._keyImageSubs$?.unsubscribe();
    //const remove all listener
    this._destroyLinstener();
    this._backupViewport();
    if (this.viewportType === Enums.ViewportType.STACK) {
      this.toolsService.remove2DTools(this.tileIndex);
    } else {
      const currentStackIndex = getStackIndexFromTileIndex(this.tileIndex);
      this.toolsService.removeVolumesViewport(this._mode, currentStackIndex, this.viewportConfig.viewportId, this._is3D);
    }

    this.cornerstoneService.disableViewport(this.tileIndex);
  }

  //#region  Renderer 2D stack/image

  /**
   * Updates the image in the viewport.
   * @param imageIds - An array of image IDs.
   * @param totalImage - The total number of images in the series.
   * @param seriesUid - The series UID (optional).
   * @param studyUid - The study UID (optional).
   * @returns A promise that resolves to a boolean indicating whether the update was successful.
   */
  public updateImage = async (imageIds: string[], totalImage: number, seriesUid: string = '', studyUid: string = ''): Promise<boolean> => {
    try {
      this.totalImage = totalImage;
      let viewport: Types.IStackViewport | null;
      viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (viewport === null) {
        this.onRender2D(seriesUid, studyUid, imageIds, totalImage);
      } else {
        await viewport.setStack(imageIds);
        await this._render2DOverlay(viewport);
      }

      return true;
    } catch (error) {
      logger.error('Error updating image in viewport', error);
      return false;
    }
  };

  /**
   * Renders a 2D image in the viewport.
   *
   * @param seriesUid - The UID of the series.
   * @param studyUId - The UID of the study.
   * @param imageIds - An array of image IDs.
   * @param totalImage - The total number of images in the series.
   * @returns A promise that resolves to a boolean indicating whether the rendering was successful.
   */
  public onRender2D = async (seriesUid: string, studyUId: string, imageIds: string[], totalImage: number): Promise<boolean> => {
    try {
      this.seriesUid = seriesUid;
      this.studyUid = studyUId;
      this.totalImage = totalImage;
      this.cornerstoneService.disableViewport(this.tileIndex);
      this.isDownloading$ = this.store.select(ViewerGeneralQuery.selectDownloadStatusBySeriesUid(seriesUid));
      //Center Full
      //Apply backup data if exist
      let backupData: IViewportBackup;
      //Case is full image, get backup data from store by seriesUid
      this._isFullImage = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectIsFullImage));
      if (this._isFullImage) {
        backupData = <IViewportBackup>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectBackupViewportBySeriesUid(seriesUid)));
        if (backupData) {
          this._fullImageTileIndex = backupData.tileIndex;
        }
      } else {
        //Case is stack 2D, get backup data from store by seriesUid and tileIndex
        backupData = <IViewportBackup>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectBackupViewport(seriesUid, this.tileIndex)));
      }
      //const backupData = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectBackupViewport(this.seriesUid, this.tileIndex)));
      let size = 1;
      const pointValue = 0.5;
      const displayArea = {
        imageArea: [size, size],
        imageCanvasPoint: {
          imagePoint: [pointValue, pointValue],
          canvasPoint: [pointValue, pointValue],
        },
      };
      const config = {
        viewportId: this.render.nativeElement.id,
        element: this.render.nativeElement,
        type: this.viewportType,
        defaultOptions: {
          displayArea,
        },
      };

      //@ts-ignore
      this.cornerstoneService.enableViewport(config);
      let viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (viewport === null) {
        return false;
      }
      //multi tile, we seperate image id to difference array to attach to viewport
      await viewport.setStack(imageIds);

      if (backupData) {
        if (backupData.voiRange) {
          viewport.setProperties({ voiRange: backupData.voiRange, suppressEvents: false });
        }
        if (backupData.colormap) {
          viewport.setProperties({ suppressEvents: false, colormap: backupData.colormap });
        }
        viewport.setZoom(backupData.zoom);
      }
      viewport.render();
      //enable scale overlay tool

      //Add viewport to cornerstone tools services
      this.toolsService.addViewport(viewport.id);
      this.toolsService.changeActiveReferenceLine(viewport.id);
      const isRenderOverlay = await firstValueFrom(this.displayOverlay$);
      if (isRenderOverlay) {
        await this._render2DOverlay(viewport);
      }
      this._start2DSubscribe();
      eventTarget.addEventListener(ANNOTATION_COMPLETED, this._onAnnotationCompleted);
      return true;
    } catch (error) {
      logger.error('Error rendering 2D image in viewport', error);
      return false;
    }
  };

  /**
   * Renders the stack overlay for the current viewport.
   * @private
   * @returns {Promise<void>} A promise that resolves when the stack overlay is rendered.
   */
  private _render2DOverlay = async (viewport: Types.IStackViewport): Promise<void> => {
    // const currentImageId = viewport.getCurrentImageId();
    // const imageTag = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectMetadataImage(currentImageId)));
    // if (imageTag === undefined) {
    //   return;
    // }
    const overlaySetting = await firstValueFrom(this.store.select(settingQuery.selectOverlaySetting));
    if (!overlaySetting) {
      return;
    }
    this.tileOverlay = settingToOverlay(overlaySetting);
    // Default init orientation
    const orientation = viewportOrientationMarkers(viewport, 0, false, false);
    if (orientation !== '') {
      this.orientation = orientation;
    }
    await this._updateAllOverlayValue(viewport);

    const isExistScale = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.SCALE);
    const isExistRotation = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.ROTATION);
    viewport.element.addEventListener(CAMERA_MODIFIED, ((evt: Types.EventTypes.CameraModifiedEvent) => this._onCameraModify(evt, isExistScale, isExistRotation)) as EventListener);
    const isExistScroll = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.INDEX);
    const isExistInstanceNumber = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.INSTANCE_NUMBER);
    viewport.element.addEventListener(STACK_VIEWPORT_SCROLL, ((evt: Types.EventTypes.StackViewportScrollEvent) => this._onViewportScroll(evt, isExistScroll, isExistInstanceNumber)) as EventListener);

    const isExistVoi = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.VOI);
    viewport.element.addEventListener(VOI_MODIFIED, ((evt: Types.EventTypes.VoiModifiedEvent) => this._onVoiModify(evt, isExistVoi)) as EventListener);
  };

  /**
   * Updates the value of all overlays for the given viewport and image tag.
   * @param viewport - The viewport to update.
   * @param volumeId - The ID of the volume.
   * @param imageTag - The image tag.
   */
  private _updateAllOverlayValue = async (viewport: StackViewport | VolumeViewport) => {
    for (const overlayItem of Object.values(this.tileOverlay)) {
      overlayItem.forEach(async (overlay) => {
        const overlayValue = await getOverlayValue(viewport, overlay, this.totalImage, this.index);
        overlay.value = overlayValue;
      });
    }
  };

  /**
   * Starts the 2D subscription for the tile component.
   * Subscribes to camera and colormap broadcasts.
   */
  private _start2DSubscribe = () => {
    this._cameraBoardcast$ = this.broadcastService.camera.subscribe(this._onCameraBroadcast);
    this._colormapSubs$ = this.broadcastService.colormap.subscribe((colormapName) => {
      this._changeColormap(colormapName);
    });
    this._syncTileSubs$ = this.syncService.tileSynchronize.subscribe(this._onSyncTile);

    this._voiSubs$ = this.broadcastService.voi.subscribe((isReset) => {
      this._onVOIStackViewport(isReset);
    });

    this._sortImageSubs$ = this.broadcastService.sortImage.subscribe((sortType) => {
      this._onSortImage(sortType);
    });

    this._keyImageSubs$ = this.broadcastService.keyImage.subscribe((isKeyImage) => {
      this._onKeyImage(isKeyImage);
    });
  };

  /**
   * Removes event listeners for 2D rendering.
   */
  private _destroyLinstener = () => {
    this.render.nativeElement.removeEventListener(STACK_VIEWPORT_SCROLL, this._onViewportScroll);
    this.render.nativeElement.removeEventListener(CAMERA_MODIFIED, this._onCameraModify);
    this.render.nativeElement.removeEventListener(VOI_MODIFIED, this._onVoiModify);
    eventTarget.removeEventListener(ANNOTATION_COMPLETED, this._onAnnotationCompleted);
  };

  /**
   * Handles the camera modification event.
   *
   * @param evt - The camera modified event.
   * @param isExistScale - Indicates whether there is a scale modification.
   * @param isExistRotation - Indicates whether there is a rotation modification.
   */
  private _onCameraModify = (evt: Types.EventTypes.CameraModifiedEvent, isExistScale: boolean, isExistRotation: boolean) => {
    if (isExistScale) {
      this._updateOverlayValue(evt.detail.viewportId, STATIC_OVERLAY.SCALE);
    }
    if (isExistRotation) {
      this._updateOverlayValue(evt.detail.viewportId, STATIC_OVERLAY.ROTATION);
    }
    this._updateOrientation(evt.detail.viewportId, evt.detail);
  };

  /**
   * Handles the stack viewport scroll event.
   *
   * @param evt - The stack viewport scroll event.
   * @param isExistScroll - Indicates whether scroll exists.
   * @param isExistInstanceNumber - Indicates whether instance number exists.
   */
  private _onViewportScroll = (evt: Types.EventTypes.StackViewportScrollEvent, isExistScroll: boolean, isExistInstanceNumber: boolean) => {
    if (this.index === 0) {
      this.scrollIndex.emit(evt.detail.newImageIdIndex);
    }
    if (isExistScroll) {
      this._updateOverlayValue(this.tileId, STATIC_OVERLAY.INDEX);
    }
    if (isExistInstanceNumber) {
      this._updateOverlayValue(this.tileId, STATIC_OVERLAY.INSTANCE_NUMBER);
    }
    this._raiseSyncScrollEvent(evt.detail.newImageIdIndex);
  };

  /**
   * Raises a sync scroll event for the specified index.
   * @param index - The index of the tile.
   */
  private _raiseSyncScrollEvent = async (index: number) => {
    //Raise sync scroll event
    if (this.activated) {
      const isScroll = await firstValueFrom(this.store.select(ViewerMenuQuery.selectSyncScroll));
      if (isScroll) {
        this.syncService.onTileSynchronize({
          type: SyncType.Scroll,
          data: { index },
          seriesUId: this.seriesUid,
          studyUId: this.studyUid,
        });
      }
    }
  };
  /**
   * Event handler for VoiModifiedEvent.
   * Updates the overlay value for the specified viewport.
   *
   * @param evt - The VoiModifiedEvent object.
   * @param isExistVoi - Indicates whether the VOI exists.
   */
  private _onVoiModify = (evt: Types.EventTypes.VoiModifiedEvent, isExistVoi: boolean) => {
    if (isExistVoi) {
      this._updateOverlayValue(evt.detail.viewportId, STATIC_OVERLAY.VOI);
    }
  };

  /**
   * Updates the orientation of a viewport based on the camera modification event.
   *
   * @param viewportId - The ID of the viewport.
   * @param event - The camera modification event.
   */
  private _updateOrientation = (viewportId: string, event: Types.EventTypes.CameraModifiedEventDetail) => {
    const viewport = this.cornerstoneService.getViewport(viewportId);
    const orientation = viewportOrientationMarkers(viewport, Math.floor(event.rotation || 0), event.camera.flipHorizontal, event.camera.flipVertical);
    if (orientation !== '') {
      this.orientation = orientation;
    }
  };

  /**
   * Updates the overlay value for a specific viewport and type.
   * @param viewportId - The ID of the viewport.
   * @param type - The type of the overlay.
   */
  private _updateOverlayValue = async (viewportId: string, type: string) => {
    const viewport = <StackViewport | VolumeViewport>this.cornerstoneService.getViewport(viewportId);
    if (viewport === null || this.tileOverlay === undefined) {
      return;
    }
    for (const overlayItems of Object.values(this.tileOverlay)) {
      for (const overlayItem of overlayItems) {
        if (overlayItem.name === type || overlayItem.id === type) {
          overlayItem.value = await getOverlayValue(viewport, overlayItem, this.totalImage, this.index);
          return;
        }
      }
    }
  };

  /**
   * Handles the completion of an annotation event.
   * @param annotation The completed annotation event.
   */
  private _onAnnotationCompleted = async (annotation: ToolsTypes.EventTypes.AnnotationCompletedEventType) => {
    if (this.activated) {
      // Processs finish draw annotation is region for doing business logic for wwwl roi or key region
      if (annotation.detail.annotation.metadata.toolName === RegionTool.toolName) {
        this.toolsService.removeAnnotationMeasureById(annotation.detail.annotation.annotationUID || '');
        const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
        if (viewport === null) {
          return;
        }
        const regionAnnotation = <RegionAnnotation>annotation.detail.annotation;
        // check is WWWL optimization by ROI
        const isOptimizeWWWL = await firstValueFrom(this.store.select(ViewerMenuQuery.selectActiveWWWLByROI));
        if (isOptimizeWWWL) {
          const wwwl = calculateWWWLRegion(viewport, regionAnnotation);
          //if not valid wwwl, return
          if (wwwl === undefined) {
            this.toolsService.removeAnnotationMeasureById(annotation.detail.annotation.annotationUID || '');
            return;
          }
          const voiRange = utilities.windowLevel.toLowHighRange(wwwl.windowWidth, wwwl.windowCenter);
          // const voiRange: Types.VOIRange = {
          //   upper: wwwl.windowWidth,
          //   lower: wwwl.windowCenter,
          // };
          viewport.setProperties({ voiRange });
          viewport.render();
          if (!(await this._isSync(ViewerMenuQuery.selectSyncWWWL))) {
            return;
          }

          //Get voi range from current viewport
          this.syncService.onTileSynchronize({
            type: SyncType.WWWL,
            data: { voiRange },
            seriesUId: this.seriesUid,
            studyUId: this.studyUid,
          });

          return;
        }

        //Check paste key image
        const isKeyImage = await firstValueFrom(this.store.select(ViewerMenuQuery.selectPasteKeyImageRegion));

        if (isKeyImage) {
          const canvas = await capture(this.tile.nativeElement);
          const { points } = regionAnnotation.data.handles;

          const canvasPoint1 = viewport.worldToCanvas(points[0]);
          const canvasPoint2 = viewport.worldToCanvas(points[3]);
          const rect: Rectangle = getRectangleCoordinates([canvasPoint1, canvasPoint2]);
          const keyImageCanvas = dropKeyImage(canvas, rect);
          const keyImage = pasteKeyImage(keyImageCanvas);
          // saveToImage(keyImage, 'keyimage.jpeg');
          this._window.recieveData(keyImage);
          return;
        }
      }
    }
  };

  //#endregion

  //#region Renderer MPR volume

  /**
   * Renders the MPR (Multi-Planar Reconstruction) for a given series, modality, and volume.
   * @param seriesUid - The UID of the series.
   * @param modality - The modality of the MPR viewport.
   * @param volumeId - The ID of the volume.
   * @returns A promise that resolves to a boolean indicating whether the rendering was successful.
   */
  public onRenderMPR = async (seriesUid: string, studyUId: string, modality: string, volumeId: string): Promise<boolean> => {
    try {
      this.seriesUid = seriesUid;
      this.studyUid = studyUId;
      const viewportConfig = this.viewportConfig;
      if (viewportConfig === undefined || viewportConfig === null) {
        return false;
      }
      viewportConfig.element = this.render.nativeElement;
      this.cornerstoneService.enableViewport(viewportConfig);
      //render 3D volume
      if (viewportConfig.type === Enums.ViewportType.VOLUME_3D) {
        await setVolumesForViewports(this.cornerstoneService.getRenderEngine(), [{ volumeId }], [viewportConfig.viewportId]);
        const viewport3D = this.cornerstoneService.getVolumeViewportById(viewportConfig.viewportId);
        const volumeActor = this.cornerstoneService.getViewportDefaultActor(viewport3D.id);
        utilities.applyPreset(
          //@ts-ignore
          volumeActor,
          CONSTANTS.VIEWPORT_PRESETS.find((preset) => preset.name === DEFAULT_3D_PRESET),
        );
        viewport3D.render();
        //this.toolsService.addToolToVolume3D(MPR_VIEWPORT_ID.VOLUME3D, volumeId);
        this._is3D = true;
        return true;
      } else {
        //Another MPR viewport
        switch (modality) {
          case MODALITY_NAME.CT:
            await setVolumesForViewports(
              this.cornerstoneService.getRenderEngine(),
              [
                {
                  volumeId: volumeId || '',
                  callback: setCtTransferFunctionForVolumeActor,
                },
              ],
              [this.viewportConfig.viewportId],
            );
            break;
          case MODALITY_NAME.PT:
          case MODALITY_NAME.PET:
            await setVolumesForViewports(
              this.cornerstoneService.getRenderEngine(),
              [
                {
                  volumeId: volumeId || '',
                  callback: setPetTransferFunction,
                },
              ],
              [this.viewportConfig.viewportId],
            );
            break;
          default:
            await setVolumesForViewports(
              this.cornerstoneService.getRenderEngine(),
              [
                {
                  volumeId: volumeId || '',
                },
              ],
              [this.viewportConfig.viewportId],
            );
            break;
        }
        const viewport = this.cornerstoneService.getViewport(viewportConfig.viewportId) as VolumeViewport;
        viewport.render();
        this._startVolumeSubscribe();
        this._mode = ViewerMode.MPR;
        this.totalImage = viewport.getNumberOfSlices();
        await this._renderVolumeOverlay(viewport, volumeId);
        this._cdr.detectChanges();
        return true;
      }
    } catch (error) {
      logger.debug(this.tileIndex, 'Error rendering MPR images in viewport', error);
      return false;
    }
  };

  //#endregion

  //#region render FUSION volume

  /**
   * Renders fusion images in the viewport.
   *
   * @param seriesUid - The UID of the series.
   * @param ctVolumeId - The ID of the CT volume.
   * @param ptVolumeId - The ID of the PT volume.
   * @returns A promise that resolves to a boolean indicating whether the rendering was successful.
   */
  public onRenderFusion = async (seriesUid: string, studyUId: string, ctVolumeId: string, ptVolumeId: string): Promise<boolean> => {
    try {
      this.seriesUid = seriesUid;
      this.studyUid = studyUId;
      const viewportConfig = this.viewportConfig;
      if (viewportConfig === undefined || viewportConfig === null) {
        return false;
      }
      viewportConfig.element = this.render.nativeElement;
      this.cornerstoneService.enableViewport(viewportConfig);
      switch (true) {
        case viewportConfig.viewportId.includes(FUSION_CT_ID):
        default:
          await setVolumesForViewports(
            this.cornerstoneService.getRenderEngine(),
            [
              {
                volumeId: ctVolumeId || '',
                callback: setCtTransferFunctionForVolumeActor,
              },
            ],
            [viewportConfig.viewportId],
          );
          break;
        case viewportConfig.viewportId.includes(FUSION_PT_ID):
          await setVolumesForViewports(
            this.cornerstoneService.getRenderEngine(),
            [
              {
                volumeId: ptVolumeId || '',
                callback: setPetTransferFunction,
              },
            ],
            [viewportConfig.viewportId],
          );
          break;
        case viewportConfig.viewportId.includes(FUSION_ID):
          await setVolumesForViewports(
            this.cornerstoneService.getRenderEngine(),
            [
              {
                volumeId: ctVolumeId || '',
                callback: setCtTransferFunctionForVolumeActor,
              },
              {
                volumeId: ptVolumeId || '',
                callback: setPetColorMapTransferFunctionForVolumeActor,
              },
            ],
            [viewportConfig.viewportId],
          );
          break;
        case viewportConfig.viewportId.includes(FUSION_MIP_ID):
          const ptVolume = cache.getVolume(ptVolumeId);
          if (!ptVolume) {
            return false;
          }
          // Calculate size of fullBody pet mip
          const ptVolumeDimensions = ptVolume.dimensions;
          // Only make the MIP as large as it needs to be.
          const slabThickness = Math.sqrt(ptVolumeDimensions[0] * ptVolumeDimensions[0] + ptVolumeDimensions[1] * ptVolumeDimensions[1] + ptVolumeDimensions[2] * ptVolumeDimensions[2]);
          await setVolumesForViewports(
            this.cornerstoneService.getRenderEngine(),
            [
              {
                volumeId: ptVolumeId,
                callback: setPetTransferFunction,
                blendMode: Enums.BlendModes.MAXIMUM_INTENSITY_BLEND,
                slabThickness,
              },
            ],
            [viewportConfig.viewportId],
          );
          break;
      }
      const viewport = this.cornerstoneService.getViewport(viewportConfig.viewportId) as VolumeViewport;
      viewport.render();
      await Threads.Instance.sleep(100);
      this._startVolumeSubscribe();
      this._mode = ViewerMode.Fusion;
      this.totalImage = viewport.getNumberOfSlices();
      this._renderVolumeOverlay(viewport, ctVolumeId !== '' ? ctVolumeId : ptVolumeId);
      this._cdr.detectChanges();
      return true;
    } catch (error) {
      logger.error(this.tileIndex, 'Error rendering fusion images in viewport', error);
      return false;
    }
  };
  //#endregion

  //#region Volume common logic
  /**
   * Starts subscribing to the camera broadcast service to receive camera volume updates.
   */
  private _startVolumeSubscribe = () => {
    this._cameraBoardcast$ = this.broadcastService.camera.subscribe(this._onCameraVolumeBroadcast);
  };

  /**
   * Handles the camera broadcast event for volume viewport.
   * @param payload - The camera broadcast payload.
   */
  private _onCameraVolumeBroadcast = async (payload: ICameraBroadcast) => {
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }

    const action = payload.action;
    //Reset camera for volume viewport(MPR, FUSION)
    if (action === CameraAction.Reset) {
      const viewport = this.cornerstoneService.getVolumeViewportById(this.render.nativeElement.id);
      viewport.resetCamera(true, true, true, true, true);
      return;
    }
  };

  /**
   * Renders the stack overlay for the current viewport.
   * @private
   * @returns {Promise<void>} A promise that resolves when the stack overlay is rendered.
   */
  private _renderVolumeOverlay = async (viewport: VolumeViewport, volumeId: string): Promise<void> => {
    // const currentImageId = viewport.getCurrentImageId() || '';
    // const imageTag = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectMetadataImage(currentImageId)));
    // if (imageTag === undefined) {
    //   return;
    // }
    const overlaySetting = await firstValueFrom(this.store.select(settingQuery.selectVolumeOverlaySetting));
    if (!overlaySetting) {
      return;
    }
    this.tileOverlay = settingToOverlay(overlaySetting);
    // Default init orientation

    const orientation = viewportOrientationMarkers(viewport, 0, false, false);
    if (orientation !== '') {
      this.orientation = orientation;
    }
    await this._updateAllOverlayValue(viewport);

    const isExistScale = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.SCALE);
    const isExistRotation = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.ROTATION);
    viewport.element.addEventListener(CAMERA_MODIFIED, ((evt: Types.EventTypes.CameraModifiedEvent) => this._onCameraModify(evt, isExistScale, isExistRotation)) as EventListener);
    const isExistScroll = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.INDEX);
    viewport.element.addEventListener(VOLUME_NEW_IMAGE, ((evt: Types.EventTypes.VolumeNewImageEvent) => this._onVolumeScroll(evt, isExistScroll)) as EventListener);

    const isExistVoi = isExistOverlayName(this.tileOverlay, STATIC_OVERLAY.VOI);
    viewport.element.addEventListener(VOI_MODIFIED, ((evt: Types.EventTypes.VoiModifiedEvent) => this._onVoiModify(evt, isExistVoi)) as EventListener);
  };

  /**
   * Handles the volume scroll event.
   *
   * @param evt - The volume new image event.
   * @param isExistScroll - Indicates whether scroll exists.
   */
  private _onVolumeScroll = (evt: Types.EventTypes.VolumeNewImageEvent, isExistScroll: boolean) => {
    if (isExistScroll) {
      this._updateOverlayValue(this.tileId, STATIC_OVERLAY.INDEX);
    }
  };
  //#endregion

  //#region Style layout

  //#endregion

  //#region Mouse event
  /**
   * Handles the mouse down event.
   * @param $event - The mouse event object.
   */
  @HostListener('mousedown', ['$event'])
  protected onMouseDown = async ($event: MouseEvent) => {
    // only processing in stack have image
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }
    this._changeActiveTile();
  };
  //#endregion
  //#region Event listener

  /**
   * Handles the mouse wheel event.
   * @param $event - The mouse wheel event object.
   */
  @HostListener('mousewheel', ['$event'])
  protected onMouseWheel = async ($event) => {
    // only processing in stack have image
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }
    this._changeActiveTile();
  };

  /**
   * Handles the mouse up event.
   * @param event - The MouseEvent object.
   */
  @HostListener('window:mouseup', ['$event'])
  protected async onMouseUp(event: MouseEvent): Promise<void> {
    if (this.activated && this.viewportType === Enums.ViewportType.STACK) {
      //Sync WWWL on left mouse up

      const currentTool = await firstValueFrom(this.store.select(ViewerMenuQuery.selectActiveTool));
      let data: ISyncWWWL | ISyncPan | ISyncZoom | ISyncRotate | ISyncFilter | ISyncLUT | ISyncScroll;
      let type: SyncType;
      const viewport = <Types.IStackViewport>this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (viewport) {
        if (event.button === MouseButton.Right) {
          //Check current state of sync WWWL
          if (!(await this._isSync(ViewerMenuQuery.selectSyncWWWL))) {
            return;
          }

          //Get voi range from current viewport
          const { voiRange } = viewport.getProperties();
          if (voiRange === undefined) {
            return;
          }

          type = SyncType.WWWL;
          data = {
            voiRange,
          };
          this.syncService.onTileSynchronize({
            type,
            data,
            seriesUId: this.seriesUid,
            studyUId: this.studyUid,
          });
          return;
        }
        switch (currentTool) {
          case Tools.WWWL:
            //Check current state of sync WWWL
            if (!(await this._isSync(ViewerMenuQuery.selectSyncWWWL))) {
              return;
            }

            //Get voi range from current viewport
            const { voiRange } = viewport.getProperties();
            if (voiRange === undefined) {
              return;
            }

            type = SyncType.WWWL;
            data = {
              voiRange,
            };
            break;
          case Tools.Pan:
            //check current state sync pan
            if (!(await this._isSync(ViewerMenuQuery.selectSyncPan))) {
              return;
            }
            const point = viewport.getPan();
            type = SyncType.Pan;
            data = {
              point,
            };
            break;
          case Tools.Zoom:
            //check current state sync zoom
            if (!(await this._isSync(ViewerMenuQuery.selectSyncZoom))) {
              return;
            }
            const scale = viewport.getZoom();
            const pan = viewport.getPan();
            type = SyncType.Zoom;
            data = {
              scale,
              pan,
            };
            break;
          case Tools.Rotate:
            //check current state sync rotate
            if (!(await this._isSync(ViewerMenuQuery.selectSyncRotate))) {
              return;
            }

            const angle = Math.floor(viewport.getRotation()) || 0;
            type = SyncType.Rotate;
            data = {
              angle,
            };
            break;
          default:
            return;
        }
        this.syncService.onTileSynchronize({
          type,
          data,
          seriesUId: this.seriesUid,
          studyUId: this.studyUid,
        });
      }
    }
  }
  //#endregion

  //#region Tile business logic

  /**
   * Handles the synchronization of tile data.
   * @param data - The tile synchronization data.
   * @returns A Promise that resolves when the synchronization is complete.
   */
  private _onSyncTile = async (data: ITileSyncData) => {
    // only processing in stack have image
    if (this.activated) {
      //Not apply on active tile
      return;
    }
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }

    const scope = await firstValueFrom(this.store.select(ViewerMenuQuery.selectSyncScope));
    switch (scope) {
      case SyncScope.All:
        this._applySyncData(data);
        break;
      case SyncScope.Series:
        if (data.seriesUId === this.seriesUid) {
          this._applySyncData(data);
        }
        break;
      case SyncScope.Study:
        if (data.studyUId === this.studyUid) {
          this._applySyncData(data);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Applies the synchronization data to the tile component.
   * @param syncData - The synchronization data to apply.
   */
  private _applySyncData = async (syncData: ITileSyncData) => {
    const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
    if (viewport === null) {
      return;
    }
    switch (syncData.type) {
      case SyncType.WWWL:
        const { voiRange } = syncData.data as ISyncWWWL;
        viewport.setProperties({ voiRange });
        break;
      case SyncType.WWWLRevert:
        const { isRevert } = syncData.data as ISyncWWWLRevert;
        viewport.setProperties({ invert: isRevert });
        this._isVoiInvert = isRevert;
        this.store.dispatch(viewerMenuActions.onWWWLInvert({ state: this._isVoiInvert }));
        break;
      case SyncType.Pan:
        const { point } = syncData.data as ISyncPan;
        viewport.setPan(point);
        break;
      case SyncType.Zoom:
        const { scale, pan } = syncData.data as ISyncZoom;

        viewport.setZoom(scale);
        viewport.setPan(pan);
        break;
      case SyncType.Rotate:
        const { angle } = syncData.data as ISyncRotate;
        viewport.setProperties({ rotation: angle });
        break;
      case SyncType.Scroll:
        const { index } = syncData.data as ISyncScroll;
        // Increment the index, clamping to the last image if necessary
        const numImages = viewport.getImageIds().length;
        if (index > numImages - 1) {
          return;
        }
        const newImageIndex = await this.syncScrollIndex(index);

        if (newImageIndex === -1) {
          return;
        }
        stackScroll(viewport, newImageIndex);
        // await ToolUtils.jumpToSlice(viewport.element, {
        //   imageIndex: newImageIndex,
        // });
        break;
      case SyncType.LUT:
        const { name } = syncData.data as ISyncLUT;
        viewport.setProperties({ colormap: { name } });
        break;
      default:
        break;
    }
    viewport.render();
  };

  /**
   * Synchronizes the scroll index with the specified index.
   *
   * @param index - The index to synchronize with.
   * @returns A promise that resolves to the synchronized index.
   */
  private syncScrollIndex = async (index: number): Promise<number> => {
    const activeStack = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveStack));
    const currentStackIndex = getStackIndexFromTileIndex(this.tileIndex);
    // Same stack viewport, not apply sync
    if (activeStack?.stackIndex === currentStackIndex) {
      return index;
    } else {
      // different stack viewport, apply sync
      const syncMode = await firstValueFrom(this.store.select(ViewerMenuQuery.selectScrollSync));
      switch (syncMode) {
        case ScrollSync.ImageOrder:
          return index;
        case ScrollSync.ImagePosition:
          const activeTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
          const sourceViewport = <Types.IStackViewport>this.cornerstoneService.getStackViewport(activeTile?.tileIndex || '');
          const targetViewport = <Types.IStackViewport>this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
          index = imageSlice(sourceViewport, targetViewport);
          return index;
        default:
          return index;
      }
    }
  };

  /**
   * Changes the active tile based on the current tile index.
   * If the current active tile is different from the current tile index,
   * dispatches an action to change the active tile.
   */
  private _changeActiveTile = async () => {
    const currentActiveTile = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveTile));
    if (currentActiveTile?.tileIndex !== this.tileIndex) {
      await this.toolsService.changeActiveReferenceLine(this.render.nativeElement.id);
      this.store.dispatch(
        layoutActions.changeActiveTile({
          activeTile: {
            seriesUid: this.seriesUid,
            tileIndex: this.tileIndex,
          },
          toolState: {
            voiInvert: this._isVoiInvert,
          },
        }),
      );
    }
  };

  /**
   * Backs up the current viewport settings for the tile.
   * @returns {Promise<void>} A promise that resolves when the backup is complete.
   */
  private _backupViewport = () => {
    const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
    if (viewport === null) {
      return;
    }
    let tileIndex = this.tileIndex;
    if (this._isFullImage) {
      tileIndex = this._fullImageTileIndex;
    }
    const { voiRange, colormap } = viewport.getProperties();
    const zoom = viewport.getZoom();
    this.store.dispatch(
      viewerGeneralActions.backupViewport({
        viewport: { seriesUid: this.seriesUid, tileIndex: tileIndex, voiRange, zoom, colormap },
      }),
    );
  };

  /**
   * Checks if the given query is in sync with the store.
   * @param query - The query to check.
   * @returns A promise that resolves to a boolean indicating whether the query is in sync.
   */
  private _isSync = async (query: any): Promise<boolean> => {
    return await firstValueFrom(this.store.select(query));
  };

  /**
   * Handles the sorting of images in the tile component.
   * @param sortType - The type of sorting to be applied.
   * @returns A Promise that resolves when the sorting is complete.
   */
  private _onSortImage = async (sortType: SortCommand) => {
    const activeStack = await firstValueFrom(this.store.select(ViewerLayoutQuery.selectActiveStack));
    const currentStackIndex = getStackIndexFromTileIndex(this.tileIndex);
    if (this.viewportType === Enums.ViewportType.STACK && this.seriesUid !== undefined && this.seriesUid !== '' && (this.activated || activeStack?.stackIndex === currentStackIndex)) {
      //Get image prefetch from store
      const images = <IImagePrefetch[]>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectImagePrefetch(this.seriesUid)));
      //If only one image, return
      if (images.length === 0 || images.length === 1) {
        return;
      }
      //get sort direction from store
      const sortDirection = await firstValueFrom(this.store.select(ViewerMenuQuery.selectSortDirection));
      let sortedImages: IImagePrefetch[] = Object.assign([], images);
      //Sort images based on sort type and direction from store setting
      switch (sortType) {
        case SortCommand.InstanceNumber:
          sortByInstanceNumber(sortDirection, sortedImages);
          break;
        case SortCommand.SliceLocation:
          sortBySliceLocation(sortDirection, sortedImages);
          break;
        case SortCommand.EchoTime:
          sortByEchoTime(sortDirection, sortedImages);
          break;
        default:
          break;
      }

      //Update stack viewport with sorted images
      const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (viewport === null) {
        return;
      }
      const imageIds = sortedImages.map((image) => image.imageId);
      await viewport.setStack(imageIds);
      viewport.render();
      await this._updateAllOverlayValue(viewport);
    }
  };

  /**
   * Handles the key image event.
   * @param isKeyImage - Indicates whether the image is a key image.
   */
  private _onKeyImage = async (isKeyImage: boolean) => {
    if (this.activated && this.viewportType === Enums.ViewportType.STACK) {
      const canvas = await capture(this.tile.nativeElement);
      const imageData = pasteKeyImage(canvas);
      // saveToImage(imageData, 'keyImage');
      this._window.recieveData(imageData);
    }
  };
  //#endregion

  //#region Cornerstone tools business logic

  /**
   * Changes the colormap of the active stack viewport.
   * @param colormapName - The name of the colormap to be applied.
   */
  private _changeColormap = async (colormapName: string) => {
    if (this.activated && this.viewportType === Enums.ViewportType.STACK) {
      const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (viewport === null) {
        return;
      }
      viewport.setProperties({ colormap: { name: colormapName } });
      viewport.render();
      const isSyncLUT = await firstValueFrom(this.store.select(ViewerMenuQuery.selectSyncLUT));
      if (isSyncLUT) {
        this.syncService.onTileSynchronize({
          type: SyncType.LUT,
          data: { name: colormapName },
          seriesUId: this.seriesUid,
          studyUId: this.studyUid,
        });
      }
    }
  };

  /**
   * Handles the camera broadcast event.
   * @param payload - The camera broadcast payload.
   */
  private _onCameraBroadcast = async (payload: ICameraBroadcast) => {
    if (this.seriesUid === undefined || this.seriesUid === '') {
      return;
    }

    const action = payload.action;
    //Reset camera for volume viewport(MPR, FUSION)
    if (
      action === CameraAction.Reset &&
      (this.viewportType === Enums.ViewportType.ORTHOGRAPHIC || this.viewportType === Enums.ViewportType.VOLUME_3D || this.viewportType === Enums.ViewportType.PERSPECTIVE)
    ) {
      const viewport = this.cornerstoneService.getVolumeViewportById(this.render.nativeElement.id);
      viewport.resetCamera(true, true, true, true, true);
      return;
    }

    if (this.viewportType === Enums.ViewportType.STACK) {
      const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (viewport === null) {
        return;
      }
      const canvas = viewport.canvas;
      const image = <IImage>viewport.getCornerstoneImage();
      const size = [canvas.clientWidth, canvas.clientHeight] as Types.Point2;
      const zoomScale = getViewportZoomScale(size, viewport.getCamera());
      const isSyncZoom = await this._isSync(ViewerMenuQuery.selectSyncZoom);
      const isSyncPan = await this._isSync(ViewerMenuQuery.selectSyncPan);
      const isSyncRotate = await this._isSync(ViewerMenuQuery.selectSyncRotate);
      let isChange = false;
      switch (action) {
        //#region  Rotate
        case CameraAction.FlipHorizontal:
          if (this.activated || isSyncRotate) {
            const { flipHorizontal } = viewport.getCamera();
            viewport.setCamera({ flipHorizontal: !flipHorizontal });
            isChange = true;
          }

          break;
        case CameraAction.FlipVertical:
          if (this.activated || isSyncRotate) {
            const { flipVertical } = viewport.getCamera();
            viewport.setCamera({ flipVertical: !flipVertical });
            isChange = true;
          }
          break;
        case CameraAction.RotateLeft:
          if (this.activated || isSyncRotate) {
            const { rotation: currentRotation } = viewport.getProperties();
            viewport.setProperties({ rotation: (currentRotation || 0) - DEFAULT_ROTATE_RADIUS });
            isChange = true;
          }
          break;
        case CameraAction.RotateRight:
          if (this.activated || isSyncRotate) {
            const { rotation: currentRotation } = viewport.getProperties();
            viewport.setProperties({ rotation: (currentRotation || 0) + DEFAULT_ROTATE_RADIUS });
            isChange = true;
          }
          break;
        case CameraAction.ResetRotate:
          if (this.activated || isSyncRotate) {
            viewport.setProperties({ rotation: 0 });
            viewport.setCamera({ flipHorizontal: false, flipVertical: false });
            isChange = true;
          }
          break;
        //#endregion
        //#region Zoom
        case CameraAction.ZoomFitAll:
          if (this.activated || isSyncZoom) {
            //calculate scale percent base on canvas size and image size
            const verticalScale = image.height / canvas.height;
            const horizontalScale = image.width / canvas.width;
            if (verticalScale < horizontalScale) {
              viewport.setZoom(zoomScale + verticalScale);
            } else {
              viewport.setZoom(zoomScale + horizontalScale);
            }
            isChange = true;
          }

          break;
        case CameraAction.ZoomFitHeight:
          if (this.activated || isSyncZoom) {
            let scaleHeight = image.height / canvas.height;
            viewport.setZoom(zoomScale + scaleHeight);
            isChange = true;
          }
          break;
        case CameraAction.ZoomFitWidth:
          if (this.activated || isSyncZoom) {
            let scaleWidth = image.width / canvas.width;
            viewport.setZoom(zoomScale + scaleWidth);
            isChange = true;
          }
          break;
        case CameraAction.ResetZoom:
          if (this.activated || isSyncZoom) {
            viewport.resetCamera(true, true);
            isChange = true;
          }
          break;
        //#endregion
        //#region Pan
        case CameraAction.VerticalAlign:
        case CameraAction.HorizontalAlign:
          if (this.activated || isSyncPan) {
            this._onPanAlign(viewport, action, payload.position || Position.Center);
            isChange = true;
          }
          break;
        case CameraAction.ResetPan:
          if (this.activated || isSyncPan) {
            viewport.setPan([0, 0]);
            isChange = true;
          }
          break;
        //#endregion
        default:
          break;
      }
      if (isChange) {
        viewport.render();
      }
    }
  };

  /**
   * Handles the panning alignment of the viewport.
   * @param viewport - The stack viewport.
   * @param action - The camera action.
   * @param position - The position to align the viewport to.
   */
  private _onPanAlign = async (viewport: Types.IStackViewport, action: CameraAction, position: Position) => {
    const canvas = viewport.canvas;
    const zoom = viewport.getZoom();
    const imageData = viewport.getImageData();
    const rows = imageData.dimensions[0];
    const columns = imageData.dimensions[1];
    //Calculate the Half Width and Half Height of tile and image (in image pixel unit)
    const tileHalfWidth = canvas.clientWidth / (2 * zoom);
    const tileHalfHeight = canvas.clientHeight / (2 * zoom);
    const imageHalfWidth = columns / 2;
    const imageHalfHeight = rows / 2;
    const pan = viewport.getPan();
    if (action === CameraAction.HorizontalAlign) {
      switch (position) {
        case Position.Center:
          // Image x position will be go to the center of canvas
          viewport.setPan([0, pan[1]]);
          break;
        case Position.Left:
          // Image x position will be go to the left of canvas
          viewport.setPan([-tileHalfWidth + imageHalfWidth, pan[1]]);
          break;
        case Position.Right:
          // Image x position will be go to the right of canvas
          viewport.setPan([tileHalfWidth - imageHalfWidth, pan[1]]);
          break;
        default:
          viewport.setPan([0, 0]);
          break;
      }
    } else {
      //Vertical align
      switch (position) {
        case Position.Center:
          // Image y position will be go to the center of canvas
          viewport.setPan([pan[0], 0]);
          break;
        case Position.Top:
          // Image y position will be go to the top of canvas
          viewport.setPan([pan[0], -tileHalfHeight + imageHalfHeight]);
          break;
        case Position.Bottom:
          // Image y position will be go to the bottom of canvas
          viewport.setPan([pan[0], tileHalfHeight - imageHalfHeight]);
          break;

        default:
          viewport.setPan([0, 0]);
          break;
      }
    }
  };

  /**
   * Resets the camera for the stack component.
   * If the viewer mode is MPR, it displays the MPR layout.
   * If the viewer mode is not MPR, it displays the fusion layout.
   */
  private _resetAllCamera = async () => {
    // const tileLayout = (await firstValueFrom(this.tileLayout$)) as ITileLayout;
    // if (this.viewportInfo?.mode === ViewerMode.MPR) {
    //   await this._displayMPR(tileLayout);
    // } else {
    //   await this._displayFusion(tileLayout);
    // }
  };
  //#region VOI
  /**
   * Handles the inversion of the VOI (Value of Interest) in the stack viewport.
   * @param isReset - Indicates whether to reset the VOI inversion.
   */
  private _onVOIStackViewport = async (isReset: boolean) => {
    if (this.viewportType === Enums.ViewportType.STACK) {
      const viewport = this.cornerstoneService.getStackViewport(this.render.nativeElement.id);
      if (!viewport) {
        return;
      }
      if (isReset) {
        this._isVoiInvert = false;
        const image = viewport.getCornerstoneImage();
        const { lower, upper } = utilities.windowLevel.toLowHighRange(image.windowWidth as number, image.windowCenter as number);
        viewport.setProperties({
          invert: false,
          voiRange: { lower, upper },
        });
        const DEFAULT_LUT_NAME = 'Grayscale';
        viewport.setProperties({ colormap: { name: DEFAULT_LUT_NAME } });
        viewport.render();
      } else if (this.activated) {
        this._isVoiInvert = !this._isVoiInvert;
        viewport.setProperties({ invert: this._isVoiInvert });
        viewport.render();
        this.store.dispatch(viewerMenuActions.onWWWLInvert({ state: this._isVoiInvert }));
        const isSyncWWWL = await this._isSync(ViewerMenuQuery.selectSyncWWWL);
        if (isSyncWWWL) {
          this.syncService.onTileSynchronize({
            type: SyncType.WWWLRevert,
            data: { isRevert: this._isVoiInvert },
            seriesUId: this.seriesUid,
            studyUId: this.studyUid,
          });
        }
      }
    }
  };
  //#endregion
  //#endregion
}
