const windowWidth = 400;
const windowCenter = 40;

const lower = windowCenter - windowWidth / 2.0;
const upper = windowCenter + windowWidth / 2.0;

const ctVoiRange = { lower, upper };

/**
 * Sets the CT transfer function for a volume actor.
 * @param {Object} options - The options object.
 * @param {Object} options.volumeActor - The volume actor to set the CT transfer function for.
 */
const setCtTransferFunctionForVolumeActor = ({ volumeActor }) => {
  volumeActor.getProperty().getRGBTransferFunction(0).setMappingRange(lower, upper);
};

export { ctVoiRange, setCtTransferFunctionForVolumeActor };
