import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { VIEWER_GENERAL_KEY, ViewerGeneralState, generalFeature } from './general/general.reducer';
import { VIEWER_LAYOUT_KEY, ViewerLayoutState, layoutFeature } from './layout/layout.reducer';
import { VIEWER_CORNERSTONE_KEY, CornerstoneState, cornerstoneFeature } from './cornerstone/cornerstone.reducer';
import { ViewerMenuState, VIEWER_MENU_KEY, menuFeature } from './menu/menu.reducer';
export const VIEWER_KEY = 'viewer';
export type ViewerState = {
  [VIEWER_GENERAL_KEY]: ViewerGeneralState;
  [VIEWER_LAYOUT_KEY]: ViewerLayoutState;
  [VIEWER_CORNERSTONE_KEY]: CornerstoneState;
  [VIEWER_MENU_KEY]: ViewerMenuState;
};

export const viewerReducer = combineReducers<ViewerState>({
  [VIEWER_GENERAL_KEY]: generalFeature.reducer,
  [VIEWER_LAYOUT_KEY]: layoutFeature.reducer,
  [VIEWER_CORNERSTONE_KEY]: cornerstoneFeature.reducer,
  [VIEWER_MENU_KEY]: menuFeature.reducer,
});

//#region  generate selector each entites for feature solector on base store
export const selectViewerModuleState = createFeatureSelector<ViewerState>(VIEWER_KEY);

export const selectViewerGeneralEntities = createSelector(selectViewerModuleState, (state: ViewerState) => state[VIEWER_GENERAL_KEY]);

export const selectViewerLayoutEntities = createSelector(selectViewerModuleState, (state: ViewerState) => state[VIEWER_LAYOUT_KEY]);

export const selectViewerCornerstoneEntities = createSelector(selectViewerModuleState, (state: ViewerState) => state[VIEWER_CORNERSTONE_KEY]);

export const selectVieweMenuEntities = createSelector(selectViewerModuleState, (state: ViewerState) => state[VIEWER_MENU_KEY]);
//#endregion
