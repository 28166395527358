export const STATIC_OVERLAY = {
  VOI: 'voi',
  INSTANCE_NUMBER: 'x00200013',
  ROTATION: 'rotation',
  SCALE: 'scale',
  REALSIZE: 'realSize',
  IMAGE_SIZE: 'imageSize',
  INDEX: 'index',
  PATIENT_NAME: 'patientName',
  SLIDE_THICKNESS: 'SlideThickness',
};
