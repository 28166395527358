import { MPR_REFERENCELINE_LINE_CONTROLLABLE, MPR_REFERENCELINE_LINE_DRAGGABLE_ROTATABLE, MPR_REFERENCELINE_LINE_SLABTHICKNESS_CONTROLSON, MPR_VIEWPORT_COLORS } from '@app/viewer/contants/viewport';

const getReferenceLineColor = (viewportId: string) => {
  return MPR_VIEWPORT_COLORS[viewportId];
};

const getReferenceLineControllable = (viewportId: string) => {
  const index = MPR_REFERENCELINE_LINE_CONTROLLABLE.indexOf(viewportId);
  return index !== -1;
};

const getReferenceLineDraggableRotatable = (viewportId: string) => {
  const index = MPR_REFERENCELINE_LINE_DRAGGABLE_ROTATABLE.indexOf(viewportId);
  return index !== -1;
};

const getReferenceLineSlabThicknessControlsOn = (viewportId: string) => {
  const index = MPR_REFERENCELINE_LINE_SLABTHICKNESS_CONTROLSON.indexOf(viewportId);
  return index !== -1;
};

export { getReferenceLineColor, getReferenceLineControllable, getReferenceLineDraggableRotatable, getReferenceLineSlabThicknessControlsOn };
