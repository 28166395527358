<div class="banner">
  <div class="container">
    <h1 class="font-weight-semibold">HTML 5 ZERO-FOOTPRINT DICOM VIEWER</h1>
    <h6 class="font-weight-normal text-muted pb-3">
      V-PACS là một hệ thống chẩn đoán hình ảnh với Trình Xem DICOM trên web và hệ thống lưu trữ đám mây nhằm mục đích chẩn đoán, xem, lưu trữ và truyền tải hình ảnh y tế.
    </h6>
    <div>
      <button class="btn btn-opacity-light mr-1" onclick="document.getElementById('case-studies-section').scrollIntoView()">Bắt đầu</button>
      <button class="btn btn-opacity-success ml-1" onclick="document.getElementById('case-studies-section').scrollIntoView()">Tìm hiểu thêm</button>
    </div>
    <br />
    <img src="assets/images/landing-page/dicomviewer.jpg" alt="" class="img-fluid" />
  </div>
</div>
<div class="content-wrapper">
  <div class="container">
    <section class="features-overview" id="features-section">
      <div class="content-header">
        <h2>Tính Năng Hệ Thống</h2>
        <h6 class="section-subtitle text-muted">Hệ Thống Chẩn Đoán Hình Ảnh Của Chúng Tôi Có Những Tính Năng Chính Sau</h6>
      </div>
      <div style="text-align: center" class="d-md-flex justify-content-between">
        <div class="grid-margin d-flex justify-content-start">
          <div class="features-width">
            <img src="assets/images/landing-page/cloudhosting.png" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">Lưu Trữ Đám Mây</h5>
            <p class="text-muted">Dữ liệu được lưu trữ trên Google Cloud Platform để cung cấp quyền truy cập an toàn và dễ dàng qua kết nối internet</p>
            <!-- <a href="#"><p class="readmore-link">Đọc thêm</p></a> -->
          </div>
        </div>
        <div class="grid-margin d-flex justify-content-center">
          <div class="features-width">
            <img src="assets/images/landing-page/dicomstandard.jpg" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">Tiêu Chuẩn DICOM</h5>
            <p class="text-muted">Hệ thống được xây dựng theo Tiêu Chuẩn DICOM cho phép hệ thống làm việc với nhiều loại hình ảnh như CT, MRI, XR, US, ....</p>
            <!-- <a href="#"><p class="readmore-link">Đọc thêm</p></a> -->
          </div>
        </div>
        <div class="grid-margin d-flex justify-content-end">
          <div class="features-width">
            <img src="assets/images/landing-page/webbased.png" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">Hệ Thống Trên Web</h5>
            <p class="text-muted">Hệ thống của chúng tôi được tạo ra với các ứng dụng trên web khác nhau để thực hiện quy trình chẩn đoán hình ảnh mà không cần cài đặt.</p>
            <!-- <a href="#"><p class="readmore-link">Đọc thêm</p></a> -->
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="digital-marketing-service" id="digital-marketing-section">
          <div class="row align-items-center">
            <div class="col-12 col-lg-7 grid-margin grid-margin-lg-0" data-aos="fade-right">
              <h3 class="m-0">Chúng tôi cung cấp một phạm vi đầy đủ<br />các dịch vụ Tiếp Thị Số!</h3>
              <div class="col-lg-7 col-xl-6 p-0">
                <p class="py-4 m-0 text-muted">
                  Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.
                </p>
                <p class="font-weight-medium text-muted">
                  Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-5 p-0 img-digital grid-margin grid-margin-lg-0" data-aos="fade-left">
              <img src="assets/images/landing-page/Group1.png" alt="" class="img-fluid" />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-lg-7 text-center flex-item grid-margin" data-aos="fade-right">
              <img src="assets/images/landing-page/Group2.png" alt="" class="img-fluid" />
            </div>
            <div class="col-12 col-lg-5 flex-item grid-margin" data-aos="fade-left">
              <h3 class="m-0">Cơ quan Số hàng đầu<br />cho Giải Pháp Kinh Doanh.</h3>
              <div class="col-lg-9 col-xl-8 p-0">
                <p class="py-4 m-0 text-muted">
                  Được trang bị những tính năng ấn tượng và tối ưu hóa tốt, mẫu này được thiết kế để cung cấp hiệu suất tốt nhất trong mọi hoàn cảnh.
                </p>
                <p class="pb-2 font-weight-medium text-muted">
                  Những tính năng thông minh của nó làm cho nó trở thành một công cụ xây dựng trang web độc lập mạnh mẽ.
                </p>
              </div>
              <button class="btn btn-info">Đọc thêm</button>
            </div>
          </div>
        </section> -->
    <section class="case-studies" id="case-studies-section">
      <div class="row grid-margin">
        <div class="col-12 text-center pb-5">
          <h2>Hướng Dẫn Sử Dụng Của Chúng Tôi</h2>
          <h6 class="section-subtitle text-muted">
            Vui lòng tham khảo hướng dẫn của chúng tôi để sử dụng hệ thống với bốn mô-đun chính: Tải Dữ Liệu Lên, Danh Sách Nghiên Cứu, Trình Xem DICOM và Tạo Báo Cáo.
          </h6>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-primary text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/upload.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around" action="https://www.w3docs.com/">
                  <div>
                    <h6 class="text-white pb-2 px-3">Tìm hiểu thêm về Tải Dữ Liệu Lên</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1EuwdE5BzSv5JGfDiZ8zWor9M7bNbv41wxlw5MKqsQ4g/edit?usp=sharing','_blank')">Đọc Thêm</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Tải Dữ Liệu Lên</h6>
                <p>Về cách tải dữ liệu lên Google Drive</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-warning text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/worklist.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">Tìm hiểu thêm về Danh Sách Công Việc</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1cLxUzOD_NcwJsdKevpJGdsrMunmIl33aGse-Lur-fjY/edit?usp=sharing','_blank')">Đọc Thêm</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Danh Sách Nghiên Cứu</h6>
                <p>Hướng dẫn về cách hiển thị và tương tác với danh sách các nghiên cứu</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-violet text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/viewer.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">Tìm hiểu thêm về Trình Xem DICOM</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1j3vbmD2_dulsb_SR7rjhKjtjDZfOKoQ54O3dzqOKRF8/edit?usp=sharing','_blank')">Đọc Thêm</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Trình Xem DICOM</h6>
                <p>Trình xem để hiển thị dữ liệu hình ảnh tiêu chuẩn DICOM</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card" data-aos="zoom-in" data-aos-delay="600">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-success text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/report.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">Tìm hiểu thêm về Tạo Báo Cáo</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1R1Qe_LieOKwy8XlqaLtAvwHwjujf1TpxEvkyc-ORrMA/edit?usp=sharing','_blank')">Đọc Thêm</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">Tạo Báo Cáo</h6>
                <p>Ứng dụng để tạo báo cáo chẩn đoán hình ảnh</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
