/**
 * Defines the MIME types used in Google Drive.
 */

/**
 * MIME types for Google Drive resources.
 */
export const DRIVE_MIMETYPE = {
  /**
   * MIME type for a Google Drive folder.
   */
  FOLDER: 'application/vnd.google-apps.folder',

  /**
   * MIME type for a Google Drive file.
   */
  FILE: 'application/vnd.google-apps.File',

  /**
   * MIME type for a Google Sheets file.
   */
  SHEET: 'application/vnd.google-apps.spreadsheet',

  /**
   * MIME type for a thumbnail image in Google Drive.
   */
  THUMBNAIL_MIME_TYPE: 'image/jpeg',

  /**
   * MIME type for a DICOMDIR file in Google Drive.
   */
  DICOMDIR: 'application/json',

  /**
   * MIME type for a JSON file in Google Drive.
   */
  JSON: 'application/json',

  /**
   * MIME type for a ZIP file in Google Drive.
   */
  ZIP: 'application/zip',
};

/**
 * Constants for Google Drive folder names.
 */
export const DRIVE_FOLDER_NAME = {
  /**
   * The folder name for DICOM files.
   */
  DICOMFILE: 'Images',
  /**
   * The folder name for reports.
   */
  REPORT: 'Report',
  /**
   * The folder name for thumbnails.
   */
  THUMBNAIL: 'Thumbnails',
};

/**
 * Constants for Google Drive file names.
 */
export const DRIVE_FILE_NAME = {
  /**
   * File name for worklist.
   */
  WORKLIST: 'worklist',
  /**
   * File name prefix for reports.
   */
  REPORT: 'report-',
  /**
   * File name for DICOM directory.
   */
  DICOM_DIR: 'dicomDir',
  /**
   * File name for share info.
   */
  SHARE_INFO: 'shareInfo',
};

/**
 * The Google API endpoint constants.
 */
export const GOOGLE_API_ENDPOINT = {
  /**
   * The upload endpoint for Google Drive.
   *
   * @remarks
   * This endpoint is used to upload files to Google Drive using the Drive API.
   *
   * @see {@link https://developers.google.com/drive/api/v3/reference/files/create}
   */
  UPLOAD: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id',
};

/**
 * Constants for Google Drive file extensions.
 */
export const DRIVE_FILE_EXTENSION = {
  /**
   * File extension for DICOM files.
   */
  DICOM: '.dcm',
  /**
   * File extension for thumbnail images.
   */
  THUMBNAIL: '.jpeg',
  /**
   * File extension for report files.
   */
  REPORT: '.json',
  /**
   * File extension for DICOMDIR files.
   */
  DICOMDIR: '.json',
  /**
   * File extension for ZIP files.
   */
  ZIP: '.zip',
};

export const PATIENT_INFO_SHEET_HEADER = [
  'PatientID',
  'PatientName',
  'BirthDate',
  'Age',
  'Gender',
  'StudyDate',
  'StudyTime',
  'BodyPart',
  'Modality',
  'Manufacture',
  'ImageCount',
  'StudyInstanceUID',
  'ImagesDriveId',
  'ReportDriveId',
  'RegisterDate',
  'DicomdirID',
];

export const DOWNLOAD_URL = (driveId) => `https://www.googleapis.com/drive/v3/files/${driveId}?alt=media`;
export const DOWNLOAD_SHARE_URL = (driveId) => `https://drive.google.com/uc?export=download&id=${driveId}`;
export const THUMBNAIL_VIEW_URL = (driveId) => `https://lh3.googleusercontent.com/d/${driveId}`;
