import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { UploadRoutingModule } from './upload-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@app/i18n';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared';
import { DicomLoaderService } from '@app/@core';
import { PreviewComponent } from './pages/preview/preview.component';
//#region PrimeNG
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { NgbScrollSpyModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldsetModule } from 'primeng/fieldset';
import { PreUploadService } from './services/pre-upload.service';
import { DicomDirService } from './services/dicom-dir.service';
import { UploadService } from './services/upload.service';
//#endregion

import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { RxRenderStrategiesConfig, RX_RENDER_STRATEGIES_CONFIG } from '@rx-angular/cdk/render-strategies';
import { DatabaseService } from './services/database.service';

const FEATURE_RX_ANGULAR_CONFIG: RxRenderStrategiesConfig<string> = {
  primaryStrategy: 'immediate',
  patchZone: false,
};
@NgModule({
  declarations: [UploadComponent, PreviewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgbModule,
    I18nModule,
    RouterModule,
    SharedModule,
    TableModule,
    ContextMenuModule,
    UploadRoutingModule,
    PanelModule,
    ButtonModule,
    ProgressBarModule,
    CheckboxModule,
    CardModule,
    NgbScrollSpyModule,
    FieldsetModule,
    RxFor,
    RxLet,
  ],
  providers: [
    {
      provide: RX_RENDER_STRATEGIES_CONFIG,
      useValue: FEATURE_RX_ANGULAR_CONFIG,
    },
    DicomLoaderService,
    PreUploadService,
    DicomDirService,
    UploadService,
    DatabaseService,
  ],
})
export class UploadModule {}
