import { Rectangle } from '@app/viewer/types';
import { Types, utilities } from '@cornerstonejs/core';

/**
 * Calculates the image coordinates of a rectAngle based on the given points.
 * @param points - An array of points representing the rectAngle.
 * @returns An object containing the left, top, width, and height of the rectAngle.
 */
const getRectangleCoordinates = (points: Array<Types.Point2> | Array<Types.Point3>): Rectangle => {
  const [point0, point1] = points;

  return {
    left: Math.min(point0[0], point1[0]),
    top: Math.min(point0[1], point1[1]),
    width: Math.abs(point0[0] - point1[0]),
    height: Math.abs(point0[1] - point1[1]),
  };
};

/**
 * Checks if the given indices are inside the specified dimensions.
 * @param index1 - The first index.
 * @param index2 - The second index.
 * @param dimensions - The dimensions of the volume.
 * @returns True if both indices are within the dimensions, false otherwise.
 */
const isInsideVolume = (index1, index2, dimensions) => {
  return utilities.indexWithinDimensions(index1, dimensions) && utilities.indexWithinDimensions(index2, dimensions);
};

/**
 * Checks if two rectangles intersect.
 *
 * @param rectA - The coordinates of the first rectangle in the format [left, top, width, height].
 * @param rectB - The coordinates of the second rectangle in the format [left, top, width, height].
 * @returns A boolean indicating whether the two rectangles intersect.
 */
const isTwoRecIntersect = (rectA: number[], rectB: number[]): boolean => {
  const [leftA, topA, widthA, heightA] = rectA;
  const [leftB, topB, widthB, heightB] = rectB;

  const rightA = leftA + widthA;
  const rightB = leftB + widthB;
  const bottomA = topA + heightA;
  const bottomB = topB + heightB;

  if (leftA >= rightB || leftB >= rightA || topA >= bottomB || topB >= bottomA) {
    return false;
  }

  return true;
};
export { getRectangleCoordinates, isInsideVolume, isTwoRecIntersect };
