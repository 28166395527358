import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RouteHelpers, SharedService } from '@app/@shared';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { IFirebaseUser } from '@app/auth/models/IFirebaseUser';
import { IOnpremiseAPI } from '@app/auth/models/IUser';
import { LoginMode, authQuery, firebaseAuthActions } from '@app/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { shellActions } from '@app/shell';
import { viewerDriveActions, viewerFileActions, viewerGeneralActions } from './store/general';
import { ViewerLayoutQuery, layoutActions } from './store/layout';
import { BroadcastService } from './services';
import { BroadcastTool } from './contants';
import { APP_ROUTE_BASE } from '@app/@core';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent implements OnInit, AfterContentInit {
  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private broadcastService: BroadcastService,
    private store: Store,
    private route: Router,
  ) {
    this.selectedId = null;
    this.dcmFile = {};
  }

  @ViewChild('dicomElement') dicomElement: ElementRef;

  public menuItems: MenuItem[] | undefined;
  public userInfo$: Observable<IFirebaseUser | IOnpremiseAPI | undefined>;
  public metadataVisible$: Observable<boolean>;
  public thumb: string = '';
  public files: File[] = [];
  public selectedId: string | null;
  public dcmFile: any;
  public selectedStudyUid: string;
  protected isDisplayDicomInfo: boolean = false;
  protected isDisplayLUTColorbarDialog: boolean = false;
  private _dicomInfo$: Subscription;
  /**
   * Initializes the component.

   */
  ngOnInit() {
    const isGuestMode = this.route.url === '/' + APP_ROUTE_BASE.GUEST_VIEWVER;
    // If the viewer is in guest mode, it dispatches a start guest mode action and hides the preloader.
    if (isGuestMode) {
      this.store.dispatch(viewerGeneralActions.startGuestMode());
      this.sharedService.preloader(false);
      return;
    }

    // If the viewer is not in share mode, it dispatches a start action.
    const driveRouter = RouteHelpers.Instance.collectRouteQueryParams(this.route);
    const isShareMode = this.route.url.includes(APP_ROUTE_BASE.SHARE_VIEWVER);
    // If the main drive is specified in the route query parameters, it loads the drive dicom dir and displays a preloader.
    // Otherwise, it hides the preloader.

    if (driveRouter.main) {
      this.sharedService.preloader(true, this.translateService.instant('StartLoadImage'));
      if (isShareMode) {
        this.store.dispatch(viewerGeneralActions.startShareMode({ params: driveRouter }));
      } else {
        this.store.dispatch(viewerDriveActions.load({ driveIds: driveRouter }));
      }
    } else {
      this.sharedService.preloader(false);
    }
  }

  onSelectedChange(id: string) {
    this.selectedId = id;
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized the content of the component's view.
   * It is called only once after the first ngAfterViewInit.
   */
  ngAfterContentInit(): void {
    this.userInfo$ = this.store.select(authQuery.selectUserInfo);
    this.metadataVisible$ = this.store.select(ViewerLayoutQuery.selectMetadataDisplay);
    this._dicomInfo$ = this.broadcastService.tool.subscribe((tool) => {
      switch (tool) {
        case BroadcastTool.DicomInfo:
          this.isDisplayDicomInfo = true;
          break;
        case BroadcastTool.Colorbar:
          this.isDisplayLUTColorbarDialog = true;
          this.sharedService.preloader(true);
          break;
        default:
          break;
      }
    });
  }

  /**
   * Logs out the user.
   * If the login mode is set to firebase, it dispatches a logout action.
   */
  async logout() {
    const loginMode = await firstValueFrom(this.store.select(authQuery.selectLoginModeInfo));
    if (loginMode === LoginMode.firebase) {
      this.store.dispatch(firebaseAuthActions.logout());
    }
  }

  /**
   * Opens the setting dialog.
   * @param visible - Specifies whether the setting dialog should be visible or not. Default value is true.
   */
  openSettingDialog(visible: boolean = true) {
    this.store.dispatch(shellActions.changeSettingDialogVisible({ visible }));
  }

  /**
   * Handles the file drop event.
   * @param $event The event object containing the dropped files.
   */
  onFileDrop($event) {
    this.sharedService.preloader(true, this.translateService.instant('StartLoadImage'));
    this.store.dispatch(viewerFileActions.load({ files: $event }));
  }

  /**
   * Handles the event when a study is selected.
   *
   * @param $event - The event object containing the selected study UID.
   */
  onSelectedStudy($event: any) {
    this.selectedStudyUid = $event.uid;
  }
}
