import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LANDING_PAGE_ROUTE } from '@app/@core';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit {
  languages = [
    { code: LANDING_PAGE_ROUTE.US, name: 'English (United States)' },
    { code: LANDING_PAGE_ROUTE.JP, name: '日本語 (日本)' },
    { code: LANDING_PAGE_ROUTE.VN, name: 'Tiếng Việt (Việt Nam)' },
  ];

  selectedLanguage: any;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // Extract language from URL
    const path = window.location.pathname.split('/')[1];
    const lang = path || LANDING_PAGE_ROUTE.US;
    this.selectedLanguage = this.languages.find((language) => language.code === lang);
    this.cdr.detectChanges(); // Ensure Angular's change detection is triggered
  }

  onLanguageChange(event: any) {
    const selectedLanguage = event.value.code;
    this.router.navigate([`/${selectedLanguage}`]);
  }
}
