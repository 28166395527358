import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SharedService, WindowService } from '@app/@shared';
import { IFirebaseUser, IOnpremiseAPI, authQuery } from '@app/auth';
import { IReportContent } from '@app/report/models';
import { ICopyContent } from '@app/report/models/ICopy';
import { selectClinicInfoByStudyUid, selectCurrentReport, selectHistoryReports, selectIsReadOnly } from '@app/report/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, first, firstValueFrom } from 'rxjs';
import { DiagnosisContentComponent } from './diagnosis-content/diagnosis-content.component';
import { IDBStudy } from '@app/@database';
import { cloneDeep } from 'lodash';
import { NO_IMAGES_SRC } from '@app/@core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
})
export class ContentComponent implements OnChanges, AfterViewInit, OnInit {
  images: any[] | undefined;
  activatedTab: number;
  historyOpened: boolean;
  @Input() studyUid: string;
  @ViewChild('currentReportEl') currentReportEl: DiagnosisContentComponent;
  protected isHaveHistory: boolean = true;
  protected currentReport: IReportContent | undefined = undefined;
  protected selectedHistoryReport: IReportContent;
  protected historyReports$: Observable<IReportContent[]>;
  protected readOnly$: Observable<boolean>;
  private _userInfo$: Observable<IFirebaseUser | IOnpremiseAPI | undefined>;
  private _window: any;
  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private store: Store,
    private windowService: WindowService,
  ) {
    this.activatedTab = 0;
    this.historyOpened = true;
    this._userInfo$ = this.store.select(authQuery.selectUserInfo);
    this._window = this.windowService.nativeWindow;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.readOnly$ = this.store.select(selectIsReadOnly);
  }

  /**
   * Lifecycle hook that is called when one or more input properties of the component change.
   * @param changes - An object containing the changed properties.
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.studyUid && changes.studyUid.currentValue !== undefined) {
      if (this.studyUid === '') return;
      const user = <IFirebaseUser>await firstValueFrom(this._userInfo$);
      this.historyReports$ = this.store.select(selectHistoryReports(this.studyUid, user.uid));
      const historyReports = await firstValueFrom(this.historyReports$);
      if (historyReports.length > 0) {
        this.selectedHistoryReport = historyReports[0];
        this.activatedTab = 0;
      }

      const currentReport$ = await firstValueFrom(this.store.select(selectCurrentReport(this.studyUid, user.uid)));
      const clinicInfo = <IDBStudy>await firstValueFrom(this.store.select(selectClinicInfoByStudyUid(this.studyUid)));
      if (currentReport$) {
        this.currentReport = cloneDeep(currentReport$);
        this.currentReport.baseDriveId = clinicInfo.reportDriveId;
        this.currentReport.studyUid = clinicInfo.id || '';
      } else {
        const isReadOnly = await firstValueFrom(this.readOnly$);
        if (isReadOnly) {
          return;
        }
        this.currentReport = {
          studyInstanceUid: this.studyUid,
          studyUid: clinicInfo.id || '',
          doctorUid: user.uid,
          doctorName: user.displayName || '',
          baseDriveId: clinicInfo.reportDriveId,
          driveId: '',
          note: '',
          comment: '',
          diagnosis: '',
          finding: '',
          keyImages: [NO_IMAGES_SRC],
          figure: [],
          updatedDate: '',
        };
      }
    }
  }

  ngAfterViewInit() {
    if (this._window.opener !== undefined && this._window.opener !== null) {
      this._window.opener.recieveData = this.recieveData;
      this._window.opener.focusReport = this.focusReport;
    }
  }

  /**
   * Handles the click event of a tab in the report content component.
   *
   * @param history - The history report object associated with the clicked tab.
   * @param index - The index of the clicked tab.
   */
  handleClickTab(history: IReportContent, index: number) {
    this.selectedHistoryReport = history;
    this.activatedTab = index;
  }

  /**
   * Toggles the historyOpened property.
   */
  handleClickToggleHistory() {
    this.historyOpened = !this.historyOpened;
  }

  onRecieveCopyContent = (payload: ICopyContent) => {
    this.currentReportEl.recieveCopyContent(payload);
  };

  /**
   * Receives data and adds a key image to the list of key images.
   * @param keyImageSrc - The source of the key image.
   */
  recieveData = (keyImageSrc: string) => {
    window.focus();
    this.currentReportEl.onRecieveKeyImage(keyImageSrc);
  };

  focusReport = () => {
    window.focus();
  };

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    // Send a message to the parent window
    window.opener.postMessage('closed', window.location.origin);
  }
}
