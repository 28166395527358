import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { BroadcastState, DOWNLOAD_DICOM_THREAD, QueueSignal, QueueState } from '../contants/download-queue';
import { DicomLoaderService, DicomTag, IImage, IImagePrefetch, ImagePrefetch, sortByInstanceNumber } from '@app/@core';
import { Store } from '@ngrx/store';
import { Logger, Severity, SharedService } from '@app/@shared';
import { ViewerGeneralQuery, viewerDriveActions, viewerGeneralActions } from '../store/general';
import { TranslateService } from '@ngx-translate/core';
import { IQueueBroadcast } from '../models/IQueue';
import { SortOrder } from '../contants/sort';
import { getWebWorkerManager } from '@cornerstonejs/core';
import { ISeriesInfo, IStackImage } from '../models';
import { authQuery } from '@app/auth';
import { PreloadService } from './preload.service';
const log = new Logger('download-queue');

const DOWNLOAD_WORKER = {
  NAME: 'download-dcm',
  CONFIG: {
    MAX_THREADS: 2,
    OVERWRITE: true,
  },
  START_FN: 'start',
  DOWNLOAD_DCM_FN: 'downloadDcm',
};
@Injectable({
  providedIn: 'root',
})
export class DownloadQueueService {
  downloadWorkerFn = () => {
    return new Worker(new URL('../workers/download.worker.ts', import.meta.url), {
      name: DOWNLOAD_WORKER.NAME, // name used by the browser to name the worker
    });
  };
  /**
   * Represents the web worker manager.
   */
  private workerManager = getWebWorkerManager();
  private _isDownload: boolean = false;
  constructor(
    private dicomLoader: DicomLoaderService,
    private preloadService: PreloadService,
    private store: Store,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) {
    //initalize webworker for donwload file to google drive
    const options = {
      maxWorkerInstances: DOWNLOAD_WORKER.CONFIG.MAX_THREADS,
      overwrite: DOWNLOAD_WORKER.CONFIG.OVERWRITE,
    };
    this.workerManager.registerWorker(DOWNLOAD_WORKER.NAME, this.downloadWorkerFn, options);
  }

  /**
   * Starts the download process for the given series information.
   *
   * @param seriesInfo - An array of series information.
   * @returns A Promise that resolves when the download process is complete.
   */
  public startDownload = async (seriesInfo: ISeriesInfo[]): Promise<void> => {
    const { token, userUid } = await firstValueFrom(this.store.select(authQuery.selectCredentials));
    this._isDownload = true;
    while (seriesInfo.length > 0) {
      const series = seriesInfo.shift();
      if (!series) {
        continue;
      }
      console.debug(`download: ${series.uid}`);
      const stackInfo = <IStackImage>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUId(series.uid)));
      if (stackInfo.imageDriveId && stackInfo.imageDriveId?.length > 0) {
        const url = <string>stackInfo.imageDriveId[0];
        const res = await (<Promise<File[]>>this.workerManager.executeTask(
          DOWNLOAD_WORKER.NAME,
          DOWNLOAD_WORKER.START_FN,
          {
            token,
            url,
          },
          {
            callbacks: [({ percent }) => {}],
          },
        ));

        const images = await this.dicomLoader.loadFromLocal(res);
        const imagePrefetchs: IImagePrefetch[] = new Array();
        images.imageIds.map((imageId) => {
          const imagePrefetchItem = new ImagePrefetch(imageId);
          const tag = new DicomTag(imageId);
          imagePrefetchItem.init(tag);
          imagePrefetchItem.checkMultiFrame();
          imagePrefetchs.push(imagePrefetchItem);
        });
        //Sort image before dispatch to store
        sortByInstanceNumber(SortOrder.Ascending, imagePrefetchs);
        this.store.dispatch(
          viewerDriveActions.downloadSuccess({
            images: imagePrefetchs,
            seriesUid: imagePrefetchs[0].tag.general.seriesInstanceUID,
          }),
        );
      } else {
        this.store.dispatch(
          viewerDriveActions.downloadSuccess({
            images: [],
            seriesUid: stackInfo.uid,
          }),
        );
      }
    }
    //TODO: Download another series
    this._downloadRemainingStudy(token || '');
  };

  public downloadByUid = async (uids: string[]): Promise<IImagePrefetch[]> => {
    const { token, userUid } = await firstValueFrom(this.store.select(authQuery.selectCredentials));
    const tasks: Promise<File>[] = [];
    for (let index = 0, length = uids.length; index < length; index++) {
      const url = uids[index];
      const task = this.workerManager.executeTask(
        DOWNLOAD_WORKER.NAME,
        DOWNLOAD_WORKER.DOWNLOAD_DCM_FN,
        {
          token,
          url,
        },
        {
          callbacks: [({ percent }) => {}],
        },
      );
      tasks.push(task);
    }
    const dicomFiles = await Promise.all(tasks);
    const images = await this.dicomLoader.loadFromLocal(dicomFiles);
    const imagePrefetchs: IImagePrefetch[] = new Array();
    images.imageIds.map((imageId) => {
      const imagePrefetchItem = new ImagePrefetch(imageId);
      const tag = new DicomTag(imageId);
      imagePrefetchItem.init(tag);
      imagePrefetchItem.checkMultiFrame();
      imagePrefetchs.push(imagePrefetchItem);
    });
    return imagePrefetchs;
  };

  /**
   * Downloads the remaining studies in the download queue.
   * @param token - The authentication token.
   */
  private _downloadRemainingStudy = async (token: string) => {
    const remainingSeries = await firstValueFrom(this.store.select(ViewerGeneralQuery.selectRemainingSeries));
    while (remainingSeries.length > 0) {
      const series = remainingSeries.shift();
      if (!series) {
        continue;
      }
      console.debug(`download remaining: ${series.uid}`);

      const stackInfo = <IStackImage>await firstValueFrom(this.store.select(ViewerGeneralQuery.selectStackImagesBySeriesUId(series.uid)));
      if (stackInfo.imageDriveId && stackInfo.imageDriveId?.length > 0) {
        const imagePrefetchs: IImagePrefetch[] = new Array();
        //download first image
        if (stackInfo.imageDriveId.length >= 1) {
          const firstImage = await this.preloadService.prefetchImage(stackInfo.imageDriveId[0]);
          if (firstImage) {
            imagePrefetchs.push(firstImage);
          }
          if (stackInfo.imageDriveId.length === 1) {
            this.store.dispatch(
              viewerDriveActions.downloadLowPrioritySuccess({
                images: imagePrefetchs,
                seriesUid: stackInfo.uid,
              }),
            );
            continue;
          }
        }
        const url = <string>stackInfo.imageDriveId[1];

        const res = await (<Promise<File[]>>this.workerManager.executeTask(
          DOWNLOAD_WORKER.NAME,
          DOWNLOAD_WORKER.START_FN,
          {
            token,
            url,
          },
          {
            callbacks: [({ percent }) => {}],
          },
        ));

        const images = await this.dicomLoader.loadFromLocal(res);
        images.imageIds.map((imageId) => {
          const imagePrefetchItem = new ImagePrefetch(imageId);
          const tag = new DicomTag(imageId);
          imagePrefetchItem.init(tag);
          imagePrefetchItem.checkMultiFrame();
          imagePrefetchs.push(imagePrefetchItem);
        });
        //Sort image before dispatch to store
        sortByInstanceNumber(SortOrder.Ascending, imagePrefetchs);
        this.store.dispatch(
          viewerDriveActions.downloadLowPrioritySuccess({
            images: imagePrefetchs,
            seriesUid: imagePrefetchs[0].tag.general.seriesInstanceUID,
          }),
        );
      }
      // else {
      //   this.store.dispatch(
      //     viewerDriveActions.downloadSuccess({
      //       images: [],
      //       seriesUid: stackInfo.uid,
      //     }),
      //   );
      // }
    }
    //Finish Download in background another image
    this._isDownload = false;
  };

  /**
   * Checks if a download is currently in progress.
   * @returns {boolean} True if a download is in progress, false otherwise.
   */
  public isDownloading = (): boolean => {
    return this._isDownload;
  };
}
