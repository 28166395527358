import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Logger, Severity, SharedService } from '@app/@shared';
import { Store } from '@ngrx/store';
import { UploadService } from '@app/upload/services/upload.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { DicomDirService } from '@app/upload/services/dicom-dir.service';
import { StudyInfo } from '@app/worklist';
import { LoginMode, authQuery } from '@app/auth';
import { ISeries, IStudy, MODALITY_SEPARATOR, Threads } from '@app/@core';
import { IStudyUpload, IStudyUploadInfo } from '@app/upload/models/IStudyUpload';
import { ISeriesUpload } from '@app/upload/models/ISeriesUpload';
import { DatabaseService } from '@app/upload/services/database.service';
import { ProgressReportType, UploadState } from '@app/upload/constants/enum';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
const log = new Logger('preview');
const TOTAL_GENERAL_STEP = 4;
/**
 * Component for previewing study data and uploading it to a drive.
 */
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnDestroy {
  constructor(
    private store: Store,
    private shareService: SharedService,
    private uploadService: UploadService,
    private dicomDirService: DicomDirService,
    private dbService: DatabaseService,
    private translate: TranslateService,
  ) {}

  public studyUid: string;
  public patientId: string;
  public patientName: string;
  public seriesList: ISeriesUpload[];
  public isUploading: boolean | undefined = undefined;
  protected seriesUploadingId: number = -1;
  protected cloneDeep: any = cloneDeep;
  private _study: IStudyUpload;
  private _totalImages: number = 0;
  private _currentUploadingSeriesIndex: number = -1;
  /**
   * Sets the study data to be previewed and uploaded.
   * @param value - The study data.
   */
  @Input()
  public set studyData(value) {
    this._study = value;
    this.studyUid = value.uid;
    this.patientId = value.patientInfo.patientId;
    this.patientName = value.patientInfo.patientName;
    this.seriesList = value.series;
  }

  /**
   * Event emitted when the upload is done.
   */
  @Output() eventUpload = new EventEmitter<boolean>();

  ngOnDestroy(): void {
    console.log('destroy all preview');
  }

  /**
   * Sets the upload status and emits an event.
   * @param status - The upload status. true: upload success, otherwise failure
   */
  uploadStatus(status: boolean) {
    this.isUploading = false;
    this._totalImages = 0;
    this.eventUpload.emit(status);
  }

  /**
   * Starts the upload of a study.
   *
   * @param isOverride - A boolean indicating whether to override an existing study.
   * @returns A Promise that resolves when the upload is complete.
   * @throws An error if the upload fails.
   */
  public startUploadStudy = async (isOverride: boolean) => {
    try {
      //count total image exist
      for (const key in this.seriesList) {
        if (Object.prototype.hasOwnProperty.call(this.seriesList, key)) {
          this._totalImages += this.seriesList[key].imageObject.length;
        }
      }

      this.isUploading = true;
      const loginMode = await firstValueFrom(this.store.select(authQuery.selectLoginModeInfo));
      if (loginMode === LoginMode.firebase) {
        const { token, userUid } = await firstValueFrom(this.store.select(authQuery.selectCredentials));
        //check is study is exist in database
        const existStudyDb = await this.dbService.checkExistStudy(this.studyUid, userUid || '');
        if (existStudyDb) {
          if (isOverride) {
            //If have setting override, delete study in database and drive
            //delete study in database
            await this.dbService.deleteExistStudy(existStudyDb.id || '');
            //delete drive folder
            await this.dicomDirService.deleteStudyFolder(existStudyDb.baseDriveId);
          } else {
            // Otherwise notify to user and stop upload this study
            this.shareService.toastMessage(Severity.info, this.translate.instant('StudyUploadExist'));
            //add delay 1s to show message to user
            await Threads.Instance.sleep(1000);
            this.uploadStatus(true);
            return;
          }
        }
        const rootFolderID = await this.dicomDirService.GetGoogleDriveRootID();
        await this.uploadToDrive(rootFolderID, token, userUid);
      } else {
        //TODO: apply logic upload to server later
      }
      this.uploadStatus(true);
    } catch (error) {
      log.error(error);
      this.uploadStatus(false);
    }
  };

  /**
   * Uploads the study to Google Drive.
   *
   * @param rootFolderID - The ID of the root folder in Google Drive.
   * @param token - The access token for authentication (optional).
   * @returns A Promise that resolves when the upload is complete.
   */
  public uploadToDrive = async (rootFolderID, token: string = '', userUid: string = ''): Promise<boolean> => {
    // Get Google service information  for uploading dicom file
    const { imgFolderId, studyFolderId, thumbnailFolderId, reportFolderId } = await this.dicomDirService.GetDicomDirID(this._study.patientInfo.patientId, rootFolderID, this.studyUid);

    //Start task upload image to google drive
    let dicomDirInfo = await this.UploadImageToDrive(this.seriesList, imgFolderId, thumbnailFolderId, token);
    if (!dicomDirInfo) {
      return false;
    }
    const baseImage = this.seriesList[0].imageObject[0];

    //count total images
    let total = 0;
    for (let index = 0, length = this.seriesList.length; index < length; index++) {
      var series = this.seriesList[index];
      total += series.imageObject.length;
    }
    //Get modality each series
    const modalityList: string[] = [];
    this.seriesList.map((series) => {
      if (modalityList.includes(series.modality) === false) {
        modalityList.push(series.modality);
      }
    });
    // Add study info to dicom dir
    dicomDirInfo.studyInfo = {
      uid: this.studyUid,
      studyDate: baseImage.tag.general.studyDate,
      studyTime: baseImage.tag.general.studyTime,
      modality: modalityList.join(MODALITY_SEPARATOR),
      bodyPart: baseImage.tag.general.bodyPart,
      totalSeries: this.seriesList.length.toString(),
      totalImage: total.toString(),
    };
    //Create dicom dir and upload to drive
    const dicomdirID = await this.dicomDirService.CreateDicomDir(studyFolderId, dicomDirInfo);

    //Find patient ID
    //update study information into worklist sheet
    const studyInfo = new StudyInfo(this._study.patientInfo.patientId || '', baseImage, total.toString(), imgFolderId, reportFolderId, dicomdirID, modalityList);
    const addRes = await this.dbService.saveStudy(studyInfo, userUid, studyFolderId);
    this.seriesUploadingId = -1;
    return addRes;
  };

  /**
   * Uploads a images to a drive.
   *
   * @param studyUid - The UID of the study.
   * @param seriesData - The series data.
   * @param imgFolderId - The ID of the image folder.
   * @param thumbnailFolderId - The ID of the thumbnail folder.
   * @param token - The token for authentication.
   * @returns A promise that resolves to the DICOM directory of the uploaded study.
   */
  private UploadImageToDrive = async (seriesData: ISeriesUpload[], imgFolderId: string, thumbnailFolderId: string, token: string): Promise<IStudyUploadInfo | undefined> => {
    try {
      //Loops all series start uploading image
      for (let index = 0, length = seriesData.length; index < length; index++) {
        //Get series data
        this._currentUploadingSeriesIndex = index;
        const series = seriesData[index];
        this.seriesUploadingId = series.metadata.seriesNumber || 0;
        series.uploadState = UploadState.uploading;
        //Upload 1st image and thumbnail of series
        // let imageList = cloneDeep(series.imageObject);
        // const firstImage = imageList.shift();
        const firstImage = series.imageObject[0];
        //Upload 1st image
        if (firstImage) {
          const uploadDcm = await this.uploadService.uploadDicomFileToDrive(firstImage, imgFolderId, token, this.progressReportCb);
          series.imagesUploaded.push(uploadDcm);
        }

        //Upload Thumbnail of series
        if (series.thumbnail) {
          //Fetches the thumbnail image and returns it as a Blob object.
          const blob = await (await fetch(series.thumbnail)).blob();
          //Start task upload dicom image to drive
          const uploadThumb = await this.uploadService.uploadingThumbnailToDrive(blob, thumbnailFolderId, token, series.uid);
          series.thumbnailDriveId = uploadThumb;
        }
        const remainingImage = series.imageObject.slice(1);
        if (remainingImage.length > 0) {
          //Upload another image of series
          const otherImage = await this.uploadService.uploadMultiImageToDrive(remainingImage, imgFolderId, token, series.uid, this.progressReportCb);
          //Add uploaded image to series
          series.imagesUploaded.push(otherImage);
        }
        series.uploadState = UploadState.finished;
      }
      return this._study.toUploadData();
    } catch (error) {
      log.error(error);
      return undefined;
    }
  };

  /**
   * Callback function to report progress.
   * @param type - The type of progress being reported.
   * @param percent - The percentage of progress.
   */
  progressReportCb = (type: ProgressReportType, percent: number) => {
    switch (type) {
      case ProgressReportType.uploadDcm:
        this.seriesList[this._currentUploadingSeriesIndex].uploadMessage = this.translate.instant('UploadingDcm', {
          percent,
        });
        break;
      case ProgressReportType.zip:
        this.seriesList[this._currentUploadingSeriesIndex].uploadMessage = this.translate.instant('ZippingDcm', {
          percent,
        });
        break;
      case ProgressReportType.uploadZip:
        this.seriesList[this._currentUploadingSeriesIndex].uploadMessage = this.translate.instant('UploadingZip', {
          percent,
        });
        break;
      default:
        break;
    }
  };
}
