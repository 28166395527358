<ng-container *rxLet="diagnosisContent">
  <h4 class="report-diagnosis__heading">
    <span translate>ReferenceDiagnosisContent</span>
    <span>{{ 'DiagnosisDoctor' | translate: { value: diagnosisContent.doctorName } }}</span>
  </h4>
</ng-container>

<div class="report-diagnosis__content">
  <ng-container *rxLet="diagnosisContent">
    <div class="report-diagnosis__content__info">
      <div class="report-diagnosis__form__item">
        <label class="report-diagnosis__form__item__label" for="overview"
          ><span translate>Finding</span>
          <ng-container *rxIf="isHistoryReport">
            <p-button icon="pi pi-reply" [rounded]="true" [text]="true" severity="secondary" [disabled]="isReadOnly" (click)="onCopyContent(diagnosisContent.finding, 0)"></p-button> </ng-container
        ></label>
        <span class="report-diagnosis__form__item__input">
          <textarea #finding rows="5" id="finding" [disabled]="isHistoryReport || isReadOnly" [(ngModel)]="diagnosisContent.finding"> </textarea>
        </span>
      </div>
      <div class="report-diagnosis__form__item">
        <label class="report-diagnosis__form__item__label" for="overview"
          ><span translate>Diagnosis</span>
          <ng-container *rxIf="isHistoryReport">
            <p-button icon="pi pi-reply" [rounded]="true" [text]="true" severity="secondary" [disabled]="isReadOnly" (click)="onCopyContent(diagnosisContent.diagnosis, 1)"></p-button>
          </ng-container>
        </label>
        <span class="report-diagnosis__form__item__input">
          <textarea #diagnosis rows="5" id="diagnosis" [disabled]="isHistoryReport || isReadOnly" [(ngModel)]="diagnosisContent.diagnosis"></textarea>
        </span>
      </div>
      <div class="report-diagnosis__form__item">
        <label class="report-diagnosis__form__item__label" for="overview"
          ><span translate>Comment</span>
          <ng-container *rxIf="isHistoryReport">
            <p-button icon="pi pi-reply" [rounded]="true" [text]="true" severity="secondary" [disabled]="isReadOnly" (click)="onCopyContent(diagnosisContent.diagnosis, 2)"></p-button> </ng-container
        ></label>
        <span class="report-diagnosis__form__item__input">
          <textarea #comment rows="5" id="comment" [disabled]="isHistoryReport || isReadOnly" [(ngModel)]="diagnosisContent.comment"> </textarea>
        </span>
      </div>
    </div>
  </ng-container>
  <div class="report-diagnosis__content__image">
    <span class="report-diagnosis__content__image__gallery">
      <span class="report-diagnosis__content__image__gallery__label" translate>KeyImage</span>
      <p-galleria #galleria [value]="keyImages" [numVisible]="2" [showThumbnails]="false" [showItemNavigators]="true" (activeIndexChange)="onKeyImageIndexChange($event)" [(activeIndex)]="activeIndex">
        <ng-template pTemplate="item" let-item>
          <img class="report-diagnosis__preview-image" [src]="item.src" alt="Preview" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="grid grid-nogutter justify-content-center">
            <img [src]="item.thumbnail" alt="Thumbnail" style="width: 64px; height: 64px" />
          </div>
        </ng-template>
        <ng-template pTemplate="footer" let-item>
          <div class="custom-galleria-footer">
            <span *ngIf="keyImages" class="title-container">
              <!-- <span>{{ activeIndex + 1 }}/{{ keyImages.length }}</span>
              <span class="title">{{ figure }}</span> -->
            </span>
            <button type="button" pButton [icon]="fullScreenIcon()" (click)="toggleFullScreen()" class="fullscreen-button"></button>
          </div>
        </ng-template>
      </p-galleria>
    </span>
    <div class="report-diagnosis__form__item" style="height: calc(100% - 447px)">
      <label class="report-diagnosis__form__item__label" for="overview">{{ 'Figure' | translate: { index: activeIndex + 1 } }}/{{ keyImages.length }}</label>
      <span class="report-diagnosis__form__item__input">
        <textarea rows="5" id="comment" [disabled]="isHistoryReport || isReadOnly" [(ngModel)]="figure" (blur)="onFigureBlur()"></textarea>
      </span>
    </div>
  </div>
</div>

<div class="report-diagnosis__actions">
  <ng-container *rxIf="isHistoryReport">
    <span></span>
    <span>
      <ng-container *ngTemplateOutlet="history"></ng-container>
    </span>
  </ng-container>
  <ng-container *rxIf="!isHistoryReport">
    <ng-container *ngTemplateOutlet="current"></ng-container>
  </ng-container>
</div>

<ng-template #history>
  <span></span>
  <span>
    <p-button (click)="onCopyAllContent()" [disabled]="isReadOnly"><span translate>CopyAllReportContent</span></p-button>
  </span>
</ng-template>
<ng-template #current>
  <span>
    <p-button (click)="onPreviewReport()"><span translate>ReportPreview</span></p-button>
    <p-button (click)="onDownloadReport()"><span translate>ExportPDF</span></p-button></span
  >
  <span>
    <p-button [hidden]="isHistoryReport || isReadOnly" (click)="onSaveReport()"><span translate>SaveReport</span></p-button>
    <p-button (click)="onClose()"><span translate>Close</span></p-button></span
  >
</ng-template>
