<div class="worklist-preset">
  <p-button severity="secondary" icon="fa-solid fa-plus" [label]="'AddPreset' | translate"
    (onClick)="showDialog()"></p-button>
  <p-button *rxFor="let button of presetListButton$" severity="secondary" [label]="button.name"
    (click)="onPresetClick(button)"> </p-button>
</div>

<p-dialog class="worklist-preset-dialog" [header]="'AddPreset' | translate" [modal]="true" [(visible)]="visible"
  [style]="{ width: '30rem' }" (onHide)="hideDialog()">
  <div class="worklist-preset-dialog__body">
    <label class="worklist-preset-dialog__form-item-label" for="preset-name" translate>PresetName</label>
    <div class="worklist-preset-dialog__form-item-input-wrap">
      <input class="worklist-preset-dialog__form-item-input" id="preset-name" type="text" pInputText
        [(ngModel)]="presetName" />
      <span class="worklist-preset-dialog__form-item-input-msg my-2 text-danger">{{ validateMsg }}</span>
    </div>
  </div>
  <div class="worklist-preset-dialog__footer">
    <p-button [label]="'Cancel' | translate" severity="secondary" (onClick)="visible = false" />
    <p-button [label]="'Save' | translate" (onClick)="savePreset()" />
  </div>
</p-dialog>