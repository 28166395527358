import { IBase } from './IBase';

/**
 * Represents a base class that provides common functionality for models.
 * @template T - The type of the model.
 */
export class Base<T> implements IBase<T> {
  constructor() {}

  /**
   * Converts the model to an object.
   * @returns The object representation of the model.
   */
  toObject(): T {
    return <T>Object.assign({}, this);
  }

  /**
   * Converts the model to a string.
   * @returns The string representation of the model.
   */
  toString(): string {
    return JSON.stringify(this.toObject());
  }
}
