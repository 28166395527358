<div class="upload">
  <div class="upload__drop-area" appFileDropZone (dropped)="onFileDrop($event)">
    <input #selectFile type="file" multiple hidden (change)="onFilesChange($event)" />
    <input #selectFolder type="file" multiple hidden webkitdirectory (change)="onFilesChange($event)" />
    <div class="upload__place-holder">
      <i class="upload__place-holder__icon pi pi-cloud-upload"></i>
      <span class="upload__place-holder__hint" translate>DragAndDropFilesHere</span>
      <span class="upload__place-holder__hint upload__place-holder__hint--line" translate>Or</span>
      <div class="upload__actions-group">
        <p-button severity="secondary" type="button" iconPos="right" (click)="selectFile.click()" [disabled]="disableUploadButton" class="upload__action-button" [label]="'BrowseFiles' | translate">
        </p-button>
        <p-button severity="secondary" type="button" iconPos="right" (click)="selectFolder.click()" [disabled]="disableUploadButton" class="upload__action-button" [label]="'BrowseFolder' | translate">
        </p-button>
      </div>
    </div>
  </div>
  <div class="upload__actions-group">
    <p-button type="button" iconPos="right" (click)="onButtonClick(0)" [disabled]="disableUploadButton" class="upload__action-button" [label]="'UploadData' | translate"> </p-button>
    <p-button
      severity="danger"
      type="button"
      iconPos="right"
      class="p-button-danger"
      (click)="onButtonClick(1)"
      [disabled]="disableResetButton"
      class="upload__action-button"
      [label]="'Reset' | translate"
    >
    </p-button>
  </div>
  <div class="upload__actions-group">
    <p-checkbox [(ngModel)]="isOverrideData" [binary]="true" inputId="isOverride" />
    <label for="isOverride" translate>IsOverride</label>
  </div>
  <div class="upload__preview">
    <h2 class="upload__preview__title" translate>DicomDataPreviewAndUpload</h2>
    <ng-container *ngIf="previewitems.length > 0">
      <p class="upload__preview__msg">{{ uploadMessage }}</p>
    </ng-container>

    <div class="upload__preview__content">
      <div ngbScrollSpy #spy="ngbScrollSpy" class="bg-light rounded-2">
        <div *ngFor="let study of previewitems; index as i">
          <div [ngbScrollSpyFragment]="i.toString()">
            <app-preview #previewChild [studyData]="study" (eventUpload)="uploadNextStudy($event)"> </app-preview>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
