export enum SyncScope {
  All,
  Series,
  Study,
}
export enum SyncType {
  Zoom,
  WWWL,
  WWWLRevert,
  Pan,
  Rotate,
  Filter,
  LUT,
  Scroll,
}
