import { Injectable } from '@angular/core';
import { COLLECTION } from '@app/@core';
import { FireStoreService, IDBReport, IDBStudy, StudyFirebaseService } from '@app/@database';
import { REPORT_COLLECTION_KEY } from '../constants/report';

@Injectable({ providedIn: 'root' })
export class ReportFirebaseService {
  constructor(
    private firestoreService: FireStoreService,
    private studyDbService: StudyFirebaseService,
  ) {}

  /**
   * Retrieves clinic information for a given study.
   * @param studyUid - The unique identifier of the study.
   * @returns A Promise that resolves to an IDBStudy object representing the clinic information.
   */
  getClinicInfo = async (studyUid: string): Promise<IDBStudy> => {
    const study = await this.studyDbService.getStudyById(studyUid);
    return study;
  };

  /**
   * Saves clinic information for a study.
   *
   * @param studyUid - The UID of the study.
   * @param data - The clinic information to save.
   * @param userId - The ID of the user performing the save operation.
   * @returns A promise that resolves when the save operation is complete.
   */
  saveClinicInfo = async (studyUid: string, data: IDBStudy, userId: string): Promise<void> => {
    await this.studyDbService.updateStudy(data, studyUid, userId);
  };

  /**
   * Finds a report by study UID.
   * @param studyUid - The study UID to search for.
   * @returns A promise that resolves to the found report.
   */
  findReportByStudyUid = async (studyUid: string): Promise<IDBReport[]> => {
    const res = <IDBReport[]>await this.firestoreService.getDatasOfCollectionByFieldValue(COLLECTION.REPORT, REPORT_COLLECTION_KEY.STUDY_UID, studyUid, '==');
    return res;
  };

  /**
   * Saves a report.
   * @param data - The report data to save.
   * @param userId - The ID of the user performing the save operation.
   * @returns A promise that resolves when the save operation is complete.
   */
  saveReport = async (data: IDBReport): Promise<string | undefined> => {
    return await this.firestoreService.addData(COLLECTION.REPORT, data);
  };

  /**
   * Deletes a report from the Firestore database.
   * @param reportId - The ID of the report to be deleted.
   * @returns A Promise that resolves when the report is successfully deleted.
   */
  deleteReport = async (reportId: string): Promise<boolean> => {
    try {
      await this.firestoreService.deleteData(COLLECTION.REPORT, reportId);
      return true;
    } catch (error) {
      return false;
    }
  };
}
