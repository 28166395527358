/**
 * Defines the base routes for the application.
 */
export const APP_ROUTE_BASE = {
  LOGIN: 'login',
  LANDING_PAGE: '',
  WORKLIST: 'worklist',
  UPLOAD: 'upload',
  VIEWVER: 'viewer',
  GUEST_VIEWVER: 'guest-viewer',
  SHARE_VIEWVER: 'share-viewer',
  REPORT: 'report',
};

/**
 * Defines the landing page routes for different languages.
 */
export const LANDING_PAGE_ROUTE = {
  US: 'en-us',
  VN: 'vi-vn',
  JP: 'ja-jp',
};

export const SUB_LANDING_PAGE_ROUTE = {
  TERM: 'terms',
  PRIVACY: 'privacy',
  COOKIES: 'cookies',
};

export const LANDING_PAGE_PATTERN = (lang: string, subPage: string) => `${lang}/${subPage}`;

/**
 * Defines the query parameters for the viewer route.
 */
export const VIEWER_QUERY_PARAMS = {
  //Main display study on viewer
  MAIN: 'main',
  //Compare or reference study
  REFERENCE: 'ref',
  MAIN_UID: 'mainUid',
  REF_UID: 'refUid',
};

const MAIN_PARAM = (dicomDir: string, uid: string) => `${VIEWER_QUERY_PARAMS.MAIN}=${dicomDir}&${VIEWER_QUERY_PARAMS.MAIN_UID}=${uid}`;
const REF_PARAM = (dicomDir: string, uid: string) => `${VIEWER_QUERY_PARAMS.REFERENCE}=${dicomDir}&${VIEWER_QUERY_PARAMS.REF_UID}=${uid}`;

export const OPEN_VIEWER_PATTERN = (main: string, uid: string) => `${APP_ROUTE_BASE.VIEWVER}?${MAIN_PARAM(main, uid)}`;
export const OPEN_SHARE_VIEWER_PATTERN = (main: string, uid: string) => `${APP_ROUTE_BASE.SHARE_VIEWVER}?${MAIN_PARAM(main, uid)}&isShare=1`;
export const OPEN_COMPARE_VIEWER_PATTERN = (main: string, mainUId: string, ref: string, refUid: string) => `${APP_ROUTE_BASE.VIEWVER}?${MAIN_PARAM(main, mainUId)}&${REF_PARAM(ref, refUid)}`;

export const OPEN_REPORT_PATTERN = (main: string, uid: string, isShareMode: boolean) => `${APP_ROUTE_BASE.REPORT}?${MAIN_PARAM(main, uid)}&${isShareMode ? 'isShare=1' : ''}`;

export const OPEN_COMPARE_REPORT_PATTERN = (main: string, uid: string, ref: string, refUid: string, isShareMode: boolean) =>
  `${APP_ROUTE_BASE.REPORT}?${MAIN_PARAM(main, uid)}&${REF_PARAM(ref, refUid)}&${isShareMode ? 'isShare=1' : ''}`;

export const READ_ONLY_PARAMS = 'readOnly';
