import { AnnotationTool, Types, utilities, cursors, state, Enums, drawing, annotation } from '@cornerstonejs/tools';
import { Types as CoreTypes } from '@cornerstonejs/core';
import { getEnabledElement, utilities as csUtils } from '@cornerstonejs/core';
import { RectangleAnnotation } from './ToolSpecificAnnotationTypes';
import { triggerAnnotationCompleted } from './state';
import { getRectangleCoordinates } from './rectangle';

const { drawHandles, drawRectByCoordinates } = drawing;
const { isAnnotationLocked } = annotation.locking;
const { addAnnotation, getAnnotations, removeAnnotation } = annotation.state;
const { isAnnotationVisible } = annotation.visibility;
class RectangleAnnotateTool extends AnnotationTool {
  static override toolName: string;

  public touchDragCallback: any;
  public mouseDragCallback: any;
  _throttledCalculateCachedStats: any;
  editData: {
    annotation: any;
    viewportIdsToRender: string[];
    handleIndex?: number;
    movingTextBox?: boolean;
    newAnnotation?: boolean;
    hasMoved?: boolean;
  } | null;
  isDrawing: boolean;
  isHandleOutsideImage: boolean;

  constructor(
    toolProps: Types.PublicToolProps = {},
    defaultToolProps: Types.ToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: false,
        arrowFirst: true,
      },
    },
  ) {
    super(toolProps, defaultToolProps);
  }

  /**
   * Based on the current position of the mouse and the current imageId to create
   * a Length Annotation and stores it in the annotationManager
   *
   * @param evt -  EventTypes.NormalizedMouseEventType
   * @returns The annotation object.
   *
   */
  addNewAnnotation = (evt: Types.EventTypes.InteractionEventType): RectangleAnnotation => {
    const eventDetail = evt.detail;
    const { currentPoints, element } = eventDetail;
    const worldPos = currentPoints.world;

    const enabledElement = getEnabledElement(element);
    const { viewport, renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

    this.isDrawing = true;

    const camera = viewport.getCamera();
    const { viewPlaneNormal = [0, 0, 0], viewUp = [0, 0, 0] } = camera;

    const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);

    const annotation = {
      invalidated: true,
      highlighted: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: <CoreTypes.Point3>[...viewPlaneNormal],
        viewUp: <CoreTypes.Point3>[...viewUp],
        referencedImageId,
        ...viewport.getViewReference({ points: [worldPos] }),
      },
      data: {
        label: '',
        handles: {
          points: [<CoreTypes.Point3>[...worldPos], <CoreTypes.Point3>[...worldPos], <CoreTypes.Point3>[...worldPos], <CoreTypes.Point3>[...worldPos]],
          activeHandleIndex: null,
        },
      },
    };

    addAnnotation(annotation, element);

    const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(element, this.getToolName());

    this.editData = {
      annotation,
      viewportIdsToRender,
      handleIndex: 1,
      newAnnotation: true,
      hasMoved: false,
    };
    this._activateDraw(element);

    evt.preventDefault();

    utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  };

  /**
   * It returns if the canvas point is near the provided annotation in the provided
   * element or not. A proximity is passed to the function to determine the
   * proximity of the point to the annotation in number of pixels.
   *
   * @param element - HTML Element
   * @param annotation - Annotation
   * @param canvasCoords - Canvas coordinates
   * @param proximity - Proximity to tool to consider
   * @returns Boolean, whether the canvas point is near tool
   */
  isPointNearTool = (element: HTMLDivElement, annotation: RectangleAnnotation, canvasCoords: CoreTypes.Point2, proximity: number): boolean => {
    const enabledElement = getEnabledElement(element);
    const { viewport } = <CoreTypes.IEnabledElement>enabledElement;

    const { data } = annotation;
    const { points } = data.handles;

    const canvasPoint1 = viewport.worldToCanvas(points[0]);
    const canvasPoint2 = viewport.worldToCanvas(points[3]);

    const rect = getRectangleCoordinates([canvasPoint1, canvasPoint2]);

    const point = [canvasCoords[0], canvasCoords[1]];
    const { left, top, width, height } = rect;

    const distanceToPoint = utilities.math.rectangle.distanceToPoint([left, top, width, height], point as CoreTypes.Point2);

    if (distanceToPoint <= proximity) {
      return true;
    }

    return false;
  };

  toolSelectedCallback = (evt: Types.EventTypes.InteractionEventType, annotation: RectangleAnnotation): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    annotation.highlighted = true;

    const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(element, this.getToolName());

    this.editData = {
      annotation,
      viewportIdsToRender,
      movingTextBox: false,
    };

    this._activateModify(element);

    cursors.elementCursor.hideElementCursor(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

    utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    evt.preventDefault();
  };

  handleSelectedCallback = (evt: Types.EventTypes.InteractionEventType, annotation: RectangleAnnotation, handle: Types.ToolHandle): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const { data } = annotation;

    annotation.highlighted = true;

    let handleIndex;

    handleIndex = data.handles.points.findIndex((p) => p === handle);

    // Find viewports to render on drag.
    const viewportIdsToRender = utilities.viewportFilters.getViewportIdsWithToolToRender(element, this.getToolName());

    this.editData = {
      annotation,
      viewportIdsToRender,
      handleIndex,
    };
    this._activateModify(element);

    cursors.elementCursor.hideElementCursor(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = <CoreTypes.IEnabledElement>enabledElement;

    utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    evt.preventDefault();
  };

  _endCallback = (evt: Types.EventTypes.InteractionEventType): void => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    const editData = this.editData;

    if (editData) {
      const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = editData;
      const { data } = annotation;

      if (newAnnotation && !hasMoved) {
        return;
      }

      data.handles.activeHandleIndex = null;

      this._deactivateModify(element);
      this._deactivateDraw(element);

      cursors.elementCursor.resetElementCursor(element);

      const { renderingEngine } = <CoreTypes.IEnabledElement>getEnabledElement(element);

      this.editData = null;
      this.isDrawing = false;

      if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
        removeAnnotation(annotation.annotationUID);
      }

      utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

      if (newAnnotation) {
        triggerAnnotationCompleted(annotation);
      }
    }
  };

  _dragCallback = (evt: Types.EventTypes.InteractionEventType): void => {
    this.isDrawing = true;

    const eventDetail = evt.detail;
    const { element } = eventDetail;
    const editData = this.editData;
    if (editData) {
      const { annotation, viewportIdsToRender, handleIndex, movingTextBox } = editData;
      const { data } = annotation;

      if (handleIndex === undefined) {
        // Drag mode - Moving tool, so move all points by the world points delta
        const { deltaPoints } = eventDetail as Types.EventTypes.MouseDragEventDetail;
        const worldPosDelta = deltaPoints.world;

        const { points } = data.handles;

        points.forEach((point) => {
          point[0] += worldPosDelta[0];
          point[1] += worldPosDelta[1];
          point[2] += worldPosDelta[2];
        });
        annotation.invalidated = true;
      } else {
        // Moving handle.
        const { currentPoints } = eventDetail;
        const enabledElement = <CoreTypes.IEnabledElement>getEnabledElement(element);
        const { worldToCanvas, canvasToWorld } = enabledElement.viewport;
        const worldPos = currentPoints.world;

        const { points } = data.handles;

        // Move this handle.
        points[handleIndex] = [...worldPos];

        let bottomLeftCanvas;
        let bottomRightCanvas;
        let topLeftCanvas;
        let topRightCanvas;

        let bottomLeftWorld;
        let bottomRightWorld;
        let topLeftWorld;
        let topRightWorld;

        switch (handleIndex) {
          case 0:
          case 3:
            // Moving bottomLeft or topRight

            bottomLeftCanvas = worldToCanvas(points[0]);
            topRightCanvas = worldToCanvas(points[3]);

            bottomRightCanvas = [topRightCanvas[0], bottomLeftCanvas[1]];
            topLeftCanvas = [bottomLeftCanvas[0], topRightCanvas[1]];

            bottomRightWorld = canvasToWorld(bottomRightCanvas);
            topLeftWorld = canvasToWorld(topLeftCanvas);

            points[1] = bottomRightWorld;
            points[2] = topLeftWorld;

            break;
          case 1:
          case 2:
            // Moving bottomRight or topLeft
            bottomRightCanvas = worldToCanvas(points[1]);
            topLeftCanvas = worldToCanvas(points[2]);

            bottomLeftCanvas = <CoreTypes.Point2>[topLeftCanvas[0], bottomRightCanvas[1]];
            topRightCanvas = <CoreTypes.Point2>[bottomRightCanvas[0], topLeftCanvas[1]];

            bottomLeftWorld = canvasToWorld(bottomLeftCanvas);
            topRightWorld = canvasToWorld(topRightCanvas);

            points[0] = bottomLeftWorld;
            points[3] = topRightWorld;

            break;
        }
        annotation.invalidated = true;
      }

      editData.hasMoved = true;

      const enabledElement = <CoreTypes.IEnabledElement>getEnabledElement(element);
      const { renderingEngine } = enabledElement;

      utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
    }
  };

  cancel = (element: HTMLDivElement) => {
    // If it is mid-draw or mid-modify
    if (this.isDrawing) {
      this.isDrawing = false;
      this._deactivateDraw(element);
      this._deactivateModify(element);
      cursors.elementCursor.resetElementCursor(element);
      const editData = this.editData;
      if (editData) {
        const { annotation, viewportIdsToRender, newAnnotation } = editData;

        const { data } = annotation;

        annotation.highlighted = false;
        data.handles.activeHandleIndex = null;

        const { renderingEngine } = <CoreTypes.IEnabledElement>getEnabledElement(element);

        utilities.triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

        if (newAnnotation) {
          triggerAnnotationCompleted(annotation);
        }

        this.editData = null;
        return annotation.annotationUID;
      }
    }
  };
  /**
   * Add event handlers for the modify event loop, and prevent default event prapogation.
   */
  _activateDraw = (element) => {
    state.isInteractingWithTool = true;

    element.addEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.addEventListener(Enums.Events.MOUSE_DRAG, this._dragCallback);
    element.addEventListener(Enums.Events.MOUSE_MOVE, this._dragCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.addEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.addEventListener(Enums.Events.TOUCH_DRAG, this._dragCallback);
    element.addEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  /**
   * Add event handlers for the modify event loop, and prevent default event prapogation.
   */
  _deactivateDraw = (element) => {
    state.isInteractingWithTool = false;

    element.removeEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.removeEventListener(Enums.Events.MOUSE_DRAG, this._dragCallback);
    element.removeEventListener(Enums.Events.MOUSE_MOVE, this._dragCallback);
    element.removeEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.removeEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.removeEventListener(Enums.Events.TOUCH_DRAG, this._dragCallback);
    element.removeEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  /**
   * Add event handlers for the modify event loop, and prevent default event prapogation.
   */
  _activateModify = (element) => {
    state.isInteractingWithTool = true;

    element.addEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.addEventListener(Enums.Events.MOUSE_DRAG, this._dragCallback);
    element.addEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.addEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.addEventListener(Enums.Events.TOUCH_DRAG, this._dragCallback);
    element.addEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  /**
   * Remove event handlers for the modify event loop, and enable default event propagation.
   */
  _deactivateModify = (element) => {
    state.isInteractingWithTool = false;

    element.removeEventListener(Enums.Events.MOUSE_UP, this._endCallback);
    element.removeEventListener(Enums.Events.MOUSE_DRAG, this._dragCallback);
    element.removeEventListener(Enums.Events.MOUSE_CLICK, this._endCallback);

    element.removeEventListener(Enums.Events.TOUCH_END, this._endCallback);
    element.removeEventListener(Enums.Events.TOUCH_DRAG, this._dragCallback);
    element.removeEventListener(Enums.Events.TOUCH_TAP, this._endCallback);
  };

  /**
   * it is used to draw the rectangleROI annotation in each
   * request animation frame. It calculates the updated cached statistics if
   * data is invalidated and cache it.
   *
   * @param enabledElement - The Cornerstone's enabledElement.
   * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
   */
  renderAnnotation = (enabledElement: CoreTypes.IEnabledElement, svgDrawingHelper: Types.SVGDrawingHelper): boolean => {
    let renderStatus = false;
    const { viewport } = enabledElement;
    const { element } = viewport;

    let annotations = getAnnotations(this.getToolName(), element);

    if (!annotations?.length) {
      return renderStatus;
    }

    annotations = <Types.Annotations>this.filterInteractableAnnotationsForElement(element, annotations);

    if (!annotations?.length) {
      return renderStatus;
    }

    const targetId = this.getTargetId(viewport);
    const renderingEngine = viewport.getRenderingEngine();

    const styleSpecifier: Types.AnnotationStyle.StyleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i] as RectangleAnnotation;
      const { annotationUID = '', data } = annotation;
      const { points, activeHandleIndex } = data.handles;
      const canvasCoordinates = points.map((p) => viewport.worldToCanvas(p));

      styleSpecifier.annotationUID = annotationUID || '';

      const { color, lineWidth, lineDash } = this.getAnnotationStyle({
        annotation,
        styleSpecifier,
      });

      // If rendering engine has been destroyed while rendering
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      let activeHandleCanvasCoords;

      if (!isAnnotationVisible(annotationUID)) {
        continue;
      }

      if (!isAnnotationLocked(annotation) && !this.editData && activeHandleIndex !== null) {
        // Not locked or creating and hovering over handle, so render handle.
        activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
      }

      if (activeHandleCanvasCoords) {
        const handleGroupUID = '0';

        drawHandles(svgDrawingHelper, annotationUID, handleGroupUID, activeHandleCanvasCoords, {
          color,
        });
      }

      const dataId = `${annotationUID}-rect`;
      const rectangleUID = '0';
      drawRectByCoordinates(
        svgDrawingHelper,
        annotationUID,
        rectangleUID,
        canvasCoordinates,
        {
          color,
          lineDash,
          lineWidth,
        },
        dataId,
      );

      renderStatus = true;
    }

    return renderStatus;
  };
}

RectangleAnnotateTool.toolName = 'RectangleAnotate';
export default RectangleAnnotateTool;
