import { createFeature, createReducer, on } from '@ngrx/store';
import { IMenuDisplay, IMenuFusion, IMenuMPR, IMenuSort, IMenuSync, IScroll, IWWWL } from '@app/viewer/models';
import { DEFAULT_PANEL_LAYOUT, DEFAULT_STACK_LAYOUT, DEFAULT_TILE_LAYOUT, Measure, Annotate, SortCommand, SortOrder, ViewerMode, ScrollMode, ScrollSync, SyncScope, Tools } from '@app/viewer/contants';
import { viewerMenuActions } from './menu.actions';

export const VIEWER_MENU_KEY = 'menu';

export interface ViewerMenuState {
  panelLayout: string;
  stackLayout: string;
  tileLayout: string;
  display: IMenuDisplay;
  measure: Measure;
  annotate: Annotate;
  sort: IMenuSort;
  activeTool: Tools;
  scroll: IScroll;
  wwwl: IWWWL;
  currentFilter: string;
  currentLut: string;
  sync: IMenuSync;
  mpr: IMenuMPR;
  fusion: IMenuFusion;
  isFullScreen: boolean;
  isActiveMagnify: boolean;
  activeMode: ViewerMode;
  sortDirection: SortOrder;
  isReportOpen: boolean;
  isPasteKeyImageRegion: boolean;
}

export const initialState: ViewerMenuState = {
  panelLayout: DEFAULT_PANEL_LAYOUT,
  stackLayout: DEFAULT_STACK_LAYOUT,
  tileLayout: DEFAULT_TILE_LAYOUT,
  display: {
    displayOverlay: true,
    displayReferenceLine: true,
    displayCrosshair: true,
  },
  measure: Measure.None,
  annotate: Annotate.None,
  activeTool: Tools.Select,
  sort: {
    command: SortCommand.InstanceNumber,
    scope: false,
    order: SortOrder.Ascending,
  },
  scroll: {
    loop: false,
    scrollMode: ScrollMode.OneImage,
    scrollSync: ScrollSync.ImageOrder,
  },
  wwwl: {
    isActiveByROI: false,
    isInvest: false,
  },
  sync: {
    scope: SyncScope.All,
    isSyncZoom: true,
    isSyncPan: true,
    isSyncRotate: true,
    isSyncWWWL: true,
    isSyncFilter: false,
    isSyncLUT: true,
    isSyncScroll: true,
  },
  currentFilter: '',
  currentLut: 'None',
  mpr: {
    layout: '3V0H',
    isDisplay2D: false,
  },
  fusion: {
    layout: '3H3V1V',
    isDisplay2D: false,
  },
  isActiveMagnify: false,
  isFullScreen: false,
  activeMode: ViewerMode.Stack2D,
  sortDirection: SortOrder.Ascending,
  isReportOpen: false,
  isPasteKeyImageRegion: false,
};

export const menuFeature = createFeature({
  name: VIEWER_MENU_KEY,
  reducer: createReducer<ViewerMenuState>(
    initialState,
    //#region display
    on(
      viewerMenuActions.changeDisplayOverlay,
      (state): ViewerMenuState => ({
        ...state,
        display: {
          ...state.display,
          displayOverlay: !state.display.displayOverlay,
        },
      }),
    ),
    on(
      viewerMenuActions.changeDisplayReferenceLine,
      (state): ViewerMenuState => ({
        ...state,
        display: {
          ...state.display,
          displayReferenceLine: !state.display.displayReferenceLine,
        },
      }),
    ),
    on(
      viewerMenuActions.changeDisplayCrosshair,
      (state): ViewerMenuState => ({
        ...state,
        display: {
          ...state.display,
          displayCrosshair: !state.display.displayCrosshair,
        },
      }),
    ),
    //#endregion
    on(
      viewerMenuActions.onMeasure,
      (state, action): ViewerMenuState => ({
        ...state,
        measure: action.name,
        annotate: Annotate.None,
      }),
    ),
    on(
      viewerMenuActions.onAnnotate,
      (state, action): ViewerMenuState => ({
        ...state,
        annotate: action.name,
        measure: Measure.None,
      }),
    ),
    on(
      viewerMenuActions.disableAllTool,
      (state, action): ViewerMenuState => ({
        ...state,
        scroll: {
          ...state.scroll,
          loop: false,
        },
        activeTool: Tools.Select,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onScroll,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Scroll,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.changeScrollLoop,
      (state, action): ViewerMenuState => ({
        ...state,
        scroll: {
          ...state.scroll,
          loop: !state.scroll.loop,
        },
      }),
    ),
    on(
      viewerMenuActions.changeScrollMode,
      (state, action): ViewerMenuState => ({
        ...state,
        scroll: {
          ...state.scroll,
          scrollMode: action.mode,
        },
      }),
    ),
    on(
      viewerMenuActions.changeScrollSync,
      (state, action): ViewerMenuState => ({
        ...state,
        scroll: {
          ...state.scroll,
          scrollSync: action.sync,
        },
      }),
    ),
    on(
      viewerMenuActions.onZoom,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Zoom,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onVolumeZoom,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Zoom,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onWWWLActive,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.WWWL,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onWWWLVolumeActive,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.WWWL,
        measure: Measure.None,
        annotate: Annotate.None,
      }),
    ),
    on(
      viewerMenuActions.onWWWLActiveByROI,
      (state, action): ViewerMenuState => ({
        ...state,
        measure: Measure.None,
        annotate: Annotate.None,
        activeTool: Tools.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: !state.wwwl.isActiveByROI,
        },
      }),
    ),
    on(
      viewerMenuActions.onWWWLInvert,
      (state, action): ViewerMenuState => ({
        ...state,
        wwwl: {
          ...state.wwwl,
          isInvest: action.state,
        },
      }),
    ),
    on(
      viewerMenuActions.onPan,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Pan,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onVolumePan,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Pan,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onRotate,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Rotate,
        measure: Measure.None,
        annotate: Annotate.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onSelect,
      (state, action): ViewerMenuState => ({
        ...state,
        measure: Measure.None,
        annotate: Annotate.None,
        activeTool: Tools.Select,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onVolumeSelect,
      (state, action): ViewerMenuState => ({
        ...state,
        measure: Measure.None,
        annotate: Annotate.None,
        activeTool: Tools.Select,
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onSyncScope,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          scope: action.scope,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSyncZoom,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          isSyncZoom: !state.sync.isSyncZoom,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSyncPan,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          isSyncPan: !state.sync.isSyncPan,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSyncRotate,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          isSyncRotate: !state.sync.isSyncRotate,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSyncScroll,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          isSyncScroll: !state.sync.isSyncScroll,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSyncWWWL,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          isSyncWWWL: !state.sync.isSyncWWWL,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSyncLUT,
      (state, action): ViewerMenuState => ({
        ...state,
        sync: {
          ...state.sync,
          isSyncLUT: !state.sync.isSyncLUT,
        },
      }),
    ),
    on(
      viewerMenuActions.onMPRLayout,
      (state, action): ViewerMenuState => ({
        ...state,
        mpr: {
          ...state.mpr,
          layout: action.layout,
        },
      }),
    ),
    on(
      viewerMenuActions.onMPRVisible2D,
      (state, action): ViewerMenuState => ({
        ...state,
        mpr: {
          ...state.mpr,
          isDisplay2D: !state.mpr.isDisplay2D,
        },
      }),
    ),
    on(
      viewerMenuActions.onMPRLayoutChange,
      (state, action): ViewerMenuState => ({
        ...state,
        mpr: {
          ...state.mpr,
          layout: action.layout,
        },
      }),
    ),
    on(
      viewerMenuActions.onMPRVisible2DChange,
      (state, action): ViewerMenuState => ({
        ...state,
        mpr: {
          ...state.mpr,
          isDisplay2D: !state.mpr.isDisplay2D,
        },
      }),
    ),
    on(
      viewerMenuActions.onFusionActive,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Select,
        measure: Measure.None,
        annotate: Annotate.None,
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onMPRActive,
      (state, action): ViewerMenuState => ({
        ...state,
        activeTool: Tools.Select,
        measure: Measure.None,
        annotate: Annotate.None,
        isActiveMagnify: false,
      }),
    ),
    on(
      viewerMenuActions.onFusionLayout,
      (state, action): ViewerMenuState => ({
        ...state,
        fusion: {
          ...state.fusion,
          layout: action.layout,
        },
      }),
    ),
    on(
      viewerMenuActions.onFusionVisible2D,
      (state, action): ViewerMenuState => ({
        ...state,
        fusion: {
          ...state.fusion,
          isDisplay2D: !state.fusion.isDisplay2D,
        },
      }),
    ),
    on(
      viewerMenuActions.onFusionLayoutChange,
      (state, action): ViewerMenuState => ({
        ...state,
        fusion: {
          ...state.fusion,
          layout: action.layout,
        },
      }),
    ),
    on(
      viewerMenuActions.onFusionVisible2DChange,
      (state, action): ViewerMenuState => ({
        ...state,
        fusion: {
          ...state.fusion,
          isDisplay2D: !state.fusion.isDisplay2D,
        },
      }),
    ),
    on(
      viewerMenuActions.changeActiveMode,
      (state, action): ViewerMenuState => ({
        ...state,
        activeMode: action.mode,
      }),
    ),
    on(
      viewerMenuActions.changeActiveTile,
      (state, action): ViewerMenuState => ({
        ...state,
        wwwl: {
          ...state.wwwl,
          isInvest: action.toolState.voiInvert,
        },
      }),
    ),
    on(
      viewerMenuActions.onFullScreenCommand,
      (state, action): ViewerMenuState => ({
        ...state,
        isFullScreen: action.isFullScreen,
      }),
    ),
    on(
      viewerMenuActions.triggerMagnify,
      (state, action): ViewerMenuState => ({
        ...state,
        isActiveMagnify: !state.isActiveMagnify,
        measure: Measure.None,
        annotate: Annotate.None,
        activeTool: Tools.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
      }),
    ),
    on(
      viewerMenuActions.changeSortDirection,
      (state, action): ViewerMenuState => ({
        ...state,
        sortDirection: state.sortDirection === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
      }),
    ),
    on(
      viewerMenuActions.onSortCommand,
      (state, action): ViewerMenuState => ({
        ...state,
        sort: {
          ...state.sort,
          command: action.command,
        },
      }),
    ),
    on(
      viewerMenuActions.pasteKeyImageRegion,
      (state, action): ViewerMenuState => ({
        ...state,
        measure: Measure.None,
        annotate: Annotate.None,
        activeTool: Tools.None,
        wwwl: {
          ...state.wwwl,
          isActiveByROI: false,
        },
        isPasteKeyImageRegion: !state.isPasteKeyImageRegion,
      }),
    ),
    on(
      viewerMenuActions.openReport,
      (state, action): ViewerMenuState => ({
        ...state,
        isReportOpen: true,
      }),
    ),
    on(
      viewerMenuActions.closeReport,
      (state, action): ViewerMenuState => ({
        ...state,
        isReportOpen: false,
      }),
    ),
  ),
});

export const { name, reducer } = menuFeature;
