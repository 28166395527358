import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ISeriesInfo } from '@app/viewer/models/ISeriesInfo';
import { ViewerGeneralQuery } from '@app/viewer/store/general';
import { ViewerLayoutQuery } from '@app/viewer/store/layout';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-viewer-series-list',
  styleUrls: ['./series.component.scss'],
  templateUrl: './series.component.html',
})
export class SeriesListComponent implements OnChanges {
  constructor(private store: Store) {}
  @Input() studyUid: string;
  selectedSeriesUID: string;
  thumbnail$ = new Observable<ISeriesInfo[] | undefined>();
  activeSeries$ = new Observable<string | undefined>();
  thumbnailSub$: Subscription;
  @ViewChild('scrollTarget') scrollTarget: ElementRef;
  /**
   * Lifecycle hook that is called when one or more input properties of the component change.
   * @param changes - An object containing the changed properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.studyUid && changes.studyUid.currentValue !== undefined) {
      this.thumbnail$ = this.store.select(ViewerGeneralQuery.selectSeriesInfo(this.studyUid));
      this.activeSeries$ = this.store.select(ViewerLayoutQuery.selectStackActiveSeriesUid);
    }
  }

  /**
   * Handles the click event on a thumbnail.
   * @param {string} uid - The UID of the selected series.
   */
  protected onClickThumbnail = (uid: string) => {
    this.selectedSeriesUID = uid;
  };

  /**
   * Handles the drag start event for a series.
   * @param event - The drag start event.
   * @param series - The series information.
   */
  protected dragStart = (event, series: ISeriesInfo) => {
    let data = {
      seriesInfo: series,
    };

    event.dataTransfer.setData('dataDrop', JSON.stringify(data));
  };

  /**
   * using mouse wheel scroll series thumbnail if posible
   * @param event
   */
  @HostListener('mousewheel', ['$event'])
  onMouseWheelThumbnail = (event: any) => {
    event.preventDefault();
    this.scrollTarget.nativeElement.scrollBy({ left: event.deltaY < 0 ? -30 : 30 });
  };
}
