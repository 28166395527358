/// <reference types="gapi.auth2" />
import { Injectable } from '@angular/core';
// import { setPersistence } from '@firebase/auth';
import {
  Auth,
  signInWithPopup,
  GoogleAuthProvider,
  browserLocalPersistence,
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  setPersistence,
  User,
  signInWithCustomToken,
  signInWithCredential,
} from '@angular/fire/auth';
import { GoogleSpreadSheetService, Logger, SharedService } from '@app/@shared';
import { Observable, Subject } from 'rxjs';
import { AxiosService } from '@app/@core';
import { HttpStatusCode } from 'axios';
import { IFirebaseUser } from './models/IFirebaseUser';
import { IGoogleCredential } from './models/IGoogleCredential';
import { LoginMode } from './models/login-mode';
import { TranslateService } from '@ngx-translate/core';
import { SettingFirebaseService } from '@app/setting';

const log = new Logger('AuthenticationService');
const GAPI_TOKEN_KEY = 'gapiOauthToken';
const FIREBASE_USER = 'firebaseUser';
const LOGIN_MODE = 'loginMode';

const TOKEN_INFO_ENDPOINT = 'https://oauth2.googleapis.com/tokeninfo';
//#region Interface
export interface loginFirebaseResult {
  status: boolean;
  userInfo?: IFirebaseUser;
  gApi?: IGoogleCredential;
  error?: string;
}

export enum AuthenticationState {
  login = 1,
  logout = 2,
  invalidToken = 3,
}

export const GoogleOauth2State = {
  INVALID_TOKEN: 'invalid_token',
};
//#endregion
/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationFirebaseService {
  /**
   * @param private
   * @param authService
   * @param Auth
   */
  constructor(
    private authService: Auth,
    private axiosSerivce: AxiosService,
    private translateService: TranslateService,
    private settingFirebaseService: SettingFirebaseService,
    private sharedService: SharedService,
  ) {
    this.authState = new Subject();
  }
  provider = new GoogleAuthProvider();

  //#region  Rxjs
  public authState: Subject<any>;
  public raiseAuthState(state: AuthenticationState) {
    this.authState.next(state);
  }
  //#endregion

  /**
   * Called when user logs in. This is the entry point for GAPI authentication. The result is a promise that resolves to the user object or rejects with an error.
   * @return { Promise<loginFirebaseResult> } object with status and userInfo if success or rejected with an error if not. {@link loginFirebaseResult}
   */
  public async onFirebaseLogin(): Promise<loginFirebaseResult> {
    try {
      //await this.authService.setPersistence(browserLocalPersistence);
      this.authService.languageCode = this.translateService.currentLang.split('-')[0];
      const loginTime = new Date().getTime();
      this.provider.addScope(
        [
          'https://www.googleapis.com/auth/drive',
          'https://www.googleapis.com/auth/drive.file',
          'https://www.googleapis.com/auth/drive.appdata',
          'https://www.googleapis.com/auth/drive.scripts',
          'https://www.googleapis.com/auth/drive.metadata',
          'https://www.googleapis.com/auth/spreadsheets',
        ].join(' '),
      );

      const result = await signInWithPopup(this.authService, this.provider);
      if (!result) {
        return { status: false, error: 'Login failed' };
      }

      const gapiOauthToken: IGoogleCredential = GoogleAuthProvider.credentialFromResult(result) as IGoogleCredential;
      // Sets the access token for Google Apps to use when authenticating with expries in 24h
      gapi.auth.setToken({
        access_token: gapiOauthToken?.accessToken || '',
        expires_in: '86400',
        state: '',
        error: '',
      });
      gapi.client.setToken({ access_token: gapiOauthToken?.accessToken || '' });
      const createdTime = parseInt(result.user.metadata['createdAt'] || '');
      if (loginTime <= createdTime) {
        log.info(`New User Login to system, create the user default database`);
        this.sharedService.preloader(true, this.translateService.instant('NewUserLogin'));
        //Create default firestore
        const firebaseUserId = result.user.uid;
        //Create default setting
        await this.settingFirebaseService.initSettingForNewUser(firebaseUserId);
      }
      // Store Firebase user in local storage. This is used to determine which users are allowed to access Firesbase
      const user: IFirebaseUser = {
        uid: result.user.uid,
        displayName: result.user.displayName,
        email: result.user.email,
        emailVerified: result.user.emailVerified,
        isAnonymous: result.user.isAnonymous,
        phoneNumber: result.user.phoneNumber,
        photoURL: result.user.photoURL,
        providerId: result.user.providerId,
        refreshToken: result.user.refreshToken,
        tenantId: result.user.tenantId,
        accessToken: (await result.user.getIdToken()) || '',
      };
      localStorage.setItem(LOGIN_MODE, JSON.stringify(LoginMode.firebase));
      localStorage.setItem(FIREBASE_USER, JSON.stringify(user));
      // Updates the local storage with the GAPI Oauth token. This is called after the user has authenticated with the Google Apps API
      localStorage.setItem(GAPI_TOKEN_KEY, JSON.stringify(gapiOauthToken));

      return { status: true, userInfo: user, gApi: gapiOauthToken || ({} as IGoogleCredential) };
    } catch (error) {
      log.error(error);
      return { status: false, error: JSON.stringify(error) };
    }
  }

  /**
   * Called when user logs out. This is a GAPI callback. You should not need to call it yourself.
   * @return { Promise<loginFirebaseResult> } Status object with status set to true to indicate that the operation was successful and the user should be redirected to the front
   */
  public onLogout(): loginFirebaseResult {
    gapi.auth?.signOut();
    this.authService.signOut();
    localStorage.removeItem(GAPI_TOKEN_KEY);
    localStorage.removeItem(FIREBASE_USER);
    localStorage.removeItem(LOGIN_MODE);
    return { status: true };
  }

  /**
   * Get firebase user information store on browser storage
   * @return { Object|undefined } User information from session or undefined if not found in session or no user information
   */
  public getUserInfoStored(): IFirebaseUser {
    const userValue = localStorage.getItem(FIREBASE_USER);
    return userValue !== null ? JSON.parse(userValue) : undefined;
  }

  //
  /**
   * Returns OAuth 2. 0 token from local storage or undefined if not available. This is used to authenticate the user
   * @returns {string | undefined} google oauth2 information
   */
  public getGAPIOauth(): IGoogleCredential {
    const oauth2 = localStorage.getItem(GAPI_TOKEN_KEY);
    return oauth2 ? JSON.parse(oauth2) : undefined;
  }

  /**
   *  Verifies the google token store on browser. This is a no - op if the user is not logged in
   * @returns {boolean} true: token valid, otherwise
   */
  //
  public async verifyToken(idToken): Promise<boolean> {
    const config = {
      params: {
        id_token: idToken,
      },
    };
    const res = await this.axiosSerivce.get(TOKEN_INFO_ENDPOINT, config);
    if (res.status !== HttpStatusCode.Ok && res.data.error === GoogleOauth2State.INVALID_TOKEN) {
      return false;
    }
    return true;
  }

  /**
   * Initializes the authentication process with a new window.
   * If the user is already authenticated, returns true.
   * If the user is not authenticated, attempts to authenticate using the stored Google API OAuth credentials.
   * If authentication is successful, returns true. Otherwise, returns false.
   * @returns A boolean indicating whether the authentication was successful.
   */
  public async initAuthenticateWithNewWindows() {
    const user = this.authService.currentUser;
    if (user) {
      return true;
    } else {
      const storeUser = this.getGAPIOauth();
      if (storeUser) {
        const credential = GoogleAuthProvider.credential(null, storeUser.accessToken);
        const res = await signInWithCredential(this.authService, credential);
        return true;
      }
      return false;
    }
  }
}
