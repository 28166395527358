<div class="viewer-study">
  <div class="viewer-study__actions" *rxLet="filterButton$; let filterButton">
    <p-button severity="info" class="viewer-study__fixed-button" (onClick)="onFilterModality('All')" [class]="curModality === 'All' ? 'state-activated' : ''" translate>All</p-button>
    <div class="viewer-study__sub-actions">
      <p-button *rxFor="let button of filterButton" severity="info" (onClick)="onFilterModality(button)" [class]="curModality === button ? 'state-activated' : ''" translate>{{ button }}</p-button>
    </div>
  </div>
  <div class="viewer-study__table">
    <div #datatable class="h-100 mh-0">
      <p-table
        #dt
        [columns]="cols"
        [value]="(tableData$ | async)!"
        [scrollable]="true"
        selectionMode="multiple"
        [scrollHeight]="scrollHeight"
        [selection]="selectedStudies$ | async"
        [metaKeySelection]="true"
        dataKey="uid"
        styleClass="p-datatable-sm"
        (selectionChange)="onSelectionChange($event)"
        [contextMenu]="cm"
        (contextMenuSelectionChange)="onContextMenuSelectionChange($event)"
      >
        <ng-template #header pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [ngStyle]="{ display: col.display, width: col.width }" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *rxFor="let col of columns" [ngStyle]="{ display: col.display, width: col.width }">
              {{ col.header | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="table-row-nodata">
            <td [attr.colspan]="columns.length"><span translate>NoStudyFound</span><span translate>DragAndDropDcmData</span></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fileInfo let-columns="columns">
          <tr [pSelectableRow]="fileInfo" [pContextMenuRow]="fileInfo">
            <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">
              <td *ngSwitchCase="'studyDate'" [ngStyle]="{ display: col.display, width: col.width }">
                {{ moment(fileInfo[col.field], 'YYYYMMDD').format(dateTimeFormat) }}
                {{ moment(fileInfo['studyTime'], 'hhmmss').format('HH:mm:ss') || '' }}
              </td>

              <td *ngSwitchDefault [ngStyle]="{ display: col.display, width: col.width }">
                <p class="viewer-study__text">{{ fileInfo[col.field] }}</p>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-contextMenu #cm [model]="cmItems"></p-contextMenu>
