import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { APP_THEME_LINK, DARK_THEME, LIGHT_THEME, PRIME_THEME_LINK, Theme } from './theme';
import { Logger } from '@app/@shared';
const THEME_KEY = 'theme';
const log = new Logger('ThemeService');
@Injectable({
  providedIn: 'root',
})
/**
 * Service responsible for switching themes in the application.
 */
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {
    let saveTheme = parseInt(localStorage.getItem(THEME_KEY) || '') || Theme.Light;
    this._currentTheme = saveTheme;
    this.switchTheme(this._currentTheme);
  }
  private _currentTheme: Theme;
  set theme(value: Theme) {
    if (value !== null && value !== undefined) {
      this._currentTheme = value;
      this.switchTheme(value);
      localStorage.setItem(THEME_KEY, value.toString());
    }
  }
  get theme(): Theme {
    return this._currentTheme;
  }

  /**
   * Switches the theme of the application.
   *
   * @param theme - The theme to switch to.
   */
  switchTheme(theme: Theme) {
    const primengThemeLink = this.document.getElementById(PRIME_THEME_LINK) as HTMLLinkElement;
    const appThemeLink = this.document.getElementById(APP_THEME_LINK) as HTMLLinkElement;
    if (primengThemeLink) {
      switch (theme) {
        case Theme.Dark:
          primengThemeLink.href = DARK_THEME.PRIME + '.css';
          appThemeLink.href = DARK_THEME.APP + '.css';
          break;
        case Theme.Light:
          primengThemeLink.href = LIGHT_THEME.PRIME + '.css';
          appThemeLink.href = LIGHT_THEME.APP + '.css';
          break;
        default:
          break;
      }
    }
  }
}
