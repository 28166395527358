import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { settingFeature } from './store/setting.reducer';
import { SettingEffects } from './store/setting.effects';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { SettingComponent } from './setting.component';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralSettingComponent } from './pages/general-setting/general-setting.component';
import { HotkeySettingComponent } from './pages/hotkey-setting/hotkey-setting.component';
import { WorklistSettingComponent } from './pages/worklist-setting/worklist-setting.component';
import { AboutTeamComponent } from './pages/about-team/about-team.component';
import { FormsModule } from '@angular/forms';
import { UserInfoSettingComponent } from './pages/user-info-setting/user-info-setting.component';
@NgModule({
  declarations: [SettingComponent, GeneralSettingComponent, HotkeySettingComponent, WorklistSettingComponent, AboutTeamComponent, UserInfoSettingComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TabViewModule,
    DialogModule,
    MenuModule,
    ButtonModule,
    SelectButtonModule,
    DropdownModule,
    ScrollPanelModule,
    InputTextModule,
    OrderListModule,
    StoreModule.forFeature(settingFeature),
    EffectsModule.forFeature(SettingEffects),
  ],
  exports: [SettingComponent],
})
export class SettingModule {}
