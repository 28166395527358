import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LandingPageComponent } from './landing-page.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { MainComponent } from './pages/en-us/main.component';
import { MainContentComponent } from './pages/en-us/main-content/main-content.component';
import { TermComponent } from './pages/en-us/term/term.component';
import { PrivacyComponent } from './pages/en-us/privacy/privacy.component';
import { CookiesComponent } from './pages/en-us/cookies/cookies.component';

import { MainComponent as VNMainComponent } from './pages/vn-vn/main.component';
import { MainContentComponent as VNMainContentComponent } from './pages/vn-vn/main-content/main-content.component';
import { TermComponent as VNTermComponent } from './pages/vn-vn/term/term.component';
import { PrivacyComponent as VNPrivacyComponent } from './pages/vn-vn/privacy/privacy.component';
import { CookiesComponent as VNCookiesComponent } from './pages/vn-vn/cookies/cookies.component';

import { MainComponent as JPMainComponent } from './pages/ja-jp/main.component';
import { MainContentComponent as JPMainContentComponent } from './pages/ja-jp/main-content/main-content.component';
import { TermComponent as JPTermComponent } from './pages/ja-jp/term/term.component';
import { PrivacyComponent as JPPrivacyComponent } from './pages/ja-jp/privacy/privacy.component';
import { CookiesComponent as JPCookiesComponent } from './pages/ja-jp/cookies/cookies.component';
import { LanguageDropdownComponent } from './pages/language-dropdown/language-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [CommonModule, TranslateModule, CarouselModule, LandingPageRoutingModule, RouterModule, DropdownModule, FormsModule],
  declarations: [
    LandingPageComponent,
    MainComponent,
    MainContentComponent,
    TermComponent,
    PrivacyComponent,
    CookiesComponent,
    VNMainComponent,
    VNMainContentComponent,
    VNTermComponent,
    VNPrivacyComponent,
    VNCookiesComponent,
    JPMainComponent,
    JPMainContentComponent,
    JPTermComponent,
    JPPrivacyComponent,
    JPCookiesComponent,
    LanguageDropdownComponent,
  ],
})
export class LandingPageModule {}
