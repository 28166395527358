/*
 * Definitions
 */

import { Types } from '@cornerstonejs/tools';
/*
 * Utils
 */

function extend(base: Types.SVGCursorDescriptor, values: Record<string, unknown>): Types.SVGCursorDescriptor {
  return Object.assign(Object.create(base), values);
}

const BASE: Types.SVGCursorDescriptor = {
  iconContent: '',
  iconSize: 16,
  viewBox: {
    x: 16,
    y: 16,
  },
  mousePoint: {
    x: 8,
    y: 8,
  },
  mousePointerGroupString: `
      <path stroke="{{color}}" d="M8 16L8 0"></path>
      <path stroke="{{color}}" d="M16 8L0 8"></path>
    `,
};

export const RectangleCursor = extend(BASE, {
  iconContent: `<path fill="{{color}}" d="M1312 256h-832q-66 0-113 47t-47 113v832q0 66 47
    113t113 47h832q66 0 113-47t47-113v-832q0-66-47-113t-113-47zm288 160v832q0
    119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119
    84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z" />`,
  viewBox: {
    x: 1792,
    y: 1792,
  },
});

export const EllipseCursor = extend(BASE, {
  iconContent: `<path stroke="{{color}}" fill="none" stroke-width="3" d="M30.74 15.76C30.74 20.99 24.14 25.23 16
    25.23C7.86 25.23 1.26 20.99 1.26 15.76C1.26 10.54 7.86 6.3 16 6.3C24.14
    6.3 30.74 10.54 30.74 15.76Z" />`,
  viewBox: {
    x: 32,
    y: 32,
  },
});
