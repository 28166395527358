<ng-container *rxLet="historyReports$; let historyReports">
  <ng-container *rxIf="historyReports.length > 0">
    <div *ngIf="historyOpened" class="report-content report-content__col report-content--history">
      <h3 class="report-content__title">
        <span class="report-content__title__tabs">
          <p-button
            *rxFor="let history of historyReports; let i = index"
            [text]="true"
            (click)="handleClickTab(history, i)"
            class="report-content__tab"
            [class]="activatedTab === i ? 'report-content__tab--activated' : ''"
            >{{ 'HistoryButton' | translate: { index: i + 1 } }}</p-button
          >
        </span>
        <span class="report-content__title__actions">
          <p-button icon="pi pi-angle-double-left" [rounded]="true" severity="secondary" (click)="handleClickToggleHistory()" [text]="true" [raised]="true"></p-button
        ></span>
      </h3>
      <app-diagnosis-content
        class="report-content__diagnosis"
        [isHistoryReport]="true"
        [isReadOnly]="(readOnly$ | async) ?? false"
        [diagnosisContent]="selectedHistoryReport"
        (copyContent)="onRecieveCopyContent($event)"
      />
    </div>
  </ng-container>
</ng-container>
<ng-container *rxIf="currentReport !== undefined">
  <div class="report-content">
    <h3 class="report-content__title">
      <span class="report-content__title__actions">
        <p-button *ngIf="!historyOpened" icon="pi pi-angle-double-right" [rounded]="true" severity="secondary" (click)="handleClickToggleHistory()" [text]="true" [raised]="true"></p-button
      ></span>
      <ng-container *rxLet="historyReports$; let historyReports">
        <span class="report-content__title__tabs">{{ 'CurrentReaderInformation' | translate: { index: historyReports.length + 1 } }}</span>
      </ng-container>
    </h3>
    <app-diagnosis-content #currentReportEl class="report-content__diagnosis" [isHistoryReport]="false" [isReadOnly]="(readOnly$ | async) ?? false" [diagnosisContent]="currentReport" />
  </div>
</ng-container>
