<div class="banner">
  <div class="container">
    <h1 class="font-weight-semibold">HTML 5 ZERO-FOOTPRINT DICOM VIEWER</h1>
    <h6 class="font-weight-normal text-muted pb-3">
      V-PACS は、ウェブベースの DICOM ビューアーとクラウドストレージシステムを備えた画像診断システムです。診断、画像閲覧、アーカイブ、医療画像の送信を目的としています
    </h6>
    <div>
      <button class="btn btn-opacity-light mr-1" onclick="document.getElementById('case-studies-section').scrollIntoView()">開始</button>
      <button class="btn btn-opacity-success ml-1" onclick="document.getElementById('case-studies-section').scrollIntoView()">詳細はこちら</button>
    </div>
    <br />
    <img src="assets/images/landing-page/dicomviewer.jpg" alt="" class="img-fluid" />
  </div>
</div>
<div class="content-wrapper">
  <div class="container">
    <section class="features-overview" id="features-section">
      <div class="content-header">
        <h2>システム機能</h2>
        <h6 class="section-subtitle text-muted">弊社の画像診断システムは、以下の主要機能を備えています</h6>
      </div>
      <div style="text-align: center" class="d-md-flex justify-content-between">
        <div class="grid-margin d-flex justify-content-start">
          <div class="features-width">
            <img src="assets/images/landing-page/cloudhosting.png" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">クラウドホスティング</h5>
            <p class="text-muted">データは Google Cloud Platform 上に保存されており、インターネット接続経由で安全かつ簡単にアクセスできます</p>
            <!-- <a href="#"><p class="readmore-link">Readmore</p></a> -->
          </div>
        </div>
        <div class="grid-margin d-flex justify-content-center">
          <div class="features-width">
            <img src="assets/images/landing-page/dicomstandard.jpg" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">DICOM 規格</h5>
            <p class="text-muted">このシステムは、CT、MRI、XR、US など複数のモダリティと連携できるように、DICOM 規格に準拠して構築されています</p>
            <!-- <a href="#"><p class="readmore-link">Readmore</p></a> -->
          </div>
        </div>
        <div class="grid-margin d-flex justify-content-end">
          <div class="features-width">
            <img src="assets/images/landing-page/webbased.png" alt="" class="img-icons" height="80px" />
            <h5 class="py-3">ウェブベースシステム</h5>
            <p class="text-muted">当社のシステムは、インストール不要で画像診断ワークフローを実行できるよう、各種ウェブベースのアプリケーションで構築されています</p>
            <!-- <a href="#"><p class="readmore-link">Readmore</p></a> -->
          </div>
        </div>
      </div>
    </section>
    <section class="case-studies" id="case-studies-section">
      <div class="row grid-margin">
        <div class="col-12 text-center pb-5">
          <h2>マニュアル</h2>
          <h6 class="section-subtitle text-muted">
            システムの使い方については、データアップローダー、スタディーワークリスト、DICOMビューアー、レポート作成の 4 つの主要モジュールに関するマニュアルを参照してください。
          </h6>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-primary text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/upload.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around" action="https://www.w3docs.com/">
                  <div>
                    <h6 class="text-white pb-2 px-3">データアップローダーの詳細についてはこちら</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1EuwdE5BzSv5JGfDiZ8zWor9M7bNbv41wxlw5MKqsQ4g/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">データアップローダー</h6>
                <p>Google ドライブへのデータのアップロード方法について</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-warning text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/worklist.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">ワークリストの詳細についてはこちら</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1cLxUzOD_NcwJsdKevpJGdsrMunmIl33aGse-Lur-fjY/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">スタディーワークリスト</h6>
                <p>検査リストの表示と操作に関するガイドライン</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card mb-3 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-violet text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/viewer.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">DICOMビューアーの詳細についてはこちら</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1j3vbmD2_dulsb_SR7rjhKjtjDZfOKoQ54O3dzqOKRF8/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">DICOMビューアー</h6>
                <p>DICOM 規格の画像データを表示するためのビューアー</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 stretch-card" data-aos="zoom-in" data-aos-delay="600">
          <div class="card color-cards">
            <div class="card-body p-0">
              <div class="bg-success text-center card-contents">
                <div class="card-image">
                  <img src="assets/images/landing-page/report.png" class="case-studies-card-img" alt="" />
                </div>
                <div class="card-desc-box d-flex align-items-center justify-content-around">
                  <div>
                    <h6 class="text-white pb-2 px-3">レポート作成の詳細についてはこちら</h6>
                    <button class="btn btn-white" onclick=" window.open('https://docs.google.com/document/d/1R1Qe_LieOKwy8XlqaLtAvwHwjujf1TpxEvkyc-ORrMA/edit?usp=sharing','_blank')">Read More</button>
                  </div>
                </div>
              </div>
              <div class="card-details text-center pt-4">
                <h6 class="m-0 pb-1">レポート作成</h6>
                <p>放射線診断レポートを作成するためのアプリケーション</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
