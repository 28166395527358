import { Injectable } from '@angular/core';
import { IDBPatient, IDBStudy, PatientFirebaseService, StudyFirebaseService } from '@app/@database';
import { Logger } from '@app/@shared';
import { StudyInfo } from '@app/worklist';
const logger = new Logger('<Upload>DatabaseService');
@Injectable()
export class DatabaseService {
  constructor(
    private patientService: PatientFirebaseService,
    private studyService: StudyFirebaseService,
  ) {}

  /**
   * Saves a study to the database.
   * @param study - The study information to be saved.
   * @param userId - The ID of the user saving the study.
   * @returns A promise that resolves to a boolean indicating whether the study was saved successfully.
   */
  public saveStudy = async (study: StudyInfo, userId: string, baseDriveId: string): Promise<boolean> => {
    try {
      // Check if the patient already exists in the database
      const patientInfo = await this.patientService.getPatientById(study.patientid);
      let patientUid = patientInfo?.id;
      // If the patient does not exist, add the patient to the database
      if (patientInfo === undefined) {
        const dbPatient: IDBPatient = {
          patientId: study.patientid || '',
          patientName: study.name || '',
          birthDate: study.dob || '',
          gender: study.gender || '',
          createBy: userId,
        };
        patientUid = await this.patientService.addPatient(dbPatient);
      }
      // Save the study to the database
      const dbStudy: IDBStudy = {
        studyInstanceUid: study.studyinstanceUID || '',
        patientId: study.patientid || '',
        patientUid: patientUid || '',
        bodyPart: study.bodypart || '',
        modality: study.modality || '',
        studyDate: study.studydate || '',
        studyTime: study.studytime || '',
        manufacture: study.manufacture || '',
        accessionNumber: study.accessionNumber || '',
        studyDescription: study.studyDescription || '',
        totalImage: study.imagecount || '',
        dicomdirDriveId: study.dicomdirID || '',
        reportDriveId: study.reportdriveId || '',
        baseDriveId,
        isShared: false, //Default after upload is not share for all
        isHaveReport: false, //Default after upload is not have report
        createBy: userId,
      };
      // Add the study to the database and return the result
      const resAddStudy = await this.studyService.addStudy(dbStudy);
      if (resAddStudy) {
        return true;
      }
      return false;
    } catch (error) {
      logger.error(error);
      return false;
    }
  };

  /**
   * Checks if a study with the given studyInstanceUid exists for the specified user.
   * @param studyInstanceUid - The unique identifier of the study.
   * @param userId - The identifier of the user.
   * @returns A Promise that resolves to an IDBStudy object if the study exists, or undefined if it doesn't.
   */
  public checkExistStudy = async (studyInstanceUid: string, userId: string): Promise<IDBStudy | undefined> => {
    try {
      const studyInfo = <IDBStudy>await this.studyService.findStudyByStudyInstanceUid(studyInstanceUid, userId);
      return studyInfo;
    } catch (error) {
      logger.error(error);
      return undefined;
    }
  };

  /**
   * Deletes an existing study from the database.
   * @param studyDbUid - The unique identifier of the study in the database.
   * @returns A promise that resolves to a boolean indicating whether the study was successfully deleted.
   */
  public deleteExistStudy = async (studyDbUid: string): Promise<boolean> => {
    try {
      const res = await this.studyService.hardDeleteStudy(studyDbUid);
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  };
}
