<div [ngStyle]="style" #tile class="position-relative">
  <ng-container *ngTemplateOutlet="tileOverlayTemp"></ng-container>
  <div #render [id]="tileId" class="w-100 h-100 position-relative">
    <span class="tile__selected" [class.tile__selected--activated]="activated"></span>
  </div>
</div>

<ng-template #tileOverlayTemp>
  <ng-container *rxLet="displayOverlay$; let isDisplay">
    <ng-container *rxIf="tileOverlay && isDisplay">
      <div class="overlay noselect tile__overlay-wrapper">
        <div id="topLeft" class="overlay__top-left">
          <ng-container *rxFor="let overlay of tileOverlay.topLeft">
            <p [id]="'overlay-' + overlay.id" class="overlay__p">{{ overlay.value }}</p>
          </ng-container>
          <ng-container *rxLet="isDownloading$; let isDownloading">
            <ng-container *rxIf="isDownloading">
              <p class="overlay__loading" translate>isLoading</p>
            </ng-container>
          </ng-container>
        </div>

        <div id="topRight" class="overlay__top-right">
          <ng-container *rxFor="let overlay of tileOverlay.topRight">
            <p [id]="'overlay-' + overlay.id" class="overlay__p">{{ overlay.value }}</p>
          </ng-container>
        </div>

        <div id="bottomLeft" class="overlay__bottom-left">
          <ng-container *rxFor="let overlay of tileOverlay.bottomLeft">
            <p [id]="'overlay-' + overlay.id" class="overlay__p">{{ overlay.value }}</p>
          </ng-container>
        </div>

        <div id="bottomRight" class="overlay__bottom-right">
          <ng-container *rxFor="let overlay of tileOverlay.bottomRight">
            <p [id]="'overlay-' + overlay.id" class="overlay__p">{{ overlay.value }}</p>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *rxIf="orientation && isDisplay">
      <div class="orientation noselect">
        <div class="orientation__top">
          <p class="orientation__p">{{ orientation.top }}</p>
        </div>
        <div class="orientation__bottom">
          <p class="orientation__p">{{ orientation.bottom }}</p>
        </div>
        <div class="orientation__left">
          <p class="orientation__p">{{ orientation.left }}</p>
        </div>
        <div class="orientation__right">
          <p class="orientation__p">{{ orientation.right }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
