import { Injectable } from '@angular/core';
import { IUploadData } from '../models/IUploadData';
import { Thumbnail, IImage, DicomTag, CornerstoneMetadata, DicomLoaderService, MODALITY_NAME, isMultiFrame, IGeneralSeries } from '@app/@core';
import { metaData } from '@cornerstonejs/core';
import { UploadData } from '../models/upload-data';
import { Logger } from '@app/@shared';
import { StudyUpload } from '../models/study-upload';
import { SeriesUpload } from '../models/series-upload';
const log = new Logger('PreUploadService');
@Injectable()
export class PreUploadService {
  constructor(private dicomLoaderSrv: DicomLoaderService) {}

  /**
   * Takes an array of DICOM files and structure them into IUploadData objects.
   *
   * @param imageObject - The image object to structure. Should be an array of IDcmImage objects
   * @param IDcmImage
   */
  public async structureData(imageObject: IImage[]): Promise<IUploadData | undefined> {
    try {
      const thumbnail = new Thumbnail();
      //await thumbnail.init();
      const uploadData = new UploadData();
      for (let index = 0, length = imageObject.length; index < length; index++) {
        let dcmFile = imageObject[index];
        let thumbnailID = dcmFile.frameId && dcmFile.frameId.length >= 1 ? dcmFile.frameId[0] : dcmFile.imageId;
        const tag = new DicomTag(dcmFile.imageId);
        dcmFile.tag = tag.toObject();
        const modality = dcmFile.tag.general.modality;

        //get metadata for image for structure study and series for inputed data
        const studyInstanceUID = tag.general.studyInstanceUID || '';
        let seriesInstanceUID = tag.general.seriesInstanceUID || '';
        const needSeperateSeries = isMultiFrame(dcmFile.imageId) || modality === MODALITY_NAME.MG;
        if (needSeperateSeries) {
          seriesInstanceUID = tag.general.seriesInstanceUID + index;
        }
        // Case 1: Add new study object into result if doesn't exist on result. Initialize all upload Data(Study, Series, dcm File)
        const study = uploadData.findStudy(studyInstanceUID);
        if (study === undefined) {
          const newStudy = new StudyUpload(studyInstanceUID);
          newStudy.setPatient(tag.patientInfo);
          const seriesMetadata: IGeneralSeries = metaData.get(CornerstoneMetadata.GENERAL_SERIES, dcmFile.imageId);
          seriesMetadata.seriesDescription = dcmFile.data.string('x0008103e') || '';
          if (needSeperateSeries) {
            seriesMetadata.seriesNumber = seriesMetadata.seriesNumber + index;
          }
          const newSeries = new SeriesUpload(seriesInstanceUID, seriesMetadata, dcmFile.tag.general.modality);
          //Generate thumbnail for multi frame image
          newSeries.addThumbnail(await thumbnail.generate(thumbnailID));
          newSeries.addImageObject(dcmFile);
          newStudy.addNewSeries(newSeries);
          uploadData.addStudy(newStudy);
        } else {
          // Case 2: Add new Series on existed study on upload data.
          const series = study.findSeries(seriesInstanceUID);
          if (series === undefined) {
            const seriesMetadata = metaData.get(CornerstoneMetadata.GENERAL_SERIES, dcmFile.imageId);
            seriesMetadata.seriesDescription = dcmFile.data.string('x0008103e') || '';
            if (needSeperateSeries) {
              seriesMetadata.seriesNumber = seriesMetadata.seriesNumber + index;
            }
            const newSeries = new SeriesUpload(seriesInstanceUID, seriesMetadata, dcmFile.tag.general.modality);
            //Generate thumbnail for multi frame image
            newSeries.addThumbnail(await thumbnail.generate(thumbnailID));
            newSeries.addImageObject(dcmFile);
            study.addNewSeries(newSeries);
          } else {
            // Case 3: exist all study, series. only add dcm file for upload
            series.addImageObject(dcmFile);
          }
        }
      }
      this.dicomLoaderSrv.purceCache();
      uploadData.defaultSort();
      return uploadData;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }
}
