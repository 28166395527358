// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  version: env['npm_package_version'] + '-dev',
  buildNumber: '0000',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'vi-VN', 'ja-JP'],
  gpai: {
    clientId: '309924252111-mg6odits85i8rkckhlhplbamv2kq7o3u.apps.googleusercontent.com',
    projectId: 'vpacs-dev',
    authUri: 'https://accounts.google.com/o/oauth2/auth',
    tokenUri: 'https://oauth2.googleapis.com/token',
  },
  firebase: {
    apiKey: 'AIzaSyBDxwkAuyXpfM5Zr8TbIpSL2qaE9XP03JY',
    authDomain: 'vpacs-dev.firebaseapp.com',
    projectId: 'vpacs-dev',
    storageBucket: 'vpacs-dev.appspot.com',
    messagingSenderId: '309924252111',
    appId: '1:309924252111:web:2f364fdab2561dff26aaba',
    measurementId: 'G-7QNGG1L4PF',
  },
  worklist: {
    fileName: 'worklist',
    sheetName: 'Sheet1',
    dataRange: '!A2:Q',
    headerRange: '!A1:Q',
  },
  drive: {
    root_path: 'dev-vpacs',
    upload: 'vpacs-internal-upload',
    shareReport: 'vpacs-share-report',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
