import { Base, IDcmTag, IImagePrefetch, convertMultiframeImageId } from '@app/@core';

/**
 * Represents an image prefetch object.
 */
export class ImagePrefetch extends Base<IImagePrefetch> implements IImagePrefetch {
  imageId: string;
  tag: IDcmTag;
  isMultiFrame: boolean = false;
  driveId?: string;
  frameId: string[] = [];

  /**
   * Creates a new instance of the ImagePrefetch class.
   * @param uid The unique identifier of the image.
   */
  constructor(uid: string) {
    super();
    this.imageId = uid;
    this.driveId = '';
  }

  /**
   * Checks if the image is a multi-frame image and sets the appropriate properties.
   */
  checkMultiFrame() {
    const frame = convertMultiframeImageId(this.imageId);
    if (frame.length > 1) {
      this.isMultiFrame = true;
      this.frameId = frame;
    }
  }

  /**
   * Initializes the ImagePrefetch object with the specified tag and multi-frame flag.
   * @param tag The DICOM tag.
   * @param isMultiFrame A flag indicating if the image is multi-frame.
   */
  init(tag: IDcmTag): void {
    this.tag = tag;
  }

  /**
   * Sets the DICOM tag of the image.
   * @param value The DICOM tag.
   */
  setTag(value: IDcmTag): void {
    this.tag = value;
  }

  /**
   * Sets the multi-frame flag and frame IDs of the image.
   * @param isMultiFrame A flag indicating if the image is multi-frame.
   * @param frameIds The array of frame IDs.
   */
  setMultiFrame(frameIds: string[] | undefined): void {
    const isMultiFrame = frameIds && frameIds.length > 0 ? true : false;
    this.isMultiFrame = isMultiFrame;
  }
}
